import React, { Component } from "react";
import Routers from "./routers/index";
import { Elements, StripeProvider } from "react-stripe-elements";
import settings from "settings";
import { Helmet } from "react-helmet";

class App extends Component {
  componentDidMount() {
    const favicon = document.getElementById("favicon");
    if (favicon) {
      favicon.href = settings?.favicon;
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{settings.versionName}</title>
        </Helmet>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
          <div className="app">
            <Elements>
              <Routers />
            </Elements>
          </div>
        </StripeProvider>
      </>
    );
  }
}

export default App;
