import { creatorListsActionTypes } from "../constants";

const initialState = {
  loading: false,
  lists: [],
  dismissModal: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case creatorListsActionTypes.getCreatorLists.REQUEST:
      return {
        ...state,
        loading: true,
        dismissModal: false,
      };
    case creatorListsActionTypes.getCreatorLists.SUCCESS:
      return {
        ...state,
        loading: false,
        lists: payload,
        dismissModal: false,
      };
    case creatorListsActionTypes.getCreatorLists.FAILURE:
      return {
        ...state,
        loading: false,
        lists: [],
        dismissModal: false,
      };

    case creatorListsActionTypes.createCreatorList.REQUEST:
      return {
        ...state,
        loading: true,
        dismissModal: false,
      };
    case creatorListsActionTypes.createCreatorList.SUCCESS:
      return {
        ...state,
        loading: false,
        dismissModal: true,
      };
    case creatorListsActionTypes.createCreatorList.FAILURE:
      return {
        ...state,
        loading: false,
        dismissModal: false,
      };

    case creatorListsActionTypes.updateCreatorList.REQUEST:
      return {
        ...state,
        loading: true,
        dismissModal: false,
      };
    case creatorListsActionTypes.updateCreatorList.SUCCESS:
      return {
        ...state,
        loading: false,
        dismissModal: true,
      };
    case creatorListsActionTypes.updateCreatorList.FAILURE:
      return {
        ...state,
        loading: false,
        dismissModal: false,
      };
    case creatorListsActionTypes.addSharedUser.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case creatorListsActionTypes.addSharedUser.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case creatorListsActionTypes.addSharedUser.FAILURE:
      return {
        ...state,
        loading: false,
      };

    default: {
      return state;
    }
  }
};
