/* eslint-disable no-loop-func */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAccountOverview,
  clearReportsErrorMsg,
  clearSubscriptionErrorAction,
} from "../reports/actions/index";
import {
  saveListSearchFilters,
  getPastExportsLists,
} from "../userProfile/actions/index";
import { getPastExportsReports } from "../userProfile/actions/index";
import { exportCSVById } from "../identification/actions/index";
import Proj4 from "proj4";
import SplashScreen from "./splashScreen";
import { showErrorMessage } from "../../actions";
import { store } from "../../store";
import history from "../../history";

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    window.proj4 = Proj4;
    this.state = {
      reportPage: 1,
      listPage: 1,
      activeReportsPage: 1,
      activeListsPage: 1,
    };
  }
  componentDidMount() {
    const { reportPage, listPage } = this.state;
    this.props.getPastExportsLists(listPage, 5);
    this.props.getPastExportsReports(reportPage, 5);
  }

  clearErrorMessage = () => {
    this.props.clearReportsErrorMsg();
  };

  clearSubscriptionError = () => {
    this.props.clearSubscriptionErrorAction();
  };

  handlePageChangeReport = (pageNumber) => {
    const { getPastExportsReports } = this.props;
    this.setState({
      activeReportsPage: pageNumber,
    });
    getPastExportsReports(pageNumber, 5);
  };

  handleReportsAnalyze = (obj) => {
    const reportUrl = `${process.env.REACT_APP_URL}/reports/${obj.report_id}`;
    let newReportTab = window.open(reportUrl, "_blank");
    if (newReportTab) return;
    history.push(`/reports/${obj.report_id}`);
    window.scroll(0, 0);
    this.props.getAccountOverview(obj.report_id);
  };

  searchAgain = (searchObj) => {
    this.props.saveListSearchFilters(searchObj);
    history.push("/discovery");
  };

  handleExport = (id) => {
    this.props.exportCSVById(id);
  };

  handlePageChangeList = (pageNumber) => {
    this.setState({
      activeListsPage: pageNumber,
    });
    this.props.getPastExportsLists(pageNumber, 5);
  };

  render() {
    return (
      <>
        {this.props.subscriptionError
          ? showErrorMessage(
              store,
              this.props.subscriptionError,
              this.clearSubscriptionError,
              "none"
            )
          : null}
        {this.props.reportsErrorMessage
          ? showErrorMessage(
              store,
              this.props.reportsErrorMessage,
              this.clearErrorMessage,
              "none"
            )
          : null}
        <SplashScreen
          state={this.state}
          initialValues={this.props.initialValues}
          pastExportsReports={this.props.pastExportsReports}
          pastExportsLists={this.props.pastExportsLists}
          handlePageChangeReport={this.handlePageChangeReport}
          handlePageChangeList={this.handlePageChangeList}
          handleReportsAnalyze={this.handleReportsAnalyze}
          handleExport={this.handleExport}
          searchAgain={this.searchAgain}
          loginUser={this.props.loginUser}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    subscriptionError: state.reports.subscriptionError,
    reportsErrorMessage: state.reports.reportsErrorMessage,
    pastExportsReports: state.userProfile.pastExportsReports,
    pastExportsLists: state.userProfile.pastExportsLists,
  };
};
export default connect(mapStateToProps, {
  clearReportsErrorMsg,
  getAccountOverview,
  clearSubscriptionErrorAction,
  getPastExportsReports,
  saveListSearchFilters,
  getPastExportsLists,
  exportCSVById,
})(DashboardComponent);
