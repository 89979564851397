import { loginActionTypes } from "../constants";
import { COMMON_ERROR_MESSAGE } from "../../../actions";
const initialState = {
  loading: false,
  refreshLoading: true,
  loginUser: null,
  authToken: null,
  errorMsg: null,
  rememberMeCredentials: null,
  signoutErrorMsg: null,
  numberOfTimeUnlockMoreClicked: 0,
  previouslyExportedIds: [],
  subscriptionDetails: null,
  checkSubscriptionErrorMsg: null,
  checkoutSessionDetails: null,
  billingPortalDetails: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case loginActionTypes.login.REQUEST:
      return {
        ...state,
        loading: true,
        refreshLoading: true,
      };
    case loginActionTypes.login.SUCCESS:
      return {
        ...state,
        loginUser: payload.errorMsg ? null : payload,
        loading: false,
        refreshLoading: true,
        errorMsg: payload.errorMsg || null,
        authToken: payload && payload.token,
      };
    case loginActionTypes.login.FAILURE:
      return {
        ...state,
        loginUser: null,
        loading: false,
        refreshLoading: false,
        errorMsg: COMMON_ERROR_MESSAGE,
        authToken: null,
      };
    case loginActionTypes.generateBillingPortal.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginActionTypes.generateBillingPortal.SUCCESS:
      return {
        ...state,
        loading: false,
        billingPortalDetails: payload,
      };
    case loginActionTypes.generateBillingPortal.FAILURE:
      return {
        ...state,
        loading: false,
        billingPortalDetails: null,
      };
    case loginActionTypes.checkSubscription.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginActionTypes.checkSubscription.SUCCESS:
      return {
        ...state,
        subscriptionDetails: payload.errorMsg ? null : payload,
        loading: false,
        checkSubscriptionErrorMsg: payload.errorMsg || null,
      };
    case loginActionTypes.checkSubscription.FAILURE:
      return {
        ...state,
        subscriptionDetails: null,
        loading: false,
        checkSubscriptionErrorMsg: COMMON_ERROR_MESSAGE,
      };
    case loginActionTypes.retrieveLoginUser.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginActionTypes.retrieveLoginUser.SUCCESS:
      return {
        ...state,
        loginUser: payload,
        loading: false,
      };
    case loginActionTypes.retrieveLoginUser.FAILURE:
      return {
        ...state,
        loginUser: null,
        loading: false,
        errorMsg: COMMON_ERROR_MESSAGE,
      };
    case loginActionTypes.adminLogin.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginActionTypes.adminLogin.SUCCESS:
      return {
        ...state,
        loginUser: payload.errorMsg ? null : payload,
        loading: false,
        errorMsg: payload.errorMsg || null,
        authToken: payload && payload.token,
      };
    case loginActionTypes.adminLogin.FAILURE:
      return {
        ...state,
        loginUser: null,
        loading: false,
        errorMsg: COMMON_ERROR_MESSAGE,
        authToken: null,
      };

    case loginActionTypes.clearErrorMsg.SUCCESS:
      return {
        ...state,
        errorMsg: null,
      };
    case loginActionTypes.clearSignoutErrorMsg.SUCCESS:
      return {
        ...state,
        signoutErrorMsg: null,
      };

    case loginActionTypes.saveCredentials.SUCCESS:
      return {
        ...state,
        rememberMeCredentials: payload,
      };
    case loginActionTypes.removeCredentials.SUCCESS:
      return {
        ...state,
        rememberMeCredentials: null,
      };
    case loginActionTypes.clearLoading.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case loginActionTypes.setRefreshLoading.REQUEST:
      return {
        ...state,
        refreshLoading: false,
      };
    case loginActionTypes.setRefreshLoading.SUCCESS:
      return {
        ...state,
        refreshLoading: true,
      };

    case loginActionTypes.signout.REQUEST:
      return {
        ...state,
        loading: true,
        refreshLoading: true,
      };
    // case loginActionTypes.setnumberOfTimeUnlockMoreClicked.SUCCESS:
    //   return {
    //     ...state,
    //     numberOfTimeUnlockMoreClicked: payload,
    //   }
    case loginActionTypes.setPreviouslyExportIds.SUCCESS:
      return {
        ...state,
        previouslyExportedIds: payload,
      };

    case loginActionTypes.signout.SUCCESS:
      return {
        ...state,
        loginUser: null,
        authToken: null,
        loading: false,
        refreshLoading: true,
        signoutErrorMsg: payload,
        numberOfTimeUnlockMoreClicked: 0,
        previouslyExportedIds: [],
      };
    case loginActionTypes.clearPreviouslyExportedIds.SUCCESS:
      return {
        ...state,
        // loading: false,
        // exportsErrorMSG: null,
        previouslyExportedIds: [],
      };
    case loginActionTypes.resetLoginReducer.SUCCESS:
      return {
        ...initialState,
        rememberMeCredentials: state.rememberMeCredentials,
      };
    case loginActionTypes.createCheckoutSession.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginActionTypes.createCheckoutSession.SUCCESS:
      return {
        ...state,
        loading: false,
        checkoutSessionDetails: payload,
      };
    case loginActionTypes.createCheckoutSession.FAILURE:
      return {
        ...state,
        loading: false,
        checkoutSessionDetails: null,
      };
    case loginActionTypes.checkSessionExistenceAction.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginActionTypes.checkSessionExistenceAction.SUCCESS:
      return {
        ...state,
        loading: false,
        checkoutSessionDetails: payload,
      };
    case loginActionTypes.checkSessionExistenceAction.FAILURE:
      return {
        ...state,
        loading: false,
        // checkoutSessionDetails: null,
      };
    case loginActionTypes.createUpdatePlanSesion.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginActionTypes.createUpdatePlanSesion.SUCCESS:
      return {
        ...state,
        loading: false,
        checkoutSessionDetails: payload,
      };
    case loginActionTypes.createUpdatePlanSesion.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
