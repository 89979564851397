import React from "react";
import settings from "settings";
import { Translation, Trans } from "react-i18next";
import { Formik, Form, Field } from "formik";
import {
  RenderTextField,
  RenderPhoneField,
  RenderPasswordField,
  SubmitButton,
} from "../../components";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { validate } from "./validate";
import {
  signup,
  clearSuccessMsg,
  clearErrorMsg,
  clearLoading,
} from "./actions/index";
// import UnAuthorized from './unauthorized';
import { Row, Col, FormGroup } from "reactstrap";
import { showErrorMessage } from "../../actions";
import queryString from "query-string";
import { toast } from "react-toastify";
import { store } from "../../store";
// import HCaptcha from 'react-hcaptcha';
class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      captcha_token: "",
    };
  }
  onSubmit = (values) => {
    let postData = { ...values };
    let params = queryString.parse(this.props.location.search);
    postData.gacdata = window.gacdata;
    postData.name = values.firstName.trim() + " " + values.lastName.trim();
    postData.email = postData.email.trim().toLowerCase();
    postData.phone = postData.phone.trim();
    postData.company = postData.company.trim();
    postData.ref = params.ref;
    delete postData.firstName;
    delete postData.lastName;

    // postData.captcha_token = this.state.captcha_token;
    if (window.location.href.split("/").pop() !== "signup") {
      let url = window.location.href;
      let regex = new RegExp(
        "\\?utm_source=Product%20Hunt&utm_medium=Launch&utm_campaign=Product%20Hunt%20Launch"
      );

      if (regex.test(url.split("/").pop())) {
        let createSessionData = { coupon: "PH20" };
        this.props.signup(postData, createSessionData);
      } else {
        this.props.signup(postData, null);
      }
    } else {
      this.props.signup(postData, null);
    }
  };

  componentDidMount() {
    this.props.clearLoading();
    document.getElementsByClassName("app")[0].classList.add("auth-wrap");
  }
  componentWillUnmount() {
    toast.dismiss();
    this.props.clearSuccessMsg();
    this.props.clearErrorMsg();
    document.getElementsByClassName("app")[0].classList.remove("auth-wrap");
  }
  handleClearErrorMsg = () => {
    this.props.clearErrorMsg();
  };
  handleClearSuccessMsg = () => {
    this.props.clearSuccessMsg();
  };
  onVerifyCaptcha = (captcha_token) => {
    this.setState({
      captcha_token,
    });
  };
  render() {
    return (
      <>
        {this.props.errorMsg
          ? showErrorMessage(
              store,
              this.props.errorMsg,
              this.handleClearErrorMsg,
              5000,
              "signupError"
            )
          : // <UnAuthorized errorMsg={this.props.errorMsg} />
            null}
        {/*this.props.successMsg ?
          <Success successMsg={this.props.successMsg} />
        : null*/}

        <div className="pt-40">
          <div className="main-site-content container">
            <div className="user-form-wrap">
              <div className="page-head">
                <img
                  src={settings.mainbrand}
                  className="auth_logo"
                  alt="homepage"
                />
                <h1>
                  <Trans i18nKey="auth.register.title">Sign up</Trans>
                </h1>
                <p className="sub-text">
                  <Trans i18nKey="auth.register.description">
                    Sign up now to access the latest insights for your social
                    media performance.
                  </Trans>
                </p>
              </div>
              <Formik
                initialValues={this.props.initialValues}
                onSubmit={this.onSubmit}
                validate={validate}
              >
                {({ touched, errors }) => (
                  <Form>
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Translation>
                            {(t, { i18n }) => (
                              <Field
                                name="firstName"
                                placeholder={t("auth.register.form.first_name")}
                                className={
                                  errors.firstName && touched.firstName
                                    ? "error"
                                    : ""
                                }
                                component={RenderTextField}
                              />
                            )}
                          </Translation>
                          {errors.firstName && touched.firstName && (
                            <span className="required">{errors.firstName}</span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Translation>
                            {(t, { i18n }) => (
                              <Field
                                name="lastName"
                                placeholder={t("auth.register.form.last_name")}
                                className={
                                  errors.lastName && touched.lastName
                                    ? "error"
                                    : ""
                                }
                                component={RenderTextField}
                              />
                            )}
                          </Translation>
                          {errors.lastName && touched.lastName && (
                            <span className="required">{errors.lastName}</span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormGroup>
                      <Translation>
                        {(t, { i18n }) => (
                          <Field
                            name="email"
                            placeholder={t("auth.register.form.email")}
                            className={
                              errors.email && touched.email ? "error" : ""
                            }
                            component={RenderTextField}
                          />
                        )}
                      </Translation>
                      {errors.email && touched.email && (
                        <span className="required">{errors.email}</span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Translation>
                        {(t, { i18n }) => (
                          <Field
                            name="company"
                            placeholder={t("auth.register.form.company")}
                            className={
                              errors.company && touched.company ? "error" : ""
                            }
                            component={RenderTextField}
                          />
                        )}
                      </Translation>
                      {errors.company && touched.company && (
                        <span className="required">{errors.company}</span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Translation>
                        {(t, { i18n }) => (
                          <Field
                            name="phone"
                            placeholder={t("auth.register.form.phone")}
                            className={
                              errors.phone && touched.phone ? "error" : ""
                            }
                            component={RenderPhoneField}
                          />
                        )}
                      </Translation>
                      {errors.phone && touched.phone && (
                        <span className="required">{errors.phone}</span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Translation>
                        {(t, { i18n }) => (
                          <Field
                            name="password"
                            placeholder={t("auth.register.form.password")}
                            className={
                              errors.password && touched.password ? "error" : ""
                            }
                            component={RenderPasswordField}
                          />
                        )}
                      </Translation>
                      {errors.password && touched.password && (
                        <span className="required">{errors.password}</span>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Translation>
                        {(t, { i18n }) => (
                          <Field
                            name="password2"
                            placeholder={t("auth.register.form.confirm")}
                            className={
                              errors.password2 && touched.password2
                                ? "error"
                                : ""
                            }
                            component={RenderPasswordField}
                          />
                        )}
                      </Translation>
                      {errors.password2 && touched.password2 && (
                        <span className="required">{errors.password2}</span>
                      )}
                    </FormGroup>
                    {/* <HCaptcha sitekey={CAPTCHA_KEY} onVerify={this.onVerifyCaptcha}></HCaptcha> */}

                    <FormGroup className="bottom text-center">
                      <SubmitButton className="btn btn-primary">
                        <Trans i18nKey="auth.register.create_account">
                          Create account
                        </Trans>
                      </SubmitButton>
                      <p className="text-grey">
                        <Trans i18nKey="auth.register.existing_account">
                          Already have an account?
                        </Trans>{" "}
                        <NavLink to="/" className="link-more">
                          <Trans i18nKey="auth.register.sign_in">Sign in</Trans>
                        </NavLink>
                      </p>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    initialValues: {
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      phone: "",
      password: "",
      password2: "",
    },
    errorMsg: state.signup.errorMsg,
    successMsg: state.signup.successMsg,
  };
};
export default connect(mapStateToProps, {
  signup,
  clearSuccessMsg,
  clearErrorMsg,
  clearLoading,
})(Signup);
