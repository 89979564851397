import React from "react";
import { Trans } from "react-i18next";

const lastPostFilterOption = [
  {
    text: <Trans i18nKey="discovery.filters.last_post.options.one_month" />,
    value: 30,
  },
  {
    text: <Trans i18nKey="discovery.filters.last_post.options.three_months" />,
    value: 90,
  },
  {
    text: <Trans i18nKey="discovery.filters.last_post.options.six_months" />,
    value: 180,
  },
];
export default lastPostFilterOption;
