import React from 'react'
import { Modal, ModalHeader, ModalBody, FormGroup } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import { RenderTextField, RenderButton, SubmitButton, RenderSingleSelect } from '../../components';

import { unlockInfluencersOptions } from '../../helpers/unlockInfluencersOptions';

const UnlockAccountsModal = (props) => {
    const value = props.influencerNeededType === 'dropdown' ? (props.noOfInfluencersNeededInDropdown ? Math.ceil(props.noOfInfluencersNeededInDropdown.value / 2) : '') : (props.noOfInfluencersNeeded ? Math.ceil(parseInt(props.noOfInfluencersNeeded, 10) / 2) : '');
    return (
        <Modal isOpen={props.openUnlockAccountsModal} toggle={props.toggleUnlockAccountsModal}>
            <ModalHeader toggle={props.toggleUnlockAccountsModal}>
                <div className="text-center unlock-more-modal-heading-section">
                    <h1 className="unlock-more-modal-heading">Confirmation</h1>
                    <p className="unlock-more-modal-subheading mb-5">Total Search Results: <span>{props.totalSearchResults}</span></p>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="container">
                    <div className="user-form-wrap no-min-height">
                        <Formik
                            initialValues={props.initialValues}
                            onSubmit={props.handleUnlockMoreSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched
                            }) => (
                                    <Form>
                                        <FormGroup>
                                            {props.influencerNeededType === 'dropdown' ?
                                                <Field
                                                    component={RenderSingleSelect}
                                                    name="noOfInfluencersNeeded"
                                                    className="center-dropdown"
                                                    placeholder='Number of Influencers Needed'
                                                    value={props.noOfInfluencersNeededInDropdown}
                                                    onChange={props.handleNoOfInfluencersChange}
                                                    options={unlockInfluencersOptions} />
                                                : <Field
                                                    name="noOfInfluencersNeeded"
                                                    component={RenderTextField}
                                                    value={props.noOfInfluencersNeeded}
                                                    onChange={props.handleInfluencerChange}
                                                    placeholder="Number of Influencers Needed"
                                                    maxLength={3} />
                                            }
                                            {/* <Field
                                                    name='includeContact'
                                                    id='includeContact'
                                                    checked={props.includeContact}
                                                    onChange={(input) => props.isIncludeContact(input)}
                                                    component={RenderCheckBox}
                                                    className="styled-checkbox center-dropdown" />{' '}
                                                <RenderLabel check for='includeContact'>
                                                   Include contact Info?
                                                </RenderLabel> */}

                                        </FormGroup>
                                        <FormGroup><p className="unlock-more-modal-subheading text-center mt-5">Export Cost: <span>{value}</span></p></FormGroup>

                                        <FormGroup>
                                            <div className="d-flex justify-content-center">
                                                <SubmitButton className="btn btn-primary mr-1">Continue</SubmitButton>
                                                <RenderButton className="btn btn-outline-primary ml-1" onClick={props.toggleUnlockAccountsModal}> Cancel</RenderButton>
                                            </div>
                                        </FormGroup>
                                    </Form>
                                )}

                        </Formik>
                    </div>
                </div>

            </ModalBody>

        </Modal>
    )
}

export default UnlockAccountsModal
