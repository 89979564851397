import React from "react";
import { Card, CardBody, CardTitle, UncontrolledTooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { showTooltip } from "../../../../actions";
const chartOptions = (props) => {
  let data =
    props.accountOverviewDetails &&
    props.accountOverviewDetails.audience_followers &&
    props.accountOverviewDetails.audience_followers.data &&
    props.accountOverviewDetails.audience_followers.data.audience_reachability
      ? props.accountOverviewDetails.audience_followers.data.audience_reachability.map(
          (obj, index) => obj
        )
      : [];
  let seriesData = data.map((obj, index) => [
    index === 0
      ? obj.code.replace("-", "<")
      : index === data.length - 1
      ? obj.code.replace("-", ">")
      : obj.code,
    parseFloat((obj.weight * 100).toFixed(2)),
  ]);
  let categories = data.map((obj, index) =>
    index === 0
      ? obj.code.replace("-", "< ")
      : index === data.length - 1
      ? obj.code.replace("-", " >")
      : obj.code.replace("-", " — ")
  );
  return {
    chart: {
      type: "column",
      height: 200,
      backgroundColor: null,
    },

    title: null,
    xAxis: {
      lineWidth: 0,
      type: "category",
      tickColor: "transparent",
      categories: categories,
      labels: {
        style: {
          color: "#888",
          fontSize: "12px",
          fontFamily: "'HKGrotesk-Regular', Arial, 'sans-serif'",
        },
      },
    },
    yAxis: {
      lineWidth: 0,
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      stackLabels: {
        enabled: props.showStackLabels,
        verticalAlign: "top",
        allowOverlap: props.showStackLabels,
        formatter: function () {
          return this.total + " %";
        },
        style: {
          color: "#888",
          fontSize: "12px",
          fontWeight: "600",
          fontFamily: "'HKGrotesk-Bold', Arial, 'sans-serif'",
          textOutline: "none",
        },
      },

      title: {
        text: null,
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    series: [
      {
        color: "#1f87ee",
        borderWidth: 0,
        borderRadius: 2,
        animation: false,
        enableMouseTracking: false,
        clip: false,
        pointWidth: window.innerWidth < 1024 ? 15 : 20,
        name: "Following",
        data: seriesData,
      },
    ],
    tooltip: {
      enabled: !props.showStackLabels,
      formatter: function () {
        return this.total + " %";
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
};
const FollowersAudienceReachability = (props) => {
  const { t } = useTranslation();

  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>
          <span className="tooltip-button-wrap">
            <span className="text-tooltip">
              {t("reports.audience_details.reach")}
            </span>
            <span
              href="#"
              id="followersAudienceReachability"
              className="tooltip-icon"
            ></span>
          </span>
          {props.tooltip && (
            <UncontrolledTooltip
              placement="bottom"
              className="tooltip-content"
              target="followersAudienceReachability"
            >
              {showTooltip("followersAudienceReachability")}
            </UncontrolledTooltip>
          )}
        </CardTitle>
        {!props.accountOverviewDetails ||
        !props.accountOverviewDetails.audience_followers ||
        !props.accountOverviewDetails.audience_followers.data ||
        !props.accountOverviewDetails.audience_followers.data
          .audience_reachability ||
        !props.accountOverviewDetails.audience_followers.data
          .audience_reachability.length ? (
          <span className="text-center text-grey ">
            <p className="pt-50">{t("errors.no_record")}</p>
          </span>
        ) : (
          <div className="card-content ch-300">
            <div className="graph-wrap">
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions(props)}
              />
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default FollowersAudienceReachability;
