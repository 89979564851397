const audAgeLeft = [
  { label: "13", value: 13 },
  { label: "18", value: 18 },
  { label: "25", value: 25 },
  { label: "35", value: 35 },
  { label: "45", value: 45 },
  { label: "65", value: 65 }
];
const audAgeRight = [
  { label: "17", value: 17 },
  { label: "24", value: 24 },
  { label: "34", value: 34 },
  { label: "44", value: 44 },
  { label: "64", value: 64 },
  { label: "65+", value: 65 }
];

const infAgeLeft = [
  { label: "13", value: 13 },
  { label: "18", value: 18 },
  { label: "25", value: 25 },
  { label: "35", value: 35 },
  { label: "45", value: 45 }
];

const infAgeRight = [
  { label: "18", value: 18 },
  { label: "25", value: 25 },
  { label: "35", value: 35 },
  { label: "45", value: 45 },
  { label: "65+", value: 65 }
];

export const audience_age_left = audAgeLeft;
export const audience_age_right = audAgeRight;
export const influencer_age_left = infAgeLeft;
export const influencer_age_right = infAgeRight;
