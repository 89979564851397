import React from "react";
import { Table, Row, Col } from "reactstrap";
import CampaignsTableRow from "./CampaignsTableRow.js";
import { useTranslation } from "react-i18next";

const CampaignsTable = ({
  deleteCampaignHandler,
  openCampaignHandler,
  toggleCampaignHandler,
  openNewPostModalHandler,
  campaigns,
  campaignsCount,
  ...props
}) => {
  const { t } = useTranslation();

  const columns = ["name", "networks", "posts", "creators", "followers", "modified"];
  const columnHeaders = columns.map((column, index) => (
    <th key={index}>{t(`campaign_manager.campaigns_table.${column}_header`)}</th>
  ));

  const tableData = campaigns.map((campaign) => (
    <CampaignsTableRow
      key={campaign._id}
      campaign={campaign}
      deleteCampaignHandler={deleteCampaignHandler}
      openCampaignHandler={openCampaignHandler}
      toggleCampaignHandler={toggleCampaignHandler}
      openNewPostModalHandler={openNewPostModalHandler}
    ></CampaignsTableRow>
  ));

  return (
    <Row className="search-results-wrap">
      
      <Col md="12">
        <div className="table-responsive table-outer user-profile-table">
          <Table hover borderless>
            <thead>
              <tr>{columnHeaders}</tr>
            </thead>
            <tbody>
              {campaigns && campaigns.length ? (
                tableData
              ) : (
                <tr>
                  <td colSpan={columns.length} align="center">
                    {t("errors.no_record")}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {/* TODO[epic=campaign-manager] campaigns table pagination */}
          {/* {campaignsCount > 10 ? (
            <Paginator
              activePage={currentPage}
              itemsPerPage={10}
              totalItems={campaignsCount}
              // handlePageChange={(pageNumber) => setCurrentPage(pageNumber)}
            />
          ) : null} */}
        </div>
      </Col>
    </Row>
  );
};

export default CampaignsTable;
