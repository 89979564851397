export const validate = (values) => {
    const errors = {};
   
  
    if (!values.password || values.password.trim() === '') {
      errors.password = 'This field is required';
    } else if (values.password.trim().length < 8) {
      errors.password = 'Must have atleast 8 characters';
    }
    if (!values.password2 || values.password2.trim() === '') {
      errors.password2 = 'This field is required';
    } else if (values.password && values.password2 && values.password.trim() !== values.password2.trim()) {
      errors.password2 = 'Password doesn\'t match';
    }
    return errors;
  }