import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import encryptor from "./encryptor";
const persistConfig = {
  key: "react-app",
  storage,
  transforms: [encryptor],
  stateReconciler: autoMergeLevel1,
  whitelist: ["login", "signup", "paymentPortal", "userProfile"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(
  persistedReducer,
  compose(applyMiddleware(thunk))
);
export const persistor = persistStore(store);
