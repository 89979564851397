import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { store } from "../../store";

import { Row, Col, Badge, Container, Table, } from "reactstrap";
import { RenderButton } from "../../components/index.js";

import { createOneEmailMatch, readManyEmailMatchs, setEmailMatchError, clearEmailMatchError, readOneDownloadEmailMatch } from "./actions/actions_email_match";
import { getInfluencerProfileDataBySearch } from "../reports/actions/index";
import {
	getCreatorLists,
	addCreatorList,
	updateCreatorList,
} from "../creatorList/actions/index";

import EmailMatchResultsModal from "./modals/emailMatchResultsModal";
import { UploadFileButton } from './upload_file_button';
import InfulencersSectionSearch from "../common/infulenceReport";

import { formatNumbersToCommaSeperated } from "../../actions";
import { showErrorMessage } from "../../actions";
import Paginator from "../../components/pagination/pagination";
import welcomeImage from "../../img/email-match-welcome.svg";
import moment from "moment";

const EmailMatchComponent = (props) => {

	const {
		emailMatchData,
		emailMatchTotal,
		showFileUploading,
		createOneEmailMatchAction,
		readManyEmailMatchsAction,
		readOneDownloadEmailMatchAction,
		setEmailMatchErrorAction,
		clearEmailMatchErrorAction,
	} = props;

	useEffect(() => {
		readManyEmailMatchsAction();
	}, [
		readManyEmailMatchsAction,
	]);

	const [emailMatchResultsView, setEmailMatchResultsView] = useState(false);
	const [activeEmailMatchRecord, setActiveEmailMatchRecord] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);

	const toggleMatchResultsModal = (record_id) => {
		const emailMatchRecord = emailMatchData?.find((record) => record._id === record_id);
		if (emailMatchRecord && emailMatchRecord?.status !== 'finished') return;
		setEmailMatchResultsView(!emailMatchResultsView);
		if (!emailMatchResultsView) {
			setActiveEmailMatchRecord(emailMatchRecord);
		}
	};

	const changePage = (newPageNumber) => {
		setCurrentPage(newPageNumber);
		readManyEmailMatchsAction(newPageNumber);
	};

	const createEmailMatch = (file) => {
		if (!file) {
			return;
		}
		if (file.type !== 'text/csv') {
			setEmailMatchErrorAction('Invalid file type. Must be a .csv');
		}
		createOneEmailMatchAction(file);
	};

	const handleDownloadMatches = (id, name) => {
		readOneDownloadEmailMatchAction(id);
		document.activeElement.blur();
	};

	const customToastId = "email-match-error";

	return (
		<>
			{props.emailMatchError
				? showErrorMessage(
					store,
					props.emailMatchError,
					clearEmailMatchErrorAction,
					5000,
					customToastId
				)
				: null}
			<Container className="main-site-content reverse-email-match">
				{emailMatchData && emailMatchData.length && emailMatchTotal ? (
					<>
						<EmailMatchResultsModal
							isOpen={emailMatchResultsView}
							toggleModal={toggleMatchResultsModal}
							emailMatchRecord={activeEmailMatchRecord}
							getInfluencerProfileDataBySearch={getInfluencerProfileDataBySearch}
							handleDownloadMatches={handleDownloadMatches}
							getCreatorLists={props.getCreatorLists}
							addCreatorList={props.addCreatorList}
							updateCreatorList={props.updateCreatorList}
							creatorLists={props.creatorLists}
							dismissCreatorListModal={
								props.dismissCreatorListModal
							}
						/>
						<Row>
							<Col className="is-search-block search-wrap">
								<InfulencersSectionSearch />
							</Col>
						</Row>
						<Row>
							<Col className="is-search-block search-wrap">
								<h1 className="page-title">
									Reverse Email Match{" "}
									<Badge
										color="success"
										className="badge-soft-success fs--2 ml-2 my-auto"
									>
										$100 / 1000 emails
									</Badge>
								</h1>
								<p className="text-grey mb-1">
									Find your existing creators from an email list.
								</p>
								<p className="sub-text text-grey">
									Upload a .CSV of creator email addresses below to retrieve corresponding creators from the Tensor Social database. Matched creators can then be added to a Creator List, filtered in a Discovery Search, or exported in .CSV format.
								</p>
							</Col>
						</Row>
						<Row>
							<div className="col-5" style={{ display: 'grid' }}>
								<UploadFileButton
									showFileUploading={showFileUploading}
									handleFileUpload={createEmailMatch}
								/>
							</div>
						</Row>

						<Row className="search-results-wrap mt-3 mb-3">
							<Col md="12">
								<div className="table-responsive table-outer user-profile-table">
									<Table hover borderless>
										<thead>
											<tr>
												<th className="my-reports">
													File Name
												</th>
												<th className="text-left mobile-view-past-report my-reports">
													Status
												</th>
												<th className="text-left mobile-view-past-report my-reports">
													Date Uploaded
												</th>
												<th width="18%"></th>
												<th width="18%"></th>
											</tr>
										</thead>
										<tbody>
											{emailMatchData && emailMatchData.length && emailMatchTotal ? (
												emailMatchData.map(
													(record, index) => (
														<tr style={{ 'height': '52px' }} key={record._id}>
															<td
																className="text-left mobile-view-past-report row-head"
																onClick={() => toggleMatchResultsModal(record._id)}
															>
																<h4 className="m-0">
																	{record.name}
																</h4>
															</td>
															<td className="text-left mobile-view-past-report">
																{record.status === 'finished' &&
																	<Badge className="badge-soft-success">
																		Matched {formatNumbersToCommaSeperated(record.matched)} / {formatNumbersToCommaSeperated(record.totalEmails)}
																	</Badge>
																}
																{record.status === 'error' &&
																	<Badge className="badge-soft-danger">
																		"Error processing email list"
																	</Badge>
																}
																{record.status === 'processing' && (
																	<Badge className="badge-soft-warning">
																		{
																			record.paid ? (
																				"Processing"
																			) : (
																				// TODO[epic=emailmatch] link to stripe checkout
																				"Unpaid"
																			)
																		}
																	</Badge>
																)}
															</td>
															<td className="text-left mobile-view-past-report">
																{moment(record.created).format("MMMM DD, YYYY")}
															</td>
															<td align="center">
																{record.status === 'finished' && record.downloadURL && (
																	<RenderButton
																		className="btn btn-outline-primary row-item-button"
																		onClick={() => {
																			handleDownloadMatches(record._id);
																		}}
																	>
																		{window.innerWidth < 676 ? "Export" : "Export Matches"}
																	</RenderButton>
																)}
															</td>
															<td align="center">
																{record.status === 'finished' && (
																	<RenderButton
																		className="btn btn-outline-primary row-item-button"
																		onClick={() => toggleMatchResultsModal(record._id)}
																	>
																		{window.innerWidth < 676 ? "View" : "View Details"}
																	</RenderButton>
																)}
															</td>
														</tr>
													)
												)
											) : (
												<tr>
													<td colSpan="6" align="center">
														No Records Found
													</td>
												</tr>
											)}
										</tbody>
									</Table>
									{emailMatchData &&
										emailMatchData.length &&
										emailMatchTotal &&
										emailMatchTotal > 10 ? (
										<Paginator
											activePage={currentPage || 1}
											itemsPerPage={10}
											totalItems={emailMatchTotal}
											handlePageChange={(pageNumber) =>
												changePage(pageNumber)
											}
										/>
									) : null}
								</div>
							</Col>
						</Row>
					</>
				) : (
					<>
						<Row>
							<Col className="is-search-block search-wrap">
								<InfulencersSectionSearch />
							</Col>
						</Row>
						<Row>
							<Col className="is-search-block search-wrap">
								<div align="center" className="welcome-page">
									<img
										className="welcome-img"
										src={welcomeImage}
										alt="welcome_image"
									/>
									<div className="text-wrapper">
										<h6 className="page-heading">
											Welcome to Tensor’s Email Match Tool!
										</h6>
										<p className="text-grey">
											Welcome to your Email Match by Tensor Social. The greatest creator discovery tool in the industry. Upload any email list and we’ll find your existing creators in that list.
										</p>
									</div>
									<UploadFileButton
										showFileUploading={showFileUploading}
										handleFileUpload={createEmailMatch}
									/>
								</div>
							</Col>
						</Row>
					</>
				)}

			</Container>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		emailMatchError: state.emailMatch.emailMatchError,
		emailMatchData: state.emailMatch.emailMatchData,
		emailMatchTotal: state.emailMatch.emailMatchTotal,
		showFileUploading: state.emailMatch.showFileUploading,
		creatorLists: state.creatorLists.lists,
    dismissCreatorListModal: state.creatorLists.dismissModal,
	};
};
export default connect(mapStateToProps, {
	createOneEmailMatchAction: createOneEmailMatch,
	readManyEmailMatchsAction: readManyEmailMatchs,
	readOneDownloadEmailMatchAction: readOneDownloadEmailMatch,
	setEmailMatchErrorAction: setEmailMatchError,
	clearEmailMatchErrorAction: clearEmailMatchError,
	getCreatorLists,
  addCreatorList,
  updateCreatorList,
})(EmailMatchComponent);
