import React, { Fragment } from 'react'
import { ListGroup, ListGroupItem, Row, Col, FormGroup, Table, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown, InputGroup, InputGroupAddon } from 'reactstrap';
import Paginator from '../../components/pagination/pagination';
import {
  CardNumberElement, CardExpiryElement, CardCVCElement,
} from 'react-stripe-elements';
import { capitalize, formatName } from '../../actions';
import { RenderButton, RenderTextField, SubmitButton } from '../../components';
import { Formik, Form, Field } from 'formik';
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',

      },
    },
  };
};
const UserManagementSection = (props) => {
  const formik = React.useRef();
  let planName = props.userDetailsById && props.userDetailsById.data && props.userDetailsById.data.subscription && Object.keys(props.userDetailsById.data.subscription).length && props.userDetailsById.data.subscription.plan;
  const getTableRows = () => {
    const tableRowArray = props.userManagementData.users.map((user, index) => (
      <Fragment key={index}>
        <tr className={props.state.activeUserId === user.id ? 'hidebottomshadow' : ''} onClick={() => props.getUserDetails(user)}>
          <td>{capitalize(user.name)}</td>
          <td>{user.email}</td>
          <td align="center"><RenderButton color="primary btn-status">{capitalize(user.plan)}</RenderButton>{''}</td>
          <td align="center"><ul className="list-hz">
            {user.balance ? (Object.keys(user.balance).map((item, index) => (
              <li title={formatName(item)} key={index}>{user.balance[item]}
              </li>))) :
              <>
                <li>-</li>
                <li>-</li>
                <li>-</li>
              </>
            }


          </ul>
          </td>
          <td align="center">
            <RenderButton color={user.status === true ? (user.cancel_at ? 'danger btn-status' : 'success btn-status') : (user.status === null ? 'danger btn-status' : 'warning btn-status')}>
              {user.status === true ? (user.cancel_at ? 'Canceled' : 'Active') : (user.status === null ? 'Inactive' : 'Past Due')}
            </RenderButton>{''}
          </td>
          <td align="center">
            <RenderButton color={user.api_access === true ? "success btn-status" : "danger btn-status"}>{user.api_access === true ? 'Yes' : 'No'}
            </RenderButton>{''}
          </td>
        </tr>
        {props.state.activeUserId && props.userDetailsById && props.userDetailsById.data && props.userDetailsById.data.id === user.id ?
          <tr className={`${props.state.activeUserId === user.id ? 'hidetopshadow' : ''}`} >

            <td colSpan="6">
              <div className="d-flex allInfo">

                <div className="PbInfo ">
                  <h3>Plan Name</h3>
                  <div className="PlanName">
                    <span>{(planName && formatName(planName)) || 'N/A'}</span>  {planName ?
                      <UncontrolledDropdown className="float-left">
                        <DropdownToggle color="link" caret className="change-plan-dropdown d-flex align-items-center">
                          <span >Select Plan</span>
                        </DropdownToggle>
                        <DropdownMenu className="ddmenu-col">
                          <DropdownItem >Entry</DropdownItem>
                          <DropdownItem >Standard</DropdownItem>
                          <DropdownItem >Premium</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown> :

                      <RenderButton color="success btn-status" onClick={() => props.grantTrialAccess(user)}>Grant Trial Access </RenderButton>}

                  </div>

                  <div className="apiInfo">

                    <h3>Api Access </h3>
                    <ListGroup>
                      <span>Token</span>
                      <ListGroupItem>{(props.userDetailsById.data.api && props.userDetailsById.data.api.api_token) || 'N/A'}</ListGroupItem>
                      <ListGroupItem>{(props.userDetailsById.data.api && props.userDetailsById.data.api.api_token) ? <RenderButton onClick={() => props.revokeAPIAccess(user)} color="danger btn-status">Revoke API Access </RenderButton> : <RenderButton onClick={() => props.grantAPIAccess(user)} color="success btn-status">Grant API Access </RenderButton>}</ListGroupItem>
                    </ListGroup>
                  </div>
                </div>

                <div className="cardInfo">
                  <div className="card-container">
                    <h3>Card Details</h3>
                    <div className="user-profile-account-setting user-profile-billing-info">
                      <div className="heading">
                        <div className={`flex-block ${props.state.cardBilling === false ? 'flex-column' : 'd-none'}`}>
                          <div className="left mt-2">
                            {props.renderCardImage()}
                            <div className="detail">
                              <span> Ending in {props.userDetailsById && props.userDetailsById.data && props.userDetailsById.data.subscription && Object.keys(props.userDetailsById.data.subscription).length && props.userDetailsById.data.subscription.card && props.userDetailsById.data.subscription.card.last_4 ? props.userDetailsById.data.subscription.card.last_4 : ''}</span>
                            </div>
                          </div>

                          <RenderButton color="success btn-status mt-3" onClick={() => props.toggleBilling()}>Update Card </RenderButton>

                        </div>
                      </div>
                      <div className={`user-profile-billing-info-form mt-3 ${props.state.cardBilling !== false ? 'd-block' : 'd-none'}`} >

                        <Formik
                          ref={formik}
                          initialValues={props.initialValues}
                          onSubmit={(values) => props.handleSubmitStripe(values, formik, user)}
                        // onSubmit={(values) => props.handleCardUpdate(values, formik, user)}
                        >
                          {() => (
                            <Form>
                              <Row>
                                <Col md="12">
                                  <FormGroup>
                                    <div className="row split-form">
                                      <div className="col-md-12">
                                        <label>
                                          Card number
                          <CardNumberElement

                                            {...createOptions()}
                                            onChange={props.handleChangeStripe}
                                          />
                                        </label>
                                      </div>

                                    </div>

                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12">
                                  <FormGroup>
                                    <div className="row split-form">
                                      <div className="col-md-6">
                                        <label>
                                          Expiration date
            <CardExpiryElement
                                            {...createOptions()}
                                            onChange={props.handleChangeStripe}
                                          />
                                        </label>
                                      </div>
                                      <div className="col-md-6">
                                        <label>
                                          CVC
      <CardCVCElement {...createOptions()} onChange={props.handleChangeStripe} />
                                        </label>
                                      </div>

                                    </div>

                                  </FormGroup>
                                </Col>

                              </Row>

                              <Row className="flex-nowrap">
                                <FormGroup className="col-sm-6 bottom text-left">
                                  <RenderButton
                                    className="btn btn-danger" onClick={() => props.cancelEditBilling(formik)}>Cancel Edit</RenderButton>
                                </FormGroup>
                                <FormGroup className="col-sm-6  bottom text-right">
                                  <SubmitButton className="btn btn-success">Update</SubmitButton>
                                </FormGroup>
                              </Row>


                            </Form>)}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="balanceInfo ">
                  <h3>Balance</h3>
                  <Formik
                    initialValues={props.initialValues}
                    onSubmit={(values) => props.handleBalanceUpdate(values, user)}

                  >
                    {({
                      touched }) => (
                        <Form>
                          <ListGroup>
                            <ListGroupItem>Reports <span>{(props.userDetailsById.data.balance && props.userDetailsById.data.balance.reports) || 0}</span> <i onClick={() => props.toggleBalance('reportsTextField')} className="fa fa-pencil" aria-hidden={true}></i>
                              {props.state.reportsTextField ?
                                <Field
                                  component={RenderTextField}
                                  name="reports"
                                />
                                : null}
                            </ListGroupItem>

                            <ListGroupItem> Posts <span>{(props.userDetailsById.data.balance && props.userDetailsById.data.balance.posts) || 0}</span> <i onClick={() => props.toggleBalance('posts')} className="fa fa-pencil" aria-hidden={true}></i>
                              {props.state.posts ?
                                <Field
                                  component={RenderTextField}
                                  name="posts"
                                />

                                : null}
                            </ListGroupItem>

                            <ListGroupItem> Exports <span>{(props.userDetailsById.data.balance && props.userDetailsById.data.balance.exports) || 0}</span> <i onClick={() => props.toggleBalance('exportsTextField')} className="fa fa-pencil" aria-hidden={true}></i>
                              {props.state.exportsTextField ?
                                <Field
                                  component={RenderTextField}
                                  name="exports"
                                />
                                : null}
                            </ListGroupItem>
                            {props.userDetailsById.data && props.userDetailsById.data.api && props.userDetailsById.data.api.only_credits &&
                              <ListGroupItem>Total Balance <span>{(props.userDetailsById.data.balance && props.userDetailsById.data.balance.total_balance) || 0}</span> <i onClick={() => props.toggleBalance('totalBalanceTextField')} className="fa fa-pencil" aria-hidden={true}></i>
                                {props.state.totalBalanceTextField ?
                                  <Field
                                    component={RenderTextField}
                                    name="totalBalance"
                                  />
                                  : null}
                              </ListGroupItem>
                            }
                          </ListGroup>
                          <FormGroup className="text-center">
                            <SubmitButton disabled={(!touched.exports && !touched.posts && !touched.reports)} className="btn btn-success">Update Balance</SubmitButton>
                          </FormGroup>

                        </Form>
                      )}
                  </Formik>
                </div>
              </div>

            </td>

          </tr>
          : null}
      </Fragment>

    ));
    return tableRowArray;
  }


  return (
    <div className="main-site-content admin-panel-content-wrap">
      <div className="ap-container">
        <div className="table-responsive table-outer admin-table">
          <Table hover borderless>
            <thead>
              <tr>
                <td colSpan="6" className="text-right">
                  <InputGroup className="search-wrap justify-content-end admin-panel-search">
                    <InputGroupAddon addonType="prepend">

                    </InputGroupAddon>
                    <RenderTextField placeholder="Search here" onChange={(event) => props.searchUser(event)} />
                  </InputGroup>
                </td>
              </tr>
              <tr>
                <th>Full Name</th>
                <th>Email Address</th>
                <th className="text-center">Subscription Plan</th>
                <th className="text-center">Balance</th>
                <th className="text-center">Status</th>
                <th className="text-center">API Access</th>

              </tr>
            </thead>
            <tbody>
              {props.userManagementData && props.userManagementData.users && props.userManagementData.users.length ?
                getTableRows()
                : <tr>
                  <td colSpan="6" align="center">
                    No Record Found
                  </td>
                </tr>
              }
            </tbody>
          </Table>
          {props.userManagementData && props.userManagementData.total && props.userManagementData.total > 10 ? <Paginator
            activePage={props.state.activeUserManagementPage}
            itemsPerPage={10}
            totalItems={props.userManagementData.total}
            handlePageChange={(pageNumber) => props.handlePageChange('0', pageNumber)}
          /> : null}
        </div>
      </div>
    </div>
  )
}

export default UserManagementSection;
