import React from "react";
import { Trans } from "react-i18next";

export const audienceGenderOptions = [
  {
    text: <Trans i18nKey="discovery.filters.gender.options.male" />,
    value: "MALE",
  },
  {
    text: <Trans i18nKey="discovery.filters.gender.options.female" />,
    value: "FEMALE",
  },
];
export const influencerGenderOptions = [
  {
    text: <Trans i18nKey="discovery.filters.gender.options.male" />,
    value: "MALE",
  },
  {
    text: <Trans i18nKey="discovery.filters.gender.options.female" />,
    value: "FEMALE",
  },
  {
    text: <Trans i18nKey="discovery.filters.gender.options.male_female" />,
    value: "KNOWN",
  },
  {
    text: <Trans i18nKey="discovery.filters.gender.options.neutral" />,
    value: "UNKNOWN",
  },
];
