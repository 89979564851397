import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  capitalize,
  formatNumbersToCommaSeperated,
  showTitle,
  showTooltip,
} from "../../../../actions";
import { LazyImage, RenderButton } from "../../../../components";
import defaultImg from "../../../../img/user-circle-outline.svg";

const FollowersNotableFollowers = (props) => {
  const { t } = useTranslation();

  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>{t("reports.audience_details.followers.notable")}</CardTitle>
        <div className="card-content ch-9">
          <ListGroup className="card-list-items">
            {props.accountOverviewDetails &&
            props.accountOverviewDetails.audience_followers &&
            props.accountOverviewDetails.audience_followers.data &&
            props.accountOverviewDetails.audience_followers.data
              .notable_users ? (
              props.accountOverviewDetails.audience_followers.data.notable_users.map(
                (follower, index) => {
                  if (index < 3) {
                    return (
                      <ListGroupItem key={index}>
                        <div className="avatar">
                          <LazyImage src={follower.picture} alt="img" />
                        </div>
                        {props.accountOverviewDetails.user_profile &&
                        props.accountOverviewDetails.user_profile.type ===
                          "youtube" ? (
                          <div
                            id={`followers_${follower.fullname}`}
                            title={showTitle(
                              `followers_${follower.fullname}`,
                              follower.fullname
                            )}
                            className="list-content youtube"
                          >
                            {" "}
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={follower.url}
                            >
                              <p>{capitalize(follower.fullname)}</p>
                            </a>
                          </div>
                        ) : (
                          <div
                            id={`followers_${follower.fullname}`}
                            title={showTitle(
                              `followers_${follower.fullname}`,
                              follower.fullname
                            )}
                            className="list-content"
                          >
                            {" "}
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={follower.url}
                            >
                              {"@" + follower.username}
                            </a>
                            <p>{capitalize(follower.fullname)}</p>
                          </div>
                        )}
                      </ListGroupItem>
                    );
                  }
                  return null;
                }
              )
            ) : (
              <span className="text-center text-grey ">
                <p className="pt-50">{t("errors.no_record")}</p>
              </span>
            )}
          </ListGroup>
        </div>
        {props.accountOverviewDetails &&
        props.accountOverviewDetails.audience_followers &&
        props.accountOverviewDetails.audience_followers.data &&
        props.accountOverviewDetails.audience_followers.data.notable_users ? (
          <div className="bottom">
            <Button
              color="link"
              onClick={() => props.toggleModal("followersNotableFiltersModal")}
            >
              {t("reports.view_more")}
            </Button>

            <Modal
              id="followersNotableFiltersModalDownload"
              isOpen={props.modals.followersNotableFiltersModal}
              toggle={() => props.toggleModal("followersNotableFiltersModal")}
            >
              <ModalHeader
                toggle={() => props.toggleModal("followersNotableFiltersModal")}
              >
                <span className="tooltip-button-wrap">
                  <span className="text-tooltip">
                    {t("reports.audience_details.followers.notable")}
                  </span>
                  <span
                    href="#"
                    id="followersNotableFiltersModal"
                    className="tooltip-icon"
                  ></span>
                </span>
                {props.tooltip && (
                  <UncontrolledTooltip
                    placement="bottom"
                    className="tooltip-content"
                    target="followersNotableFiltersModal"
                  >
                    {showTooltip("followersNotableFiltersModal")}
                  </UncontrolledTooltip>
                )}
                <RenderButton
                  onClick={() =>
                    props.downloadPdf("followersNotableFiltersModalDownload")
                  }
                  className="download-icon-button mg-left-15 link"
                >
                  {t("reports.download")}
                </RenderButton>
              </ModalHeader>
              <ModalBody>
                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th colSpan="2">{t("reports.influencer")}</th>
                      <th className="text-right">{t("reports.chart.likes")}</th>
                      <th className="text-right">
                        {t("reports.chart.followers")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.accountOverviewDetails &&
                    props.accountOverviewDetails.audience_followers &&
                    props.accountOverviewDetails.audience_followers.data &&
                    props.accountOverviewDetails.audience_followers.data
                      .notable_users ? (
                      props.accountOverviewDetails.audience_followers.data.notable_users.map(
                        (follower, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row" className="tb-media-left">
                                <img
                                  src={follower.picture}
                                  alt="profile pic"
                                  className="rounded-circle"
                                  onError={(e) => (e.target.src = defaultImg)}
                                />
                              </th>
                              <td className="tb-head">
                                <h4>
                                  {" "}
                                  <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={follower.url}
                                  >
                                    @{follower.username || follower.fullname}
                                  </a>
                                </h4>
                                <div className="country-text-wrap">
                                  <div className="country-name">
                                    {follower.fullname}
                                  </div>
                                </div>
                              </td>
                              <td className="text-right">
                                {formatNumbersToCommaSeperated(
                                  follower.engagements
                                )}
                              </td>
                              <td className="text-right">
                                {formatNumbersToCommaSeperated(
                                  follower.followers
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <tr>
                        <td align="center" colSpan="3">
                          {t("errors.no_record")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default FollowersNotableFollowers;
