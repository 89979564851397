import React from "react";
import { useTranslation } from "react-i18next";
import { RenderButton } from "../../../components";
import { Row, Col, Table, TabPane, Badge } from "reactstrap";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import Paginator from "../../../components/pagination/pagination";
import { formatNumbersToCommaSeperated } from "../../../actions";
import moment from "moment";
import networkImage from "helpers/networkImage";

const showFirst2Items = (data, filters, translate) => {
  let modifiedData = { ...data };
  delete modifiedData.selectedThreeTableColumns;

  return filters &&
    modifiedData.allSelectedFilters &&
    (modifiedData.allSelectedFilters.length ||
      filters.search.relevance.value !== "" ||
      filters.search.audience_relevance.value !== "") ? (
    <span className="past-export-filters">
      {modifiedData.allSelectedFilters.map((filter, ind) => (
        <Badge
          key={ind}
          className={`fs--2 rank-wrap badge-soft-${
            filter.radioButtonValueOfState.toLowerCase().includes("aud")
              ? "neutral"
              : "danger"
          }`}
        >
          {filter.label && filter.label.props
            ? translate(filter.label.props.i18nKey)
            : filter.label}
        </Badge>
      ))}
      {filters.search.relevance &&
        filters.search.relevance.value !== "" &&
        filters.search.relevance.value.split(" ").map((filter, ind) => {
          if (
            filter.includes("@") &&
            modifiedData.allSelectedFilters.some(
              (element) =>
                element.value &&
                typeof element.value === "string" &&
                `@${element.value.trim()}` === filter.trim()
            )
          ) {
            return null;
          }
          return (
            <Badge
              key={`${filter}_${ind}`}
              className={`fs--2 rank-wrap badge-soft-danger`}
            >
              #{filter}
            </Badge>
          );
        })}
    </span>
  ) : (
    <span className="past-export-filters">
      <Badge className={`fs--2 rank-wrap badge-light badge-soft-empty`}>
        {translate("discovery.chart.empty")}
      </Badge>
    </span>
  );
};
const ListsTab = (props) => {
  const { t } = useTranslation();

  return (
    <TabPane tabId="0">
      <Row className="search-results-wrap mb-4">
        <Col md="12">
          <div className="table-responsive table-outer user-profile-table">
            <Table hover borderless className="mb-3">
              <thead>
                <tr>
                  {/* <th>
                    <div className="edit-and-sort-buttons">
                      <UncontrolledDropdown className="float-left">
                        <DropdownToggle color="link" caret className="sortby-button user-profile-sort-btn p-0">
                          Sort by date
                    </DropdownToggle>
                        <DropdownMenu className="ddmenu-col">
                          <DropdownItem>a</DropdownItem>
                          <DropdownItem>b</DropdownItem>
                          <DropdownItem>a</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </th> */}
                  <th width="20%" className="my-reports">
                    {t("discovery.chart.title")}
                  </th>
                  <th width="45%" className="text-left mobile-view-past-report">
                    {t("discovery.chart.filters")}
                    <span className="audience-badge-legend"></span>
                    <span className="influencer-badge-legend"></span>
                  </th>
                  {/*<th width="10%" className="text-left mobile-view-past-report">
                    Exports Used
                  </th>*/}
                  <th width="15%" className="text-left mobile-view-past-report">
                    {t("discovery.chart.date")}
                  </th>
                  <th width="15%" />
                  <th width="5%" />
                </tr>
              </thead>
              <tbody>
                {props.pastExportsLists &&
                props.pastExportsLists.lists_generated &&
                props.pastExportsLists.lists_generated.length ? (
                  props.pastExportsLists.lists_generated.map((item, index) => (
                    <tr key={index}>
                      <td className="past-export-platform">
                        {networkImage(item.type)}
                        <Badge className="badge-light badge-soft-empty exports-used">
                          {formatNumbersToCommaSeperated(item.exports_used)}
                        </Badge>
                        {item.demo ? (
                          <Badge className="fs--2 badge-soft-neutral ml-3">
                            {t("discovery.chart.example")}
                          </Badge>
                        ) : null}
                      </td>
                      <td>{showFirst2Items(item.data, item.filters, t)}</td>
                      {/*<td>
                        {formatNumbersToCommaSeperated(item.exports_used)}
                      </td>*/}
                      <td className="mobile-view-past-report">
                        {!item.demo &&
                          moment(item.created).format("MMMM DD, YYYY")}
                      </td>
                      <td className="mobile-view-past-report">
                        <RenderButton
                          onClick={() =>
                            props.searchAgain({
                              data: item.data,
                              filters: item.filters,
                            })
                          }
                          className="btn btn-outline-primary"
                        >
                          {t("discovery.chart.search")}
                        </RenderButton>
                      </td>
                      <td>
                        <RenderButton
                          onClick={() => props.handleExport(item.id)}
                          className="btn btn-primary btn-export-data"
                        >
                          <span className="export-icon">
                            <ArrowDownTrayIcon width={20} height={25} />
                          </span>
                        </RenderButton>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" align="center">
                      {t("errors.no_record")}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {props.pastExportsLists &&
            props.pastExportsLists.total &&
            props.pastExportsLists.total > props.limit ? (
              <Paginator
                activePage={props.state.activeListsPage}
                itemsPerPage={10}
                totalItems={props.pastExportsLists.total}
                handlePageChange={(pageNumber) =>
                  props.handlePageChange(pageNumber, "LISTS")
                }
              />
            ) : null}
          </div>
        </Col>
      </Row>
    </TabPane>
  );
};

export default ListsTab;
