import React from "react";
import Pagination from "react-js-pagination";
import { FormGroup } from "reactstrap";
import { RenderSingleSelect } from "..";
import { Field } from "formik";
import { items_per_page } from "helpers/itemsCountPerPageOptions";

import "./index.scss";

const Paginator = (props) => {
  return (
    <div className="paginator">
      <div className="paginator-section">
        <Pagination
          activePage={props.activePage}
          itemsCountPerPage={props.itemsPerPage}
          totalItemsCount={props.totalItems}
          pageRangeDisplayed={5}
          onChange={props.handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
      {props.state?.pageSize && (
        <FormGroup className="paginator-section page-size-field follower-col">
          <Field
            component={RenderSingleSelect}
            name="itemsPerPage"
            value={props.state.pageSize}
            onChange={(input) => props.handlePageSizeChange(input)}
            options={items_per_page}
          />
        </FormGroup>
      )}
    </div>
  );
};
export default Paginator;
