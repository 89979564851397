import {
  actionCreator,
  createQueryParams,
  privateHeaders,
  checkHttpStatus,
  COMMON_ERROR_MESSAGE,
  checkAuthTokenExpiry,
  CHECKOUT_APP_EXTERNAL_URL,
} from "../../../actions";
import {
  reportsActionTypes,
  GET_ACCOUNT_OVERVIEW_URL,
  STORY_ANALYSIS_URL,
  GET_CLOUD_TAGS,
  GET_AVAILABLE_ACCOUNTS,
  GET_INFLUENCER_PROFILE_DATA_BY_SEARCH,
} from "../constants";
import { signOut, createCheckoutSession } from "../../login/actions";
import { getUserBalanceData } from "../../header/actions";

export const getAccountOverview = (reportId, clearFields) => {
  return (dispatch, getState) => {
    if (clearFields === true) {
      dispatch(
        actionCreator(reportsActionTypes.getAccountOverview.SUCCESS, null)
      );

      dispatch(getStoryAnalysis(null, clearFields));
    } else {
      dispatch(actionCreator(reportsActionTypes.getAccountOverview.REQUEST));
      const fullUrl = new URL(`${GET_ACCOUNT_OVERVIEW_URL}/${reportId}`);
      fullUrl.searchParams.append('locale', process.env.REACT_APP_LOCALE);
      fetch(fullUrl, {
        method: "get",
        headers: privateHeaders(getState),
      })
        .then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response))
        .then((response) => {
          if (response.success) {
            dispatch(
              actionCreator(
                reportsActionTypes.getAccountOverview.SUCCESS,
                response
              )
            );
            let storyObj = {
              user: response.user_profile && response.user_profile.username,
              follower_count:
                response.user_profile && response.user_profile.followers,
            };

            if (
              response.user_profile.type !== "tiktok" &&
              response.user_profile.type !== "youtube" &&
              !response.user_profile.story_posts
            ) {
              dispatch(getStoryAnalysis(storyObj));
            } else {
              dispatch(
                actionCreator(
                  reportsActionTypes.getStoryAnalysis.SUCCESS,
                  response.user_profile.story_posts
                )
              );
            }

            let cloudSearchbj = {
              tag: `@${
                response.user_profile.username || response.user_profile.user_id
              }`,
              platform: response.user_profile.type,
            };
            dispatch(getCloudTags(cloudSearchbj));
          } else {
            let clearFields = true;
            dispatch(getCloudTags(null, clearFields));
            let reportsErrorMessage = response.message || COMMON_ERROR_MESSAGE;
            dispatch(
              actionCreator(
                reportsActionTypes.showReportsErrorMessage.SUCCESS,
                reportsErrorMessage
              )
            );
            if (response.redirect === "PAYMENT_PAGE") {
              // history.push('/subscription');
              if (
                getState().login.checkoutSessionDetails &&
                getState().login.checkoutSessionDetails._id
              ) {
                window.open(
                  `${CHECKOUT_APP_EXTERNAL_URL}/?session=${
                    getState().login.checkoutSessionDetails._id
                  }`,
                  "_self"
                );
              } else {
                dispatch(createCheckoutSession());
              }
            }
          }
        })
        .catch((error) => {
          let reportsErrorMessage = COMMON_ERROR_MESSAGE;
          dispatch(
            actionCreator(
              reportsActionTypes.showReportsErrorMessage.SUCCESS,
              reportsErrorMessage
            )
          );
          dispatch(
            actionCreator(reportsActionTypes.getAccountOverview.FAILURE)
          );
          if (
            error.statusCode &&
            error.statusCode >= 400 &&
            error.statusCode <= 500
          ) {
            dispatch(signOut(error));
          }
        });
    }
  };
};
export const getCloudTags = (searchObj, clearFields) => {
  return (dispatch, getState) => {
    if (clearFields === true) {
      dispatch(actionCreator(reportsActionTypes.getCloudTags.SUCCESS, null));
    } else {
      let queryParams = createQueryParams(searchObj);
      dispatch(actionCreator(reportsActionTypes.getCloudTags.REQUEST));
      fetch(`${GET_CLOUD_TAGS}${queryParams}`, {
        method: "get",
        headers: privateHeaders(getState),
      })
        .then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response))
        .then((response) => {
          dispatch(
            actionCreator(reportsActionTypes.getCloudTags.SUCCESS, response)
          );
        })
        .catch((error) => {
          dispatch(actionCreator(reportsActionTypes.getCloudTags.FAILURE));
          if (
            error.statusCode &&
            error.statusCode >= 400 &&
            error.statusCode <= 500
          ) {
            dispatch(signOut(error));
          }
        });
    }
  };
};

export const getInfluencerProfileDataBySearch = (
  postData,
  clearFields,
  userId,
  toggleViewReportModal,
  newReportTab
) => {
  return (dispatch, getState) => {
    if (clearFields === true) {
      dispatch(
        actionCreator(
          reportsActionTypes.getInfluencerProfileDataBySearch.SUCCESS,
          null
        )
      );
    } else {
      dispatch(
        actionCreator(
          reportsActionTypes.getInfluencerProfileDataBySearch.REQUEST
        )
      );
      const fullUrl = new URL(GET_INFLUENCER_PROFILE_DATA_BY_SEARCH);
      fullUrl.searchParams.append('locale', process.env.REACT_APP_LOCALE);
      fetch(fullUrl, {
        method: "POST",
        headers: privateHeaders(getState),
        body: JSON.stringify(postData),
      })
        .then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response))
        .then((response) => {
          if (
            response.success &&
            response.report_info &&
            response.report_info.report_id
          ) {
            response.toggleViewReportModal = toggleViewReportModal
              ? true
              : false;
            dispatch(
              actionCreator(
                reportsActionTypes.getInfluencerProfileDataBySearch.SUCCESS,
                response
              )
            );
            let cloudSearchbj = { tag: `@${response.user_profile.username}` };
            let storyObj = {
              user: response.user_profile && response.user_profile.username,
              follower_count:
                response.user_profile && response.user_profile.followers,
            };
            dispatch(getCloudTags(cloudSearchbj));

            if (userId) {
              dispatch(getUserBalanceData(userId));
            }
            if (
              response.user_profile.type !== "tiktok" &&
              response.user_profile.type !== "youtube" &&
              !response.user_profile.story_posts
            ) {
              dispatch(getStoryAnalysis(storyObj));
            } else {
              dispatch(
                actionCreator(
                  reportsActionTypes.getStoryAnalysis.SUCCESS,
                  response.user_profile.story_posts
                )
              );
            }

            if (newReportTab) {
              const reportUrl = `${process.env.REACT_APP_URL}/reports/${response.report_info.report_id}`;
              newReportTab.location.href = reportUrl;
              newReportTab.focus();
              return;
            }
            // history.push(`/reports/${response.report_info.report_id}`);
          } else {
            let clearFields = true;
            dispatch(getCloudTags(null, clearFields));
            let reportsErrorMessage =
              response.error_message ||
              response.message ||
              COMMON_ERROR_MESSAGE;

            if (newReportTab) {
              newReportTab.close();
            }

            dispatch(
              actionCreator(
                reportsActionTypes.showReportsErrorMessage.SUCCESS,
                reportsErrorMessage
              )
            );
            if (response.redirect === "PAYMENT_PAGE") {
              // history.push('/subscription');
              if (
                getState().login.checkoutSessionDetails &&
                getState().login.checkoutSessionDetails._id
              ) {
                window.open(
                  `${CHECKOUT_APP_EXTERNAL_URL}/?session=${
                    getState().login.checkoutSessionDetails._id
                  }`,
                  "_self"
                );
              } else {
                dispatch(createCheckoutSession());
              }
            }
            response.toggleViewReportModal = false;
            dispatch(
              actionCreator(
                reportsActionTypes.getInfluencerProfileDataBySearch.SUCCESS,
                response
              )
            );
          }
        })
        .catch((error) => {
          let reportsErrorMessage = COMMON_ERROR_MESSAGE;
          dispatch(
            actionCreator(
              reportsActionTypes.showReportsErrorMessage.SUCCESS,
              reportsErrorMessage
            )
          );
          dispatch(
            actionCreator(
              reportsActionTypes.getInfluencerProfileDataBySearch.FAILURE
            )
          );
          if (
            error.statusCode &&
            error.statusCode >= 400 &&
            error.statusCode <= 500
          ) {
            dispatch(signOut(error));
          }
        });
    }
  };
};
export const clearReportsErrorMsg = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(reportsActionTypes.clearReportsErrorMessage.SUCCESS)
    );
  };
};

export const toggleViewModal = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(reportsActionTypes.toggleViewModal.SUCCESS));
  };
};

export const showLoadingAction = (action) => {
  return (dispatch, getState) => {
    if (action.toLowerCase() === "start") {
      dispatch(actionCreator(reportsActionTypes.showLoading.REQUEST));
    } else if (action.toLowerCase() === "stop") {
      dispatch(actionCreator(reportsActionTypes.showLoading.SUCCESS));
    }
  };
};

export const getStoryAnalysis = (storyObj, clearFields) => {
  return (dispatch, getState) => {
    if (clearFields === true) {
      dispatch(
        actionCreator(reportsActionTypes.getStoryAnalysis.SUCCESS),
        null
      );
    } else {
      dispatch(actionCreator(reportsActionTypes.getStoryAnalysis.REQUEST));
      fetch(`${STORY_ANALYSIS_URL}?username=${storyObj.user}`, {
        method: "GET",
        headers: privateHeaders(getState),
      })
        .then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response))
        .then((response) => {
          if (response.success) {
            dispatch(
              actionCreator(
                reportsActionTypes.getStoryAnalysis.SUCCESS,
                response.data
              )
            );
          } else {
            dispatch(
              actionCreator(reportsActionTypes.getStoryAnalysis.SUCCESS, null)
            );
          }
        })
        .catch((error) => {
          dispatch(
            actionCreator(reportsActionTypes.getStoryAnalysis.SUCCESS, null)
          );
          if (
            error.statusCode &&
            error.statusCode >= 400 &&
            error.statusCode <= 500
          ) {
            dispatch(signOut(error));
          }
        });
    }
  };
};
export const clearSubscriptionErrorAction = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(reportsActionTypes.clearSubscriptionError.SUCCESS));
  };
};
export const showPlansErrorInReports = (errorMsg) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(reportsActionTypes.showSubscriptionError.SUCCESS, errorMsg)
    );
  };
};

export const searchAvailableAccounts = (searchData) => {
  return (dispatch, getState) => {
    if (searchData.query === null) {
      dispatch(
        actionCreator(reportsActionTypes.getAvailableAccounts.SUCCESS, [])
      );
    } else {
      fetch(
        `${GET_AVAILABLE_ACCOUNTS}?q=${searchData.query}&platform=${searchData.platform}`,
        {
          method: "GET",
          headers: privateHeaders(getState),
        }
      )
        .then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response))
        .then((response) => {
          dispatch(
            actionCreator(
              reportsActionTypes.getAvailableAccounts.SUCCESS,
              response.data
            )
          );
        })
        .catch((error) => {
          if (
            error.statusCode &&
            error.statusCode >= 400 &&
            error.statusCode <= 500
          ) {
            dispatch(signOut(error));
          }
        });
    }
  };
};

export const searchAvailableAccountsGlobal = (searchData) => {
  return (dispatch, getState) => {
    if (searchData.query === null) {
      dispatch(
        actionCreator(reportsActionTypes.getAvailableAccounts.SUCCESS, [])
      );
    } else {
      fetch(`${GET_AVAILABLE_ACCOUNTS}/global?q=${searchData.query}`, {
        method: "GET",
        headers: privateHeaders(getState),
      })
        .then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response))
        .then((response) => {
          dispatch(
            actionCreator(
              reportsActionTypes.getAvailableAccounts.SUCCESS,
              response.data
            )
          );
        })
        .catch((error) => {
          if (
            error.statusCode &&
            error.statusCode >= 400 &&
            error.statusCode <= 500
          ) {
            dispatch(signOut(error));
          }
        });
    }
  };
};
