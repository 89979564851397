import React from "react";
import { Row, Col, Table, TabPane, Spinner } from "reactstrap";
import moment from "moment";
import { formatNumbersToCommaSeperated } from "../../actions";
import { RenderButton } from "../../components";

/*const img_default =
  "https://cdn5.vectorstock.com/i/thumb-large/45/79/male-avatar-profile-picture-silhouette-light-vector-4684579.jpg";*/

const statusType = (status) => {
  let type;
  switch (status) {
    case "Pending":
      type = "pending";
      break;
    case "Failed":
      type = "failed";
      break;
    case "Download":
      type = "download";
      break;

    default:
      break;
  }

  return type;
};

export default function table(props) {
  const { tensorLists } = props;

  return (
    <TabPane tabId="1">
      <Row className="search-results-wrap tensor-list">
        <Col md="12">
          <div className="table-responsive table-outer user-profile-table">
            <Table hover borderless>
              <thead>
                <tr>
                  <th width="25%">Account</th>
                  <th width="15%" className="text-left">
                    Followers
                  </th>
                  <th width="15%" className="text-left">
                    Emails
                  </th>
                  <th width="25%" className="text-left">
                    Date
                  </th>
                  <th width="15%"></th>
                </tr>
              </thead>
              <tbody>
                {tensorLists ? (
                  tensorLists.map((report, index) => (
                    <tr key={index}>
                      <td className="tb-head">
                        {/*<img
                          src={
                            report.profile_pic
                              ? report.profile_pic
                              : img_default
                          }
                          alt="profile pic"
                          width="50px"
                          height="50px"
                          className="rounded-circle mr-3"
                        />*/}
                        <div className="d-flex">
                          <h4 className="my-auto">{`@${report.username}`}</h4>
                        </div>
                      </td>
                      <td className="text-left">
                        {formatNumbersToCommaSeperated(report.followers)}
                      </td>
                      <td className="text-left">
                        {formatNumbersToCommaSeperated(report.emails)}
                      </td>
                      <td className="text-left">
                        {moment(report.date).format("MMMM DD, YYYY")}
                      </td>
                      <td align="center">
                        {statusType(report.status) === "pending" && (
                          <Spinner
                            className="spinner"
                            animation="border"
                            variant="primary"
                          />
                        )}
                        {statusType(report.status) !== "pending" && (
                          <a
                            href={report.download_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                          >
                            <RenderButton
                              disabled={
                                statusType(report.status) === "pending" ||
                                statusType(report.status) === "failed"
                              }
                              // onClick={() => console.log('view')}
                              className={`btn action-button status-button-${statusType(
                                report.status
                              )}`}
                            >
                              {report.status}
                            </RenderButton>
                          </a>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" align="center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </TabPane>
  );
}
