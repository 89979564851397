import React, { useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { Formik, Form, Field } from "formik";
import {
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
  // PencilIcon,
} from "@heroicons/react/20/solid";
import { SharedTag } from "components/tags";
import { RenderTextFieldWithoutAutoComplete, SubmitButton } from "components";
import CreatorListModalTable from "./creatorListModalTable";
import CreatorListsDropdown from "../creatorListsDropdown";
import { formatNumbersToUnits } from "../../../actions";
import { deleteCreator, updateCreatorListName } from "../actions";
import { getInfluencerProfileDataBySearch } from "../../reports/actions/index";
import noimg from "../../../img/MaskGroup.png";
import defaultImg from "../../../img/user-circle-outline.svg";
import { connect } from "react-redux";
import "./index.scss";
import { postForExportCSV } from "../../identification/actions/index";
import networkImage from "helpers/networkImage";
import { saveListSearchFilters } from "../../userProfile/actions/index";
import history from "../../../history";

const CreatorListModal = ({
  loginUser,
  getInfluencerProfileDataBySearch,
  postForExportCSV,
  deleteCreatorAction,
  deleteCreatorList,
  creatorList,
  isOpen,
  toggleModal,
  saveListSearchFilters,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [creatorListName, setCreatorListName] = useState(creatorList?.name);

  const [isExportDropdownOpen, setIsExportDropdownOpen] = useState(false);
  const [isDiscoveryDropdownOpen, setIsDiscoveryDropdownOpen] = useState(false);

  const toggleExportDropdown = () =>
    setIsExportDropdownOpen((prevState) => !prevState);
  const toggleDiscoveryDropdown = () =>
    setIsDiscoveryDropdownOpen((prevState) => !prevState);

  const platformOptions = [
    {
      label: "Instagram",
      value: "instagram",
      icon: networkImage("instagram"),
    },
    {
      label: "YouTube",
      value: "youtube",
      icon: networkImage("youtube"),
    },
    {
      label: "TikTok",
      value: "tiktok",
      icon: networkImage("tiktok"),
    },
  ];

  const handleExport = (platform) => {
    postForExportCSV(
      {
        creatorList_id: creatorList._id,
        platform,
        paging: {
          limit: creatorList.totalCreators,
        },
        export_type: "csv",
      },
      true
    );
  };

  const handleAnalyze = (creator) => {
    let searchObj = {
      username: creator.handle,
      platform: creator.network,
    };
    const newTab = window.open("/loading", "_blank");
    getInfluencerProfileDataBySearch(
      searchObj,
      null,
      loginUser && loginUser.id,
      false,
      newTab
    );
  };

  const cancelEdit = () => {
    setCreatorListName(creatorList?.name);
    setIsEditing(false);
  };

  const updateCreatorListNameHandler = () => {
    props.updateCreatorListName({
      _id: creatorList._id,
      name: creatorListName,
    });
    setIsEditing(false);
  };

  const deleteCreatorHandler = (creator_id) => {
    const creatorData = {
      _id: creatorList._id,
      creator_id,
    };
    deleteCreatorAction(creatorData);
  };

  const handleSearch = (platform) => {
    const searchObj = {
      data: {
        creatorList_id: creatorList._id,
        platformValue: {
          value: platform,
        },
      },
      filters: {
        search: {
          account_type: [],
          relevance: {},
          text: "",
          audience_relevance: {
            value: "",
          },
        },
        sort: {
          field: "followers",
          direction: "desc",
        },
      },
    };
    saveListSearchFilters(searchObj);
    history.push("/discovery");
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      wrapClassName="creator-list-modal creator-lists"
      modalClassName="right"
    >
      <ModalHeader toggle={toggleModal}>
        <Row className="d-flex align-items-center justify-content-between">
          {isEditing ? (
            <Formik onSubmit={updateCreatorListNameHandler}>
              {() => (
                <Form className="creator-list-form">
                  <InputGroup className="d-flex align-items-center">
                    <Field
                      autoFocus
                      id="creator-list-field"
                      name="creatorListName"
                      className="col-4"
                      customvalue={creatorListName}
                      onChange={(e) => {
                        setCreatorListName(e.target.value);
                      }}
                      component={RenderTextFieldWithoutAutoComplete}
                    />

                    <SubmitButton color="none" className="ml-3">
                      <CheckCircleIcon
                        className="check-icon"
                        width={30}
                        height={30}
                      />
                    </SubmitButton>

                    <XCircleIcon
                      className="ml-2 close-icon"
                      onClick={() => cancelEdit()}
                      width={30}
                      height={30}
                    />
                  </InputGroup>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="d-flex align-items-center">
              <span className="page-title">{creatorListName}</span>
              <div className="social-icons">
                {creatorList?.networks?.map((network, index) => (
                  <div className="social-icon-round" key={index}>
                    {networkImage(network)}
                  </div>
                ))}
              </div>
              <div className="share-icon">
                {(creatorList?.isShared ||
                  creatorList?.sharedWithUser_ids.length > 0) && <SharedTag />}
                {/*!creatorList?.isShared && (
                  <PencilIcon
                    className="edit-icon ml-4"
                    onClick={() => setIsEditing(true)}
                    width={25}
                    height={25}
                  />
                )*/}
              </div>
            </div>
          )}
          <div className="creator-actions">
            {creatorList && !creatorList?.isShared ? (
              <CreatorListsDropdown
                className="mr-4"
                dropdownStyle="button"
                creatorList={creatorList}
                deleteCreatorList={deleteCreatorList}
              />
            ) : null}
            <Dropdown
              toggle={() => toggleDiscoveryDropdown()}
              isOpen={isDiscoveryDropdownOpen}
              disabled={!creatorList?.creators?.length}
            >
              <DropdownToggle
                className="btn btn-primary"
                disabled={!creatorList?.creators?.length}
              >
                <MagnifyingGlassIcon className="mr-2" height={18} width={18} />
                Search
              </DropdownToggle>
              <DropdownMenu className="button-dropdown-primary-test ddmenu-col m-0 p-0">
                {platformOptions
                  ?.filter((platformOption) =>
                    creatorList?.networks?.includes(platformOption?.value)
                  )
                  .map((platformOption) => {
                    return (
                      <DropdownItem
                        key={platformOption?.value}
                        onClick={() => handleSearch(platformOption?.value)}
                        disabled={
                          !creatorList?.networks?.includes(
                            platformOption?.value
                          )
                        }
                      >
                        <div className="input-select__icon">
                          <span>
                            {platformOption?.icon || ""}{" "}
                            <span>{platformOption?.label}</span>
                          </span>
                        </div>
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </Row>
      </ModalHeader>
      <ModalBody className="confirm-modal">
        <Row>
          <Col sm="6" md="3">
            <Card className="card-content-custom">
              <CardBody>
                <h2>{formatNumbersToUnits(creatorList?.totalCreators)}</h2>
                <CardTitle>
                  <span className="tooltip-button-wrap">
                    <span className="text-tooltip text-medium text-grey">
                      Creators
                    </span>
                  </span>
                </CardTitle>
                <div className="creators d-none d-lg-flex">
                  <div className="account-images">
                    {creatorList?.creators.slice(0, 3).map((influencer) => (
                      <div className="account-image" key={influencer._id}>
                        <img
                          src={
                            influencer.imageUrl ? influencer.imageUrl : noimg
                          }
                          alt="profile pic"
                          width="45px"
                          height="45px"
                          className="rounded-circle"
                          onError={(e) => (e.target.src = defaultImg)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6" md="3">
            <Card className="card-content-custom">
              <CardBody>
                <h2>{formatNumbersToUnits(creatorList?.totalReach)}</h2>
                <CardTitle>
                  <span className="tooltip-button-wrap">
                    <span className="text-tooltip text-medium text-grey">
                      Reach
                    </span>
                  </span>
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
          {creatorList?.totalEngagements > 0 ? (
            <Col sm="6" md="3">
              <Card className="card-content-custom">
                <CardBody>
                  <h2>{formatNumbersToUnits(creatorList?.totalEngagements)}</h2>
                  <CardTitle>
                    <span className="tooltip-button-wrap">
                      <span className="text-tooltip text-medium text-grey">
                        Engagements
                      </span>
                    </span>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          ) : null}

          <Col md="12" className="my-3 ml-2">
            <div className="actions">
              <Dropdown
                toggle={() => toggleExportDropdown()}
                isOpen={isExportDropdownOpen}
                disabled={!creatorList?.creators?.length}
              >
                <DropdownToggle
                  className="btn btn-primary mx-3"
                  disabled={!creatorList?.creators?.length}
                >
                  <ArrowDownTrayIcon height={18} width={18} />
                </DropdownToggle>
                <DropdownMenu className="button-dropdown-primary-test ddmenu-col m-0 p-0">
                  {platformOptions
                    ?.filter((platformOption) =>
                      creatorList?.networks?.includes(platformOption?.value)
                    )
                    .map((platformOption) => {
                      return (
                        <DropdownItem
                          key={platformOption?.value}
                          onClick={() => handleExport(platformOption?.value)}
                          disabled={
                            !creatorList?.networks?.includes(
                              platformOption?.value
                            )
                          }
                        >
                          <div className="input-select__icon">
                            <span>
                              {platformOption?.icon || ""}{" "}
                              <span>{platformOption?.label}</span>
                            </span>
                          </div>
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            </div>
          </Col>
          <Col md="12">
            <CreatorListModalTable
              creators={creatorList?.creators}
              deleteCreator={deleteCreatorHandler}
              handleAnalyze={handleAnalyze}
            />
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default connect(null, {
  getInfluencerProfileDataBySearch,
  postForExportCSV,
  deleteCreatorAction: deleteCreator,
  updateCreatorListName,
  saveListSearchFilters,
})(CreatorListModal);
