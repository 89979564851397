import React from "react";
import { Container, Row, Col, InputGroup, Badge } from "reactstrap";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import { RenderTextField, SubmitButton, RenderButton } from "../../components";
import "./splashScreen.scss";
import { showTitle } from "../../actions";
import { getUserBalanceData } from "../header/actions/index";
import {
  getPostDataById,
  getNewPostByLink,
  clearLikesLoaded,
  generateNewPost,
} from "./actions/index";
import history from "../../history";
import ViewPostConfirmationModal from "./viewPostConfirmationModal";
import InfulencersSectionSearch from "../common/infulenceReport";
import PostsTab from "../userProfile/pastExportsTabs/postsTab";
import DeleteModal from "../userProfile/deleteModal";
import { getPastExportsPosts } from "../userProfile/actions/index";
class SplashScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirmationModal: false,
      newArr: [],
      isModalOpen: false,
      postPage: 1,
    };
    this.formikSplash = React.createRef();
    this.removeRecord = this.removeRecord.bind(this);
  }
  componentDidMount() {
    this.props.getPastExportsPosts(this.state.postPage);
  }
  componentWillUnmount() {
    this.props.clearLikesLoaded();
  }
  onSubmit = (values) => {
    let arr = [...this.state.newArr];
    if (values.postLink !== "") {
      arr.push(values.postLink);
      this.setState({
        newArr: arr,
      });
    }

    // let postData = {
    //   post: values.postLink && values.postLink.trim().length ? [values.postLink.trim()] : []
    // }
    let postData = {
      post: arr,
    };
    this.formikSplash.current.resetForm({
      postLink: "",
    });
    this.props.getNewPostByLink(postData);
  };

  handleEnterKey = (event) => {
    if (event.charCode === 13) {
      let arr = [...this.state.newArr];
      if (event.target.value !== "") {
        arr.push(event.target.value);
        this.setState({
          newArr: arr,
        });
      }
      this.formikSplash.current.resetForm({
        postLink: "",
      });
    }
  };
  removeRecord = (i) => {
    let arr = [...this.state.newArr];
    arr.splice(i, 1);
    this.setState({
      newArr: arr,
    });
  };
  handleGenerateReport = () => {
    this.props.generateNewPost(this.props.likesLoaded.postData);
  };
  toggleConfirmationModal = () => {
    this.props.clearLikesLoaded();
  };

  handlePageChange = (pageNumber) => {
    this.setState({
      activePostsPage: pageNumber,
    });
    this.props.getPastExportsPosts(pageNumber);
  };

  handlePostAnalyze = (post) => {
    console.log(post);
    history.push(`/campaigns/${post.id}`);
    this.props.getPostDataById(post.id);
  };

  deletePost = (id) => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      isDeleteId: id,
      isDeleteType: "postType",
    }));
  };

  toggleCancelDeleteModal = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  confirmDelete = (e) => {
    const { isDeleteId, activePostsPage, activeListsPage, activeReportsPage } =
      this.state;

    this.props.deleteListIdAction({
      listId: isDeleteId,
      pageNumber: activeListsPage,
    });
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      isDeleteId: "",
      isDeleteType: "",
      activeListsPage: activeListsPage,
      activePostsPage: activePostsPage,
      activeReportsPage: activeReportsPage,
    }));
  };

  render() {
    return (
      <Container className="main-site-content splash">
        <DeleteModal
          openDelete={this.state.isModalOpen}
          toggleCancelDeleteModal={this.toggleCancelDeleteModal}
          confirmDelete={this.confirmDelete}
        />
        <Row>
          <Col className="is-search-block search-wrap">
            <InfulencersSectionSearch />
          </Col>
        </Row>
        {this.props.likesLoaded ? (
          <ViewPostConfirmationModal
            toggleConfirmationModal={this.toggleConfirmationModal}
            handleGenerateReport={this.handleGenerateReport}
            postsBalance={
              this.props.userBalanceData && this.props.userBalanceData.posts
            }
            {...this.props}
          />
        ) : null}
        <Row>
          <Col className="is-search-block search-wrap">
            <h1 className="page-title">
              Campaign Results{" "}
              <Badge
                color="success"
                className="badge-soft-success fs--2 ml-2 my-auto"
              >
                $4.99 / 1k likes
              </Badge>
            </h1>
            <p className="sub-text text-grey">
              Enter the link to any Instagram URL (Must be a public account) to
              view the demographic, geographic, psychographic, and performance
              insights of an individual post.
            </p>
            <Formik
              initialValues={this.props.initialValues}
              onSubmit={this.onSubmit}
              ref={this.formikSplash}
            >
              {() => (
                <Form className="is-searchform">
                  <InputGroup>
                    <div className="input-checkbox-wrap">
                      <Field
                        name="postLink"
                        className="col-12"
                        onKeyPress={this.handleEnterKey}
                        placeholder="Enter instagram post link..."
                        component={RenderTextField}
                      />
                    </div>

                    <SubmitButton
                      className="search-field-button"
                      color="primary"
                    >
                      Analyze
                    </SubmitButton>
                  </InputGroup>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
        <Row>
          <Col md="4" className="col active-filters-wrap ">
            <div className="active-filters inside">
              <ul className="list-inline ul-width">
                {this.state.newArr.map((val, index) => {
                  return (
                    <li
                      id={val + index}
                      key={index}
                      className="d-block btn-padding"
                      title={showTitle(val + index, val)}
                    >
                      {val}
                      <RenderButton
                        close
                        onClick={() => this.removeRecord(index)}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </Col>
        </Row>

        {this.props.pastExportsPosts?.posts_generated &&
          this.props.pastExportsPosts?.posts_generated.length > 0 && (
            <PostsTab
              {...this.props}
              handlePageChange={this.handlePageChange}
              handlePostAnalyze={this.handlePostAnalyze}
              state={this.state}
              deletePost={this.deletePost}
            />
          )}
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    pastExportsPosts: state.userProfile.pastExportsPosts,
    likesLoaded: state.postAnalysis.likesLoaded,
    loginUser: state.login.loginUser,
    userBalanceData: state.header.UserBalanceData,
    initialValues: {
      postLink: "",
    },
  };
};
export default connect(mapStateToProps, {
  getNewPostByLink,
  getUserBalanceData,
  clearLikesLoaded,
  generateNewPost,
  getPastExportsPosts,
  getPostDataById,
})(SplashScreen);
