import {
  actionCreator,
  checkHttpStatus,
  publicHeaders,
  SIGNUP_SUCCESS_MESSAGE,
  COMMON_ERROR_MESSAGE,
} from "../../../actions";
import {
  signupActionTypes,
  SIGNUP_URL,
  EMAIL_VERIFICATION_CONFIRMATION,
} from "../constants";
import { createCheckoutSession, retrieveLoginUser } from "../../login/actions/index";
export const signup = (authData, createSessionData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(signupActionTypes.signup.REQUEST));
    fetch(SIGNUP_URL, {
      method: "POST",
      headers: publicHeaders,
      body: JSON.stringify(authData),
    })
      .then(checkHttpStatus)
      .then((response) => {
        if (response.error) {
          let errorMsg =
            response.error[Object.keys(response.error)[0]] ||
            COMMON_ERROR_MESSAGE;
          dispatch(
            actionCreator(signupActionTypes.signup.SUCCESS, {
              errorMsg: errorMsg,
            })
          );
        } else {
          response.successMsg = SIGNUP_SUCCESS_MESSAGE;
          dispatch(actionCreator(signupActionTypes.signup.SUCCESS, response));
          dispatch(retrieveLoginUser());
          dispatch(createCheckoutSession(false, createSessionData));
        }
      })
      .catch((error) => {
        dispatch(actionCreator(signupActionTypes.signup.FAILURE));
      });
  };
};
export const clearErrorMsg = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(signupActionTypes.clearErrorMsg.SUCCESS));
  };
};
export const clearSuccessMsg = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(signupActionTypes.clearSuccessMsg.SUCCESS));
  };
};

export const clearLoading = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(signupActionTypes.clearLoading.SUCCESS));
  };
};

export const emailVerificationConfirmation = (postData) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(signupActionTypes.emailVerificationConfirmation.REQUEST)
    );
    fetch(EMAIL_VERIFICATION_CONFIRMATION, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: publicHeaders,
    })
      .then(checkHttpStatus)
      .then((response) => {
        dispatch(
          actionCreator(
            signupActionTypes.emailVerificationConfirmation.SUCCESS,
            response
          )
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(signupActionTypes.emailVerificationConfirmation.FAILURE)
        );
      });
  };
};
