import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { RenderButton } from '../../components';

const DeleteModal = (props) => {
  return (<><Modal isOpen={props.openDelete} toggle={props.toggleCancelDeleteModal}>
    <ModalHeader toggle={props.toggleCancelDeleteModal}>
      <div className="text-center pt-4 unlock-more-modal-heading-section">
        <div className="unlock-more-modal-heading">Confirmation</div>
        {/* <p className="unlock-more-modal-subheading mb-1">{`Please confirm to delete`}<span></span></p> */}
      </div>
    </ModalHeader>
    <ModalBody>
      <p className="text-center">Please confirm to delete</p>
      <div className="d-flex justify-content-center pt-3 pb-4">
        <RenderButton className="btn btn-primary mr-1" onClick={props.confirmDelete}>Delete</RenderButton>
        <RenderButton className="btn btn-outline-primary ml-1" onClick={props.toggleCancelDeleteModal}> Cancel</RenderButton>
      </div>
    </ModalBody>

  </Modal></>)
}
export default DeleteModal;