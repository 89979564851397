
import { adminPanelActionTypes } from "../constants";
import { COMMON_ERROR_MESSAGE } from "../../../actions";
const initialState = {
  loading: false,
  userManagementData: null,
  userDetailsById: null,
  apiAccess: null,
  trailAccess: null,
  trailAccessSuccessMessage: null,
  trailAccessErrorMessage: null,
  updatedBalance: null,
  updatedCardDetails: null,
  cardUpdateSuccessMessage: null,
  cardUpdateErrorMessage: null,
  subscriptionPlansDetails: null,
  editedSubscriptionPlansDetails: null,
  planUpdateErrorMsg: null,
  demoLinkdata: null,
  demoLinksError: null,

}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case adminPanelActionTypes.getUserManagementData.REQUEST:
      return {
        ...state,
        loading: true,
        userManagementData: null
      }
    case adminPanelActionTypes.getUserManagementData.SUCCESS:
      return {
        ...state,
        userManagementData: payload,
        loading: false,

      }
    case adminPanelActionTypes.getUserManagementData.FAILURE:
      return {
        ...state,
        userManagementData: null,
        loading: false,

      }
    case adminPanelActionTypes.getSearchResult.REQUEST:
      return {
        ...state,
        loading: true,
        userManagementData: null
      }
    case adminPanelActionTypes.getSearchResult.SUCCESS:
      return {
        ...state,
        userManagementData: payload,
        loading: false,

      }
    case adminPanelActionTypes.getSearchResult.FAILURE:
      return {
        ...state,
        userManagementData: null,
        loading: false,

      }

    case adminPanelActionTypes.getUserDetailsForEdit.REQUEST:
      return {
        ...state,
        loading: true,
        userDetailsById: null
      }
    case adminPanelActionTypes.getUserDetailsForEdit.SUCCESS:
      return {
        ...state,
        userDetailsById: payload,
        loading: false,

      }
    case adminPanelActionTypes.getUserDetailsForEdit.FAILURE:
      return {
        ...state,
        userDetailsById: null,
        loading: false,

      }
    case adminPanelActionTypes.grantAPIAccess.REQUEST:
      return {
        ...state,
        loading: true,
        apiAccess: null
      }
    case adminPanelActionTypes.grantAPIAccess.SUCCESS:
      return {
        ...state,
        apiAccess: payload,
        loading: false,

      }
    case adminPanelActionTypes.grantAPIAccess.FAILURE:
      return {
        ...state,
        apiAccess: null,
        loading: false,

      }
    case adminPanelActionTypes.revokeAPIAccess.REQUEST:
      return {
        ...state,
        loading: true,
        apiAccess: null
      }
    case adminPanelActionTypes.revokeAPIAccess.SUCCESS:
      return {
        ...state,
        apiAccess: payload,
        loading: false,

      }
    case adminPanelActionTypes.revokeAPIAccess.FAILURE:
      return {
        ...state,
        apiAccess: null,
        loading: false,

      }
    case adminPanelActionTypes.grantTrialAccess.REQUEST:
      return {
        ...state,
        loading: true,
        trailAccess: null,

      }
    case adminPanelActionTypes.grantTrialAccess.SUCCESS:
      return {
        ...state,
        trailAccess: payload,
        trailAccessSuccessMessage: payload && payload.success && payload.message,
        trailAccessErrorMessage: payload && !payload.success && payload.message,
        loading: false,

      }
    case adminPanelActionTypes.grantTrialAccess.FAILURE:
      return {
        ...state,
        trailAccess: null,
        loading: false,
        trailAccessSuccessMessage: null,
        trailAccessErrorMessage: COMMON_ERROR_MESSAGE,

      }
    case adminPanelActionTypes.updateCreditsBalance.REQUEST:
      return {
        ...state,
        loading: true,
        updatedBalance: null
      }
    case adminPanelActionTypes.updateCreditsBalance.SUCCESS:
      return {
        ...state,
        updatedBalance: payload,
        loading: false,

      }
    case adminPanelActionTypes.updateCreditsBalance.FAILURE:
      return {
        ...state,
        updatedBalance: null,
        loading: false,

      }
    case adminPanelActionTypes.clearTrialAccessMessage.SUCCESS:
      return {
        ...state,
        trailAccessSuccessMessage: null,
        trailAccessErrorMessage: null,
      }
    case adminPanelActionTypes.clearCardUpdateMessage.SUCCESS:
      return {
        ...state,
        cardUpdateSuccessMessage: null,
        cardUpdateErrorMessage: null,
      }
    case adminPanelActionTypes.clearPlanUpdateErrorMsg.SUCCESS:
      return {
        ...state,
        planUpdateErrorMsg: null,
      }

    case adminPanelActionTypes.cardDetailsUpdate.REQUEST:
      return {
        ...state,
        loading: true,
        updatedCardDetails: null
      }
    case adminPanelActionTypes.cardDetailsUpdate.SUCCESS:
      return {
        ...state,
        updatedCardDetails: payload,
        cardUpdateSuccessMessage: payload && payload.success && payload.message,
        cardUpdateErrorMessage: payload && !payload.success && payload.message,
        loading: false,

      }
    case adminPanelActionTypes.cardDetailsUpdate.FAILURE:
      return {
        ...state,
        updatedCardDetails: null,
        cardUpdateSuccessMessage: null,
        cardUpdateErrorMessage: COMMON_ERROR_MESSAGE,

        loading: false,

      }
    case adminPanelActionTypes.getSubscriptionPlansDetails.REQUEST:
      return {
        ...state,
        loading: true,
        subscriptionPlansDetails: null
      }
    case adminPanelActionTypes.getSubscriptionPlansDetails.SUCCESS:
      return {
        ...state,
        subscriptionPlansDetails: payload,
        loading: false,

      }
    case adminPanelActionTypes.getSubscriptionPlansDetails.FAILURE:
      return {
        ...state,
        subscriptionPlansDetails: null,
        loading: false,

      }
    case adminPanelActionTypes.editSubscriptionPlansDetails.REQUEST:
      return {
        ...state,
        loading: true,
        planUpdateErrorMsg: null,

      }
    case adminPanelActionTypes.editSubscriptionPlansDetails.SUCCESS:
      return {
        ...state,
        editedSubscriptionPlansDetails: payload,
        planUpdateErrorMsg: payload.errorMsg || null,
        loading: false,

      }
    case adminPanelActionTypes.editSubscriptionPlansDetails.FAILURE:
      return {
        ...state,
        editedSubscriptionPlansDetails: null,
        loading: false,
        planUpdateErrorMsg: COMMON_ERROR_MESSAGE,

      }
    case adminPanelActionTypes.getDemoLink.REQUEST:
      return {
        ...state,
        loading: true,
        demoLinkdata: null
      }
    case adminPanelActionTypes.getDemoLink.SUCCESS:
      return {
        ...state,
        demoLinkdata: payload,
        loading: false,
        demoLinksError: payload.demoLinksError || null,

      }
    case adminPanelActionTypes.getDemoLink.FAILURE:
      return {
        ...state,
        demoLinkdata: null,
        loading: false,
        demoLinksError: COMMON_ERROR_MESSAGE,

      }
    case adminPanelActionTypes.clearDemoLinksErrorMsg.SUCCESS:
      return {
        ...state,

        demoLinksError: null,

      }
    default:
      return state;

  }
}