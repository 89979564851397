import { createRequestActionTypes, API_URL } from "../../../actions";

export const CREATE_ONE_EMAIL_MATCH_URL = `${API_URL}/emailMatch`;
export const READ_ONE_EMAIL_MATCH_URL = `${API_URL}/emailMatch/:emailMatch_id`;
export const READ_ONE_DOWNLOAD_EMAIL_MATCH_URL = `${API_URL}/emailMatch/:emailMatch_id/download`;
export const READ_MANY_EMAIL_MATCHS_URL = `${API_URL}/emailMatchs`;
export const DELETE_ONE_EMAIL_MATCH_URL = `${API_URL}/emailMatch/:emailMatch_id`;

export const emailMatchActionTypes = {
	createOneEmailMatch: createRequestActionTypes('CREATE_ONE_EMAIL_MATCH'),
	readOneEmailMatch: createRequestActionTypes('READ_ONE_EMAIL_MATCH'),
	readOneDownloadEmailMatch: createRequestActionTypes('READ_ONE_DOWNLOAD_EMAIL_MATCH'),
	readManyEmailMatchs: createRequestActionTypes('READ_MANY_EMAIL_MATCHS'),
	deleteOneEmailMatch: createRequestActionTypes('DELETE_ONE_EMAIL_MATCH'),
	showLoading: createRequestActionTypes("SHOW_LOADING"),
	showFileUploading: createRequestActionTypes("SHOW_FILE_UPLOADING"),
	hideFileUploading: createRequestActionTypes("HIDE_FILE_UPLOADING"),
	showUploadComplete: createRequestActionTypes("SHOW_UPLOAD_COMPLETE"),
	hideUploadComplete: createRequestActionTypes("HIDE_UPLOAD_COMPLETE"),
	setEmailMatchError: createRequestActionTypes("SET_EMAIL_MATCH_ERROR"),
	clearEmailMatchError: createRequestActionTypes("CLEAR_EMAIL_MATCH_ERROR"),
};
