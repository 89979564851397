import React from 'react'
import { RenderTextField, SubmitButton, RenderTextArea, RenderNumericField } from '../../components';
import { Row, Col, FormGroup } from 'reactstrap';
import { Field, Formik, Form } from 'formik';
const validate = (values) => {
  const errors = {};

  if (!values.name || values.name.trim() === '') {
    errors.name = 'This field is required';
  }
  if (!values.expiration || values.expiration.trim() === '') {
    errors.expiration = 'This field is required';
  }
  if (!values.email || values.email.trim() === '') {
    errors.email = 'This field is required';
  } else if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]/i.test(values.email)) {
    errors.email = 'Invalid email';
  }
  return errors;
}
const DemoLinks = (props) => {

  return (
    <div className="main-site-content admin-panel-content-wrap">
      <div className="ap-container">
        <div className="blue-gray-border">
          <div className="user-form-wrap">
            <Formik

              initialValues={props.initialValuesForDemoLinks}
              onSubmit={props.onSubmitDemoLinks}
              validate={validate}

            >
              {({
                touched,
                errors,
              }) => (
                  <Form>
                    <Row>
                      <Col sm="12">


                        <FormGroup>
                          <Field
                            name="name"
                            placeholder="Name"
                            className={errors.name && touched.name ? 'error' : ''}
                            component={RenderTextField} />
                          {errors.name && touched.name &&
                            <span className="required">
                              {errors.name}
                            </span>
                          }
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Field
                        name="email"
                        placeholder="Email"
                        className={errors.email && touched.email ? 'error' : ''}
                        component={RenderTextField}
                      />
                      {errors.email && touched.email &&
                        <span className="required">
                          {errors.email}
                        </span>
                      }
                    </FormGroup>
                    <FormGroup>
                      <Field
                        name="company"
                        placeholder="Company Name"
                        className={errors.company && touched.company ? 'error' : ''}
                        component={RenderTextField}
                      />
                      {errors.company && touched.company &&
                        <span className="required">
                          {errors.company}
                        </span>
                      }
                    </FormGroup>
                    <FormGroup>
                      <Field

                        name="expiration"
                        placeholder="Expiration"
                        className={errors.expiration && touched.expiration ? 'error' : ''}

                        component={RenderNumericField}
                      />
                      {errors.expiration && touched.expiration &&
                        <span className="required">
                          {errors.expiration}
                        </span>
                      }
                    </FormGroup>
                    <FormGroup className="text-center">
                      <SubmitButton className="btn btn-primary">Generate Demo Link</SubmitButton>

                    </FormGroup>
                    {props.demoLinkdata ?
                      <FormGroup>
                        <Field
                          name="demoLink"
                          placeholder="Generated Demo Link"
                          value={(props.demoLinkdata && props.demoLinkdata.link) || ''}
                          onChange={() => { }}
                          disabled={true}
                          className="min-height-200"
                          component={RenderTextArea}
                        />

                      </FormGroup>
                      : null
                    }
                  </Form>
                )}

            </Formik></div>
        </div>
      </div>
    </div>
  )
}

export default DemoLinks
