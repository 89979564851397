import React from "react";
import { Row, Col } from "reactstrap";
import CampaignPostDisplayCard from "./components/CampaignPostDisplayCard";

const CampaignPostsGrid = ({ posts, deletePostHandler, isCampaignShared, ...props }) => {
  return posts && posts.length ? (
    <div className="creator-grid">
      <Row>
        {posts.map((post) => (
          <Col lg="4" sm="6" xs="12" key={post?._id}>
            <CampaignPostDisplayCard
              deletePostHandler={deletePostHandler}
              isCampaignShared={isCampaignShared}
              post={post}
            />
          </Col>
        ))}
      </Row>
    </div>
  ) : (
    <p>No Posts have been added to this campaign yet.</p>
  );
};

export default CampaignPostsGrid;
