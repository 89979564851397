import { pdfReportsActionTypes } from "../constants";
import { COMMON_ERROR_MESSAGE } from "../../../actions";

const initialState = {
  loading: false,
  postAnalysisDetails: null,
  postStatusErrorMessage: null,
  likesLoaded: false,
  storePostLinkDetails: []
}
export default (state = initialState, { type, payload }) => {
  switch (type) {

    case pdfReportsActionTypes.generateNewPost.REQUEST:
      return {
        ...state,
        loading: true,
        postAnalysisDetails: null
      }
    case pdfReportsActionTypes.generateNewPost.SUCCESS:
      return {
        ...state,
        loading: false,
        postAnalysisDetails: payload
      }
    case pdfReportsActionTypes.generateNewPost.FAILURE:
      return {
        ...state,
        loading: false,
        postAnalysisDetails: null
      }
    case pdfReportsActionTypes.getNewPostByLink.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case pdfReportsActionTypes.getNewPostByLink.SUCCESS:
      return {
        ...state,
        loading: false,
        likesLoaded: payload,
      }
    case pdfReportsActionTypes.getNewPostByLink.FAILURE:
      return {
        ...state,
        loading: false,
        likesLoaded: false,
        postStatusErrorMessage: payload || COMMON_ERROR_MESSAGE,
      }
    case pdfReportsActionTypes.getPostDataById.REQUEST:
      return {
        ...state,
        loading: true,
        postAnalysisDetails: null,
      }
    case pdfReportsActionTypes.getPostDataById.SUCCESS:
      return {
        ...state,
        loading: false,
        postAnalysisDetails: payload,
      }
    case pdfReportsActionTypes.getPostDataById.FAILURE:
      return {
        ...state,
        loading: false,
        postAnalysisDetails: null,
        postStatusErrorMessage: payload || COMMON_ERROR_MESSAGE,
      }
    case pdfReportsActionTypes.checkPostStatusById.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case pdfReportsActionTypes.checkPostStatusById.SUCCESS:

      return {
        ...state,
        loading: false,
        postStatusErrorMessage: payload
      }
    case pdfReportsActionTypes.checkPostStatusById.FAILURE:
      return {
        ...state,
        loading: false,
        postStatusErrorMessage: payload
      }
    case pdfReportsActionTypes.clearPostAnalysisErrorMsg.SUCCESS:
      return {
        ...state,
        postStatusErrorMessage: null,
      }
    case pdfReportsActionTypes.showLoading.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case pdfReportsActionTypes.showLoading.SUCCESS:
      return {
        ...state,
        loading: false,
      }
    default: {
      return state
    }
  }
}
