export const validate = (values) => {
  const errors = {};
  if (!values.email || values.email.trim() === '') {
    errors.email = 'This field is required';
  } else if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]/i.test(values.email)) {
    errors.email = 'Invalid email';
  }
  if (!values.password || values.password.trim() === '') {
    errors.password = 'This field is required';
  } else if (values.password.trim().length < 8) {
    errors.password = 'Password must have atleast 8 characters';
  }
  return errors;
}