import {
  actionCreator,
  privateHeaders,
  checkHttpStatus,
  checkAuthTokenExpiry,
  showSuccessMessage,
  showErrorMessage,
  COMMON_ERROR_MESSAGE,
} from "../../../actions";
import {
  userProfileActionTypes,
  GET_PROFILE_DETAILS_URL,
  UPDATE_PROFILE_DETAILS_URL,
  GET_PAST_EXPORTS_LISTS_URL,
  GET_PAST_EXPORTS_POSTS_URL,
  GET_PAST_EXPORTS_REPORTS_URL,
  CANCEL_SUBSCRIPTION_PLAN,
  UPDATE_PAYMENT_CARD,
  CREATE_SUBSCRIPTION,
  UPDATE_USER_PASSWORD_URL,
  SUBSCRIPTION_RE_ACTIVATE_URL,
  DELETE_REPORT_ID,
  DELETE_POST_ID_URL,
  DELETE_LIST_ID_URL,
  DOWNLOAD_REPORT_PDF_URL,
} from "../constants";
import { signOut } from "../../login/actions";

export const getProfileDetails = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(userProfileActionTypes.getProfileDetails.REQUEST));
    fetch(GET_PROFILE_DETAILS_URL, {
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            userProfileActionTypes.getProfileDetails.SUCCESS,
            response
          )
        );
      })
      .catch((error) => {
        // let errorMsg =  COMMON_ERROR_MESSAGE;
        dispatch(
          actionCreator(userProfileActionTypes.getProfileDetails.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const updateProfileDetails = (userData) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(userProfileActionTypes.updateProfileDetails.REQUEST)
    );
    fetch(UPDATE_PROFILE_DETAILS_URL, {
      method: "PUT",
      headers: privateHeaders(getState),
      body: JSON.stringify(userData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.error) {
          let errorMsg =
            response.error[Object.keys(response.error)[0]] ||
            COMMON_ERROR_MESSAGE;
          dispatch(
            actionCreator(userProfileActionTypes.updateProfileDetails.FAILURE)
          );
          showErrorMessage(errorMsg);
        } else {
          dispatch(
            actionCreator(
              userProfileActionTypes.updateProfileDetails.SUCCESS,
              response?.result
            )
          );
          showSuccessMessage("Account successfully updated.");
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(userProfileActionTypes.updateProfileDetails.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const getPastExportsReports = (pageNumber, limit = 10, searchData) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(userProfileActionTypes.getPastExportsReports.REQUEST)
    );
    let reportsUrl = `${GET_PAST_EXPORTS_REPORTS_URL}${pageNumber}&limit=${limit}`;
    if (searchData) {
      reportsUrl += `&q=${searchData}`;
    }
    fetch(reportsUrl, {
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            userProfileActionTypes.getPastExportsReports.SUCCESS,
            response
          )
        );
      })
      .catch((error) => {
        // let errorMsg =  COMMON_ERROR_MESSAGE;
        dispatch(
          actionCreator(userProfileActionTypes.getPastExportsReports.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const getPastExportsPosts = (number) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(userProfileActionTypes.getPastExportsPosts.REQUEST));
    fetch(`${GET_PAST_EXPORTS_POSTS_URL}${number}`, {
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            userProfileActionTypes.getPastExportsPosts.SUCCESS,
            response
          )
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(userProfileActionTypes.getPastExportsPosts.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const getPastExportsLists = (number, limit) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(userProfileActionTypes.getPastExportsLists.REQUEST));
    fetch(`${GET_PAST_EXPORTS_LISTS_URL}${number}&limit=${limit}`, {
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            userProfileActionTypes.getPastExportsLists.SUCCESS,
            response
          )
        );
      })
      .catch((error) => {
        // let errorMsg =  COMMON_ERROR_MESSAGE;
        dispatch(
          actionCreator(userProfileActionTypes.getPastExportsLists.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const cancelSubscriptionAction = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(userProfileActionTypes.cancelSubscription.REQUEST));
    fetch(CANCEL_SUBSCRIPTION_PLAN, {
      method: "POST",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            userProfileActionTypes.cancelSubscription.SUCCESS,
            response
          )
        );
        dispatch(getProfileDetails());
      })
      .catch((error) => {
        dispatch(
          actionCreator(userProfileActionTypes.cancelSubscription.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const switchPlanAction = (data) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(userProfileActionTypes.switchPlanConstant.SUCCESS, data)
    );
  };
};
export const createSubscriptionAction = (data) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(userProfileActionTypes.createSubscription.REQUEST));
    fetch(CREATE_SUBSCRIPTION, {
      method: "POST",
      headers: privateHeaders(getState),
      body: JSON.stringify(data),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.success === false) {
          dispatch(
            actionCreator(
              userProfileActionTypes.createSubscription.FAILURE,
              response
            )
          );
        } else {
          dispatch(
            actionCreator(
              userProfileActionTypes.createSubscription.SUCCESS,
              response
            )
          );
        }
        dispatch(getProfileDetails());
      })
      .catch((error) => {
        dispatch(
          actionCreator(userProfileActionTypes.createSubscription.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const updatePaymentCardAction = (data) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(userProfileActionTypes.updateCardDetails.REQUEST));
    fetch(UPDATE_PAYMENT_CARD, {
      method: "POST",
      headers: privateHeaders(getState),
      body: JSON.stringify(data),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.success === false) {
          dispatch(
            actionCreator(
              userProfileActionTypes.updateCardDetails.FAILURE,
              response
            )
          );
        } else {
          dispatch(
            actionCreator(
              userProfileActionTypes.updateCardDetails.SUCCESS,
              response
            )
          );
        }
        dispatch(getProfileDetails());
      })
      .catch((error) => {
        dispatch(
          actionCreator(userProfileActionTypes.updateCardDetails.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const updateUserPasswordAction = (postData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(userProfileActionTypes.updateUserPassword.REQUEST));
    fetch(UPDATE_USER_PASSWORD_URL, {
      method: "POST",
      headers: privateHeaders(getState),
      body: JSON.stringify(postData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (!response.success) {
          dispatch(
            actionCreator(
              userProfileActionTypes.updateCardDetails.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else {
          dispatch(
            actionCreator(
              userProfileActionTypes.updateUserPassword.SUCCESS,
              response
            )
          );
          showSuccessMessage(response.message);
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(userProfileActionTypes.updateUserPassword.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const clearErrorMsg = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(userProfileActionTypes.clearErrorMessage.SUCCESS));
  };
};
export const clearStripeMsgAction = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(userProfileActionTypes.clearStripeMsgAction.SUCCESS)
    );
  };
};

export const clearSuccessMsg = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(userProfileActionTypes.clearSuccessMessage.SUCCESS));
  };
};
export const showStripeMsg = (msg) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(userProfileActionTypes.showStripeMsg.SUCCESS, msg));
  };
};

export const reActivatePlanAction = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(userProfileActionTypes.planReActivate.REQUEST));
    fetch(SUBSCRIPTION_RE_ACTIVATE_URL, {
      method: "POST",
      headers: privateHeaders(getState),
    })
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(userProfileActionTypes.planReActivate.SUCCESS, response)
        );
        dispatch(getProfileDetails());
      })
      .catch((error) => {
        dispatch(actionCreator(userProfileActionTypes.planReActivate.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const deleteReportIdAction = (action) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(userProfileActionTypes.deleteReportId.REQUEST));
    fetch(`${DELETE_REPORT_ID}${action.reportId}`, {
      method: "DELETE",
      headers: privateHeaders(getState),
    })
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(userProfileActionTypes.deleteReportId.SUCCESS, response)
        );
        dispatch(getPastExportsReports(action.pageNumber));
      })
      .catch((error) => {
        dispatch(actionCreator(userProfileActionTypes.deleteReportId.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const deletePostIdAction = (action) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(userProfileActionTypes.deletePostId.REQUEST));
    fetch(`${DELETE_POST_ID_URL}${action.postId}`, {
      method: "DELETE",
      headers: privateHeaders(getState),
    })
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(userProfileActionTypes.deletePostId.SUCCESS, response)
        );
        dispatch(getPastExportsPosts(action.pageNumber));
      })
      .catch((error) => {
        dispatch(actionCreator(userProfileActionTypes.deletePostId.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const deleteListIdAction = (action) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(userProfileActionTypes.deleteListId.REQUEST));
    fetch(`${DELETE_LIST_ID_URL}${action.listId}`, {
      method: "DELETE",
      headers: privateHeaders(getState),
    })
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(userProfileActionTypes.deleteListId.SUCCESS, response)
        );
        dispatch(getPastExportsLists(action.pageNumber));
      })
      .catch((error) => {
        dispatch(actionCreator(userProfileActionTypes.deleteListId.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const downloadReportPdfAction = (report_id) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(userProfileActionTypes.downloadReportPdf.REQUEST));
    fetch(`${DOWNLOAD_REPORT_PDF_URL}${report_id}/generate/pdf`, {
      method: "GET",
      headers: privateHeaders(getState),
    })
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            userProfileActionTypes.downloadReportPdf.SUCCESS,
            response.url
          )
        );
        if (response && response.url) {
          window.open(response.url, "_blank");
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(userProfileActionTypes.downloadReportPdf.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const clearSavedListFilters = (filtersObj) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(userProfileActionTypes.clearSavedListFilters.SUCCESS)
    );
  };
};
export const saveListSearchFilters = (filtersObj) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(
        userProfileActionTypes.saveListSearchFilters.SUCCESS,
        filtersObj
      )
    );
  };
};
