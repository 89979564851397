const trialBannerOptions = [
  {
    id: "discovery",
    title: "Find & Contact Content Creators",
    message:
      "The most relevant hashtags are colored in dark blue, the least relevant in light blue, and those of average relevance are in intermediate colors. The size of each hashtag is proportional to its popularity.",
  },
  {
    id: "reports",
    title: "Generate a Creator Analytics Report",
    message:
      "Search for creators across our database of over 250M+ Instagram, TikTok, and YouTube profiles.<br />This section lets you view analytics with over 50+ creator and audience data metrics, including contact, all in a single concise report.",
  },
  {
    id: "lists",
    title: "Organize & Save Your Search Results into Lists",
    message:
      "Create a new list or update an existing list with creators found on Discovery.<br />Lists make it easy to label and save your past search results for your next campaign or to track a group of creators over time.",
  },
  {
    id: "activity",
    title: "View Your Past Creator Reports & Saved Exports",
    message:
      "Easily review all of your previous historical creator reports and csv exports.<br />Exports generated during search are saved and accessible below, ready for download or modification.",
  },
];
export default trialBannerOptions;
