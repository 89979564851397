import { API_URL, createRequestActionTypes } from '../../../actions/index';
export const SUBSCRIPTION_URL = `${API_URL}/payment/subscription`;
export const FETCH_PLANS_URL = `${API_URL}/payment/plans`;
export const SUBSCRIPTION_UPDATE_URL = `${API_URL}/payment/subscription/update`;
export const CONTINUE_WITH_TRIAL_ACCESS_URL = `${API_URL}/api/auth/create/trial`;
export const subscriptionActionTypes = {
    activatePlan: createRequestActionTypes('SUBSCRIPTON'),
    showSubscriptionError: createRequestActionTypes('SUBSCRIPTION_ERROR'),
    clearSubscriptionError: createRequestActionTypes('CLEAR_SUBSCRIPTION_ERROR'),
    hideHeaderAction: createRequestActionTypes('HIDE_HEADER_SUCCESS'),
    continueWithTrialAccess: createRequestActionTypes('CONTINUE_WITH_TRIAL_ACCESS_URL'),
    clearContinueWithTrialAccessErrorMsg: createRequestActionTypes('CLEAR_CONTINUE_WITH_TRIAL_ACCESS_ERROR_MSG'),
    clearContinueWithTrialAccessSuccessMsg: createRequestActionTypes('CLEAR_CONTINUE_WITH_TRIAL_ACCESS_SUCCESS_MSG'),
}
/***
 * on the selection of the plan choosen by the user.
 */
export const planSelectionTypes = {
    choosePlan: createRequestActionTypes('PLAN')
}
/***
 * for subscription plan action 
 */
export const fetchPlanTypes = {
    getPlanTypes: createRequestActionTypes('PLANTYPES')
}
/**
 * clear error status on payment 
 */
export const clearResponseMessage = {
    removeResponsePaymentType: createRequestActionTypes('REMOVEPAYMENTERROR')
}
/**
 * ON SELECT THE PLAN , UPDATE THE SUBSCRIPTION PLAN
 * 
 */
export const updateSubscription = {
    updateSubscriptionType: createRequestActionTypes('UPDATE_SUBSCRIPTION_PLAN')
}
/***
 * 
 * on card number save 
 */

export const saveCardNumber = {
    saveCardNumberType: createRequestActionTypes('SAVE_CARD_NUMBER')
}