const sortOptions = [
  {
    text: "Followers",
    value: "followers",
    radioButtonValueOfState: "followers",
    type: "fixed",
  },
  {
    text: "Engagements",
    value: "engagements",
    radioButtonValueOfState: "engagements",
    type: "fixed",
  },
  {
    text: "Location",
    value: "audience_geo",
    radioButtonValueOfState: "audienceLocationValues",
    type: "dynamic",
  },
  {
    text: "Language",
    value: "audience_lang",
    radioButtonValueOfState: "audienceLanguage",
    type: "dynamic",
  },
  {
    text: "Brands",
    value: "audience_brand",
    radioButtonValueOfState: "audienceBrandsValues",
    type: "dynamic",
  },
  {
    text: "Interests",
    value: "audience_brand_category",
    radioButtonValueOfState: "audienceInterestsValues",
    type: "dynamic",
  },
  {
    text: "Gender",
    value: "audience_gender",
    radioButtonValueOfState: "genderAudience",
    type: "dynamic",
  },
  {
    text: "Ethnicity",
    value: "audience_race",
    radioButtonValueOfState: "ethnicityAudience",
    type: "dynamic",
  },
  {
    text: "Age",
    value: "audience_age",
    radioButtonValueOfState: "audiencesAge",
    type: "dynamic",
  },
  {
    text: "Views",
    value: "views",
    radioButtonValueOfState: "viewsTo",
    type: "dynamic",
  },
  {
    text: "Age",
    value: "audience_age",
    radioButtonValueOfState: "audiencesAgeRight",
    type: "dynamic",
  },
   {
    text: "Age",
    value: "audience_age",
    radioButtonValueOfState: "audiencesAgeLeft",
    type: "dynamic",
  },
  // { text: 'Contacts', value: 'contacts', radioButtonValueOfState: 'contactsValues', type: 'dynamic' },
];
export default sortOptions;
