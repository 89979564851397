import React from "react";
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  formatNumbersToCommaSeperated,
  capitalize,
  showTitle,
  showTooltip,
} from "../../../actions";
import { LazyImage, RenderButton } from "../../../components";
import defaultImg from "../../../img/user-circle-outline.svg";

const LookAlikesSection = (props) => {
  const { t } = useTranslation();

  return (
    <Col sm="6">
      <Card className="card-content-custom">
        <CardBody>
          <CardTitle>
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                {t("reports.influencer_details.lookalikes")}
              </span>
              <span
                href="#"
                id="influencerLookAlikes"
                className="tooltip-icon"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                placement="bottom"
                className="tooltip-content"
                target="influencerLookAlikes"
              >
                {showTooltip("influencerLookAlikes")}
              </UncontrolledTooltip>
            )}
          </CardTitle>

          {props.accountOverviewDetails &&
          props.accountOverviewDetails.user_profile &&
          props.accountOverviewDetails.user_profile.similar_users &&
          props.accountOverviewDetails.user_profile.similar_users.length ? (
            <div className="card-content ch-3">
              <ListGroup className="card-list-items">
                {props.accountOverviewDetails &&
                  props.accountOverviewDetails.user_profile.similar_users.map(
                    (user, index) => {
                      if (index < 4) {
                        return (
                          <ListGroupItem key={index}>
                            <div className="avatar">
                              <LazyImage src={user.picture} alt="profile pic" />
                            </div>
                            {props.accountOverviewDetails.user_profile &&
                            props.accountOverviewDetails.user_profile.type ===
                              "youtube" ? (
                              <div
                                id={`influencer_${user.fullname}`}
                                title={showTitle(
                                  `influencer_${user.fullname}`,
                                  user.fullname
                                )}
                                className="list-content youtube"
                              >
                                {" "}
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={user.url}
                                >
                                  <p>{capitalize(user.fullname)}</p>
                                </a>
                              </div>
                            ) : (
                              <div
                                id={`influencer_${user.fullname}`}
                                title={showTitle(
                                  `influencer_${user.fullname}`,
                                  user.fullname
                                )}
                                className="list-content"
                              >
                                {" "}
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={user.url}
                                >
                                  @{user.username}
                                </a>
                                <p>{capitalize(user.fullname)}</p>
                              </div>
                            )}
                          </ListGroupItem>
                        );
                      }
                      return null;
                    }
                  )}
              </ListGroup>
            </div>
          ) : (
            <span className="text-center text-grey ">
              <p className="pt-50">{t("errors.no_record")}</p>
            </span>
          )}

          {props.accountOverviewDetails &&
          props.accountOverviewDetails.user_profile &&
          props.accountOverviewDetails.user_profile.similar_users &&
          props.accountOverviewDetails.user_profile.similar_users.length ? (
            <div className="bottom">
              <Button
                color="link"
                onClick={() => props.toggleModal("lookAlikesModal")}
              >
                {t("reports.view_more")}
              </Button>
              <Modal
                id="lookAlikesModalDownload"
                isOpen={props.modals.lookAlikesModal}
                toggle={() => props.toggleModal("lookAlikesModal")}
              >
                <ModalHeader
                  toggle={() => props.toggleModal("lookAlikesModal")}
                >
                  <span className="tooltip-button-wrap">
                    <span className="text-tooltip">
                      {t("reports.influencer_details.lookalikes")}
                    </span>
                    <span
                      href="#"
                      id="lookAlikesModal"
                      className="tooltip-icon"
                    ></span>
                  </span>
                  {props.tooltip && (
                    <UncontrolledTooltip
                      placement="bottom"
                      className="tooltip-content"
                      target="lookAlikesModal"
                    >
                      {showTooltip("influencerLookAlikes")}
                    </UncontrolledTooltip>
                  )}
                  <RenderButton
                    onClick={() => props.downloadPdf("lookAlikesModalDownload")}
                    className="download-icon-button mg-left-15 link"
                  >
                    {t("reports.download")}
                  </RenderButton>
                </ModalHeader>
                <ModalBody>
                  <Table className="modal-table">
                    <thead>
                      <tr>
                        <th colSpan="2">{t("reports.influencer")}</th>
                        <th className="text-right">
                          {t("reports.influencer_details.engagements")}
                        </th>
                        <th className="text-right">
                          {t("reports.influencer_details.followers")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.accountOverviewDetails &&
                      props.accountOverviewDetails.user_profile &&
                      props.accountOverviewDetails.user_profile.similar_users &&
                      props.accountOverviewDetails.user_profile.similar_users
                        .length
                        ? props.accountOverviewDetails.user_profile.similar_users.map(
                            (user, index) => {
                              return (
                                <tr key={index}>
                                  <th scope="row" className="tb-media-left">
                                    <img
                                      src={user.picture}
                                      alt="profile pic"
                                      className="rounded-circle"
                                      onError={(e) =>
                                        (e.target.src = defaultImg)
                                      }
                                    />
                                  </th>
                                  <td className="tb-head">
                                    <h4>
                                      {" "}
                                      <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={user.url}
                                      >
                                        @{user.username || user.fullname}
                                      </a>
                                    </h4>
                                    <div className="country-text-wrap">
                                      <div className="country-name">
                                        {user.fullname}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="text-right">
                                    {formatNumbersToCommaSeperated(
                                      user.engagements
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {formatNumbersToCommaSeperated(
                                      user.followers
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )
                        : null}
                    </tbody>
                  </Table>
                </ModalBody>
              </Modal>
            </div>
          ) : null}
        </CardBody>
      </Card>
    </Col>
  );
};

export default LookAlikesSection;
