import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { RenderSingleSelect } from "../../components";
import { growthIntervals, growthRate } from "../../helpers/growthOptions";
import { Field } from "formik";

const GrowthFilter = (props) => {
  const { t } = useTranslation();

  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2 second"
      isOpen={props.state.filters.growth}
      toggle={() => props.toggleFilter("growth")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        Growth Rate
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-lg-1">
        <Row>
          <Col md="12" className="col">
            <h3 className="head icon-influencer">
              {props.state.platformValue.value === "youtube"
                ? "Subscribers "
                : "Followers "}
              Growth Rate
            </h3>
          </Col>
          <Col md="8" className="col">
            <FormGroup className="mini-select ptop15 mw-none">
              <Field
                component={RenderSingleSelect}
                name="followersGrowth"
                placeholder="Select interval"
                value={props.state.followersGrowth}
                onChange={(input) =>
                  props.handleSingleSelectChange(input, "followersGrowth")
                }
                options={growthIntervals}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            {props.state.followersGrowth ? (
              <FormGroup className="mini-select ptop15 mw-none">
                <Field
                  component={RenderSingleSelect}
                  name="followersGrowthWeight"
                  placeholder={t("discovery.fields.select_weight")}
                  value={props.state.followersGrowthWeight}
                  onChange={(input) =>
                    props.handleSingleSelectChange(
                      input,
                      "followersGrowthWeight",
                      true
                    )
                  }
                  options={growthRate}
                />
              </FormGroup>
            ) : null}
          </Col>
        </Row>
        {props.state.platformValue.value === "tiktok" ? (
          <Row className="mt-4">
            <Col md="12" className="col">
              <h3 className="head icon-influencer">Total Likes Growth Rate</h3>
            </Col>
            <Col md="8" className="col">
              <FormGroup className="mini-select ptop15 mw-none">
                <Field
                  component={RenderSingleSelect}
                  name="likesGrowth"
                  placeholder="Select interval"
                  value={props.state.likesGrowth}
                  onChange={(input) =>
                    props.handleSingleSelectChange(input, "likesGrowth")
                  }
                  options={growthIntervals}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              {props.state.likesGrowth ? (
                <FormGroup className="mini-select ptop15 mw-none">
                  <Field
                    component={RenderSingleSelect}
                    name="likesGrowthWeight"
                    placeholder={t("discovery.fields.select_weight")}
                    value={props.state.likesGrowthWeight}
                    onChange={(input) =>
                      props.handleSingleSelectChange(
                        input,
                        "likesGrowthWeight",
                        true
                      )
                    }
                    options={growthRate}
                  />
                </FormGroup>
              ) : null}
            </Col>
          </Row>
        ) : null}
        {props.state.platformValue.value === "youtube" ? (
          <Row className="mt-4">
            <Col md="12" className="col">
              <h3 className="head icon-influencer">Total Views Growth Rate</h3>
            </Col>
            <Col md="8" className="col">
              <FormGroup className="mini-select ptop15 mw-none">
                <Field
                  component={RenderSingleSelect}
                  name="viewsGrowth"
                  placeholder="Select interval"
                  value={props.state.viewsGrowth}
                  onChange={(input) =>
                    props.handleSingleSelectChange(input, "viewsGrowth")
                  }
                  options={growthIntervals}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              {props.state.viewsGrowth ? (
                <FormGroup className="mini-select ptop15 mw-none">
                  <Field
                    component={RenderSingleSelect}
                    name="viewsGrowthWeight"
                    placeholder={t("discovery.fields.select_weight")}
                    value={props.state.viewsGrowthWeight}
                    onChange={(input) =>
                      props.handleSingleSelectChange(
                        input,
                        "viewsGrowthWeight",
                        true
                      )
                    }
                    options={growthRate}
                  />
                </FormGroup>
              ) : null}
            </Col>
          </Row>
        ) : null}
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default GrowthFilter;
