import { createRequestActionTypes, API_URL } from "../../../actions";

export const GET_CREATOR_LISTS_URL = `${API_URL}/creatorLists.json`;
export const CREATE_CREATOR_LIST_URL = `${API_URL}/creatorList.json`;
export const UPDATE_CREATOR_LIST_URL = `${API_URL}/creatorList/addCreator.json`;
export const DELETE_CREATOR_URL = `${API_URL}/creatorList/removeCreator.json`;
export const DELETE_CREATOR_LIST_URL = `${API_URL}/creatorList/:creatorList_id.json`;
export const ADD_SHARED_USER_URL = `${API_URL}/creatorList/addSharedUser.json`;
export const REMOVE_SHARED_USER_URL = `${API_URL}/creatorList/removeSharedUser.json`;

export const ADD_SHARED_USER_SUCCESS_MESSAGE = (email) => `Shared list with ${email}`;

export const creatorListsActionTypes = {
  getCreatorLists: createRequestActionTypes("GET_CREATOR_LISTS"),
  createCreatorList: createRequestActionTypes("CREATE_CREATOR_LIST"),
  updateCreatorList: createRequestActionTypes("UPDATE_CREATOR_LIST"),
  deleteCreatorList: createRequestActionTypes("DELETE_CREATOR_LIST"),
  removeSharedUser: createRequestActionTypes("REMOVE_SHARED_USER"),
  addSharedUser: createRequestActionTypes("ADD_SHARED_USER"),
  deleteCreator: createRequestActionTypes("DELETE_CREATOR"),
};
