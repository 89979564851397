import {
  actionCreator,
  privateHeaders,
  checkHttpStatus,
  COMMON_ERROR_MESSAGE,
  checkAuthTokenExpiry,
  CHECKOUT_APP_EXTERNAL_URL,
} from "../../../actions";
import {
  pdfReportsActionTypes,
  GET_POST_DATA_BY_SEARCH_URL,
  GET_NEW_POST_URL,
  GENERATE_NEW_POST,
  CHECK_POST_STATUS_BY_REPORT_ID_URL,
} from "../constants";
import history from "../../../history";
import { signOut, createCheckoutSession } from "../../login/actions";

export const getPostDataById = (postId, clearFields) => {
  return (dispatch, getState) => {
    if (clearFields === true) {
      dispatch(
        actionCreator(pdfReportsActionTypes.getPostDataById.SUCCESS, null)
      );
    } else {
      dispatch(actionCreator(pdfReportsActionTypes.getPostDataById.REQUEST));
      fetch(`${GET_POST_DATA_BY_SEARCH_URL}/${postId}`, {
        method: "get",
        headers: privateHeaders(getState),
      })
        .then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response))
        .then((response) => {
          if (response.success) {
            dispatch(
              actionCreator(
                pdfReportsActionTypes.getPostDataById.SUCCESS,
                response
              )
            );
          } else {
            let postStatusErrorMessage =
              response.message || COMMON_ERROR_MESSAGE;
            dispatch(
              actionCreator(
                pdfReportsActionTypes.checkPostStatusById.SUCCESS,
                postStatusErrorMessage
              )
            );
            if (response.redirect === "PAYMENT_PAGE") {
              // history.push('/subscription');
              if (
                getState().login.checkoutSessionDetails &&
                getState().login.checkoutSessionDetails._id
              ) {
                window.open(
                  `${CHECKOUT_APP_EXTERNAL_URL}/?session=${
                    getState().login.checkoutSessionDetails._id
                  }`,
                  "_self"
                );
              } else {
                dispatch(createCheckoutSession());
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(
            actionCreator(pdfReportsActionTypes.getPostDataById.FAILURE)
          );
          if (
            error.statusCode &&
            error.statusCode >= 400 &&
            error.statusCode <= 500
          ) {
            dispatch(signOut(error));
          }
        });
    }
  };
};
export const checkPostStatusById = (postId, counter) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(pdfReportsActionTypes.checkPostStatusById.REQUEST));
    fetch(`${CHECK_POST_STATUS_BY_REPORT_ID_URL}/${postId}`, {
      method: "get",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.done_at) {
          let postStatusErrorMessage = null;
          dispatch(
            actionCreator(
              pdfReportsActionTypes.checkPostStatusById.SUCCESS,
              postStatusErrorMessage
            )
          );
          history.push(`/cam/${response.report_id}`);
          dispatch(getPostDataById(response.report_id));
        } else {
          if (counter && counter < 2) {
            setTimeout(() => {
              dispatch(checkPostStatusById(postId, 2));
            }, 5000);
          } else {
            let postStatusErrorMessage = COMMON_ERROR_MESSAGE;
            dispatch(
              actionCreator(
                pdfReportsActionTypes.checkPostStatusById.SUCCESS,
                postStatusErrorMessage
              )
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
        let postStatusErrorMessage = COMMON_ERROR_MESSAGE;
        dispatch(
          actionCreator(
            pdfReportsActionTypes.checkPostStatusById.FAILURE,
            postStatusErrorMessage
          )
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const generateNewPost = (postData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(pdfReportsActionTypes.generateNewPost.REQUEST));
    fetch(GENERATE_NEW_POST, {
      method: "POST",
      headers: privateHeaders(getState),
      body: JSON.stringify(postData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(pdfReportsActionTypes.generateNewPost.SUCCESS, response)
        );
        if (response.report_info && response.report_info.report_id) {
          // dispatch(checkPostStatusById(response.report_info.report_id, 1));
          history.push(`/campaigns/${response.report_info.report_id}`);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(actionCreator(pdfReportsActionTypes.generateNewPost.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const getNewPostByLink = (postData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(pdfReportsActionTypes.getNewPostByLink.REQUEST));
    fetch(GET_NEW_POST_URL, {
      method: "POST",
      headers: privateHeaders(getState),
      body: JSON.stringify(postData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        response.postData = postData;
        if (response.success) {
          dispatch(
            actionCreator(
              pdfReportsActionTypes.getNewPostByLink.SUCCESS,
              response
            )
          );
          //   dispatch(generateNewPost(postData));
        } else {
          dispatch(
            actionCreator(
              pdfReportsActionTypes.getNewPostByLink.FAILURE,
              response.message
            )
          );
          if (response.redirect === "PAYMENT_PAGE") {
            // history.push('/subscription');
            if (
              getState().login.checkoutSessionDetails &&
              getState().login.checkoutSessionDetails._id
            ) {
              window.open(
                `${CHECKOUT_APP_EXTERNAL_URL}/?session=${
                  getState().login.checkoutSessionDetails._id
                }`,
                "_self"
              );
            } else {
              dispatch(createCheckoutSession());
            }
          }
        }
      })
      .catch((error) => {
        // console.log(error)
        dispatch(actionCreator(pdfReportsActionTypes.getNewPostByLink.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const clearPostAnalysisErrorMsg = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(pdfReportsActionTypes.clearPostAnalysisErrorMsg.SUCCESS)
    );
  };
};

export const showLoadingAction = (action) => {
  return (dispatch, getState) => {
    if (action.toLowerCase() === "start") {
      dispatch(actionCreator(pdfReportsActionTypes.showLoading.REQUEST));
    } else if (action.toLowerCase() === "stop") {
      dispatch(actionCreator(pdfReportsActionTypes.showLoading.SUCCESS));
    }
  };
};
export const clearLikesLoaded = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(pdfReportsActionTypes.getNewPostByLink.SUCCESS, null)
    );
  };
};
