import { createRequestActionTypes, API_URL } from "../../../actions";
export const GET_POST_DATA_BY_SEARCH_URL = `${API_URL}/posts`;
export const GET_NEW_POST_URL = `${API_URL}/posts/new`;
export const GENERATE_NEW_POST = `${API_URL}/generate/new/post`;
export const CHECK_POST_STATUS_BY_REPORT_ID_URL = `${API_URL}/posts/status`
export const pdfReportsActionTypes = {
    getNewPostByLink: createRequestActionTypes('GET_NEW_POST'),
    generateNewPost: createRequestActionTypes('GENERATE_NEW_POST'),
    getPostDataById: createRequestActionTypes('GET_POST_DATA_BY_SEARCH'),
    checkPostStatusById: createRequestActionTypes('CHECK_POST_STATUS_BY_REPORT_ID'),
    clearPostAnalysisErrorMsg: createRequestActionTypes('CLEAR_POST_ANALYSIS_ERROR_MSG'),
    showLoading: createRequestActionTypes('SHOW_LOADING'),
}