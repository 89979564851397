/* eslint-disable no-loop-func */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import {
  getAccountOverview,
  showLoadingAction,
  clearReportsErrorMsg,
  getCloudTags,
  getInfluencerProfileDataBySearch,
  clearSubscriptionErrorAction,
  searchAvailableAccountsGlobal,
} from "./actions/index";
import { getLocationsBasedOnFilter } from "../identification/actions/index";
import { debounce } from "lodash";
import Proj4 from "proj4";
import SplashScreen from "./splashScreen";
import ReportSection from "./reports";
import { showErrorMessage } from "../../actions";
import { downloadReportPdfAction } from "../userProfile/actions/index";
import { store } from "../../store";
import history from "../../history";

import { getPastExportsReports } from "../userProfile/actions/index";
class Reports extends Component {
  constructor(props) {
    super(props);
    window.proj4 = Proj4;
    this.state = {
      username: "",
      reportPage: 1,
      activeReportsPage: 1,
      reportsTextQuery: "",
    };

    this.handleSearchAccounts = debounce(
      this.handleSearchAccounts.bind(this),
      250
    );
  }
  componentDidMount() {
    const { reportPage, reportsTextQuery } = this.state;

    if (
      window.location.pathname.split("/").pop().trim() &&
      window.location.pathname.split("/").pop().trim() !== "reports"
    ) {
      let reportId = window.location.pathname.split("/").pop().trim();

      this.props.getAccountOverview(reportId);
    } else {
      let searchObj = {};
      let clearFields = true;
      this.props.getCloudTags(searchObj, clearFields);
      this.props.getAccountOverview(null, clearFields);
    }
    this.props.getPastExportsReports(reportPage, 10, reportsTextQuery);
  }

  componentWillUnmount() {
    let searchObj = {};
    let clearFields = true;
    this.props.getCloudTags(searchObj, clearFields);
    this.props.getAccountOverview(null, clearFields);
    this.clearSubscriptionError();
  }

  clearErrorMessage = () => {
    this.props.clearReportsErrorMsg();
  };
  clearSubscriptionError = () => {
    this.props.clearSubscriptionErrorAction();
  };
  onSubmitSpashScreen = (user) => {
    if (user.username && user.username.trim().length) {
      let searchObj = {
        username: user.username,
        platform: user.type,
      };
      this.props.getInfluencerProfileDataBySearch(
        searchObj,
        null,
        this.props.loginUser && this.props.loginUser.id
      );
    } else {
      let clearFields = true;
      this.props.getInfluencerProfileDataBySearch(null, clearFields);
    }
  };

  handleSearchAccounts = (query) => {
    const searchQuery = {
      query: query && query,
    };
    const { searchAvailableAccountsGlobal } = this.props;
    searchAvailableAccountsGlobal(searchQuery);
  };

  handleSubmitReportsFilter = (query) => {
    this.setState({
      reportsTextQuery: query,
    });
    const { getPastExportsReports } = this.props;
    getPastExportsReports(this.state.activeReportsPage, 10, query);
  };

  handlePageChange = (pageNumber) => {
    const { getPastExportsReports } = this.props;
    this.setState({
      activeReportsPage: pageNumber,
    });
    getPastExportsReports(pageNumber, 10, this.state.reportsTextQuery);
  };

  // called by View Report button in Reports tab
  handleReportsAnalyze = (obj) => {
    const reportUrl = `${process.env.REACT_APP_URL}/reports/${obj.report_id}`;
    let newReportTab = window.open(reportUrl, "_blank");
    if (newReportTab) return;
    history.push(`/reports/${obj.report_id}`);
    window.scroll(0, 0);
    this.props.getAccountOverview(obj.report_id);
  };

  render() {
    return (
      <>
        {this.props.subscriptionError
          ? showErrorMessage(
              store,
              this.props.subscriptionError,
              this.clearSubscriptionError,
              "none",
              "subscriptionError"
            )
          : null}
        {this.props.reportsErrorMessage
          ? showErrorMessage(
              store,
              this.props.reportsErrorMessage,
              this.clearErrorMessage,
              "none",
              "reportsErrorMessage"
            )
          : null}
        {!this.props.accountOverviewDetails ? (
          <SplashScreen
            state={this.state}
            loginUser={this.props.loginUser}
            subscriptionDetails={this.props.subscriptionDetails}
            onSubmitSpashScreen={this.onSubmitSpashScreen}
            availableAccounts={this.props.availableAccounts}
            initialValues={this.props.initialValues}
            handleSearchAccounts={this.handleSearchAccounts}
            pastExportsReports={this.props.pastExportsReports}
            handlePageChange={this.handlePageChange}
            handleSubmitReportsFilter={this.handleSubmitReportsFilter}
            handleReportsAnalyze={this.handleReportsAnalyze}
          />
        ) : (
          <Container className="main-site-content">
            <div className="px-2 reports">
              <ReportSection _blank={false} />
            </div>
          </Container>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    initialValues: {
      userName: "",
      userNameSplash: "",
    },
    accountOverviewDetails: state.reports.accountOverviewDetails,
    availableAccounts: state.reports.availableAccounts,
    loginUser: state.login.loginUser,
    subscriptionDetails: state.login.subscriptionDetails,
    subscriptionError: state.reports.subscriptionError,
    reportsErrorMessage: state.reports.reportsErrorMessage,
    pastExportsReports: state.userProfile.pastExportsReports,
  };
};
export default connect(mapStateToProps, {
  clearReportsErrorMsg,
  getAccountOverview,
  getCloudTags,
  getInfluencerProfileDataBySearch,
  showLoadingAction,
  downloadReportPdfAction,
  getLocationsBasedOnFilter,
  clearSubscriptionErrorAction,
  searchAvailableAccountsGlobal,
  getPastExportsReports,
})(Reports);
