import React from "react";
import { Col, Card, CardBody, CardTitle } from "reactstrap";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import wordcloud from "highcharts/modules/wordcloud";
wordcloud(Highcharts);
const chartOptions = (props) => {
  const initial_freq = props.cloudTags?.data[0].freq;
  const initial_cnt = props.cloudTags?.data[0].tag_cnt;

  const weight = (freq, count) =>
    Math.round(((freq / initial_freq + count / initial_cnt) / 2) * 100) / 100;

  let data =
    props.cloudTags && props.cloudTags.data
      ? props.cloudTags.data.map((tag) => ({
          name: tag.tag.replace("#", ""),
          weight: weight(tag.freq, tag.tag_cnt),
          color: tag.color,
        }))
      : [];
  return {
    chart: {
      height: 298,
      backgroundColor: null,
    },
    series: [
      {
        rotation: {
          from: 90,
          to: 90,
        },
        type: "wordcloud",
        data: data.slice(0, 20),
        colors: ["#337F97", "#429fbd", "#51BFE2", "#86E4FC"],
      },
    ],
    title: {
      text: null,
    },
    tooltip: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
  };
};
const SocialCloud = (props) => {
  const { t } = useTranslation();

  return (
    <Col sm="6">
      <Card className="card-content-custom">
        <CardBody>
          <CardTitle>
            {t("reports.influencer_details.relevant_topics")}
          </CardTitle>
          {props.cloudTags &&
          props.cloudTags.data &&
          props.cloudTags.data.length ? (
            <div className="card-content ts-tag-clouds-in">
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions(props)}
              />
            </div>
          ) : (
            <span className="text-center text-grey ">
              <p className="pt-50">{t("errors.no_record")}</p>
            </span>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default SocialCloud;
