import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { formatNumbersToCommaSeperated } from "actions";
// import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { XMarkIcon } from "@heroicons/react/20/solid";

const PreciseMetricDisplayHover = ({
  metricValue,
  triggerElem,
  header,
  delay = 500,
  ...props
}) => {
  // const { t } = useTranslation();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const triggerRef = useRef(null);

  const openPopover = () => {
    setPopoverOpen(true);
  };
  const closePopover = () => {
    if (!isHovering && popoverOpen) {
      setPopoverOpen(false);
    }
  };

  const closeRef = useRef(closePopover);

  useEffect(() => {
    closeRef.current = closePopover;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovering, popoverOpen]);

  const closePopoverDebounce = useMemo(() => {
    return debounce(() => {
      closeRef.current();
    }, delay);
  }, [delay]);

  const mouseEnterHandler = () => {
    setIsHovering(true);
    if (!popoverOpen) {
      openPopover();
    }
  };
  const mouseLeaveHandler = () => {
    setIsHovering(false);
    if (popoverOpen) {
      closePopoverDebounce();
    }
  };

  return (
    <>
      {metricValue > 999 && triggerElem ? (
        <div>
          {React.cloneElement(triggerElem, {
            ref: triggerRef,
            onMouseEnter: mouseEnterHandler,
            onMouseLeave: mouseLeaveHandler,
          })}
          {triggerRef?.current && (
            <Popover
              placement="top"
              isOpen={popoverOpen}
              target={triggerRef?.current}
            >
              {header && <PopoverHeader>{header}</PopoverHeader>}
              <PopoverBody
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
                className="metric-popover-content d-flex justify-content-space-between"
              >
                {formatNumbersToCommaSeperated(metricValue)}
                <XMarkIcon
                  className="icon-btn popover-close-btn"
                  onClick={() => setPopoverOpen(false)}
                  width={15}
                  height={15}
                  strokeWidth={.6}
                />
              </PopoverBody>
            </Popover>
          )}
        </div>
      ) : (
        <>{React.cloneElement(triggerElem, {})}</>
      )}
    </>
  );
};
export default PreciseMetricDisplayHover;
