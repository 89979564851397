import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { RenderButton, RenderSearchableSingleSelect } from "../../components";
import { Field } from "formik";
import { showTitle, showTooltip } from "../../actions";
import noimg from "../../img/avatar.svg";
import defaultImg from "../../img/user-circle-outline.svg";
import { components } from "react-select";

const LookalikesFilter = (props) => {
  const { t } = useTranslation();

  const FormattedOption = (props) => {
    const { picture, label } = props.data;
    return (
      <components.Option {...props}>
        <div className="option-creator-network">
          {picture ? (
            <div className="account-image">
              <img
                src={picture || noimg}
                alt="profile pic"
                width="25px"
                height="25px"
                className="rounded-circle"
                onError={(e) => (e.target.src = defaultImg)}
              />
            </div>
          ) : null}

          <div>{label}</div>
        </div>
      </components.Option>
    );
  };

  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2 third"
      isOpen={props.state.filters.lookalikes}
      toggle={() => props.toggleFilter("lookalikes")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {t("discovery.filters.lookalikes.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-lg">
        <Row>
          <Col sm="6" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-audience">
                  {t("discovery.audience")}
                </h3>
              </span>
              <span
                id="lookalikesAudience"
                className="tooltip-icon tool-top"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="lookalikesAudience"
              >
                {showTooltip("lookalikesAudience")}
              </UncontrolledTooltip>
            )}
            <Field
              component={RenderSearchableSingleSelect}
              replaceComponents={{ Option: FormattedOption }}
              options={props.filteredAudienceLookalikeOptions}
              name="audienceLookalikes"
              onChange={(option) =>
                props.handleSingleSelectChange(option, "audienceLookalikeValue")
              }
              onInputChange={(value) =>
                props.filterOptions(
                  "filteredAudienceLookalikeOptions",
                  "lookalike",
                  value
                )
              }
              placeholder={t(
                "discovery.filters.lookalikes.placeholder.audience"
              )}
              value={props.state.audienceLookalikeValue}
            />
            {props.state.audienceLookalikeValue ? (
              <Row className="selected-tags-bottom left-audience">
                <Col sm="12" className="col active-filters-wrap">
                  <div className="active-filters inside">
                    <ul className="list-inline">
                      <li>
                        <div className="li-audience">
                          <p
                            title={showTitle(
                              `aud_lookalikes_inside_${props.state.audienceLookalikeValue.value}`,
                              props.state.audienceLookalikeValue.label
                            )}
                            id={`aud_lookalikes_inside_${props.state.audienceLookalikeValue.value}`}
                          >
                            {props.state.audienceLookalikeValue.label}
                          </p>
                          <RenderButton
                            close
                            onClick={() =>
                              props.removeSingleSelectFilters(
                                "audienceLookalikeValue",
                                false
                              )
                            }
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
          <Col sm="6" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span
                id="lookalikesInfluencer"
                className="tooltip-icon tool-top"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="lookalikesInfluencer"
              >
                {showTooltip("lookalikesInfluencer")}
              </UncontrolledTooltip>
            )}
            <Field
              component={RenderSearchableSingleSelect}
              replaceComponents={{ Option: FormattedOption }}
              name="influencerLookalikes"
              options={props.filteredInfluencerLookalikeOptions}
              onChange={(option) =>
                props.handleSingleSelectChange(
                  option,
                  "influencerLookalikeValue"
                )
              }
              onInputChange={(value) =>
                props.filterOptions(
                  "filteredInfluencerLookalikeOptions",
                  "lookalike",
                  value
                )
              }
              placeholder={t(
                "discovery.filters.lookalikes.placeholder.influencer"
              )}
              value={props.state.influencerLookalikeValue}
            />
            {props.state.influencerLookalikeValue ? (
              <Row className="selected-tags-bottom">
                <Col sm="12" className="col active-filters-wrap">
                  <div className="active-filters inside">
                    <ul className="list-inline">
                      <li>
                        <div className="li-influencer">
                          <p
                            title={showTitle(
                              `inf_lookalikes_inside_${props.state.influencerLookalikeValue.value}`,
                              props.state.influencerLookalikeValue.label
                            )}
                            id={`inf_lookalikes_inside_${props.state.influencerLookalikeValue.value}`}
                          >
                            {props.state.influencerLookalikeValue.label}
                          </p>
                          <RenderButton
                            close
                            onClick={() =>
                              props.removeSingleSelectFilters(
                                "influencerLookalikeValue",
                                false
                              )
                            }
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default LookalikesFilter;
