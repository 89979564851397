import React from "react";
import networkImage from "../../helpers/networkImage";
import { FollowerTag, SharedTag } from "components/tags";
import noimg from "../../img/MaskGroup.png";
import defaultImg from "../../img/user-circle-outline.svg";
import moment from "moment";
import CreatorListsDropdown from "./creatorListsDropdown";

const CreatorListsRow = (props) => {
  const { creatorList, deleteCreatorList } = props;

  return (
    <tr>
      <td
        className="tb-head"
        onClick={() => props.toggleModal(creatorList._id)}
      >
        <h4 className="m-0 d-flex align-items-center">
          {creatorList.name}{" "}
          {(creatorList?.isShared ||
            creatorList?.sharedWithUser_ids.length > 0) && <SharedTag />}
        </h4>
      </td>
      <td>
        <div className="social-icons">
          {creatorList.networks.map((network, index) => (
            <div className="social-icon-round" key={index}>
              {networkImage(network)}
            </div>
          ))}
        </div>
      </td>
      <td>
        <div className="creators">
          <div className="account-images">
            {creatorList.creators.slice(0, 3).map((influencer) => (
              <div className="account-image" key={influencer._id}>
                <img
                  src={influencer.imageUrl ? influencer.imageUrl : noimg}
                  alt="profile pic"
                  width="35px"
                  height="35px"
                  className="rounded-circle"
                  onError={(e) => (e.target.src = defaultImg)}
                />
              </div>
            ))}
          </div>
          <div className="creators-count">{creatorList.totalCreators}</div>
        </div>
      </td>
      <td>
        <FollowerTag followers={creatorList.totalReach || 0}></FollowerTag>
      </td>
      <td>{moment(creatorList.updated).format("MMMM DD, YYYY")}</td>
      <td>
        <CreatorListsDropdown
          creatorList={creatorList}
          deleteCreatorList={deleteCreatorList}
        />
      </td>
    </tr>
  );
};

export default CreatorListsRow;
