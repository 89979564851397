import React from "react";
import { RenderButton } from "../../../components";
import { Row, Col, Table, TabPane } from "reactstrap";
import Paginator from "../../../components/pagination/pagination";
import { formatNumbersToCommaSeperated } from "../../../actions";
import moment from "moment";
const PostsTab = (props) => {
  return (
    <TabPane tabId="2">
      <Row className="search-results-wrap">
        <Col md="12">
          <div className="table-responsive table-outer user-profile-table">
            <Table hover borderless>
              <thead>
                <tr>
                  <th width="25%" className="my-reports">
                    Post
                  </th>
                  <th width="20%" className="text-left mobile-view-past-report">
                    Posts Analyzed
                  </th>
                  <th width="20%" className="text-left mobile-view-past-report">
                    Engagements
                  </th>
                  <th width="25%" className="text-left mobile-view-past-report">
                    Date
                  </th>
                  <th width="10%"></th>
                </tr>
              </thead>
              <tbody>
                {props.pastExportsPosts &&
                props.pastExportsPosts.posts_generated &&
                props.pastExportsPosts.posts_generated.length ? (
                  props.pastExportsPosts.posts_generated.map((post, index) => (
                    <tr key={index}>
                      <td className="tb-head d-flex">
                        <div className="square-img">
                          <img
                            src={
                              post.user_picture &&
                              post.user_picture.length &&
                              post.user_picture[0]
                            }
                            alt="profile pic"
                            width="50px"
                            height="50px"
                            className=""
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <h4>{`@${
                            post.creators &&
                            post.creators.length &&
                            post.creators.join(", @")
                          }`}</h4>
                        </div>
                      </td>
                      <td className="text-left mobile-view-past-report">
                        {formatNumbersToCommaSeperated(post.posts_num)}
                      </td>
                      <td className="text-left mobile-view-past-report">
                        {formatNumbersToCommaSeperated(post.engagement)}
                      </td>
                      <td className="text-left mobile-view-past-report">
                        {moment(post.created).format("MMMM DD, YYYY")}
                      </td>
                      <td className="text-left mobile-view-past-report">
                        <RenderButton
                          onClick={() => props.handlePostAnalyze(post)}
                          className="btn btn-outline-primary"
                        >
                          View
                        </RenderButton>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" align="center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {props.pastExportsPosts &&
            props.pastExportsPosts.total &&
            props.pastExportsPosts.total > 0 ? (
              <Paginator
                activePage={props.state.activePostsPage}
                itemsPerPage={10}
                totalItems={props.pastExportsPosts.total}
                handlePageChange={(pageNumber) =>
                  props.handlePageChange(pageNumber, "POSTS")
                }
              />
            ) : null}
          </div>
        </Col>
      </Row>
    </TabPane>
  );
};

export default PostsTab;
