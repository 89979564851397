/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  CommandLineIcon,
  CreditCardIcon,
  IdentificationIcon,
  PuzzlePieceIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import { connect } from "react-redux";
import APIToken from "./api-token";
import AccountDetails from "./account-details";
import Password from "./password";
import BillingPortal from "./billing-portal";
import Integrations from "./integrations";
import settings from "settings";
import classNames from "classnames";

import {
  checkSubscription,
  generateBillingPortal,
} from "../login/actions/index";
import {
  getProfileDetails,
  updateProfileDetails,
  updateUserPasswordAction,
} from "../userProfile/actions/index";
import { generateToken } from "./actions/index";

import { Helmet } from "react-helmet";
import "./index.scss";

const Settings = ({
  checkSubscription,
  generateBillingPortal,
  generateToken,
  getProfileDetails,
  updateProfileDetails,
  updateUserPasswordAction,
  loginUser,
  subscriptionDetails,
  profileDetails,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState("0");

  useEffect(() => {
    checkSubscription();
    getProfileDetails();
  }, []);

  const handleManageAccountClick = () => {
    generateBillingPortal();
  };

  const handleGenerateTokenClick = async () => {
    generateToken();
  };

  return (
    <>
      {loginUser?.id ? (
        <Helmet>
          <script
            src="https://labs.pathfix.com/helper.js"
            data-user-id={loginUser.id}
            id="pinc.helper"
            modules="pinc.oauth.min"
            data-public-key={process.env.REACT_APP_PATHFIX_PUBLIC_ID}
          ></script>
        </Helmet>
      ) : null}

      <Container className="main-site-content settings-wrapper">
        <Row>
          <Col className="text-wrapper">
            <h1 className="page-title">Settings</h1>
          </Col>
        </Row>
        <Row>
          <Col md="3" className="mb-5">
            <Nav vertical pills className="settings-nav">
              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === "0" })}
                  onClick={() => setActiveTab("0")}
                >
                  <IdentificationIcon
                    className="mr-2"
                    width={24}
                    height={24}
                    strokeWidth={2}
                  />
                  Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === "1" })}
                  onClick={() => setActiveTab("1")}
                >
                  <ShieldCheckIcon
                    className="mr-2"
                    width={24}
                    height={24}
                    strokeWidth={2}
                  />
                  Password
                </NavLink>
              </NavItem>
              {settings.providers.stripe.enabled ? (
                <NavItem>
                  <NavLink
                    className={classNames({ active: activeTab === "2" })}
                    onClick={() => handleManageAccountClick()}
                  >
                    <CreditCardIcon
                      className="mr-2"
                      width={24}
                      height={24}
                      strokeWidth={2}
                    />
                    Billing
                  </NavLink>
                </NavItem>
              ) : null}
              {settings.providers.pathfix.enabled ? (
                <NavItem>
                  <NavLink
                    className={classNames({ active: activeTab === "3" })}
                    onClick={() => setActiveTab("3")}
                  >
                    <PuzzlePieceIcon
                      className="mr-2"
                      width={24}
                      height={24}
                      strokeWidth={2}
                    />
                    Integrations
                  </NavLink>
                </NavItem>
              ) : null}
              {settings.api_token.enabled ? (
                <NavItem>
                  <NavLink
                    className={classNames({ active: activeTab === "4" })}
                    onClick={() => setActiveTab("4")}
                  >
                    <CommandLineIcon
                      className="mr-2"
                      width={24}
                      height={24}
                      strokeWidth={2}
                    />
                    API
                  </NavLink>
                </NavItem>
              ) : null}
            </Nav>
          </Col>
          <Col md={{ size: 8, offset: 1 }} className="mb-5">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="0">
                <Row>
                  <Col md="12">
                    <AccountDetails
                      profile={profileDetails}
                      updateProfileDetails={updateProfileDetails}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="1">
                <Row>
                  <Col md="12">
                    <Password
                      updateUserPasswordAction={updateUserPasswordAction}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <BillingPortal
                  handleManageAccountClick={handleManageAccountClick}
                />
              </TabPane>
              <TabPane tabId="3">
                <Integrations />
              </TabPane>
              <TabPane tabId="4">
                <APIToken
                  handleGenerateTokenClick={handleGenerateTokenClick}
                  subscriptionDetails={subscriptionDetails}
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    subscriptionDetails: state.login.subscriptionDetails,
    profileDetails: state.userProfile.profileDetails,
  };
};

export default connect(mapStateToProps, {
  checkSubscription,
  generateBillingPortal,
  generateToken,
  getProfileDetails,
  updateProfileDetails,
  updateUserPasswordAction,
})(Settings);
