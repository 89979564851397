import React from "react";
import { Trans } from "react-i18next";

const youtubeOtherFiltersOptions = [
  {
    text: <Trans i18nKey="discovery.filters.other.verified" />,
    value: "is_verified",
  },
  {
    text: <Trans i18nKey="discovery.filters.other.audience" />,
    value: "has_audience_data",
  },
];

export default youtubeOtherFiltersOptions;
