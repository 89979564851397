import React, { useEffect, useState } from "react";
import settings from "settings";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap";
import {
  RenderTextFieldWithoutAutoComplete,
  RenderSingleSelectWithIcon,
} from "../../components";
import { Field } from "formik";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import wordcloud from "highcharts/modules/wordcloud";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import defaultImg from "../../img/user-circle-outline.svg";
import Loader from "../../loader";
import { showTooltip } from "../../actions";
wordcloud(Highcharts);

NoDataToDisplay(Highcharts);
Highcharts.setOptions({
  lang: {
    noData: "No search suggestions",
  },
});

const chartOptions = (props) => {
  let data = [];
  if (props.cloudTags?.data.length) {
    const initial_freq = props.cloudTags?.data[0].freq;
    const initial_cnt = props.cloudTags?.data[0].tag_cnt;

    const weight = (freq, count) =>
      Math.round(((freq / initial_freq + count / initial_cnt) / 2) * 100) / 100;

    data =
      props.cloudTags && props.cloudTags.data
        ? props.cloudTags.data.map((tag) => ({
            name: tag.tag.replace("#", ""),
            weight: weight(tag.freq, tag.tag_cnt),
            color: tag.color,
          }))
        : [];
  }

  return {
    chart: {
      height: 150,
      spacingBottom: 0,
      backgroundColor: null,
    },
    series: [
      {
        rotation: {
          from: 90,
          to: 90,
          orientations: 5,
        },
        type: "wordcloud",
        data: data.slice(0, 30),
        colors: ["#337F97", "#429fbd", "#51BFE2", "#86E4FC"],
      },
    ],
    title: {
      text: null,
    },
    tooltip: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
};

const SearchBoxComponent = (props) => {
  const { t } = useTranslation();

  const [keywordInputValue, setKeywordInputValue] = useState("");
  const [topicTags, setTopicTags] = useState([]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const openDropdown = () => setDropdownOpen((prevState) => !prevState);

  const clearInputValue = () => {
    setKeywordInputValue("");
    props.getDropdownOptions("", true);
  };

  useEffect(() => {
    if (keywordInputValue.trim()) {
      const relevance = new Set(
        props.state?.relevance.map((item) => item.label)
      );

      let uniqueTags = [...new Set([keywordInputValue.trim(), ...props.topicTags])];
      return setTopicTags(
        uniqueTags
          .map((tag) => ({
            label: tag,
            value: tag,
            applied: relevance.has(tag),
          }))
          .slice(0, 10)
      );
    }

    setTopicTags([]);
  }, [keywordInputValue, props.state.relevance, props.topicTags]);

  const {
    filters: { disabled },
  } = settings;
  return (
    <Row className="search-and-topic-tensor">
      <Col sm={props.state.showRelevance ? "12" : "8"}>
        <div className="search-block">
          <Row>
            <Col sm={props.state.showRelevance ? "7" : "12"}>
              <Row>
                <div className="col-xl-4 col-md-4 col-sm-12">
                  <div className="insta-dropdown">
                    <Field
                      name="platformVal"
                      placeholder=""
                      className="min-height48"
                      value={props.state.platformValue}
                      options={props.state.platformOptions}
                      onChange={(option) => {
                        clearInputValue();
                        props.handlePlatformChange(option);
                      }}
                      component={RenderSingleSelectWithIcon}
                    />
                  </div>
                </div>
                {!disabled.includes("keyword") && (
                  <div className="col-xl-8 col-md-8 col-sm-12">
                    <div className="post-search">
                      {props.state?.relevance.length > 0 && (
                        <span className="relevance-search-tag">
                          {props.state.relevance.length}
                        </span>
                      )}
                      <InputGroup className="search-wrap relevance-dropdown-parent mb-0">
                        <Dropdown
                          className="discovery-search"
                          isOpen={dropdownOpen && !!topicTags?.length}
                          toggle={openDropdown}
                        >
                          <DropdownToggle tag="div">
                            <Field
                              name="searchFieldValue"
                              placeholder={t("discovery.keywords")}
                              onChange={(e) => setKeywordInputValue(e.target.value.toLowerCase())}
                              onKeyUp={(e) => {
                                props.getDropdownOptions(e.target.value);
                                setDropdownOpen(true);
                              }}
                              customvalue={keywordInputValue}
                              component={RenderTextFieldWithoutAutoComplete}
                            />
                          </DropdownToggle>
                          <DropdownMenu className="ddmenu-col preview-accounts-dropdown width-md-2">
                            <Row className="no-gutters">
                              <Col
                                md={!!props.availableAccounts?.length ? 5 : 12}
                                className="dropdown-col"
                              >
                                <h2 className="dropdown-title">Keywords</h2>
                                {topicTags.map((item) => (
                                  <DropdownItem
                                    key={item.label}
                                    onClick={() => {
                                      clearInputValue();
                                      props.handleKeywordsChange(item.value);
                                    }}
                                    disabled={item.applied}
                                  >
                                    <h3
                                      title={item.label}
                                      className="dropdown-item-label"
                                    >
                                      {item.label}
                                    </h3>
                                  </DropdownItem>
                                ))}
                              </Col>
                              {!!props.availableAccounts?.length && (
                                <Col md="7" className="dropdown-col">
                                  <h2 className="dropdown-title">Creators</h2>
                                  {props.availableAccounts.map((item) => (
                                    <DropdownItem
                                      key={item.user_id}
                                      onClick={() => {
                                        clearInputValue();
                                        props.handleUsernameChange(item);
                                      }}
                                    >
                                      <Row className="d-flex align-items-center">
                                        <div className="account-image">
                                          <img
                                            src={item.picture}
                                            alt="profile pic"
                                            width="40px"
                                            height="40px"
                                            className="rounded-circle"
                                            onError={(e) =>
                                              (e.target.src = defaultImg)
                                            }
                                          />
                                        </div>
                                        <h4
                                          title={item.fullname || item.username}
                                          className="dropdown-item-label"
                                        >
                                          {item.fullname || item.username}
                                          {item.is_verified && (
                                            <CheckBadgeIcon
                                              className="h-5 w-5 ml-2 mb-1"
                                              fill="#00c2e6"
                                              width={20}
                                              height={20}
                                            />
                                          )}
                                        </h4>
                                      </Row>
                                    </DropdownItem>
                                  ))}
                                </Col>
                              )}
                            </Row>
                          </DropdownMenu>
                        </Dropdown>
                      </InputGroup>
                    </div>
                  </div>
                )}
              </Row>
              {props.state.showRelevance && (
                <div>
                  <Row className="range-row mt-4">
                    <Col lg="5">
                      <h2 className="heading-without-icon">
                        <i id="search" className="tooltip-icon-for-heading"></i>
                        Topic Tensor Legend:
                      </h2>
                      <UncontrolledTooltip
                        className="tooltip-content"
                        target={"search"}
                      >
                        {showTooltip("search")}
                      </UncontrolledTooltip>
                    </Col>
                    <Col lg="7">
                      <div className="row range-slider-wrap">
                        <span className="col-4 col-lg-3">Less Relevant</span>
                        <div className="col-4 col-lg-3 slidecontainer dots-wrap">
                          <span className="dot primary-light"></span>
                          <span className="dot primary"></span>
                          <span className="dot primary-dark"></span>
                        </div>
                        <span className="col-4 col-lg-3">More Relevant</span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="range-row mb-3">
                    <Col lg="5" className="d-none d-md-block">
                      <h2>&nbsp;</h2>
                    </Col>
                    <Col lg="7">
                      <div className="row range-slider-wrap">
                        <span className="col-4 col-lg-3">Less Frequent</span>
                        <div className="col-4 col-lg-3 slidecontainer letters-wrap">
                          <span className="letter small">A</span>
                          <span className="letter medium">A</span>
                          <span className="letter large">A</span>
                        </div>
                        <span className="col-4 col-lg-3">More Frequent</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
            {props.state.showRelevance && (
              <Col sm="5">
                <div id="topic-tensor">
                  <h2 className="heading-without-icon">Search Suggestions</h2>

                  {props.cloudTags && props.cloudTags.data ? (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={chartOptions(props)}
                    />
                  ) : (
                    <Loader className="social-cloud-loader" />
                  )}
                </div>
              </Col>
            )}
          </Row>
        </div>
      </Col>
    </Row>
  );
};
export default SearchBoxComponent;
