import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { RenderButton, RenderMutiSelect } from "../../components";

import { Field } from "formik";
import { showTitle, showTooltip } from "../../actions";
const PartnershipFilter = (props) => {
  const { t } = useTranslation();

  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2 third"
      isOpen={props.state.filters.partnership}
      toggle={() => props.toggleFilter("partnership")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {t("discovery.filters.partnerships.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-md">
        <Row>
          <Col sm="12" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span
                id="partnershipInfluencer"
                className="tooltip-icon tool-top"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="partnershipInfluencer"
              >
                {showTooltip("partnershipInfluencer")}
              </UncontrolledTooltip>
            )}
            <Field
              component={RenderMutiSelect}
              name="influencerPartnership"
              options={props.filteredInfluencerBrandOptions}
              onChange={(values) =>
                props.handleMultipleDropdownChange(
                  values,
                  "influencerPartnership"
                )
              }
              onInputChange={(value) =>
                props.filterOptions(
                  "filteredInfluencerBrandOptions",
                  "brand",
                  value
                )
              }
              placeholder={t(
                "discovery.filters.partnerships.placeholder.influencer"
              )}
              value={props.state.influencerPartnershipValues}
            />
            {props.state.influencerPartnershipValues &&
            props.state.influencerPartnershipValues.length ? (
              <Row className="selected-tags-bottom">
                <Col sm="12" className="col active-filters-wrap">
                  <div className="active-filters inside">
                    <ul className="list-inline">
                      {(props.state.influencerPartnershipValues || []).map(
                        (option, index) => (
                          <li key={index}>
                            <div className="li-influencer">
                              <p
                                title={showTitle(
                                  `inf_brands_inside_${option.value}`,
                                  option.label
                                )}
                                id={`inf_brands_inside_${option.value}`}
                              >
                                {option.label}
                              </p>{" "}
                              <RenderButton
                                close
                                onClick={() =>
                                  props.removeMultipleDropdownFilters(
                                    option,
                                    "influencerPartnershipValues"
                                  )
                                }
                              />
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default PartnershipFilter;
