import React from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";

class Verify extends React.Component {
  constructor(props) {
    super(props);

    this.login = this.login.bind(this);

    this.state = {
      success: null,
      free_report: false,
      message: "",
    };
  }
  async componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    if (values.email !== undefined && values.id !== undefined) {
      let headersOpt = {
        "content-type": "application/json",
      };

      if (values.free_report !== undefined) {
        this.setState({
          free_report: true,
        });

        await axios({
          method: "post",
          url: process.env.REACT_APP_API_URL + "/webflow/generate/report",
          headers: headersOpt,
          data: {
            email: values.email,
            confirmation_token: values.id,
          },
        })
          .then((response) => {
            this.setState({
              success: response.data.success,
              message: response.data.message,
            });
          })
          .catch((err) => {
            this.setState({
              success: err.response.data.success,
              message: err.response.data.message,
            });
          });
      } else {
        await axios({
          method: "post",
          url: process.env.REACT_APP_API_URL + "/api/auth/verify",
          headers: headersOpt,
          data: {
            email: values.email,
            confirmation_token: values.id,
          },
        })
          .then((response) => {
            this.setState({
              success: response.data.success,
              message: response.data.message,
            });
          })
          .catch((err) => {
            this.setState({
              success: err.response.data.success,
              message: err.response.data.message,
            });
          });
      }
    } else {
      this.setState({
        success: false,
        message: "No email or id provided",
      });
    }
  }

  login() {
    this.props.history.push("/");
  }

  render() {
    if (this.state.success == null) {
      return (
        <div className="auth-wrap">
          <div className="main-site-content container">
            <div className="user-form-wrap">
              <div className="page-head">
                <h1>Verifying</h1>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.success === true) {
      return (
        <div className="auth-wrap">
          <div className="main-site-content container mt-5">
            <div className="user-form-wrap">
              <div className="page-head">
                <img
                  alt=""
                  src="https://image.flaticon.com/icons/svg/148/148767.svg"
                  width="25%"
                  height="auto"
                />
                <h1 style={{ margin: "5%" }}>Success</h1>
                <p className="sub-text">{this.state.message || ""}</p>
                <br></br>
                {this.state.free_report ? null : (
                  <button className="btn btn-primary" onClick={this.login}>
                    Login
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.success === false) {
      return (
        <div className="auth-wrap">
          <div className="main-site-content container mt-5">
            <div className="user-form-wrap">
              <div className="page-head">
                <img
                  alt=""
                  src="https://image.flaticon.com/icons/svg/148/148766.svg"
                  width="25%"
                  height="auto"
                />
                <h1 style={{ margin: "5%" }}>Error</h1>
                <p className="sub-text">{this.state.message || ""}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(Verify);
