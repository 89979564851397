import React from "react";
import settings from "settings";
import { Badge, Col } from "reactstrap";

import csv from "../../img/csv_file.svg";
import hubspot from "../../img/hubspot.svg";
import googleSheet from "../../img/google-sheets.svg";

const integrationOptions = {
  csv: {
    name: ".csv",
    src: <img width="50px" src={csv} alt=""></img>,
  },
  hubspot: {
    name: "HubSpot",
    src: <img className="w-150" src={hubspot} alt=""></img>,
  },
  googlesheets: {
    name: "Sheets",
    src: <img className="w-150" src={googleSheet} alt=""></img>,
  },
};

const IntegrationSelection = (props) => {
  const {
    providers: {
      pathfix: { services },
    },
  } = settings;

  const authIntegrations =
    props.authIntegration.integrations &&
    props.authIntegration.integrations.filter((integration) =>
      services.includes(integration.type)
    );
  return (
    <div className="img-src integrations row">
      {props.authIntegration?.integrations ? (
        authIntegrations.map((integration, index) => {
          return (
            <Col
              md={12 / authIntegrations.length}
              sm="12"
              className="p-0 center-int"
              key={integration.type}
            >
              <div
                key={integration.type}
                onClick={() => props.handleChangeFile(integration.type)}
                className={
                  integration.enabled
                    ? props.state.saveFileType === integration.type
                      ? "img-png hun-1 active"
                      : "img-png hun-1"
                    : "img-png hun-1 disabled"
                }
              >
                <span>{integrationOptions[integration.type].src}</span>
                {integrationOptions[integration.type].name}
                {integration.enabled && !integration.oauth && (
                  <Badge
                    className={`fs--2 rank-wrap badge-soft-neutral connect`}
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_PATHFIX_AUTH_URL}/integrate/command?provider=${integration.type}&public_key=${process.env.REACT_APP_PATHFIX_PUBLIC_ID}&user_id=${props.userId}`
                      )
                    }
                    data-dismiss="modal"
                  >
                    Connect
                  </Badge>
                )}
                {!integration.enabled && !integration.oauth && (
                  <Badge
                    className={`fs--2 rank-wrap badge-soft-danger coming-soon`}
                  >
                    Coming Soon
                  </Badge>
                )}
              </div>
            </Col>
          );
        })
      ) : (
        <span className="text-center text-grey col-md-12">
          <p className="pt-50">No integrations Found</p>
        </span>
      )}
    </div>
  );
};
export default IntegrationSelection;
