import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  formatNumbersToCommaSeperated,
  showTooltip,
} from "../../../../actions";
import { RenderButton } from "../../../../components";

const chartOptions = (props) => {
  let data =
    props.accountOverviewDetails &&
    props.accountOverviewDetails.audience_likers &&
    props.accountOverviewDetails.audience_likers.data &&
    props.accountOverviewDetails.audience_likers.data.audience_genders_per_age
      ? props.accountOverviewDetails.audience_likers.data.audience_genders_per_age.map(
          (obj, index) => obj
        )
      : [];
  let maleData = data.map((obj, index) => [
    obj.code,
    parseFloat((obj.male * 100).toFixed(2)),
  ]);
  let femaleData = data.map((obj, index) => [
    obj.code,
    parseFloat((obj.female * 100).toFixed(2)),
  ]);
  let categories = data.map((obj, index) => obj.code.replace("-", " — "));

  maleData.pop();
  femaleData.pop();
  categories.pop();

  return {
    chart: {
      type: "column",
      height: 250,
      backgroundColor: null,
    },

    title: null,
    xAxis: {
      lineWidth: 0,
      type: "category",
      tickColor: "transparent",
      categories: categories,
      labels: {
        style: {
          color: "#888",
          fontSize: "14px",
          fontFamily: "'HKGrotesk-Regular', Arial, 'sans-serif'",
        },
      },
    },
    yAxis: {
      lineWidth: 0,
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      stackLabels: {
        enabled: props.showStackLabels,
        verticalAlign: "top",
        allowOverlap: props.showStackLabels,
        formatter: function () {
          return this.total + "%";
        },
        style: {
          color: "#888",
          fontSize: "12px",
          fontWeight: "600",
          fontFamily: "'HKGrotesk-Bold', Arial, 'sans-serif'",
          textOutline: "none",
        },
      },

      title: {
        text: null,
      },
    },
    plotOptions: {
      column: {
        animation: false,
        stacking: "normal",
        borderWidth: 0,
        borderRadius: 2,
        enableMouseTracking: false,
      },
    },
    series: [
      {
        color: "#1f87ee",
        clip: false,
        pointWidth: window.innerWidth < 1024 ? 15 : 20,
        name: "Male",
        stack: "male",
        data: maleData,
      },
      {
        color: "#ff5537",
        clip: false,
        pointWidth: window.innerWidth < 1024 ? 15 : 20,
        name: "Female",
        stack: "female",
        data: femaleData,
      },
    ],
    tooltip: { enabled: false },
    legend: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
  };
};
const LikesAgeAndGenderSplit = (props) => {
  const { t } = useTranslation();

  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>
          <span className="tooltip-button-wrap">
            <span className="text-tooltip">
              {t("reports.audience_details.age_gender.title")}
            </span>
            <span
              href="#"
              id="likesAgeAndGenderSplit"
              className="tooltip-icon"
            ></span>
          </span>
          {props.tooltip && (
            <UncontrolledTooltip
              placement="bottom"
              className="tooltip-content"
              target="likesAgeAndGenderSplit"
            >
              {showTooltip("likesAgeAndGenderSplit")}
            </UncontrolledTooltip>
          )}
        </CardTitle>
        <div className="card-content ch-300">
          <div className="graph-wrap">
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions(props)}
            />
          </div>
        </div>
        <div className="bottom">
          <Button
            color="link"
            onClick={() => props.toggleModal("likesAgeAndGenderSplitModal")}
          >
            {t("reports.view_more")}
          </Button>

          <Modal
            id="likesAgeAndGenderSplitModalDownload"
            isOpen={props.modals.likesAgeAndGenderSplitModal}
            toggle={() => props.toggleModal("likesAgeAndGenderSplitModal")}
          >
            <ModalHeader
              toggle={() => props.toggleModal("likesAgeAndGenderSplitModal")}
            >
              <span className="tooltip-button-wrap">
                <span className="text-tooltip">
                  {t("reports.audience_details.age_gender.title")}
                </span>
                <span
                  href="#"
                  id="likesAgeAndGenderSplitModal"
                  className="tooltip-icon"
                ></span>
              </span>
              {props.tooltip && (
                <UncontrolledTooltip
                  placement="bottom"
                  className="tooltip-content"
                  target="likesAgeAndGenderSplitModal"
                >
                  {showTooltip("likesAgeAndGenderSplitModal")}
                </UncontrolledTooltip>
              )}
              <RenderButton
                onClick={() =>
                  props.downloadPdf("likesAgeAndGenderSplitModalDownload")
                }
                className="download-icon-button mg-left-15 link"
              >
                {t("reports.download")}
              </RenderButton>
            </ModalHeader>
            <ModalBody>
              <div className="multiple-tbl">
                <h4>{t("reports.audience_details.age_gender.age")}</h4>
                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th>{t("reports.chart.age")}</th>
                      <th className="text-right">
                        {t("reports.chart.followers")}
                      </th>
                      <th className="text-right">{t("reports.chart.likes")}</th>
                      <th className="text-right">
                        {t("reports.chart.percentage")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.accountOverviewDetails &&
                    props.accountOverviewDetails.audience_likers &&
                    props.accountOverviewDetails.audience_likers.data &&
                    props.accountOverviewDetails.audience_likers.data
                      .audience_genders_per_age ? (
                      props.accountOverviewDetails.audience_likers.data.audience_genders_per_age.map(
                        (obj, index) => (
                          <tr key={index}>
                            <th scope="row">{obj.code}</th>
                            <td className="text-right">
                              {formatNumbersToCommaSeperated(
                                (obj.male + obj.female) *
                                  props.accountOverviewDetails.user_profile
                                    .followers
                              )}
                            </td>
                            <td className="text-right">
                              {formatNumbersToCommaSeperated(
                                (obj.male + obj.female) *
                                  props.accountOverviewDetails.user_profile
                                    .avg_likes
                              )}
                            </td>
                            <td className="text-right">
                              {((obj.male + obj.female) * 100).toFixed(2) + "%"}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td align="center" colSpan="4">
                          {t("errors.no_record")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              <div className="multiple-tbl">
                <h4>{t("reports.audience_details.age_gender.female")}</h4>
                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th>{t("reports.chart.age")}</th>
                      <th className="text-right">
                        {t("reports.chart.followers")}
                      </th>
                      <th className="text-right">{t("reports.chart.likes")}</th>
                      <th className="text-right">
                        {t("reports.chart.percentage")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.accountOverviewDetails &&
                    props.accountOverviewDetails.audience_likers &&
                    props.accountOverviewDetails.audience_likers.data &&
                    props.accountOverviewDetails.audience_likers.data
                      .audience_genders_per_age ? (
                      props.accountOverviewDetails.audience_likers.data.audience_genders_per_age.map(
                        (obj, index) => (
                          <tr key={index}>
                            <th scope="row">{obj.code}</th>
                            <td className="text-right">
                              {formatNumbersToCommaSeperated(
                                obj.female *
                                  props.accountOverviewDetails.user_profile
                                    .followers
                              )}
                            </td>
                            <td className="text-right">
                              {formatNumbersToCommaSeperated(
                                obj.female *
                                  props.accountOverviewDetails.user_profile
                                    .avg_likes
                              )}
                            </td>
                            <td className="text-right">
                              {(obj.female * 100).toFixed(2) + "%"}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td align="center" colSpan="4">
                          {t("errors.no_record")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              <div className="multiple-tbl">
                <h4>{t("reports.audience_details.age_gender.male")}</h4>
                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th>{t("reports.chart.age")}</th>
                      <th className="text-right">
                        {t("reports.chart.followers")}
                      </th>
                      <th className="text-right">{t("reports.chart.likes")}</th>
                      <th className="text-right">
                        {t("reports.chart.percentage")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.accountOverviewDetails &&
                    props.accountOverviewDetails.audience_likers &&
                    props.accountOverviewDetails.audience_likers.data &&
                    props.accountOverviewDetails.audience_likers.data
                      .audience_genders_per_age ? (
                      props.accountOverviewDetails.audience_likers.data.audience_genders_per_age.map(
                        (obj, index) => (
                          <tr key={index}>
                            <th scope="row">{obj.code}</th>
                            <td className="text-right">
                              {formatNumbersToCommaSeperated(
                                obj.male *
                                  props.accountOverviewDetails.user_profile
                                    .followers
                              )}
                            </td>
                            <td className="text-right">
                              {formatNumbersToCommaSeperated(
                                obj.male *
                                  props.accountOverviewDetails.user_profile
                                    .avg_likes
                              )}
                            </td>
                            <td className="text-right">
                              {(obj.male * 100).toFixed(2) + "%"}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td align="center" colSpan="4">
                          {t("errors.no_record")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </CardBody>
    </Card>
  );
};

export default LikesAgeAndGenderSplit;
