import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { RenderButton } from '../../components';


const viewReportModal = (props) => {
    return (
        <Modal isOpen={props.toggleViewReportModal} toggle={props.toggleViewReportModalFunction}>
            <ModalHeader toggle={props.toggleViewReportModalFunction}>
                <div className="text-center unlock-more-modal-heading-section">
                    <h1 className="unlock-more-modal-heading">View Tensor Report?</h1>
                </div>
            </ModalHeader>
            <ModalBody className="confirm-modal">
                <p className="unlock-more-modal-subheading">Do you want to open Audience Report<span></span></p>

                <div className="d-flex justify-content-center">
                    <RenderButton className="btn btn-primary mr-1" onClick={props.openNewTab}>Continue</RenderButton>
                    <RenderButton className="btn btn-outline-primary ml-1" onClick={props.toggleViewReportModalFunction}> Cancel</RenderButton>
                </div>
            </ModalBody>

        </Modal>
    )
}
export default viewReportModal

