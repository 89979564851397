import { identificationActionTypes } from "../constants";
import { COMMON_ERROR_MESSAGE } from "../../../actions";

const initialState = {
  loading: false,
  searchingInfluencers: false,
  influencerRecords: null,
  audiencesFilteredLoactions: [],
  influencersFilteredLocations: [],
  tagsErrorMSG: null,
  cloudTags: null,
  topicTags: null,
  availableAccounts: null,
  showAllRecords: false,
  influencerFilteredUniversities: [],
  checkExportsRemaining: null,
  dontCallInfluencersAPI: false,
  filteredInfluencerBrandOptions: null,
  filteredAudienceBrandOptions: null,
  filteredInfluencerLookalikeOptions: null,
  filteredAudienceLookalikeOptions: null,
  filteredAudienceInterestOptions: null,
  filteredInfluencerInterestOptions: null,
  globalCreatorDatabaseTotalCount: null,
  blockRecords: false,
  exportsErrorMSG: null,
  disableScroll: false,
  authIntegration: {},
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case identificationActionTypes.dontCallInitialEmptyFiltersAPIForInfluencers
      .SUCCESS:
      return {
        ...state,
        dontCallInfluencersAPI: true,
      };
    case identificationActionTypes.influencers_list.REQUEST:
      return {
        ...state,
        blockRecords: true,
      };
    case identificationActionTypes.influencers_list.SUCCESS:
      return {
        ...state,
        blockRecords: false,
        influencerRecords: payload,
        showAllRecords: false,
      };
    case identificationActionTypes.influencers_list.FAILURE:
      return {
        ...state,
        blockRecords: false,
        influencerRecords: [],
        showAllRecords: false,
      };
    case identificationActionTypes.getAvailableAccountsTotal.REQUEST:
      return {
        ...state,
        globalCreatorDatabaseTotalCount: null,
      };
    case identificationActionTypes.getAvailableAccountsTotal.SUCCESS:
      return {
        ...state,
        globalCreatorDatabaseTotalCount: payload?.total,
      };
    case identificationActionTypes.getAvailableAccountsTotal.FAILURE:
      return {
        ...state,
        globalCreatorDatabaseTotalCount: null,
      };
    case identificationActionTypes.unlock_more_results.REQUEST:
      return {
        ...state,
        searchingInfluencers: true,
        disableScroll: true,
      };
    case identificationActionTypes.unlock_more_results.SUCCESS:
      return {
        ...state,
        searchingInfluencers: false,
        influencerRecords: payload,
        exportsErrorMSG: null,
        showAllRecords: true,
        disableScroll: false,
      };
    case identificationActionTypes.unlock_more_results.FAILURE:
      return {
        ...state,
        searchingInfluencers: false,
        influencerRecords: [],
        exportsErrorMSG: payload || COMMON_ERROR_MESSAGE,
        showAllRecords: true,
        disableScroll: false,
      };

    case identificationActionTypes.post_exports_csv.REQUEST:
      return {
        ...state,
        loading: false,
        disableScroll: true,
      };
    case identificationActionTypes.post_exports_csv.SUCCESS:
      return {
        ...state,
        loading: false,
        disableScroll: false,
      };
    case identificationActionTypes.post_exports_csv.FAILURE:
      return {
        ...state,
        loading: false,
        disableScroll: false,
      };
    case identificationActionTypes.checkExportsRemaining.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case identificationActionTypes.checkExportsRemaining.SUCCESS:
      return {
        ...state,
        loading: false,
        checkExportsRemaining: payload,
      };
    case identificationActionTypes.checkExportsRemaining.FAILURE:
      return {
        ...state,
        loading: false,
        checkExportsRemaining: null,
      };
    case identificationActionTypes.getLocations.REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case identificationActionTypes.getLocations.SUCCESS: {
      let modifiedState = state;
      if (payload.filterName === "initial") {
        modifiedState.audiencesFilteredLoactions = payload;
        modifiedState.influencersFilteredLocations = payload;
      } else {
        modifiedState[payload.filterName] = payload;
      }

      return {
        ...modifiedState,
        // loading: false,
      };
    }
    case identificationActionTypes.getLocations.FAILURE:
      return {
        ...state,
        // loading: false,
        influencerRecords: [],
      };
    case identificationActionTypes.getCloudTags.REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case identificationActionTypes.getCloudTags.SUCCESS:
      return {
        ...state,
        // loading: false,
        cloudTags: payload,
      };
    case identificationActionTypes.getCloudTags.FAILURE:
      return {
        ...state,
        // loading: false,
        cloudTags: null,
      };
    case identificationActionTypes.getTopicTags.REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case identificationActionTypes.getTopicTags.SUCCESS:
      return {
        ...state,
        // loading: false,
        topicTags: payload,
      };
    case identificationActionTypes.getTopicTags.FAILURE:
      return {
        ...state,
        // loading: false,
        topicTags: null,
      };
    case identificationActionTypes.getAvailableAccounts.REQUEST:
      return {
        ...state,
      };
    case identificationActionTypes.getAvailableAccounts.SUCCESS:
      return {
        ...state,
        availableAccounts: payload,
      };
    case identificationActionTypes.getAvailableAccounts.FAILURE:
      return {
        ...state,
        availableAccounts: null,
      };
    case identificationActionTypes.showLoading.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case identificationActionTypes.showLoading.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case identificationActionTypes.showTagsErrorMSG.SUCCESS:
      return {
        ...state,
        loading: false,
        tagsErrorMSG: payload,
      };
    case identificationActionTypes.clearTagsErrorMSG.SUCCESS:
      return {
        ...state,
        loading: false,
        tagsErrorMSG: null,
      };
    case identificationActionTypes.clearExportsErrorMSG.SUCCESS:
      return {
        ...state,
        loading: false,
        exportsErrorMSG: null,
      };

    case identificationActionTypes.getUniversities.REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case identificationActionTypes.getUniversities.SUCCESS: {
      return {
        ...state,
        // loading: false,
        influencerFilteredUniversities: payload,
      };
    }
    case identificationActionTypes.getUniversities.FAILURE:
      return {
        ...state,
        // loading: false,
        influencerFilteredUniversities: [],
      };
    case identificationActionTypes.getFilteredBrands.REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case identificationActionTypes.getFilteredBrands.SUCCESS:
      if (payload.optionListName === "initial") {
        return {
          ...state,
          filteredAudienceBrandOptions: payload,
          filteredInfluencerBrandOptions: payload,
          // loading: false,
        };
      } else {
        return {
          ...state,
          [payload.optionListName]: payload,
          // loading: false,
        };
      }
    case identificationActionTypes.getFilteredBrands.FAILURE:
      return {
        ...state,
        // loading: false,
        filteredInfluencerBrandOptions: null,
        filteredAudienceBrandOptions: null,
      };
    case identificationActionTypes.getFilteredLookalikes.REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case identificationActionTypes.getFilteredLookalikes.SUCCESS:
      if (payload.optionListName === "initial") {
        return {
          ...state,
          filteredAudienceLookalikeOptions: payload,
          filteredInfluencerLookalikeOptions: payload,
          // loading: false,
        };
      } else {
        return {
          ...state,
          [payload.optionListName]: payload,
          // loading: false,
        };
      }
    case identificationActionTypes.getFilteredLookalikes.FAILURE:
      return {
        ...state,
        // loading: false,
        filteredInfluencerLookalikeOptions: null,
        filteredAudienceLookalikeOptions: null,
      };
    case identificationActionTypes.getFilteredInterests.REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case identificationActionTypes.getFilteredInterests.SUCCESS:
      if (payload.optionListName === "initial") {
        return {
          ...state,
          filteredAudienceInterestOptions: payload,
          filteredInfluencerInterestOptions: payload,
          // loading: false,
        };
      } else {
        return {
          ...state,
          [payload.optionListName]: payload,
          // loading: false,
        };
      }
    case identificationActionTypes.getFilteredInterests.FAILURE:
      return {
        ...state,
        // loading: false,
        filteredAudienceInterestOptions: null,
        filteredInfluencerInterestOptions: null,
      };

    case identificationActionTypes.get_auth_integration.SUCCESS:
      return {
        ...state,
        // loading: false,
        authIntegration: payload,
      };

    default:
      return state;
  }
};
