import React from "react";
import { Row, Col } from "reactstrap";
import settings from "settings";

const Integrations = (props) => {
  return (
    <Row>
      <Col className="text-wrapper">
        <h1 className="page-heading">Integrations</h1>
        <p className="sub-text text-grey">
          Connect your accounts to {settings.versionName}
        </p>
        <div
          className="integrations-container p-0 mt-2"
          data-oauth-button-color="#1f87ee"
          data-oauth-ui="list-panel"
          data-oauth-ui-switches="statusOn,disconnect"
          data-oauth-icon-size="Medium"
          data-oauth-ui-providers="googlesheets,hubspot"
        ></div>
      </Col>
    </Row>
  );
};

export default Integrations;
