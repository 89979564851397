import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import {
  ChevronDownIcon,
  EllipsisVerticalIcon,
  PlusIcon,
  TrashIcon,
  UserPlusIcon,
} from "@heroicons/react/20/solid";
import ShareCampaignModal from "../modals/ShareCampaignModal";

const CampaignActionsDropdown = ({
  campaign,
  deleteCampaignHandler,
  openNewPostModalHandler,
  dropdownStyle = "icon",
  ...props
}) => {
  const [shareCampaignView, setShareCampaignView] = useState(false);

  const toggleShareCampaignView = () => {
    setShareCampaignView(!shareCampaignView);
  };

  return (
    <>
      {campaign && !campaign.isShared && (
        <UncontrolledDropdown className="campaign-dropdown">
          <ShareCampaignModal
            isOpen={shareCampaignView}
            toggleModal={toggleShareCampaignView}
            campaign={campaign}
            key={`${campaign?._id}-share-dropdown`}
          />

          <DropdownToggle
            className={`btn btn-dropdown mr-4 ${
              dropdownStyle === "button" ? "btn-default btn-dropdown-white btn-outline-primary" : ""
            }`}
            id={`action-popover-${campaign._id}`}
          >
            {dropdownStyle === "button" ? (
              <>
                Actions
                <ChevronDownIcon
                  className="icon-btn"
                  width={20}
                  height={20}
                  strokeWidth={2}
                />
              </>
            ) : (
              <EllipsisVerticalIcon
                className="icon-btn"
                width={20}
                height={20}
                strokeWidth={2}
              />
            )}
          </DropdownToggle>
          <DropdownMenu className="campaign-dropdown-menu">
            <DropdownItem
              disabled={campaign?.isShared}
              className="btn btn-popover"
              onClick={() => toggleShareCampaignView()}
            >
              <UserPlusIcon
                width={20}
                height={20}
                strokeWidth={2}
              ></UserPlusIcon>
              <span>Share Campaign</span>
            </DropdownItem>
            <DropdownItem
              className="btn btn-popover"
              onClick={() =>
                openNewPostModalHandler({ campaign, collectUrl: true })
              }
            >
              <PlusIcon width={20} height={20} strokeWidth={2}></PlusIcon>
              <span>Add Post</span>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              className="btn btn-popover delete"
              onClick={() => deleteCampaignHandler(campaign._id)}
            >
              <TrashIcon width={20} height={20} strokeWidth={2}></TrashIcon>
              <span>Delete Campaign</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </>
  );
};

export default CampaignActionsDropdown;
