import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import {
  ChevronDownIcon,
  EllipsisVerticalIcon,
  TrashIcon,
  UserPlusIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import ShareListModal from "./modals/shareCreatorListModal";
import history from "../../history";

const CreatorListsDropdown = ({ creatorList, deleteCreatorList, dropdownStyle = "icon", ...props }) => {
  const [shareListView, setShareListView] = useState(false);

  const toggleShareListView = () => {
    setShareListView(!shareListView);
  };

  return (
    <UncontrolledDropdown className="creator-list-dropdown">
      <ShareListModal
        isOpen={shareListView}
        toggleModal={toggleShareListView}
        creatorList={creatorList}
        key={`${creatorList?._id}-share`}
      />
      <DropdownToggle
        className={`btn btn-dropdown mr-4 ${
          dropdownStyle === "button"
            ? "btn-default btn-dropdown-white btn-outline-primary"
            : ""
        }`}
        id={`action-popover-${creatorList._id}`}
      >
        {dropdownStyle === "button" ? (
          <>
            Actions
            <ChevronDownIcon
              className="icon-btn"
              width={20}
              height={20}
              strokeWidth={2}
            />
          </>
        ) : (
          <EllipsisVerticalIcon
            className="icon-btn"
            width={20}
            height={20}
            strokeWidth={2}
          />
        )}
      </DropdownToggle>
      <DropdownMenu className="creator-dropdown-menu">
        <DropdownItem
          disabled={creatorList?.isShared}
          className="btn btn-popover"
          onClick={() => toggleShareListView()}
        >
          <UserPlusIcon width={20} height={20} strokeWidth={2}></UserPlusIcon>
          <span>Share</span>
        </DropdownItem>
        <DropdownItem
          disabled={creatorList?.isShared}
          className="btn btn-popover"
          onClick={() => history.push("/discovery")}
        >
          <PlusIcon width={20} height={20} strokeWidth={2}></PlusIcon>
          <span>Add Creators</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          className="btn btn-popover delete"
          onClick={() => deleteCreatorList(creatorList._id)}
        >
          <TrashIcon width={20} height={20} strokeWidth={2}></TrashIcon>
          <span>Delete</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default CreatorListsDropdown;
