import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "./index.scss";
import settings from "settings";
import { connect } from "react-redux";
import {
  getPastExportsReports,
  saveListSearchFilters,
  getPastExportsPosts,
  getPastExportsLists,
  deleteReportIdAction,
  deletePostIdAction,
  deleteListIdAction,
  downloadReportPdfAction,
} from "./actions/index";
import history from "../../history";
import ListsTab from "./pastExportsTabs/listsTab";
import ReportsTab from "./pastExportsTabs/reportsTab";
import { exportCSVById } from "../identification/actions/index";
import TrialBanner from "components/banners/TrialBanner";

class PastReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      activeReportsPage: 1,
      activePostsPage: 1,
      activeListsPage: 1,
      isModalOpen: false,
      isDeleteId: "",
      isDeleteType: "",
      reportPage: 1,
      postPage: 1,
      listPage: 1,
    };
  }
  componentDidMount() {
    const { reportPage, listPage } = this.state;
    this.props.getPastExportsReports(reportPage, 5);
    this.props.getPastExportsLists(listPage, 5);
  }
  handleReportsAnalyze = (obj) => {
    const reportUrl = `${process.env.REACT_APP_URL}/reports/${obj.report_id}`;
    let newReportTab = window.open(reportUrl, "_blank");
    if (newReportTab) return;
    history.push(`/reports/${obj.report_id}`);
  };
  handlePostAnalyze = (post) => {
    history.push(`/campaigns/${post.report_id}`);
  };
  handleExport = (id) => {
    this.props.exportCSVById(id);
  };
  toggle = (value) => {
    this.setState({
      activeTab: value.toString(),
    });
  };
  handlePageChange = (pageNumber, type) => {
    if (type.toUpperCase() === "REPORTS") {
      this.setState({
        activeReportsPage: pageNumber,
      });
      this.props.getPastExportsReports(pageNumber);
    } else if (type.toUpperCase() === "POSTS") {
      this.setState({
        activePostsPage: pageNumber,
      });
      this.props.getPastExportsPosts(pageNumber);
    } else if (type.toUpperCase() === "LISTS") {
      this.setState({
        activeListsPage: pageNumber,
      });
      this.props.getPastExportsLists(pageNumber);
    }
  };

  handlePageChangeReport = (pageNumber) => {
    const { getPastExportsReports } = this.props;
    this.setState({
      activeReportsPage: pageNumber,
    });
    getPastExportsReports(pageNumber, 5);
  };

  handlePageChangeList = (pageNumber) => {
    const { getPastExportsLists } = this.props;
    this.setState({
      activeListsPage: pageNumber,
    });
    getPastExportsLists(pageNumber, 5);
  };

  searchAgain = (searchObj) => {
    this.props.saveListSearchFilters(searchObj);
    history.push("/discovery");
  };
  deleteReport = (reportId) => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      isDeleteId: reportId,
      isDeleteType: "reportType",
    }));
  };
  deletePost = (id) => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      isDeleteId: id,
      isDeleteType: "postType",
    }));
  };
  deleteListId = (id) => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      isDeleteId: id,
      isDeleteType: "listType",
    }));
  };
  toggleCancelDeleteModal = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };
  confirmDelete = (e) => {
    const {
      isDeleteType,
      isDeleteId,
      activePostsPage,
      activeListsPage,
      activeReportsPage,
    } = this.state;
    if (isDeleteType === "reportType") {
      this.props.deleteReportIdAction({
        reportId: isDeleteId,
        pageNumber: activeReportsPage,
      });
    } else if (isDeleteType === "postType") {
      this.props.deletePostIdAction({
        postId: isDeleteId,
        pageNumber: activePostsPage,
      });
    } else if (isDeleteType === "listType") {
      this.props.deleteListIdAction({
        listId: isDeleteId,
        pageNumber: activeListsPage,
      });
    }
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      isDeleteId: "",
      isDeleteType: "",
      activeListsPage: activeListsPage,
      activePostsPage: activePostsPage,
      activeReportsPage: activeReportsPage,
    }));
  };
  downloadReportPdf = async (reportId) => {
    await this.props.downloadReportPdfAction(reportId);
  };
  render() {
    return (
      <Container className="main-site-content past-exports">
        {this.props.subscriptionDetails?.status === "trialing" &&
        settings.providers.calendly.enabled ? (
          <TrialBanner type="activity" loginUser={this.props.loginUser} />
        ) : null}
        <Row className="page-header">
          <Col className="is-search-block">
            <h1 className="page-title">Activity</h1>

            <ReportsTab
              {...this.props}
              handlePageChange={this.handlePageChangeReport}
              handleReportsAnalyze={this.handleReportsAnalyze}
              state={this.state}
              limit={5}
            />

            <ListsTab
              {...this.props}
              searchAgain={this.searchAgain}
              handleExport={this.handleExport}
              handlePageChange={this.handlePageChangeList}
              state={this.state}
              limit={5}
            />

            {/*<div className="follower-like-tabs">
              <Nav pills>
                <NavItem>
                  <ReactStrapNavLink
                    className={
                      this.state.activeTab === "0" ? "active first" : "first"
                    }
                    onClick={() => this.toggle("0")}
                  >
                    Exports
                  </ReactStrapNavLink>
                </NavItem>
                <NavItem>
                  <ReactStrapNavLink
                    className={this.state.activeTab === "1" ? "active" : ""}
                    onClick={() => this.toggle("1")}
                  >
                    Reports
                  </ReactStrapNavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <ListsTab
                  {...this.props}
                  searchAgain={this.searchAgain}
                  handleExport={this.handleExport}
                  handlePageChange={this.handlePageChange}
                  state={this.state}
                  limit={5}
                  deleteListId={this.deleteListId}
                />
                <ReportsTab
                  {...this.props}
                  handlePageChange={this.handlePageChange}
                  handleReportsAnalyze={this.handleReportsAnalyze}
                  state={this.state}
                  limit={5}
                  deleteReport={this.deleteReport}
                  downloadReportPdf={this.downloadReportPdf}
                />
              </TabContent>
                  </div>*/}
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    subscriptionDetails: state.login.subscriptionDetails,
    pastExportsReports: state.userProfile.pastExportsReports,
    pastExportsPosts: state.userProfile.pastExportsPosts,
    pastExportsLists: state.userProfile.pastExportsLists,
  };
};
export default connect(mapStateToProps, {
  getPastExportsReports,
  getPastExportsPosts,
  getPastExportsLists,
  exportCSVById,
  deleteReportIdAction,
  deletePostIdAction,
  deleteListIdAction,
  downloadReportPdfAction,
  saveListSearchFilters,
})(PastReports);
