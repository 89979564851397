import React from 'react'
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem, Button, Modal, ModalHeader, ModalBody, Table, UncontrolledTooltip } from 'reactstrap';
import { capitalize, formatNumbersToCommaSeperated, showTitle } from '../../../../actions';
import { RenderButton } from '../../../../components';
const LikesNotableUsers = (props) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>Notable Followers</CardTitle>
        <div className="card-content ch-9">
          {props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.notable_users &&
            props.postAnalysisDetails.posts_profile.notable_users.length ?
            <ListGroup className="card-list-items">
              {
                props.postAnalysisDetails.posts_profile.notable_users.map((user, index) => {
                  if (index < 3) {
                    return <ListGroupItem key={index}><div className="avatar"><img src={user.picture} alt="img" /></div>
                      <div id={`likes_${user.fullname}`} title={showTitle(`likes_${user.fullname}`, user.fullname)} className="list-content"> <a rel="noopener noreferrer" target="_blank" href={user.url}>{'@' + user.username}</a>
                        <p>{capitalize(user.fullname)}</p>
                      </div>
                    </ListGroupItem>
                  }
                  return null
                })}
            </ListGroup>
            : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}
        </div>
        {props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.notable_users &&
          props.postAnalysisDetails.posts_profile.notable_users.length ?
          <div className="bottom">
            <Button color="link" onClick={() => props.toggleModal("likesNotableUsersModal")}>View More</Button>

            <Modal id="likesNotableFiltersModalDownload" isOpen={props.state.modals.likesNotableUsersModal} toggle={() => props.toggleModal("likesNotableUsersModal")} >
              <ModalHeader toggle={() => props.toggleModal("likesNotableUsersModal")}>
                <span className="tooltip-button-wrap"><span className="text-tooltip">Notable Followers</span>  <span href="#" id="likesNotableUsersModal" className="tooltip-icon"></span></span>
                <UncontrolledTooltip placement="bottom" className="tooltip-content" target="likesNotableUsersModal" >
                  Hello world!
              </UncontrolledTooltip>
                <RenderButton onClick={() => props.downloadPdf("likesNotableFiltersModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
              </ModalHeader>
              <ModalBody>
                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th colSpan="2">Influencer</th>
                      <th className="text-right">Likes</th>
                      <th className="text-right">Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.notable_users ?
                      props.postAnalysisDetails.posts_profile.notable_users.map((user, index) => {

                        return <tr key={index}>
                          <th scope="row" className="tb-media-left"><img src={user.picture} alt="profile pic" className="rounded-circle" /></th>
                          <td className="tb-head"><h4> <a rel="noopener noreferrer" target="_blank" href={user.url}>{'@' + user.username}</a></h4>
                            <div className="country-text-wrap">
                              <div className="country-name">{user.fullname}</div>
                            </div>
                          </td>
                          <td className="text-right">{formatNumbersToCommaSeperated(user.engagements)}</td>
                          <td className="text-right">{user.created ? 'Yes' : 'No'}</td>
                        </tr>

                      }) : <tr>
                        <td align="center" colSpan="4">No Record Found</td>
                      </tr>}
                  </tbody>
                </Table>
              </ModalBody>

            </Modal>
          </div>
          : null}
      </CardBody>
    </Card>
  )
}

export default LikesNotableUsers
