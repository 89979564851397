import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { RenderSingleSelect } from "../../components";
import { Field } from "formik";
import {
  followers_engage_range_left,
  followers_engage_range_right,
} from "../../helpers/Followers";
import { engagement_rate } from "../../helpers/engagementRateOptions";
// import engagement_rate_operators from '../../helpers/engagementRateOperators';
import { showTooltip } from "../../actions";

const EngagementFilter = (props) => {
  const { t } = useTranslation();

  let arr1 = [...followers_engage_range_left];
  let arr2 = [...followers_engage_range_right];

  const from_options = arr1.map((option) => {
    if (
      props.state.engagementsInfluencersTo &&
      props.state.engagementsInfluencersTo.value &&
      props.state.engagementsInfluencersTo.value <= option.value
    ) {
      option.isDisabled = true;
    } else {
      option.isDisabled = false;
    }
    return option;
  });

  const to_options = arr2.map((option) => {
    if (
      props.state.engagementsInfluencersFrom &&
      props.state.engagementsInfluencersFrom.value &&
      props.state.engagementsInfluencersFrom.value >= option.value
    ) {
      option.isDisabled = true;
    } else {
      option.isDisabled = false;
    }
    return option;
  });

  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2"
      isOpen={props.state.filters.engagements}
      toggle={() => props.toggleFilter("engagements")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {t("discovery.filters.engagements.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-md">
        <Row>
          <Col md="12" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span
                id="engagementInfluencer"
                className="tooltip-icon tool-top"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="engagementInfluencer"
              >
                {showTooltip("engagementInfluencer")}
              </UncontrolledTooltip>
            )}
            <FormGroup className="select-field-col">
              <Field
                component={RenderSingleSelect}
                name="engagementsInfluencersFrom"
                placeholder={t("discovery.fields.from")}
                value={props.state.engagementsInfluencersFrom}
                onChange={(input) =>
                  props.handleSingleSelectChange(
                    input,
                    "engagementsInfluencersFrom"
                  )
                }
                options={from_options}
              />
            </FormGroup>
            <FormGroup className="select-field-col last">
              <Field
                component={RenderSingleSelect}
                name="engagementsInfluencersTo"
                placeholder={t("discovery.fields.to")}
                value={props.state.engagementsInfluencersTo}
                onChange={(input) =>
                  props.handleSingleSelectChange(
                    input,
                    "engagementsInfluencersTo"
                  )
                }
                options={to_options}
              />
            </FormGroup>
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head">
                  {t("discovery.filters.engagements.rate")}
                </h3>
              </span>
              <span
                id="engagementRate"
                className="tooltip-icon tool-top"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="engagementRate"
              >
                {showTooltip("engagementRate")}
              </UncontrolledTooltip>
            )}
            <FormGroup className="select-field-col full full last">
              <Field
                component={RenderSingleSelect}
                name="engagementRate"
                placeholder={t("discovery.fields.from")}
                value={props.state.engagementRate}
                onChange={(input) =>
                  props.handleSingleSelectChange(input, "engagementRate")
                }
                options={engagement_rate}
              />
            </FormGroup>
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default EngagementFilter;
