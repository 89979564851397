import React from 'react'

import { RenderButton, RenderTextField } from '../../components';
import { Field, Formik, Form } from 'formik';
const SubscriptionPlans = (props) => {


  const growthPlan = props.subscriptionPlansDetails && props.subscriptionPlansDetails.data && props.subscriptionPlansDetails.data.monthly && props.subscriptionPlansDetails.data.monthly.find(plan => plan.nickname && plan.nickname.toLowerCase() === 'growth');
  const standardPlan = props.subscriptionPlansDetails && props.subscriptionPlansDetails.data && props.subscriptionPlansDetails.data.monthly && props.subscriptionPlansDetails.data.monthly.find(plan => plan.nickname && plan.nickname.toLowerCase() === 'standard');
  const professionalPlan = props.subscriptionPlansDetails && props.subscriptionPlansDetails.data && props.subscriptionPlansDetails.data.monthly && props.subscriptionPlansDetails.data.monthly.find(plan => plan.nickname && plan.nickname.toLowerCase() === 'professional');
  const meteredReportsPlans = props.subscriptionPlansDetails && props.subscriptionPlansDetails.data && props.subscriptionPlansDetails.data.metered && props.subscriptionPlansDetails.data.metered.filter(plan => plan.nickname && plan.nickname.split('_').pop().toLowerCase() === 'reports');

  return (
    props.subscriptionPlansDetails && props.subscriptionPlansDetails.data ?
      <Formik>
        {({
          values
        }) => (
            <Form>
              <div className="main-site-content admin-panel-content-wrap">
                <div className="ap-container">
                  <div className="blue-gray-border">
                    {props.subscriptionPlansDetails.data.monthly && props.subscriptionPlansDetails.data.monthly.length ?
                      <div className="monthly-subscription subscripition">
                        <span className="block-heading">Monthly Subscriptions</span>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-4 col-sm-12">
                              <div className=" card-container text-center">
                                <div className="content">
                                  <div className="heading">
                                    <span>Entry</span>
                                  </div>
                                  <div className="value">
                                    <span>{(growthPlan && growthPlan.price && `$${growthPlan.price}`) || 'N/A'}</span>
                                  </div>
                                </div>
                                <div className="sub-content">
                                  <div className="heading-value">
                                    <div className="heading">
                                      <span>Reports</span>
                                    </div>
                                    <div className="value">
                                      <span>{(growthPlan && growthPlan.services && growthPlan.services.reports) || 'N/A'}</span>

                                    </div>

                                  </div>
                                  <div className="edit-field">
                                    {props.state.editGrowthClicked ?
                                      <Field
                                        name="growthReports"
                                        value={props.state.growthReports}
                                        onChange={props.handleTextChange}
                                        component={RenderTextField}
                                      />

                                      :
                                      null}
                                  </div>
                                </div>
                                <div className="edit-button">
                                  {!props.state.editGrowthClicked ?
                                    <RenderButton disabled={!growthPlan} color="btn-status" className="edit-btn" onClick={() => props.handleEditButton('growth')}>Edit</RenderButton> :
                                    <> <RenderButton onClick={() => props.handleCancelButton('growth')} className="edit-btn btn btn-danger">Cancel</RenderButton>
                                      <RenderButton className="edit-btn btn btn-success" onClick={() => props.handleSaveButton('growth', growthPlan)}>Save</RenderButton></>}

                                </div>
                              </div>

                            </div>
                            <div className="col-md-4 col-sm-12">
                              <div className=" card-container text-center">
                                <div className="content">
                                  <div className="heading">
                                    <span>Standard</span>
                                  </div>
                                  <div className="value">
                                    <span>{(standardPlan && standardPlan.price && `$${standardPlan.price}`) || 'N/A'}</span>
                                  </div>
                                </div>
                                <div className="sub-content">
                                  <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                      <div className="heading-value">
                                        <div className="heading">
                                          <span>Reports</span>
                                        </div>
                                        <div className="value">
                                          <span>{(standardPlan && standardPlan.services && standardPlan.services.reports) || 'N/A'}</span>

                                        </div>
                                      </div>

                                      <div className="edit-field">
                                        {props.state.editStandardClicked ?
                                          <Field
                                            name="standardReports"
                                            value={props.state.standardReports}
                                            onChange={props.handleTextChange}
                                            component={RenderTextField}
                                          />

                                          :
                                          null}
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                      <div className="heading-value">
                                        <div className="heading">
                                          <span>Exports</span>
                                        </div>
                                        <div className="value">
                                          <span>{(standardPlan && standardPlan.services && standardPlan.services.exports) || 'N/A'}</span>

                                        </div>
                                      </div>
                                      <div className="edit-field">
                                        {props.state.editStandardClicked ?
                                          <Field
                                            name="standardExports"
                                            value={props.state.standardExports}
                                            onChange={props.handleTextChange}
                                            component={RenderTextField}
                                          />

                                          :
                                          null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="edit-button">
                                  {!props.state.editStandardClicked ? <RenderButton color="btn-status" onClick={() => props.handleEditButton('standard')} className="edit-btn">Edit</RenderButton> :
                                    <> <RenderButton onClick={() => props.handleCancelButton('standard')} className="edit-btn btn btn-danger">Cancel</RenderButton>
                                      <RenderButton className="edit-btn btn btn-success" onClick={() => props.handleSaveButton('standard', standardPlan)}>Save</RenderButton></>}

                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                              <div className=" card-container text-center">
                                <div className="content">
                                  <div className="heading">
                                    <span>Premium</span>
                                  </div>
                                  <div className="value">
                                    <span>{(professionalPlan && professionalPlan.price && `$${professionalPlan.price}`) || 'N/A'}</span>
                                  </div>
                                </div>
                                <div className="sub-content">
                                  <div className="row">
                                    <div className="col-md-4 col-sm-12">
                                      <div className="heading-value">
                                        <div className="heading">
                                          <span>Reports</span>
                                        </div>
                                        <div className="value">
                                          <span>{(professionalPlan && professionalPlan.services && professionalPlan.services.reports) || 'N/A'}</span>

                                        </div>
                                      </div>
                                      <div className="edit-field">
                                        {props.state.editProfessionalClicked ?
                                          <Field
                                            name="professionalReports"
                                            value={props.state.professionalReports}
                                            onChange={props.handleTextChange}
                                            component={RenderTextField}
                                          />

                                          :
                                          null}
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                      <div className="heading-value">
                                        <div className="heading">
                                          <span>Exports</span>
                                        </div>
                                        <div className="value">
                                          <span>{(professionalPlan && professionalPlan.services && professionalPlan.services.exports) || 'N/A'}</span>

                                        </div>
                                      </div>
                                      <div className="edit-field">
                                        {props.state.editProfessionalClicked ?
                                          <Field
                                            name="professionalExports"
                                            value={props.state.professionalExports}
                                            onChange={props.handleTextChange}
                                            component={RenderTextField}
                                          />

                                          :
                                          null}
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                      <div className="heading-value">
                                        <div className="heading">
                                          <span>Post Analysis</span>
                                        </div>
                                        <div className="value">
                                          <span>{(professionalPlan && professionalPlan.services && professionalPlan.services.posts) || 'N/A'}</span>

                                        </div>
                                      </div>
                                      <div className="edit-field">
                                        {props.state.editProfessionalClicked ?
                                          <Field
                                            name="professionalPostAnalysis"
                                            value={props.state.professionalPostAnalysis}
                                            onChange={props.handleTextChange}
                                            component={RenderTextField}
                                          />

                                          :
                                          null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="edit-button">
                                  {!props.state.editProfessionalClicked ? <RenderButton color="btn-status" onClick={() => props.handleEditButton('professional')} className="edit-btn">Edit</RenderButton> :
                                    <> <RenderButton onClick={() => props.handleCancelButton('professional')} className="edit-btn btn btn-danger">Cancel</RenderButton>
                                      <RenderButton className="edit-btn btn btn-success" onClick={() => props.handleSaveButton('professional', professionalPlan)}>Save</RenderButton></>}

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      : null}

                    <div className="metered-subscriptions subscripition">
                      <span className="block-heading">Metered Subscriptions</span>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className=" card-container text-center">
                              <div className="content">
                                <div className="heading">
                                  <span>Reports</span>
                                </div>
                              </div>
                              <div className="sub-content">
                                <div className="list">
                                  <span>Entry</span>
                                  <span>{meteredReportsPlans && meteredReportsPlans.find(plan => plan.nickname && plan.nickname.toLowerCase() === 'growth_reports') && `$${meteredReportsPlans.find(plan => plan.nickname && plan.nickname.toLowerCase() === 'growth_reports').price.toFixed(2)}`}</span>
                                </div>
                                <div className="list">
                                  <span>Standard</span>
                                  <span>{meteredReportsPlans && meteredReportsPlans.find(plan => plan.nickname && plan.nickname.toLowerCase() === 'standard_reports') && `$${meteredReportsPlans.find(plan => plan.nickname && plan.nickname.toLowerCase() === 'standard_reports').price.toFixed(2)}`}</span>
                                </div>
                                <div className="list">
                                  <span>Premium</span>
                                  <span>{meteredReportsPlans && meteredReportsPlans.find(plan => plan.nickname && plan.nickname.toLowerCase() === 'professional_reports') && `$${meteredReportsPlans.find(plan => plan.nickname && plan.nickname.toLowerCase() === 'professional_reports').price.toFixed(2)}`}</span>
                                </div>
                              </div>

                            </div>

                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className=" card-container text-center">
                              <div className="content">
                                <div className="heading">
                                  <span>Post Analysis</span>
                                </div>
                              </div>
                              <div className="sub-content">
                                <div className="list">
                                  <span>Entry</span>
                                  <span>???</span>
                                </div>
                                <div className="list">
                                  <span>Standard</span>
                                  <span>???</span>
                                </div>
                                <div className="list">
                                  <span>Premium</span>
                                  <span>$1.50 / 100 Likes</span>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
      </Formik>
      : null
  )
}

export default SubscriptionPlans
