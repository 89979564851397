import React  from "react";
import { useTranslation } from "react-i18next";
import { formatNumbersToCommaSeperated } from "actions";

const Balance = ({ userBalance, ...props }) => {
  const { t } = useTranslation();

  return (
    <div className="balance-items-container text-grey">
      <div className="balance-item first">{t("auth.sidebar.balance")}</div>
      <hr />
      <div className="balance-item">
        {t("auth.sidebar.reports")}
        <span>{formatNumbersToCommaSeperated(userBalance?.reports) || 0}</span>
      </div>
      <div className="balance-item">
        {t("auth.sidebar.exports")}
        <span>{formatNumbersToCommaSeperated(userBalance?.exports) || 0}</span>
      </div>
    </div>
  );
};

export default Balance;
