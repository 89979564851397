import React from "react";
import settings from "settings";
import { Translation, Trans } from "react-i18next";
import { FormGroup } from "reactstrap";
import { NavLink } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { SubmitButton, RenderTextField } from "../../components";
import { connect } from "react-redux";
import { debounce } from "lodash";
import {
  forgotPassword,
  clearErrorMsg,
  clearSuccessMsg,
} from "./actions/index";
import { showErrorMessage, showSuccessMessage } from "../../actions";
import { store } from "../../store";
const validate = (values) => {
  const errors = {};

  if (!values.email || values.email.trim() === "") {
    errors.email = "This field is required";
  } else if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]/i.test(values.email)
  ) {
    errors.email = "Invalid email";
  }

  return errors;
};
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleEnterKey = debounce(this.handleEnterKey.bind(this), 500);
    this.onSubmit = debounce(this.onSubmit.bind(this), 500);
  }
  onSubmit = (values) => {
    values.email = values.email.trim();
    this.props.forgotPassword(values);
  };
  clearErrorMsg = () => {
    this.props.clearErrorMsg();
  };
  clearSuccessMsg = () => {
    this.props.clearSuccessMsg();
  };
  componentDidMount() {
    document.getElementsByClassName("app")[0].classList.add("auth-wrap");
  }
  componentWillUnmount() {
    this.props.clearErrorMsg();
    this.props.clearSuccessMsg();
    document.getElementsByClassName("app")[0].classList.remove("auth-wrap");
  }

  handleEnterKey = (input, values) => {
    input.persist();
    if (input.charCode === 13 && input.target.value.trim() !== "") {
      this.props.forgotPassword(values);
    }
  };
  render() {
    return (
      <div className="pt-40">
        {this.props.successMsg
          ? showSuccessMessage(
              this.props.successMsg,
              this.clearSuccessMsg,
              5000,
              "forgotPasswordEmail"
            )
          : null}
        {this.props.errorMsg
          ? showErrorMessage(
              store,
              this.props.errorMsg,
              this.clearErrorMsg,
              5000,
              "forgotPasswordError"
            )
          : null}
        <div className="main-site-content container">
          <div className="user-form-wrap">
            <div className="page-head">
              <img
                src={settings.mainbrand}
                className="auth_logo"
                alt="homepage"
              />
              <h1>
                <Trans i18nKey="auth.forgot.title">Forgot your password?</Trans>
              </h1>
              <p className="sub-text">
                <Trans i18nKey="auth.forgot.description">
                  Enter your email below and we’ll send you a reset link.
                </Trans>
              </p>
            </div>
            <Formik
              initialValues={this.props.initialValues}
              onSubmit={this.onSubmit}
              validate={validate}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <FormGroup>
                    <Translation>
                      {(t, { i18n }) => (
                        <Field
                          name="email"
                          placeholder={t("auth.forgot.form.email")}
                          onKeyPress={(input) =>
                            this.handleEnterKey(input, values)
                          }
                          className={
                            errors.email && touched.email ? "error" : ""
                          }
                          component={RenderTextField}
                        />
                      )}
                    </Translation>
                    {errors.email && touched.email && (
                      <span className="required">{errors.email}</span>
                    )}
                  </FormGroup>
                  <FormGroup className="bottom text-center">
                    <SubmitButton className="btn btn-primary">
                      <Trans i18nKey="auth.forgot.link">Send Reset Link</Trans>
                    </SubmitButton>
                    <br />
                    <p className="text-grey">
                      <Trans i18nKey="auth.forgot.back">Back to</Trans>{" "}
                      <NavLink to="/" className="link-more">
                        <Trans i18nKey="auth.forgot.sign_in">Sign in</Trans>
                      </NavLink>
                    </p>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    errorMsg: state.forgotPassword.errorMsg,
    successMsg: state.forgotPassword.successMsg,
    initialValues: {
      email: "",
    },
  };
};
export default connect(mapStateToProps, {
  forgotPassword,
  clearErrorMsg,
  clearSuccessMsg,
})(ForgotPassword);
