import React from "react";
import { Row, Col, Table, TabPane, Badge } from "reactstrap";
import { useTranslation } from "react-i18next";
import networkImage from "helpers/networkImage";
import { RenderButton } from "../../../components";
import { EngagementTag, FollowerTag } from "components/tags";
import Paginator from "../../../components/pagination/pagination";
import noimg from "../../../img/MaskGroup.png";
import defaultImg from "../../../img/user-circle-outline.svg";
import moment from "moment";

const ReportsTab = (props) => {
  const { t } = useTranslation();

  return (
    <TabPane tabId="1">
      <Row className="search-results-wrap mb-3">
        <Col md="12">
          <div className="table-responsive table-outer user-profile-table">
            <Table hover borderless className="mb-3">
              <thead>
                <tr>
                  <th width="32%" className="my-reports">
                    {t("reports.splash.chart.title")}
                  </th>
                  <th width="15%" className="text-left mobile-view-past-report">
                    {t("reports.splash.chart.followers")}
                  </th>
                  <th width="15%" className="text-left mobile-view-past-report">
                    {t("reports.splash.chart.engagements")}
                  </th>
                  <th width="15%" className="text-left mobile-view-past-report">
                    {t("reports.splash.chart.date")}
                  </th>
                  <th width="20%"></th>
                </tr>
              </thead>
              <tbody>
                {props.pastExportsReports &&
                props.pastExportsReports.reports_generated &&
                props.pastExportsReports.reports_generated.length ? (
                  props.pastExportsReports.reports_generated.map(
                    (report, index) => (
                      <tr key={index}>
                        <td className="tb-head d-flex">
                          <div className="account-image">
                            <img
                              src={report.picture ? report.picture : noimg}
                              alt="profile pic"
                              width="50px"
                              height="50px"
                              className="rounded-circle"
                              onError={(e) => (e.target.src = defaultImg)}
                            />
                            {networkImage(report.type)}
                          </div>

                          <div className="d-flex align-items-center text-wrap">
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={report.url}
                              className="link"
                            >
                              <h4 className="m-0">
                                {report.type === "youtube"
                                  ? report.fullname || report.social_id
                                  : `@${report.username}`}
                              </h4>
                            </a>
                            {report.demo ? (
                              <Badge
                                className={`fs--2 badge-soft-neutral ml-2`}
                              >
                                {t("reports.splash.chart.example")}
                              </Badge>
                            ) : null}
                          </div>
                        </td>
                        <td className="text-left mobile-view-past-report">
                          <FollowerTag
                            followers={report.followers || 0}
                          ></FollowerTag>
                        </td>
                        <td className="text-left mobile-view-past-report">
                          <EngagementTag
                            engagements={
                              report.followers * report.engagement_rate || 0
                            }
                          ></EngagementTag>
                        </td>
                        <td className="text-left mobile-view-past-report">
                          {!report.demo &&
                            moment(report.created).format("MMMM DD, YYYY")}
                        </td>
                        <td align="center">
                          <RenderButton
                            onClick={() => props.handleReportsAnalyze(report)}
                            className="btn btn-outline-primary"
                          >
                            {window.innerWidth < 676
                              ? t("reports.splash.chart.view")
                              : t("reports.splash.chart.view_report")}
                          </RenderButton>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="6" align="center">
                      {t("errors.no_record")}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {props.pastExportsReports &&
            props.pastExportsReports.total &&
            props.pastExportsReports.total > props.limit ? (
              <Paginator
                activePage={props.state.activeReportsPage}
                itemsPerPage={10}
                totalItems={props.pastExportsReports.total}
                handlePageChange={(pageNumber) =>
                  props.handlePageChange(pageNumber)
                }
              />
            ) : null}
          </div>
        </Col>
      </Row>
    </TabPane>
  );
};

export default ReportsTab;
