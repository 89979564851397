import React, { useState } from "react";
import { Table, Row, Col } from "reactstrap";
import Paginator from "../../components/pagination/pagination";
import CreatorListsRow from "./creatorListsRow";
import CreatorListModal from "./modals/creatorListModal";
import { useTranslation } from "react-i18next";

const CreatorListsTable = (props) => {
  const { t } = useTranslation();
  const { creatorLists, deleteCreatorList } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [creatorListView, setCreatorListView] = useState(false);
  const [selectedCreatorList, setCreatorList] = useState(null);

  const toggleCreatorListView = (list_id) => {
    setCreatorListView(!creatorListView);

    if (!creatorListView) {
      const creatorList = creatorLists.find((list) => list._id === list_id);
      setCreatorList(creatorList);
    }
  };

  const columns = ["name", "networks", "creators", "reach", "modified"];
  const columnHeaders = columns.map((column, index) => (
    <th key={index}>{t(`creator_lists.list_table.${column}_header`)}</th>
  ));

  const tableData = creatorLists.map((list) => (
    <CreatorListsRow
      key={list._id}
      creatorList={list}
      toggleModal={toggleCreatorListView}
      deleteCreatorList={deleteCreatorList}
    ></CreatorListsRow>
  ));

  return (
    <Row className="search-results-wrap">
      <CreatorListModal
        isOpen={creatorListView}
        toggleModal={toggleCreatorListView}
        creatorList={selectedCreatorList}
        deleteCreatorList={deleteCreatorList}
        key={selectedCreatorList?._id}
      />
      <Col md="12">
        <div className="table-responsive table-outer user-profile-table creator-lists-table">
          <Table hover borderless>
            <thead>
              <tr>{columnHeaders}</tr>
            </thead>
            <tbody>
              {creatorLists && creatorLists.length ? (
                tableData
              ) : (
                <tr>
                  <td colSpan={columns.length} align="center">
                    {t("errors.no_record")}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {creatorLists && creatorLists.total && creatorLists.total > 10 ? (
            <Paginator
              activePage={currentPage}
              itemsPerPage={10}
              totalItems={creatorLists.total}
              handlePageChange={(pageNumber) => setCurrentPage(pageNumber)}
            />
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

export default CreatorListsTable;
