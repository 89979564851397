import {
  actionCreator,
  checkHttpStatus,
  privateHeaders,
  checkAuthTokenExpiry,
  showErrorMessage,
  showSuccessMessage,
} from "../../../actions";
import {
  settingsActionTypes,
  GENERATE_TOKEN_URL,
  GENERATE_TOKEN_SUCCESS_MESSAGE,
} from "../constants";
import { checkSubscription } from "../../login/actions";

export const generateToken = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(settingsActionTypes.generateToken.REQUEST));
    fetch(GENERATE_TOKEN_URL, {
      method: "PUT",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (!response.success) {
          showErrorMessage(null, response.message);
        } else {
          dispatch(checkSubscription());
          showSuccessMessage(GENERATE_TOKEN_SUCCESS_MESSAGE);
        }

        dispatch(
          actionCreator(settingsActionTypes.generateToken.SUCCESS, response)
        );
      })
      .catch((error) => {
        showErrorMessage(null, error.message);
        dispatch(actionCreator(settingsActionTypes.generateToken.FAILURE));
      });
  };
};
