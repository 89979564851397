import React, { useState } from "react";
import { Row, Col, FormGroup, Modal, ModalHeader, ModalBody } from "reactstrap";
import "../index.scss";
import { RenderTextFieldWithoutAutoComplete } from "../../../components";
import { UserPlusIcon } from "@heroicons/react/20/solid";
import {
  RenderLabel,
  RenderSearchableSingleSelect,
  RenderRadioButton,
} from "../../../components";
import noimg from "../../../img/MaskGroup.png";
import defaultImg from "../../../img/user-circle-outline.svg";
import { Field } from "formik";

const CreateListForm = (props) => {
  return (
    <div className="create-form">
      <Field
        className="create-list-field"
        name="listNameForm"
        component={RenderTextFieldWithoutAutoComplete}
        placeholder="Enter List Name"
        customvalue={props.listName}
        onChange={(e) => {
          props.setCreatorListName(e.target.value);
        }}
      />
    </div>
  );
};

const UpdateListForm = (props) => {
  return (
    <div className="update-form">
      <Field
        component={RenderSearchableSingleSelect}
        name="selectListForm"
        placeholder="Select Creator List"
        value={props.creatorList}
        onChange={(input) => props.setCreatorList(input)}
        options={props.formattedCreatorLists}
      />
    </div>
  );
};

const CreatorListModal = (props) => {
  const radioOptions = [
    { value: "CREATE", text: "Create new list" },
    { value: "UPDATE", text: "Update existing list" },
  ];

  const [radioCheckType, setRadioCheckType] = useState("CREATE");

  const [listName, setCreatorListName] = useState("");
  const [creatorList, setCreatorList] = useState(props.creatorLists[0]);

  const updateRadioCheck = (e, type) => {
    if (e.target.checked) {
      setRadioCheckType(type);
    }
  };

  const handleCreatorListSubmit = () => {
    if (radioCheckType === "CREATE") {
      props.addCreatorListHandler(listName);
      setCreatorListName("");
    } else if (radioCheckType === "UPDATE") {
      props.updateCreatorListHandler(creatorList.value);
    }
  };

  return (
    <Modal
      id="creator-list-modal"
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      className="modal-dialog-centered"
    >
      <ModalHeader toggle={props.toggleModal}>
        <span className="export-icon mr-3">
          <UserPlusIcon width={25} height={25} />
        </span>
        Add {props.selectedUsers.length} Creator
        {props.selectedUsers.length > 1 ? "s" : ""}
      </ModalHeader>
      <ModalBody className="confirm-modal">
        <Row>
          {radioOptions.map((option, index) => (
            <Col md="6" key={index}>
              <FormGroup
                check
                className={`${
                  radioCheckType === option.value ? "selected" : "hidden"
                } ${
                  option.value === "UPDATE" && !props.creatorLists.length
                    ? "disabled"
                    : ""
                }`}
              >
                <RenderLabel check className="radio-container">
                  {option.text}
                  <Field
                    component={RenderRadioButton}
                    value={option.value}
                    disabled={
                      option.value === "UPDATE" && !props.creatorLists.length
                    }
                    checked={radioCheckType === option.value}
                    onChange={(e) => updateRadioCheck(e, option.value)}
                  />
                  <span className="checkmark"></span>
                </RenderLabel>
              </FormGroup>
            </Col>
          ))}
          <Col md="12" className="creators my-4 justify-content-center">
            <div className="account-images">
              {props.selectedUsers.slice(0, 10).map((influencer) => (
                <div
                  className="account-image"
                  key={`modal_${influencer.handle}`}
                >
                  <img
                    src={influencer.imageUrl ? influencer.imageUrl : noimg}
                    alt="profile pic"
                    width="50px"
                    height="50px"
                    className="rounded-circle"
                    onError={(e) => (e.target.src = defaultImg)}
                  />
                </div>
              ))}
            </div>
          </Col>
          <Col md="7" className="mx-auto mb-5 justify-content-center">
            {radioCheckType === "CREATE" && (
              <CreateListForm
                listName={listName}
                setCreatorListName={setCreatorListName}
              />
            )}
            {radioCheckType === "UPDATE" && (
              <UpdateListForm
                creatorList={creatorList}
                setCreatorList={setCreatorList}
                formattedCreatorLists={props.creatorLists}
              />
            )}
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <button
            disabled={
              (radioCheckType === "CREATE" && !listName) ||
              (radioCheckType === "UPDATE" && !creatorList)
            }
            className="btn btn-primary modal-button"
            onClick={handleCreatorListSubmit}
          >
            Continue
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default CreatorListModal;
