import { signupActionTypes } from "../constants";
import { COMMON_ERROR_MESSAGE } from "../../../actions";
const initialState = {
  loading: false,
  registeredUser: null,
  authToken: null,
  errorMsg: null,
  successMsg: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case signupActionTypes.signup.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case signupActionTypes.signup.SUCCESS:
      return {
        ...state,
        registeredUser: payload.errorMsg ? null : payload,
        loading: false,
        authToken: payload && payload.token,
        successMsg: payload.successMsg || null,
        errorMsg: payload.errorMsg || null,
      };
    case signupActionTypes.signup.FAILURE:
      return {
        ...state,
        registeredUser: null,
        successMsg: null,
        loading: false,
        authToken: null,
        errorMsg: COMMON_ERROR_MESSAGE,
      };
    case signupActionTypes.clearErrorMsg.SUCCESS:
      return {
        ...state,
        errorMsg: null,
      };
    case signupActionTypes.clearSuccessMsg.SUCCESS:
      return {
        ...state,
        successMsg: null,
      };
    case signupActionTypes.clearLoading.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case signupActionTypes.emailVerificationConfirmation.REQUEST:
      return {
        ...state,
        verifying: true,
      };
    case signupActionTypes.emailVerificationConfirmation.SUCCESS:
      return {
        ...state,
        verifying: false,
      };
    case signupActionTypes.emailVerificationConfirmation.FAILURE:
      return {
        ...state,
        verifying: false,
      };

    default:
      return state;
  }
};
