import React from "react";
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { RenderButton } from "../../../components";
import { showTooltip } from "../../../actions";

const PopularMentions = (props) => {
  const { t } = useTranslation();

  return (
    <Col sm="6" className="d-flex align-items-stretch">
      <Card className="card-content-custom">
        <CardBody>
          <CardTitle>
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">Popular Mentions</span>{" "}
              <span
                href="#"
                id="popularMentionTooltip"
                className="tooltip-icon"
              ></span>
            </span>
            <UncontrolledTooltip
              placement="bottom"
              className="tooltip-content"
              target="popularMentionTooltip"
            >
              {showTooltip("popularMentionTooltip")}
            </UncontrolledTooltip>
          </CardTitle>
          <div className="popular-tags ch-5">
            <ul>
              {props.accountOverviewDetails &&
              props.accountOverviewDetails.user_profile &&
              props.accountOverviewDetails.user_profile.top_mentions ? (
                props.accountOverviewDetails.user_profile.top_mentions.map(
                  (tag, index) => {
                    if (index < 10 && tag.tag.length < 15) {
                      return <li key={index}>@{tag.tag}</li>;
                    }
                    return null;
                  }
                )
              ) : (
                <span className="text-center text-grey ">
                  <p className="pt-50">{t("errors.no_record")}</p>
                </span>
              )}
            </ul>
          </div>
          {props.accountOverviewDetails &&
          props.accountOverviewDetails.user_profile &&
          props.accountOverviewDetails.user_profile.top_mentions ? (
            <div className="bottom">
              <Button
                color="link"
                onClick={() => props.toggleModal("popularAtTheRateModal")}
              >
                {t("reports.view_more")}
              </Button>
              <Modal
                id="popularAtTheRateModalDownload"
                isOpen={props.modals.popularAtTheRateModal}
                toggle={() => props.toggleModal("popularAtTheRateModal")}
              >
                <ModalHeader
                  toggle={() => props.toggleModal("popularAtTheRateModal")}
                >
                  <span className="tooltip-button-wrap">
                    <span className="text-tooltip">
                      {t("reports.influencer_details.mentions.title")}
                    </span>
                    <span
                      href="#"
                      id="popularAt"
                      className="tooltip-icon"
                    ></span>
                  </span>
                  {props.tooltip && (
                    <UncontrolledTooltip
                      placement="bottom"
                      className="tooltip-content"
                      target="popularAt"
                    >
                      {showTooltip("popularMentionTooltip")}
                    </UncontrolledTooltip>
                  )}
                  <RenderButton
                    onClick={() =>
                      props.downloadPdf("popularAtTheRateModalDownload")
                    }
                    className="download-icon-button mg-left-15 link"
                  >
                    {t("reports.download")}
                  </RenderButton>
                </ModalHeader>
                <ModalBody>
                  <Table className="modal-table">
                    <thead>
                      <tr>
                        <th colSpan="2">
                          {t("reports.influencer_details.mentions.mention")}
                        </th>
                        <th className="text-right">
                          {t("reports.influencer_details.mentions.percent")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.accountOverviewDetails &&
                      props.accountOverviewDetails.user_profile &&
                      props.accountOverviewDetails.user_profile.top_mentions &&
                      props.accountOverviewDetails.user_profile.top_mentions
                        .length ? (
                        props.accountOverviewDetails.user_profile.top_mentions.map(
                          (tag, index) => (
                            <tr key={index}>
                              <th scope="row">{`@${tag.tag}`}</th>
                              <td className="text-left">&nbsp;</td>
                              <td className="text-right">
                                {(tag.weight * 100).toFixed(2) + "%"}
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan="3" align="center">
                            {t("errors.no_record")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </ModalBody>
              </Modal>
            </div>
          ) : null}
        </CardBody>
      </Card>
    </Col>
  );
};

export default PopularMentions;
