import React from "react";
import { connect } from "react-redux";

class Error404 extends React.Component {
  componentDidMount() {
    document.getElementsByClassName("app")[0].classList.add("auth-wrap");
    window.open(process.env.REACT_APP_URL, "_self");
  }
  componentWillUnmount() {
    document.getElementsByClassName("app")[0].classList.remove("auth-wrap");
  }

  render() {
    return (
      <div className="center-screen">
        <div className="main-site-content container">
          <div className="user-form-wrap">
            <div className="page-head">
              <img
                alt=""
                src="https://image.flaticon.com/icons/svg/148/148766.svg"
                className="response-svg"
              />
              <h1>Error</h1>
              <p className="sub-text">Page not found</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(Error404);
