import React from "react";
import {
  formatNumbersToUnits,
  formatNumbersToCommaSeperated,
} from "../../../actions";
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import useDarkMode from "use-dark-mode";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsGrouped from "highcharts-grouped-categories";
import HighchartsBorder from "highcharts-border-radius";

import moment from "moment";
import { groupBy } from "lodash";
import { RenderButton } from "../../../components";

HighchartsBorder(Highcharts);
HighchartsGrouped(Highcharts);

const engagementSpreadChartOptions = (props, darkMode) => {
  const monthName = (item) =>
    moment(item.created, "YYYY-MM-DD").format("MMM, YYYY");

  const recent_posts =
    props.accountOverviewDetails?.user_profile?.recent_posts.map((post) => ({
      created: post.created,
      likes: post.stat.likes,
      comments: post.stat.comments,
      views: post.stat.views,
    }));

  const result = groupBy(recent_posts, monthName);

  const emptySpace = Math.ceil(
    (recent_posts.length - Object.entries(result).length) / 2
  );

  const monthCategories = [];
  const recentPostsLikesData = [];
  const recentPostsCommentsData = [];
  const recentPostsViewsData = [];

  const highchartsSpacing = () => {
    recentPostsLikesData.push(...Array.from(" ".repeat(emptySpace)));
    recentPostsCommentsData.push(...Array.from(" ".repeat(emptySpace)));
    recentPostsViewsData.push(...Array.from(" ".repeat(emptySpace)));
  };

  for (let [month, post] of Object.entries(result)) {
    const category = {
      name: month,
      categories: [
        ...Array.from(" ".repeat(emptySpace)),
        ...post.map((value) => moment(value.created).format("MMM DD, YYYY")),
        ...Array.from(" ".repeat(emptySpace)),
      ],
    };

    monthCategories.push(category);
    highchartsSpacing();

    post.forEach((item) => {
      recentPostsLikesData.push({
        y: item.likes,
        month: moment(item.created).format("MMM DD, YYYY"),
      });
      recentPostsCommentsData.push({
        y: item.comments,
        month: moment(item.created).format("MMM DD, YYYY"),
      });
      recentPostsViewsData.push({
        y: item.views,
        month: moment(item.created).format("MMM DD, YYYY"),
      });
    });
    highchartsSpacing();
  }

  return {
    chart: {
      type: "column",
      height: 250,
      backgroundColor: null,
    },
    title: null,
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          color: "#898e94",
        },
      },
      gridLineColor: darkMode ? "#66666640" : "#e6e6e6",
    },
    tooltip: {
      backgroundColor: "#17222d",
      borderColor: "transparent",
      borderRadius: 10,
      shadow: false,
      style: {
        color: "white",
        fontSize: "11px",
      },
      formatter: function () {
        return (
          "<b>" +
          this.series.name +
          "</b><br/>" +
          this.point.month +
          ": " +
          formatNumbersToCommaSeperated(this.y)
        );
      },
    },
    xAxis: {
      title: {
        text: null,
      },
      labels: {
        rotation: 0,
        groupedOptions: [
          {
            style: {
              fontSize: 11,
            },
          },
        ],
        style: {
          fontSize: 0,
          color: "#898e94",
        },
      },
      reversed: true,
      type: "category",
      tickColor: "transparent",
      lineColor: "#1f87ee",
      categories: monthCategories,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
        stacking: "normal",
        pointPadding: 0,
        groupPadding: 0,
      },
      column: {
        pointWidth: window.innerWidth < 1024 ? 15 : 20,
      },
    },

    series: [
      {
        name: "Likes",
        color: "#1f87ee",
        data: recentPostsLikesData,
        borderRadiusTopLeft: 10,
        borderRadiusTopRight: 10,
        borderColor: "transparent",
      },
      {
        name: "Comments",
        color: "#ff5537",
        data: recentPostsCommentsData,
        borderColor: "transparent",
      },
    ],
    credits: {
      enabled: false,
    },
  };
};
const EngagementsSpreadLastPosts = (props) => {
  const { t } = useTranslation();
  const darkMode = useDarkMode(false);

  return (
    <Col sm="12">
      <Card className="card-content-custom block-last-posts">
        <CardBody>
          <CardTitle>
            {t("reports.influencer_details.engagements_spread.title")}
            <ul className="like-comment">
              <li className="like">
                {t("reports.influencer_details.engagements_spread.likes")}
              </li>
              <li className="comment">
                {t("reports.influencer_details.engagements_spread.comments")}
              </li>
            </ul>
          </CardTitle>
          <div className="graph-wrap ptop15">
            <HighchartsReact
              highcharts={Highcharts}
              options={engagementSpreadChartOptions(props, darkMode.value)}
            />
          </div>
          <div className="bottom engagement_chart">
            <Button
              color="link"
              onClick={() =>
                props.toggleModal("engagementSpreadForLastPostsModal")
              }
            >
              {t("reports.view_more")}
            </Button>
            <Modal
              id="engagementSpreadForLastPostsModalDownload"
              isOpen={props.modals.engagementSpreadForLastPostsModal}
              toggle={() =>
                props.toggleModal("engagementSpreadForLastPostsModal")
              }
            >
              <ModalHeader
                toggle={() =>
                  props.toggleModal("engagementSpreadForLastPostsModal")
                }
              >
                {t("reports.influencer_details.engagements_spread.title")}
                <RenderButton
                  onClick={() =>
                    props.downloadPdf(
                      "engagementSpreadForLastPostsModalDownload"
                    )
                  }
                  className="download-icon-button mg-left-15 link"
                >
                  {t("reports.download")}
                </RenderButton>
              </ModalHeader>
              <ModalBody>
                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th>
                        {t(
                          "reports.influencer_details.engagements_spread.date"
                        )}
                      </th>
                      <th className="text-right">
                        {t(
                          "reports.influencer_details.engagements_spread.likes"
                        )}
                      </th>
                      <th className="text-right">
                        {t(
                          "reports.influencer_details.engagements_spread.comments"
                        )}
                      </th>
                      <th className="text-right">
                        {t(
                          "reports.influencer_details.engagements_spread.post"
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.accountOverviewDetails &&
                    props.accountOverviewDetails.user_profile &&
                    props.accountOverviewDetails.user_profile.recent_posts &&
                    props.accountOverviewDetails.user_profile.recent_posts
                      .length ? (
                      props.accountOverviewDetails.user_profile.recent_posts.map(
                        (post, index) => (
                          <tr key={index}>
                            <th scope="row">
                              {moment(post.created).format(
                                "MMMM DD, YYYY, HH:mm:ss"
                              )}
                            </th>
                            <td className="text-right">
                              {formatNumbersToUnits(post.stat.likes)}
                            </td>
                            <td className="text-right">
                              {formatNumbersToUnits(post.stat.comments)}
                            </td>
                            <td className="text-right">
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={post.link}
                              >
                                {t(
                                  "reports.influencer_details.engagements_spread.view"
                                )}
                              </a>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan="4" align="center">
                          {t("errors.no_record")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default EngagementsSpreadLastPosts;
