/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import CalendlyEmbed from "components/calendly/CalendlyEmbed";
import trialBannerOptions from "../../helpers/trialBanners";

import "./index.scss";

const TrialBanner = ({ loginUser, type, ...props }) => {
  const [calendlyOpen, setCalendlyOpen] = useState(false);

  const prefill = {
    email: loginUser?.email,
    name: loginUser?.name,
  };

  const handleCalendlyPopup = (value) => {
    setCalendlyOpen(value);
  };

  const findBannerMessage = (option = "message") => {
    return trialBannerOptions.find((item) => item.id === type)?.[option] || "";
  };

  return (
    <div className="trial-banner">
      <div className="alert alert-info" role="alert">
        <h1 className="alert-heading">{findBannerMessage("title")}</h1>
        <p dangerouslySetInnerHTML={{ __html: findBannerMessage("message") }} />
        <hr className="h-row" />
        <p className="mb-0">
          For more info, set up a time to{" "}
          <a className="alert-link" onClick={() => handleCalendlyPopup(true)}>
            Talk to an Expert
          </a>
        </p>
      </div>
      <CalendlyEmbed
        loginUser={props.loginUser}
        prefill={prefill}
        isOpen={calendlyOpen}
        onModalClose={handleCalendlyPopup}
      />
    </div>
  );
};
export default TrialBanner;
