import {
  actionCreator,
  privateHeaders,
  checkHttpStatus,
  checkAuthTokenExpiry,
} from "../../../actions";
import {
  subscriptionActionTypes,
  SUBSCRIPTION_URL,
  CONTINUE_WITH_TRIAL_ACCESS_URL,
  planSelectionTypes,
  fetchPlanTypes,
  FETCH_PLANS_URL,
  clearResponseMessage,
  SUBSCRIPTION_UPDATE_URL,
  updateSubscription,
  saveCardNumber,
} from "../constants";
import history from "../../../history";
import { signOut, checkSubscription } from "../../login/actions";
import { getUserBalanceData } from "../../header/actions";

export const activateSubscription = (postData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(subscriptionActionTypes.activatePlan.REQUEST));
    fetch(SUBSCRIPTION_URL, {
      method: "POST",
      headers: privateHeaders(getState),
      body: JSON.stringify(postData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.success === true) {
          dispatch(
            actionCreator(
              subscriptionActionTypes.activatePlan.SUCCESS,
              response
            )
          );
          dispatch(getUserBalanceData(getState().login.loginUser.id));
        } else {
          dispatch(actionCreator(subscriptionActionTypes.activatePlan.FAILURE));
          dispatch(getUserBalanceData(getState().login.loginUser.id));
        }
      })
      .catch((error) => {
        dispatch(actionCreator(subscriptionActionTypes.activatePlan.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
          // history.push('/subscriptionFailed');
        }
      });
  };
};
/***
 * plan select action on the subscription page
 */
export const selectPlan = (data) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(planSelectionTypes.choosePlan.SUCCESS, data));

    history.push("/paymentConfirmation");
  };
};
/***
 * change status of payment error
 */
export const changeResponseStatusPayment = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(clearResponseMessage.removeResponsePaymentType.REQUEST)
    );
  };
};
/***
 * FETCH PLANS FROM SERVER
 */

export const retrievePlans = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(fetchPlanTypes.getPlanTypes.REQUEST));
    fetch(FETCH_PLANS_URL, {
      method: "GET",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(actionCreator(fetchPlanTypes.getPlanTypes.SUCCESS, response));
      })
      .catch((error) => {
        dispatch(actionCreator(fetchPlanTypes.getPlanTypes.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
/***
 * update subscription plan from switch subscription plan
 *
 */
export const updateSubscriptionAction = (planId) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(updateSubscription.updateSubscriptionType.REQUEST));
    fetch(SUBSCRIPTION_UPDATE_URL, {
      method: "POST",
      headers: privateHeaders(getState),
      body: JSON.stringify(planId),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            updateSubscription.updateSubscriptionType.SUCCESS,
            response
          )
        );
        dispatch(getUserBalanceData(getState().login.loginUser.id));
        history.push("/userProfile");
      })
      .catch((error) => {
        dispatch(
          actionCreator(updateSubscription.updateSubscriptionType.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
          // history.push('/subscriptionFailed');
        }
      });
  };
};
export const clearStripeMsgAction = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(clearStripeMsgAction.clearStripeMsgAction.SUCCESS));
  };
};
export const showHeaderAction = (showHeader) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(
        subscriptionActionTypes.hideHeaderAction.SUCCESS,
        showHeader
      )
    );
  };
};

export const showStripeMsg = (msg) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(showStripeMsg.showStripeMsg.SUCCESS, msg));
  };
};

/***
 * save card number action
 */
export const saveCardNumberAction = (data) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(saveCardNumber.saveCardNumberType.SUCCESS, data));
  };
};
export const showPlansError = (errorMsg) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(
        subscriptionActionTypes.showSubscriptionError.SUCCESS,
        errorMsg
      )
    );
  };
};
export const clearSubscriptionErrorAction = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(subscriptionActionTypes.clearSubscriptionError.SUCCESS)
    );
  };
};
export const clearContinueWithTrialAccessErrorMsgAction = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(
        subscriptionActionTypes.clearContinueWithTrialAccessErrorMsg.SUCCESS
      )
    );
  };
};
export const clearContinueWithTrialAccessSuccessMsgAction = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(
        subscriptionActionTypes.clearContinueWithTrialAccessSuccessMsg.SUCCESS
      )
    );
  };
};

export const continueWithTrialAccess = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(subscriptionActionTypes.continueWithTrialAccess.REQUEST)
    );
    fetch(CONTINUE_WITH_TRIAL_ACCESS_URL, {
      method: "POST",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (!response.success) {
          response.errorMsg = response.message;
        } else {
          response.successMsg = response.message;
        }
        dispatch(
          actionCreator(
            subscriptionActionTypes.continueWithTrialAccess.SUCCESS,
            response
          )
        );
        dispatch(retrievePlans());
        dispatch(checkSubscription());
        if (response.success) {
          history.push("/discovery");
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(subscriptionActionTypes.continueWithTrialAccess.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
