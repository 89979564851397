import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  capitalize,
  formatNumbersToCommaSeperated,
  showTooltip,
} from "../../../../actions";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import { RenderButton } from "../../../../components";
highchartsMore(Highcharts);
solidGauge(Highcharts);
const gaugeOptions = (props) => {
  let data =
    props.accountOverviewDetails &&
    props.accountOverviewDetails.audience_followers &&
    props.accountOverviewDetails.audience_followers.data &&
    props.accountOverviewDetails.audience_followers.data.audience_genders
      ? props.accountOverviewDetails.audience_followers.data.audience_genders.sort(
          (a, b) => b.weight - a.weight
        )
      : [];
  let seriesData = data.map((obj, index) => ({
    name: capitalize(obj.code),
    radius: "100%",
    color: obj.code.toLowerCase() === "female" ? "#ff5537" : "#1f87ee",
    innerRadius: "70%",
    y:
      index === 0
        ? Math.round((obj.weight + data[index + 1].weight) * 100)
        : Math.round(obj.weight * 100),
  }));
  return {
    chart: {
      type: "solidgauge",
      height: 150,
      backgroundColor: null,
    },

    title: null,

    pane: {
      size: "100%",
      background: [
        {
          outerRadius: "100%",
          innerRadius: "70%",
          backgroundColor: "transparent",
          borderWidth: 0,
        },
      ],
    },

    tooltip: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: [],
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
        },
        animation: false,
        stickyTracking: true,
        rounded: false,
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        data: seriesData,
      },
    ],
  };
};
const FollowersGenderSplit = (props) => {
  const { t } = useTranslation();

  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>
          <span className="tooltip-button-wrap">
            <span className="text-tooltip">
              {t("reports.audience_details.gender")}
            </span>
            <span
              href="#"
              id="followersGenderSplit"
              className="tooltip-icon"
            ></span>
          </span>
          {props.tooltip && (
            <UncontrolledTooltip
              placement="bottom"
              className="tooltip-content"
              target="followersGenderSplit"
            >
              {showTooltip("followersGenderSplit")}
            </UncontrolledTooltip>
          )}
        </CardTitle>
        <div className="card-content ch-300">
          <div className="graph-wrap">
            <HighchartsReact
              highcharts={Highcharts}
              options={gaugeOptions(props)}
            />
          </div>
          <ListGroup flush className="custom-bedge-list">
            {props.accountOverviewDetails &&
            props.accountOverviewDetails.audience_followers &&
            props.accountOverviewDetails.audience_followers.data &&
            props.accountOverviewDetails.audience_followers.data
              .audience_genders
              ? props.accountOverviewDetails.audience_followers.data.audience_genders.map(
                  (gender, index) => (
                    <ListGroupItem
                      key={index}
                      className={
                        gender.code.toLowerCase() === "female"
                          ? "female d-flex justify-content-between align-items-center"
                          : "d-flex justify-content-between align-items-center"
                      }
                    >
                      {t(
                        `discovery.filters.gender.options.${gender.code.toLowerCase()}`
                      )}
                      <Badge className="text-grey" color="">
                        {(gender.weight * 100).toFixed(2) + "%"}
                      </Badge>
                    </ListGroupItem>
                  )
                )
              : null}
          </ListGroup>
        </div>
        <div className="bottom">
          <Button
            onClick={() => props.toggleModal("followersGenderSplitModal")}
            color="link"
          >
            {t("reports.view_more")}
          </Button>
          <Modal
            id="followersGenderSplitModalDownload"
            isOpen={props.modals.followersGenderSplitModal}
            toggle={() => props.toggleModal("followersGenderSplitModal")}
          >
            <ModalHeader
              toggle={() => props.toggleModal("followersGenderSplitModal")}
            >
              <span className="tooltip-button-wrap">
                <span className="text-tooltip">
                  {t("reports.audience_details.gender")}
                </span>
                <span
                  href="#"
                  id="followersGenderSplitModal"
                  className="tooltip-icon"
                ></span>
              </span>
              {props.tooltip && (
                <UncontrolledTooltip
                  placement="bottom"
                  className="tooltip-content"
                  target="followersGenderSplitModal"
                >
                  {showTooltip("followersGenderSplitModal")}
                </UncontrolledTooltip>
              )}
              <RenderButton
                onClick={() =>
                  props.downloadPdf("followersGenderSplitModalDownload")
                }
                className="download-icon-button mg-left-15 link"
              >
                {t("reports.download")}
              </RenderButton>
            </ModalHeader>
            <ModalBody>
              <Table className="modal-table">
                <thead>
                  <tr>
                    <th>{t("reports.chart.gender")}</th>
                    <th className="text-right">
                      {t("reports.chart.followers")}
                    </th>
                    <th className="text-right">{t("reports.chart.likes")}</th>
                    <th className="text-right">
                      {t("reports.chart.percentage")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.accountOverviewDetails &&
                  props.accountOverviewDetails.audience_followers &&
                  props.accountOverviewDetails.audience_followers.data &&
                  props.accountOverviewDetails.audience_followers.data
                    .audience_genders ? (
                    props.accountOverviewDetails.audience_followers.data.audience_genders.map(
                      (gender, index) => (
                        <tr key={index}>
                          <th scope="row">{capitalize(gender.code)}</th>
                          <td className="text-right">
                            {formatNumbersToCommaSeperated(
                              gender.weight *
                                props.accountOverviewDetails.user_profile
                                  .followers
                            )}
                          </td>
                          <td className="text-right">
                            {formatNumbersToCommaSeperated(
                              gender.weight *
                                props.accountOverviewDetails.user_profile
                                  .avg_likes
                            )}
                          </td>
                          <td className="text-right">
                            {(gender.weight * 100).toFixed(2) + "%"}
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan="4" align="center">
                        {t("errors.no_record")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
        </div>
      </CardBody>
    </Card>
  );
};

export default FollowersGenderSplit;
