import React from "react";
import { Route, Redirect } from "react-router-dom";
const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return rest.loginUser && !rest.path.includes("reports") ? (
        <Redirect to="/discovery" />
      ) : (
        <Component {...props} />
      );
    }}
  />
);
export default PublicRoute;
