import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import settings from "settings";
import { Row, Col, TabContent, Nav, NavItem } from "reactstrap";
import { useTranslation } from "react-i18next";

import ProfileSection from "./profileSection";
import InfulencersSection from "./infulencersSection/infulencersSection";
import AudiencesSection from "./audiencesSection/audiencesSection";
import GlobalLoadScreen from "modules/globalLoadScreen/GlobalLoadScreen";
import ScrollToTop from "components/scroll/ScrollToTop";
import PostsSection from "./postSection";
import Stories from "./stories";
import { Link } from "react-scroll";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./index.scss";

import {
  getCreatorLists,
  addCreatorList,
  updateCreatorList,
} from "../creatorList/actions/index";
import { downloadReportPdfAction } from "../userProfile/actions/index";
import { getAccountOverview, showLoadingAction } from "./actions/index";

const generateImgUrl = (type, id) => {
  if (type === "country") {
    return `${process.env.REACT_APP_API_URL}/assets/images/country_flags/${id}`;
  } else if (type === "brand") {
    return `${process.env.REACT_APP_API_URL}/assets/images/brand_logo/${id}`;
  } else if (type === "interest") {
    return `${process.env.REACT_APP_API_URL}/assets/images/interests/${id}`;
  }
};

const ReportSection = (props) => {
  const { t } = useTranslation();
  const [activeMainTab, setActiveTab] = useState("0");
  const [showStackLabels, setShowStackLabels] = useState(true);

  const [reportModals, setReportModals] = useState({
    engagementRateModal: false,
    lookAlikesModal: false,
    engagementSpreadForLastPostsModal: false,
    popularHashModal: false,
    popularAtTheRateModal: false,
    influencersBrandAffinityModal: false,
    influencersInterestsModal: false,
    followersGenderSplitModal: false,
    followersAgeAndGenderSplitModal: false,
    followersLocationByCityModal: false,
    followersLocationByCountryModal: false,
    followersEthnicityModal: false,
    followersLanguageModal: false,
    followersAudienceBrandAffinityModal: false,
    followersAudienceInterestsModal: false,
    followersAudienceLookAlikesModal: false,
    followersNotableFiltersModal: false,
    likesGenderSplitModal: false,
    likesAgeAndGenderSplitModal: false,
    likesLocationByCityModal: false,
    likesLocationByCountryModal: false,
    likesEthnicityModal: false,
    likesLanguageModal: false,
    likesAudienceBrandAffinityModal: false,
    likesAudienceInterestsModal: false,
    likesAudienceLookAlikesModal: false,
    likesNotableFiltersModal: false,
  });

  useEffect(() => {
    const handleResize = () => {
      if (!!navigator.maxTouchPoints && !window.screen.orientation.angle) {
        setShowStackLabels(false);
      } else {
        setShowStackLabels(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setShowStackLabels]);

  const {
    filters: { tooltip },
  } = settings;

  const toggleModal = (modalKey) => {
    setReportModals((prevState) => ({
      ...prevState,
      [modalKey]: !reportModals[modalKey],
    }));
  };

  const calculatePercentage = (type) => {
    if (props.accountOverviewDetails?.user_profile?.stat_history.length) {
      let data = props.accountOverviewDetails.user_profile.stat_history;
      let firstMonth = data[data.length - 1][type];
      let lastMonth =
        (data[data.length - 2] && data[data.length - 2][type]) || 0;
      return isFinite(((firstMonth - lastMonth) / lastMonth) * 100)
        ? (((firstMonth - lastMonth) / lastMonth) * 100).toFixed(2) + "%"
        : "0.00%";
    }
    return "";
  };

  const downloadPdf = (ref) => {
    if (ref === "app") {
      let reportId = window.location.pathname.split("/").pop().trim();
      props.downloadReportPdfAction(reportId);
    } else {
      props.showLoadingAction("start");
      html2canvas(document.getElementById(ref), {
        removeContainer: true,
        useCORS: true,
        logging: false,
        foreignObjectRendering: ref === "app",
        ignoreElements: (element) => {
          return (
            element.id === "header" ||
            element.id === "search" ||
            element.id === "loader" ||
            element.id === "footer" ||
            (element.className &&
              (element.className.toString().includes("download-icon-button") ||
                element.className.toString().includes("close") ||
                element.className.toString().includes("tooltip-icon")))
          );
        },
      })
        .then((canvas) => {
          let contentDataURL = canvas.toDataURL("image/png");
          contentDataURL = contentDataURL
            .split(";")
            .pop()
            .split("base64,")
            .pop();
          let canvasWidth = document.getElementById(ref).scrollWidth;
          let canvasHeight = document.getElementById(ref).scrollHeight;
          let pdf = new jsPDF();

          let pageHeight = pdf.internal.pageSize.getHeight();
          let pageWidth = pdf.internal.pageSize.getWidth();
          let ratio = canvasWidth / canvasHeight;
          let imgHeight = pageWidth / ratio;
          let heightLeft = imgHeight;
          let position = 0;
          pdf.addImage(
            contentDataURL,
            "PNG",
            0,
            position,
            pageWidth,
            imgHeight
          );
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(
              contentDataURL,
              "PNG",
              0,
              position,
              pageWidth,
              imgHeight
            );
            heightLeft -= pageHeight;
          }
          pdf.save("exported.pdf");
          props.showLoadingAction("stop");
        })
        .catch((error) => {
          console.log(error);
          props.showLoadingAction("stop");
        });
    }
  };

  return (
    <section>
      {!props.accountOverviewDetails ? (
        <GlobalLoadScreen />
      ) : (
        <div>
          <ScrollToTop setActiveTab={setActiveTab} />
          <ProfileSection
            {...props}
            downloadPdf={downloadPdf}
            tooltip={tooltip}
          />
          <Row>
            <Col md="12" className="iap-tabber">
              {props._blank ? (
                <hr className="h-row mt-2" />
              ) : (
                <Nav pills className="d-flex justify-content-center">
                  <NavItem>
                    <Link
                      smooth={true}
                      to="Creator"
                      onClick={() => setActiveTab("0")}
                      className={
                        activeMainTab === "0" ? "active nav-link" : "nav-link"
                      }
                    >
                      {t("reports.influencer")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      smooth={true}
                      to="Audience"
                      onClick={() => setActiveTab("1")}
                      className={
                        activeMainTab === "1" ? "active nav-link" : "nav-link"
                      }
                    >
                      {t("reports.audience")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      smooth={true}
                      onClick={() => setActiveTab("2")}
                      to="Posts"
                      className={
                        activeMainTab === "2" ? "active nav-link" : "nav-link"
                      }
                    >
                      {t("reports.posts")}
                    </Link>
                  </NavItem>
                  {props.stories &&
                    props.accountOverviewDetails?.user_profile?.type ===
                      "instagram" && (
                      <NavItem>
                        <Link
                          smooth={true}
                          onClick={() => setActiveTab("3")}
                          to="Stories"
                          className={
                            activeMainTab === "3"
                              ? "active nav-link"
                              : "nav-link"
                          }
                        >
                          {t("reports.stories")}
                        </Link>
                      </NavItem>
                    )}
                </Nav>
              )}

              <TabContent>
                <InfulencersSection
                  modals={reportModals}
                  toggleModal={toggleModal}
                  downloadPdf={downloadPdf}
                  calculatePercentage={calculatePercentage}
                  generateImgUrl={generateImgUrl}
                  tooltip={tooltip}
                  {...props}
                />
                <AudiencesSection
                  {...props}
                  modals={reportModals}
                  toggleModal={toggleModal}
                  showStackLabels={showStackLabels}
                  downloadPdf={downloadPdf}
                  downloadReportPdf={props.downloadReportPdf}
                  generateImgUrl={generateImgUrl}
                  toggle={props.toggle}
                  tooltip={tooltip}
                />
                <PostsSection {...props} state={props.state} />
                {props.stories &&
                  props.accountOverviewDetails?.user_profile?.type ===
                    "instagram" && <Stories {...props} />}
              </TabContent>
            </Col>
          </Row>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    accountOverviewDetails: state.reports.accountOverviewDetails,
    cloudTags: state.reports.cloudTags,
    stories: state.reports.stories,
    loginUser: state.login.loginUser,
    creatorLists: state.creatorLists.lists,
    dismissCreatorListModal: state.creatorLists.dismissModal,
  };
};

export default connect(mapStateToProps, {
  getAccountOverview,
  showLoadingAction,
  downloadReportPdfAction,
  getCreatorLists,
  addCreatorList,
  updateCreatorList,
})(ReportSection);
