import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { formatNumbersToCommaSeperated } from "../../../../actions";
import { RenderButton } from "../../../../components";
import { showTooltip } from "../../../../actions";

const chartOptions = (props) => {
  let data =
    props.postAnalysisDetails &&
    props.postAnalysisDetails.audience_likers &&
    props.postAnalysisDetails.audience_likers.data &&
    props.postAnalysisDetails.audience_likers.data.audience_genders_per_age
      ? props.postAnalysisDetails.audience_likers.data.audience_genders_per_age.map(
          (obj, index) => obj
        )
      : [];
  let maleData = data.map((obj, index) => [
    obj.code,
    parseFloat((obj.male * 100).toFixed(2)),
  ]);
  let femaleData = data.map((obj, index) => [
    obj.code,
    parseFloat((obj.female * 100).toFixed(2)),
  ]);
  let categories = data.map((obj, index) => obj.code);
  return {
    chart: {
      type: "column",
      height: 250,
      backgroundColor: null,
    },

    title: null,
    xAxis: {
      lineWidth: 0,
      type: "category",
      tickColor: "transparent",
      categories: categories,
    },
    yAxis: {
      lineWidth: 0,
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      stackLabels: {
        enabled: props.state.showStackLabels,
        verticalAlign: "top",
        allowOverlap: props.state.showStackLabels,
        formatter: function () {
          return this.total + " %";
        },
      },

      title: {
        text: null,
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    series: [
      {
        color: "#1f87ee",
        borderRadius: 10,
        pointWidth: window.innerWidth < 1024 ? 15 : 20,
        name: "Male",
        stack: "male",
        data: maleData,
      },
      {
        color: "#ff5537",
        borderRadius: 10,
        pointWidth: window.innerWidth < 1024 ? 15 : 20,
        name: "Female",
        stack: "female",
        data: femaleData,
      },
    ],
    tooltip: {
      enabled: !props.state.showStackLabels,
      formatter: function () {
        return this.total + " %";
      },
    },
    legend: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
  };
};
const LikesAgeAndGenderSplit = (props) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>
          <span className="tooltip-button-wrap">
            <span className="text-tooltip">Age and Gender Split</span>{" "}
            <span
              href="#"
              id="likesAgeAndGenderSplit"
              className="tooltip-icon"
            ></span>
          </span>
          <UncontrolledTooltip
            placement="bottom"
            className="tooltip-content"
            target="likesAgeAndGenderSplit"
          >
            {showTooltip("likesAgeAndGenderSplit")}
          </UncontrolledTooltip>
        </CardTitle>
        <div className="card-content ch-300">
          {!props.postAnalysisDetails ||
          !props.postAnalysisDetails.audience_likers ||
          !props.postAnalysisDetails.audience_likers.data ||
          !props.postAnalysisDetails.audience_likers.data
            .audience_genders_per_age ||
          !props.postAnalysisDetails.audience_likers.data
            .audience_genders_per_age.length ? (
            <span className="text-center text-grey ">
              <p className="pt-50">No Record Found</p>
            </span>
          ) : (
            <div className="graph-wrap">
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions(props)}
              />
            </div>
          )}
        </div>
        {props.postAnalysisDetails &&
        props.postAnalysisDetails.audience_likers &&
        props.postAnalysisDetails.audience_likers.data &&
        props.postAnalysisDetails.audience_likers.data
          .audience_genders_per_age &&
        props.postAnalysisDetails.audience_likers.data.audience_genders_per_age
          .length ? (
          <div className="bottom">
            <Button
              color="link"
              onClick={() => props.toggleModal("likesAgeAndGenderSplitModal")}
            >
              View More
            </Button>

            <Modal
              id="likesAgeAndGenderSplitModalDownload"
              isOpen={props.state.modals.likesAgeAndGenderSplitModal}
              toggle={() => props.toggleModal("likesAgeAndGenderSplitModal")}
            >
              <ModalHeader
                toggle={() => props.toggleModal("likesAgeAndGenderSplitModal")}
              >
                <span className="tooltip-button-wrap">
                  <span className="text-tooltip">Age and Gender Split</span>{" "}
                  <span
                    href="#"
                    id="likesAgeAndGenderSplitModal"
                    className="tooltip-icon"
                  ></span>
                </span>
                <UncontrolledTooltip
                  placement="bottom"
                  className="tooltip-content"
                  target="likesAgeAndGenderSplitModal"
                >
                  {showTooltip("likesAgeAndGenderSplitModal")}
                </UncontrolledTooltip>
                <RenderButton
                  onClick={() =>
                    props.downloadPdf("likesAgeAndGenderSplitModalDownload")
                  }
                  className="download-icon-button mg-left-15 link"
                >
                  Download PDF
                </RenderButton>
              </ModalHeader>
              <ModalBody>
                <div className="multiple-tbl">
                  <h4>Age Split</h4>
                  <Table className="modal-table">
                    <thead>
                      <tr>
                        <th>Age</th>
                        <th className="text-right">Likes</th>
                        <th className="text-right">Percentage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.postAnalysisDetails &&
                      props.postAnalysisDetails.audience_likers &&
                      props.postAnalysisDetails.audience_likers.data &&
                      props.postAnalysisDetails.audience_likers.data
                        .audience_genders_per_age ? (
                        props.postAnalysisDetails.audience_likers.data.audience_genders_per_age.map(
                          (obj, index) => (
                            <tr key={index}>
                              <th scope="row">{obj.code}</th>
                              <td className="text-right">
                                {formatNumbersToCommaSeperated(
                                  (obj.male + obj.female) *
                                    props.postAnalysisDetails.posts_profile
                                      .likes
                                )}
                              </td>
                              <td className="text-right">
                                {((obj.male + obj.female) * 100).toFixed(2) +
                                  "%"}
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td align="center" colSpan="3">
                            No Record Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <div className="multiple-tbl">
                  <h4>Female Age Split</h4>
                  <Table className="modal-table">
                    <thead>
                      <tr>
                        <th>Age</th>
                        <th className="text-right">Likes</th>
                        <th className="text-right">Percentage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.postAnalysisDetails &&
                      props.postAnalysisDetails.audience_likers &&
                      props.postAnalysisDetails.audience_likers.data &&
                      props.postAnalysisDetails.audience_likers.data
                        .audience_genders_per_age ? (
                        props.postAnalysisDetails.audience_likers.data.audience_genders_per_age.map(
                          (obj, index) => (
                            <tr key={index}>
                              <th scope="row">{obj.code}</th>
                              <td className="text-right">
                                {formatNumbersToCommaSeperated(
                                  obj.female *
                                    props.postAnalysisDetails.posts_profile
                                      .likes
                                )}
                              </td>
                              <td className="text-right">
                                {(obj.female * 100).toFixed(2) + "%"}
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td align="center" colSpan="3">
                            No Record Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <div className="multiple-tbl">
                  <h4>Male Age Split</h4>
                  <Table className="modal-table">
                    <thead>
                      <tr>
                        <th>Age</th>
                        <th className="text-right">Likes</th>
                        <th className="text-right">Percentage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.postAnalysisDetails &&
                      props.postAnalysisDetails.audience_likers &&
                      props.postAnalysisDetails.audience_likers.data &&
                      props.postAnalysisDetails.audience_likers.data
                        .audience_genders_per_age ? (
                        props.postAnalysisDetails.audience_likers.data.audience_genders_per_age.map(
                          (obj, index) => (
                            <tr key={index}>
                              <th scope="row">{obj.code}</th>
                              <td className="text-right">
                                {formatNumbersToCommaSeperated(
                                  obj.male *
                                    props.postAnalysisDetails.posts_profile
                                      .likes
                                )}
                              </td>
                              <td className="text-right">
                                {(obj.male * 100).toFixed(2) + "%"}
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td align="center" colSpan="3">
                            No Record Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </ModalBody>
            </Modal>
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default LikesAgeAndGenderSplit;
