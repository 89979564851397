import { createRequestActionTypes, API_URL } from "../../../actions";
export const GET_ACCOUNT_OVERVIEW_URL = `${API_URL}/reports`;
export const GET_CLOUD_TAGS = `${API_URL}/identification/relevant_tags`;
export const GET_INFLUENCER_PROFILE_DATA_BY_SEARCH = `${API_URL}/reports/new`;
export const STORY_ANALYSIS_URL = `${API_URL}/story_analysis`;
export const GET_AVAILABLE_ACCOUNTS = `${API_URL}/identification/users`;
export const reportsActionTypes = {
  getAccountOverview: createRequestActionTypes("GET_ACCOUNT_OVERVIEW"),
  getCloudTags: createRequestActionTypes("GET_CLOUD_TAGS"),
  getInfluencerProfileDataBySearch: createRequestActionTypes(
    "GET_INFLUENCER_PROFILE_DATA_BY_SEARCH"
  ),
  showReportsErrorMessage: createRequestActionTypes(
    "SHOW_REPORTS_ERROR_MESSAGE"
  ),
  clearReportsErrorMessage: createRequestActionTypes(
    "CLEAR_REPORTS_ERROR_MESSAGE"
  ),
  showLoading: createRequestActionTypes("SHOW_LOADING"),
  getStoryAnalysis: createRequestActionTypes("STORY_ANALYSIS"),
  clearSubscriptionError: createRequestActionTypes(
    "CLEAR_SUBSCRIPTION_ERROR_MESSAGE"
  ),
  showSubscriptionError: createRequestActionTypes("SHOW_SUBSCRIPTION_ERROR"),
  toggleViewModal: createRequestActionTypes("TOOGLE_VIEW_SUCCESS"),
  getAvailableAccounts: createRequestActionTypes("GET_AVAILABLE_ACCOUNTS"),
  getAvailableAccountsSearch: createRequestActionTypes( // not used
    "GET_AVAILABLE_ACCOUNTS_SEARCH"
  ),
};
