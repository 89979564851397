import { createRequestActionTypes, API_URL } from "../../../actions";

export const GET_CAMPAIGNS_URL = `${API_URL}/campaigns.json`;
export const GET_CAMPAIGN_URL = `${API_URL}/campaign.json`;
export const DELETE_CAMPAIGN_URL = `${API_URL}/campaign/:campaign_id.json`;
export const GET_POST_PREVIEW_URL = `${API_URL}/campaignContent/preview.json`;
export const GET_IMAGE_PREVIEW_URL = `${API_URL}/campaignContent/previewImage.json`;
export const CREATE_CAMPAIGN_URL = `${API_URL}/campaign.json`;
export const CREATE_CAMPAIGN_POST_URL = `${API_URL}/campaignContent.json`;
export const GET_CAMPAIGN_POSTS_URL = `${API_URL}/campaignContents.json`;
export const DELETE_CAMPAIGN_POST_URL = `${API_URL}/campaignContent/:content_id.json`;
export const ADD_SHARED_USER_URL = `${API_URL}/campaign/addSharedUser.json`;
export const REMOVE_SHARED_USER_URL = `${API_URL}/campaign/removeSharedUser.json`;

export const ADD_SHARED_USER_SUCCESS_MESSAGE = (email) => email ? `Shared campaign with ${email}` : "Shared campaign";
export const ADD_SHARED_USER_ERROR_MESSAGE = (email) => email ? `Error sharing campaign with ${email}` : "Error sharing campaign with user";
export const REMOVE_SHARED_USER_SUCCESS_MESSAGE = (email) => email ? `Revoked ${email}'s access to campaign` : "Revoked user's access to campaign";
export const REMOVE_SHARED_USER_ERROR_MESSAGE = (email) => email ? `Error revoking ${email}'s campaign access` : "Error revoking user's campaign access";
export const CREATE_POST_SUCCESS_MESSAGE = "Post added to campaign successfully";
export const CREATE_POST_ERROR_MESSAGE = "Error adding post";
export const POST_LIMIT_REACHED_MESSAGE = "You have reached the maximum number of posts for this campaign";

export const campaignManagerActionTypes = {
  getCampaigns: createRequestActionTypes("GET_CAMPAIGNS"),
  getCampaign: createRequestActionTypes("GET_CAMPAIGN"),
  addSharedUser: createRequestActionTypes("ADD_CAMPAIGN_SHARED_USER"),
  removeSharedUser: createRequestActionTypes("REMOVE_CAMPAIGN_SHARED_USER"),
  deleteCampaign: createRequestActionTypes("DELETE_CAMPAIGN"),
  setNewPostModalIsOpen: createRequestActionTypes(
    "SET_ADD_POST_MODAL_IS_OPEN_OPEN"
  ),
  getPostPreview: createRequestActionTypes("GET_CAMPAIGN_POST_PREVIEW"),
  getPostPreviewImage: createRequestActionTypes("GET_IMAGE_PREVIEW"),
  getCreatorPreviewImage: createRequestActionTypes("GET_CREATOR_IMAGE_PREVIEW"),
  createCampaign: createRequestActionTypes("CREATE_CAMPAIGN"),
  updateCampaign: createRequestActionTypes("UPDATE_CAMPAIGN"),
  createCampaignPost: createRequestActionTypes("CREATE_CAMPAIGN_POST"),
  setCampaignModalIsOpen: createRequestActionTypes(
    "SET_CAMPAIGN_MODAL_IS_OPEN_OPEN"
  ),
  getCampaignPosts: createRequestActionTypes("GET_CAMPAIGN_POSTS"),
  deleteCampaignPost: createRequestActionTypes("DELETE_CAMPAIGN_POST"),

  // getCampaignCreators: createRequestActionTypes("GET_CAMPAIGN_CREATORS"),
  // getCampaignContent: createRequestActionTypes("GET_CAMPAIGN_CONTENT"),
};
