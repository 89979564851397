import React from "react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import { FollowerTag } from "components/tags";
import networkImage from "helpers/networkImage";
import { Trans } from "react-i18next";
import { Col, Container, DropdownItem, Row } from "reactstrap";

export const DropdownItems = (items, handleDropDownSelect) => {
	// if (!items || !items.length) return;
	const DropdownItems = items.map((item, index) => (
    <DropdownItem
      key={index}
      className="infulence-dropdown-list-2"
      onClick={(e) => handleDropDownSelect(e, item)}
    >
      <h1 className="report-analyze">
        <Trans i18nKey="reports.generate">Generate</Trans>
      </h1>
      <Container>
        <Row>
          <Col md="8">
            <Row className="generate-report-row">
              <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                <div className="account-image">
                  {item.picture ? (
                    <img
                      src={item.picture}
                      alt="profile pic"
                      width="50px"
                      height="50px"
                      className="rounded-circle"
                    />
                  ) : (
                    <UserCircleIcon
                      className="user-circle rounded-circle"
                      fill="#00c2e6b3"
                      width={50}
                      height={50}
                      strokeWidth={1}
                    />
                  )}
                  {networkImage(item.type)}
                </div>
              </span>
              <div className="d-flex align-items-center">
                <h4 className="ml-2">{item.fullname || item.username}</h4>
                {item.is_verified && (
                  <CheckBadgeIcon
                    className="h-5 w-5 ml-2 mb-3"
                    fill="#00c2e6"
                    width={25}
                    height={25}
                  />
                )}
              </div>
            </Row>
          </Col>
          {item.followers > 0 && (
            <Col md="4" className="text-center my-auto follower-font">
              <FollowerTag followers={item.followers}></FollowerTag>
            </Col>
          )}
        </Row>
      </Container>
    </DropdownItem>
  ));
	return DropdownItems;
};
