import {
  checkHttpStatus,
  actionCreator,
  createQueryParams,
  privateHeaders,
  TAGS_ERROR_MSG,
  checkAuthTokenExpiry,
  CHECKOUT_APP_EXTERNAL_URL,
} from "../../../actions/index";
import {
  identificationActionTypes,
  POST_EXPORTS_URL,
  GET_AUTH_URL,
  INFLUENCERS_LIST_URL,
  GET_CLOUD_TAGS,
  SEARCH_LOCATIONS_URL,
  CHECK_EXPORTS_REMAINING,
  SEARCH_UNIVERSITY_URL,
  SEARCH_BRANDS_URL,
  SEARCH_INTERESTS_URL,
  UNLOCK_MORE_RESULTS_URL,
  GET_TOPIC_TAGS,
  GET_AVAILABLE_ACCOUNTS_URL,
  GLOBAL_CREATOR_SEARCH_URL,
  GET_GLOBAL_COUNT,
} from "../constants/index";
import { signOut, createCheckoutSession } from "../../login/actions";
import history from "../../../history";
import { showPlansError } from "../../paymentPortal/actions";
import { showPlansErrorInReports } from "../../reports/actions";
import { getUserBalanceData } from "../../header/actions";
import settings from "settings";

export const getInfluencerRecords = (
  searchObj,
  clearFields,
  isCloudTagsAPI
) => {
  return (dispatch, getState) => {
    if (clearFields === true) {
      dispatch(
        actionCreator(identificationActionTypes.influencers_list.SUCCESS, null)
      );
      dispatch(getCloudTags(searchObj, clearFields));
      dispatch(getTopicTags(searchObj, clearFields));
    } else {
      dispatch(
        actionCreator(identificationActionTypes.influencers_list.REQUEST)
      );
      fetch(`${INFLUENCERS_LIST_URL}`, {
        method: "post",
        body: JSON.stringify(searchObj),
        headers: privateHeaders(getState),
      })
        .then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response))
        .then((response) => {
          if (response.success === false) {
            dispatch(
              actionCreator(
                identificationActionTypes.influencers_list.SUCCESS,
                response
              )
            );
            if (response.redirect) {
              if (
                response.redirect.toUpperCase() === "INFLUENCER_SEARCH_PAGE"
              ) {
                dispatch(showPlansErrorInReports(response.message));
                history.push("/reports");
              } else if (response.redirect.toUpperCase() === "PAYMENT_PAGE") {
                dispatch(showPlansError(response.message));
                // history.push('/subscription');
                if (
                  getState().login.checkoutSessionDetails &&
                  getState().login.checkoutSessionDetails._id
                ) {
                  window.open(
                    `${CHECKOUT_APP_EXTERNAL_URL}/?session=${
                      getState().login.checkoutSessionDetails._id
                    }`,
                    "_self"
                  );
                } else {
                  dispatch(createCheckoutSession());
                }
              }
            }
          } else {
            dispatch(
              actionCreator(
                identificationActionTypes.influencers_list.SUCCESS,
                response
              )
            );
            if (isCloudTagsAPI === true) {
              let cloudSearchbj = {
                tag:
                  searchObj.search.relevance.value.replace("\t", "%20") || "",
                platform: searchObj.platform,
              };
              dispatch(getCloudTags(cloudSearchbj));
            }
          }
        })
        .catch((error) => {
          dispatch(
            actionCreator(identificationActionTypes.influencers_list.FAILURE)
          );
          if (
            error.statusCode &&
            error.statusCode >= 400 &&
            error.statusCode <= 500
          ) {
            dispatch(signOut(error));
          }
        });
    }
  };
};

export const getGlobalCreatorDatabaseTotalCount = () => {
  return (dispatch, getState) => {
      fetch(`${GET_GLOBAL_COUNT}`, {
        method: "GET",
        headers: privateHeaders(getState),
      })
        .then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response))
        .then((response) => {
          dispatch(
            actionCreator(
              identificationActionTypes.getAvailableAccountsTotal.SUCCESS,
              response
            )
          );
        })
        .catch((error) => {
          dispatch(
            actionCreator(identificationActionTypes.getAvailableAccountsTotal.FAILURE)
          );
          if (
            error.statusCode &&
            error.statusCode >= 400 &&
            error.statusCode <= 500
          ) {
            dispatch(signOut(error));
          }
        });
    // }
  };
};

export const getLocationsBasedOnFilter = (filterName, searchObj) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(identificationActionTypes.getLocations.REQUEST));
    let queryParams = createQueryParams(searchObj);
    fetch(`${SEARCH_LOCATIONS_URL}${queryParams}`, {
      method: "get",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        response.filterName = filterName;
        dispatch(
          actionCreator(
            identificationActionTypes.getLocations.SUCCESS,
            response
          )
        );
      })
      .catch((error) => {
        dispatch(actionCreator(identificationActionTypes.getLocations.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const getCloudTags = (searchObj, clearFields) => {
  return (dispatch, getState) => {
    if (clearFields === true) {
      dispatch(
        actionCreator(identificationActionTypes.getCloudTags.SUCCESS, null)
      );
    } else {
      let queryParams = createQueryParams(searchObj);

      dispatch(actionCreator(identificationActionTypes.getCloudTags.REQUEST));
      fetch(`${GET_CLOUD_TAGS}${queryParams}`, {
        method: "get",
        headers: privateHeaders(getState),
      })
        .then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response))
        .then((response) => {
          dispatch(
            actionCreator(
              identificationActionTypes.getCloudTags.SUCCESS,
              response
            )
          );
          if (response.status && response.status.toLowerCase() === "error") {
            dispatch(showTagsErrorMSG());
          }
        })
        .catch((error) => {
          dispatch(
            actionCreator(identificationActionTypes.getCloudTags.FAILURE)
          );
          if (
            error.statusCode &&
            error.statusCode >= 400 &&
            error.statusCode <= 500
          ) {
            dispatch(signOut(error));
          }
        });
    }
  };
};
export const getTopicTags = (searchObj, clearFields) => {
  return (dispatch, getState) => {
    if (clearFields === true) {
      dispatch(
        actionCreator(identificationActionTypes.getTopicTags.SUCCESS, null)
      );
    } else {
      let queryParams = createQueryParams(searchObj);

      dispatch(actionCreator(identificationActionTypes.getTopicTags.REQUEST));
      fetch(`${GET_TOPIC_TAGS}${queryParams}`, {
        method: "get",
        headers: privateHeaders(getState),
      })
        .then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response))
        .then((response) => {
          dispatch(
            actionCreator(
              identificationActionTypes.getTopicTags.SUCCESS,
              response
            )
          );
          if (response.status && response.status.toLowerCase() === "error") {
            dispatch(showTagsErrorMSG());
          }
        })
        .catch((error) => {
          dispatch(
            actionCreator(identificationActionTypes.getTopicTags.FAILURE)
          );
          if (
            error.statusCode &&
            error.statusCode >= 400 &&
            error.statusCode <= 500
          ) {
            dispatch(signOut(error));
          }
        });
    }
  };
};
export const getAvailableAccounts = (searchObj, clearFields) => {
  return (dispatch, getState) => {
    if (clearFields === true) {
      dispatch(
        actionCreator(
          identificationActionTypes.getAvailableAccounts.SUCCESS,
          null
        )
      );
    } else {
      let queryParams = createQueryParams(searchObj);

      dispatch(
        actionCreator(identificationActionTypes.getAvailableAccounts.REQUEST)
      );
      fetch(`${GET_AVAILABLE_ACCOUNTS_URL}${queryParams}`, {
        method: "GET",
        headers: privateHeaders(getState),
      })
        .then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response))
        .then((response) => {
          dispatch(
            actionCreator(
              identificationActionTypes.getAvailableAccounts.SUCCESS,
              response.data
            )
          );
        })
        .catch((error) => {
          dispatch(
            actionCreator(
              identificationActionTypes.getAvailableAccounts.FAILURE
            )
          );
          if (
            error.statusCode &&
            error.statusCode >= 400 &&
            error.statusCode <= 500
          ) {
            dispatch(signOut(error));
          }
        });
    }
  };
};

export const showLoadingAction = (action) => {
  return (dispatch, getState) => {
    if (action.toLowerCase() === "start") {
      dispatch(actionCreator(identificationActionTypes.showLoading.REQUEST));
    } else if (action.toLowerCase() === "stop") {
      dispatch(actionCreator(identificationActionTypes.showLoading.SUCCESS));
    }
  };
};
export const showTagsErrorMSG = () => {
  return (dispatch, getState) => {
    let errorMsg = TAGS_ERROR_MSG;
    dispatch(
      actionCreator(
        identificationActionTypes.showTagsErrorMSG.SUCCESS,
        errorMsg
      )
    );
  };
};
export const clearTagsErrorMSG = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(identificationActionTypes.clearTagsErrorMSG.SUCCESS)
    );
  };
};
export const clearExportsErrorMSGAction = (searchObj, isCloudTagsAPI) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(identificationActionTypes.clearExportsErrorMSG.SUCCESS)
    );
    dispatch(getInfluencerRecords(searchObj, null, isCloudTagsAPI));
  };
};

export const getUniversitiesBasedOnFilter = (filterName, searchObj) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(identificationActionTypes.getUniversities.REQUEST));
    let queryParams = createQueryParams(searchObj);
    fetch(`${SEARCH_UNIVERSITY_URL}${queryParams}`, {
      method: "get",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        response.filterName = filterName;
        dispatch(
          actionCreator(
            identificationActionTypes.getUniversities.SUCCESS,
            response
          )
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(identificationActionTypes.getUniversities.FAILURE)
        );
        if (error.statusCode && error.statusCode === 401) {
          dispatch(signOut(error));
        }
      });
  };
};

export const getFilteredBrands = (optionListName, queryParm) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(identificationActionTypes.getFilteredBrands.REQUEST)
    );

    fetch(`${SEARCH_BRANDS_URL}${queryParm ? `?q=${queryParm}` : ""}`, {
      method: "get",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        response.optionListName = optionListName;
        dispatch(
          actionCreator(
            identificationActionTypes.getFilteredBrands.SUCCESS,
            response
          )
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(identificationActionTypes.getFilteredBrands.FAILURE)
        );
        if (error.statusCode && error.statusCode === 401) {
          dispatch(signOut(error));
        }
      });
  };
};
export const getFilteredLookalikes = (optionListName, queryParm, platform) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(identificationActionTypes.getFilteredLookalikes.REQUEST)
    );

    let fullUrl = new URL(GLOBAL_CREATOR_SEARCH_URL);
    if (queryParm) fullUrl.searchParams.append('q', queryParm);
    if (platform) fullUrl.searchParams.append('platform', platform);
    fetch(fullUrl, {
      method: "get",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        response.optionListName = optionListName;
        dispatch(
          actionCreator(
            identificationActionTypes.getFilteredLookalikes.SUCCESS,
            response
          )
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(identificationActionTypes.getFilteredLookalikes.FAILURE)
        );
        if (error.statusCode && error.statusCode === 401) {
          dispatch(signOut(error));
        }
      });
  };
};
export const getFilteredInterests = (optionListName, queryParm) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(identificationActionTypes.getFilteredInterests.REQUEST)
    );

    fetch(
      `${SEARCH_INTERESTS_URL}${
        queryParm
          ? `?q=${queryParm}&locale=${process.env.REACT_APP_LOCALE}`
          : `?locale=${process.env.REACT_APP_LOCALE}`
      }`,
      {
        method: "get",
        headers: privateHeaders(getState),
      }
    )
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        response.optionListName = optionListName;
        dispatch(
          actionCreator(
            identificationActionTypes.getFilteredInterests.SUCCESS,
            response
          )
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(identificationActionTypes.getFilteredInterests.FAILURE)
        );
        if (error.statusCode && error.statusCode === 401) {
          dispatch(signOut(error));
        }
      });
  };
};

export const checkExportsRemaining = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(identificationActionTypes.checkExportsRemaining.REQUEST)
    );
    fetch(CHECK_EXPORTS_REMAINING, {
      method: "get",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.success) {
          dispatch(
            actionCreator(
              identificationActionTypes.checkExportsRemaining.SUCCESS,
              response
            )
          );
        } else {
          dispatch(
            actionCreator(
              identificationActionTypes.checkExportsRemaining.SUCCESS,
              null
            )
          );
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(identificationActionTypes.checkExportsRemaining.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const postForExportCSV = (postData, shouldToggleLoader) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(identificationActionTypes.post_exports_csv.REQUEST));
    if (shouldToggleLoader) {
      dispatch(actionCreator(identificationActionTypes.showLoading.REQUEST));
    }
    const fullUrl = new URL(POST_EXPORTS_URL);
    fullUrl.searchParams.append('locale', process.env.REACT_APP_LOCALE);
    fetch(fullUrl, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: privateHeaders(getState),
    })
      .then((response) => response.blob())
      .then((blob) => {
        dispatch(
          actionCreator(
            identificationActionTypes.post_exports_csv.SUCCESS,
            true
          )
        );
        if (blob.type === "text/csv") {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `${settings.exportName}(${
            postData.paging.limit
          })_${new Date().toISOString()}.csv`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.parentNode.removeChild(a);
          dispatch(getUserBalanceData(getState().login.loginUser.id));
        }
        if (shouldToggleLoader) {
          dispatch(actionCreator(identificationActionTypes.showLoading.SUCCESS));
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(identificationActionTypes.post_exports_csv.FAILURE)
        );
        if (shouldToggleLoader) {
          dispatch(actionCreator(identificationActionTypes.showLoading.SUCCESS));
        }
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const exportCSVById = (id) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(identificationActionTypes.post_exports_csv.REQUEST));
    const fullUrl = new URL(`${POST_EXPORTS_URL}/${id}`);
    fullUrl.searchParams.append('locale', process.env.REACT_APP_LOCALE);
    fetch(fullUrl, {
      method: "GET",
      headers: privateHeaders(getState),
    })
      .then((response) => response.blob())
      .then((blob) => {
        dispatch(
          actionCreator(
            identificationActionTypes.post_exports_csv.SUCCESS,
            true
          )
        );
        if (blob.type === "text/csv") {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `${settings.exportName}${id}.csv`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.parentNode.removeChild(a);
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(identificationActionTypes.post_exports_csv.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const dontCallInitialEmptyFiltersAPIForInfluencers = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(
        identificationActionTypes.dontCallInitialEmptyFiltersAPIForInfluencers
          .SUCCESS
      )
    );
  };
};

export const unlockMoreResultsAction = (searchObj) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(identificationActionTypes.unlock_more_results.REQUEST)
    );
    fetch(`${UNLOCK_MORE_RESULTS_URL}`, {
      method: "POST",
      body: JSON.stringify(searchObj),
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.success) {
          dispatch(
            actionCreator(
              identificationActionTypes.unlock_more_results.SUCCESS,
              response
            )
          );
          // let data = getState().login.previouslyExportedIds && getState().login.previouslyExportedIds.length ? getState().login.previouslyExportedIds : [];
          // if (data.indexOf(response.export_id) === -1 && response.export_id) {
          //   data.push(response.export_id)
          //   dispatch(setPreviouslyExportIds(data))
          // }
        } else {
          dispatch(
            actionCreator(
              identificationActionTypes.unlock_more_results.FAILURE,
              response.message
            )
          );
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(identificationActionTypes.unlock_more_results.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const getAuthIntegrations = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(identificationActionTypes.get_auth_integration.REQUEST)
    );
    fetch(`${GET_AUTH_URL}`, {
      method: "get",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            identificationActionTypes.get_auth_integration.SUCCESS,
            response
          )
        );
      })

      .catch((error) => {
        dispatch(
          actionCreator(identificationActionTypes.get_auth_integration.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
