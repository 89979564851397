import React from "react";
import { PopupModal } from "react-calendly";

const CalendlyEmbed = ({ isOpen, prefill, ...props }) => {
  return (
    <PopupModal
      url="https://calendly.com/tensor-chris/qa"
      prefill={prefill}
      onModalClose={() => props.onModalClose(false)}
      open={isOpen}
      /*
       * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
       * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
       */
      rootElement={document.getElementById("root")}
    />
  );
};
export default CalendlyEmbed;
