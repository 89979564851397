import React, { useState } from "react";
import {
  Row,
  Col,
  TabContent,
  Nav,
  NavItem,
  NavLink as ReactStrapNavLink,
  Card,
  CardBody,
  CardTitle,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import settings from "settings";
import { useTranslation } from "react-i18next";
import FollowersGenderSplit from "./byFollowers/followersGenderSplit";
import FollowersAgeAndGenderSplit from "./byFollowers/followersAgeAndGenderSplit";
import FollowersLocationByCity from "./byFollowers/followersLocationByCity";
import FollowersLocationByCountry from "./byFollowers/followersLocationByCountry";
import FollowersEthnicity from "./byFollowers/followersEthnicity";
import FollowersLanguage from "./byFollowers/followersLanguage";
import FollowersAudienceBrandAffinity from "./byFollowers/followersAudienceBrandAffinity";
import FollowersAudienceInterests from "./byFollowers/followersAudienceInterests";
import FollowersAudienceLookAlikes from "./byFollowers/followersAudienceLookAlikes";
import FollowersNotableFollowers from "./byFollowers/followersNotableFollowers";
import LikesGenderSplit from "./byLikes/likesGenderSplit";
import LikesAgeAndGenderSplit from "./byLikes/likesAgeAndGenderSplit";
import LikesLocationByCity from "./byLikes/likesLocationByCity";
import LikesLocationByCountry from "./byLikes/likesLocationByCountry";
import LikesEthnicity from "./byLikes/likesEthnicity";
import LikesLanguage from "./byLikes/likesLanguage";
import LikesAudienceBrandAffinity from "./byLikes/likesAudienceBrandAffinity";
import LikesAudienceInterests from "./byLikes/likesAudienceInterests";
// import LikesAudienceLookAlikes from './byLikes/likesAudienceLookAlikes';
import LikesNotableUsers from "./byLikes/likesNotableUsers";
import FollowersAudienceReachability from "./byFollowers/followersAudienceReachability";
import LikesAudienceReachability from "./byLikes/likesAudienceReachability";
import { showTooltip } from "../../../actions";
const AudiencesSection = (props) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("0");

  const followersLikeIconClass =
    props.accountOverviewDetails &&
    props.accountOverviewDetails.audience_followers &&
    props.accountOverviewDetails.audience_followers.data &&
    props.accountOverviewDetails.audience_followers.data.credibility_class
      ? props.accountOverviewDetails.audience_followers.data.credibility_class.toLowerCase() ===
          "high" ||
        props.accountOverviewDetails.audience_followers.data.credibility_class.toLowerCase() ===
          "best"
        ? "icon-liked"
        : props.accountOverviewDetails.audience_followers.data.credibility_class.toLowerCase() ===
          "bad"
        ? "icon-unlike"
        : "icon-neutral"
      : "";
  const likesLikeIconClass =
    props.accountOverviewDetails &&
    props.accountOverviewDetails.audience_likers &&
    props.accountOverviewDetails.audience_likers.data &&
    props.accountOverviewDetails.audience_likers.data.credibility_class
      ? props.accountOverviewDetails.audience_likers.data.credibility_class.toLowerCase() ===
          "high" ||
        props.accountOverviewDetails.audience_likers.data.credibility_class.toLowerCase() ===
          "best"
        ? "icon-liked"
        : props.accountOverviewDetails.audience_likers.data.credibility_class.toLowerCase() ===
          "bad"
        ? "icon-unlike"
        : "icon-neutral"
      : "";
  return (
    <div className="section section-au" id="Audience">
      <div className="section-head text-center">
        <h2>{t("reports.audience_details.title")}</h2>
      </div>

      <div className="follower-like-tabs">
        <Nav pills className="d-flex justify-content-center">
          {props.accountOverviewDetails.audience_followers &&
            props.accountOverviewDetails.audience_followers.success && (
              <NavItem>
                <ReactStrapNavLink
                  className={
                    activeTab === "0" ? "active toggle first" : "first"
                  }
                  onClick={() => setActiveTab("0")}
                >
                  {t("reports.audience_details.tabs.followers")}
                </ReactStrapNavLink>
              </NavItem>
            )}
          {props.accountOverviewDetails.audience_likers &&
          props.accountOverviewDetails.audience_likers.success &&
          props.accountOverviewDetails.user_profile &&
          (props.accountOverviewDetails.user_profile.type === "youtube" ||
            props.accountOverviewDetails.user_profile.type ===
              "tiktok") ? null : (
            <NavItem>
              <ReactStrapNavLink
                className={activeTab === "1" ? "active toggle" : ""}
                onClick={() => setActiveTab("1")}
              >
                {t("reports.audience_details.tabs.likes")}
              </ReactStrapNavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="0">
            {props.accountOverviewDetails.user_profile &&
            (props.accountOverviewDetails.user_profile.type === "youtube" ||
              props.accountOverviewDetails.user_profile.type ===
                "tiktok") ? null : (
              <Row className="row-xs">
                <Col sm="6">
                  <Card className="card-content-custom ch-179">
                    <CardBody>
                      <h2 className={followersLikeIconClass}>
                        {props.accountOverviewDetails &&
                          props.accountOverviewDetails.audience_followers &&
                          props.accountOverviewDetails.audience_followers
                            .data &&
                          props.accountOverviewDetails.audience_followers.data
                            .audience_credibility &&
                          (
                            props.accountOverviewDetails.audience_followers.data
                              .audience_credibility * 100
                          ).toFixed(2) + "%"}
                      </h2>
                      <CardTitle>
                        <span className="tooltip-button-wrap">
                          <span className="text-tooltip">
                            {t(
                              "reports.audience_details.followers.credibility"
                            )}
                          </span>
                          <span
                            href="#"
                            id="followersFollowersCredibility"
                            className="tooltip-icon"
                          ></span>
                        </span>
                        {props.tooltip && (
                          <UncontrolledTooltip
                            placement="bottom"
                            className="tooltip-content"
                            target="followersFollowersCredibility"
                          >
                            {showTooltip("followersFollowersCredibility")}
                          </UncontrolledTooltip>
                        )}
                      </CardTitle>
                    </CardBody>
                  </Card>
                  <Card className="card-content-custom ch-179">
                    <CardBody>
                      <h2>
                        {props.accountOverviewDetails &&
                          props.accountOverviewDetails.audience_followers &&
                          props.accountOverviewDetails.audience_followers
                            .data &&
                          props.accountOverviewDetails.audience_followers.data
                            .notable_users_ratio &&
                          (
                            props.accountOverviewDetails.audience_followers.data
                              .notable_users_ratio * 100
                          ).toFixed(2) + "%"}
                      </h2>
                      <CardTitle>
                        <span className="tooltip-button-wrap">
                          <span className="text-tooltip">
                            {t("reports.audience_details.followers.notable")}
                          </span>
                          <span
                            href="#"
                            id="notableUsers"
                            className="tooltip-icon"
                          ></span>
                        </span>
                        {props.tooltip && (
                          <UncontrolledTooltip
                            placement="bottom"
                            className="tooltip-content"
                            target="notableUsers"
                          >
                            {showTooltip("notableUsers")}
                          </UncontrolledTooltip>
                        )}
                      </CardTitle>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="6">
                  <FollowersAudienceReachability {...props} />
                </Col>
              </Row>
            )}

            {(!props.accountOverviewDetails ||
              !props.accountOverviewDetails.audience_followers ||
              !props.accountOverviewDetails.audience_followers.data ||
              !props.accountOverviewDetails.audience_followers.data
                .audience_genders ||
              !props.accountOverviewDetails.audience_followers.data
                .audience_genders.length) &&
            (!props.accountOverviewDetails ||
              !props.accountOverviewDetails.audience_followers ||
              !props.accountOverviewDetails.audience_followers.data ||
              !props.accountOverviewDetails.audience_followers.data
                .audience_genders_per_age ||
              !props.accountOverviewDetails.audience_followers.data
                .audience_genders_per_age.length) ? null : (
              <Row className="row-xs">
                <Col sm="5" md="4">
                  <FollowersGenderSplit {...props} />
                </Col>
                <Col sm="7" md="8">
                  <FollowersAgeAndGenderSplit {...props} />
                </Col>
              </Row>
            )}
            {!props.accountOverviewDetails ||
            !props.accountOverviewDetails.audience_followers ||
            !props.accountOverviewDetails.audience_followers.data ||
            !props.accountOverviewDetails.audience_followers.data
              .audience_geo ||
            !props.accountOverviewDetails.audience_followers.data.audience_geo
              .cities ||
            !props.accountOverviewDetails.audience_followers.data.audience_geo
              .cities.length ? null : (
              <Row>
                <Col>
                  <FollowersLocationByCity {...props} />
                </Col>
              </Row>
            )}
            {(!props.accountOverviewDetails ||
              !props.accountOverviewDetails.audience_followers ||
              !props.accountOverviewDetails.audience_followers.data ||
              !props.accountOverviewDetails.audience_followers.data
                .audience_brand_affinity ||
              !props.accountOverviewDetails.audience_followers.data
                .audience_brand_affinity.length) &&
            (!props.accountOverviewDetails ||
              !props.accountOverviewDetails.audience_followers ||
              !props.accountOverviewDetails.audience_followers.data ||
              !props.accountOverviewDetails.audience_followers.data
                .audience_interests ||
              !props.accountOverviewDetails.audience_followers.data
                .audience_interests.length) ? null : (
              <Row className="row-xs">
                <Col sm="6" className="d-flex align-items-stretch">
                  <FollowersAudienceBrandAffinity {...props} />
                </Col>

                <Col sm="6" className="d-flex align-items-stretch">
                  <FollowersAudienceInterests {...props} />
                </Col>
              </Row>
            )}
            {(!props.accountOverviewDetails ||
              !props.accountOverviewDetails.audience_followers ||
              !props.accountOverviewDetails.audience_followers.data ||
              !props.accountOverviewDetails.audience_followers.data
                .audience_geo ||
              !props.accountOverviewDetails.audience_followers.data.audience_geo
                .countries ||
              !props.accountOverviewDetails.audience_followers.data.audience_geo
                .countries.length) &&
            (!props.accountOverviewDetails ||
              !props.accountOverviewDetails.audience_followers ||
              !props.accountOverviewDetails.audience_followers.data ||
              !props.accountOverviewDetails.audience_followers.data
                .audience_ethnicities ||
              !props.accountOverviewDetails.audience_followers.data
                .audience_ethnicities.length) &&
            (!props.accountOverviewDetails ||
              !props.accountOverviewDetails.audience_followers ||
              !props.accountOverviewDetails.audience_followers.data ||
              !props.accountOverviewDetails.audience_followers.data
                .audience_languages ||
              !props.accountOverviewDetails.audience_followers.data
                .audience_languages.length) ? null : (
              <Row className="row-xs">
                <Col md="4" className="d-flex align-items-stretch">
                  <FollowersLocationByCountry {...props} />
                </Col>

                {!settings.pages.reports.disabled.includes("ethnicity") && (
                  <Col md="4" className="d-flex align-items-stretch">
                    <FollowersEthnicity {...props} />
                  </Col>
                )}

                <Col md="4" className="d-flex align-items-stretch">
                  <FollowersLanguage {...props} />
                </Col>
              </Row>
            )}
            {(!props.accountOverviewDetails ||
              !props.accountOverviewDetails.audience_followers ||
              !props.accountOverviewDetails.audience_followers.data ||
              !props.accountOverviewDetails.audience_followers.data
                .audience_lookalikes ||
              !props.accountOverviewDetails.audience_followers.data
                .audience_lookalikes.length) &&
            (!props.accountOverviewDetails ||
              !props.accountOverviewDetails.audience_followers ||
              !props.accountOverviewDetails.audience_followers.data ||
              !props.accountOverviewDetails.audience_followers.data
                .notable_users ||
              !props.accountOverviewDetails.audience_followers.data
                .notable_users.length) ? null : (
              <Row className="row-xs">
                <Col md="4" className="d-flex align-items-stretch">
                  <FollowersAudienceLookAlikes {...props} />
                </Col>

                <Col md="4" className="d-flex align-items-stretch">
                  <FollowersNotableFollowers {...props} />
                </Col>
              </Row>
            )}
          </TabPane>
          <TabPane tabId="1">
            <Row className="row-xs">
              <Col sm="6">
                <Card className="card-content-custom ch-179">
                  <CardBody>
                    <h2 className={likesLikeIconClass}>
                      {props.accountOverviewDetails &&
                        props.accountOverviewDetails.audience_likers &&
                        props.accountOverviewDetails.audience_likers.data &&
                        props.accountOverviewDetails.audience_likers.data
                          .audience_credibility &&
                        (
                          props.accountOverviewDetails.audience_likers.data
                            .audience_credibility * 100
                        ).toFixed(2) + "%"}
                    </h2>
                    <CardTitle>
                      <span className="tooltip-button-wrap">
                        <span className="text-tooltip">
                          {t("reports.audience_details.likes.credibility")}
                        </span>
                        <span
                          href="#"
                          id="likessFollowersCredibility"
                          className="tooltip-icon"
                        ></span>
                      </span>
                      {props.tooltip && (
                        <UncontrolledTooltip
                          placement="bottom"
                          className="tooltip-content"
                          target="likessFollowersCredibility"
                        >
                          {showTooltip("likesFollowersCredibility")}
                        </UncontrolledTooltip>
                      )}
                    </CardTitle>
                  </CardBody>
                </Card>
                <Card className="card-content-custom ch-179">
                  <CardBody>
                    <h2>
                      {props.accountOverviewDetails &&
                        props.accountOverviewDetails.audience_likers &&
                        props.accountOverviewDetails.audience_likers.data &&
                        props.accountOverviewDetails.audience_likers.data
                          .notable_users_ratio &&
                        (
                          props.accountOverviewDetails.audience_likers.data
                            .notable_users_ratio * 100
                        ).toFixed(2) + "%"}
                    </h2>
                    <CardTitle>
                      {t("reports.audience_details.likes.notable")}
                    </CardTitle>
                  </CardBody>
                </Card>

                {/*
                  <Col sm="12">
                    <Card className="card-content-custom ch-112">
                      <CardBody>
                        <h2>
                          {props.accountOverviewDetails.audience_likers.data
                            ?.likes_not_from_followers &&
                            (
                              props.accountOverviewDetails.audience_likers.data
                                .likes_not_from_followers * 100
                            ).toFixed(2) + "%"}
                        </h2>
                        <CardTitle>Likes not from Followers</CardTitle>
                      </CardBody>
                    </Card>
                            </Col>*/}
              </Col>

              <Col sm="6">
                <LikesAudienceReachability {...props} />
              </Col>
            </Row>
            <Row className="row-xs">
              {(props.accountOverviewDetails.audience_likers?.data
                ?.audience_genders ||
                props.accountOverviewDetails.audience_likers?.data
                  ?.audience_genders.length) && (
                <Col sm="5" md="4">
                  <LikesGenderSplit {...props} />
                </Col>
              )}

              {(props.accountOverviewDetails.audience_likers?.data
                ?.audience_genders_per_age ||
                props.accountOverviewDetails.audience_likers?.data
                  ?.audience_genders_per_age.length) && (
                <Col sm="7" md="8">
                  <LikesAgeAndGenderSplit {...props} />
                </Col>
              )}
            </Row>
            {props.accountOverviewDetails.audience_likers.data?.audience_geo
              ?.cities &&
              props.accountOverviewDetails.audience_likers.data?.audience_geo
                ?.cities.length && (
                <Row>
                  <Col>
                    <LikesLocationByCity {...props} />
                  </Col>
                </Row>
              )}
            <Row className="row-xs">
              {props.accountOverviewDetails.audience_likers.data?.audience_geo
                ?.countries &&
                props.accountOverviewDetails.audience_likers.data?.audience_geo
                  ?.countries.length && (
                  <Col md="4">
                    <LikesLocationByCountry {...props} />
                  </Col>
                )}

              {props.accountOverviewDetails.audience_likers.data
                ?.audience_ethnicities &&
                props.accountOverviewDetails.audience_likers.data
                  ?.audience_ethnicities.length && (
                  <Col md="4">
                    <LikesEthnicity {...props} />
                  </Col>
                )}
              {props.accountOverviewDetails.audience_likers.data
                ?.audience_languages &&
                props.accountOverviewDetails.audience_likers.data
                  ?.audience_languages.length && (
                  <Col md="4">
                    <LikesLanguage {...props} />
                  </Col>
                )}
            </Row>
            {(!props.accountOverviewDetails ||
              !props.accountOverviewDetails.audience_likers ||
              !props.accountOverviewDetails.audience_likers.data ||
              !props.accountOverviewDetails.audience_likers.data
                .audience_brand_affinity ||
              !props.accountOverviewDetails.audience_likers.data
                .audience_brand_affinity.length) &&
            (!props.accountOverviewDetails ||
              !props.accountOverviewDetails.audience_likers ||
              !props.accountOverviewDetails.audience_likers.data ||
              !props.accountOverviewDetails.audience_likers.data
                .audience_interests ||
              !props.accountOverviewDetails.audience_likers.data
                .audience_interests.length) ? null : (
              <Row className="row-xs">
                <Col sm="6">
                  <LikesAudienceBrandAffinity {...props} />
                </Col>

                <Col sm="6">
                  <LikesAudienceInterests {...props} />
                </Col>
              </Row>
            )}
            {!props.accountOverviewDetails ||
            !props.accountOverviewDetails.audience_likers ||
            !props.accountOverviewDetails.audience_likers.data ||
            !props.accountOverviewDetails.audience_likers.data.notable_users ||
            !props.accountOverviewDetails.audience_likers.data.notable_users
              .length ? null : (
              <Row className="row-xs">
                <Col md="4">
                  <LikesNotableUsers {...props} />
                </Col>
              </Row>
            )}
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};
export default AudiencesSection;
