import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.scss";
import {
  Container,
  Row,
  Col,
  TabContent,
  Nav,
  NavItem,
  NavLink as ReactStrapNavLink,
} from "reactstrap";
import ProfileSection from "./profileSection";
import AudiencesSection from "./audiencesSection/audiencesSection";
import PostsSection from "./postSection";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Proj4 from "proj4";
import SplashScreen from "./splashScreen";
import {
  getPostDataById,
  showLoadingAction,
  clearPostAnalysisErrorMsg,
} from "./actions/index";
import { showErrorMessage } from "../../actions/index";
import { store } from "../../store";
import InfulencersSectionSearch from "../common/infulenceReport";

class Reports extends Component {
  constructor(props) {
    super(props);
    window.proj4 = Proj4;
    this.state = {
      activeTab: "0",
      activeMainTab: "0",
      showStackLabels: true,
      modals: {
        likesPopularHastagsModal: false,
        likesPopularMentionsModal: false,
        likesGenderSplitModal: false,
        likesAgeAndGenderSplitModal: false,
        likesLocationByCityModal: false,
        likesLocationByCountryModal: false,
        likesEthnicityModal: false,
        likesLanguageModal: false,
        likesAudienceBrandAffinityModal: false,
        likesAudienceInterestsModal: false,
        likesAudienceLookAlikesModal: false,
        likesNotableUsersModal: false,
      },
    };
  }
  componentDidMount() {
    if (
      window.location.pathname.split("/").pop().trim() &&
      window.location.pathname.split("/").pop().trim() !== "campaigns"
    ) {
      let postId = window.location.pathname.split("/").pop().trim();
      this.props.getPostDataById(postId);
    }

    if (
      document.getElementById("Posts") &&
      window.scrollY < document.getElementById("Posts").offsetTop
    ) {
      this.setState({
        activeMainTab: "0",
      });
    } else {
      this.setState({
        activeMainTab: "2",
      });
    }
    if (window.innerWidth <= 768) {
      this.setState({
        smallSectionLength: 15,
        largeSectionLength: 20,
      });
    } else {
      this.setState({
        smallSectionLength: 30,
        largeSectionLength: 40,
      });
    }
    if (window.innerWidth <= 1024) {
      this.setState({
        showStackLabels: false,
      });
    } else {
      this.setState({
        showStackLabels: true,
      });
    }
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
    let clearFields = true;
    this.props.getPostDataById(null, clearFields);
  }

  handleResize = () => {
    if (window.innerWidth <= 768) {
      this.setState({
        smallSectionLength: 15,
        largeSectionLength: 20,
      });
    } else {
      this.setState({
        smallSectionLength: 30,
        largeSectionLength: 40,
      });
    }
    if (window.innerWidth <= 1024) {
      this.setState({
        showStackLabels: false,
      });
    } else {
      this.setState({
        showStackLabels: true,
      });
    }
  };
  handleScroll = () => {
    if (
      document.getElementById("Posts") &&
      window.scrollY < document.getElementById("Posts").offsetTop
    ) {
      this.setState({
        activeMainTab: "0",
      });
    } else {
      this.setState({
        activeMainTab: "2",
      });
    }
  };

  handleActiveTab = (tab) => {
    this.setState({
      activeMainTab: tab,
    });
  };

  toggleModal = (modalKey) => {
    let modals = this.state.modals;
    modals[modalKey] = !modals[modalKey];
    this.setState({
      modals: modals,
    });
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  generateImgUrl = (type, id) => {
    if (type === "country") {
      return `${process.env.REACT_APP_API_URL}/assets/images/country_flags/${id}`;
    } else if (type === "brand") {
      return `${process.env.REACT_APP_API_URL}/assets/images/brand_logo/${id}`;
    } else if (type === "interest") {
      return `${process.env.REACT_APP_API_URL}/assets/images/interests/${id}`;
    }
  };

  downloadPdf = (ref) => {
    this.props.showLoadingAction("start");
    html2canvas(document.getElementById(ref), {
      removeContainer: true,
      useCORS: true,
      logging: false,
      foreignObjectRendering: ref === "app",
      ignoreElements: (element) => {
        return (
          element.id === "header" ||
          element.id === "search" ||
          element.id === "loader" ||
          element.id === "footer" ||
          (element.className &&
            (element.className.toString().includes("download-icon-button") ||
              element.className.toString().includes("close") ||
              element.className.toString().includes("tooltip-icon")))
        );
      },
    })
      .then((canvas) => {
        let contentDataURL = canvas.toDataURL("image/png");
        contentDataURL = contentDataURL.split(";").pop().split("base64,").pop();
        let canvasWidth = document.getElementById(ref).scrollWidth;
        let canvasHeight = document.getElementById(ref).scrollHeight;
        let pdf = new jsPDF();
        let pageHeight = pdf.internal.pageSize.getHeight();
        let pageWidth = pdf.internal.pageSize.getWidth();
        let ratio = canvasWidth / canvasHeight;
        let imgHeight = pageWidth / ratio;
        let heightLeft = imgHeight;
        let position = 0;
        pdf.addImage(contentDataURL, "PNG", 0, position, pageWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(
            contentDataURL,
            "PNG",
            0,
            position,
            pageWidth,
            imgHeight
          );
          heightLeft -= pageHeight;
        }
        pdf.save("Post_Analysis.pdf");
        this.props.showLoadingAction("stop");
      })
      .catch((error) => {
        console.log(error);
        this.props.showLoadingAction("stop");
      });
  };
  clearErrorMessage = () => {
    this.props.clearPostAnalysisErrorMsg();
  };
  render() {
    return (
      <>
        {this.props.postStatusErrorMessage
          ? showErrorMessage(
              store,
              this.props.postStatusErrorMessage,
              this.clearErrorMessage,
              "none",
              "postStatusErrorMessage"
            )
          : null}
        {!this.props.postAnalysisDetails ? (
          <SplashScreen getPostDataById={getPostDataById} />
        ) : (
          <Container className="main-site-content">
            <Row>
              <Col className="is-search-block search-wrap">
                <InfulencersSectionSearch />
              </Col>
            </Row>
            <div className="pl-2 pr-2">
              <ProfileSection {...this.props} />
              <Row>
                <Col md="12" className="iap-tabber">
                  <Nav pills className="d-flex justify-content-center">
                    <NavItem>
                      <ReactStrapNavLink
                        onClick={() => this.handleActiveTab("2")}
                        className={
                          this.state.activeMainTab === "0" ? "active" : ""
                        }
                        href="#Audience"
                      >
                        Audience
                      </ReactStrapNavLink>
                    </NavItem>
                    <NavItem>
                      <ReactStrapNavLink
                        onClick={() => this.handleActiveTab("1")}
                        className={
                          this.state.activeMainTab === "1" ? "active" : ""
                        }
                        href="#Posts"
                      >
                        Posts
                      </ReactStrapNavLink>
                    </NavItem>
                  </Nav>
                  <TabContent>
                    <AudiencesSection
                      {...this.props}
                      toggleModal={this.toggleModal}
                      state={this.state}
                      downloadPdf={this.downloadPdf}
                      generateImgUrl={this.generateImgUrl}
                      toggle={this.toggleTab}
                    />
                    <PostsSection
                      {...this.props}
                      state={this.state}
                      handleOnSlideChange={this.handleOnSlideChange}
                      slidePrevPage={this.slidePrevPage}
                      slideNextPage={this.slideNextPage}
                    />
                  </TabContent>
                </Col>
              </Row>
            </div>
          </Container>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    postAnalysisDetails: state.postAnalysis.postAnalysisDetails,
    postStatusErrorMessage: state.postAnalysis.postStatusErrorMessage,
  };
};
export default connect(mapStateToProps, {
  getPostDataById,
  showLoadingAction,
  clearPostAnalysisErrorMsg,
})(Reports);
