import { tensorListsActionTypes } from "../constants";

const initialState = {
  loading: false,
  tensorListsError: "",
  tensorLists: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case tensorListsActionTypes.getTensorLists.REQUEST:
      return {
        ...state,
        loading: true,
        tensorLists: [],
      };
    case tensorListsActionTypes.getTensorLists.SUCCESS:
      return {
        ...state,
        loading: false,
        tensorLists: payload,
      };
    case tensorListsActionTypes.getTensorLists.FAILURE:
      return {
        ...state,
        loading: false,
        tensorLists: null,
      };
    case tensorListsActionTypes.createTensorLists.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case tensorListsActionTypes.createTensorLists.SUCCESS:
      return {
        ...state,
        loading: false,
        tensorListsError: null,
      };
    case tensorListsActionTypes.createTensorLists.FAILURE:
      return {
        ...state,
        loading: false,
        tensorListsError: payload,
      };
    default: {
      return state;
    }
  }
};
