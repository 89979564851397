import React from "react";
import {
	Spinner
} from "reactstrap";
import {
	RenderButton
} from "../../components/index.js";

export function UploadFileButton(props) {
	const {
		showFileUploading,
		handleFileUpload
	} = props;

	const fileInputField = React.createRef(null);
	const handleUploadBtnClick = () => {
		fileInputField.current.click();
		document.activeElement.blur();
	};

	return <>
		<div className="upload-widget">
			<RenderButton className="btn btn-primary" style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}} onClick={showFileUploading ? null : handleUploadBtnClick}>
				<span className="export-icon mr-3">
					{showFileUploading ? <Spinner className="spinner" animation="border" variant="primary" style={{
						height: '27px',
						'width': '27px'
					}} /> : <svg width={25} height={30} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
					</svg>}
				</span>
				<span> {showFileUploading ? "Uploading..." : "Upload an email list"}
				</span>
			</RenderButton>
			<input type="file" ref={fileInputField} title="" value="" accept=".csv" onChange={e => {
				const file = e.target.files[0];

				if (!e || !e.target || !e.target.files || !e.target.files.length) {
					return;
				}

				handleFileUpload(file);
			}} hidden={true} />
		</div>
	</>;
}
