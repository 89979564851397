const inf_views_engage_left = [
  { label: "1k", value: 1000 },
  { label: "5k", value: 5000 },
  { label: "10k", value: 10000 },
  { label: "50k", value: 50000 },
  { label: "100k", value: 100000 },
  { label: "500k", value: 500000 }
];
const inf_views_engage_right = [
  { label: "1k", value: 1000 },
  { label: "5k", value: 5000 },
  { label: "10k", value: 10000 },
  { label: "50k", value: 50000 },
  { label: "100k", value: 100000 },
  { label: "500k", value: 500000 },
  { label: "1M+", value: 1000000 }
];

export const views_engage_range_left = inf_views_engage_left;
export const views_engage_range_right = inf_views_engage_right;
