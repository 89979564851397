import React, { useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  InputGroup,
  ModalFooter,
} from "reactstrap";
import { Formik, Form, Field } from "formik";
import { ShareIcon } from "@heroicons/react/20/solid";
import {
  RenderButton,
  RenderTextFieldWithoutAutoComplete,
  SubmitButton,
} from "components";
import { addSharedUser, removeSharedUser } from "../actions";
import { API_URL } from "actions";
import { connect } from "react-redux";
import "../index.scss";

const ShareCampaignModal = ({
  loginUser,
  addSharedUserAction,
  removeSharedUserAction,
  campaign,
  isOpen,
  toggleModal,
  ...props
}) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [isClipboardCopied, setClipboardCopied] = useState(false);

  const addSharedUserHandler = () => {
    addSharedUserAction({
      _id: campaign._id,
      name: campaign.name,
      shareWithEmail: emailAddress,
    });
    setEmailAddress("");
  };

  const copyLinkHandler = () => {
    const textField = document.createElement("textarea");
    textField.innerText = `${API_URL}/campaign/addSharedUser.json?shareToken=${campaign._id}`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    setClipboardCopied(true);

    setTimeout(() => {
      setClipboardCopied(false);
    }, 2000);
  };

  const removeSharedUserHandler = (user_id) => {
    removeSharedUserAction({
      _id: campaign._id,
      shareWithUser_id: user_id,
    });
  };

  const sharedUserCol = (user, isOwner) => (
    <Col key={user._id} className="col-12 mt-2 user">
      <h2>{user.name}</h2>
      <p className="user-email">{user.email}</p>
      {isOwner ? (
        <span className="user-owner">Owner</span>
      ) : (
        <button
          type="button"
          className="close remove-button"
          aria-label="Remove user"
          onClick={() => removeSharedUserHandler(user._id)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      )}
    </Col>
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      wrapClassName="share-campaign-modal"
      contentClassName="share-campaign-content"
      centered
    >
      <ModalHeader>
        <Row className="d-flex align-items-center">
          <span className="export-icon mr-3">
            <ShareIcon width={25} height={25} strokeWidth={5} />
          </span>
          Share "{campaign.name}"
        </Row>
      </ModalHeader>
      <ModalBody className="share-modal">
        <Row>
          <Formik onSubmit={addSharedUserHandler}>
            {() => (
              <Form className="share-campaign-form mt-2 mb-4">
                <InputGroup className="d-flex align-items-center">
                  <Field
                    autoFocus
                    id="share-campaign-field"
                    name="shareEmail"
                    className="col-12"
                    placeholder="Enter email address..."
                    customvalue={emailAddress}
                    onChange={(e) => {
                      setEmailAddress(e.target.value);
                    }}
                    component={RenderTextFieldWithoutAutoComplete}
                  />

                  <SubmitButton
                    color="primary"
                    className="ml-3"
                    disabled={!emailAddress}
                  >
                    Share
                  </SubmitButton>
                </InputGroup>
              </Form>
            )}
          </Formik>
          <div className="shared-users">
            <h1>People with access</h1>
            <Row className="mx-0">
              {sharedUserCol(loginUser, true)}
              {campaign?.sharedWithUser_ids.map((user) =>
                sharedUserCol(user, false)
              )}
            </Row>
          </div>
        </Row>
        {/* <Row className="my-2">
          <h1>General access</h1>
					<Col xs="12">
          <RenderButton
            id="clipboardCopy"
            className="btn btn-outline-primary"
            onClick={copyLinkHandler}
          >
            <LinkIcon width={20} height={20} className="mr-1" />
            Copy link
          </RenderButton>

          <Tooltip
            placement="top"
            className="tooltip-content"
            target="clipboardCopy"
            isOpen={isClipboardCopied}
          >
            {showTooltip("clipboardCopy")}
          </Tooltip>
					</Col>
        </Row> */}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between my-3">
        <RenderButton className="btn btn-primary" onClick={toggleModal}>
          Close
        </RenderButton>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
  };
};

export default connect(mapStateToProps, {
  addSharedUserAction: addSharedUser,
  removeSharedUserAction: removeSharedUser,
})(ShareCampaignModal);
