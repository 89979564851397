import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  RenderRadioButton,
  RenderSingleSelect,
  RenderLabel,
} from "../../components";
import { engagement_rate } from "../../helpers/engagementRateOptions";
import {
  audienceGenderOptions,
  influencerGenderOptions,
} from "../../helpers/genderOptions";
import { Field } from "formik";
import { showTooltip } from "../../actions";

const GenderFilter = (props) => {
  const { t } = useTranslation();
  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2"
      isOpen={props.state.filters.gender}
      toggle={() => props.toggleFilter("gender")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {t("discovery.filters.gender.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-lg-1">
        <Row>
          <Col md="6" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-audience">
                  {t("discovery.audience")}
                </h3>
              </span>
              <span
                id="genderAudience"
                className="tooltip-icon tool-top"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="genderAudience"
              >
                {showTooltip("genderAudience")}
              </UncontrolledTooltip>
            )}
            <ul className="list-inline gender-list">
              {(audienceGenderOptions || []).map((option, index) => (
                <li key={index}>
                  <FormGroup check>
                    <RenderLabel check className="radio-container">
                      {option.text}
                      <Field
                        component={RenderRadioButton}
                        value={option.value}
                        checked={props.state.genderAudience === option.value}
                        onChange={(input) =>
                          props.handleRadioButtonChange(
                            input,
                            "genderAudience",
                            option.text
                          )
                        }
                        name="genderAudience"
                      />
                      <span className="checkmark"></span>
                    </RenderLabel>
                  </FormGroup>
                </li>
              ))}
            </ul>
            {props.state.genderAudience ? (
              <FormGroup className="mini-select ptop15 mw-none">
                <Field
                  component={RenderSingleSelect}
                  name="genderWeight"
                  placeholder={t("discovery.fields.select_weight")}
                  value={props.state.genderWeight}
                  onChange={(input) =>
                    props.handleSingleSelectChange(input, "genderWeight", true)
                  }
                  options={engagement_rate}
                />
              </FormGroup>
            ) : null}
          </Col>

          <Col md="6" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span
                id="genderInfluencer"
                className="tooltip-icon tool-top"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="genderInfluencer"
              >
                {showTooltip("genderInfluencer")}
              </UncontrolledTooltip>
            )}
            <ul className="list-inline gender-list">
              {(influencerGenderOptions || []).map((option, index) => (
                <li key={index}>
                  <FormGroup check>
                    <RenderLabel check className="radio-container">
                      {option.text}
                      <Field
                        component={RenderRadioButton}
                        value={option.value}
                        checked={props.state.genderInfluencer === option.value}
                        onChange={(input) =>
                          props.handleRadioButtonChange(
                            input,
                            "genderInfluencer",
                            option.text
                          )
                        }
                        name="genderInfluencer"
                      />
                      <span className="checkmark"></span>
                    </RenderLabel>
                  </FormGroup>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default GenderFilter;
