import React from "react";
import { Button, Form, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import { clearSuccessMsg } from "./actions/index";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import history from "../../history";
class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe_cust: "",
    };
  }

  handleOkButton = (e) => {
    e.preventDefault();
    this.props.clearSuccessMsg();
    history.push("/");
  };

  componentWillMount() {
    let params = queryString.parse(this.props.location.search);
    if (params.id) {
      this.setState({
        stripe_cust: params.id,
      });
    }
  }
  componentDidMount() {
    document.getElementsByClassName("app")[0].classList.add("auth-wrap");
  }
  componentWillUnmount() {
    document.getElementsByClassName("app")[0].classList.remove("auth-wrap");
  }
  render() {
    return (
      <div>
        {this.state.stripe_cust !== "" ? (
          <Helmet
            script={[
              {
                type: "text/javascript",
                innerHTML:
                  "(function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){\r\n      (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');\r\n\r\n      tap('create', '14567-e9f6cb', { integration: 'stripe' });\r\n      tap('trial', '" +
                  this.state.stripe_cust +
                  "');",
              },
            ]}
          />
        ) : null}

        <div className="pt-40">
          <div className="main-site-content container">
            <div className="user-form-wrap">
              <div className="page-head">
                <h1>Success</h1>
                <h2>Your account has been created!</h2>
                <p className="sub-text">
                  {this.props.successMsg ||
                    "Please verify your account by clicking the link we sent to your email."}
                </p>
              </div>
              <Form>
                <FormGroup className="bottom text-center">
                  <Button
                    onClick={(e) => this.handleOkButton(e)}
                    className="btn btn-success"
                  >
                    Ok
                  </Button>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { clearSuccessMsg })(Success);
