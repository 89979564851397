import us1mImg from "../img/quick_search/us_1m.avif"
import beauty100kImg from "../img/quick_search/beauty_100k.avif"
import crypto10kImg from "../img/quick_search/crypto_10k.avif"
import fitnessEmailImg from "../img/quick_search/fitness_email.avif"
import rappersOnRiseImg from "../img/quick_search/rappers_on_rise.avif"
import techNews100kImg from "../img/quick_search/tech_news_100k.avif"
import verifiedUnder1mImg from "../img/quick_search/verified_under_1m.avif"

const presets = [
	{
		platform: 'instagram',
		title: 'US-based creators with 1M+ followers',
		filters: {
			data: {
				"audience_geo": [],
				"geo": [
					{
						"label": "United States",
						"value": 148838,
						"weight": {
							"label": "> 10%",
							"value": 0.1
						}
					}
				],
				"audience_brand": [],
				"brand": [],
				"audience_brand_category": [],
				"brand_category": [],
				"with_contact": [],
				"audiencesAgeLeft": null,
				"audiencesAgeRight": null,
				"audiencesAgeWeight": {
					"label": "> 10%",
					"value": 0.1
				},
				"influencersAgeLeft": null,
				"influencersAgeRight": null,
				"university": null,
				"followersFrom": {
					"label": "1M",
					"value": 1000000
				},
				"followersTo": null,
				"viewsFrom": null,
				"viewsTo": null,
				"engagementsInfluencersFrom": null,
				"engagementsInfluencersTo": null,
				"engagementRate": null,
				"engagementRateOperator": {
					"label": "Greater than or equal",
					"value": "gte"
				},
				"accountTypeFilters": {
					"Regular": false,
					"Business": false,
					"Creator": false
				},
				"audienceType": null,
				"otherFilters": {
					"is_verified": false,
					"onlyCredibleAccount": false,
					"is_hidden": false,
					"has_audience_data": false,
					"hasContactDetails": false,
					"hasSponseredPosts": false
				},
				"allSelectedFilters": [
					{
						"label": "United States",
						"value": "148838",
						"radioButtonValueOfState": "influencerLocationValues"
					},
					{
						"label": "Followers: 1M-",
						"value": "Followers: 1M-",
						"radioButtonValueOfState": "followersFrom"
					}
				],
				"selectedThreeTableColumns": [],
				"relevance": [],
				"platformValue": {
					"label": "Instagram",
					"value": "instagram",
					"icon": {
						"type": "img",
						"key": null,
						"ref": null,
						"props": {
							"className": "social-icon-img instagram",
							"src": "/static/media/insta_icon.1c2d37a5.svg",
							"alt": "instagram"
						},
						"_owner": null
					}
				},
				"influencerLookalikeValue": ""
			},
			filters: {
				"search": {
					"account_type": [],
					"relevance": {
						"value": "",
						"weight": 0
					},
					"text": "",
					"audience_relevance": {
						"value": ""
					},
					"geo": [
						{
							"id": 148838
						}
					],
					"followers": {
						"left_number": 1000000,
						"right_number": null
					}
				},
				"sort": {
					"field": "followers",
					"direction": "desc"
				}
			}
		},
		image: us1mImg
	},
	{
		platform: 'instagram',
		title: 'Beauty creators with 100K+ followers',
		filters: {
			"data": {
				"audience_geo": [],
				"geo": [],
				"audience_brand": [],
				"brand": [],
				"audience_brand_category": [],
				"brand_category": [],
				"with_contact": [],
				"audiencesAgeLeft": null,
				"audiencesAgeRight": null,
				"audiencesAgeWeight": {
					"label": "> 10%",
					"value": 0.1
				},
				"influencersAgeLeft": null,
				"influencersAgeRight": null,
				"university": null,
				"followersFrom": {
					"label": "100k",
					"value": 100000
				},
				"followersTo": null,
				"viewsFrom": null,
				"viewsTo": null,
				"engagementsInfluencersFrom": null,
				"engagementsInfluencersTo": null,
				"engagementRate": null,
				"engagementRateOperator": {
					"label": "Greater than or equal",
					"value": "gte"
				},
				"accountTypeFilters": {
					"Regular": false,
					"Business": false,
					"Creator": false
				},
				"audienceType": null,
				"otherFilters": {
					"is_verified": false,
					"onlyCredibleAccount": false,
					"is_hidden": false,
					"has_audience_data": false,
					"hasContactDetails": false,
					"hasSponseredPosts": false
				},
				"allSelectedFilters": [
					{
						"label": "Followers: 100k-",
						"value": "Followers: 100k-",
						"radioButtonValueOfState": "followersFrom"
					}
				],
				"selectedThreeTableColumns": [],
				"relevance": [
					{
						"label": "cosmetics",
						"value": "cosmetics"
					}
				],
				"platformValue": {
					"label": "Instagram",
					"value": "instagram",
					"icon": {
						"type": "img",
						"key": null,
						"ref": null,
						"props": {
							"className": "social-icon-img instagram",
							"src": "/static/media/insta_icon.1c2d37a5.svg",
							"alt": "instagram"
						},
						"_owner": null
					}
				},
				"influencerLookalikeValue": ""
			},
			"filters": {
				"search": {
					"account_type": [],
					"relevance": {
						"value": "cosmetics",
						"weight": 0
					},
					"text": "",
					"audience_relevance": {
						"value": ""
					},
					"followers": {
						"left_number": 100000,
						"right_number": null
					}
				},
				"sort": {
					"field": "followers",
					"direction": "desc"
				}
			}
		},
		image: beauty100kImg
	},
	{
		platform: 'tiktok',
		title: 'Fastest growing crypto creators with 10K+ followers',
		filters: {
			"data": {
				"audience_geo": [],
				"geo": [],
				"audience_brand": [],
				"brand": [],
				"audience_brand_category": [],
				"brand_category": [],
				"with_contact": [],
				"audiencesAgeLeft": null,
				"audiencesAgeRight": null,
				"audiencesAgeWeight": {
					"label": "> 10%",
					"value": 0.1
				},
				"influencersAgeLeft": null,
				"influencersAgeRight": null,
				"university": null,
				"followersFrom": {
					"label": "10k",
					"value": 10000
				},
				"followersTo": null,
				"viewsFrom": null,
				"viewsTo": null,
				"engagementsInfluencersFrom": null,
				"engagementsInfluencersTo": null,
				"engagementRate": null,
				"engagementRateOperator": {
					"label": "Greater than or equal",
					"value": "gte"
				},
				"accountTypeFilters": {
					"Regular": false,
					"Business": false,
					"Creator": false
				},
				"audienceType": null,
				"otherFilters": {
					"is_verified": false,
					"onlyCredibleAccount": false,
					"is_hidden": false,
					"has_audience_data": false,
					"hasContactDetails": false,
					"hasSponseredPosts": false
				},
				"allSelectedFilters": [
					{
						"label": "Followers: 10k-",
						"value": "Followers: 10k-",
						"radioButtonValueOfState": "followersFrom"
					},
					{
						"label": "1 Month",
						"value": "i1month",
						"radioButtonValueOfState": "followersGrowth"
					}
				],
				"selectedThreeTableColumns": [],
				"relevance": [
					{
						"label": "crypto",
						"value": "crypto"
					}
				],
				"platformValue": {
					"label": "TikTok",
					"value": "tiktok",
					"icon": {
						"type": "img",
						"key": null,
						"ref": null,
						"props": {
							"className": "social-icon-img tiktok",
							"src": "/static/media/tiktok.bc3a3e58.svg",
							"alt": "tiktok"
						},
						"_owner": null
					}
				},
				"influencerLookalikeValue": ""
			},
			"filters": {
				"search": {
					"account_type": [],
					"relevance": {
						"value": "crypto",
						"weight": 0
					},
					"text": "",
					"audience_relevance": {
						"value": ""
					},
					"followers_growth": {
						"interval": "i1month",
						"value": 1.5
					},
					"followers": {
						"left_number": 10000,
						"right_number": null
					}
				},
				"sort": {
					"field": "followers",
					"direction": "desc"
				}
			}
		},
		image: crypto10kImg
	},
	{
		platform: 'tiktok',
		title: 'Verified accounts under 1M followers',
		filters: {
			"data": {
				"audience_geo": [],
				"geo": [],
				"audience_brand": [],
				"brand": [],
				"audience_brand_category": [],
				"brand_category": [],
				"with_contact": [],
				"audiencesAgeLeft": null,
				"audiencesAgeRight": null,
				"audiencesAgeWeight": {
					"label": "> 10%",
					"value": 0.1
				},
				"influencersAgeLeft": null,
				"influencersAgeRight": null,
				"university": null,
				"followersFrom": null,
				"followersTo": {
					"label": "1M",
					"value": 1000000
				},
				"viewsFrom": null,
				"viewsTo": null,
				"engagementsInfluencersFrom": null,
				"engagementsInfluencersTo": null,
				"engagementRate": null,
				"engagementRateOperator": {
					"label": "Greater than or equal",
					"value": "gte"
				},
				"accountTypeFilters": {
					"Regular": false,
					"Business": false,
					"Creator": false
				},
				"audienceType": null,
				"otherFilters": {
					"is_verified": true,
					"onlyCredibleAccount": false,
					"is_hidden": false,
					"has_audience_data": false,
					"hasContactDetails": false,
					"hasSponseredPosts": false
				},
				"allSelectedFilters": [
					{
						"label": "Followers: -1M",
						"value": "Followers: -1M",
						"radioButtonValueOfState": "followersTo"
					}
				],
				"selectedThreeTableColumns": [],
				"relevance": [],
				"platformValue": {
					"label": "TikTok",
					"value": "tiktok",
					"icon": {
						"type": "img",
						"key": null,
						"ref": null,
						"props": {
							"className": "social-icon-img tiktok",
							"src": "/static/media/tiktok.bc3a3e58.svg",
							"alt": "tiktok"
						},
						"_owner": null
					}
				},
				"influencerLookalikeValue": ""
			},
			"filters": {
				"search": {
					"account_type": [],
					"relevance": {
						"value": "",
						"weight": 0
					},
					"text": "",
					"audience_relevance": {
						"value": ""
					},
					"followers": {
						"left_number": null,
						"right_number": 1000000
					},
					"is_verified": true
				},
				"sort": {
					"field": "followers",
					"direction": "desc"
				}
			}
		},
		image: verifiedUnder1mImg
	},
	{
		platform: 'tiktok',
		title: 'Rappers on the rise',
		filters: {
			"data": {
				"audience_geo": [],
				"geo": [],
				"audience_brand": [],
				"brand": [],
				"audience_brand_category": [],
				"brand_category": [],
				"with_contact": [],
				"audiencesAgeLeft": null,
				"audiencesAgeRight": null,
				"audiencesAgeWeight": {
					"label": "> 10%",
					"value": 0.1
				},
				"influencersAgeLeft": null,
				"influencersAgeRight": null,
				"university": null,
				"followersFrom": {
					"label": "10k",
					"value": 10000
				},
				"followersTo": null,
				"viewsFrom": null,
				"viewsTo": null,
				"engagementsInfluencersFrom": null,
				"engagementsInfluencersTo": null,
				"engagementRate": null,
				"engagementRateOperator": {
					"label": "Greater than or equal",
					"value": "gte"
				},
				"accountTypeFilters": {
					"Regular": false,
					"Business": false,
					"Creator": false
				},
				"audienceType": null,
				"otherFilters": {
					"is_verified": false,
					"onlyCredibleAccount": false,
					"is_hidden": false,
					"has_audience_data": false,
					"hasContactDetails": false,
					"hasSponseredPosts": false
				},
				"allSelectedFilters": [
					{
						"label": "Followers: 10k-",
						"value": "Followers: 10k-",
						"radioButtonValueOfState": "followersFrom"
					},
					{
						"label": "1 Month",
						"value": "i1month",
						"radioButtonValueOfState": "followersGrowth"
					}
				],
				"selectedThreeTableColumns": [],
				"relevance": [
					{
						"label": "rap",
						"value": "rap"
					},
					{
						"label": "hiphop",
						"value": "hiphop"
					}
				],
				"platformValue": {
					"label": "TikTok",
					"value": "tiktok",
					"icon": {
						"type": "img",
						"key": null,
						"ref": null,
						"props": {
							"className": "social-icon-img tiktok",
							"src": "/static/media/tiktok.bc3a3e58.svg",
							"alt": "tiktok"
						},
						"_owner": null
					}
				},
				"influencerLookalikeValue": ""
			},
			"filters": {
				"search": {
					"account_type": [],
					"relevance": {
						"value": "rap hiphop",
						"weight": 0
					},
					"text": "",
					"audience_relevance": {
						"value": ""
					},
					"followers_growth": {
						"interval": "i1month",
						"value": 0.5
					},
					"followers": {
						"left_number": 10000,
						"right_number": null
					}
				},
				"sort": {
					"field": "followers",
					"direction": "desc"
				}
			}
		},
		image: rappersOnRiseImg
	},
	{
		platform: 'youtube',
		title: 'Tech news over 100K subscribers',
		filters: {
			"data": {
				"audience_geo": [],
				"geo": [],
				"audience_brand": [],
				"brand": [],
				"audience_brand_category": [],
				"brand_category": [],
				"with_contact": [],
				"audiencesAgeLeft": null,
				"audiencesAgeRight": null,
				"audiencesAgeWeight": {
					"label": "> 10%",
					"value": 0.1
				},
				"influencersAgeLeft": null,
				"influencersAgeRight": null,
				"university": null,
				"followersFrom": {
					"label": "100k",
					"value": 100000
				},
				"followersTo": null,
				"viewsFrom": null,
				"viewsTo": null,
				"engagementsInfluencersFrom": null,
				"engagementsInfluencersTo": null,
				"engagementRate": null,
				"engagementRateOperator": {
					"label": "Greater than or equal",
					"value": "gte"
				},
				"accountTypeFilters": {
					"Regular": false,
					"Business": false,
					"Creator": false
				},
				"audienceType": null,
				"otherFilters": {
					"is_verified": false,
					"onlyCredibleAccount": false,
					"is_hidden": false,
					"has_audience_data": false,
					"hasContactDetails": false,
					"hasSponseredPosts": false
				},
				"allSelectedFilters": [
					{
						"label": "Followers: 100k-",
						"value": "Followers: 100k-",
						"radioButtonValueOfState": "followersFrom"
					}
				],
				"selectedThreeTableColumns": [],
				"relevance": [
					{
						"label": "tech",
						"value": "tech"
					},
					{
						"label": "news",
						"value": "news"
					}
				],
				"platformValue": {
					"label": "YouTube",
					"value": "youtube",
					"icon": {
						"type": "img",
						"key": null,
						"ref": null,
						"props": {
							"className": "social-icon-img youtube",
							"src": "/static/media/youtube.2f027ba9.svg",
							"alt": "youtube"
						},
						"_owner": null
					}
				},
				"influencerLookalikeValue": ""
			},
			"filters": {
				"search": {
					"account_type": [],
					"relevance": {
						"value": "tech news",
						"weight": 0
					},
					"text": "",
					"audience_relevance": {
						"value": ""
					},
					"followers": {
						"left_number": 100000,
						"right_number": null
					}
				},
				"sort": {
					"field": "followers",
					"direction": "desc"
				}
			}
		},
		image: techNews100kImg
	},
	{
		platform: 'youtube',
		title: 'Fitness trainers with email addresses',
		filters: {
			"data": {
				"audience_geo": [],
				"geo": [],
				"audience_brand": [],
				"brand": [],
				"audience_brand_category": [],
				"brand_category": [],
				"with_contact": [
					{
						"label": "Email",
						"value": "email",
						"weight": {
							"label": "> 10%",
							"value": 0.1
						}
					}
				],
				"audiencesAgeLeft": null,
				"audiencesAgeRight": null,
				"audiencesAgeWeight": {
					"label": "> 10%",
					"value": 0.1
				},
				"influencersAgeLeft": null,
				"influencersAgeRight": null,
				"university": null,
				"followersFrom": null,
				"followersTo": null,
				"viewsFrom": null,
				"viewsTo": null,
				"engagementsInfluencersFrom": null,
				"engagementsInfluencersTo": null,
				"engagementRate": null,
				"engagementRateOperator": {
					"label": "Greater than or equal",
					"value": "gte"
				},
				"accountTypeFilters": {
					"Regular": false,
					"Business": false,
					"Creator": false
				},
				"audienceType": null,
				"otherFilters": {
					"is_verified": false,
					"onlyCredibleAccount": false,
					"is_hidden": false,
					"has_audience_data": false,
					"hasContactDetails": false,
					"hasSponseredPosts": false
				},
				"allSelectedFilters": [
					{
						"label": "Email",
						"value": "email",
						"radioButtonValueOfState": "contactsValues"
					}
				],
				"selectedThreeTableColumns": [],
				"relevance": [
					{
						"label": "fitnesstrainer",
						"value": "fitnesstrainer"
					}
				],
				"platformValue": {
					"label": "YouTube",
					"value": "youtube",
					"icon": {
						"type": "img",
						"key": null,
						"ref": null,
						"props": {
							"className": "social-icon-img youtube",
							"src": "/static/media/youtube.2f027ba9.svg",
							"alt": "youtube"
						},
						"_owner": null
					}
				},
				"influencerLookalikeValue": ""
			},
			"filters": {
				"search": {
					"account_type": [],
					"relevance": {
						"value": "fitnesstrainer",
						"weight": 0
					},
					"text": "",
					"audience_relevance": {
						"value": ""
					},
					"with_contact": [
						{
							"type": "email",
							"action": "should"
						}
					]
				},
				"sort": {
					"field": "followers",
					"direction": "desc"
				}
			}
		},
		image: fitnessEmailImg
	},
];

export default presets;
