import React from "react";
import { Col, Row, InputGroup, FormGroup } from "reactstrap";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  SubmitButton,
  RenderTextFieldWithoutAutoComplete,
} from "../../../components";

const PostUrlForm = ({
  submitHandler,
  changeHandler,
  isCentered,
  ...props
}) => {
  const { t } = useTranslation();

  const validateHandler = (values, props) => {
    const errors = {};
    if (!values.postUrl || values.postUrl.trim() === "") {
      errors.postUrl = "A post URL is required";
    } else if (
      !(
        values.postUrl?.toLowerCase()?.includes("instagram.com") ||
        values.postUrl?.toLowerCase()?.includes("tiktok.com") ||
        values.postUrl?.toLowerCase()?.includes("youtube.com") ||
        values.postUrl?.toLowerCase()?.includes("youtu.be")
      )
    ) {
      errors.postUrl =
        "This URL is not a valid post link. Please provide a valid Instagram, TikTok, or YouTube post URL.";
    } else if (values.postUrl?.toLowerCase()?.includes("vm.tiktok.com")) {
      errors.postUrl =
        'TikTok no longer supports the old "vm.tiktok.com" shortlink format.';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{ postUrl: "" }}
      validate={validateHandler}
      onSubmit={(values, { setSubmitting }) => {
        if (!!submitHandler) {
          submitHandler({url: values.postUrl});
          setSubmitting(false);
        }
      }}
      onChange={(values) => {
        if (!!changeHandler) {
          changeHandler(values.postUrl);
        }
      }}
      validateOnBlur={false}
      validateOnChange={!!changeHandler}
    >
      {({ isSubmitting, touched, errors, setFieldError }) => (
        <Form
          className="campaign-manager__post-url-form is-searchform dropdown"
          onChange={(event) => {
            if (Object.keys(errors)?.length) {
              setFieldError(event.target.name);
            }
          }}
        >
          <FormGroup>
            <Row className={isCentered ? "justify-content-center" : ""}>
              <Col xs="12">
                <InputGroup className="justify-content-center">
                  {errors.postUrl && touched.postUrl && (
                    <span className="error mb-2">{errors.postUrl}</span>
                  )}
                  <Field
                    component={RenderTextFieldWithoutAutoComplete}
                    name="postUrl"
                    id="postUrl"
                    className="mr-2 form-control mb-3"
                    // TODO[epic=campaign-manager]: translation
                    placeholder="Enter an Instagram, TikTok, or YouTube post URL..."
                  />
                  {submitHandler && (
                    <SubmitButton
                      className="btn btn-primary campaign-button mx-0 mb-3"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {t("campaign_manager.new_campaign")}
                    </SubmitButton>
                  )}
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

export default PostUrlForm;
