import {
  actionCreator,
  privateHeaders,
  checkHttpStatus,
  checkAuthTokenExpiry,
  showSuccessMessage,
  showErrorMessage,
} from "../../../actions";
import {
  ADD_SHARED_USER_URL,
  GET_CREATOR_LISTS_URL,
  CREATE_CREATOR_LIST_URL,
  UPDATE_CREATOR_LIST_URL,
  DELETE_CREATOR_LIST_URL,
  DELETE_CREATOR_URL,
  REMOVE_SHARED_USER_URL,
  creatorListsActionTypes,
  ADD_SHARED_USER_SUCCESS_MESSAGE,
} from "../constants";
import { signOut } from "../../login/actions";

export const getCreatorLists = (sortKey = "updated") => {
  return (dispatch, getState) => {
    dispatch(actionCreator(creatorListsActionTypes.getCreatorLists.REQUEST));
    fetch(
      `${GET_CREATOR_LISTS_URL}?populate=sharedWithUser_ids&sortKey=${sortKey}`,
      {
        headers: privateHeaders(getState),
      }
    )
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            creatorListsActionTypes.getCreatorLists.SUCCESS,
            response.results
          )
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(creatorListsActionTypes.getCreatorLists.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const addCreatorList = (listData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(creatorListsActionTypes.createCreatorList.REQUEST));
    fetch(`${CREATE_CREATOR_LIST_URL}`, {
      method: "POST",
      headers: privateHeaders(getState),
      body: JSON.stringify(listData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(creatorListsActionTypes.createCreatorList.SUCCESS)
        );
        dispatch(getCreatorLists());
      })
      .catch((error) => {
        dispatch(
          actionCreator(creatorListsActionTypes.createCreatorList.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const updateCreatorList = (listData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(creatorListsActionTypes.createCreatorList.REQUEST));
    fetch(`${UPDATE_CREATOR_LIST_URL}`, {
      method: "PUT",
      headers: privateHeaders(getState),
      body: JSON.stringify(listData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(creatorListsActionTypes.createCreatorList.SUCCESS)
        );
        dispatch(getCreatorLists());
      })
      .catch((error) => {
        dispatch(
          actionCreator(creatorListsActionTypes.createCreatorList.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const updateCreatorListName = (listData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(creatorListsActionTypes.updateCreatorList.REQUEST));
    fetch(`${CREATE_CREATOR_LIST_URL}`, {
      method: "PUT",
      headers: privateHeaders(getState),
      body: JSON.stringify(listData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(creatorListsActionTypes.updateCreatorList.SUCCESS)
        );
        dispatch(getCreatorLists());
      })
      .catch((error) => {
        dispatch(
          actionCreator(creatorListsActionTypes.updateCreatorList.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const deleteCreatorList = (creatorList_id) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(creatorListsActionTypes.deleteCreatorList.REQUEST));

    const url = DELETE_CREATOR_LIST_URL.replace(
      ":creatorList_id",
      creatorList_id
    );

    fetch(url, {
      method: "DELETE",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(creatorListsActionTypes.deleteCreatorList.SUCCESS)
        );
        dispatch(getCreatorLists());
      })
      .catch((error) => {
        dispatch(
          actionCreator(creatorListsActionTypes.deleteCreatorList.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const deleteCreator = (creatorData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(creatorListsActionTypes.deleteCreator.REQUEST));

    fetch(`${DELETE_CREATOR_URL}`, {
      method: "PUT",
      headers: privateHeaders(getState),
      body: JSON.stringify(creatorData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(actionCreator(creatorListsActionTypes.deleteCreator.SUCCESS));
        dispatch(getCreatorLists());
      })
      .catch((error) => {
        dispatch(actionCreator(creatorListsActionTypes.deleteCreator.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const addSharedUser = (userData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(creatorListsActionTypes.addSharedUser.REQUEST));
    fetch(`${ADD_SHARED_USER_URL}`, {
      method: "PUT",
      headers: privateHeaders(getState),
      body: JSON.stringify(userData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.result?.sharedWithUser_ids.length > 0) {
          dispatch(getCreatorLists());
          showSuccessMessage(
            ADD_SHARED_USER_SUCCESS_MESSAGE(userData.shareWithEmail)
          );
        } else {
          showErrorMessage(null, response.message);
        }

        dispatch(actionCreator(creatorListsActionTypes.addSharedUser.SUCCESS));
      })
      .catch((error) => {
        dispatch(actionCreator(creatorListsActionTypes.addSharedUser.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const removeSharedUser = (userData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(creatorListsActionTypes.removeSharedUser.REQUEST));
    fetch(`${REMOVE_SHARED_USER_URL}`, {
      method: "PUT",
      headers: privateHeaders(getState),
      body: JSON.stringify(userData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(creatorListsActionTypes.removeSharedUser.SUCCESS)
        );
        dispatch(getCreatorLists());
      })
      .catch((error) => {
        dispatch(
          actionCreator(creatorListsActionTypes.removeSharedUser.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const clearCreatorListMsg = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(creatorListsActionTypes.createCreatorList.SUCCESS));
  };
};
