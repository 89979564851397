import React from "react";
import { Row, Col } from "reactstrap";
import { RenderButton } from "../../components";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import settings from "settings";

const BillingPortal = ({ handleManageAccountClick, ...props }) => {
  return (
    <Row className="d-flex align-items-center mb-4">
      <Col md="6" className="text-wrapper">
        <h1 className="page-heading">Manage Subscription</h1>
        <p className="sub-text text-grey">
          {settings.versionName} partners with Stripe for simplified billing.
        </p>
      </Col>
      <Col md="6">
        <RenderButton
          id="manageSubscription"
          className="btn btn-primary manage-subscription float-right"
          onClick={() => handleManageAccountClick()}
        >
          <span className="d-flex align-items-center">
            <ArrowTopRightOnSquareIcon
              className="mr-3"
              width={25}
              height={25}
            />
            Stripe
          </span>
        </RenderButton>
      </Col>
    </Row>
  );
};

export default BillingPortal;
