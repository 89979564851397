
import { UserBalanceDataActionTypes } from "../constants";
const initialState = {
  loading: false,
  UserBalanceData: null

}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UserBalanceDataActionTypes.getUserBalanceData.REQUEST:
      return {
        ...state,
        loading: true,
        UserBalanceData: null
      }
    case UserBalanceDataActionTypes.getUserBalanceData.SUCCESS:
      return {
        ...state,
        UserBalanceData: payload.data,
        loading: false,

      }
    case UserBalanceDataActionTypes.getUserBalanceData.FAILURE:
      return {
        ...state,
        UserBalanceData: null,
        loading: false,

      }
    default:
      return state;

  }
}