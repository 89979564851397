import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  RenderButton,
  RenderSingleSelect,
  RenderSearchableSingleSelect,
} from "../../components";
import useLanguage from "hooks/useLanguage";
import { engagement_rate } from "../../helpers/engagementRateOptions";
import { Field } from "formik";
import { showTitle, showTooltip } from "../../actions";

const LanguageFilter = (props) => {
  const { t } = useTranslation();
  const languages = useLanguage();

  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2 second"
      isOpen={props.state.filters.language}
      toggle={() => props.toggleFilter("language")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {t("discovery.filters.language.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-lg">
        <Row>
          <Col sm="6" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-audience">
                  {t("discovery.audience")}
                </h3>
              </span>
              <span
                id="languageAudience"
                className="tooltip-icon tool-top"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="languageAudience"
              >
                {showTooltip("languageAudience")}
              </UncontrolledTooltip>
            )}

            <Field
              component={RenderSearchableSingleSelect}
              name="audienceLanguage"
              placeholder={t("discovery.filters.language.placeholder.audience")}
              value={props.state.audienceLanguage}
              onChange={(input) =>
                props.handleSingleSelectChange(input, "audienceLanguage")
              }
              options={languages}
            />

            {props.state.audienceLanguage &&
            props.state.audienceLanguage.value ? (
              <Row className="selected-tags-bottom left-audience">
                <Col sm="12" className="col active-filters-wrap">
                  <div className="active-filters inside">
                    <ul className="list-inline">
                      {props.state.audienceLanguage &&
                      props.state.audienceLanguage.value ? (
                        <li>
                          <div className="li-audience">
                            <p
                              title={showTitle(
                                `aud_lang_inside_${props.state.audienceLanguage.value}`,
                                props.state.audienceLanguage.label
                              )}
                              id={`inf_loc_inside_${props.state.audienceLanguage.value}`}
                            >
                              {props.state.audienceLanguage.label}
                            </p>
                            <RenderButton
                              close
                              onClick={() =>
                                props.removeSingleSelectFilters(
                                  "audienceLanguage",
                                  "languageWeight"
                                )
                              }
                            />
                          </div>
                          {props.state.audienceLanguage &&
                          props.state.audienceLanguage.value ? (
                            <FormGroup>
                              <Field
                                className="li-weight"
                                component={RenderSingleSelect}
                                name="languageWeight"
                                placeholder={t(
                                  "discovery.fields.select_weight"
                                )}
                                value={props.state.languageWeight}
                                onChange={(input) =>
                                  props.handleSingleSelectChange(
                                    input,
                                    "languageWeight",
                                    true
                                  )
                                }
                                options={engagement_rate}
                              />
                            </FormGroup>
                          ) : null}
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
          <Col sm="6" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span
                id="languageInfluencer"
                className="tooltip-icon tool-top"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="languageInfluencer"
              >
                {showTooltip("languageInfluencer")}
              </UncontrolledTooltip>
            )}
            <Field
              component={RenderSearchableSingleSelect}
              name="influencerLanguage"
              placeholder={t(
                "discovery.filters.language.placeholder.influencer"
              )}
              value={props.state.influencerLanguage}
              onChange={(input) =>
                props.handleSingleSelectChange(input, "influencerLanguage")
              }
              options={languages}
            />
            {props.state.influencerLanguage &&
            props.state.influencerLanguage.value ? (
              <Row className="selected-tags-bottom">
                <Col sm="12" className="col active-filters-wrap">
                  <div className="active-filters inside">
                    <ul className="list-inline">
                      {props.state.influencerLanguage &&
                      props.state.influencerLanguage.value ? (
                        <li>
                          <div className="li-influencer">
                            <p
                              title={showTitle(
                                `inf_lang_inside_${props.state.influencerLanguage.value}`,
                                props.state.influencerLanguage.label
                              )}
                              id={`inf_loc_inside_${props.state.influencerLanguage.value}`}
                            >
                              {props.state.influencerLanguage.label}
                            </p>
                            <RenderButton
                              close
                              onClick={() =>
                                props.removeSingleSelectFilters(
                                  "influencerLanguage",
                                  null
                                )
                              }
                            />
                          </div>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default LanguageFilter;
