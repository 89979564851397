import { createRequestActionTypes, API_URL } from "../../../../actions";

export const CREATE_ONE_CREATOR_TRACKING_URL = `${API_URL}/creatorTracking/createOne`;
export const READ_ALL_CREATOR_TRACKING_URL = `${API_URL}/creatorTracking/readAll`;
export const GLOBAL_CREATOR_SEARCH_URL = `${API_URL}/identification/users/global`;

export const creatorTrackingActionTypes = {
	getTrackedCreators: createRequestActionTypes("GET_TRACKED_CREATORS"),
	createTrackedCreator: createRequestActionTypes("CREATE_TRACKED_CREATOR"),
	getFilteredCreatorOptions: createRequestActionTypes("GLOBAL_CREATOR_SEARCH_URL"),
};
