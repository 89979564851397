import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { RenderButton, RenderMutiSelect } from "../../components";
import { Field } from "formik";
import {
  contactOptions as options,
  defaultOptions,
  instagram,
  youtube,
  tiktok,
} from "../../helpers/contactsOptions";
import { showTitle, showTooltip } from "../../actions";
const ContactsFilter = (props) => {
  const { t } = useTranslation();

  const contactOptions = (platform) => {
    switch (platform) {
      case "youtube":
        return [...defaultOptions, instagram, tiktok, ...options];
      case "tiktok":
        return [...defaultOptions, instagram, youtube, ...options];
      case "instagram":
      default:
        return [...defaultOptions, tiktok, youtube, ...options];
    }
  };

  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2"
      isOpen={props.state.filters.contacts}
      toggle={() => props.toggleFilter("contacts")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {t("discovery.filters.contacts.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-md">
        <Row>
          <Col md="12" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span id="contacts" className="tooltip-icon tool-top"></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="contacts"
              >
                {showTooltip("contacts")}
              </UncontrolledTooltip>
            )}
            <Field
              component={RenderMutiSelect}
              name="contacts"
              options={contactOptions(props.state.platformValue.value)}
              onChange={(values) =>
                props.handleMultipleDropdownChange(values, "contacts")
              }
              placeholder={t(
                "discovery.filters.contacts.placeholder.influencer"
              )}
              value={props.state.contactsValues}
            />
            {props.state.contactsValues && props.state.contactsValues.length ? (
              <Row className="selected-tags-bottom">
                <Col md="12" className="col active-filters-wrap">
                  <div className="active-filters inside">
                    <ul className="list-inline">
                      {(props.state.contactsValues || []).map(
                        (option, index) => (
                          <li key={index}>
                            <div className="li-influencer">
                              <p
                                title={showTitle(
                                  `aud_contacts_inside_${option.value}`,
                                  option.label
                                )}
                                id={`aud_contacts_inside_${option.value}`}
                              >
                                {option.label}
                              </p>
                              <RenderButton
                                close
                                onClick={() =>
                                  props.removeMultipleDropdownFilters(
                                    option,
                                    "contactsValues"
                                  )
                                }
                              />
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default ContactsFilter;
