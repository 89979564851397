import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  RenderRadioButton,
  RenderSingleSelect,
  RenderLabel,
} from "../../components";
import { engagement_rate } from "../../helpers/engagementRateOptions";
import ethnicityOptions from "../../helpers/ethnicityOptions";
import { Field } from "formik";
import { showTooltip } from "../../actions";

const EthnicityFilter = (props) => {
  const { t } = useTranslation();
  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2"
      isOpen={props.state.filters.ethnicity}
      toggle={() => props.toggleFilter("ethnicity")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {t("discovery.filters.ethnicity.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-md">
        <Row>
          <Col md="12" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-audience">
                  {t("discovery.audience")}
                </h3>
              </span>
              <span
                id="ethnicityAudience"
                className="tooltip-icon tool-top"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="ethnicityAudience"
              >
                {showTooltip("ethnicityAudience")}
              </UncontrolledTooltip>
            )}
            <ul className="list-inline gender-list">
              {(ethnicityOptions || []).map((option, index) => (
                <li key={index}>
                  <FormGroup check>
                    <RenderLabel check className="radio-container">
                      {option.text}
                      <Field
                        name="ethnicityAudience"
                        component={RenderRadioButton}
                        value={option.value}
                        checked={props.state.ethnicityAudience === option.value}
                        onChange={(input) =>
                          props.handleRadioButtonChange(
                            input,
                            "ethnicityAudience",
                            option.text
                          )
                        }
                      />
                      <span className="checkmark"></span>
                    </RenderLabel>
                  </FormGroup>
                </li>
              ))}
            </ul>
            {props.state.ethnicityAudience ? (
              <FormGroup className="mini-select ptop15 mw-none">
                <Field
                  component={RenderSingleSelect}
                  name="ethnicityWeight"
                  placeholder={t("discovery.fields.select_weight")}
                  value={props.state.ethnicityWeight}
                  onChange={(input) =>
                    props.handleSingleSelectChange(
                      input,
                      "ethnicityWeight",
                      true
                    )
                  }
                  options={engagement_rate}
                />
              </FormGroup>
            ) : null}
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default EthnicityFilter;
