import { userProfileActionTypes } from "../constants";

const initialState = {
  loading: false,
  profileDetails: null,
  pastExportsReports: [],
  pastExportsPosts: [],
  pastExportsLists: [],
  isSubscriptionCancel: false,
  isSwitchPlan: null,
  isUpdatePaymentCard: false,
  isUpdatePassword: false,
  isErrorMessage: null,
  isSuccessMessage: null,
  isSubscriptionReActivate: false,
  savedListSearchFilters: null,
  stripeMsg: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case userProfileActionTypes.getProfileDetails.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userProfileActionTypes.getProfileDetails.SUCCESS:
      return {
        ...state,
        loading: false,
        profileDetails: payload,
      };
    case userProfileActionTypes.getProfileDetails.FAILURE:
      return {
        ...state,
        loading: false,
        profileDetails: null,
      };
    case userProfileActionTypes.updateProfileDetails.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userProfileActionTypes.updateProfileDetails.SUCCESS:
      return {
        ...state,
        loading: false,
        profileDetails: payload,
      };
    case userProfileActionTypes.updateProfileDetails.FAILURE:
      return {
        ...state,
        loading: false,
        profileDetails: null,
      };
    case userProfileActionTypes.getPastExportsReports.REQUEST:
      return {
        ...state,
        loading: true,
        pastExportsReports: [],
      };
    case userProfileActionTypes.getPastExportsReports.SUCCESS:
      return {
        ...state,
        loading: false,
        pastExportsReports: payload,
      };
    case userProfileActionTypes.getPastExportsReports.FAILURE:
      return {
        ...state,
        loading: false,
        pastExportsReports: null,
      };
    case userProfileActionTypes.getPastExportsPosts.REQUEST:
      return {
        ...state,
        loading: true,
        pastExportsPosts: [],
      };
    case userProfileActionTypes.getPastExportsPosts.SUCCESS:
      return {
        ...state,
        loading: false,
        pastExportsPosts: payload,
      };
    case userProfileActionTypes.getPastExportsPosts.FAILURE:
      return {
        ...state,
        loading: false,
        pastExportsPosts: null,
      };
    case userProfileActionTypes.getPastExportsLists.REQUEST:
      return {
        ...state,
        loading: true,
        pastExportsLists: [],
      };
    case userProfileActionTypes.getPastExportsLists.SUCCESS:
      return {
        ...state,
        loading: false,
        pastExportsLists: payload,
      };
    case userProfileActionTypes.getPastExportsLists.FAILURE:
      return {
        ...state,
        loading: false,
        pastExportsLists: null,
      };
    case userProfileActionTypes.cancelSubscription.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userProfileActionTypes.cancelSubscription.SUCCESS:
      return {
        ...state,
        loading: true,
        isSubscriptionCancel: true,
      };
    case userProfileActionTypes.cancelSubscription.FAILURE:
      return {
        ...state,
        loading: false,
        isSubscriptionCancel: false,
      };
    case userProfileActionTypes.switchPlanConstant.SUCCESS:
      return {
        ...state,
        loading: false,
        isSwitchPlan: payload,
      };
    case userProfileActionTypes.updateCardDetails.REQUEST:
      return {
        ...state,
        loading: true,
        isSuccessMessage: null,
      };
    case userProfileActionTypes.updateCardDetails.SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdatePaymentCard: true,
        isSuccessMessage: "Your Card details has updated.",
      };
    case userProfileActionTypes.updateCardDetails.FAILURE:
      return {
        ...state,
        loading: false,
        isUpdatePaymentCard: false,
        isErrorMessage:
          payload && payload.message
            ? payload.message
            : "Unable to update the card details.",
      };
    case userProfileActionTypes.createSubscription.REQUEST:
      return {
        ...state,
        loading: true,
        isSuccessMessage: null,
      };
    case userProfileActionTypes.createSubscription.SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccessMessage: "Your Card details has added.",
      };
    case userProfileActionTypes.createSubscription.FAILURE:
      return {
        ...state,
        loading: false,
        isErrorMessage:
          payload && payload.message
            ? payload.message
            : "Unable to add the card details.",
      };
    case userProfileActionTypes.updateUserPassword.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userProfileActionTypes.updateUserPassword.SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdatePassword: true,
        isSuccessMessage: "Your password has updated.",
      };
    case userProfileActionTypes.updateUserPassword.FAILURE:
      return {
        ...state,
        loading: false,
        isUpdatePassword: false,
      };
    case userProfileActionTypes.clearErrorMessage.SUCCESS:
      return {
        ...state,
        loading: false,
        isErrorMessage: null,
      };
    case userProfileActionTypes.clearSuccessMessage.SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccessMessage: null,
      };
    case userProfileActionTypes.planReActivate.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userProfileActionTypes.planReActivate.SUCCESS:
      return {
        ...state,
        loading: false,
        isSubscriptionReActivate: true,
      };
    case userProfileActionTypes.planReActivate.FAILURE:
      return {
        ...state,
        loading: false,
        isSubscriptionReActivate: false,
      };
    case userProfileActionTypes.deleteReportId.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userProfileActionTypes.deleteReportId.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userProfileActionTypes.showStripeMsg.SUCCESS:
      return {
        ...state,
        stripeMsg: payload,
      };
    case userProfileActionTypes.clearStripeMsgAction.SUCCESS:
      return {
        ...state,
        stripeMsg: null,
      };

    case userProfileActionTypes.deleteReportId.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case userProfileActionTypes.deletePostId.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userProfileActionTypes.deletePostId.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userProfileActionTypes.deletePostId.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case userProfileActionTypes.deleteListId.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userProfileActionTypes.deleteListId.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userProfileActionTypes.deleteListId.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case userProfileActionTypes.downloadReportPdf.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userProfileActionTypes.downloadReportPdf.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userProfileActionTypes.downloadReportPdf.FAILURE:
      return {
        ...state,
        loading: false,
      };

    case userProfileActionTypes.saveListSearchFilters.SUCCESS:
      return {
        ...state,
        savedListSearchFilters: payload,
      };
    case userProfileActionTypes.clearSavedListFilters.SUCCESS:
      return {
        ...state,
        savedListSearchFilters: null,
      };

    default:
      return state;
  }
};
