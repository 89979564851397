import React, { Component } from "react";
import {
  Row,
  Col,
  InputGroup,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Container,
} from "reactstrap";
import { MagnifyingGlassIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import { Translation, Trans } from "react-i18next";
import { FollowerTag } from "components/tags";
import networkImage from "helpers/networkImage";
import defaultImg from "../../img/user-circle-outline.svg";
import { RenderTextFieldWithoutAutoCompleteGlobal } from "../../components";
import {
  searchAvailableAccountsGlobal,
  getInfluencerProfileDataBySearch,
} from "../reports/actions/index";
import { debounce } from "lodash";
import { Formik, Field, Form } from "formik";
import { connect } from "react-redux";

class InfulencersSectionSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSearch: false,
      selectedUser: null,
      searchQuery: null,
      dropdownOpen: false,
      platformValue: "instagram",
      audienceLanguageValues: [],
      audienceLocationValues: [],
      allSelectedFilters: [],
      audienceBrandsValues: [],
      audienceInterestsValues: [],
      genderAudience: null,
      credibilityAudience: null,
      reachabilityAudience: null,
      audiencesAgeLeft: null,
      audiencesAgeRight: null,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.wrapperRef = React.createRef();

    this.handleSearchAccounts = debounce(
      this.handleSearchAccounts.bind(this),
      300
    );
    this.onInputChange = this.onInputChange.bind(this);
  }

  calculateSearchObj = (username) => {
    let searchObj = {
      username: username,
      platform: this.state.platformValue,
      filter: {
        language: [],
        age_group: [],
      },
    };
    if (this.state.audienceLocationValues.find((loc) => loc.value)) {
      searchObj.filter.geo = this.state.audienceLocationValues.map(
        (loc, index) => loc.value
      );
    }
    if (this.state.audienceLanguageValues.find((loc) => loc.value)) {
      searchObj.filter.language = this.state.audienceLanguageValues.map(
        (loc, index) => loc.value
      );
    }

    if (this.state.audienceBrandsValues.find((loc) => loc.value)) {
      searchObj.filter.brand = this.state.audienceBrandsValues.map(
        (loc, index) => loc.value
      );
    }
    if (this.state.audienceInterestsValues.find((loc) => loc.value)) {
      searchObj.filter.interest = this.state.audienceInterestsValues.map(
        (loc, index) => loc.value
      );
    }
    if (this.state.genderAudience) {
      searchObj.filter.gender = this.state.genderAudience;
    }
    if (this.state.credibilityAudience) {
      searchObj.filter.is_fake = this.state.credibilityAudience;
    }
    if (this.state.reachabilityAudience) {
      searchObj.filter.following_group = this.state.reachabilityAudience.value;
    }
    if (this.state.audiencesAgeLeft || this.state.audiencesAgeRight) {
      const leftValue =
        this.state.audiencesAgeLeft && this.state.audiencesAgeLeft.value
          ? this.state.audiencesAgeLeft.value
          : "0";
      const rightValue =
        this.state.audiencesAgeRight && this.state.audiencesAgeRight.value
          ? this.state.audiencesAgeRight.value
          : "0";
      searchObj.filter.age_group.push(leftValue + "-" + rightValue);
    }
    return searchObj;
  };

  handleSubmit = async (e, newReportTab) => {
    // e.preventdefault()
    if (newReportTab === null) {
      // popup blocked
    }
    if (newReportTab) {
      window.focus();
    }
    const { selectedUser } = this.state;
    let searchObj = {
      username: selectedUser && selectedUser.trim(),
    };

    if (selectedUser && selectedUser.trim().length) {
      this.setState({
        username: selectedUser,
      });
      let newReportTab = window.open('/loading', '_blank');
      searchObj = this.calculateSearchObj(selectedUser);
      await this.props.getInfluencerProfileDataBySearch(
        searchObj,
        null,
        this.props.loginUser && this.props.loginUser.id,
        false,
        newReportTab
      );

      await document.body.classList.remove("image-overlay");

      await document.removeEventListener("mousedown", this.handleClickOutside);

      // await this.setState({
      //   selectedUser: "",
      //   dropdownOpen: false,

      // searchQuery: null,

      //   // searchQuery: null,
      //   // selectedUser: null
      // });
    }
  };

  handleSearchAccounts = (query) => {
    const { searchAvailableAccountsGlobal } = this.props;
    const searchQuery = {
      query: query && query,
      platform: this.state.platformValue,
    };

    searchAvailableAccountsGlobal(searchQuery);
  };

  onInputChange(e) {
    this.handleSearchAccounts(e);
  }

  DropdownItems = (items) => {
    const DropdownItems = items.map((item, index) => (
      <DropdownItem
        key={index}
        className="infulence-dropdown-list-2"
        onClick={(e) =>
          this.setState(
            {
              selectedUser: item.username || item.custom_name || item.user_id,
              platformValue: item.type,
            },
            () => {
              this.handleSubmit(e);
            }
          )
        }
      >
        <h1 className="report-analyze">
          <Trans i18nKey="reports.generate">Generate</Trans>
        </h1>
        <Container>
          <Row>
            <Col md="8">
              <Row className="generate-report-row">
                <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  <div className="account-image">
                    {item.picture ? (
                      <img
                        src={item.picture}
                        alt="profile pic"
                        width="50px"
                        height="50px"
                        className="rounded-circle"
                        onError={(e) => (e.target.src = defaultImg)}
                      />
                    ) : (
                      <UserCircleIcon
                        className="user-circle rounded-circle"
                        fill="#00c2e6b3"
                        width={50}
                        height={50}
                        strokeWidth={1}
                      />
                    )}
                    {networkImage(item.type)}
                  </div>
                </span>
                <div className="d-flex align-items-center">
                  <h4 className="ml-2">{item.fullname || item.username}</h4>
                  {item.is_verified && (
                    <CheckBadgeIcon
                      className="h-5 w-5 ml-2 mb-3"
                      fill="#00c2e6"
                      width={25}
                      height={25}
                    />
                  )}
                </div>
              </Row>
            </Col>
            {item.followers > 0 && (
              <Col md="4" className="text-center my-auto follower-font">
                <FollowerTag followers={item.followers}></FollowerTag>
              </Col>
            )}
          </Row>
        </Container>
      </DropdownItem>
    ));
    return DropdownItems;
  };

  handleEnterKeySpashScreen = (selectedUser) => {
    if (selectedUser && selectedUser.trim().length) {
      let searchObj = {
        username: selectedUser && selectedUser.trim(),
        platform: this.state.platformValue,
      };
      this.props.getInfluencerProfileDataBySearch(
        searchObj,
        null,
        this.props.loginUser && this.props.loginUser.id
      );
    } else {
      let clearFields = true;
      this.props.getInfluencerProfileDataBySearch(null, clearFields);
    }
  };

  componentDidMount() {
    this.props.searchAvailableAccountsGlobal({
      query: null,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      document.body.classList.remove("image-overlay");
      document.removeEventListener("mousedown", this.handleClickOutside);
      const { searchAvailableAccountsGlobal } = this.props;
      const searchQuery = {
        query: null,
      };

      searchAvailableAccountsGlobal(searchQuery);

      this.setState({
        activeSearch: false,
        dropdownOpen: false,
        selectedUser: "",
      });
    }
  }

  render() {
    const { selectedUser, dropdownOpen, activeSearch } = this.state;
    const { availableAccounts } = this.props;
    return (
      <>
        <Formik onSubmit={this.handleSubmit}>
          {() => (
            <div className="fixed-search">
              <Form className="global-search">
                <Row>
                  <Col sm="auto">
                    {!activeSearch && (
                      <div
                        className="action-sorting"
                        onClick={() => {
                          this.setState({
                            activeSearch: true,
                          });
                          document.addEventListener(
                            "mousedown",
                            this.handleClickOutside
                          );
                          document.body.classList.add("image-overlay");
                        }}
                      >
                        <MagnifyingGlassIcon height={20} />
                      </div>
                    )}
                    <div className="row">
                      <div
                        ref={this.wrapperRef}
                        className="col-xl-10 col-md-9 col-sm-12"
                      >
                        {activeSearch && (
                          <InputGroup className="is-search-block search-wrap mb-0">
                            <Dropdown
                              className="preview-accounts"
                              isOpen={
                                dropdownOpen &&
                                availableAccounts &&
                                availableAccounts.length > 0
                              }
                              toggle={() => {
                                if (dropdownOpen) {
                                  // document.body.classList.remove("image-overlay");
                                } else {
                                  document.body.classList.add("image-overlay");
                                }
                                this.setState({
                                  dropdownOpen: !dropdownOpen,
                                });
                              }}
                            >
                              <DropdownToggle tag="div">
                                <Translation>
                                  {(t, { i18n }) => (
                                    <Field
                                      id="globalSearch"
                                      className={
                                        availableAccounts &&
                                        availableAccounts.length > 0 &&
                                        this.state.searchQuery &&
                                        this.state.searchQuery.length > 2
                                          ? "search-radius"
                                          : ""
                                      }
                                      name="userName"
                                      component={
                                        RenderTextFieldWithoutAutoCompleteGlobal
                                      }
                                      placeholder={t(
                                        "reports.global.placeholder"
                                      )}
                                      onChange={(e) =>
                                        this.setState({
                                          selectedUser: e.target.value,
                                        })
                                      }
                                      onKeyUp={(e) => {
                                        if (e.target.value.length > 2) {
                                          document.body.classList.add(
                                            "image-overlay"
                                          );

                                          this.onInputChange(e.target.value);
                                        }
                                        this.setState({
                                          // selectedUser: null,
                                          searchQuery: e.target.value,
                                          dropdownOpen: true,
                                        });
                                      }}
                                      customvalue={selectedUser}
                                    />
                                  )}
                                </Translation>
                              </DropdownToggle>
                              {this.state.searchQuery &&
                                this.state.searchQuery.length > 2 && (
                                  <DropdownMenu className="ddmenu-col preview-accounts-dropdown-report width-md-2 infulence-dropdown-list">
                                    {availableAccounts &&
                                      this.DropdownItems(availableAccounts)}
                                  </DropdownMenu>
                                )}
                            </Dropdown>
                          </InputGroup>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountOverviewDetails: state.reports.accountOverviewDetails,
    cloudTags: state.reports.cloudTags,
    availableAccounts: state.reports.availableAccounts,
    loginUser: state.login.loginUser,
    subscriptionError: state.reports.subscriptionError,
    reportsErrorMessage: state.reports.reportsErrorMessage,
    audiencesFilteredLoactions: (
      state.identification.audiencesFilteredLoactions || []
    ).map((loc, index) => ({ label: loc.title, value: loc.id })),
    pastExportsReports: state.userProfile.pastExportsReports,
  };
};
export default connect(mapStateToProps, {
  searchAvailableAccountsGlobal,
  getInfluencerProfileDataBySearch,
})(InfulencersSectionSearch);
