import React from 'react'
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem, Button, Badge, Progress, Modal, ModalHeader, ModalBody, Table, UncontrolledTooltip } from 'reactstrap';
import { capitalize, formatNumbersToCommaSeperated, showTitle } from '../../../../actions';
import { RenderButton } from '../../../../components';
const LikesLanguage = (props) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>Language</CardTitle>
        <div className="card-content ch-10">
          {props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_languages && props.postAnalysisDetails.audience_likers.data.audience_languages.length ?
            <ListGroup flush className="country-list with-progress">
              {
                props.postAnalysisDetails.audience_likers.data.audience_languages.map((language, index) => {
                  if (index < 3) {
                    return <ListGroupItem key={index}>

                      <div className="d-flex justify-content-between align-items-center">
                        <div className="country-text-wrap">
                          <div id={`likes_${language.name}`} title={showTitle(`likes_${language.name}`, language.name)} className="country-name">{capitalize(language.name)}
                          </div>

                        </div>
                        <Badge className="text-grey" color="">{(language.weight * 100).toFixed(2) + '%'}</Badge>
                      </div>

                      <Progress value={(language.weight * 100).toFixed(2)} className="progress-bar-custom" />

                    </ListGroupItem>
                  }
                  return null;
                })
              }
            </ListGroup>
            : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}
        </div>
        {props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_languages && props.postAnalysisDetails.audience_likers.data.audience_languages.length ?
          <div className="bottom">
            <Button color="link" onClick={() => props.toggleModal("likesLanguageModal")}>View More</Button>
            <Modal id="likesLanguageModalDownload" isOpen={props.state.modals.likesLanguageModal} toggle={() => props.toggleModal("likesLanguageModal")} >
              <ModalHeader toggle={() => props.toggleModal("likesLanguageModal")}>
                <span className="tooltip-button-wrap"><span className="text-tooltip">Language</span>  <span href="#" id="likesLanguageModal" className="tooltip-icon"></span></span>
                <UncontrolledTooltip placement="bottom" className="tooltip-content" target="likesLanguageModal" >
                  Hello world!
            </UncontrolledTooltip>
                <RenderButton onClick={() => props.downloadPdf("likesLanguageModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
              </ModalHeader>
              <ModalBody>
                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th>Language</th>
                      {/* <th className="text-right">Followers</th> */}
                      <th className="text-right">Likes</th>
                      <th className="text-right">Percentage</th>
                    </tr>
                  </thead>
                  <tbody>

                    {props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_languages && props.postAnalysisDetails.audience_likers.data.audience_languages ?
                      props.postAnalysisDetails.audience_likers.data.audience_languages.map((language, index) =>
                        <tr key={index}>
                          <th scope="row"><div className="country-text-wrap">
                            <div className="country-name">{capitalize(language.name || 'Others')}</div>
                          </div></th>
                          {/* <td className="text-right">{formatNumbersToCommaSeperated((language.weight) *  (props.postAnalysisDetails.posts_profile.notable_users ? props.postAnalysisDetails.posts_profile.notable_users.length : 0))}</td> */}
                          <td className="text-right">{formatNumbersToCommaSeperated((language.weight) * props.postAnalysisDetails.posts_profile.likes)}</td>
                          <td className="text-right">{(language.weight * 100).toFixed(2) + '%'}</td>
                        </tr>) :
                      <tr>
                        <td align="center" colSpan="3">No Record Found</td>
                      </tr>
                    }

                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </div>
          : null}
      </CardBody>
    </Card>
  )
}

export default LikesLanguage
