import { useTranslation } from "react-i18next";

const useLanguage = () => {
  const { t } = useTranslation();
  return [
    { label: t("languages.english"), value: "en" },
    { label: t("languages.spanish"), value: "es" },
    { label: t("languages.portuguese"), value: "pt" },
    { label: t("languages.russian"), value: "ru" },
    { label: t("languages.arabic"), value: "ar" },
    { label: t("languages.french"), value: "fr" },
    { label: t("languages.italian"), value: "it" },
    { label: t("languages.german"), value: "de" },
    { label: t("languages.indonesian"), value: "id" },
    { label: t("languages.persian"), value: "fa" },
    { label: t("languages.turkish"), value: "tr" },
    { label: t("languages.japanese"), value: "ja" },
    { label: t("languages.polish"), value: "pl" },
    { label: t("languages.thai"), value: "th" },
    { label: t("languages.chinese"), value: "zh" },
    { label: t("languages.korean"), value: "ko" },
    { label: t("languages.hindi"), value: "hi" },
    { label: t("languages.malay"), value: "ms" },
    { label: "Arabic (Egyptian)", value: "arz" },
    { label: t("languages.dutch"), value: "nl" },
    { label: t("languages.ukrainian"), value: "uk" },
    { label: "Nepali", value: "ne" },
    { label: "Azerbaijani", value: "az" },
    { label: "Panjabi", value: "pa" },
    { label: t("languages.hebrew"), value: "he" },
    { label: t("languages.swedish"), value: "sv" },
    { label: "Gujarati", value: "gu" },
    { label: "Kurdish, Central", value: "ckb" },
    { label: t("languages.vietnamese"), value: "vi" },
    { label: "Kazakh", value: "kk" },
    { label: t("languages.czech"), value: "cs" },
    { label: "Romanian", value: "ro" },
    { label: "Marathi", value: "mr" },
    { label: "Kirghiz", value: "ky" },
    { label: t("languages.hungarian"), value: "hu" },
    { label: t("languages.greek"), value: "el" },
    { label: t("languages.urdu"), value: "ur" },
    { label: t("languages.finnish"), value: "fi" },
    { label: t("languages.norwegian"), value: "no" },
    { label: t("languages.catalan"), value: "ca" },
    { label: t("languages.danish"), value: "da" },
    { label: "Serbian", value: "sr" },
    { label: "Swahili", value: "sw" },
    { label: "Bulgarian", value: "bg" },
    { label: "Croatian", value: "hr" },
    { label: "Malayalam", value: "ml" },
    { label: "Bengali", value: "bn" },
    { label: t("languages.albanian"), value: "sq" },
    { label: t("languages.tagalog"), value: "tl" },
    { label: "Slovak", value: "sk" },
    { label: "Pushto", value: "ps" },
    { label: "Serbo-Croatian", value: "sh" },
    { label: "Slovenian", value: "sl" },
    { label: "Central Khmer", value: "km" },
    { label: "Cebuano", value: "ceb" },
    { label: t("languages.macedonian"), value: "mk" },
    { label: "Tamil", value: "ta" },
    { label: "Armenian", value: "hy" },
    { label: "Chechen", value: "ce" },
    { label: "Kannada", value: "kn" },
    { label: "Lithuanian", value: "lt" },
    { label: "Galician", value: "gl" },
    { label: "Afrikaans", value: "af" },
    { label: "Estonian", value: "et" },
    { label: "Azerbaijani, South", value: "azb" },
    { label: "Sinhala", value: "si" },
    { label: "Latvian", value: "lv" },
    { label: "Panjabi, Western", value: "pnb" },
    { label: "Lao", value: "lo" },
    { label: "Bosnian", value: "bs" },
    { label: "Uzbek", value: "uz" },
    { label: "Georgian", value: "ka" },
    { label: "Mazanderani", value: "mzn" },
    { label: "Amharic", value: "am" },
    { label: "Icelandic", value: "is" },
    { label: "Gaelic (Scots)", value: "gd" },
    { label: "Tatar", value: "tt" },
    { label: "Assamese", value: "as" },
    { label: "Kurdish", value: "ku" },
    { label: "Sakha", value: "sah" },
    { label: "Burmese", value: "my" },
    { label: "Sindhi", value: "sd" },
    { label: "Waray", value: "war" },
    { label: "Mongolian", value: "mn" },
    { label: "Telugu", value: "te" },
    { label: "Tajik", value: "tg" },
    { label: "Interlingue", value: "ie" },
    { label: "Javanese", value: "jv" },
    { label: "Somali", value: "so" },
    { label: "Belarusian", value: "be" },
    { label: "Interlingua", value: "ia" },
    { label: "Albanian (Tosk)", value: "als" },
    { label: "Frisian, Western", value: "fy" },
    { label: "Low German", value: "nds" },
    { label: "Guarani", value: "gn" },
    { label: "Lojban", value: "jbo" },
    { label: "Mari, Eastern", value: "mhr" },
    { label: "Uighur", value: "ug" },
    { label: "Bashkir", value: "ba" },
    { label: "Neapolitan", value: "nap" },
    { label: "Malagasy", value: "mg" },
    { label: "Oriya", value: "or" },
    { label: "Sundanese", value: "su" },
    { label: "Minangkabau", value: "min" },
    { label: "Iloko", value: "ilo" },
    { label: "Tibetan", value: "bo" },
    { label: "Ido", value: "io" },
    { label: "Turkmen", value: "tk" },
    { label: "Haitian", value: "ht" },
    { label: "Irish", value: "ga" },
    { label: "Cornish", value: "kw" },
    { label: "Pampanga", value: "pam" },
    { label: "Bihari", value: "bh" },
    { label: "Lezghian", value: "lez" },
    { label: "Piedmontese", value: "pms" },
    { label: "Asturian", value: "ast" },
    { label: "Yue Chinese", value: "yue" },
    { label: "Yiddish", value: "yi" },
    { label: "Xhosa", value: "xh" },
    { label: "Sorbian, Upper", value: "hsb" },
    { label: "Scots", value: "sco" },
    { label: "Luri, Northern", value: "lrc" },
    { label: "Zulu", value: "zu" },
    { label: "Bavarian", value: "bar" },
    { label: "Newari", value: "new" },
    { label: "Chuvash", value: "cv" },
    { label: "Maithili", value: "mai" },
    { label: "Mingrelian", value: "xmf" },
    { label: "Kinyarwanda", value: "rw" },
    { label: "Chavacano", value: "cbk" },
    { label: "Sanskrit", value: "sa" },
    { label: "Manx", value: "gv" },
    { label: "Divehi", value: "dv" },
    { label: "Karachay-Balkar", value: "krc" },
    { label: "Wu Chinese", value: "wuu" },
    { label: "Emiliano-Romagnolo", value: "eml" },
    { label: "Bishnupriya Manipuri", value: "bpy" },
    { label: "Raeto-Romance", value: "rm" },
    { label: "Bicolano, Central", value: "bcl" },
    { label: "Lombard", value: "lmo" },
    { label: "Sardinian", value: "sc" },
    { label: "Sicilian", value: "scn" },
    { label: "Mari, Western", value: "mrj" },
    { label: "Komi", value: "kv" },
    { label: "Ossetian", value: "os" },
    { label: "Limburgish", value: "li" },
    { label: "Yoruba", value: "yo" },
    { label: "Kalmyk", value: "xal" },
    { label: "Erzya", value: "myv" },
    { label: "Veps", value: "vep" },
    { label: "Dimli", value: "diq" },
    { label: "Tuvinian", value: "tyv" },
    { label: "Konkani, Goan", value: "gom" },
    { label: "Venetian", value: "vec" },
    { label: "Buriat, Russia", value: "bxr" },
    { label: "Mirandese", value: "mwl" },
    { label: "Nahuatl languages", value: "nah" },
    { label: "West Flemish", value: "vls" },
    { label: "Dotyali", value: "dty" },
    { label: "Sorbian, Lower", value: "dsb" },
    { label: "Pfaelzisch", value: "pfl" },
    { label: "Avaric", value: "av" },
    { label: "Corsican", value: "co" },
    { label: "Frisian, Northern", value: "frr" },
    { label: "Rusyn", value: "rue" },
  ];
};

export default useLanguage;
