import React from "react";
import { HeartIcon, UsersIcon, UserGroupIcon } from "@heroicons/react/20/solid";
import { formatNumbersToUnits } from "../actions";
import useDarkMode from "use-dark-mode";
import { Badge } from "reactstrap";

export const FollowerTag = (props) => {
  const darkMode = useDarkMode(false);

  return (
    <Badge className="badge-soft-neutral">
      {formatNumbersToUnits(props.followers)}
      <UsersIcon
        className="h-5 w-5 ml-2 mb-1"
        fill={darkMode.value ? "#75d4fe" : "#1978a2"}
        width={14}
        height={14}
      />
    </Badge>
  );
};

export const EngagementTag = (props) => {
  const darkMode = useDarkMode(false);

  return (
    <Badge className="badge-soft-danger">
      {formatNumbersToUnits(props.engagements)}
      <HeartIcon
        className="h-5 w-5 ml-2 mb-1"
        fill={darkMode.value ? "#ef7f93" : "#932338"}
        width={14}
        height={14}
      />
    </Badge>
  );
};

export const EngagementRateTag = (props) => {
  return (
    <span className="ml-3">
      <Badge className="px-3 py-2" color="light">
        {Math.round(props.engagement_rate * 100 * 100) / 100 + "%"}
      </Badge>
    </span>
  );
};

export const SharedTag = () => {
  const darkMode = useDarkMode(false);

  return (
    <Badge className="badge-soft-neutral shared-tag ml-3" title="Shared">
      <UserGroupIcon
        className="h-5 w-5"
        fill={darkMode.value ? "#75d4fe" : "#1978a2"}
        width={16}
        height={16}
      />
    </Badge>
  );
};

export const PostsTag = (props) => {
  return <Badge className="badge-soft-warning">{props.posts} Posts</Badge>;
};
