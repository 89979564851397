import React from "react";
import { RenderButton } from "../../components";
import { useTranslation } from "react-i18next";
import {
  audienceGenderOptions,
  influencerGenderOptions,
} from "../../helpers/genderOptions";
import { growthIntervals } from "../../helpers/growthOptions";
import ethnicityOptions from "../../helpers/ethnicityOptions";
import otherFiltersOptions from "../../helpers/otherFiltersOptions";
import { showTitle } from "../../actions";
import audienceTypeFilterOptions from "../../helpers/audienceTypeFilterOptions";
import lastPostFilterOption from "../../helpers/lastPostFilterOption";
const CommonSelectedFilters = (props) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Relevance start */}
      {props.state.showRelevance &&
      props.state.relevance &&
      props.state.relevance.length
        ? props.state.relevance.map((item, index) => (
            <li key={index} className="active-icon-influencer">
              <span
                id={`relevance_filter_${item.value}`}
                title={showTitle(`relevance_filter_${item.value}`, item.value)}
                className="d-block"
                // onClick={() => props.toggleFilter('location')}
              >
                {`Keyword: ${item.value.trim()}`}
              </span>
              <RenderButton close onClick={() => props.clearRelevance(item)} />
            </li>
          ))
        : null}
      {/* location filter start */}
      {/* audience */}
      {(props.state.audienceLocationValues || []).map((option, index) => (
        <li
          className="active-icon-audience"
          key={index}
          title={showTitle(
            `aud_loc_${option.value}`,
            `${option.label} ${
              option.weight && option.weight.value && option.weight.label
            }`
          )}
        >
          <span
            id={`aud_loc_${option.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("location")}
          >
            {`${option.label} ${
              option.weight && option.weight.value && option.weight.label
            }`}
          </span>

          <RenderButton
            close
            onClick={() =>
              props.removeMultipleDropdownFilters(
                option,
                "audienceLocationValues"
              )
            }
          />
        </li>
      ))}
      {/* influencer */}
      {(props.state.influencerLocationValues || []).map((option, index) => (
        <li
          className="active-icon-influencer"
          key={index}
          title={showTitle(`inf_loc_${option.value}`, option.label)}
        >
          <span
            id={`inf_loc_${option.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("location")}
          >
            {option.label}
          </span>

          <RenderButton
            close
            onClick={() =>
              props.removeMultipleDropdownFilters(
                option,
                "influencerLocationValues"
              )
            }
          />
        </li>
      ))}
      {/* location filters end */}
      {/** University filter start */}
      {props.state.universityValue && props.state.universityValue.value && (
        <li
          className="active-icon-audience"
          title={showTitle(
            `aud_uni_${props.state.universityValue.value}`,
            props.state.universityValue.label
          )}
        >
          <span
            id={`aud_uni_${props.state.universityValue.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("university")}
          >
            {props.state.universityValue.label}
          </span>
          <RenderButton
            close
            onClick={() => props.removeSingleSelectFilters("universityValue")}
          />
        </li>
      )}
      {/** University filter end */}
      {/* language filters start */}
      {/* audience */}
      {props.state.audienceLanguage && props.state.audienceLanguage.value ? (
        <li
          className="active-icon-audience"
          title={showTitle(
            `aud_lang_${props.state.audienceLanguage.value}`,
            `${props.state.audienceLanguage.label} ${
              props.state.languageWeight && props.state.languageWeight.value
                ? props.state.languageWeight.label
                : ""
            }`
          )}
        >
          <span
            id={`aud_lang_${props.state.audienceLanguage.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("language")}
          >
            {" "}
            {`${
              props.state.audienceLanguage.label.props
                ? t(props.state.audienceLanguage.label.props.i18nKey)
                : props.state.audienceLanguage.label
            } ${
              props.state.languageWeight && props.state.languageWeight.value
                ? props.state.languageWeight.label
                : ""
            }`}
          </span>
          <RenderButton
            close
            onClick={() =>
              props.removeSingleSelectFilters(
                "audienceLanguage",
                "languageWeight"
              )
            }
          />
        </li>
      ) : null}
      {/* influencer */}
      {props.state.influencerLanguage &&
      props.state.influencerLanguage.value ? (
        <li
          className="active-icon-influencer"
          title={showTitle(
            `inf_lang_${props.state.influencerLanguage.value}`,
            props.state.influencerLanguage.label
          )}
        >
          <span
            id={`inf_lang_${props.state.influencerLanguage.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("language")}
          >
            {props.state.influencerLanguage.label}
          </span>

          <RenderButton
            close
            onClick={() =>
              props.removeSingleSelectFilters("influencerLanguage", null)
            }
          />
        </li>
      ) : null}
      {/* language filters end */}
      {/* brands filters start */}
      {/* audience */}
      {(props.state.audienceBrandsValues || []).map((option, index) => (
        <li
          className="active-icon-audience"
          key={index}
          title={showTitle(
            `aud_brands_${option.value}`,
            `${option.label} ${
              option.weight && option.weight.value && option.weight.label
            }`
          )}
        >
          <span
            id={`aud_brands_${option.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("brands")}
          >
            {`${option.label} ${
              option.weight && option.weight.value && option.weight.label
            }`}
          </span>
          <RenderButton
            close
            onClick={() =>
              props.removeMultipleDropdownFilters(
                option,
                "audienceBrandsValues",
                "brandsWeight"
              )
            }
          />
        </li>
      ))}
      {/* influencer */}
      {(props.state.influencerBrandsValues || []).map((option, index) => (
        <li
          className="active-icon-influencer"
          key={index}
          title={showTitle(`inf_brands_${option.value}`, option.label)}
        >
          <span
            id={`inf_brands_${option.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("brands")}
          >
            {`${option.label}`}
          </span>
          <RenderButton
            close
            onClick={() =>
              props.removeMultipleDropdownFilters(
                option,
                "influencerBrandsValues"
              )
            }
          />
        </li>
      ))}
      {/* brands filter end */}
      {(props.state.influencerPartnershipValues || []).map((option, index) => (
        <li
          className="active-icon-influencer"
          key={index}
          title={showTitle(`inf_partnership_${option.value}`, option.label)}
        >
          <span
            id={`inf_partnership_${option.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("partnership")}
          >
            {`Partnership: ${option.label}`}
          </span>
          <RenderButton
            close
            onClick={() =>
              props.removeMultipleDropdownFilters(
                option,
                "influencerPartnershipValues"
              )
            }
          />
        </li>
      ))}
      {/* interests filters start */}
      {/* audience */}
      {(props.state.audienceInterestsValues || []).map((option, index) => (
        <li
          className="active-icon-audience"
          key={index}
          title={showTitle(
            `aud_interests_${option.value}`,
            `${option.label} ${
              option.weight && option.weight.value && option.weight.label
            }`
          )}
        >
          <span
            id={`aud_interests_${option.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("interests")}
          >
            {`${option.label} ${
              option.weight && option.weight.value && option.weight.label
            }`}
          </span>

          <RenderButton
            close
            onClick={() =>
              props.removeMultipleDropdownFilters(
                option,
                "audienceInterestsValues"
              )
            }
          />
        </li>
      ))}
      {/* influencer */}
      {(props.state.influencerInterestsValues || []).map((option, index) => (
        <li
          className="active-icon-influencer"
          key={index}
          title={showTitle(`inf_interests_${option.value}`, option.label)}
        >
          <span
            id={`inf_interests_${option.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("interests")}
          >
            {option.label}
          </span>
          <RenderButton
            close
            onClick={() =>
              props.removeMultipleDropdownFilters(
                option,
                "influencerInterestsValues"
              )
            }
          />
        </li>
      ))}
      {/* interests filters start */}
      {/* gender filters start */}
      {/* audience */}
      {props.state.genderAudience ? (
        <li
          className="active-icon-audience"
          title={showTitle(
            `aud_gender_${props.state.genderAudience.value}`,
            `${
              audienceGenderOptions.find(
                (option, index) => option.value === props.state.genderAudience
              ).text
            } ${
              props.state.genderWeight && props.state.genderWeight.value
                ? props.state.genderWeight.label
                : null
            }`
          )}
        >
          <span
            id={`aud_gender_${props.state.genderAudience.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("gender")}
          >
            {
              audienceGenderOptions.find(
                (option, index) => option.value === props.state.genderAudience
              ).text
            }
            {` ${
              props.state.genderWeight && props.state.genderWeight.value
                ? props.state.genderWeight.label
                : null
            }`}
          </span>

          <RenderButton
            close
            onClick={() =>
              props.removeRadioFilters("genderAudience", "genderWeight")
            }
          />
        </li>
      ) : null}
      {/* influencer */}
      {props.state.genderInfluencer ? (
        <li
          className="active-icon-influencer"
          title={showTitle(
            `inf_gender_${props.state.genderInfluencer.value}`,
            influencerGenderOptions.find(
              (option, index) => option.value === props.state.genderInfluencer
            ).text
          )}
        >
          <span
            id={`inf_gender_${props.state.genderInfluencer.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("gender")}
          >
            {
              influencerGenderOptions.find(
                (option, index) => option.value === props.state.genderInfluencer
              ).text
            }
          </span>

          <RenderButton
            close
            onClick={() => props.removeRadioFilters("genderInfluencer", null)}
          />
        </li>
      ) : null}
      {/* gender filters end */}
      {/* growth filters start */}
      {props.state.followersGrowth ? (
        <li
          className="active-icon-influencer"
          title={showTitle(
            `follower_growth_${props.state.followersGrowth.value}`,
            `${
              growthIntervals.find(
                (option, index) =>
                  option.value === props.state.followersGrowth.value
              ).label
            } ${
              props.state.followersGrowthWeight &&
              props.state.followersGrowthWeight.value
                ? props.state.followersGrowthWeight.label
                : null
            }`
          )}
        >
          <span
            id={`follower_growth_${props.state.followersGrowth.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("growth")}
          >
            {`${
              props.state.platformValue.value === "youtube"
                ? "Subscribers"
                : "Followers"
            } Growth  ${
              props.state.followersGrowthWeight &&
              props.state.followersGrowthWeight.value
                ? props.state.followersGrowthWeight.label
                : null
            }`}
          </span>

          <RenderButton
            close
            onClick={() =>
              props.removeSingleSelectFilters(
                "followersGrowth",
                "followersGrowthWeight"
              )
            }
          />
        </li>
      ) : null}
      {props.state.likesGrowth ? (
        <li
          className="active-icon-influencer"
          title={showTitle(
            `likes_growth_${props.state.likesGrowth.value}`,
            `${
              growthIntervals.find(
                (option, index) =>
                  option.value === props.state.likesGrowth.value
              ).label
            } ${
              props.state.likesGrowthWeight &&
              props.state.likesGrowthWeight.value
                ? props.state.likesGrowthWeight.label
                : null
            }`
          )}
        >
          <span
            id={`likes_growth_${props.state.likesGrowth.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("growth")}
          >
            {`Total Likes Growth  ${
              props.state.likesGrowthWeight &&
              props.state.likesGrowthWeight.value
                ? props.state.likesGrowthWeight.label
                : null
            }`}
          </span>

          <RenderButton
            close
            onClick={() =>
              props.removeSingleSelectFilters(
                "likesGrowth",
                "likesGrowthWeight"
              )
            }
          />
        </li>
      ) : null}
      {props.state.viewsGrowth ? (
        <li
          className="active-icon-influencer"
          title={showTitle(
            `views_growth_${props.state.viewsGrowth.value}`,
            `${
              growthIntervals.find(
                (option, index) =>
                  option.value === props.state.viewsGrowth.value
              ).label
            } ${
              props.state.viewsGrowthWeight &&
              props.state.viewsGrowthWeight.value
                ? props.state.viewsGrowthWeight.label
                : null
            }`
          )}
        >
          <span
            id={`views_growth_${props.state.viewsGrowth.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("growth")}
          >
            {`Total Views Growth  ${
              props.state.viewsGrowthWeight &&
              props.state.viewsGrowthWeight.value
                ? props.state.viewsGrowthWeight.label
                : null
            }`}
          </span>

          <RenderButton
            close
            onClick={() =>
              props.removeSingleSelectFilters(
                "viewsGrowth",
                "viewsGrowthWeight"
              )
            }
          />
        </li>
      ) : null}
      {/* growth filters end */}
      {/* ethnicity filters start */}
      {/* audience */}
      {props.state.ethnicityAudience ? (
        <li
          className="active-icon-audience"
          title={showTitle(
            `aud_ethnicity_${props.state.ethnicityAudience.value}`,
            `${
              ethnicityOptions.find(
                (option, index) =>
                  option.value === props.state.ethnicityAudience
              ).text
            } ${
              props.state.ethnicityWeight && props.state.ethnicityWeight.value
                ? props.state.ethnicityWeight.label
                : null
            }`
          )}
        >
          <span
            id={`aud_ethnicity_${props.state.ethnicityAudience.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("ethnicity")}
          >
            {`${
              ethnicityOptions.find(
                (option, index) =>
                  option.value === props.state.ethnicityAudience
              ).text
            } ${
              props.state.ethnicityWeight && props.state.ethnicityWeight.value
                ? props.state.ethnicityWeight.label
                : null
            }`}
          </span>

          <RenderButton
            close
            onClick={() =>
              props.removeRadioFilters("ethnicityAudience", "ethnicityWeight")
            }
          />
        </li>
      ) : null}
      {/* ethnicity filters end */}
      {/* Age filters start */}
      {/* audience */}
      {(props.state.audiencesAgeLeft && props.state.audiencesAgeLeft.value) ||
      (props.state.audiencesAgeRight && props.state.audiencesAgeRight.value) ? (
        <li className="active-icon-audience">
          <span className="d-block" onClick={() => props.toggleFilter("age")}>
            {`Age: ${
              (props.state.audiencesAgeLeft &&
                props.state.audiencesAgeLeft.label) ||
              ""
            }-${
              (props.state.audiencesAgeRight &&
                props.state.audiencesAgeRight.label) ||
              ""
            } ${
              props.state.audiencesAgeWeight &&
              props.state.audiencesAgeWeight.value
                ? props.state.audiencesAgeWeight.label
                : ""
            }`}
          </span>
          <RenderButton
            close
            onClick={() =>
              props.removeSingleSelectFilters(
                "audiencesAgeLeft",
                "audiencesAgeWeight",
                () =>
                  props.removeSingleSelectFilters(
                    "audiencesAgeRight",
                    "audiencesAgeWeight"
                  )
              )
            }
          />
        </li>
      ) : null}
      {/* influencer*/}
      {(props.state.influencersAgeLeft &&
        props.state.influencersAgeLeft.value) ||
      (props.state.influencersAgeRight &&
        props.state.influencersAgeRight.value) ? (
        <li className="active-icon-influencer">
          <span className="d-block" onClick={() => props.toggleFilter("age")}>
            {`Age: ${
              (props.state.influencersAgeLeft &&
                props.state.influencersAgeLeft.label) ||
              ""
            }-${
              (props.state.influencersAgeRight &&
                props.state.influencersAgeRight.label) ||
              ""
            } ${
              props.state.influencersAgeWeight &&
              props.state.influencersAgeWeight.value
                ? props.state.influencersAgeWeight.label
                : ""
            }`}
          </span>
          <RenderButton
            close
            onClick={() =>
              props.removeSingleSelectFilters(
                "influencersAgeLeft",
                "influencersAgeWeight",
                () =>
                  props.removeSingleSelectFilters(
                    "influencersAgeRight",
                    "influencersAgeWeight"
                  )
              )
            }
          />
        </li>
      ) : null}
      {/* Age filters end */}
      {/* Bio filter start */}
      {props.state.bio && props.state.bio.trim().length ? (
        <li
          title={
            document.getElementById("bio_filter") &&
            document.getElementById("bio_filter").offsetWidth <
              document.getElementById("bio_filter").scrollWidth
              ? `Bio: ${props.state.bio.trim()}`
              : null
          }
          className="active-icon-influencer"
        >
          <span
            id={"bio_filter"}
            className="d-block"
            onClick={() => props.toggleFilter("bio")}
          >
            {`Bio: ${props.state.bio.trim()}`}
          </span>

          <RenderButton close onClick={() => props.clearBioValue()} />
        </li>
      ) : null}
      {/* Bio filter end*/}

      {/* Keyword filter start */}
      {props.state.keywords && props.state.keywords.trim().length ? (
        <li className="active-icon-influencer">
          <span
            id={`keywords_filter_${props.state.keywords.trim()}`}
            title={showTitle(
              `keywords_filter_${props.state.keywords.trim()}`,
              props.state.keywords.trim()
            )}
            className="d-block"
          >
            {`Keyword: ${props.state.keywords.trim()}`}
          </span>

          <RenderButton close onClick={() => props.clearKeywordsValue()} />
        </li>
      ) : null}
      {/* Keyword filter end*/}

      {/* Username filter start */}
      {props.state.username && props.state.username.trim().length ? (
        <li className="active-icon-influencer">
          <span
            id={`username_filter_${props.state.username.trim()}`}
            title={showTitle(
              `username_filter_${props.state.username.trim()}`,
              props.state.username.trim()
            )}
            className="d-block"
          >
            {`Username: ${props.state.username.trim()}`}
          </span>

          <RenderButton close onClick={() => props.clearUsernameValue()} />
        </li>
      ) : null}
      {/* Username filter end*/}

      {/* Email Match filter start */}
      {props.state.emailMatch_id && props.state.emailMatch_id.trim()?.length ? (
        <li
          title={
            document.getElementById("email_match_filter") &&
            document.getElementById("email_match_filter").offsetWidth <
              document.getElementById("email_match_filter").scrollWidth
              ? `Bio: ${props.state.bio.trim()}`
              : null
          }
          className="active-icon-influencer"
        >
          <span
            id={"email_match_filter"}
            className="d-block"
            onClick={() => props.toggleFilter("emailMatch")}
          >
            {`Email Match Results`}
          </span>

          <RenderButton close onClick={() => props.clearEmailMatch_id()} />
        </li>
      ) : null}
      {/* Email Match filter end*/}

      {/* Creator List filter start */}
      {props.state.creatorList_id &&
      props.state.creatorList_id.trim()?.length ? (
        <li
          title={
            document.getElementById("creator_list_filter") &&
            document.getElementById("creator_list_filter").offsetWidth <
              document.getElementById("creator_list_filter").scrollWidth
              ? `Bio: ${props.state.bio.trim()}`
              : null
          }
          className="active-icon-influencer"
        >
          <span
            id={"creator_list_filter"}
            className="d-block"
            onClick={() => props.toggleFilter("creatorList")}
          >
            {`Creator List`}
          </span>

          <RenderButton close onClick={() => props.clearCreatorList_id()} />
        </li>
      ) : null}
      {/* Creator List filter end*/}

      {/* Lookalikes filter start */}
      {/* audience lookalikes */}
      {props.state.audienceLookalikeValue && (
        <li
          className="active-icon-audience"
          title={showTitle(
            `aud_lookalikes_filter}`,
            `${props.state.audienceLookalikeValue.label}`
          )}
        >
          <span
            id={`aud_lookalikes_filter}`}
            className="d-block"
            onClick={() => props.toggleFilter("lookalikes")}
          >
            {`Lookalikes: ${props.state.audienceLookalikeValue.label}`}
          </span>
          <RenderButton
            close
            onClick={() =>
              props.removeSingleSelectFilters("audienceLookalikeValue")
            }
          />
        </li>
      )}
      {/* influencer lookalikes */}
      {props.state.influencerLookalikeValue && (
        <li
          className="active-icon-influencer"
          title={showTitle(
            `inf_lookalikes_filter`,
            props.state.influencerLookalikeValue.label
          )}
        >
          <span
            id={`inf_lookalikes_filter`}
            className="d-block"
            onClick={() => props.toggleFilter("lookalikes")}
          >
            {`Lookalikes: ${props.state.influencerLookalikeValue.label}`}
          </span>
          <RenderButton
            close
            onClick={() =>
              props.removeSingleSelectFilters("influencerLookalikeValue")
            }
          />
        </li>
      )}
      {/* lookalikes filter end */}
      {/* followers filters start */}
      {/* influencer */}
      {(props.state.followersTo && props.state.followersTo.value) ||
      (props.state.followersFrom && props.state.followersFrom.value) ? (
        <li className="active-icon-influencer">
          <span
            className="d-block"
            onClick={() => props.toggleFilter("followers")}
          >
            {`Followers: ${
              (props.state.followersFrom && props.state.followersFrom.label) ||
              ""
            }-${
              (props.state.followersTo && props.state.followersTo.label) || ""
            }`}
          </span>

          <RenderButton
            close
            onClick={() =>
              props.removeSingleSelectFilters("followersFrom", null, () =>
                props.removeSingleSelectFilters("followersTo", null)
              )
            }
          />
        </li>
      ) : null}
      {/* followers filters end */}
      {(props.state.viewsTo && props.state.viewsTo.value) ||
      (props.state.viewsFrom && props.state.viewsFrom.value) ? (
        <li className="active-icon-influencer">
          <span className="d-block" onClick={() => props.toggleFilter("views")}>
            {`Views: ${
              (props.state.viewsFrom && props.state.viewsFrom.label) || ""
            }-${(props.state.viewsTo && props.state.viewsTo.label) || ""}`}
          </span>

          <RenderButton
            close
            onClick={() =>
              props.removeSingleSelectFilters("viewsFrom", null, () =>
                props.removeSingleSelectFilters("viewsTo", null)
              )
            }
          />
        </li>
      ) : null}
      {/* reels filters start */}
      {(props.state.reelsTo && props.state.reelsTo.value) ||
      (props.state.reelsFrom && props.state.reelsFrom.value) ? (
        <li className="active-icon-influencer">
          <span className="d-block" onClick={() => props.toggleFilter("reels")}>
            {`Reels Plays: ${
              (props.state.reelsFrom && props.state.reelsFrom.label) || ""
            }-${(props.state.reelsTo && props.state.reelsTo.label) || ""}`}
          </span>

          <RenderButton
            close
            onClick={() =>
              props.removeSingleSelectFilters("reelsFrom", null, () =>
                props.removeSingleSelectFilters("reelsTo", null)
              )
            }
          />
        </li>
      ) : null}
      {/* engagement filters start */}
      {/* influencer */}
      {props.state.engagementsInfluencersFrom &&
      props.state.engagementsInfluencersFrom.value &&
      props.state.engagementsInfluencersTo &&
      props.state.engagementsInfluencersTo.value ? (
        <li className="active-icon-influencer">
          <span
            className="d-block"
            onClick={() => props.toggleFilter("engagements")}
          >
            {`Engagements: ${
              props.state.engagementsInfluencersFrom.label || ""
            }-${props.state.engagementsInfluencersTo.label || ""}`}
          </span>
          <RenderButton
            close
            onClick={() =>
              props.removeSingleSelectFilters(
                "engagementsInfluencersFrom",
                null,
                () =>
                  props.removeSingleSelectFilters(
                    "engagementsInfluencersTo",
                    null
                  )
              )
            }
          />
        </li>
      ) : null}
      {/* engagement Rate */}
      {props.state.engagementRate && props.state.engagementRate.value ? (
        <li
          title={showTitle(
            "eng_rate",
            `Engagement Rate: ${props.state.engagementRate.label || ""}`
          )}
        >
          <span
            id="eng_rate"
            className="d-block"
            onClick={() => props.toggleFilter("engagements")}
          >
            {`Engagement Rate: ${props.state.engagementRate.label || ""}`}
          </span>
          <RenderButton
            close
            onClick={() =>
              props.removeSingleSelectFilters("engagementRate", null)
            }
          />
        </li>
      ) : null}
      {/* engagement filters end */}
      {/* contacts filters start */}
      {/* influencer */}
      {(props.state.contactsValues || []).map((option, index) => (
        <li
          className="active-icon-influencer"
          key={index}
          title={showTitle(
            `inf_contacts_${option.value}`,
            `Contacts: ${option.label}`
          )}
        >
          <span
            id={`inf_contacts_${option.value}`}
            className="d-block"
            onClick={() => props.toggleFilter("contacts")}
          >
            {`Contacts: ${option.label}`}
          </span>
          <RenderButton
            close
            onClick={() =>
              props.removeMultipleDropdownFilters(option, "contactsValues")
            }
          />
        </li>
      ))}
      {/* contacts filter end */}
      {/* Account Type filters start*/}
      {(props.state.accountTypeFiltersOptions || []).map((option, index) => {
        if (props.state.accountTypeFilters[option.type] === true) {
          return (
            <li
              className="active-icon-influencer"
              title={showTitle(
                `inf_account_type_${option.value}`,
                `Account Type: ${option.type}`
              )}
              key={index}
            >
              <span
                id={`inf_account_type_${option.value}`}
                className="d-block"
                onClick={() => props.toggleFilter("accountTypeFilters")}
              >
                Account Type: {option.text}
              </span>
              <RenderButton
                close
                onClick={() =>
                  props.removeAccountTypeFilters(
                    option.type,
                    "accountTypeFilter"
                  )
                }
              />
            </li>
          );
        }
        return null;
      })}
      {/* Account Type filters end */}
      {/* audienceType filters start */}
      {/* audience */}
      {props.state.audienceType ? (
        <li
          className="active-icon-audience"
          title={showTitle(
            `aud_audienceType_${props.state.audienceType}`,
            `Audience Type: ${
              audienceTypeFilterOptions.find(
                (option, index) => option.value === props.state.audienceType
              ).text
            }`
          )}
        >
          <span
            id={`aud_audienceType_${props.state.audienceType}`}
            className="d-block"
            onClick={() => props.toggleFilter("audienceType")}
          >
            Audience Type:{" "}
            {
              audienceTypeFilterOptions.find(
                (option, index) => option.value === props.state.audienceType
              ).text
            }
          </span>
          <RenderButton
            close
            onClick={() => props.removeRadioFilters("audienceType")}
          />
        </li>
      ) : null}
      {/* audienceType filters end */}
      {/* last post filter start */}
      {props.state.lastPost ? (
        <li
          className="active-icon-audience"
          title={showTitle(
            `lastpostType_${props.state.lastPost}`,
            `Last Post : ${
              lastPostFilterOption.find(
                (option, index) =>
                  option.value === parseInt(props.state.lastPost)
              ).text
            }`
          )}
        >
          <span
            id={`lastpostType_${props.state.lastPost}`}
            className="d-block"
            onClick={() => props.toggleFilter("lastPost")}
          >
            Last Post:{" "}
            {
              lastPostFilterOption.find(
                (option, index) =>
                  option.value === parseInt(props.state.lastPost)
              ).text
            }
          </span>
          <RenderButton
            close
            onClick={() => props.removeRadioFilters("lastPost")}
          />
        </li>
      ) : null}
      {/* last post filters end */}
      {/* other filters start */}
      {(Object.keys(props.state.otherFilters) || []).map((filter, index) => {
        if (props.state.otherFilters[filter] === true) {
          let filterObj =
            otherFiltersOptions.find(
              (option, ind) => option.value === filter
            ) || null;
          if (filterObj) {
            let domObject = document.getElementById(
              `other_filters_${filterObj.value}`
            );
            return (
              <li
                title={
                  domObject && domObject.offsetWidth < domObject.scrollWidth
                    ? filterObj.text
                    : ""
                }
                key={index}
              >
                <span
                  id={`other_filters_${filterObj.value}`}
                  className="d-block"
                  onClick={() => props.toggleFilter("settings")}
                >
                  {filterObj && filterObj.text}
                </span>
                <RenderButton
                  close
                  onClick={() => props.removeOtherFilters(filter)}
                />
              </li>
            );
          }
          return null;
        } else {
          return null;
        }
      })}
      {/* other filters end */}
    </>
  );
};
export default CommonSelectedFilters;
