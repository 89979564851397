import identificationReducer from "../modules/identification/reducers/index";
import pdfReportsReducer from "../modules/postAnalysis/reducers/index";
import reportsReducer from "../modules/reports/reducers/index";
import loginReducer from "../modules/login/reducers/index";
import signupReducer from "../modules/signup/reducers/index";
import userProfileReducer from "../modules/userProfile/reducers/index";
import paymentPortalReducer from "../modules/paymentPortal/reducers/index";
import forgotPasswordReducer from "../modules/forgotPassword/reducers/index";
import adminPanelReducer from "../modules/adminPannel/reducers/index";
import headerReducer from "../modules/header/reducers/index";
import tensorListsReducer from "../modules/tensorList/reducers";
import creatorListsReducer from "../modules/creatorList/reducers";
import campaignManagerReducer from '../modules/campaignManager/reducers';
import emailMatchReducer from '../modules/email_match/reducers/reducers_email_matchs';
import creatorTrackingReducer from "modules/dashboard/creatorTracking/reducers";

import { combineReducers } from "redux";

export default combineReducers({
  identification: identificationReducer,
  reports: reportsReducer,
  postAnalysis: pdfReportsReducer,
  login: loginReducer,
  signup: signupReducer,
  header: headerReducer,
  userProfile: userProfileReducer,
  paymentPortal: paymentPortalReducer,
  forgotPassword: forgotPasswordReducer,
  adminPanel: adminPanelReducer,
  tensorLists: tensorListsReducer,
  creatorLists: creatorListsReducer,
  campaignManager: campaignManagerReducer,
  emailMatch: emailMatchReducer,
  creatorTracking: creatorTrackingReducer,
});
