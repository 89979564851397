import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Media,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  FormGroup,
} from "reactstrap";
import settings from "settings";
import CreatorListModal from "./modals/creatorListModal";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import {
  PlusCircleIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
} from "@heroicons/react/24/outline";
import { useTranslation, Trans } from "react-i18next";
import noimg from "../../img/MaskGroup.png";
import { EngagementTag, EngagementRateTag, FollowerTag } from "components/tags";
import { RenderCheckBox, RenderLabel, RenderButton } from "../../components";
import { Field } from "formik";
import { formatNumbersToCommaSeperated } from "../../actions";
import sortOptions from "../../helpers/sortOptions";
import { formatNumbersToUnits } from "../../actions";
import defaultImg from "../../img/user-circle-outline.svg";
import Loader from "../../loader";

const renderMatchColumns = (obj, index, user) => {
  if (obj.radioButtonValueOfState === "audienceLocationValues") {
    return (
      <td key={index}>
        {user.match &&
        user.match.audience_likers &&
        user.match.audience_likers.data.audience_geo &&
        user.match.audience_likers.data.audience_geo.cities &&
        user.match.audience_likers.data.audience_geo.cities.length &&
        user.match.audience_likers.data.audience_geo.cities[0].weight
          ? Math.round(
              user.match.audience_likers.data.audience_geo.cities[0].weight *
                100 *
                100
            ) /
              100 +
            "%"
          : "-"}
      </td>
    );
  } else if (obj.radioButtonValueOfState === "audienceLanguage") {
    return (
      <td key={index}>
        {user.match &&
        user.match.audience_likers &&
        user.match.audience_likers.data.audience_languages &&
        user.match.audience_likers.data.audience_languages.length &&
        user.match.audience_likers.data.audience_languages[0].weight
          ? Math.round(
              user.match.audience_likers.data.audience_languages[0].weight *
                100 *
                100
            ) /
              100 +
            "%"
          : "-"}
      </td>
    );
  } else if (obj.radioButtonValueOfState === "audienceBrandsValues") {
    return (
      <td key={index}>
        {user.match &&
        user.match.audience_likers &&
        user.match.audience_likers.data.audience_brand_affinity &&
        user.match.audience_likers.data.audience_brand_affinity.length &&
        user.match.audience_likers.data.audience_brand_affinity[0].weight
          ? Math.round(
              user.match.audience_likers.data.audience_brand_affinity[0]
                .weight *
                100 *
                100
            ) /
              100 +
            "%"
          : "-"}
      </td>
    );
  } else if (obj.radioButtonValueOfState === "audienceInterestsValues") {
    return (
      <td key={index}>
        {user.match &&
        user.match.audience_likers &&
        user.match.audience_likers.data.audience_interests &&
        user.match.audience_likers.data.audience_interests.length &&
        user.match.audience_likers.data.audience_interests[0].weight
          ? Math.round(
              user.match.audience_likers.data.audience_interests[0].weight *
                100 *
                100
            ) /
              100 +
            "%"
          : "-"}
      </td>
    );
  } else if (obj.radioButtonValueOfState === "genderAudience") {
    return (
      <td key={index}>
        {user.match &&
        user.match.audience_likers &&
        user.match.audience_likers.data.audience_genders &&
        user.match.audience_likers.data.audience_genders.length &&
        user.match.audience_likers.data.audience_genders[0].weight
          ? Math.round(
              user.match.audience_likers.data.audience_genders[0].weight *
                100 *
                100
            ) /
              100 +
            "%"
          : "-"}
      </td>
    );
  } else if (obj.radioButtonValueOfState === "ethnicityAudience") {
    return (
      <td key={index}>
        {user.match &&
        user.match.audience_likers &&
        user.match.audience_likers.data.audience_ethnicities &&
        user.match.audience_likers.data.audience_ethnicities.length &&
        user.match.audience_likers.data.audience_ethnicities[0].weight
          ? Math.round(
              user.match.audience_likers.data.audience_ethnicities[0].weight *
                100 *
                100
            ) /
              100 +
            "%"
          : "-"}
      </td>
    );
  } else if (obj.radioButtonValueOfState === "audiencesAge") {
    return (
      <td key={index}>
        {user.match &&
        user.match.audience_likers &&
        user.match.audience_likers.data.audience_ages &&
        user.match.audience_likers.data.audience_ages.length &&
        user.match.audience_likers.data.audience_ages[0].weight
          ? Math.round(
              user.match.audience_likers.data.audience_ages[0].weight *
                100 *
                100
            ) /
              100 +
            "%"
          : "-"}
      </td>
    );
  } else if (obj.radioButtonValueOfState === "audiencesAgeRight") {
    return (
      <td key={index}>
        {user.match &&
        user.match.audience_likers &&
        user.match.audience_likers.data.audience_ages &&
        user.match.audience_likers.data.audience_ages.length &&
        user.match.audience_likers.data.audience_ages[0].weight
          ? Math.round(
              user.match.audience_likers.data.audience_ages[0].weight *
                100 *
                100
            ) /
              100 +
            "%"
          : "-"}
      </td>
    );
  } else if (obj.radioButtonValueOfState === "audiencesAgeLeft") {
    return (
      <td key={index}>
        {user.match &&
        user.match.audience_likers &&
        user.match.audience_likers.data.audience_ages &&
        user.match.audience_likers.data.audience_ages.length &&
        user.match.audience_likers.data.audience_ages[0].weight
          ? Math.round(
              user.match.audience_likers.data.audience_ages[0].weight *
                100 *
                100
            ) /
              100 +
            "%"
          : "-"}
      </td>
    );
  } else if (obj.radioButtonValueOfState === "viewsTo") {
    return (
      <td key={index}>
        {user && user.avg_views ? formatNumbersToUnits(user.avg_views) : "-"}
      </td>
    );
  } else {
    return <td key={index}>-</td>;
  }
};
// const renderUsersWithoutScroll = (props) => {
//   if (props.blockRecords) {
//     return (
//       <tr>
//         <td
//           colSpan={
//             props.state.selectedThreeTableColumns &&
//             props.state.selectedThreeTableColumns.length
//               ? props.state.selectedThreeTableColumns.length + 4
//               : 4
//           }
//         >
//           <Loader className="social-cloud-loader" />
//         </td>
//       </tr>
//     );
//   } else if (
//     props.influencerRecords &&
//     props.influencerRecords.users &&
//     props.influencerRecords.users.length
//   ) {
//     return props.influencerRecords.users.map((user, index) =>
//       !props.showAllRecords && index === 3 ? (
//         <Fragment key={index}>
//           <tr className="table-grey-background table-grey-row">
//             <td
//               colSpan={
//                 props.state.selectedThreeTableColumns &&
//                 props.state.selectedThreeTableColumns.length
//                   ? props.state.selectedThreeTableColumns.length + 4
//                   : 4
//               }
//             >
//               <div className="btn-text-row">
//                 <div className="ml-3 records-remaining">
//                   {props.influencerRecords && props.influencerRecords.total > 3
//                     ? `${formatNumbersToCommaSeperated(
//                         props.influencerRecords.total - 3
//                       )} more results`
//                     : ""}
//                 </div>
//                 <div className="unlock-btn">
//                   <RenderButton
//                     className="btn btn-primary"
//                     onClick={(e) => props.goToSubscription(e)}
//                   >
//                     Subscribe Now
//                   </RenderButton>
//                 </div>
//               </div>
//             </td>
//           </tr>
//           <tr>
//             <th scope="row">
//               <Media className="username-col d-flex align-items-center">
//                 <span className="mr-3 ml-3 width-30">{index + 1}</span>
//                 <Media left>
//                   <Media
//                     object
//                     src={user.picture || noimg}
//                     onError={(e) => (e.target.src = defaultImg)}
//                     alt="profile-pic"
//                   />
//                 </Media>
//                 {index > 2 && !props.showAllRecords ? (
//                   <span
//                     className={`text-grey ${
//                       !props.showAllRecords ? "blur-bg" : ""
//                     }`}
//                   >{`Account ${index - 2}`}</span>
//                 ) : (
//                   <Media body>
//                     <Media heading>
//                       {props.state.platformValue.value === "youtube"
//                         ? user.name
//                         : user.username}
//                     </Media>
//                   </Media>
//                 )}
//               </Media>
//             </th>
//             <td>
//               <FollowerTag followers={user.followers || 0}></FollowerTag>
//             </td>
//             <td>
//               <EngagementTag
//                 engagements={user.engagements || 0}
//               ></EngagementTag>
//               <EngagementRateTag
//                 engagement_rate={user.engagement_rate}
//               ></EngagementRateTag>
//             </td>
//             {props.state.selectedThreeTableColumns.map((obj, index) => {
//               return renderMatchColumns(obj, index, user);
//             })}

//             <td></td>
//           </tr>
//         </Fragment>
//       ) : (
//         <Fragment key={index}>
//           <tr>
//             <th scope="row">
//               <Media className="username-col d-flex align-items-center">
//                 <span className="mr-3 ml-3 width-30">{index + 1}</span>
//                 <Media left>
//                   <Media
//                     object
//                     src={user.picture || noimg}
//                     onError={(e) => (e.target.src = defaultImg)}
//                     alt="profile-pic"
//                   />
//                 </Media>
//                 {index > 2 && !props.showAllRecords ? (
//                   <span
//                     className={`text-grey ${
//                       !props.showAllRecords ? "blur-bg" : ""
//                     }`}
//                   >{`Account ${index - 2}`}</span>
//                 ) : (
//                   <Media body>
//                     <Media heading>
//                       {props.state.platformValue.value === "youtube"
//                         ? user.name
//                         : user.username}
//                     </Media>
//                   </Media>
//                 )}
//               </Media>
//             </th>
//             <td>
//               <FollowerTag followers={user.followers || 0}></FollowerTag>
//             </td>
//             <td>
//               <EngagementTag
//                 engagements={user.engagements || 0}
//               ></EngagementTag>
//               <EngagementRateTag
//                 engagement_rate={user.engagement_rate}
//               ></EngagementRateTag>
//             </td>
//             {props.state.selectedThreeTableColumns.map((obj, index) => {
//               return renderMatchColumns(obj, index, user);
//             })}

//             {!props.showAllRecords && index > 2 ? (
//               <td></td>
//             ) : (
//               <td align="right">
//                 <RenderButton
//                   onClick={() => props.handleAnalyze(user)}
//                   className="btn btn-outline-primary"
//                 >
//                   <Trans i18nKey="discovery.view">View Report</Trans>
//                 </RenderButton>
//               </td>
//             )}
//           </tr>
//           {index === props.influencerRecords.users.length - 1 &&
//           props.showAllRecords ? (
//             <tr className="table-grey-background table-grey-row">
//               <td
//                 colSpan={
//                   props.state.selectedThreeTableColumns &&
//                   props.state.selectedThreeTableColumns.length
//                     ? props.state.selectedThreeTableColumns.length + 4
//                     : 4
//                 }
//               >
//                 <div className="btn-text-row">
//                   <div className="ml-3 records-remaining">
//                     {props.influencerRecords &&
//                     props.influencerRecords.total >
//                       props.influencerRecords.users.length
//                       ? `${formatNumbersToCommaSeperated(
//                           props.influencerRecords.total -
//                             props.influencerRecords.users.length
//                         )} more results`
//                       : ""}
//                   </div>
//                   <div className="unlock-btn">
//                     <RenderButton
//                       className="btn btn-primary"
//                       onClick={(e) => props.goToSubscription(e)}
//                     >
//                       Subscribe Now
//                     </RenderButton>
//                   </div>
//                 </div>
//               </td>
//             </tr>
//           ) : null}
//         </Fragment>
//       )
//     );
//   }
//   return (
//     <tr>
//       <td align="center" colSpan="4" className="py-4">
//         <Trans i18nKey="errors.no_record">No Records Found</Trans>
//       </td>
//     </tr>
//   );
// };
const renderUsers = (props, selectedUsers, onItemCheck) => {
  if (props.blockRecords) {
    return (
      <tr>
        <td
          colSpan={
            props.state.selectedThreeTableColumns &&
            props.state.selectedThreeTableColumns.length
              ? props.state.selectedThreeTableColumns.length + 4
              : 4
          }
        >
          <Loader className="social-cloud-loader" />
        </td>
      </tr>
    );
  } else if (
    props.influencerRecords &&
    props.influencerRecords.users &&
    props.influencerRecords.users.length
  ) {
    return props.influencerRecords.users.map((user, index) => (
      <tr
        key={index}
        className={
          selectedUsers.some((select) => select.user_id === user.user_id)
            ? "selected"
            : ""
        }
      >
        <th scope="row">
          <Media className="username-col d-flex align-items-center">
            <span className="mr-3 ml-3">
              {settings.pages.creator_lists.enabled ? (
                <FormGroup check className="creator-lists-check">
                  <Field
                    id={`row_check_${user.user_id}`}
                    checked={
                      selectedUsers.some(
                        (select) => select.user_id === user.user_id
                      ) | false
                    }
                    onChange={(e) => onItemCheck(e, user)}
                    component={RenderCheckBox}
                    className="styled-checkbox"
                  />
                  <RenderLabel
                    check
                    for={`row_check_${user.user_id}`}
                  ></RenderLabel>
                </FormGroup>
              ) : (
                index + (props.state.currentPage - 1) * props.state.pageSize + 1
              )}
            </span>
            <Media left>
              <Media
                object
                src={user.picture || noimg}
                onError={(e) => (e.target.src = defaultImg)}
                alt="profile-pic"
              />
            </Media>
            {index > 2 && !props.showAllRecords ? (
              <span
                className={`text-grey ${
                  !props.showAllRecords ? "blur-bg" : ""
                }`}
              >{`Account ${index - 2}`}</span>
            ) : (
              <Media tag="a" href={user.url} target="_blank">
                <Media body>
                  <Media heading>
                    {props.state.platformValue.value === "youtube"
                      ? user.name
                      : user.username}
                  </Media>
                </Media>
              </Media>
            )}
          </Media>
        </th>
        <td>
          <FollowerTag followers={user.followers || 0}></FollowerTag>
        </td>
        <td>
          <EngagementTag engagements={user.engagements || 0}></EngagementTag>
          <EngagementRateTag
            engagement_rate={user.engagement_rate}
          ></EngagementRateTag>
        </td>
        {props.state.selectedThreeTableColumns.map((obj, index) => {
          return renderMatchColumns(obj, index, user);
        })}

        {!props.showAllRecords && index > 2 ? (
          <td></td>
        ) : (
          <td align="right">
            <RenderButton
              onClick={() => props.handleAnalyze(user)}
              className="btn btn-outline-primary"
            >
              <Trans i18nKey="discovery.view">View Report</Trans>
            </RenderButton>
          </td>
        )}
      </tr>
    ));
  }
  return (
    <tr>
      <td align="center" colSpan="4" className="py-4">
        <Trans i18nKey="errors.no_record">No Records Found</Trans>
      </td>
    </tr>
  );
};
const RecordsList = (props) => {
  const { t } = useTranslation();

  const [globalCheck, setGlobalCheck] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [creators, setCreators] = useState([]);

  const [creatorListModalView, setCreatorListModalView] = useState(false);

  const formattedCreatorLists = props.creatorLists
    ?.filter((list) => !list?.isShared)
    .map((list) => ({
      label: list.name,
      value: list._id,
    }));

  const toggleCreatorListModal = () => {
    setCreatorListModalView(!creatorListModalView);

    if (!creatorListModalView) {
      props.getCreatorLists();
    }
  };

  const onTableCheck = (e) => {
    let userList = props.influencerRecords?.users;
    userList.map((user) => (user.selected = e.target.checked));

    setGlobalCheck(e.target.checked);
    setSelectedUsers(userList.filter((e) => e.selected));
  };

  const onItemCheck = (e, item) => {
    const userList = props.influencerRecords?.users.map((user) => {
      if (user.user_id === item.user_id) {
        user.selected = e.target.checked;
      }

      return user;
    });

    const totalItems = props.influencerRecords?.users.length;
    const totalCheckedItems = userList.filter((e) => e.selected).length;

    setGlobalCheck(totalItems === totalCheckedItems);
    setSelectedUsers(userList.filter((e) => e.selected));
  };

  const addCreatorListHandler = (listName) => {
    props.addCreatorList({
      name: listName,
      creators,
    });
  };

  const updateCreatorListHandler = (listId) => {
    props.updateCreatorList({
      _id: listId,
      creators,
    });
  };

  useEffect(() => {
    const creators = selectedUsers.map((user) => ({
      reach: user.followers,
      engagements: user.engagements,
      network: props.state.platformValue.value,
      imageUrl: user.picture,
      handle: user.username,
      user_id: user.user_id,
    }));

    setCreators(creators);
  }, [props.state.platformValue.value, selectedUsers]);

  useEffect(() => {
    if (props.blockRecords) {
      setGlobalCheck(false);
      setSelectedUsers([]);
    }
  }, [props.blockRecords]);

  useEffect(() => {
    setCreatorListModalView(props.dismissCreatorListModal);
    if (props.dismissCreatorListModal) {
      setGlobalCheck(false);
      setSelectedUsers([]);
    }
  }, [props.dismissCreatorListModal]);

  return (
    <Row className="search-results-wrap">
      <CreatorListModal
        isOpen={creatorListModalView}
        toggleModal={toggleCreatorListModal}
        selectedUsers={creators}
        creatorLists={formattedCreatorLists}
        addCreatorListHandler={addCreatorListHandler}
        updateCreatorListHandler={updateCreatorListHandler}
      />
      <Col md="6" className="text-grey results-found">
        {props.influencerRecords && props.influencerRecords.total
          ? `${formatNumbersToCommaSeperated(
              props.influencerRecords.total
            )} ${t("discovery.results")}`
          : ""}
      </Col>
      <Col md="6">
        <div className="edit-and-sort-buttons float-md-right">
          {["entry", "standard", "premium"].includes(
            props.subscriptionDetails?.subscription
          ) && (
            <button
              onClick={() => {
                props.getAuthIntegrations();
                props.resetExportTotal();
              }}
              type="button"
              data-toggle="modal"
              data-target="#exportModal"
              className="btn btn-primary btn-export-data"
            >
              <span className="export-icon">
                <ArrowDownTrayIcon width={20} height={25} />
              </span>
            </button>
          )}
        </div>
      </Col>
      <Col md="12">
        <div className="table-responsive table-outer no-left-right-padding">
          <Table hover borderless className="table-records">
            <thead>
              <tr>
                {settings.pages.creator_lists.enabled && (
                  <th scope="col">
                    {props.influencerRecords?.users?.length > 0 &&
                      !props.blockRecords && (
                        <FormGroup check className="creator-lists-check">
                          <Field
                            id="global_check"
                            checked={globalCheck}
                            onChange={(e) => onTableCheck(e)}
                            component={RenderCheckBox}
                            className="styled-checkbox global-checkbox"
                          />
                          <RenderLabel check for="global_check"></RenderLabel>
                        </FormGroup>
                      )}
                  </th>
                )}
                {(props.state.defaultColumns || []).map((header, index) =>
                  index !== props.state.defaultColumns.length - 1 ? (
                    <th
                      key={index}
                      width={
                        (props.state.selectedThreeTableColumns || []).length
                          ? 100 /
                              (props.state.selectedThreeTableColumns.length +
                                props.state.defaultColumns.length) +
                            "%"
                          : header.width
                      }
                    >
                      <span
                        className={
                          props.state.sortColumn === header.value
                            ? "records-table action-sorting active"
                            : "records-table action-sorting"
                        }
                        onClick={() =>
                          props.setSortColumn({
                            value: header.value,
                            desc:
                              props.state.sortColumn === header.value
                                ? !props.state.sortDesc
                                : true,
                          })
                        }
                      >
                        {props.state.platformValue.value === "youtube" &&
                        header.value === "followers"
                          ? t("discovery.filters.followers.youtube")
                          : header.text}
                        {header.sort &&
                          props.state.sortColumn === header.value &&
                          props.state.sortDesc && (
                            <BarsArrowDownIcon
                              className="sort-icon"
                              width={22}
                              height={22}
                              strokeWidth={2}
                            />
                          )}
                        {header.sort &&
                          props.state.sortColumn === header.value &&
                          !props.state.sortDesc && (
                            <BarsArrowUpIcon
                              className="sort-icon"
                              width={22}
                              height={22}
                              strokeWidth={2}
                            />
                          )}
                      </span>
                    </th>
                  ) : (
                    <Fragment key={index}>
                      {(props.state.selectedThreeTableColumns || []).map(
                        (header, index1) => {
                          const dynamicSort =
                            sortOptions &&
                            sortOptions.find(
                              (opt) =>
                                opt.radioButtonValueOfState ===
                                header.radioButtonValueOfState
                            ).value;
                          return (
                            <th
                              key={`${index}_${index1}`}
                              width={
                                100 /
                                  (props.state.selectedThreeTableColumns
                                    .length +
                                    props.state.defaultColumns.length) +
                                "%"
                              }
                            >
                              <span
                                className={
                                  index === 0
                                    ? "ml-3 records-table"
                                    : props.state.sortColumn === dynamicSort
                                    ? "records-table action-sorting active"
                                    : "records-table action-sorting"
                                }
                                onClick={() =>
                                  props.setSortColumn({
                                    value: dynamicSort,
                                    desc:
                                      props.state.sortColumn === dynamicSort
                                        ? !props.state.sortDesc
                                        : true,
                                  })
                                }
                              >
                                {header.label}
                                {props.state.sortColumn === dynamicSort &&
                                  props.state.sortDesc && (
                                    <BarsArrowDownIcon
                                      className="sort-icon"
                                      width={22}
                                      height={22}
                                      strokeWidth={2}
                                    />
                                  )}
                                {props.state.sortColumn === dynamicSort &&
                                  !props.state.sortDesc && (
                                    <BarsArrowUpIcon
                                      className="sort-icon"
                                      width={22}
                                      height={22}
                                      strokeWidth={2}
                                    />
                                  )}
                              </span>
                            </th>
                          );
                        }
                      )}

                      <th
                        width={
                          (props.state.selectedThreeTableColumns || []).length
                            ? 100 /
                                (props.state.selectedThreeTableColumns.length +
                                  props.state.defaultColumns.length) +
                              "%"
                            : header.width
                        }
                      >
                        {settings.pages.discovery.edit_columns &&
                          props.state.allSelectedFilters &&
                          props.state.allSelectedFilters.length > 0 && (
                            <span
                              className={
                                index === 0
                                  ? "ml-3 records-table"
                                  : "records-table"
                              }
                            >
                              <Dropdown
                                className="gear-edit-columns float-right"
                                isOpen={props.state.editColumnsMenuOpen}
                                toggle={props.toggleEditColumn}
                              >
                                <DropdownToggle
                                  disabled={
                                    !props.state.allSelectedFilters ||
                                    !props.state.allSelectedFilters.length
                                  }
                                  tag="span"
                                  onClick={props.toggleEditColumn}
                                  data-toggle="dropdown"
                                  aria-expanded={
                                    props.state.editColumnsMenuOpen
                                  }
                                >
                                  <PlusCircleIcon width={25} height={25} />
                                </DropdownToggle>
                                <DropdownMenu className="ddmenu-col">
                                  <DropdownItem header>
                                    Select Up To 3
                                  </DropdownItem>
                                  <ul className="list-unstyled edit-col-list">
                                    {(props.state.allSelectedFilters || []).map(
                                      (col, index) => {
                                        return (
                                          <li key={index}>
                                            <FormGroup check>
                                              <Field
                                                name={col.value}
                                                id={
                                                  col.value +
                                                  col.radioButtonValueOfState
                                                }
                                                component={RenderCheckBox}
                                                onChange={(input) =>
                                                  props.handleEditcolumns(
                                                    input,
                                                    col.radioButtonValueOfState,
                                                    col.label
                                                  )
                                                }
                                                checked={
                                                  props.state.selectedThreeTableColumns.find(
                                                    (obj) =>
                                                      obj.value.toString() ===
                                                        col.value.toString() &&
                                                      obj.radioButtonValueOfState ===
                                                        col.radioButtonValueOfState
                                                  )
                                                    ? true
                                                    : false
                                                }
                                                disabled={
                                                  props.state
                                                    .selectedThreeTableColumns
                                                    .length === 3 &&
                                                  props.state.selectedThreeTableColumns.findIndex(
                                                    (obj) =>
                                                      obj.value.toString() ===
                                                        col.value.toString() &&
                                                      obj.radioButtonValueOfState ===
                                                        col.radioButtonValueOfState
                                                  ) < 0
                                                    ? true
                                                    : false
                                                }
                                                className="styled-checkbox"
                                              />{" "}
                                              <RenderLabel
                                                check
                                                for={
                                                  col.value +
                                                  col.radioButtonValueOfState
                                                }
                                              >
                                                {col.label.props
                                                  ? t(col.label.props.i18nKey)
                                                  : col.label}
                                              </RenderLabel>
                                            </FormGroup>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </DropdownMenu>
                              </Dropdown>
                            </span>
                          )}
                      </th>
                    </Fragment>
                  )
                )}
              </tr>
            </thead>
            <tbody>{renderUsers(props, selectedUsers, onItemCheck)}</tbody>
          </Table>
        </div>
      </Col>
      <Col md="12">
        <div className={`footer ${selectedUsers.length > 0 ? "show" : ""}`}>
          <div className="content float-right">
            <span>
              Add {selectedUsers.length} account
              {selectedUsers.length > 1 ? "s" : ""} to creator list
            </span>
            <RenderButton
              className="btn btn-outline-primary ml-5"
              onClick={toggleCreatorListModal}
            >
              Continue
            </RenderButton>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default RecordsList;
