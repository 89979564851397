import React from "react";
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { RenderCheckBox, RenderLabel } from "../../components";
import { Field } from "formik";
import otherFiltersOptions from "../../helpers/otherFiltersOptions";
import { showTooltip } from "../../actions";
import youtubeOtherFiltersOptions from "../../helpers/youtubeOtherFilterOptions";

const OtherFilters = (props) => {
  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2 dot-filter-width"
      isOpen={props.state.filters.settings}
      toggle={() => props.toggleFilter("settings")}
    >
      <DropdownToggle className="btn btn-default settings btn-dropdown-white"></DropdownToggle>
      <DropdownMenu className="ddmenu-col width-md">
        <ul className="list-unstyled settings-list other-filters">
          {(props.state.platformValue.value === "youtube" ||
          props.state.platformValue.value === "tiktok"
            ? youtubeOtherFiltersOptions
            : otherFiltersOptions
          ).map((option, index) => (
            <li key={index}>
              <FormGroup check>
                <Field
                  name={option.value}
                  id={option.value}
                  checked={props.state.otherFilters[option.value]}
                  onChange={props.handleOtherFiltersChange}
                  component={RenderCheckBox}
                  className="styled-checkbox"
                />
                <RenderLabel className="minWidth-200" check for={option.value}>
                  {option.text}
                  <span className="tooltip-button-wrap">
                    <span className="text-tooltip"></span>
                    <span
                      id={`tooltip_${option.value}`}
                      className="tooltip-icon other-filter"
                    ></span>
                  </span>
                  {props.tooltip && (
                    <UncontrolledTooltip
                      className="tooltip-content"
                      target={`tooltip_${option.value}`}
                    >
                      {showTooltip(option.value)}
                    </UncontrolledTooltip>
                  )}
                </RenderLabel>
              </FormGroup>
            </li>
          ))}
        </ul>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default OtherFilters;
