import React, { useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { RenderButton } from "../../components";
import "./index.scss";
import settings from "settings";
import TrialBanner from "components/banners/TrialBanner";
import creatorListImage from "../../img/creator_list_image.svg";
import CreatorListsTable from "./creatorListsTable";
import { getCreatorLists, addCreatorList, deleteCreatorList } from "./actions";
import { connect } from "react-redux";
import history from "../../history";

const CreatorList = function (props) {
  const { t } = useTranslation();

  const {
    providers: { calendly },
  } = settings;

  const { getCreatorListsAction, deleteCreatorListAction, creatorLists } =
    props;

  useEffect(() => {
    getCreatorListsAction();
  }, [getCreatorListsAction]);

  const handleNewCreatorList = () => {
    history.push("/discovery");
  };

  const handleDeleteCreatorList = (list_id) => {
    deleteCreatorListAction(list_id);
  };

  const listButton = (
    <RenderButton
      className="btn btn-primary list-button mb-3"
      onClick={handleNewCreatorList}
    >
      {t("creator_lists.new_list")}
    </RenderButton>
  );

  return (
    <Container className="main-site-content splash creator-lists">
      {props.subscriptionDetails?.status === "trialing" && calendly.enabled ? (
        <TrialBanner type="lists" loginUser={props.loginUser} />
      ) : null}
      <Row className="page-header">
        <Col className="is-search-block search-wrap">
          {creatorLists && creatorLists.length ? (
            <>
              <h1 className="page-title">{t("creator_lists.title")}</h1>

              <p className="sub-text text-grey">
                {t("creator_lists.description")}
              </p>
              {listButton}
              <CreatorListsTable
                creatorLists={creatorLists}
                deleteCreatorList={handleDeleteCreatorList}
              />
            </>
          ) : (
            <div align="center" className="welcome-page">
              <img
                className="welcome-img"
                src={creatorListImage}
                alt="dashboard_image"
              />
              <div className="text-wrapper">
                <h6 className="page-heading">{t("creator_lists.welcome")}</h6>
                <p className="text-grey">
                  {t("creator_lists.welcome_description")}
                </p>
              </div>
              {listButton}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loginUser: state.login.loginUser,
    creatorLists: state.creatorLists.lists,
    subscriptionDetails: state.login.subscriptionDetails,
  };
};
export default connect(mapStateToProps, {
  getCreatorListsAction: getCreatorLists,
  addCreatorListAction: addCreatorList,
  deleteCreatorListAction: deleteCreatorList,
})(CreatorList);
