import React from "react";
import settings from "settings";
import { Translation, Trans } from "react-i18next";
import { Row, Col, FormGroup } from "reactstrap";
import { Form, Formik, Field } from "formik";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  SubmitButton,
  RenderTextField,
  RenderPasswordField,
  RenderCheckBox,
  RenderLabel,
} from "../../components/index";
import { login } from "./actions/index";
import { validate } from "./validate";
import { showSuccessMessage, showErrorMessage } from "../../actions";
import {
  clearErrorMsg,
  ClearSignoutErrorMsg,
  saveCredentials,
  removeCredentials,
  clearLoading,
} from "./actions/index";
import { clearSuccessMsg as clearForgotPasswordSuccessMsg } from "../forgotPassword/actions";
import { toast } from "react-toastify";

import { store } from "../../store";
// import HCaptcha from 'react-hcaptcha';
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      isRememberMeChecked: false,
      captcha_token: "",
      showLoginForm: true,
    };
  }
  handleShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  componentDidMount() {
    this.props.clearLoading();
    document.getElementsByClassName("app")[0].classList.add("auth-wrap");
    let url = window.location.href;
    let params = url.split("?").length > 1 && url.split("?").pop().split("&");
    let email =
      params[0] && params[0].includes("email") && params[0].split("=").pop();
    let token =
      params[1] && params[1].includes("token") && params[1].split("=").pop();
    if (email && email.trim() && token && token.trim()) {
      let postData = {
        demo: true,
        email: email.trim(),
        password: token.trim(),
      };

      this.setState(
        {
          showLoginForm: false,
        },
        () => this.props.login(postData)
      );
    }

    if (this.props.rememberMeCredentials) {
      this.setState({
        isRememberMeChecked: true,
      });
    }
  }

  componentWillUnmount() {
    toast.dismiss();
    this.props.clearErrorMsg();
    this.props.ClearSignoutErrorMsg();
    this.props.clearForgotPasswordSuccessMsg();
    document.getElementsByClassName("app")[0].classList.remove("auth-wrap");
  }
  componentDidUpdate(props, state) {
    if (
      this.props.signoutErrorMsg &&
      !props.signoutErrorMsg &&
      this.state.showLoginForm
    ) {
      this.form.setFieldValue("password", "", false);
    }
  }
  handleRememberMe = () => {
    if (this.state.isRememberMeChecked) {
      this.setState(
        {
          isRememberMeChecked: false,
        },
        () => this.props.removeCredentials()
      );
    } else {
      this.setState({
        isRememberMeChecked: true,
      });
    }
  };

  onVerifyCaptcha = (captcha_token) => {
    this.setState({
      captcha_token: captcha_token,
    });
  };
  handleEnterKey = (event, values) => {
    if (event.charCode === 13) {
      this.onSubmit(values);
    }
  };
  onSubmit = (values) => {
    let postData = { ...values };

    if (postData.password) {
      postData.password = postData.password.trim();
    }
    if (postData.email) {
      postData.email = postData.email.trim().toLowerCase();
    }
    // postData.captcha_token = this.state.captcha_token;
    if (this.state.isRememberMeChecked) {
      this.props.saveCredentials(postData);
    }
    this.props.login(postData);
  };
  clearForgotPasswordSuccessMsg = () => {
    this.props.clearForgotPasswordSuccessMsg();
  };
  handleClearErrorMsg = () => {
    this.props.clearErrorMsg();
  };
  handleClearSignoutErrorMsg = () => {
    this.props.ClearSignoutErrorMsg();
  };
  render() {
    return (
      <>
        {this.props.forgotPasswordSuccessMsg
          ? showSuccessMessage(
              this.props.forgotPasswordSuccessMsg,
              this.clearForgotPasswordSuccessMsg,
              5000,
              "forgotPasswordSuccessMsg"
            )
          : null}
        {this.props.signoutErrorMsg
          ? showErrorMessage(
              store,
              this.props.signoutErrorMsg,
              this.handleClearSignoutErrorMsg,
              5000,
              "signoutErrorMsg"
            )
          : null}
        {this.props.errorMsg
          ? showErrorMessage(
              store,
              this.props.errorMsg,
              this.handleClearErrorMsg,
              5000,
              "errorMsg"
            )
          : null}
        {this.state.showLoginForm ? (
          <div className=" pt-40">
            <div className="main-site-content container">
              <div className="user-form-wrap">
                <div className="page-head">
                  <img
                    src={settings.mainbrand}
                    className="auth_logo"
                    alt="homepage"
                  />
                  <h1>
                    <Trans i18nKey="auth.login.title">Sign in</Trans>
                  </h1>
                  {/* <p className="sub-text">
                    <Trans i18nKey="auth.login.description">
                      Sign in now to access the latest insights for your social
                      media performance.
                    </Trans>
                  </p> */}
                </div>
                <Formik
                  ref={(form) => (this.form = form)}
                  onSubmit={this.onSubmit}
                  validate={validate}
                  initialValues={this.props.initialValues}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form>
                      <FormGroup>
                        <Translation>
                          {(t, { i18n }) => (
                            <Field
                              name="email"
                              placeholder={t("auth.login.form.email")}
                              autoComplete="username password"
                              className={
                                errors.email && touched.email ? "error" : ""
                              }
                              component={RenderTextField}
                            />
                          )}
                        </Translation>
                        {errors.email && touched.email && (
                          <span className="required">{errors.email}</span>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Translation>
                          {(t, { i18n }) => (
                            <Field
                              name="password"
                              placeholder={t("auth.login.form.password")}
                              onKeyPress={(event) =>
                                this.handleEnterKey(event, values)
                              }
                              className={
                                errors.password && touched.password
                                  ? "error has-icon-right"
                                  : "has-icon-right"
                              }
                              component={
                                this.state.showPassword
                                  ? RenderTextField
                                  : RenderPasswordField
                              }
                            />
                          )}
                        </Translation>
                        <span
                          onClick={this.handleShowPassword}
                          className="button-showpass"
                        >
                          {this.state.showPassword ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19.067"
                              height="20.482"
                              viewBox="0 0 19.067 20.482"
                            >
                              <path
                                id="ic_remove_red_eye_24px"
                                d="M10.533,4.5A10.25,10.25,0,0,0,1,11a10.241,10.241,0,0,0,19.067,0A10.25,10.25,0,0,0,10.533,4.5Zm0,10.833A4.333,4.333,0,1,1,14.867,11,4.335,4.335,0,0,1,10.533,15.333Zm0-6.933a2.6,2.6,0,1,0,2.6,2.6A2.6,2.6,0,0,0,10.533,8.4Z"
                                transform="translate(-1 -0.759)"
                              />
                              <line
                                id="Line_1"
                                data-name="Line 1"
                                x1="11"
                                y2="20"
                                transform="translate(4.033 0.241)"
                                strokeWidth="1"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19.067"
                              height="13"
                              viewBox="0 0 19.067 13"
                            >
                              <path
                                id="ic_remove_red_eye_24px"
                                d="M10.533,4.5A10.25,10.25,0,0,0,1,11a10.241,10.241,0,0,0,19.067,0A10.25,10.25,0,0,0,10.533,4.5Zm0,10.833A4.333,4.333,0,1,1,14.867,11,4.335,4.335,0,0,1,10.533,15.333Zm0-6.933a2.6,2.6,0,1,0,2.6,2.6A2.6,2.6,0,0,0,10.533,8.4Z"
                                transform="translate(-1 -4.5)"
                              />
                            </svg>
                          )}
                        </span>
                        {errors.password && touched.password && (
                          <span className="required">{errors.password}</span>
                        )}
                        <Row className="m-0 forgot-pass-link">
                          <Col md="6" className="px-0">
                            <div className="text-left text">
                              <Field
                                name="rememberme"
                                id="rememberme"
                                component={RenderCheckBox}
                                checked={this.state.isRememberMeChecked}
                                onChange={this.handleRememberMe}
                                className="styled-checkbox"
                              />
                              <RenderLabel
                                check
                                for="rememberme"
                                className="text-grey"
                              >
                                <Trans i18nKey="auth.login.remember">
                                  Remember me
                                </Trans>
                              </RenderLabel>
                            </div>
                          </Col>
                          <Col md="6" className="px-0">
                            <div className="text-right text">
                              <NavLink to="/forgotPassword" className="link">
                                <Trans i18nKey="auth.login.forgot_password">
                                  Forgot password?
                                </Trans>
                              </NavLink>
                            </div>
                          </Col>
                        </Row>
                      </FormGroup>

                      <FormGroup></FormGroup>
                      {/* <FormGroup >
                          <HCaptcha ref={input => this.captcha = input} sitekey={CAPTCHA_KEY} onVerify={this.onVerifyCaptcha}></HCaptcha>
                        </FormGroup> */}

                      <FormGroup className="bottom text-center">
                        <SubmitButton className="btn btn-primary">
                          <Trans i18nKey="auth.login.continue">Continue</Trans>
                        </SubmitButton>
                        {settings.pages.signup.enabled ? (
                          <p className="text-grey">
                            <Trans i18nKey="auth.login.no_account">
                              Don’t have an account?
                            </Trans>{" "}
                            <NavLink to="/signup" className="link-more">
                              <Trans i18nKey="auth.login.sign_up">
                                Sign up
                              </Trans>
                            </NavLink>
                          </p>
                        ) : null}
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    initialValues: {
      email:
        (state.login.rememberMeCredentials &&
          state.login.rememberMeCredentials.email) ||
        "",
      password:
        (state.login.rememberMeCredentials &&
          state.login.rememberMeCredentials.password) ||
        "",
    },
    errorMsg: state.login.errorMsg,
    rememberMeCredentials: state.login.rememberMeCredentials,
    signoutErrorMsg: state.login.signoutErrorMsg,
    forgotPasswordSuccessMsg: state.forgotPassword.successMsg,
  };
};
export default connect(mapStateToProps, {
  login,
  clearErrorMsg,
  ClearSignoutErrorMsg,
  saveCredentials,
  removeCredentials,
  clearLoading,
  clearForgotPasswordSuccessMsg,
})(Login);
