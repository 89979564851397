import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  RenderButton,
  RenderSingleSelect,
  RenderMutiSelect,
} from "../../components";
import { engagement_rate } from "../../helpers/engagementRateOptions";
import { Field } from "formik";
import { showTitle, showTooltip } from "../../actions";

const InterestsFilter = (props) => {
  const { t } = useTranslation();

  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2 second"
      isOpen={props.state.filters.interests}
      toggle={() => props.toggleFilter("interests")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {t("discovery.filters.culture.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-lg">
        <Row>
          <Col sm="6" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-audience">
                  {t("discovery.audience")}
                </h3>
              </span>
              <span
                id="interestsAudience"
                className="tooltip-icon tool-top"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="interestsAudience"
              >
                {showTooltip("interestsAudience")}
              </UncontrolledTooltip>
            )}
            <Field
              component={RenderMutiSelect}
              name="audienceInterests"
              options={props.filteredAudienceInterestOptions}
              onChange={(values) =>
                props.handleMultipleDropdownChange(values, "audienceInterests")
              }
              onInputChange={(value) =>
                props.filterOptions(
                  "filteredAudienceInterestOptions",
                  "interests",
                  value
                )
              }
              numberDisplayed={0}
              placeholder={t("discovery.filters.culture.placeholder.audience")}
              value={props.state.audienceInterestsValues}
            />
            {props.state.audienceInterestsValues &&
            props.state.audienceInterestsValues.length ? (
              <Row className="selected-tags-bottom left-audience">
                <Col sm="12" className="col active-filters-wrap">
                  <div className="active-filters inside">
                    <ul className="list-inline">
                      {(props.state.audienceInterestsValues || []).map(
                        (option, index) => (
                          <li key={index}>
                            <div className="li-audience">
                              <p
                                title={showTitle(
                                  `aud_interests_inside_${option.value}`,
                                  option.label
                                )}
                                id={`aud_interests_inside_${option.value}`}
                              >
                                {option.label}
                              </p>
                              <RenderButton
                                close
                                onClick={() =>
                                  props.removeMultipleDropdownFilters(
                                    option,
                                    "audienceInterestsValues"
                                  )
                                }
                              />
                            </div>
                            <FormGroup>
                              <Field
                                className="li-weight"
                                component={RenderSingleSelect}
                                name={`interestsWeight${index}`}
                                placeholder={t(
                                  "discovery.fields.select_weight"
                                )}
                                value={option.weight}
                                onChange={(option) =>
                                  props.handleSingleSelectWeight(
                                    option,
                                    "audienceInterestsValues",
                                    index
                                  )
                                }
                                onInputChange={(value) =>
                                  props.filterOptions(
                                    "filteredInfluencerInterestOptions",
                                    "interests",
                                    value
                                  )
                                }
                                options={engagement_rate}
                              />
                            </FormGroup>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>

          <Col sm="6" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span
                id="interestsInfluencer"
                className="tooltip-icon tool-top"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="interestsInfluencer"
              >
                {showTooltip("interestsInfluencer")}
              </UncontrolledTooltip>
            )}
            <Field
              component={RenderMutiSelect}
              name="influencerInterests"
              options={props.filteredInfluencerInterestOptions}
              onChange={(values) =>
                props.handleMultipleDropdownChange(
                  values,
                  "influencerInterests"
                )
              }
              onInputChange={(value) =>
                props.filterOptions(
                  "filteredInfluencerInterestOptions",
                  "interests",
                  value
                )
              }
              placeholder={t(
                "discovery.filters.culture.placeholder.influencer"
              )}
              value={props.state.influencerInterestsValues}
            />
            {props.state.influencerInterestsValues &&
            props.state.influencerInterestsValues.length ? (
              <Row className="selected-tags-bottom">
                <Col sm="12" className="col active-filters-wrap">
                  <div className="active-filters inside">
                    <ul className="list-inline">
                      {(props.state.influencerInterestsValues || []).map(
                        (option, index) => (
                          <li key={index}>
                            <div className="li-influencer">
                              <p
                                title={showTitle(
                                  `inf_interests_inside_${option.value}`,
                                  option.label
                                )}
                                id={`inf_interests_inside_${option.value}`}
                              >
                                {option.label}
                              </p>
                              <RenderButton
                                close
                                onClick={() =>
                                  props.removeMultipleDropdownFilters(
                                    option,
                                    "influencerInterestsValues"
                                  )
                                }
                              />
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default InterestsFilter;
