import { createRequestActionTypes, API_URL } from "../../../actions";
export const GET_USER_MANAGEMENT_DATA_URL = `${API_URL}/api/auth/admin/users`;
export const GET_USER_DETAILS_FOR_EDIT_URL = `${API_URL}/api/auth/admin/users`;
export const GRANT_API_ACCESS_URL = `${API_URL}/api/auth/admin/users`;
export const GRANT_TRIAL_ACCESS_URL = `${API_URL}/api/auth/admin/users`;
export const REVOKE_API_ACCESS_URL = `${API_URL}/api/auth/admin/users`;
export const UPDATE_CREDITS_BALANCE_URL = `${API_URL}/api/auth/admin/users`;
export const UPDATE_CARD_DETAILS_URL = `${API_URL}/api/auth/admin/users`;
export const GET_METERED_PLANS_DETAILS_URL = `${API_URL}/api/auth/admin/subscription_plans`;
export const EDIT_SUBSCRIPTION_PLANS_DETAILS = `${API_URL}/api/auth/admin/subscription_plans`;
export const GET_DEMO_LINK_URL = `${API_URL}/api/auth/generate/demo`;
export const adminPanelActionTypes = {
    getUserManagementData: createRequestActionTypes('GET_USER_MANAGEMENT_DATA'),
    getUserDetailsForEdit: createRequestActionTypes('GET_USER_DETAILS_FOR_EDIT'),
    grantAPIAccess: createRequestActionTypes('GRANT_API_ACCESS'),
    revokeAPIAccess: createRequestActionTypes('REVOKE_API_ACCESS'),
    grantTrialAccess: createRequestActionTypes('GRANT_TRAIL_ACCESS'),
    updateCreditsBalance: createRequestActionTypes('UPDATE_CREDITS_BALANCE'),
    clearTrialAccessMessage: createRequestActionTypes('CLEAR_TRIAL_ACCESS_MESSAGE'),
    clearPlanUpdateErrorMsg: createRequestActionTypes('CLEAR_PLAN_UPDATE_ERROR_MSG'),
    clearCardUpdateMessage: createRequestActionTypes('CLEAR_CARD_UPDATE_MESSAGE'),
    cardDetailsUpdate: createRequestActionTypes('UPDATE_CARD_DETAILS'),
    getSubscriptionPlansDetails: createRequestActionTypes('GET_METERED_PLANS_DETAILS'),
    editSubscriptionPlansDetails: createRequestActionTypes('EDIT_SUBSCRIPTION_PLANS_DETAILS'),
    getMeteredPlansDetails: createRequestActionTypes('GET_METERED_PLANS_DETAILS'),
    getSearchResult: createRequestActionTypes('GET_USER_SEARCH_DATA'),
    getDemoLink: createRequestActionTypes('GET_DEMO_LINK'),
    clearDemoLinksErrorMsg: createRequestActionTypes('CLEAR_DEMO_LINKS_ERROR_MSG'),
}