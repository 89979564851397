import React from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonDropdown,
  DropdownMenu,
  Row,
  Col,
  DropdownToggle,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { RenderCheckBox, RenderLabel } from "../../components";
import { Field } from "formik";
import { showTooltip } from "../../actions";

const AccountTypeFilter = (props) => {
  const { t } = useTranslation();

  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2 second"
      isOpen={props.state.filters.accountTypeFilters}
      toggle={() => props.toggleFilter("accountTypeFilters")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {t("discovery.filters.account_type.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-md">
        <Row>
          <Col md="12" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span id="accountType" className="tooltip-icon tool-top"></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="accountType"
              >
                {showTooltip("accountType")}
              </UncontrolledTooltip>
            )}
            <ul className="list-unstyled settings-list">
              {(props.state.accountTypeFiltersOptions || []).map(
                (option, index) => (
                  <li key={index}>
                    <FormGroup check>
                      <Field
                        name={option.value.toString()}
                        id={option.value.toString()}
                        checked={props.state.accountTypeFilters[option.type]}
                        onChange={(input) =>
                          props.handleAccountTypeFiltersChange(
                            input,
                            option.type,
                            "accountTypeFilter"
                          )
                        }
                        component={RenderCheckBox}
                        className="styled-checkbox"
                      />
                      <RenderLabel check for={option.value.toString()}>
                        {option.text}
                      </RenderLabel>
                    </FormGroup>
                  </li>
                )
              )}
            </ul>
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default AccountTypeFilter;
