export const validate = (values) => {
  const errors = {};
  if (!values.firstName || values.firstName.trim() === '') {
    errors.firstName = 'This field is required';
  }
  if (!values.lastName || values.lastName.trim() === '') {
    errors.lastName = 'This field is required';
  }
  if (!values.phone || values.phone.trim() === '') {
    errors.phone = 'This field is required';
  } else if (values.phone && !/^[+()\d\-\s.ext]{9,}$/mi.test(values.phone)) {
    errors.phone = 'Invalid phone number';
  }
  if (!values.email || values.email.trim() === '') {
    errors.email = 'This field is required';
  } else if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]/i.test(values.email)) {
    errors.email = 'Invalid email';
  }
  if (!values.password || values.password.trim() === '') {
    errors.password = 'This field is required';
  } else if (values.password.trim().length < 8) {
    errors.password = 'Must have atleast 8 characters';
  }
  if (!values.password2 || values.password2.trim() === '') {
    errors.password2 = 'This field is required';
  } else if (values.password && values.password2 && values.password.trim() !== values.password2.trim()) {
    errors.password2 = 'Password doesn\'t match';
  }
  return errors;
}
