const inf_fol_engage_left = [
  { label: "1k", value: 1000 },
  { label: "5k", value: 5000 },
  { label: "10k", value: 10000 },
  { label: "20k", value: 20000 },
  { label: "50k", value: 50000 },
  { label: "100k", value: 100000 },
  { label: "500k", value: 500000 },
  { label: "1M", value: 1000000 },
  { label: "2.5M", value: 2500000 },
  { label: "5M", value: 5000000 },
  { label: "10M", value: 10000000 },
  { label: "25M", value: 25000000 },
];
const inf_fol_engage_right = [
  { label: "1k", value: 1000 },
  { label: "5k", value: 5000 },
  { label: "10k", value: 10000 },
  { label: "20k", value: 20000 },
  { label: "50k", value: 50000 },
  { label: "100k", value: 100000 },
  { label: "500k", value: 500000 },
  { label: "1M", value: 1000000 },
  { label: "2.5M", value: 2500000 },
  { label: "5M", value: 5000000 },
  { label: "10M", value: 10000000 },
  { label: "25M", value: 25000000 },
  { label: "50M+", value: 500000000 },
];

export const followers_engage_range_left = inf_fol_engage_left;
export const followers_engage_range_right = inf_fol_engage_right;
