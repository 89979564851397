import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  RenderButton,
  RenderSingleSelect,
  RenderMutiSelect,
} from "../../components";

import { engagement_rate } from "../../helpers/engagementRateOptions";
import { Field } from "formik";
import { showTitle, showTooltip } from "../../actions";
const BrandsFilter = (props) => {
  const { t } = useTranslation();
  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2 third"
      isOpen={props.state.filters.brands}
      toggle={() => props.toggleFilter("brands")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {t("discovery.filters.brands.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-lg">
        <Row>
          <Col sm="6" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-audience">
                  {t("discovery.audience")}
                </h3>
              </span>
              <span
                id="brandsAudience"
                className="tooltip-icon tool-top"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="brandsAudience"
              >
                {showTooltip("brandsAudience")}
              </UncontrolledTooltip>
            )}
            <Field
              component={RenderMutiSelect}
              options={props.filteredAudienceBrandOptions}
              name="audienceBrands"
              onChange={(values) =>
                props.handleMultipleDropdownChange(values, "audienceBrands")
              }
              onInputChange={(value) =>
                props.filterOptions(
                  "filteredAudienceBrandOptions",
                  "brand",
                  value
                )
              }
              placeholder={t("discovery.filters.brands.placeholder.audience")}
              value={props.state.audienceBrandsValues}
            />
            {props.state.audienceBrandsValues &&
            props.state.audienceBrandsValues.length ? (
              <Row className="selected-tags-bottom left-audience">
                <Col sm="12" className="col active-filters-wrap">
                  <div className="active-filters inside">
                    <ul className="list-inline">
                      {(props.state.audienceBrandsValues || []).map(
                        (option, index) => (
                          <li key={index}>
                            <div className="li-audience">
                              <p
                                title={showTitle(
                                  `aud_brands_inside_${option.value}`,
                                  option.label
                                )}
                                id={`aud_brands_inside_${option.value}`}
                              >
                                {option.label}
                              </p>
                              <RenderButton
                                close
                                onClick={() =>
                                  props.removeMultipleDropdownFilters(
                                    option,
                                    "audienceBrandsValues"
                                  )
                                }
                              />
                            </div>
                            <FormGroup key={index}>
                              <Field
                                className="li-weight"
                                component={RenderSingleSelect}
                                name={`brandsWeight${index}`}
                                placeholder={t(
                                  "discovery.fields.select_weight"
                                )}
                                value={option.weight}
                                onChange={(option) =>
                                  props.handleSingleSelectWeight(
                                    option,
                                    "audienceBrandsValues",
                                    index
                                  )
                                }
                                options={engagement_rate}
                              />
                            </FormGroup>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
          <Col sm="6" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span
                id="brandsInfluencer"
                className="tooltip-icon tool-top"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="brandsInfluencer"
              >
                {showTooltip("brandsInfluencer")}
              </UncontrolledTooltip>
            )}
            <Field
              component={RenderMutiSelect}
              name="influencerBrands"
              options={props.filteredInfluencerBrandOptions}
              onChange={(values) =>
                props.handleMultipleDropdownChange(values, "influencerBrands")
              }
              onInputChange={(value) =>
                props.filterOptions(
                  "filteredInfluencerBrandOptions",
                  "brand",
                  value
                )
              }
              placeholder={t("discovery.filters.brands.placeholder.influencer")}
              value={props.state.influencerBrandsValues}
            />
            {props.state.influencerBrandsValues &&
            props.state.influencerBrandsValues.length ? (
              <Row className="selected-tags-bottom">
                <Col sm="12" className="col active-filters-wrap">
                  <div className="active-filters inside">
                    <ul className="list-inline">
                      {(props.state.influencerBrandsValues || []).map(
                        (option, index) => (
                          <li key={index}>
                            <div className="li-influencer">
                              <p
                                title={showTitle(
                                  `inf_brands_inside_${option.value}`,
                                  option.label
                                )}
                                id={`inf_brands_inside_${option.value}`}
                              >
                                {option.label}
                              </p>
                              <RenderButton
                                close
                                onClick={() =>
                                  props.removeMultipleDropdownFilters(
                                    option,
                                    "influencerBrandsValues"
                                  )
                                }
                              />
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default BrandsFilter;
