import { API_URL, createRequestActionTypes } from "../../../actions/index";
export const LOGIN_USER_URL = `${API_URL}/api/auth`;
export const LOGIN_URL = `${API_URL}/api/auth/login`;
export const ADMIN_LOGIN_URL = `${API_URL}/api/auth/admin/login`;
export const CHECK_SUBSCRIPTION_URL = `${API_URL}/api/auth/subscription`;
export const BILLING_PORTAL_URL = `${API_URL}/payment/portal`;
export const CREATE_CHECKOUT_SESSION_URL = `${API_URL}/checkout/session/create`;
export const CHECK_SESSION_EXISTENCE_URL = `${API_URL}/checkout/session`;
export const CREATE_UPDATE_PLAN_SESSION_URL = `${API_URL}/checkout/session/update`;
export const loginActionTypes = {
  login: createRequestActionTypes("LOGIN"),
  adminLogin: createRequestActionTypes("ADMIN_LOGIN"),
  retrieveLoginUser: createRequestActionTypes("RETRIEVE_LOGIN_USER"),
  clearErrorMsg: createRequestActionTypes("CLEAR_ERROR_MSG"),
  clearSignoutErrorMsg: createRequestActionTypes("CLEAR_SIGN_OUT_ERROR_MSG"),
  signout: createRequestActionTypes("SIGN_OUT"),
  saveCredentials: createRequestActionTypes("SAVE_CREDENTIALS"),
  removeCredentials: createRequestActionTypes("REMOVE_CREDENTIALS"),
  clearLoading: createRequestActionTypes("CLEAR_LOADING"),
  setRefreshLoading: createRequestActionTypes("SET_REFRESH_LOADING"),
  setPreviouslyExportIds: createRequestActionTypes("SET_PREVIOUSLY_EXPORT_IDS"),
  clearPreviouslyExportedIds: createRequestActionTypes(
    "CLEAR_PREVIOUS_EXPORTED_ID"
  ),
  generateBillingPortal: createRequestActionTypes("GENERATE_BILLING_PORTAL"),
  checkSubscription: createRequestActionTypes("CHECK_SUBSCRIPTION"),
  resetLoginReducer: createRequestActionTypes("RESET_LOGIN_REDUCER"),
  createCheckoutSession: createRequestActionTypes("CREATE_CHECKOUT_SESSION"),
  checkSessionExistenceAction: createRequestActionTypes(
    "CHECK_CHECKOUT_SESSION_EXISTENCE"
  ),
  createUpdatePlanSesion: createRequestActionTypes(
    "CREATE_UPDATE_PLAN_SESSION"
  ),
};
