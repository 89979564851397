import React from "react";
import youtube from "../img/youtube.svg";
import instagram from "../img/insta_icon.svg";
import tiktok from "../img/tiktok.svg";

const networkImage = (network) => {
  switch (network) {
    case "instagram":
      return (
        <img
          className="social-icon-img instagram"
          src={instagram}
          alt={network}
        />
      );
    case "youtube":
      return <img className="social-icon-img youtube" src={youtube} alt={network} />;
    case "tiktok":
      return <img className="social-icon-img tiktok" src={tiktok} alt={network} />;
    default:
      return (
        <img
          className="social-icon-img instagram"
          src={instagram}
          alt={network}
        />
      );
  }
};

export default networkImage;
