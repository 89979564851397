import React from "react";
import { Trans } from "react-i18next";

export const growthIntervals = [
  { label: <Trans i18nKey="discovery.fields.select_interval" />, value: "" },
  { label: "1 Month", value: "i1month" },
  { label: "2 Months", value: "i2months" },
  { label: "3 Months", value: "i3months" },
  { label: "4 Months", value: "i4months" },
  { label: "5 Months", value: "i5months" },
  { label: "6 Months", value: "i6months" },
];

export const growthRate = [
  { label: <Trans i18nKey="discovery.fields.select_weight" />, value: "" },
  { label: "> 10%", value: 0.1 },
  { label: "> 25%", value: 0.25 },
  { label: "> 50%", value: 0.5 },
  { label: "> 75%", value: 0.75 },
  { label: "> 100%", value: 1 },
  { label: "> 150%", value: 1.5 },
  { label: "> 200%", value: 2 },
  { label: "> 300%", value: 3 },
  { label: "> 400%", value: 4 },
  { label: "> 500%", value: 5 },
];
