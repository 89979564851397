import React from "react";
import { Trans } from "react-i18next";

const accountTypeFilterOptions = [
  {
    type: "Regular",
    text: <Trans i18nKey="discovery.filters.account_type.options.regular" />,
    value: 1,
  },
  {
    type: "Business",
    text: <Trans i18nKey="discovery.filters.account_type.options.business" />,
    value: 2,
  },
  {
    type: "Creator",
    text: <Trans i18nKey="discovery.filters.account_type.options.creator" />,
    value: 3,
  },
];
export default accountTypeFilterOptions;
