const toolTipOptions = [
  {
	  id: "search",
	  value:
		"The most relevant hashtags are colored in dark blue, the least relevant in light blue, and those of average relevance are in intermediate colors. The size of each hashtag is proportional to its popularity.",
	},
	{
	  id: "languageAudience",
	  value: "Identify creators by their audience language. We determine language of audience by analyzing captions of recent posts.",
	},
	{
	  id: "languageInfluencer",
	  value: "Identify creators by their language. We determine language of creators by analyzing captions of recent posts.",
	},
	{
	  id: "locationAudience",
	  value:
		"Identify creators by their audience location. We determine audience location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several audience locations and specify minimum percentage for each location, your search results will be refined with creators that have audience in ANY of the specified locations.",
	},
	{
	  id: "locationInfluencer",
	  value:
		"Identify creators by their location. We determine creator location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several creator locations and your search results will be refined with creators available in ANY of the specified locations. ",
	},
	{
	  id: 'universityInfluencer', value: 'Identify creators by university attendance or public affiliation. We determine creator university association by analyzing location tags, post captions, and text references in profile bios.'
	},
	{
	  id: "interestsAudience",
	  value:
		"Identify creators by their audience interests. We determine interest of audience by analyzing posts for caption text, mentions, hashtags and location tags. You can add several interests and specify minimum percentage for each interest, your search results will be refined with creators that have audience mentioning ANY of the specified interests. ",
	},
	{
	  id: "interestsInfluencer",
	  value:
		"Identify creators by their interests. We determine interest of creators by analyzing posts for caption text, mentions, hashtags and location tags. You can add several interests and your search results will be refined with creators that have ANY of the specified interests.",
	},
	{
	  id: "brandsAudience",
	  value:
		"Identify creators by their audience brand affinities. We determine brand affinities of audience by analyzing posts for caption text, mentions, hashtags and location tags. You can add several brands and specify minimum percentage for each brand, your search results will be refined with creators that have audience mentioning ANY of the specified brands.",
	},
	{
	  id: "brandsInfluencer",
	  value:
		"Identify creators by their brand affinities. We determine brand affinities of creators by analyzing posts for caption text, mentions, hashtags and location tags. You can add several brands and your search results will be refined with creators that have ANY of the specified brands.",
	},
	{
	  id: "genderAudience",
	  value:
		"Identify creators by their audience gender. We determine gender of audience by analyzing profile photos, name and text in profile bio and selfies in recent posts. ",
	},
	{
	  id: "genderInfluencer",
	  value:
		"Identify creators by gender. We determine gender of creators by analyzing profile photos, name and text in profile bio and selfies in recent posts. ",
	},
	{
	  id: "ethnicityAudience",
	  value:
		"Identify creators by their audience ethnicity. We determine ethnicity of audience by analyzing profile photos and selfies in recent posts.",
	},
	{
	  id: "ageAudience",
	  value:
		"Identify creators by their audience age. We determine age of audience by analyzing profile photos and selfies in recent posts",
	},
	{
	  id: 'ageInfluencer',
	  value: 'Identify creators by their age. We determine age of creators by analyzing profile photos and selfies in recent posts',
	},
	{
	  id: 'lookalikesAudience',
	  value: 'Audience lookalikes help you find creators that have a similar audience by returning creators whose audience follows accounts with the same topic as the audience of the specified account.',
	},
	{
	  id: 'lookalikesInfluencer',
	  value: 'Creator lookalikes help you find similar creators by returning creators that post content that is similar to the specified account.',
	},
	{
	  id: 'audienceTypeAudience',
	  value: 'Switch audience filters to only return creators with the specified audience type. Any by default.',
	},
	{
	  id: "lastPost",
	  value: "Identify creator by date of most recently posted content",
	},
	{
	  id: "bio",
	  value: "Identify creators by keywords within their bio description.",
	},
	{
	  id: "engagementInfluencer",
	  value:
		"Identify creators by average number of likes and comments in their recent posts. ",
	},
	{
	  id: "followerRate",
	  value: "Identify creators by their number of followers. ",
	},
	{
	  id: "viewsRate",
	  value: "Identify creators by their number of views.",
	},
	{
	  id: "reelsRate",
	  value: "Identify creators by average number of plays on their recent reels.",
	},
	{
	  id: "engagementRate",
	  value:
		"Identify creators by their engagement rate. ER is average likes of their recent posts divided by their followers.",
	},
	{
	  id: "contacts",
	  value:
		"Refine search results with only influencers that have the specified email or other contact details.",
	},
	{
	  id: "accountType",
	  value:
		"Identify creators by their Instagram account type - Business, Creator or Regular (non business) Instagram account. ",
	},
	{
	  id: "is_verified",
	  value:
		"Refine search results with only creators that have a verified account.",
	},
	{
	  id: "onlyCredibleAccount",
	  value:
		"Refine search results with only creators that have a credibility greater than 90%.",
	},
	{
	  id: "is_hidden",
	  value:
		"Creators that have a private account will be excluded from search results. ",
	},
	{
	  id: "has_audience_data",
	  value:
		"Refine search results with only creators that have an Audience Data report. ",
	},
	{
	  id: "hasContactDetails",
	  value:
		"Refine search results with only creators that have email or other contact details",
	},
	{
	  id: "hasSponseredPosts",
	  value: "Refine search results with only creators with sponsored posts",
	},
	{
	  id: "influencerLocation",
	  value:
		"Get report on creators followers & active audience from specific locations only. You can add several audience locations.",
	},
	{
	  id: "influencerLanguage",
	  value:
		"Get report on creators followers & active audience speaking specific language. You can add several audience languages.",
	},
	{
	  id: "influencerBrands",
	  value:
		"Get report on creator's followers & active audience interested in specific brand. You can add several audience brands.",
	},
	{
	  id: "influencerInterests",
	  value:
		"Get report on creator's followers & active audience on specific interest. You can add several audience interests.",
	},
	{
	  id: "influencerAge",
	  value:
		"Get report on creator's followers & active audience for specific age group.",
	},
	{
	  id: "influencerReachability",
	  value:
		"Get report on creator's followers & active audience with specific number of their followings",
	},
	{
	  id: "influencerCredibility",
	  value:
		"Get report on creator's followers & active audience for specific credibility.",
	},
	{
	  id: "influencerGender",
	  value:
		"Get report on creator's followers & active audience on specific gender.",
	},
	{
	  id: "reportAvgLikes",
	  value: "The average sum of likes on the last 30 posts.",
	},
	{
	  id: "reportAvgViews",
	  value: "The average sum of views on the last 30 posts.",
	},
	{
	  id: "reportAvgComments",
	  value: "The average sum of comments on the last 30 posts.",
	},
	{
	  id: "reportAvgReelsPlays",
	  value: "The average sum of reels plays on the last 30 posts.",
	},
	{
	  id: "paidPostTooltip",
	  value: "Most frequently used hashtags on the last 30 posts.",
	},
	{
	  id: "influencerPriceTooltip",
	  value: "Estimated cost for one sponsored post. This estimation is calculated by a machine learning model.",
	},
	{
	  id:"partnershipInfluencer",value:"Identify creators by having sponsored posts with brands. We detect sponsored posts either by usage of commercial hashtags or official paid partnership tag. You can add several brands and your search results will be refined with creators that have sponsored posts for ANY of the specified brands."
	},
	{
	  id: "trendingAnalysisTooltip",
	  value:
		"A value between -1 and 1 used as a measurement to make future predictions of profile follower growth.",
	},
	{
	  id: "engagementTooltip",
	  value: "Average likes divided by followers",
	},
	{
	  id: "engagementRateModal",
	  value: "Average likes divided by followers",
	},
	{
	  id: "influencerLookAlikes",
	  value:
		"We determine creator lookalikes by analyzing and comparing creator data to help you find creators that have similar metrics.",
	},
	{
	  id: "followersAudienceLookAlikesModal",
	  value:
		"We determine audience lookalikes by analyzing and comparing audience data to help you find creators that have a similar audience..",
	},
	{
	  id: "likesAudienceLookAlikesModal",
	  value:
		"We determine audience lookalikes by analyzing and comparing audience data to help you find creators that have a similar audience..",
	},
	{
	  id: "followersAudienceBrandAffinityModal",
	  value:
		"We determine brand affinities by analyzing posts for caption texts, mentions, hashtags and location tags.",
	},
	{
	  id: "likesAudienceBrandAffinityModal",
	  value:
		"We determine brand affinities by analyzing posts for caption texts, mentions, hashtags and location tags.",
	},
	{
	  id: "likesAudienceInterestsModal",
	  value:
		"We determine interests by analyzing posts for caption texts, @mentions, #hashtags and location tags.",
	},
	{
	  id: "followersAudienceInterestsModal",
	  value:
		"We determine interests by analyzing posts for caption texts, @mentions, #hashtags and location tags.",
	},
	{
	  id: "likesFollowersCredibility",
	  value:
		"To establish the followers credibility score for historical followers we take into account factors such as an account’s avatar and bio description, number of posts, number of accounts followed vs following ratio. Influencers with a genuine audience will attain scores of 80 or above.",
	},
	{
	  id: "followersFollowersCredibility",
	  value:
		"To establish the followers credibility score for historical followers we take into account factors such as an account’s avatar and bio description, number of posts, number of accounts followed vs following ratio. Influencers with a genuine audience will attain scores of 80 or above.",
	},
	{
	  id: "followersNotableFiltersModal",
	  value:
		"Shows what percentage of followers are creators. Notable Followers for a 0.99 quantile can largely differ from 1% to 20%.",
	},
	{
	  id: "notableUsers",
	  value:
		"Shows what percentage of followers are creators. Notable Followers for a 0.99 quantile can largely differ from 1% to 20%.",
	},
	{
	  id: "likesNotableFiltersModal",
	  value: "Top followers of this user with more than 1,000 followers.",
	},
	{
	  id: "likesAudienceReachability",
	  value:
		"Shows percentage of followers that follow more than 1500 accounts, between 1000-1500, 500-1000 and below 500. Accounts following more than 1.5k accounts will most likely not see the sponsored posts.",
	},
	{
	  id: "followersAudienceReachability",
	  value:
		"Shows percentage of followers that follow more than 1500 accounts, between 1000-1500, 500-1000 and below 500. Accounts following more than 1.5k accounts will most likely not see the sponsored posts.",
	},
	{
	  id: "followersAgeAndGenderSplitModal",
	  value:
		"We determine historical followers age by analyzing the profile picture, text in the profile description (bio) and selfies in recent posts. Only available for the audience as an aggregated percentage.",
	},
	{
	  id: "followersAgeAndGenderSplit",
	  value:
		"We determine historical followers age by analyzing the profile picture, text in the profile description (bio) and selfies in recent posts. Only available for the audience as an aggregated percentage.",
	},
	{
	  id: "likesAgeAndGenderSplitModal",
	  value:
		"We determine historical followers age by analyzing the profile picture, text in the profile description (bio) and selfies in recent posts. Only available for the audience as an aggregated percentage.",
	},
	{
	  id: "likesAgeAndGenderSplit",
	  value:
		"We determine historical followers age by analyzing the profile picture, text in the profile description (bio) and selfies in recent posts. Only available for the audience as an aggregated percentage.",
	},
	{
	  id: "likesGenderSplitModal",
	  value:
		"We determine historical followers gender by analyzing the profile picture, name, text in the profile description (bio) and selfies in recent posts.",
	},
	{
	  id: "likesGenderSplit",
	  value:
		"We determine historical followers gender by analyzing the profile picture, name, text in the profile description (bio) and selfies in recent posts.",
	},
	{
	  id: "followersGenderSplit",
	  value:
		"We determine historical followers gender by analyzing the profile picture, name, text in the profile description (bio) and selfies in recent posts.",
	},
	{
	  id: "followersGenderSplitModal",
	  value:
		"We determine historical followers gender by analyzing the profile picture, name, text in the profile description (bio) and selfies in recent posts.",
	},
	{
	  id: "likesLocationByCityModal",
	  value:
		"We determine historical followers location (country, state, city) by analyzing location tags, language and caption of the recent posts and text in profile description (bio).",
	},
	{
	  id: "likesLocationByCity",
	  value:
		"We determine historical followers location (country, state, city) by analyzing location tags, language and caption of the recent posts and text in profile description (bio).",
	},
	{
	  id: "followersLocationByCity",
	  value:
		"We determine historical followers location (country, state, city) by analyzing location tags, language and caption of the recent posts and text in profile description (bio).",
	},
	{
	  id: "followersLocationByCityModal",
	  value:
		"We determine historical followers location (country, state, city) by analyzing location tags, language and caption of the recent posts and text in profile description (bio).",
	},
	{
	  id: "likesLocationByCountryModal",
	  value:
		"We determine historical followers location (country, state, city) by analyzing location tags, language and caption of the recent posts and text in profile description (bio).",
	},
	{
	  id: "followersLocationByCountryModal",
	  value:
		"We determine historical followers location (country, state, city) by analyzing location tags, language and caption of the recent posts and text in profile description (bio).",
	},
	{
	  id: "followersEthnicityModal",
	  value:
		"We determine historical followers ethnicity by analyzing the profile picture, text in the profile description (bio) and selfies in recent posts. Only available for the audience as an aggregated percentage.",
	},
	{
	  id: "likesEthnicityModal",
	  value:
		"We determine historical followers ethnicity by analyzing the profile picture, text in the profile description (bio) and selfies in recent posts. Only available for the audience as an aggregated percentage.",
	},
	{
	  id: "likesLanguageModal",
	  value:
		"We determine historical followers language by analyzing caption of the recent posts.",
	},
	{
	  id: "followersLanguageModal",
	  value:
		"We determine historical followers language by analyzing caption of the recent posts.",
	},
	{
	  id: "popularMentionTooltip",
	  value: "Most frequently used mentions on the last 30 posts.",
	},
	{
	  id: "popularHashtagTooltip",
	  value: "Most frequently used hashtags on the last 30 posts.",
	},
	{
	  id: "creatorsInterestsModal",
	  value:
		"We determine interests by analyzing posts for caption texts, @mentions, #hashtags and location tags.",
	},
	{
	  id: "creatorsBrandAffinityModal",
	  value:
		"We determine brand affinities by analyzing posts for caption texts, mentions, hashtags and location tags.",
	},
	{
		id: "clipboardCopy",
		value: "Copied!",
	},
	{
		id: "tokenGenerate",
		value: "Premium subscription is required to generate an API token.",
	}
];
export default toolTipOptions;
