import React from "react";
import { Button, Input, Label } from "reactstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import ReactSelect, { components } from "react-select";
import { titlelize } from "../actions";

const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};
export const RenderButton = (props) => {
  return (
    <Button {...props} type="button">
      {props.children}
    </Button>
  );
};
export const RenderSlider = (props) => (
  <Slider {...props} className="slider" handle={handle} />
);
export const RenderTextField = ({
  field, // { name, value, onChange, onBlur }
  // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...customProps
}) => {
  delete customProps.form;
  return <Input {...field} {...customProps} type="text" />;
};

export const RenderTextFieldWithoutAutoComplete = ({
  field,
  ...customProps
}) => {
  let currentValue;
  if (customProps.customvalue !== null) {
    currentValue = customProps.customvalue;
  } else {
    currentValue = field.value;
  }

  delete field.value;
  return (
    <Input
      {...field}
      {...customProps}
      type="text"
      autoComplete="off"
      value={currentValue}
    />
  );
};

export const RenderTextFieldWithoutAutoCompleteGlobal = ({
  field,
  ...customProps
}) => {
  // console.log('selectedUser f',field, customProps)
  // let currentValue;
  // if (customProps.customvalue !== null) {
  //   currentValue = customProps.customvalue;
  // } else {
  //   currentValue = field.value;
  // }

  delete field.value;
  return (
    <Input
      {...field}
      {...customProps}
      type="text"
      autoComplete="off"
      value={customProps.customvalue || ""}
      autoFocus
    />
  );
};

export const RenderNumericField = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...customProps
}) => {
  delete customProps.form;
  return (
    <Input
      {...field}
      {...customProps}
      onChange={(input) =>
        form.setFieldValue(
          field.name,
          (input.target &&
            input.target.value &&
            input.target.value.replace(/[^0-9]/, "")) ||
            ""
        )
      }
      type="text"
      autoComplete="off"
    />
  );
};

export const RenderPhoneField = ({
  field, // { name, value, onChange, onBlur }
  // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...customProps
}) => {
  delete customProps.form;
  return <Input {...field} {...customProps} type="tel" autoComplete="tel" />;
};

export const RenderSingleSelect = ({
  field, // { name, value, onChange, onBlur }
  // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...customProps
}) => {
  const customStyles = {
    option: (provided, state) => {
      provided[":hover"] = {
        ...provided[":hover"],
        backgroundColor: "#DEEBFF",
      };
      provided[":active"] = {
        ...provided[":active"],
        backgroundColor: "#B2D4FF !important",
      };

      return {
        ...provided,
        backgroundColor: state.isSelected ? "#DEEBFF" : "#fff",
        color: "#696C6F",
      };
    },
  };
  return (
    <ReactSelect
      {...field}
      {...customProps}
      isMulti={false}
      placeholder={
        customProps.placeholder && titlelize(customProps.placeholder)
      }
      isClearable={false}
      isSearchable={false}
      styles={customStyles}
      backspaceRemovesValue={true}
      deleteRemoves={true}
      menuPlacement="auto"
      // components={{ClearIndicator}}
    />
  );
};
export const RenderSingleSelectWithIcon = ({
  field, // { name, value, onChange, onBlur }
  // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...customProps
}) => {
  const { value } = customProps;
  const customStyles = {
    option: (provided, state) => {
      provided[":active"] = {
        ...provided[":active"],
        backgroundColor: "#B2D4FF",
      };
      // provided[":hover"] = { ...provided[":hover"], backgroundColor: '#DEEBFF' }
      return {
        ...provided,
        backgroundColor: state.isSelected ? "#DEEBFF" : "#fff",
        color: "#696C6F",
      };
    },
  };
  const optionRenderer = (props) => {
    return (
      <div className="input-select__icon" {...props.innerProps}>
        <span
          className={`p-2 react-select-single-select-with-icon-options ${
            props.isSelected
              ? "react-select-single-select-with-icon-isSelected"
              : ""
          }`}
        >
          {(props.data && props.data.icon) || ""}{" "}
          <span>{props.data.label}</span>
        </span>
      </div>
    );
  };
  const customSingleValue = (props) => {
    const data = props.data || null;

    return (
      <div className="input-select">
        <div className="input-select__single-value">
          {data && data.icon ? (
            <span className="input-select__icon">{data.icon}</span>
          ) : (
            ""
          )}
          <span>{data.label}</span>
        </div>
      </div>
    );
  };

  // console.log('customProps', customProps, options, value )
  return (
    <ReactSelect
      {...field}
      {...customProps}
      isMulti={false}
      placeholder={
        customProps.placeholder && titlelize(customProps.placeholder)
      }
      isClearable={false}
      isSearchable={false}
      styles={customStyles}
      backspaceRemovesValue={true}
      isOptionDisabled={(options) => options.value === value.value}
      deleteRemoves={true}
      components={{ SingleValue: customSingleValue, Option: optionRenderer }}
    />
  );
};

export const RenderSearchableSingleSelect = ({
  field, // { name, value, onChange, onBlur, replaceComponents }
  ...customProps
}) => {
  const customStyles = {
    option: (provided, state) => {
      provided[":hover"] = {
        ...provided[":hover"],
        backgroundColor: "#DEEBFF",
      };
      provided[":active"] = {
        ...provided[":active"],
        backgroundColor: "#B2D4FF !important",
      };

      return {
        ...provided,

        backgroundColor: state.isSelected ? "#DEEBFF" : "#fff",
        color: "#696C6F",
      };
    },
  };
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className="fa fa-search" />
        </components.DropdownIndicator>
      )
    );
  };
  const ClearIndicator = (props) => {
    return null;
  };

  let customComponents = {
    ClearIndicator,
    DropdownIndicator,
    ...customProps?.replaceComponents,
  };

  return (
    <ReactSelect
      {...field}
      {...customProps}
      isMulti={false}
      placeholder={
        customProps.placeholder && titlelize(customProps.placeholder)
      }
      isClearable={true}
      isSearchable={true}
      styles={customStyles}
      backspaceRemovesValue={true}
      deleteRemoves={true}
      components={customComponents}
    />
  );
};

export const RenderMutiSelect = ({ field, ...customProps }) => {
  const MultiValue = ({ children, ...props }) => {
    return (
      <components.MultiValue {...props}>
        <components.MultiValueContainer {...props}>
          <components.MultiValueLabel>
            {!props.selectProps.menuIsOpen && ""}
          </components.MultiValueLabel>
        </components.MultiValueContainer>
      </components.MultiValue>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator &&
      !customProps.noSearchIcon && (
        <components.DropdownIndicator {...props}>
          <i className="fa fa-search" />
        </components.DropdownIndicator>
      )
    );
  };
  const Comps = { MultiValue, DropdownIndicator };
  return (
    <ReactSelect
      {...field}
      {...customProps}
      isMulti={true}
      autosize={false}
      isClearable={false}
      styles={{
        multiValueRemove: (base) => ({
          ...base,
          display: "none",
        }),
      }}
      isSearchable={true}
      components={Comps}
    />
  );
};

export const LazyImage = ({ src, alt,...props }) => {
  return <img {...props} loading="lazy" src={src} alt={alt}></img>;
};

export const SubmitButton = ({ ...props }) => {
  return (
    <Button {...props} type="submit" color={props.color || "primary"}>
      {props.children}
    </Button>
  );
};
export const RenderPasswordField = ({
  field, // { name, value, onChange, onBlur }
  ...customProps
}) => {
  delete customProps.form;
  return (
    <Input
      {...field}
      {...customProps}
      autoComplete="username password"
      type="password"
    />
  );
};

export const RenderTextArea = ({
  field, // { name, value, onChange, onBlur }
  ...customProps
}) => {
  delete customProps.form;
  return <Input {...field} {...customProps} type="textarea" />;
};
export const RenderRadioButton = ({
  field, // { name, value, onChange, onBlur }
  ...props
}) => {
  return <Input {...field} {...props} type="radio" />;
};
export const RenderLabel = ({ ...props }) => {
  return <Label {...props}>{props.children}</Label>;
};
export const RenderCheckBox = ({
  field, // { name, value, onChange, onBlur }
  ...props
}) => {
  return <Input {...field} {...props} type="checkbox" />;
};
