import { emailMatchActionTypes } from "../constants/constants_email_match";

const initialState = {
  loading: false,
  emailMatchError: '',
  subscriptionError: null,
  emailMatchData: [],
  emailMatchTotal: 0,
  showFileUploading: false,
  showUploadComplete: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case emailMatchActionTypes.showFileUploading.SUCCESS:
      return {
        ...state,
        showFileUploading: true
      };
    case emailMatchActionTypes.hideFileUploading.SUCCESS:
      return {
        ...state,
        showFileUploading: false
      };
    case emailMatchActionTypes.showUploadComplete.SUCCESS:
      return {
        ...state,
        showUploadComplete: true
      };
    case emailMatchActionTypes.hideUploadComplete.SUCCESS:
      return {
        ...state,
        showUploadComplete: false
      };
    case emailMatchActionTypes.readManyEmailMatchs.REQUEST:
      return {
        ...state,
        loading: true
      };
    case emailMatchActionTypes.readManyEmailMatchs.SUCCESS:
      return {
        ...state,
        emailMatchData: payload.results,
        emailMatchTotal: payload.count,
        loading: false,
      };
    case emailMatchActionTypes.readManyEmailMatchs.FAILURE:
      return {
        ...state,
        loading: false,
        emailMatchError: payload,
      };
    case emailMatchActionTypes.createOneEmailMatch.REQUEST:
      return {
        ...state,
        showFileUploading: true,
        showUploadComplete: false,
      };
    case emailMatchActionTypes.createOneEmailMatch.SUCCESS:
      return {
        ...state,
        showFileUploading: false,
        showUploadComplete: true,
        // emailMatchData: payload.result,
        // emailMatchTotal: payload.count,
      };
    case emailMatchActionTypes.createOneEmailMatch.FAILURE:
      return {
        ...state,
        showFileUploading: false,
        showUploadComplete: false,
        emailMatchError: payload,
      };

    case emailMatchActionTypes.readOneDownloadEmailMatch.REQUEST:
      return {
        ...state,
        loading: true
      };
    case emailMatchActionTypes.readOneDownloadEmailMatch.SUCCESS:
      return {
        ...state,
        loading: false,

      };
    case emailMatchActionTypes.readOneDownloadEmailMatch.FAILURE:
      return {
        ...state,
        loading: false,
        emailMatchError: payload,
      };
    case emailMatchActionTypes.setEmailMatchError.SUCCESS:
      return {
        ...state,
        emailMatchError: payload
      };

    default: {
      return state;
    }
  }
};
