import React, { useState, useEffect } from "react";
import { Popover } from "reactstrap";
import { useTranslation } from "react-i18next";
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/20/solid";
import { EngagementTag, FollowerTag } from "components/tags";
import { RenderButton } from "../../../components";
import networkImage from "../../../helpers/networkImage";
import noimg from "../../../img/MaskGroup.png";
import defaultImg from "../../../img/user-circle-outline.svg";
import moment from "moment";

const CreatorListModalRow = (props) => {
  const { t } = useTranslation();

  const { creator, deleteCreator } = props;

  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen((prevState) => !prevState);
  };

  const onDelete = () => {
    togglePopover();
    deleteCreator(creator._id);
  };

  const mapPlatformUrl = () => {
    switch (creator.network) {
      case "instagram":
        return `https://www.instagram.com/${creator.handle}/`;
      case "tiktok":
        return `https://www.tiktok.com/@${creator.user_id}`;
      case "youtube":
        return `https://www.youtube.com/channel/${creator.user_id}`;
    }
  };

  useEffect(() => {
    setPopoverOpen(false);
  }, [setPopoverOpen]);

  return (
    <tr>
      <td className="tb-head d-flex">
        <div className="account-image">
          <img
            src={creator.imageUrl ? creator.imageUrl : noimg}
            alt="profile pic"
            width="50px"
            height="50px"
            className="rounded-circle"
            onError={(e) => (e.target.src = defaultImg)}
          />
          {networkImage(creator.network)}
        </div>

        <div className="d-flex align-items-center">
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={mapPlatformUrl()}
            className="link"
          >
            <h4 className="m-0">{`@${creator.handle}`}</h4>
          </a>
        </div>
      </td>
      <td className="text-left">
        <FollowerTag followers={creator.reach || 0}></FollowerTag>
      </td>
      <td className="mobile-view-past-report">
        {creator.engagements > 0 && (
          <EngagementTag engagements={creator.engagements || 0}></EngagementTag>
        )}
      </td>
      <td className="mobile-view-past-report">
        {moment(creator.created).format("MMMM DD, YYYY")}
      </td>
      <td align="center">
        <RenderButton
          onClick={() => props.handleAnalyze(creator)}
          className="btn btn-outline-primary"
        >
          {window.innerWidth < 676
            ? t("reports.splash.chart.view")
            : t("reports.splash.chart.view_report")}
        </RenderButton>
      </td>
      <td className="mobile-view-past-report">
        <EllipsisVerticalIcon
          className="icon-btn"
          onClick={togglePopover}
          id={`action-popover-${creator._id}`}
          width={20}
          height={20}
          strokeWidth={2}
        />
        <Popover
          isOpen={popoverOpen}
          target={`action-popover-${creator._id}`}
          toggle={togglePopover}
          placement="auto"
        >
          <div className="action-buttons">
            <button className="btn btn-popover" onClick={onDelete}>
              <TrashIcon width={20} height={20} strokeWidth={2}></TrashIcon>
              <span>Delete</span>
            </button>
          </div>
        </Popover>
      </td>
    </tr>
  );
};

export default CreatorListModalRow;
