import React from "react";
import { FormGroup } from "reactstrap";
import { Form, Formik, Field } from "formik";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  SubmitButton,
  RenderTextField,
  RenderPasswordField,
} from "../../components/index";
import { adminLoginAction, ClearSignoutErrorMsg } from "./actions/index";
import { validate } from "./validate";
import { showErrorMessage } from "../../actions";
import { clearErrorMsg } from "./actions/index";
import { toast } from "react-toastify";

import { store } from "../../store";

class AdminLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }
  handleShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  componentDidUpdate() {
    if (this.props.errorMsg) {
      this.form.setFieldValue("password", "", false);
    }
  }

  componentDidMount() {
    document.getElementsByClassName("app")[0].classList.add("auth-wrap");
  }
  componentWillUnmount() {
    toast.dismiss();
    this.props.clearErrorMsg();
    this.props.ClearSignoutErrorMsg();
    document.getElementsByClassName("app")[0].classList.remove("auth-wrap");
  }
  handleEnterKey = (event, values) => {
    if (event.charCode === 13) {
      this.onSubmit(values);
    }
  };
  onSubmit = (values) => {
    let postData = { ...values };

    if (postData.password) {
      postData.password = postData.password.trim();
    }
    if (postData.email) {
      postData.email = postData.email.trim().toLowerCase();
    }
    // postData.captcha_token = this.state.captcha_token;
    this.props.adminLoginAction(postData);
  };
  handleClearErrorMsg = () => {
    this.props.clearErrorMsg();
  };
  handleClearSignoutErrorMsg = () => {
    this.props.ClearSignoutErrorMsg();
  };
  render() {
    return (
      <>
        {this.props.signoutErrorMsg
          ? showErrorMessage(
              store,
              this.props.signoutErrorMsg,
              this.handleClearSignoutErrorMsg,
              5000,
              "signoutErrorMsg"
            )
          : null}
        {this.props.errorMsg
          ? showErrorMessage(
              store,
              this.props.errorMsg,
              this.handleClearErrorMsg,
              5000,
              "errorMsg"
            )
          : // <UnAuthorized errorMsg={this.props.errorMsg} />
            null}
        <div className="pt-40">
          <div className="main-site-content container">
            <div className="user-form-wrap">
              <div className="page-head">
                <h1>Admin Login</h1>
                <p className="sub-text">Sign in now to manage admin tasks.</p>
              </div>
              <Formik
                ref={(form) => (this.form = form)}
                onSubmit={this.onSubmit}
                validate={validate}
                initialValues={this.props.initialValues}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form>
                    <FormGroup>
                      <Field
                        name="email"
                        placeholder="Email"
                        autoComplete="username password"
                        className={errors.email && touched.email ? "error" : ""}
                        component={RenderTextField}
                      />
                      {errors.email && touched.email && (
                        <span className="required">{errors.email}</span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Field
                        name="password"
                        placeholder="Password"
                        onKeyPress={(event) =>
                          this.handleEnterKey(event, values)
                        }
                        className={
                          errors.password && touched.password
                            ? "error has-icon-right"
                            : "has-icon-right"
                        }
                        component={
                          this.state.showPassword
                            ? RenderTextField
                            : RenderPasswordField
                        }
                      />
                      <span
                        onClick={this.handleShowPassword}
                        className="button-showpass"
                      >
                        {this.state.showPassword ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.067"
                            height="20.482"
                            viewBox="0 0 19.067 20.482"
                          >
                            <path
                              id="ic_remove_red_eye_24px"
                              d="M10.533,4.5A10.25,10.25,0,0,0,1,11a10.241,10.241,0,0,0,19.067,0A10.25,10.25,0,0,0,10.533,4.5Zm0,10.833A4.333,4.333,0,1,1,14.867,11,4.335,4.335,0,0,1,10.533,15.333Zm0-6.933a2.6,2.6,0,1,0,2.6,2.6A2.6,2.6,0,0,0,10.533,8.4Z"
                              transform="translate(-1 -0.759)"
                            />
                            <line
                              id="Line_1"
                              data-name="Line 1"
                              x1="11"
                              y2="20"
                              transform="translate(4.033 0.241)"
                              strokeWidth="1"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.067"
                            height="13"
                            viewBox="0 0 19.067 13"
                          >
                            <path
                              id="ic_remove_red_eye_24px"
                              d="M10.533,4.5A10.25,10.25,0,0,0,1,11a10.241,10.241,0,0,0,19.067,0A10.25,10.25,0,0,0,10.533,4.5Zm0,10.833A4.333,4.333,0,1,1,14.867,11,4.335,4.335,0,0,1,10.533,15.333Zm0-6.933a2.6,2.6,0,1,0,2.6,2.6A2.6,2.6,0,0,0,10.533,8.4Z"
                              transform="translate(-1 -4.5)"
                            />
                          </svg>
                        )}
                      </span>
                      {errors.password && touched.password && (
                        <span className="required">{errors.password}</span>
                      )}
                      {/* <div className="forgot-pass-link text-right">
                          <NavLink to='/forgotPassword' className="link">Forgot password?</NavLink>
                        </div> */}
                    </FormGroup>
                    {/* <FormGroup >
                        <HCaptcha ref={input => this.captcha = input} sitekey={CAPTCHA_KEY} onVerify={this.onVerifyCaptcha}></HCaptcha>
                      </FormGroup> */}

                    <FormGroup className="bottom text-center">
                      <SubmitButton className="btn btn-primary">
                        Sign in
                      </SubmitButton>
                      <p className="text-grey">
                        Not admin?
                        <NavLink to="/" className="link-more">
                          Sign in as user
                        </NavLink>
                      </p>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    initialValues: {
      email: "",
      password: "",
    },
    errorMsg: state.login.errorMsg,
    signoutErrorMsg: state.login.signoutErrorMsg,
  };
};
export default connect(mapStateToProps, {
  adminLoginAction,
  ClearSignoutErrorMsg,
  clearErrorMsg,
})(AdminLogin);
