export const contactOptions = [
  { label: "Snapchat", value: "snapchat" },
  { label: "Facebook", value: "facebook" },
  { label: "Twitter", value: "twitter" },
  { label: "Telegram", value: "telegram" },
  { label: "WhatsApp", value: "whatsapp" },
  { label: "LinkedIn", value: "lineid" },
  { label: "VK", value: "vk" },
  { label: "BBM", value: "bbm" },
  { label: "Kik", value: "kik" },
  { label: "WeChat", value: "wechat" },
  { label: "Viber", value: "viber" },
  { label: "Skype", value: "skype" },
  { label: "Tumblr", value: "tumblr" },
  { label: "Twitch", value: "twitchtv" },
  { label: "KaKao", value: "kakao" },
  { label: "Pinterest", value: "pinterest" },
  { label: "Linktree", value: "linktree" },
  { label: "Sarahah", value: "sarahah" },
  { label: "Sayat.me", value: "sayat" },
  { label: "Apple Music", value: "itunes" },
  { label: "Weibo", value: "weibo" },
];

export const defaultOptions = [
  { label: "Email", value: "email" },
  { label: "Phone", value: "phone" },
];
export const instagram = { label: "Instagram", value: "instagram" };
export const youtube = { label: "YouTube", value: "youtube" };
export const tiktok = { label: "TikTok", value: "tiktok" };
