import { toast } from "react-toastify";
import React from "react";
import settings from "settings";
import { loadStripe } from "@stripe/stripe-js";
import toolTipOptions from "../helpers/tooltip";
export const API_URL = process.env.REACT_APP_API_URL;

export const CHECKOUT_APP_EXTERNAL_URL =
  process.env.REACT_APP_CHECKOUT_APP_EXTERNAL_URL;
export const SIGNUP_SUCCESS_MESSAGE = "Please confirm your email address";
export const COMMON_ERROR_MESSAGE = "Something went wrong, please try again";
export const TAGS_ERROR_MSG = "The search term you entered is not valid";
export const CAPTCHA_KEY = "bffbe37c-b98d-4ad1-ad6e-91f0a4e1cbd6";
const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";
const NOT_ENOUGH_CREDITS = "Not enough credits";
const NOT_ENOUGH_POST_ANALYSIS_CREDITS = "Not enough post analysis credits";
const EXPORTING_NOT_INCLUDED = "Exporting not included";

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 204) {
    return response.json();
  } else if (response.status === 204) {
    return true;
  } else if (response.status >= 400 && response.status < 500) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}
export const checkAuthTokenExpiry = (response) => {
  let valid = response;
  if (
    response.message &&
    response.message === "Failed to authenticate token."
  ) {
    let error = new Error(response.message);
    error.message = `You have been logged out. Please login again to continue using ${settings.versionName}`;
    error.statusCode = 401;
    throw error;
  }
  return valid;
};

export const stripePayment = async (response) => {
  if (response.session_id) {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);

    stripe.redirectToCheckout({
      sessionId: response.session_id,
    });
  } else {
    return response;
  }
};
export const showErrorMessage = (
  store,
  message,
  closeFunction = () => {},
  timeout = "none",
  toastId
) => {
  const id =
    (store?.getState().login.checkoutSessionDetails &&
      store?.getState().login.checkoutSessionDetails._id) ||
    null;
  toast.info(
    message === NOT_ENOUGH_CREDITS ||
      message === NOT_ENOUGH_POST_ANALYSIS_CREDITS ||
      message === EXPORTING_NOT_INCLUDED ? (
      <div
        onClick={() => {
          if (id) {
            window.open(`${CHECKOUT_APP_EXTERNAL_URL}/?session=${id}`, "_self");
          }
          toast.dismiss();
        }}
      >
        {message}&nbsp;
        <u className="error-nav"> Click here to upgrade your subscription </u>
      </div>
    ) : (
      message
    ),
    {
      position: "top-right",
      autoClose: 7500,
      hideProgressBar: false,
      closeOnClick: true,
      onClose: function () {
        return closeFunction();
      },
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId,
      icon: ({ theme, type }) => (
        <img
          src={settings.mainbrand}
          width={20}
          className="toast-icon"
          alt="toast-icon"
        />
      ),
    }
  );
};

export const showSuccessMessage = (
  message,
  closeFunction = () => {},
  timeout = "none",
  toastId
) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 7500,
    hideProgressBar: false,
    closeOnClick: true,
    onClose: function () {
      return closeFunction();
    },
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId,
  });
};

export function actionCreator(actionType, data) {
  return {
    type: actionType,
    payload: data,
  };
}
export function createRequestActionTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((requestTypes, type) => {
    requestTypes[type] = `${base}_${type}`;
    return requestTypes;
  }, {});
}

export const createQueryParams = (searchObj) => {
  let queryParams = "?";
  let queryArray = Object.keys(searchObj).map(
    (key, index) => `${key}=${searchObj[key]}`
  );
  queryParams = queryParams + (queryArray.length ? queryArray.join("&") : "");
  return queryParams;
};
export const formatNumbersToCommaSeperated = (number) => {
  return number && Math.round(number).toLocaleString().split(",").join(", ");
};
export const showTooltip = (id) => {
  return (
    (toolTipOptions.find((item) => item.id === id) &&
      toolTipOptions.find((item) => item.id === id).value) ||
    ""
  );
};
export const formatNumbersToUnits = (count) => {
  const COUNT_ABBRS = ["", "k", "M", "B", "T", "P", "E", "Z", "Y"];

  const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
  let result = parseFloat((count / Math.pow(1000, i)).toFixed(1));

  return (result += `${COUNT_ABBRS[i]}`);
};

export const formatNumbersToIntegerUnits = (count) => {
  const COUNT_ABBRS = ["", "k", "M", "B", "T", "P", "E", "Z", "Y"];

  const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
  let result = parseFloat((count / Math.pow(1000, i)).toFixed(0));

  return (result += `${COUNT_ABBRS[i]}`);
};

export const capitalize = (name) => {
  return (
    name &&
    name
      .split(" ")
      .map(
        (word, index) =>
          word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(" ")
  );
};
export const titlelize = (name) => {
  return (
    name &&
    name
      .split(" ")
      .map(
        (word, index) =>
          (index < 1 ? word.slice(0, 1).toUpperCase() : word.slice(0, 1)) +
          word.slice(1).toLowerCase()
      )
      .join(" ")
  );
};
export const formatName = (name) => {
  let formatedName = name && name.replace(/[A-Z]|-|_/, " ");
  if (formatedName) {
    formatedName = capitalize(formatedName);
  }
  return formatedName;
};
export const privateHeaders = (getState, contentType) => {
  let headers = {
    Authorization: getState().login.authToken || getState().signup.authToken,
    "x-domain-referer": process.env.REACT_APP_BRAND,
  };
  if (contentType !== "none") {
    headers["Content-Type"] = contentType || "application/json";
  }
  return headers;
};
export const publicHeaders = {
  "Content-Type": "application/json",
};

export const showTitle = (id, name) =>
  document.getElementById(id) &&
  document.getElementById(id).offsetWidth <
    document.getElementById(id).scrollWidth
    ? capitalize(name)
    : "";
