const calculateDataForFilters = (state) => {
  return {
    audience_geo: state.audienceLocationValues.map((item) => item),
    geo: state.influencerLocationValues.map((item) => item),
    audience_brand: state.audienceBrandsValues.map((item) => item),
    brand: state.influencerBrandsValues.map((item) => item),
    audience_brand_category: state.audienceInterestsValues.map((item) => item),
    brand_category: state.influencerInterestsValues.map((item) => item),
    with_contact: state.contactsValues.map((item) => item),
    audiencesAgeLeft: state.audiencesAgeLeft,
    audiencesAgeRight: state.audiencesAgeRight,
    audiencesAgeWeight: state.audiencesAgeWeight,
    influencersAgeLeft: state.influencersAgeLeft,
    influencersAgeRight: state.influencersAgeRight,
    university: state.universityValue,
    followersFrom: state.followersFrom,
    followersTo: state.followersTo,
    viewsFrom: state.viewsFrom,
    viewsTo: state.viewsTo,
    reelsFrom: state.reelsFrom,
    reelsTo: state.reelsTo,
    engagementsInfluencersFrom: state.engagementsInfluencersFrom,
    engagementsInfluencersTo: state.engagementsInfluencersTo,
    engagementRate: state.engagementRate,
    engagementRateOperator: state.engagementRateOperator,
    accountTypeFilters: state.accountTypeFilters,
    audienceType: state.audienceType,
    otherFilters: state.otherFilters,
    allSelectedFilters: state.allSelectedFilters,
    selectedThreeTableColumns: state.selectedThreeTableColumns,
    relevance: state.relevance,
    platformValue: state.platformValue,
    influencerLookalikeValue: state.influencerLookalikeValue,
  };
};
export default calculateDataForFilters;
