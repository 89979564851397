import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap";
import { RenderButton, RenderSearchableSingleSelect } from "../../components";
import { useTranslation } from "react-i18next";
import { Field } from "formik";
import { showTitle, showTooltip } from "../../actions";
import betaIcon from "../../img/beta_tag.svg";
const UniversityFilter = (props) => {
  const { t } = useTranslation();

  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2"
      isOpen={props.state.filters.university}
      toggle={() => props.toggleFilter("university")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        University{" "}
        <img className="university-beta-icon" src={betaIcon} alt="beta" />
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-md">
        <Row>
          <Col md="12" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span id="universityInfluencer" className="tooltip-icon"></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="universityInfluencer"
              >
                {showTooltip("universityInfluencer")}
              </UncontrolledTooltip>
            )}
            <Field
              component={RenderSearchableSingleSelect}
              name="university"
              options={props.influencerFilteredUniversities}
              onChange={(option) =>
                props.handleSingleSelectChange(option, "universityValue")
              }
              onInputChange={(value) =>
                props.getUniversityBasedOnFilterValue(value)
              }
              placeholder={"Search Universities"}
              value={props.state.universityValue}
            />
            {props.state.universityValue &&
            props.state.universityValue.value ? (
              <Row className="selected-tags-bottom">
                <Col md="12" className="col active-filters-wrap">
                  <div className="active-filters inside">
                    <ul className="list-inline">
                      {props.state.universityValue &&
                        props.state.universityValue.value && (
                          <li>
                            <div className="li-influencer">
                              <p
                                title={showTitle(
                                  `aud_uni_inside_${props.state.universityValue.value}`,
                                  props.state.universityValue.label
                                )}
                                id={`aud_uni_inside_${props.state.universityValue.value}`}
                              >
                                {props.state.universityValue.label}
                              </p>
                              <RenderButton
                                close
                                onClick={() =>
                                  props.removeSingleSelectFilters(
                                    "universityValue",
                                    false
                                  )
                                }
                              />
                            </div>
                          </li>
                        )}
                    </ul>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default UniversityFilter;
