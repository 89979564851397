import React from "react";
import { Container, Modal, ModalHeader, ModalBody } from "reactstrap";
import "./index.scss";
import ReportSection from "../reports";
import { getAccountOverview } from "../actions";
import { connect } from "react-redux";

const CreatorReportModal = ({
  accountOverviewDetails,
  loginUser,
  isOpen,
  ...props
}) => {
  const toggleCreatorReportView = () => {
    props.getAccountOverview(null, true);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleCreatorReportView}
      wrapClassName="creator-report-modal"
      modalClassName="right"
    >
      <ModalHeader toggle={toggleCreatorReportView}></ModalHeader>
      <ModalBody className="confirm-modal">
        <Container className="main-site-content">
          <div className="px-3">
            <ReportSection _blank={true} />
          </div>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default connect(null, {
  getAccountOverview,
})(CreatorReportModal);
