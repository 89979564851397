import React from "react";
import { Card, CardBody, CardTitle, UncontrolledTooltip } from "reactstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { showTooltip } from "../../../../actions";
const chartOptions = (props) => {
  let data =
    props.postAnalysisDetails &&
    props.postAnalysisDetails.audience_likers &&
    props.postAnalysisDetails.audience_likers.data &&
    props.postAnalysisDetails.audience_likers.data.audience_reachability
      ? props.postAnalysisDetails.audience_likers.data.audience_reachability.map(
          (obj, index) => obj
        )
      : [];
  let seriesData = data.map((obj, index) => [
    index === 0
      ? obj.code.replace("-", "<")
      : index === data.length - 1
      ? obj.code.replace("-", ">")
      : obj.code,
    parseFloat((obj.weight * 100).toFixed(2)),
  ]);
  let categories = data.map((obj, index) =>
    index === 0
      ? obj.code.replace("-", "<") + " Followings"
      : index === data.length - 1
      ? obj.code.replace("-", ">") + " Followings"
      : obj.code + " Followings"
  );
  return {
    chart: {
      type: "column",
      height: 200,
      backgroundColor: null,
    },
    title: null,
    xAxis: {
      // visible: true,
      lineWidth: 0,
      type: "category",
      tickColor: "transparent",
      categories: categories,
    },
    yAxis: {
      lineWidth: 0,
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      stackLabels: {
        enabled: props.state.showStackLabels,
        verticalAlign: "top",
        allowOverlap: props.state.showStackLabels,
        formatter: function () {
          return this.total + " %";
        },
      },

      title: {
        text: null,
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    series: [
      {
        color: "#1f87ee",
        borderRadius: 10,
        pointWidth: window.innerWidth < 1024 ? 15 : 20,
        name: "Following",
        data: seriesData,
      },
    ],
    tooltip: {
      enabled: !props.state.showStackLabels,
      formatter: function () {
        return this.total + " %";
      },
    },
    legend: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
  };
};
const LikesAudienceReachability = (props) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>
          <span className="tooltip-button-wrap">
            <span className="text-tooltip">Audience Reachability</span>
            <span
              href="#"
              id="likesAudienceReachability"
              className="tooltip-icon"
            ></span>
          </span>
          <UncontrolledTooltip
            placement="bottom"
            className="tooltip-content"
            target="likesAudienceReachability"
          >
            {showTooltip("likesAudienceReachability")}
          </UncontrolledTooltip>
        </CardTitle>
        <div className="card-content ch-300">
          {!props.postAnalysisDetails ||
          !props.postAnalysisDetails.audience_likers ||
          !props.postAnalysisDetails.audience_likers.data ||
          !props.postAnalysisDetails.audience_likers.data
            .audience_reachability ||
          !props.postAnalysisDetails.audience_likers.data.audience_reachability
            .length ? (
            <span className="text-center text-grey ">
              <p className="pt-50">No Record Found</p>
            </span>
          ) : (
            <div className="graph-wrap">
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions(props)}
              />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default LikesAudienceReachability;
