import React from "react";
import { Trans } from "react-i18next";

const interests = [
  { label: <Trans i18nKey="interests.television_film" />, value: 1 },
  { label: <Trans i18nKey="interests.music" />, value: 3 },
  { label: <Trans i18nKey="interests.shopping_retail" />, value: 7 },
  { label: <Trans i18nKey="interests.coffee_tea_beverages" />, value: 9 },
  { label: <Trans i18nKey="interests.camera_photography" />, value: 11 },
  { label: <Trans i18nKey="interests.clothes_shoes" />, value: 13 },
  { label: <Trans i18nKey="interests.beer_wine" />, value: 19 },
  { label: <Trans i18nKey="interests.sports" />, value: 21 },
  { label: <Trans i18nKey="interests.electronics_computers" />, value: 25 },
  { label: <Trans i18nKey="interests.gaming" />, value: 30 },
  { label: <Trans i18nKey="interests.activewear" />, value: 33 },
  { label: <Trans i18nKey="interests.art_design" />, value: 36 },
  { label: <Trans i18nKey="interests.travel_tourism" />, value: 43 },
  { label: <Trans i18nKey="interests.business_careers" />, value: 58 },
  { label: <Trans i18nKey="interests.beauty_cosmetics" />, value: 80 },
  { label: <Trans i18nKey="interests.healthcare_medicine" />, value: 100 },
  { label: <Trans i18nKey="interests.jewelry_watches" />, value: 130 },
  { label: <Trans i18nKey="interests.restaurants_grocery" />, value: 139 },
  { label: <Trans i18nKey="interests.toys_children" />, value: 190 },
  { label: <Trans i18nKey="interests.fitness_yoga" />, value: 196 },
  { label: <Trans i18nKey="interests.wedding" />, value: 291 },
  { label: <Trans i18nKey="interests.tobacco_smoking" />, value: 405 },
  { label: <Trans i18nKey="interests.pets" />, value: 673 },
  { label: <Trans i18nKey="interests.healthy_lifestyle" />, value: 798 },
  { label: <Trans i18nKey="interests.luxury_goods" />, value: 1500 },
  { label: <Trans i18nKey="interests.furniture_garden" />, value: 1560 },
  { label: <Trans i18nKey="interests.friends_family" />, value: 1708 },
  { label: <Trans i18nKey="interests.cars_motorbikes" />, value: 1826 },
];
export default interests;
