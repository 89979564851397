import {
  actionCreator,
  checkHttpStatus,
  publicHeaders,
  privateHeaders,
  checkAuthTokenExpiry,
  CHECKOUT_APP_EXTERNAL_URL,
} from "../../../actions";
import {
  loginActionTypes,
  LOGIN_URL,
  ADMIN_LOGIN_URL,
  CHECK_SESSION_EXISTENCE_URL,
  BILLING_PORTAL_URL,
  CHECK_SUBSCRIPTION_URL,
  CREATE_CHECKOUT_SESSION_URL,
  CREATE_UPDATE_PLAN_SESSION_URL,
  LOGIN_USER_URL,
} from "../constants";
import history from "../../../history";

export const login = (authData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.login.REQUEST));
    fetch(LOGIN_URL, {
      method: "POST",
      headers: publicHeaders,
      body: JSON.stringify(authData),
    })
      .then(checkHttpStatus)
      .then((response) => {
        if (response.error) {
          let errorMsg = response.error[Object.keys(response.error)[0]];
          dispatch(
            actionCreator(loginActionTypes.login.SUCCESS, {
              errorMsg: errorMsg,
            })
          );
        } else {
          dispatch(actionCreator(loginActionTypes.login.SUCCESS, response));
        }
      })
      .catch((error) => {
        dispatch(actionCreator(loginActionTypes.login.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const retrieveLoginUser = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.retrieveLoginUser.REQUEST));
    fetch(LOGIN_USER_URL, {
      method: "GET",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.success) {
          dispatch(
            actionCreator(loginActionTypes.retrieveLoginUser.SUCCESS, response)
          );
        }
      })
      .catch((error) => {
        dispatch(actionCreator(loginActionTypes.retrieveLoginUser.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const checkSubscription = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.checkSubscription.REQUEST));
    fetch(CHECK_SUBSCRIPTION_URL, {
      method: "GET",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (!response.success) {
          let errorMsg = response.message;
          response.errorMsg = errorMsg;
        } else {
          if (response.subscription && response.subscription === "trial") {
            let isTrial = true;
            dispatch(checkSessionExistence(isTrial));
          }
        }

        dispatch(
          actionCreator(loginActionTypes.checkSubscription.SUCCESS, response)
        );
      })
      .catch((error) => {
        dispatch(actionCreator(loginActionTypes.checkSubscription.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const checkSessionExistence = (isTrial = false) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(loginActionTypes.checkSessionExistenceAction.REQUEST)
    );
    fetch(CHECK_SESSION_EXISTENCE_URL, {
      method: "GET",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (!response.success) {
          dispatch(createCheckoutSession(isTrial));
          dispatch(
            actionCreator(
              loginActionTypes.checkSessionExistenceAction.SUCCESS,
              null
            )
          );
        } else {
          dispatch(
            actionCreator(
              loginActionTypes.checkSessionExistenceAction.SUCCESS,
              response.session
            )
          );

          window.open(
            `${CHECKOUT_APP_EXTERNAL_URL}/?session=${response.session._id}`,
            "_self"
          );
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(loginActionTypes.checkSessionExistenceAction.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const createUpdatePlanSesionAction = (data) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.createUpdatePlanSesion.REQUEST));
    fetch(CREATE_UPDATE_PLAN_SESSION_URL, {
      method: "POST",
      headers: privateHeaders(getState),
      body: JSON.stringify(data),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            loginActionTypes.createUpdatePlanSesion.SUCCESS,
            response
          )
        );
        window.open(
          `${CHECKOUT_APP_EXTERNAL_URL}/?session=${response._id}`,
          "_self"
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(loginActionTypes.createUpdatePlanSesion.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const createCheckoutSession = (
  isNotRedirect = false,
  postData = null
) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.createCheckoutSession.REQUEST));
    fetch(CREATE_CHECKOUT_SESSION_URL, {
      method: "POST",
      headers: privateHeaders(getState),
      body: postData ? JSON.stringify(postData) : "",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response._id) {
          dispatch(
            actionCreator(
              loginActionTypes.createCheckoutSession.SUCCESS,
              response
            )
          );
          if (!isNotRedirect) {
            window.open(
              `${CHECKOUT_APP_EXTERNAL_URL}/?session=${response._id}`,
              "_self"
            );
            //dispatch(signOut(null));
          }
        } else {
          dispatch(
            actionCreator(loginActionTypes.createCheckoutSession.FAILURE)
          );
        }
      })
      .catch((error) => {
        dispatch(actionCreator(loginActionTypes.createCheckoutSession.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const generateBillingPortal = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.generateBillingPortal.REQUEST));
    fetch(BILLING_PORTAL_URL, {
      method: "POST",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (response.success) {
          dispatch(
            actionCreator(
              loginActionTypes.generateBillingPortal.SUCCESS,
              response
            )
          );

          window.open(response.url, "_self");
        } else {
          dispatch(
            actionCreator(loginActionTypes.generateBillingPortal.FAILURE)
          );
        }
      })
      .catch((error) => {
        dispatch(actionCreator(loginActionTypes.generateBillingPortal.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const adminLoginAction = (authData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.login.REQUEST));
    fetch(ADMIN_LOGIN_URL, {
      method: "POST",
      headers: publicHeaders,
      body: JSON.stringify(authData),
    })
      .then(checkHttpStatus)
      .then((response) => {
        if (response.error) {
          let errorMsg = response.error[Object.keys(response.error)[0]];
          dispatch(
            actionCreator(loginActionTypes.adminLogin.SUCCESS, {
              errorMsg: errorMsg,
            })
          );
        } else {
          response.isAdmin = true;
          dispatch(
            actionCreator(loginActionTypes.adminLogin.SUCCESS, response)
          );
          history.push("/admin/dashboard");
        }
      })
      .catch((error) => {
        dispatch(actionCreator(loginActionTypes.adminLogin.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};
export const signOut = (error, isAdmin) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.signout.REQUEST));

    dispatch(
      actionCreator(loginActionTypes.signout.SUCCESS, error && error.message)
    );
    if (isAdmin === true) {
      history.push("/admin");
    }
    dispatch(resetLoginReducer());
    history.push("/");
  };
};

export const resetLoginReducer = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.resetLoginReducer.SUCCESS));
  };
};
export const clearErrorMsg = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.clearErrorMsg.SUCCESS));
  };
};
export const ClearSignoutErrorMsg = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.clearSignoutErrorMsg.SUCCESS));
  };
};
export const saveCredentials = (credentials) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(loginActionTypes.saveCredentials.SUCCESS, credentials)
    );
  };
};
export const removeCredentials = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.removeCredentials.SUCCESS));
  };
};

export const clearLoading = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(loginActionTypes.clearLoading.SUCCESS));
    dispatch(actionCreator(loginActionTypes.setRefreshLoading.REQUEST));
    // dispatch(actionCreator(identificationActionTypes.clearLoading.SUCCESS));
  };
};
// export const setnumberOfTimeUnlockMoreClicked = (numberOfTimeUnlockMoreClicked)=> {
//   return (dispatch, getState) => {
//       dispatch(actionCreator(loginActionTypes.setnumberOfTimeUnlockMoreClicked.SUCCESS,numberOfTimeUnlockMoreClicked));
//   };
// }
// export const setPreviouslyExportIds = (data) => {

//   return (dispatch, getState) => {
//     dispatch(actionCreator(loginActionTypes.setPreviouslyExportIds.SUCCESS, data));
//   };
// }
export const clearPreviouslyExportedIds = () => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(loginActionTypes.clearPreviouslyExportedIds.SUCCESS)
    );
  };
};
