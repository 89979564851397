import { reportsActionTypes } from "../constants";

const initialState = {
  loading: false,
  accountOverviewDetails: null,
  cloudTags: null,
  reportsErrorMessage: null,
  stories: null,
  subscriptionError: null,
  toggleViewExportModal: false,
  isHeader: true,
  availableAccounts: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case reportsActionTypes.getInfluencerProfileDataBySearch.REQUEST:
      return {
        ...state,
        loading: true,
        accountOverviewDetails: null,
      };
    case reportsActionTypes.getInfluencerProfileDataBySearch.SUCCESS:
      return {
        ...state,
        loading: false,
        accountOverviewDetails: payload && payload.success ? payload : null,
        reportsErrorMessage: null,
        toggleViewReportModal: payload.toggleViewReportModal,
      };
    case reportsActionTypes.getInfluencerProfileDataBySearch.FAILURE:
      return {
        ...state,
        loading: false,
        accountOverviewDetails: null,
        toggleViewReportModal: false,
      };
    case reportsActionTypes.toggleViewModal.SUCCESS:
      return {
        ...state,
        toggleViewReportModal: false,
      };

    case reportsActionTypes.getAccountOverview.REQUEST:
      return {
        ...state,
        loading: true,
        accountOverviewDetails: null,
      };
    case reportsActionTypes.getAccountOverview.SUCCESS:
      return {
        ...state,
        loading: false,
        accountOverviewDetails: payload,
      };
    case reportsActionTypes.getAccountOverview.FAILURE:
      return {
        ...state,
        loading: false,
        accountOverviewDetails: null,
      };
    case reportsActionTypes.getCloudTags.REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case reportsActionTypes.getCloudTags.SUCCESS:
      return {
        ...state,
        // loading: false,
        cloudTags: payload,
      };
    case reportsActionTypes.getCloudTags.FAILURE:
      return {
        ...state,
        // loading: false,
        cloudTags: null,
      };
    case reportsActionTypes.showReportsErrorMessage.SUCCESS:
      return {
        ...state,
        accountOverviewDetails: null,
        loading: false,
        reportsErrorMessage: payload,
      };
    case reportsActionTypes.clearReportsErrorMessage.SUCCESS:
      return {
        ...state,
        accountOverviewDetails: null,
        loading: false,
        reportsErrorMessage: null,
      };
    case reportsActionTypes.showLoading.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportsActionTypes.showLoading.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case reportsActionTypes.getStoryAnalysis.REQUEST:
      return {
        ...state,
        loading: true,
        stories: null,
      };
    case reportsActionTypes.getStoryAnalysis.SUCCESS:
      return {
        ...state,
        loading: false,
        stories: payload,
      };
    case reportsActionTypes.getStoryAnalysis.FAILURE:
      return {
        ...state,
        loading: false,
        stories: null,
      };

    case reportsActionTypes.clearSubscriptionError.SUCCESS:
      return {
        ...state,
        subscriptionError: null,
      };
    case reportsActionTypes.showSubscriptionError.SUCCESS:
      return {
        ...state,
        subscriptionError: payload,
      };

    case reportsActionTypes.getAvailableAccounts.SUCCESS:
      return {
        ...state,
        loading: false,
        availableAccounts: payload,
      };

    default: {
      return state;
    }
  }
};
