import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { RenderRadioButton, RenderLabel } from "../../components";
import lastPostFilterOption from "../../helpers/lastPostFilterOption";
import { Field } from "formik";
import { showTooltip } from "../../actions";

const LastPostFilter = (props) => {
  const { t } = useTranslation();

  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2 third"
      isOpen={props.state.filters.lastPost}
      toggle={() => props.toggleFilter("lastPost")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {props.state.platformValue &&
        props.state.platformValue.value === "youtube"
          ? t("discovery.filters.last_post.youtube")
          : t("discovery.filters.last_post.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-md">
        <Row>
          <Col md="12" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span id="lastPost" className="tooltip-icon tool-top"></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="lastPost"
              >
                {showTooltip("lastPost")}
              </UncontrolledTooltip>
            )}
            <ul className="list-inline gender-list">
              {(lastPostFilterOption || []).map((option, index) => (
                <li key={index}>
                  <FormGroup check>
                    <RenderLabel check className="radio-container">
                      {option.text}
                      <Field
                        name="lastPost"
                        component={RenderRadioButton}
                        value={option.value}
                        checked={
                          parseInt(props.state.lastPost) === option.value
                        }
                        onChange={(input) =>
                          props.handleRadioButtonChange(
                            input,
                            "lastPost",
                            option.text
                          )
                        }
                      />
                      <span className="checkmark"></span>
                    </RenderLabel>
                  </FormGroup>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default LastPostFilter;
