import { API_URL, createRequestActionTypes } from "../../../actions";

export const INFLUENCERS_LIST_URL = `${API_URL}/identification`;
export const SEARCH_LOCATIONS_URL = `${API_URL}/locations`;
export const GET_CLOUD_TAGS = `${API_URL}/identification/relevant_tags`;
export const GET_TOPIC_TAGS = `${API_URL}/identification/topic-tags`;
export const SEARCH_UNIVERSITY_URL = `${API_URL}/identification/university/list`;
export const SEARCH_BRANDS_URL = `${API_URL}/identification/brand/list`;
export const SEARCH_INTERESTS_URL = `${API_URL}/identification/interest/list`;

export const CHECK_EXPORTS_REMAINING = `${API_URL}/profile/exports`;
export const POST_EXPORTS_URL = `${API_URL}/identification/new/export`;
export const UNLOCK_MORE_RESULTS_URL = `${API_URL}/identification/more/results`;
export const GET_AUTH_URL = `${API_URL}/api/auth/integrations`;
export const GLOBAL_CREATOR_SEARCH_URL = `${API_URL}/identification/users/global`;
export const GET_AVAILABLE_ACCOUNTS_URL = `${API_URL}/identification/users`;
export const GET_GLOBAL_COUNT = `${API_URL}/identification/global/count`;

export const identificationActionTypes = {
    influencers_list: createRequestActionTypes('GET_INFLUENCERS_LIST'),
    getLocations: createRequestActionTypes('GET_LOCATIONS'),
    getCloudTags: createRequestActionTypes('GET_CLOUD_TAGS'),
    getTopicTags: createRequestActionTypes('GET_TOPIC_TAGS'),
    showLoading: createRequestActionTypes('SHOW_LOADING'),
    showTagsErrorMSG: createRequestActionTypes('SHOW_TAGS_ERROR_MSG'),
    clearTagsErrorMSG: createRequestActionTypes('CLEAR_TAGS_ERROR_MSG'),
    clearExportsErrorMSG: createRequestActionTypes('CLEAR_EXPORTS_ERROR_MSG'),
    clearSavedListFilters: createRequestActionTypes('CLEAR_SAVED_LIST_FILTER'),
    getUniversities: createRequestActionTypes('GET_UNIVERSITIES'),
    checkExportsRemaining: createRequestActionTypes('CHECK_EXPORTS_REMAINING'),
    post_exports_csv: createRequestActionTypes('POST_EXPORTS'),
    dontCallInitialEmptyFiltersAPIForInfluencers: createRequestActionTypes('DONT_CALL_INITIAL_EMPTY_INFLUENCERS_LIST'),
    getFilteredBrands: createRequestActionTypes('GET_FILTERED_BRANDS_OPTIONS'),
    getFilteredLookalikes: createRequestActionTypes('GET_FILTERED_LOOKALIKES_OPTIONS'),
    getFilteredInterests: createRequestActionTypes('GET_FILTERED_INTERESTS_OPTIONS'),
    unlock_more_results: createRequestActionTypes('UNLOCK_MORE_RESULTS'),
    get_auth_integration: createRequestActionTypes('GET_AUTH_INTEGRATION'),
    getAvailableAccountsTotal: createRequestActionTypes('GET_AVAILABLE_ACCOUNTS_TOTAL'),
    getAvailableAccounts: createRequestActionTypes('GET_AVAILABLE_ACCOUNTS')
}
