import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  FormGroup,
  Label,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Field, Formik, Form } from "formik";
import { RenderPhoneField, RenderTextField, SubmitButton } from "components";
import _ from "lodash";

const AccountDetails = ({ profile, updateProfileDetails, ...props }) => {
  const { t } = useTranslation();

  const initialValues = {
    name: profile?.name,
    company: profile?.company,
    email: profile?.email,
    phone: profile?.phone,
  };

  const validateUser = (values) => {
    const errors = {};
    if (!values.name || values.name.trim() === "") {
      errors.name = "This field is required";
    }
    if (!values.phone || values.phone.trim() === "") {
      errors.phone = "This field is required";
    } else if (values.phone && !/^[+()\d\-\s.ext]{9,}$/im.test(values.phone)) {
      errors.phone = "Invalid phone number";
    }
    if (!values.email || values.email.trim() === "") {
      errors.email = "This field is required";
    } else if (
      values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]/i.test(values.email)
    ) {
      errors.email = "Invalid email";
    }
    return errors;
  };


  const handleUserSubmit = (values) => {
    let updatedFields = {};
    for (const field in initialValues) {
      if (!_.isEqual(initialValues[field], values[field])) {
        updatedFields[field] = values[field];
      }
    }
    updateProfileDetails(updatedFields);
  };

  return (
    <div className="profile-settings user-form-wrap">
      <Card className="card-custom">
        <CardBody>
          <CardTitle>Profile</CardTitle>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleUserSubmit}
            validate={validateUser}
          >
            {({ touched, errors }) => (
              <Form>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="name">Name</Label>
                      <Field
                        name="name"
                        placeholder={t("auth.register.form.name")}
                        className={errors.name && touched.name ? "error" : ""}
                        component={RenderTextField}
                      />
                      {errors.name && touched.name && (
                        <span className="required">{errors.name}</span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="company">Company</Label>
                      <Field
                        name="company"
                        placeholder={t("auth.register.form.company")}
                        className={
                          errors.company && touched.company ? "error" : ""
                        }
                        component={RenderTextField}
                      />
                      {errors.company && touched.company && (
                        <span className="required">{errors.company}</span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <Label for="email">Email</Label>
                  <Field
                    name="email"
                    placeholder={t("auth.register.form.email")}
                    className={errors.email && touched.email ? "error" : ""}
                    component={RenderTextField}
                    disabled
                  />

                  {errors.email && touched.email && (
                    <span className="required">{errors.email}</span>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label for="phone">Phone</Label>
                  <Field
                    name="phone"
                    placeholder={t("auth.register.form.phone")}
                    className={errors.phone && touched.phone ? "error" : ""}
                    component={RenderPhoneField}
                  />
                  {errors.phone && touched.phone && (
                    <span className="required">{errors.phone}</span>
                  )}
                </FormGroup>

                <FormGroup className="bottom text-left mb-2">
                  <SubmitButton className="btn btn-primary mb-0">
                    {t("auth.register.save_changes")}
                  </SubmitButton>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
};

export default AccountDetails;
