import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Container,
  InputGroup,
  Badge,
  // Modal,
  // ModalHeader,
  // ModalBody,
  // ModalFooter,
} from "reactstrap";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import Table from "./table";
import { RenderTextFieldWithoutAutoComplete } from "../../components";
import { showErrorMessage } from "../../actions";
import { getTensorLists, addTensorLists, clearTensorListMsg } from "./actions";
import { store } from "../../store";
import InfulencersSectionSearch from "../common/infulenceReport";

const TensorList = function (props) {
  const [searchQuery, setQuery] = useState("");
  const [totalEmails, setTotalEmails] = useState("");
  // const [totalEmailsString, setTotalEmailsString] = useState("");
  const [listType, setListType] = useState({
    label: "Followers",
    value: "followers",
  });

  const {
    getTensorListsAction,
    addTensorListsAction,
    tensorLists,
    clearTensorListMsgAction,
  } = props;

  useEffect(() => {
    getTensorListsAction();
  }, [getTensorListsAction]);

  useEffect(() => {
    setQuery("");
    setTotalEmails("");
    setListType({
      label: "Followers",
      value: "followers",
    });
  }, [tensorLists]);

  const addTensorListsHandler = () => {
    addTensorListsAction({
      username: searchQuery,
      type: listType.value,
      ...(totalEmails && { total_emails: totalEmails }),
    });
  };

  const customId = "tensor-list-error";

  return (
    <>
      {props.tensorListsError
        ? showErrorMessage(
            store,
            props.tensorListsError,
            clearTensorListMsgAction,
            5000,
            customId
          )
        : null}
      <Container className="main-site-content">
        <Row>
          <Col className="is-search-block search-wrap">
            <InfulencersSectionSearch />
          </Col>
        </Row>
        <Row>
          <Col className="is-search-block search-wrap">
            <h1 className="page-title">
              Follower Lists{" "}
              <Badge
                color="success"
                className="badge-soft-success fs--2 ml-2 my-auto"
              >
                $9.99 / 1k followers
              </Badge>
            </h1>

            <p className="sub-text text-grey">
              Mobilize followers by contacting them directly. Extract the entire
              list of followers and their contact details from any public
              Instagram account.
            </p>
            <Formik>
              {() => (
                <Form className="is-searchform">
                  <InputGroup className="search-wrap relevance-dropdown-parent mb-0">
                    <div className="input-checkbox-wrap dropdown">
                      <Field
                        id="searchFormList"
                        name="userNameList"
                        className="col-12"
                        placeholder="Enter account username…"
                        customvalue={searchQuery}
                        onChange={(e) => {
                          setQuery(e.target.value);
                        }}
                        component={RenderTextFieldWithoutAutoComplete}
                      />
                    </div>

                    <Button
                      onClick={() => {
                        addTensorListsHandler();
                      }}
                      disabled={searchQuery === ""}
                      className="search-field-button"
                      color="primary"
                    >
                      Search
                    </Button>
                  </InputGroup>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
        {tensorLists && tensorLists.length > 0 && (
          <Table tensorLists={tensorLists} />
        )}

        {/* <div className="section section-au ptop0 tensor-list">
          <div className="search"></div>
        </div>
        <Modal isOpen={isNoTensorLists} >
                    <ModalHeader >Contact Us</ModalHeader>
                    <ModalBody>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </ModalBody>
                    <ModalFooter>
                        <a href={`mailto:hello@tensorsocial.com`} target="_blank" rel="noopener noreferrer">
                            <Button color="primary">Contact Us</Button>
                        </a>

                    </ModalFooter>
                </Modal> */}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tensorLists: state.tensorLists.tensorLists,
    tensorListsError: state.tensorLists.tensorListsError,
    tensorListsSuccess: state.tensorLists.tensorListsSuccess,
    subscriptionDetails: state.login.subscriptionDetails,
  };
};
export default connect(mapStateToProps, {
  getTensorListsAction: getTensorLists,
  addTensorListsAction: addTensorLists,
  clearTensorListMsgAction: clearTensorListMsg,
})(TensorList);
