import React from "react";
import { Trans } from "react-i18next";

const tableColumns = [
  { width: "25%", text: "", value: "#", sort: false, addAction: false },
  {
    width: "20%",
    text: <Trans i18nKey="discovery.filters.followers.title" />,
    value: "followers",
    sort: true,
    addAction: false,
  },
  {
    width: "25%",
    text: <Trans i18nKey="discovery.filters.engagements.title" />,
    value: "engagements",
    sort: true,
    addAction: false,
  },
  { width: "20%", text: "", value: "", sort: false, addAction: true },
];
export default tableColumns;
