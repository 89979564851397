/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import settings from "settings";
import { Row, Col, Modal, ModalHeader, ModalBody, Badge, Form } from "reactstrap";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import EmailMatchResultsModalTable from "./emailMatchResultsModalTable";
import { formatNumbersToCommaSeperated } from "../../../actions/index";
// import { deleteCreator } from "../actions";
import { getInfluencerProfileDataBySearch } from "../../reports/actions/index";
import {
  saveListSearchFilters,
} from "../../userProfile/actions/index";
import {
  dontCallInitialEmptyFiltersAPIForInfluencers,
} from "../../identification/actions/index";
import noimg from "../../../img/MaskGroup.png";
import defaultImg from "../../../img/user-circle-outline.svg";
import { connect } from "react-redux";
import "../index.scss";
import { RenderButton } from "components";
import moment from "moment";
import { Formik } from "formik";
import CreatorListModal from "modules/identification/modals/creatorListModal";
import history from "../../../history";

const EmailMatchResultsModal = (props) => {

  const [matchedEmailResults, setMatchedEmailResults] = useState([]);
  // const [notMatchedEmailResults, setNotMatchedEmailResults] = useState([]);
  const [isCreatorListModalOpen, setIsCreatorListModalOpen] = useState(false);
  const [formattedCreatorLists, setFormattedCreatorLists] = useState([]);

  const handleAnalyze = (creator) => {
    let searchObj = {
      username: creator.handle || creator.user_id,
      platform: creator.network || creator.type,
    };

    const newTab = window.open('/loading', '_blank');
    props.getInfluencerProfileDataBySearch(
      searchObj,
      null,
      props.loginUser && props.loginUser.id,
      false,
      newTab
    );
  };

  const handleSearch = () => {
    const searchObj = {
      "data": {
        "emailMatch_id": props?.emailMatchRecord?._id,
        "audience_geo": [],
        "geo": [],
        "audience_brand": [],
        "brand": [],
        "audience_brand_category": [],
        "brand_category": [],
        "with_contact": [],
        "audiencesAgeLeft": null,
        "audiencesAgeRight": null,
        "audiencesAgeWeight": {
          "label": "> 10%",
          "value": 0.1
        },
        "influencersAgeLeft": null,
        "influencersAgeRight": null,
        "university": null,
        "followersFrom": null,
        "followersTo": null,
        "viewsFrom": null,
        "viewsTo": null,
        "engagementsInfluencersFrom": null,
        "engagementsInfluencersTo": null,
        "engagementRate": null,
        "engagementRateOperator": {
          "label": "Greater than or equal",
          "value": "gte"
        },
        "accountTypeFilters": {
          "Regular": false,
          "Business": false,
          "Creator": false
        },
        "audienceType": null,
        "otherFilters": {
          "is_verified": false,
          "onlyCredibleAccount": false,
          "is_hidden": false,
          "has_audience_data": false,
          "hasContactDetails": false,
          "hasSponseredPosts": false
        },
        "allSelectedFilters": [],
        "selectedThreeTableColumns": [],
        "relevance": [],
        "platformValue": {
          "label": "Instagram",
          "value": "instagram",
          "icon": {
            "type": "img",
            "key": null,
            "ref": null,
            "props": {
              "className": "social-icon-img instagram",
              "src": "/static/media/insta_icon.1c2d37a5.svg",
              "alt": "instagram"
            },
            "_owner": null
          }
        },
        "influencerLookalikeValue": ""
      },
      "filters": {
        "search": {
          "account_type": [],
          "relevance": {
            "value": "cosmetics",
            "weight": 0
          },
          "text": "",
          "audience_relevance": {
            "value": ""
          },
          "followers": {
            "left_number": 100000,
            "right_number": null
          }
        },
        "sort": {
          "field": "followers",
          "direction": "desc"
        }
      }
    };
    props.saveListSearchFilters(searchObj);
    props.dontCallInitialEmptyFiltersAPIForInfluencers();
    history.push("/discovery");
  }

  const toggleCreatorListModal = () => {
    if (!isCreatorListModalOpen) props.getCreatorLists();
    setIsCreatorListModalOpen(!isCreatorListModalOpen);
  };

  const addCreatorListHandler = (listName) => {
    props.addCreatorList({
      name: listName,
      creators: formatCreatorsForList(matchedEmailResults),
    });
  };

  const updateCreatorListHandler = (listId) => {
    props.updateCreatorList({
      _id: listId,
      creators: formatCreatorsForList(matchedEmailResults),
    });
  };

  const formatCreatorListDropdownOptions = () => {
    return props.creatorLists?.map((list) => ({
      label: list.name,
      value: list._id,
    }));
  }

  const formatCreatorsForList = (creators) => {
    return creators.map(creator => {
      return {
        reach: creator?.followers,
        engagements: creator?.engagements,
        network: creator?.type,
        imageUrl: creator?.picture,
        handle: creator?.username,
        user_id: creator?.user_id,
      };
    })
  }

  const formatEmailResults = () => {
    let results = {
      matchedEmails: [],
      notMatchedEmails: []
    };
    Object.keys(results).forEach(resultType => {
      if (props?.emailMatchRecord?._raw?.[resultType]) {
        for (const [key, value] of Object.entries(props?.emailMatchRecord?._raw?.[resultType])) {
          const formattedResults = value.map(result => {
            result.email = key;
            return result;
          });
          results[resultType] = [...results[resultType], ...formattedResults]
        }
      }
    });
    setMatchedEmailResults(results.matchedEmails);
    // setNotMatchedEmailResults(results.notMatchedEmails)
  }

  useEffect(() => {
    const formattedLists = formatCreatorListDropdownOptions();
    setFormattedCreatorLists(formattedLists);
  }, [props.creatorLists]);

  useEffect(() => {
    formatEmailResults();
  }, [props.emailMatchRecord]);

  useEffect(() => {
    setIsCreatorListModalOpen(props.dismissCreatorListModal);
  }, [props.dismissCreatorListModal]);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleModal}
      wrapClassName="email-match-results-modal"
      modalClassName="right"
    >
      <ModalHeader toggle={props.toggleModal}>
        <span className="export-icon mr-3">
          <EnvelopeIcon width={25} height={25} />
        </span>
        {props.emailMatchRecord?.name}
        <span className="ml-3 mr-1 text-grey date-title">
          {moment(props.emailMatchRecord?.created).format("MMMM DD, YYYY")}
        </span>
        <span className="ml-3">
          {props?.emailMatchRecord?.status === "finished" && (
            <Badge className="badge-soft-success">Processed</Badge>
          )}
          {props?.emailMatchRecord?.status === "error" && (
            <Badge className="badge-soft-danger">Error</Badge>
          )}
          {props?.emailMatchRecord?.status === "processing" && (
            <Badge className="badge-soft-warning">
              {props?.emailMatchRecord?.paid ? "Processing" : "Unpaid"}
            </Badge>
          )}
        </span>
      </ModalHeader>
      <ModalBody className="confirm-modal">
        <Row className="my-3 ml-2 d-flex align-items-center">
          <Col md="6 creators">
            <div className="account-images">
              {matchedEmailResults.slice(0, 3).map((influencer) => (
                <div
                  className="account-image"
                  key={influencer._id || influencer.user_id}
                >
                  <img
                    src={influencer.picture ? influencer.picture : noimg}
                    alt="profile pic"
                    width="45px"
                    height="45px"
                    className="rounded-circle"
                    onError={(e) => (e.target.src = defaultImg)}
                  />
                </div>
              ))}
            </div>
            <div className="creators-count">
              {props?.emailMatchRecord?.status === "finished" ? (
                <>
                  <span className="mx-3">
                    {`${formatNumbersToCommaSeperated(
                      matchedEmailResults?.length
                    )} Creators`}
                  </span>{" "}
                  |
                  <span className="mx-3">
                    {`${formatNumbersToCommaSeperated(
                      props.emailMatchRecord?.matched
                    )} Emails Matched`}
                  </span>{" "}
                  |
                </>
              ) : null}
              <span className="mx-3">
                {`${formatNumbersToCommaSeperated(
                  props.emailMatchRecord?.totalEmails
                )} Emails Uploaded`}
              </span>
            </div>
          </Col>
          <Col md="6 d-flex justify-content-end">
            {props?.emailMatchRecord?.status === "finished" &&
              props?.emailMatchRecord?.downloadURL && (
                <>
                  <RenderButton
                    className="btn btn-outline-primary row-item-button"
                    onClick={() => {
                      props.handleDownloadMatches(props?.emailMatchRecord?._id);
                    }}
                  >
                    {window.innerWidth < 676 ? "Export" : "Export Matches"}
                  </RenderButton>
                  <RenderButton
                    className="btn btn-outline-primary row-item-button"
                    onClick={() => {
                      handleSearch(props?.emailMatchRecord?._id);
                    }}
                  >
                    {window.innerWidth < 676 ? "Search" : "Search in Discovery"}
                  </RenderButton>
                </>
              )}
            {props?.emailMatchRecord?.status === "finished" &&
              props?.emailMatchRecord?.downloadURL &&
              (settings.pages.creator_lists.enabled ? (
                <Formik>
                  {() => (
                    <Form>
                      {/* CreatorListModal must be inside a Formik Form */}
                      <RenderButton
                        className="btn btn-outline-primary row-item-button"
                        onClick={toggleCreatorListModal}
                      >
                        Add all to List
                      </RenderButton>
                      <CreatorListModal
                        isOpen={isCreatorListModalOpen}
                        toggleModal={toggleCreatorListModal}
                        selectedUsers={formatCreatorsForList(
                          matchedEmailResults
                        )}
                        creatorLists={formattedCreatorLists}
                        addCreatorListHandler={addCreatorListHandler}
                        updateCreatorListHandler={updateCreatorListHandler}
                      />
                    </Form>
                  )}
                </Formik>
              ) : null)}
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <EmailMatchResultsModalTable
              creators={matchedEmailResults}
              handleAnalyze={handleAnalyze}
              isCreatorListModalOpen={isCreatorListModalOpen}
              toggleCreatorListModal={toggleCreatorListModal}
              formatCreatorsForList={formatCreatorsForList}
              creatorLists={formattedCreatorLists}
              addCreatorListHandler={addCreatorListHandler}
              updateCreatorListHandler={updateCreatorListHandler}
            />
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default connect(null, {
  getInfluencerProfileDataBySearch,
  saveListSearchFilters,
  dontCallInitialEmptyFiltersAPIForInfluencers,
})(EmailMatchResultsModal);
