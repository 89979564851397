import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { MapPinIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import {
  capitalize,
  formatNumbersToCommaSeperated,
  showTitle,
  showTooltip,
} from "../../../../actions";
import useDarkMode from "use-dark-mode";
import { LazyImage, RenderButton } from "../../../../components";
import mainbrand from "../../../../img/LOGO_TS-1.svg";
import ReactMapGL, { Marker, Popup, WebMercatorViewport } from "react-map-gl";

const applyToArray = (func, array) => func.apply(Math, array);

const getBounds = (markers) => {
  const lat = markers.map((marker) => marker.latitude);
  const lng = markers.map((marker) => marker.longitude);

  const cornersLngLat = [
    [applyToArray(Math.min, lng), applyToArray(Math.min, lat)],
    [applyToArray(Math.max, lng), applyToArray(Math.max, lat)],
  ];

  const viewportDimension = new WebMercatorViewport({
    width: 800,
    height: 600,
  }).fitBounds(cornersLngLat, { padding: 150 });

  const { longitude, latitude, zoom } = viewportDimension;
  return { longitude, latitude, zoom };
};

const LikesLocationByCity = (props) => {
  const { t } = useTranslation();

  let data =
    props.accountOverviewDetails &&
    props.accountOverviewDetails.audience_likers &&
    props.accountOverviewDetails.audience_likers.data &&
    props.accountOverviewDetails.audience_likers.data.audience_geo &&
    props.accountOverviewDetails.audience_likers.data.audience_geo.cities
      ? props.accountOverviewDetails.audience_likers.data.audience_geo.cities
          .map((city, index) => ({
            name: city.name,
            weight: (city.weight * 100).toFixed(2) + "%",
            latitude: city.coords.lat,
            longitude: city.coords.lon,
          }))
          .slice(0, 10)
      : [];

  const bounds = getBounds(data);

  const [viewport, setViewport] = useState({
    latitude: bounds.latitude,
    longitude: bounds.longitude,
    zoom: bounds.zoom,
  });

  const darkMode = useDarkMode(false);
  const [map, setMap] = useState(null);
  useEffect(() => {
    setMap(
      darkMode.value
        ? `mapbox://styles/robert-tensor/ckjl159um0gvp19o6xxh1tso1`
        : `mapbox://styles/robert-tensor/ckjkxlxoh0dp619sd4ssel3cv`
    );
  }, [darkMode, map]);

  const [popupInfo, setPopupInfo] = useState(null);
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>
          <span className="tooltip-button-wrap">
            <span className="text-tooltip">
              {t("reports.audience_details.location.city")}
            </span>
            <span
              href="#"
              id="followersLocationByCity"
              className="tooltip-icon"
            ></span>
          </span>
          {props.tooltip && (
            <UncontrolledTooltip
              placement="bottom"
              className="tooltip-content"
              target="followersLocationByCity"
            >
              {showTooltip("likesLocationByCity")}
            </UncontrolledTooltip>
          )}
        </CardTitle>
        {props.accountOverviewDetails &&
        props.accountOverviewDetails.audience_likers &&
        props.accountOverviewDetails.audience_likers.data &&
        props.accountOverviewDetails.audience_likers.data.audience_geo &&
        props.accountOverviewDetails.audience_likers.data.audience_geo
          .cities ? (
          <Row className="row-xs">
            <Col sm="6" md="8">
              <div className="graph-wrap text-left mapHeight">
                <div className="mapContainer">
                  <ReactMapGL
                    {...viewport}
                    width="100%"
                    height="100%"
                    mapStyle={map}
                    onViewportChange={(nextViewport) =>
                      setViewport(nextViewport)
                    }
                  >
                    {data.map((city, index) => {
                      return (
                        <Marker
                          key={`marker-${index}`}
                          longitude={city.longitude}
                          latitude={city.latitude}
                        >
                          <MapPinIcon
                            height={25}
                            color="#1f87ee"
                            style={{
                              cursor: "pointer",
                              transform: `translate(${-25 / 2}px,-25px)`,
                            }}
                            onClick={() => setPopupInfo(city)}
                          />
                        </Marker>
                      );
                    })}
                    {popupInfo && (
                      <Popup
                        tipSize={15}
                        anchor="top"
                        longitude={popupInfo.longitude}
                        latitude={popupInfo.latitude}
                        closeOnClick={false}
                        onClose={() => setPopupInfo(null)}
                      >
                        <div>
                          <Row>
                            <Col className="text-center">
                              <img
                                src={mainbrand}
                                className="popup_logo"
                                alt="homepage"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {popupInfo.name} | {popupInfo.weight}
                            </Col>
                          </Row>
                        </div>
                      </Popup>
                    )}
                  </ReactMapGL>
                </div>
              </div>
            </Col>

            <Col sm="6" md="4">
              <ListGroup flush className="country-list">
                {props.accountOverviewDetails &&
                props.accountOverviewDetails.audience_likers &&
                props.accountOverviewDetails.audience_likers.data &&
                props.accountOverviewDetails.audience_likers.data
                  .audience_geo &&
                props.accountOverviewDetails.audience_likers.data.audience_geo
                  .cities
                  ? props.accountOverviewDetails.audience_likers.data.audience_geo.cities.map(
                      (city, index) => {
                        if (index < 5) {
                          return (
                            <ListGroupItem key={index}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="country-text-wrap">
                                  <div className="flag-img">
                                    <LazyImage
                                      src={props.generateImgUrl(
                                        "country",
                                        city.country && city.country.code
                                      )}
                                      alt="img"
                                    />
                                  </div>
                                  <div
                                    id={`likes_${city.name}`}
                                    title={showTitle(
                                      `likes_${city.name}`,
                                      city.name
                                    )}
                                    className="country-name"
                                  >
                                    {capitalize(city.name)}
                                  </div>
                                </div>
                                <Badge className="text-grey" color="">
                                  {(city.weight * 100).toFixed(2) + "%"}
                                </Badge>
                              </div>
                            </ListGroupItem>
                          );
                        }
                        return null;
                      }
                    )
                  : null}
              </ListGroup>

              <div className="bottom">
                <Button
                  color="link"
                  onClick={() => props.toggleModal("likesLocationByCityModal")}
                >
                  {t("reports.view_more")}
                </Button>

                <Modal
                  id="likesLocationByCityModalDownload"
                  isOpen={props.modals.likesLocationByCityModal}
                  toggle={() => props.toggleModal("likesLocationByCityModal")}
                >
                  <ModalHeader
                    toggle={() => props.toggleModal("likesLocationByCityModal")}
                  >
                    <span className="tooltip-button-wrap">
                      <span className="text-tooltip">
                        {t("reports.audience_details.location.city")}
                      </span>
                      <span
                        href="#"
                        id="likesLocationByCityModal"
                        className="tooltip-icon"
                      ></span>
                    </span>
                    {props.tooltip && (
                      <UncontrolledTooltip
                        placement="bottom"
                        className="tooltip-content"
                        target="likesLocationByCityModal"
                      >
                        {showTooltip("likesLocationByCityModal")}
                      </UncontrolledTooltip>
                    )}
                    <RenderButton
                      onClick={() =>
                        props.downloadPdf("likesLocationByCityModalDownload")
                      }
                      className="download-icon-button mg-left-15 link"
                    >
                      {t("reports.download")}
                    </RenderButton>
                  </ModalHeader>
                  <ModalBody>
                    <Table className="modal-table">
                      <thead>
                        <tr>
                          <th>{t("reports.chart.city")}</th>
                          <th className="text-right">
                            {t("reports.chart.followers")}
                          </th>
                          <th className="text-right">
                            {t("reports.chart.likes")}
                          </th>
                          <th className="text-right">
                            {t("reports.chart.percentage")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.accountOverviewDetails &&
                        props.accountOverviewDetails.audience_likers &&
                        props.accountOverviewDetails.audience_likers.data &&
                        props.accountOverviewDetails.audience_likers.data
                          .audience_geo &&
                        props.accountOverviewDetails.audience_likers.data
                          .audience_geo.cities ? (
                          props.accountOverviewDetails.audience_likers.data.audience_geo.cities.map(
                            (city, index) => (
                              <tr key={index}>
                                <th scope="row">
                                  <div className="country-text-wrap">
                                    <div className="flag-img">
                                      {
                                        <img
                                          src={props.generateImgUrl(
                                            "country",
                                            city.country && city.country.code
                                          )}
                                          alt="img"
                                        />
                                      }
                                    </div>
                                    <div className="country-name">
                                      {capitalize(city.name)}
                                    </div>
                                  </div>
                                </th>
                                <td className="text-right">
                                  {formatNumbersToCommaSeperated(
                                    city.weight *
                                      props.accountOverviewDetails.user_profile
                                        .followers
                                  )}
                                </td>
                                <td className="text-right">
                                  {formatNumbersToCommaSeperated(
                                    city.weight *
                                      props.accountOverviewDetails.user_profile
                                        .avg_likes
                                  )}
                                </td>
                                <td className="text-right">
                                  {(city.weight * 100).toFixed(2) + "%"}
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td align="center" colSpan="4">
                              {t("errors.no_record")}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </ModalBody>
                </Modal>
              </div>
            </Col>
          </Row>
        ) : (
          <span className="text-center text-grey ">
            <p className="pt-50">{t("errors.no_record")}</p>
          </span>
        )}
      </CardBody>
    </Card>
  );
};

export default LikesLocationByCity;
