import { creatorTrackingActionTypes } from "../constants";

const initialState = {
  loading: false,
  loadingTrackedCreators: false,
  trackedCreatorsError: "",
  trackedCreators: [],
  filteredCreatorOptions: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case creatorTrackingActionTypes.getTrackedCreators.REQUEST:
      return {
        ...state,
        loading: true,
        loadingTrackedCreators: true,
      };
    case creatorTrackingActionTypes.getTrackedCreators.SUCCESS:
      return {
        ...state,
        loading: false,
        trackedCreators: payload,
        loadingTrackedCreators: false,
      };
    case creatorTrackingActionTypes.getTrackedCreators.FAILURE:
      return {
        ...state,
        loading: false,
        loadingTrackedCreators: false,
        trackedCreators: null,
      };
    case creatorTrackingActionTypes.createTrackedCreator.REQUEST:
      return {
        ...state,
        loadingTrackedCreators: true,
        loading: true,
      };
    case creatorTrackingActionTypes.createTrackedCreator.SUCCESS:
      return {
        ...state,
        loading: false,
        loadingTrackedCreators: false,
        trackedCreators: [],
      };
    case creatorTrackingActionTypes.createTrackedCreator.FAILURE:
      return {
        ...state,
        loadingTrackedCreators: false,
        loading: false,
      };
    case creatorTrackingActionTypes.getFilteredCreatorOptions.REQUEST:
      return {
        ...state,
      };
    case creatorTrackingActionTypes.getFilteredCreatorOptions.SUCCESS:
      return {
        ...state,
        filteredCreatorOptions: payload,
      };
    case creatorTrackingActionTypes.getFilteredCreatorOptions.FAILURE:
      return {
        ...state,
        filteredCreatorOptions: null,
      };
    default: {
      return state;
    }
  }
};
