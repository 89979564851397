import React from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Button,
  Badge,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip
} from 'reactstrap';
import { capitalize, formatNumbersToCommaSeperated, showTitle } from '../../../../actions';
import { RenderButton } from '../../../../components';
const LikesEthnicity = (props) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>Ethnicity</CardTitle>
        <div className="card-content ch-10">
          {props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_ethnicities && props.postAnalysisDetails.audience_likers.data.audience_ethnicities.length
            ?
            <ListGroup flush className="country-list with-progress">
              {props.postAnalysisDetails.audience_likers.data.audience_ethnicities.map((ethnicity, index) => {
                if (index < 3) {
                  return <ListGroupItem key={index}>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="country-text-wrap">
                        <div id={`likes_${ethnicity.name}`} title={showTitle(`likes_${ethnicity.name}`, ethnicity.name)} className="country-name">{capitalize(ethnicity.name)}
                        </div>
                      </div>
                      <Badge className="text-grey" color="">{(ethnicity.weight * 100).toFixed(2) + '%'}</Badge>
                    </div>

                    <Progress value={(ethnicity.weight * 100).toFixed(2)} className="progress-bar-custom" />

                  </ListGroupItem>
                }
                return null;
              })
              }

            </ListGroup>
            : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}
        </div>
        {props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_ethnicities && props.postAnalysisDetails.audience_likers.data.audience_ethnicities.length ?
          <div className="bottom">
            <Button color="link" onClick={() => props.toggleModal("likesEthnicityModal")}>View More</Button>
            <Modal id="likesEthnicityModalDownload" isOpen={props.state.modals.likesEthnicityModal} toggle={() => props.toggleModal("likesEthnicityModal")} >
              <ModalHeader toggle={() => props.toggleModal("likesEthnicityModal")}>
                <span className="tooltip-button-wrap"><span className="text-tooltip">Ethnicity</span>  <span href="#" id="likesEthnicityModal" className="tooltip-icon"></span></span>
                <UncontrolledTooltip placement="bottom" className="tooltip-content" target="likesEthnicityModal" >
                  Hello world!
              </UncontrolledTooltip>
                <RenderButton onClick={() => props.downloadPdf("likesEthnicityModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
              </ModalHeader>
              <ModalBody>

                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th>Ethnicity</th>
                      <th className="text-right">Likes</th>
                      <th className="text-right">Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_ethnicities ?
                      props.postAnalysisDetails.audience_likers.data.audience_ethnicities.map((ethnicity, index) =>
                        <tr key={index}>
                          <th scope="row"><div className="country-text-wrap">
                            <div className="country-name">{capitalize(ethnicity.name)}</div>
                          </div></th>
                          <td className="text-right">{formatNumbersToCommaSeperated((ethnicity.weight) * props.postAnalysisDetails.posts_profile.likes)}</td>
                          <td className="text-right">{(ethnicity.weight * 100).toFixed(2) + '%'}</td>
                        </tr>) :
                      <tr>
                        <td align="center" colSpan="3">No Record Found</td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </div>
          : null}
      </CardBody>
    </Card>
  )
}

export default LikesEthnicity;
