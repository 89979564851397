import React, { useEffect } from "react";
import { RenderTextFieldWithoutAutoComplete } from 'components';
import { Field, Form, Formik } from 'formik';
import { t } from 'i18next';
import { useState } from 'react';
import { Translation } from "react-i18next";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Dropdown, DropdownMenu, DropdownToggle, InputGroup, Row } from 'reactstrap';
import {
	getInfluencerProfileDataBySearch, searchAvailableAccountsGlobal
} from "../../reports/actions";
import { DropdownItems } from './DropdownItems';
import './SearchBar.scss';
import { getGlobalCreatorDatabaseTotalCount } from "modules/identification/actions";
import { formatNumbersToIntegerUnits } from "actions";

function SearchBar({ loginUser, availableAccounts, searchAvailableAccountsGlobal, getInfluencerProfileDataBySearch, subscriptionError, reportsErrorMessage, getGlobalCreatorDatabaseTotalCount, globalCreatorDatabaseTotalCount, }) {

	const [selectedInfluencer, setSelectedInfluencer] = useState('');
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [platformValue, setPlatformValue] = useState('instagram');
	const [searchQuery, setSearchQuery] = useState(null);

	useEffect(() => {
		getGlobalCreatorDatabaseTotalCount();
	}, [getGlobalCreatorDatabaseTotalCount]);

	const calculateSearchObj = (username) => {
		let searchObj = {
			username: username,
			platform: platformValue,
			filter: {
				language: [],
				age_group: [],
			},
		};
		return searchObj;
	};

	const handleSubmit = async (e, newReportTab) => {
		// e.preventdefault()
		if (newReportTab === null) {
			// popup blocked
		}
		const influencer = selectedInfluencer;
		let searchObj = {
			username: influencer && influencer.trim(),
		};

		if (influencer && influencer.trim().length) {
			searchObj = calculateSearchObj(influencer);
			await getInfluencerProfileDataBySearch(
				searchObj,
				null,
				loginUser && loginUser.id,
				false,
				newReportTab
			);
		}
	};

	const handleDropDownSelect = (e, selectedItem) => {
		const name = selectedItem.username || selectedItem.custom_name || selectedItem.user_id;
		setSelectedInfluencer(name);
		setPlatformValue(selectedItem.type);
		let newReportTab = window.open('/loading', '_blank');
		handleSubmit(e, newReportTab);
	};

	const handleSearchAccounts = (query) => {
		const searchQuery = {
			query: query && query,
			platform: platformValue,
		};

		searchAvailableAccountsGlobal(searchQuery);
	};

	const onInputChange = (e) => {
		handleSearchAccounts(e);
	}

	return (<>
		{/* <Formik onSubmit={this.handleSubmit}> */}
		<Formik onSubmit={handleSubmit}>
			{() => (
				<div className="dash-search">
					<p className="sub-text">
						{t("dashboard.search.description")}
					</p>
					<Form className="">
						<Row>
							<Col sm="auto">
								<div className="row">
									<div
										className="col-xl-10 col-md-9 col-sm-12"
									>
										<InputGroup className="search-wrap mb-0">
											<Dropdown
												isOpen={
													dropdownOpen &&
													availableAccounts &&
													availableAccounts.length > 0
												}
												toggle={() => {
													setDropdownOpen(!dropdownOpen);
												}}
											>
												<DropdownToggle tag="div">
													<Translation>
														{(t, { i18n }) => (
															<Field
																className={
																	availableAccounts &&
																		availableAccounts.length > 0 &&
																		searchQuery &&
																		searchQuery.length > 2
																		? "search-radius"
																		: ""
																}
																name="handleSearch"
																component={
																	RenderTextFieldWithoutAutoComplete
																}
																placeholder={t(
																	"dashboard.search.placeholder"
																)}
																onChange={(e) => setSelectedInfluencer(e.target.value)}
																onKeyUp={(e) => {
																	if (e.target.value.length > 2) {

																		onInputChange(e.target.value);
																	}
																	setSearchQuery(e.target.value);
																	setDropdownOpen(true);
																}}
																customvalue={selectedInfluencer}
															>
															</Field>
														)}
													</Translation>
												</DropdownToggle>
												{searchQuery &&
													searchQuery.length > 2 &&
													availableAccounts && (
														<DropdownMenu className="ddmenu-col preview-accounts-dropdown-report width-md-2 infulence-dropdown-list">
															{availableAccounts &&
																DropdownItems(availableAccounts, handleDropDownSelect)}
														</DropdownMenu>
													)}
											</Dropdown>
											<p className="creators-text text-grey"><Link className="discovery-link" to="/discovery">{globalCreatorDatabaseTotalCount ? formatNumbersToIntegerUnits(globalCreatorDatabaseTotalCount) : t("dashboard.search.discovery_link_count_fallback")} {t("dashboard.search.discovery_link_text")}</Link> {t("dashboard.search.discovery_link_appended_text")}</p>
										</InputGroup>
									</div>
								</div>
							</Col>
						</Row>
					</Form>
				</div>
			)}
		</Formik>

	</>);
}

const mapStateToProps = (state) => {
	return {
		availableAccounts: state.reports.availableAccounts,
		loginUser: state.login.loginUser,
		subscriptionError: state.reports.subscriptionError,
		reportsErrorMessage: state.reports.reportsErrorMessage,
		globalCreatorDatabaseTotalCount: state.identification.globalCreatorDatabaseTotalCount,
	};
};
export const DashSearchBar = connect(mapStateToProps, {
	searchAvailableAccountsGlobal,
	getInfluencerProfileDataBySearch,
	getGlobalCreatorDatabaseTotalCount,
})(SearchBar);
