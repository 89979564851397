import { API_URL, createRequestActionTypes } from "../../../actions/index";
export const SIGNUP_URL = `${API_URL}/api/auth/register`;
export const EMAIL_VERIFICATION_CONFIRMATION = `${API_URL}/api/auth/verify`;
export const signupActionTypes = {
  signup: createRequestActionTypes("SIGNUP"),
  clearErrorMsg: createRequestActionTypes("CLEAR_ERROR_MSG"),
  clearSuccessMsg: createRequestActionTypes("CLEAR_SUCCESS_MSG"),
  clearLoading: createRequestActionTypes("CLEAR_LOADING"),
  emailVerificationConfirmation: createRequestActionTypes(
    "EMAIL_VERIFICATION_CONFIRMATION"
  ),
};
