import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { RenderSingleSelect } from "../../components";
import { Field } from "formik";
import { showTooltip } from "../../actions";

import {
  reels_engage_range_left,
  reels_engage_range_right,
} from "../../helpers/Reels";
const ReelsFilter = (props) => {
  const { t } = useTranslation();

  const from_options = () => {
    return reels_engage_range_left.map((option) => {
      if (
        props.state.reelsTo &&
        props.state.reelsTo.value &&
        props.state.reelsTo.value <= option.value
      ) {
        return { ...option, isDisabled: true };
      } else {
        return { ...option, isDisabled: false };
      }
    });
  };

  const to_options = () => {
    return reels_engage_range_right.map((option) => {
      if (
        props.state.reelsFrom &&
        props.state.reelsFrom.value &&
        props.state.reelsFrom.value >= option.value
      ) {
        return { ...option, isDisabled: true };
      } else {
        return { ...option, isDisabled: false };
      }
    });
  };
  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2 second"
      isOpen={props.state.filters.reels}
      toggle={() => props.toggleFilter("reels")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {t("discovery.filters.reels.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-md">
        <Row>
          <Col md="12" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span id="reelsRate" className="tooltip-icon tool-top"></span>
            </span>
            <UncontrolledTooltip className="tooltip-content" target="reelsRate">
              {showTooltip("reelsRate")}
            </UncontrolledTooltip>
            <FormGroup className="select-field-col follower-col">
              <Field
                component={RenderSingleSelect}
                name="reelsFrom"
                placeholder={t("discovery.fields.from")}
                value={props.state.reelsFrom}
                onChange={(input) =>
                  props.handleSingleSelectChange(input, "reelsFrom")
                }
                options={from_options()}
              />
            </FormGroup>

            <FormGroup className="select-field-col last">
              <Field
                component={RenderSingleSelect}
                name="reelsTo"
                placeholder={t("discovery.fields.to")}
                value={props.state.reelsTo}
                onChange={(input) =>
                  props.handleSingleSelectChange(input, "reelsTo")
                }
                options={to_options()}
              />
            </FormGroup>
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default ReelsFilter;
