import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  formatNumbersToCommaSeperated,
  showTitle,
  showTooltip,
} from "../../../../actions";
import { LazyImage, RenderButton } from "../../../../components";
const FollowersAudienceBrandAffinity = (props) => {
  const { t } = useTranslation();

  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>{t("reports.audience_details.brand_affinity")}</CardTitle>
        <div className="card-content ch-8">
          <ListGroup className="ba-list">
            {props.accountOverviewDetails &&
            props.accountOverviewDetails.audience_followers &&
            props.accountOverviewDetails.audience_followers.data &&
            props.accountOverviewDetails.audience_followers.data
              .audience_brand_affinity &&
            props.accountOverviewDetails.audience_followers.data
              .audience_brand_affinity ? (
              props.accountOverviewDetails.audience_followers.data.audience_brand_affinity.map(
                (affinity, index) => {
                  if (index < 3) {
                    return (
                      <ListGroupItem
                        key={index}
                        className="d-flex align-items-center"
                      >
                        <div className="img-col-left">
                          <LazyImage
                            src={props.generateImgUrl("brand", affinity.id)}
                            alt="img"
                          />
                        </div>
                        <div
                          id={`followers_${affinity.name}`}
                          title={showTitle(
                            `followers_${affinity.name}`,
                            affinity.name
                          )}
                          className="text-wrap"
                        >
                          {affinity.name}
                        </div>
                      </ListGroupItem>
                    );
                  }
                  return null;
                }
              )
            ) : (
              <span className="text-center text-grey ">
                <p className="pt-50">{t("errors.no_record")}</p>
              </span>
            )}
          </ListGroup>
        </div>
        {props.accountOverviewDetails &&
        props.accountOverviewDetails.audience_followers &&
        props.accountOverviewDetails.audience_followers.data &&
        props.accountOverviewDetails.audience_followers.data
          .audience_brand_affinity &&
        props.accountOverviewDetails.audience_followers.data
          .audience_brand_affinity ? (
          <div className="bottom">
            <Button
              color="link"
              onClick={() =>
                props.toggleModal("followersAudienceBrandAffinityModal")
              }
            >
              {t("reports.view_more")}
            </Button>
            <Modal
              id="followersAudienceBrandAffinityModalDownload"
              isOpen={props.modals.followersAudienceBrandAffinityModal}
              toggle={() =>
                props.toggleModal("followersAudienceBrandAffinityModal")
              }
            >
              <ModalHeader
                toggle={() =>
                  props.toggleModal("followersAudienceBrandAffinityModal")
                }
              >
                <span className="tooltip-button-wrap">
                  <span className="text-tooltip">
                    {t("reports.audience_details.brand_affinity")}
                  </span>
                  <span
                    href="#"
                    id="followersAudienceBrandAffinityModal"
                    className="tooltip-icon"
                  ></span>
                </span>
                {props.tooltip && (
                  <UncontrolledTooltip
                    placement="bottom"
                    className="tooltip-content"
                    target="followersAudienceBrandAffinityModal"
                  >
                    {showTooltip("followersAudienceBrandAffinityModal")}
                  </UncontrolledTooltip>
                )}
                <RenderButton
                  onClick={() =>
                    props.downloadPdf(
                      "followersAudienceBrandAffinityModalDownload"
                    )
                  }
                  className="download-icon-button mg-left-15 link"
                >
                  {t("reports.download")}
                </RenderButton>
              </ModalHeader>
              <ModalBody>
                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th>{t("reports.chart.brand")}</th>
                      <th className="text-right">
                        {t("reports.chart.followers")}
                      </th>
                      <th className="text-right">{t("reports.chart.likes")}</th>
                      <th className="text-right">
                        {t("reports.chart.percentage")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.accountOverviewDetails &&
                    props.accountOverviewDetails.audience_followers &&
                    props.accountOverviewDetails.audience_followers.data &&
                    props.accountOverviewDetails.audience_followers.data
                      .audience_brand_affinity ? (
                      props.accountOverviewDetails.audience_followers.data.audience_brand_affinity.map(
                        (affinity, index) => (
                          <tr key={index}>
                            <th scope="row">
                              <div className="country-text-wrap">
                                <div className="flag-img brand-img">
                                  <img
                                    src={props.generateImgUrl(
                                      "brand",
                                      affinity.id
                                    )}
                                    alt="img"
                                  />
                                </div>
                                <div className="brand-name">
                                  {affinity.name}
                                </div>
                              </div>
                            </th>
                            <td className="text-right">
                              {formatNumbersToCommaSeperated(
                                affinity.weight *
                                  props.accountOverviewDetails.user_profile
                                    .followers
                              )}
                            </td>
                            <td className="text-right">
                              {formatNumbersToCommaSeperated(
                                affinity.weight *
                                  props.accountOverviewDetails.user_profile
                                    .avg_likes
                              )}
                            </td>
                            <td className="text-right">
                              {(affinity.weight * 100).toFixed(2) + "%"}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td align="center" colSpan="4">
                          {t("errors.no_record")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default FollowersAudienceBrandAffinity;
