import React  from "react";
import { Card, CardBody, CardTitle, FormGroup } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Field, Formik, Form } from "formik";
import {
  RenderPasswordField,
  SubmitButton,
} from "components";
import "./index.scss";

const Password = ({ updateUserPasswordAction, ...props }) => {
  const { t } = useTranslation();
  const initialValues = {
    current: "",
    password: "",
    confirmPassword: "",
  };

  const validateUser = (values) => {
    const errors = {};
    if (!values.current || values.current.trim() === "") {
      errors.current = "Must enter current password";
    }
    if (values.password.trim().length < 8) {
      errors.password = "Must have at least 8 characters";
    }
    if (!values.confirmPassword || values.confirmPassword.trim() === "") {
      errors.confirmPassword = "Must confirm new password";
    }
    if (values.password.trim() !== values.confirmPassword.trim()) {
      errors.confirmPassword = "Password doesn't match";
    }
    return errors;
  };

  const handleUserSubmit = (values, action) => {
    const { current, password, confirmPassword } = values;
    const passwordForm = {
      current,
      password,
      confirm_password: confirmPassword,
    };
    updateUserPasswordAction(passwordForm);
    action.resetForm();
  };

  return (
    <div className="password-settings user-form-wrap">
      <Card className="card-custom">
        <CardBody>
          <CardTitle>Change Password</CardTitle>
          <Formik
            initialValues={initialValues}
            onSubmit={handleUserSubmit}
            validate={validateUser}
          >
            {({ touched, errors }) => (
              <Form>
                <FormGroup>
                  <Field
                    name="current"
                    placeholder={t("auth.register.form.current")}
                    className={errors.current && touched.current ? "error" : ""}
                    component={RenderPasswordField}
                  />
                  {errors.current && touched.current && (
                    <span className="required">{errors.current}</span>
                  )}
                </FormGroup>

                <FormGroup>
                  <Field
                    name="password"
                    placeholder={t("auth.register.form.password")}
                    className={
                      errors.password && touched.password ? "error" : ""
                    }
                    component={RenderPasswordField}
                  />
                  {errors.password && touched.password && (
                    <span className="required">{errors.password}</span>
                  )}
                </FormGroup>

                <FormGroup>
                  <Field
                    name="confirmPassword"
                    placeholder={t("auth.register.form.confirm")}
                    className={
                      errors.confirmPassword && touched.confirmPassword
                        ? "error"
                        : ""
                    }
                    component={RenderPasswordField}
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <span className="required">{errors.confirmPassword}</span>
                  )}
                </FormGroup>
                <FormGroup className="bottom text-left mb-2">
                  <SubmitButton className="btn btn-primary mb-0">
                    {t("auth.register.update_password")}
                  </SubmitButton>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
};

export default Password;
