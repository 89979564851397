import {
  actionCreator,
  privateHeaders,
  checkHttpStatus,
  checkAuthTokenExpiry,
  showSuccessMessage,
  showErrorMessage,
} from "../../../actions";
import {
  GET_CAMPAIGNS_URL,
  GET_CAMPAIGN_URL,
  ADD_SHARED_USER_URL,
  REMOVE_SHARED_USER_URL,
  DELETE_CAMPAIGN_URL,
  GET_POST_PREVIEW_URL,
  GET_IMAGE_PREVIEW_URL,
  CREATE_CAMPAIGN_POST_URL,
  CREATE_CAMPAIGN_URL,
  GET_CAMPAIGN_POSTS_URL,
  // GET_CAMPAIGN_CREATORS_URL,
  // UPDATE_CAMPAIGN_URL,
  // GET_CAMPAIGN_CONTENT_URL,
  DELETE_CAMPAIGN_POST_URL,
  ADD_SHARED_USER_SUCCESS_MESSAGE,
  REMOVE_SHARED_USER_SUCCESS_MESSAGE,
  ADD_SHARED_USER_ERROR_MESSAGE,
  REMOVE_SHARED_USER_ERROR_MESSAGE,
  CREATE_POST_SUCCESS_MESSAGE,
  CREATE_POST_ERROR_MESSAGE,
  POST_LIMIT_REACHED_MESSAGE,
  campaignManagerActionTypes,
} from "../constants";
import { signOut } from "../../login/actions";

export const getCampaigns = ({
  calculate = "networks performance creators postCounts",
  sortKey = "updated",
  ...params
} = {}) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(campaignManagerActionTypes.getCampaigns.REQUEST));
    let campaignsUrl;
    try {
      campaignsUrl = new URL(GET_CAMPAIGNS_URL);
      campaignsUrl.searchParams.set("calculate", calculate);
      campaignsUrl.searchParams.set("populate", "sharedWithUser_ids");
      campaignsUrl.searchParams.set("sortKey", sortKey);
      for (let key in params) {
        campaignsUrl.searchParams.set(key, params[key]);
      }
    } catch (error) {
      console.error("Error constructing campaigns URL", error);
      dispatch(actionCreator(campaignManagerActionTypes.getCampaigns.FAILURE));
      return;
    }
    fetch(campaignsUrl, {
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            campaignManagerActionTypes.getCampaigns.SUCCESS,
            response
          )
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(campaignManagerActionTypes.getCampaigns.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const getCampaign = ({
  campaign_id,
  calculate = "networks performance creators postCounts",
} = {}) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(campaignManagerActionTypes.getCampaign.REQUEST));
    let campaignUrl;
    try {
      campaignUrl = new URL(GET_CAMPAIGN_URL);
      campaignUrl.searchParams.set("calculate", calculate);
      campaignUrl.searchParams.set("_id", campaign_id);
    } catch (error) {
      console.error("Error constructing campaign URL", error);
      dispatch(actionCreator(campaignManagerActionTypes.getCampaign.FAILURE));
      return;
    }
    fetch(campaignUrl, {
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            campaignManagerActionTypes.getCampaign.SUCCESS,
            response.result
          )
        );
        dispatch(getCampaignPosts({ campaign_id }));
      })
      .catch((error) => {
        dispatch(actionCreator(campaignManagerActionTypes.getCampaign.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const addSharedUser = (userData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(campaignManagerActionTypes.addSharedUser.REQUEST));
    fetch(`${ADD_SHARED_USER_URL}`, {
      method: "PUT",
      headers: privateHeaders(getState),
      body: JSON.stringify(userData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (
          response.success &&
          response.result?.sharedWithUser_ids.length > 0
        ) {
          showSuccessMessage(
            ADD_SHARED_USER_SUCCESS_MESSAGE(userData.shareWithEmail)
          );
          dispatch(getCampaigns());
          dispatch(
            actionCreator(campaignManagerActionTypes.addSharedUser.SUCCESS)
          );
        } else {
          showErrorMessage(
            null,
            ADD_SHARED_USER_ERROR_MESSAGE(userData.shareWithEmail)
          );
          dispatch(
            actionCreator(campaignManagerActionTypes.addSharedUser.FAILURE)
          );
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(campaignManagerActionTypes.addSharedUser.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const removeSharedUser = (userData) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(campaignManagerActionTypes.removeSharedUser.REQUEST)
    );
    fetch(`${REMOVE_SHARED_USER_URL}`, {
      method: "PUT",
      headers: privateHeaders(getState),
      body: JSON.stringify(userData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (!response.success) {
          showErrorMessage(
            null,
            REMOVE_SHARED_USER_ERROR_MESSAGE(userData.shareWithEmail)
          );
          dispatch(
            actionCreator(campaignManagerActionTypes.removeSharedUser.FAILURE)
          );
          return;
        }
        dispatch(getCampaigns());
        dispatch(
          actionCreator(campaignManagerActionTypes.removeSharedUser.SUCCESS)
        );
        showSuccessMessage(
          REMOVE_SHARED_USER_SUCCESS_MESSAGE(userData.shareWithEmail)
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(campaignManagerActionTypes.removeSharedUser.FAILURE)
        );
        showErrorMessage(
          null,
          REMOVE_SHARED_USER_ERROR_MESSAGE(userData.shareWithEmail)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const deleteCampaign = (campaign_id) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(campaignManagerActionTypes.deleteCampaign.REQUEST));

    const url = DELETE_CAMPAIGN_URL.replace(":campaign_id", campaign_id);

    fetch(url, {
      method: "DELETE",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(getCampaigns());
        dispatch(
          actionCreator(campaignManagerActionTypes.deleteCampaign.SUCCESS)
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(campaignManagerActionTypes.deleteCampaign.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const getPostPreview = ({ postUrl, postId, network } = {}) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(campaignManagerActionTypes.getPostPreview.REQUEST));
    fetch(`${GET_POST_PREVIEW_URL}?postUrl=${postUrl}`, {
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            campaignManagerActionTypes.getPostPreview.SUCCESS,
            response.result
          )
        );
        dispatch(getPostPreviewImage(response?.result?.thumbnail));
        dispatch(getCreatorPreviewImage(response?.result?.creator?.picture));
      })
      .catch((error) => {
        dispatch(
          actionCreator(campaignManagerActionTypes.getPostPreview.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const getPostPreviewImage = (imageUrl) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(campaignManagerActionTypes.getPostPreviewImage.REQUEST)
    );
    fetch(`${GET_IMAGE_PREVIEW_URL}?imageUrl=${encodeURIComponent(imageUrl)}`, {
      headers: privateHeaders(getState),
    })
      .then((response) => {
        return response.blob();
      })
      .then((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = () => {
          const base64data = reader.result;
          dispatch(
            actionCreator(
              campaignManagerActionTypes.getPostPreviewImage.SUCCESS,
              base64data
            )
          );
        };
      })
      .catch((error) => {
        dispatch(
          actionCreator(campaignManagerActionTypes.getPostPreviewImage.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const getCreatorPreviewImage = (imageUrl) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(campaignManagerActionTypes.getCreatorPreviewImage.REQUEST)
    );
    fetch(`${GET_IMAGE_PREVIEW_URL}?imageUrl=${encodeURIComponent(imageUrl)}`, {
      headers: privateHeaders(getState),
    })
      .then((response) => {
        return response.blob();
      })
      .then((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = () => {
          const base64data = reader.result;
          dispatch(
            actionCreator(
              campaignManagerActionTypes.getCreatorPreviewImage.SUCCESS,
              base64data
            )
          );
        };
      })
      .catch((error) => {
        dispatch(
          actionCreator(
            campaignManagerActionTypes.getCreatorPreviewImage.FAILURE
          )
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const createCampaignPost = ({
  campaign_id,
  postUrl,
  postId,
  network,
} = {}) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(campaignManagerActionTypes.createCampaignPost.REQUEST)
    );
    fetch(CREATE_CAMPAIGN_POST_URL, {
      method: "POST",
      headers: privateHeaders(getState),
      body: JSON.stringify({ campaign_id, postUrl, postId, network }),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (!response?.success) {
          if (response?.message?.includes("limit reached")) {
            showErrorMessage(null, POST_LIMIT_REACHED_MESSAGE);
          } else {
            showErrorMessage(null, CREATE_POST_ERROR_MESSAGE);
          }
          dispatch(
            actionCreator(campaignManagerActionTypes.createCampaignPost.FAILURE)
          );
          return;
        }
        showSuccessMessage(CREATE_POST_SUCCESS_MESSAGE);
        dispatch(
          actionCreator(
            campaignManagerActionTypes.createCampaignPost.SUCCESS,
            response
          )
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(campaignManagerActionTypes.createCampaignPost.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const createCampaign = ({
  name,
  description,
  status,
  postData,
} = {}) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(campaignManagerActionTypes.createCampaign.REQUEST));
    fetch(CREATE_CAMPAIGN_URL, {
      method: "POST",
      headers: privateHeaders(getState),
      body: JSON.stringify({ name, description, status }),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        if (postData?.isPreview) {
          dispatch(
            actionCreator(campaignManagerActionTypes.createCampaign.SUCCESS)
          );
          dispatch(
            createCampaignPost({
              campaign_id: response.result._id,
              postUrl: postData.postUrl,
              postId: postData.postId,
              network: postData.network,
            })
          );
        } else {
          dispatch(
            actionCreator(
              campaignManagerActionTypes.createCampaign.SUCCESS,
              true
            )
          );
          dispatch(getCampaigns());
        }
      })
      .catch((error) => {
        dispatch(
          actionCreator(campaignManagerActionTypes.createCampaign.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const getCampaignPosts = ({
  networks,
  campaign_id,
  skip = 0,
  limit = 50,
  sortKey = "created",
  sortValue,
  createdFrom,
  createdTo,
  populate,
} = {}) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(campaignManagerActionTypes.getCampaignPosts.REQUEST)
    );
    fetch(
      `${GET_CAMPAIGN_POSTS_URL}?sortKey=${sortKey}&campaign_id=${campaign_id}&includeShared=true`,
      {
        headers: privateHeaders(getState),
      }
    )
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            campaignManagerActionTypes.getCampaignPosts.SUCCESS,
            response
          )
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(campaignManagerActionTypes.getCampaignPosts.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const deleteCampaignPost = ({ post_id, campaign_id } = {}) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(campaignManagerActionTypes.deleteCampaignPost.REQUEST)
    );
    const url = DELETE_CAMPAIGN_POST_URL.replace(":content_id", post_id);

    fetch(url, {
      method: "DELETE",
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(campaignManagerActionTypes.deleteCampaignPost.SUCCESS)
        );
        dispatch(getCampaign({ campaign_id }));
      })
      .catch((error) => {
        dispatch(
          actionCreator(campaignManagerActionTypes.deleteCampaignPost.FAILURE)
        );
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const setNewPostModalIsOpen = (modalIsOpen) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(campaignManagerActionTypes.setNewPostModalIsOpen.SUCCESS, {
        modalIsOpen,
      })
    );
  };
};

export const setCampaignModalIsOpen = (modalIsOpen, campaignIdToOpen) => {
  return (dispatch, getState) => {
    dispatch(
      actionCreator(campaignManagerActionTypes.setCampaignModalIsOpen.SUCCESS, {
        modalIsOpen,
        campaignIdToOpen,
      })
    );
  };
};

// export const getCampaignCreators = ({ networks, campaign_id, skip, limit, sortKey, sortValue, createdFrom, createdTo } = {}) => {
//   return (dispatch, getState) => {
//     dispatch(actionCreator(campaignManagerActionTypes.getCampaignCreators.REQUEST));
//     fetch(`${GET_CAMPAIGN_CREATORS_URL}?sortKey=${sortKey}`, {
//       headers: privateHeaders(getState),
//     })
//       .then(checkHttpStatus)
//       .then((response) => checkAuthTokenExpiry(response))
//       .then((response) => {
//         dispatch(
//           actionCreator(
//             campaignManagerActionTypes.getCampaignCreators.SUCCESS,
//             response
//           )
//         );
//       })
//       .catch((error) => {
//         dispatch(
//           actionCreator(campaignManagerActionTypes.getCampaignCreators.FAILURE)
//         );
//         if (
//           error.statusCode &&
//           error.statusCode >= 400 &&
//           error.statusCode <= 500
//         ) {
//           dispatch(signOut(error));
//         }
//       });
//   };
// };
