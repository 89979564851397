import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { formatNumbersToUnits } from "../../../actions";
import {
  deleteCampaignPost,
  getCampaign,
  getCampaignPosts,
  getCampaigns,
  setCampaignModalIsOpen,
} from "../actions";
import { SharedTag } from "components/tags";
import noimg from "../../../img/MaskGroup.png";
import defaultImg from "../../../img/user-circle-outline.svg";
import { connect } from "react-redux";
import "../index.scss";
import networkImage from "helpers/networkImage";
import { useTranslation } from "react-i18next";
import CampaignPostsGrid from "../CampaignPostsGrid";
import Loader from "loader";
import PreciseMetricDisplayHover from "../components/PreciseMetricDisplayHover";
import ShareCampaignModal from "./ShareCampaignModal";
import CampaignActionsDropdown from "../components/CampaignActionsDropdown";
import { RenderButton } from "components";

const CampaignModal = ({
  deleteCampaignHandler,
  openNewPostModalHandler,

  campaignModalIsOpen,
  activeCampaign_id,
  activeCampaign,
  campaignPosts,
  campaignPostsTotalCount,
  loadingActiveCampaign,
  updateCampaignsOnClose,

  setCampaignModalIsOpen,
  getCampaign,
  getCampaigns,
  getCampaignPosts,
  deleteCampaignPost,

  ...props
}) => {
  const { t } = useTranslation();

  const [shareCampaignView, setShareCampaignView] = useState(false);

  const toggleShareCampaignView = () => {
    setShareCampaignView(!shareCampaignView);
  };

  const handleToggleModal = () => {
    setCampaignModalIsOpen(!campaignModalIsOpen);
  };

  const deleteCampaignPostHandler = (post_id) => {
    deleteCampaignPost({ post_id, campaign_id: activeCampaign_id });
  };

  useEffect(() => {
    if (activeCampaign_id) {
      getCampaign({ campaign_id: activeCampaign_id });
    }
  }, [activeCampaign_id, getCampaign]);

  useEffect(() => {
    if (!campaignModalIsOpen && updateCampaignsOnClose) {
      getCampaigns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignModalIsOpen, getCampaigns]);

  return (
    <>
      <Modal
        isOpen={campaignModalIsOpen}
        toggle={handleToggleModal}
        wrapClassName="campaign-modal campaign campaign-manager"
        modalClassName="right"
      >
        {loadingActiveCampaign && (
          <Loader className="social-cloud-loader campaign-manage-loader" />
        )}
        <ModalHeader toggle={handleToggleModal}>
          <Row className="d-flex align-items-center justify-content-between">
            <div className="campaign-title-section d-flex align-items-center">
              <span className="page-title">{activeCampaign?.name}</span>
              <div className="social-icons">
                {activeCampaign?.__networks?.map((network, index) => (
                  <div className="social-icon-round" key={index}>
                    {networkImage(network)}
                  </div>
                ))}
              </div>
              <div className="share-icon">
                {(activeCampaign?.isShared ||
                  activeCampaign?.sharedWithUser_ids.length > 0) && (
                  <SharedTag />
                )}
              </div>
            </div>
            <div className="campaign-actions">
              <CampaignActionsDropdown
                className="mr-4"
                campaign={activeCampaign}
                dropdownStyle="button"
                deleteCampaignHandler={deleteCampaignHandler}
                openNewPostModalHandler={openNewPostModalHandler}
              />
              <RenderButton
                className="btn btn-primary add-post-btn-modal"
                onClick={() =>
                  openNewPostModalHandler({
                    campaign: activeCampaign,
                    collectUrl: true,
                  })
                }
              >
                + Add Post
              </RenderButton>
            </div>
          </Row>
          <Row className="mt-3">
            <Col xs="" sm="6" md="6" lg="">
              <Card className="card-content-custom">
                <CardBody>
                  <h2>{formatNumbersToUnits(campaignPostsTotalCount)}</h2>
                  <CardTitle>
                    <span className="tooltip-button-wrap">
                      <span className="text-tooltip">
                        {t("campaign_manager.campaign_modal.posts")}
                      </span>
                    </span>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col xs="" sm="6" md="6" lg="">
              <Card className="card-content-custom">
                <CardBody>
                  <h2>
                    {formatNumbersToUnits(
                      activeCampaign?.__creatorsDisplay?.counts?.total ?? 0
                    )}
                  </h2>
                  <CardTitle>
                    <span className="tooltip-button-wrap">
                      <span className="text-tooltip">
                        {t("campaign_manager.campaign_modal.creators")}
                      </span>
                    </span>
                  </CardTitle>
                  <div className="creators d-none d-lg-flex">
                    <div className="account-images">
                      {activeCampaign?.__creatorsDisplay?.creators?.map(
                        (creator) => (
                          <div className="account-image" key={creator.id}>
                            <img
                              src={
                                creator.pictureCDN ? creator.pictureCDN : noimg
                              }
                              alt="profile pic"
                              width="45px"
                              height="45px"
                              className="rounded-circle"
                              onError={(e) => (e.target.src = defaultImg)}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <div className="w-100 d-md-none d-lg-none"></div>
            <Col xs="" sm="4" md="" lg="">
              <Card className="card-content-custom">
                <CardBody>
                  <PreciseMetricDisplayHover
                    metricValue={activeCampaign?.__totalFollowers}
                    triggerElem={
                      <div>
                        <h2>
                          {formatNumbersToUnits(
                            activeCampaign?.__totalFollowers ?? 0
                          )}
                        </h2>
                        <CardTitle>
                          <span className="tooltip-button-wrap">
                            <span className="text-tooltip">
                              {t("campaign_manager.campaign_modal.followers")}
                            </span>
                          </span>
                        </CardTitle>
                      </div>
                    }
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xs="" sm="4" md="" lg="">
              <Card className="card-content-custom">
                <CardBody>
                  <PreciseMetricDisplayHover
                    metricValue={activeCampaign?.__totalViews}
                    triggerElem={
                      <div>
                        <h2>
                          {(activeCampaign?.__totalLikes > 0 ||
                            activeCampaign?.__totalComments > 0 ||
                            activeCampaign?.__totalPlays > 0 ||
                            activeCampaign?.__totalShares > 0) &&
                          activeCampaign?.__totalViews === 0
                            ? "--"
                            : formatNumbersToUnits(
                                activeCampaign?.__totalViews ?? 0
                              )}
                        </h2>
                        <CardTitle>
                          <span className="tooltip-button-wrap">
                            <span className="text-tooltip">
                              {t("campaign_manager.campaign_modal.views")}
                            </span>
                          </span>
                        </CardTitle>
                      </div>
                    }
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xs="" sm="4" md="" lg="">
              <Card className="card-content-custom">
                <CardBody>
                  <PreciseMetricDisplayHover
                    metricValue={
                      (activeCampaign?.__totalLikes ?? 0) +
                      (activeCampaign?.__totalComments ?? 0) +
                      (activeCampaign?.__totalShares ?? 0)
                    }
                    triggerElem={
                      <div>
                        <h2>
                          {formatNumbersToUnits(
                            (activeCampaign?.__totalLikes ?? 0) +
                              (activeCampaign?.__totalComments ?? 0) +
                              (activeCampaign?.__totalShares ?? 0)
                          )}
                        </h2>
                        <CardTitle>
                          <span className="tooltip-button-wrap">
                            <span className="text-tooltip">
                              {t("campaign_manager.campaign_modal.engagements")}
                            </span>
                          </span>
                        </CardTitle>
                      </div>
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody className="confirm-modal">
          <Row>
            <Col md="12" className={loadingActiveCampaign ? "d-none" : ""}>
              {/* <CampaignCreatorsModalTable
      creators={creatorList?.creators}
      deleteCreator={deleteCreatorHandler}
      handleAnalyze={handleAnalyze}
    /> */}
              <CampaignPostsGrid
                posts={campaignPosts}
                deletePostHandler={deleteCampaignPostHandler}
                isCampaignShared={activeCampaign?.isShared}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {activeCampaign && (
        <ShareCampaignModal
          isOpen={shareCampaignView}
          toggleModal={toggleShareCampaignView}
          campaign={activeCampaign}
          key={`${activeCampaign?._id}-share`}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    campaignModalIsOpen: state.campaignManager.campaignModalIsOpen,
    activeCampaign_id: state.campaignManager.activeCampaign_id,
    activeCampaign: state.campaignManager.activeCampaign,
    campaignPosts: state.campaignManager.campaignPosts,
    campaignPostsTotalCount: state.campaignManager.campaignPostsTotalCount,
    loadingActiveCampaign: state.campaignManager.loadingActiveCampaign,
    updateCampaignsOnClose: state.campaignManager.updateCampaignsOnClose,
  };
};
export default connect(mapStateToProps, {
  setCampaignModalIsOpen,
  getCampaign,
  getCampaigns,
  getCampaignPosts,
  deleteCampaignPost,
})(CampaignModal);
