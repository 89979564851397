import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (<Route {...rest} render={(props) => {
    return rest.loginUser ?

      (rest.loginUser.isAdmin ?
        (rest.path.split('/')[1] === 'admin' ?
          <Component {...props} /> :
          <Redirect to='/admin/dashboard' />
        )
        :
        rest.path === '/paymentConfirmation' ?
          (rest.isSubscriptionChoose !== null ?
            <Component {...props} /> :
            <Redirect to="/" />
          ) :
          <Component {...props} />
      )

      : <Redirect to='/' />

  }} />
  )
}
export default PrivateRoute;
