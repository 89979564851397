import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Button,
  Badge,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import languages from "helpers/Languages";
import { useTranslation } from "react-i18next";
import {
  formatNumbersToCommaSeperated,
  showTitle,
  showTooltip,
} from "../../../../actions";
import { RenderButton } from "../../../../components";
const LikesLanguage = (props) => {
  const { t } = useTranslation();

  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>{t("reports.audience_details.location.language")}</CardTitle>
        <div className="card-content ch-10">
          <ListGroup flush className="country-list with-progress">
            {props.accountOverviewDetails &&
            props.accountOverviewDetails.audience_likers &&
            props.accountOverviewDetails.audience_likers.data &&
            props.accountOverviewDetails.audience_likers.data
              .audience_languages ? (
              props.accountOverviewDetails.audience_likers.data.audience_languages.map(
                (language, index) => {
                  if (index < 3) {
                    return (
                      <ListGroupItem key={index}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="country-text-wrap">
                            <div
                              id={`likes_${language.name}`}
                              title={showTitle(
                                `likes_${language.name}`,
                                language.name
                              )}
                              className="country-name"
                            >
                              {
                                languages.find(
                                  (option) => option.value === language.code
                                ).label
                              }
                            </div>
                          </div>
                          <Badge className="text-grey" color="">
                            {(language.weight * 100).toFixed(2) + "%"}
                          </Badge>
                        </div>

                        <Progress
                          value={(language.weight * 100).toFixed(2)}
                          className="progress-bar-custom"
                        />
                      </ListGroupItem>
                    );
                  }
                  return null;
                }
              )
            ) : (
              <span className="text-center text-grey ">
                <p className="pt-50">{t("errors.no_record")}</p>
              </span>
            )}
          </ListGroup>
        </div>
        {props.accountOverviewDetails &&
        props.accountOverviewDetails.audience_likers &&
        props.accountOverviewDetails.audience_likers.data &&
        props.accountOverviewDetails.audience_likers.data.audience_languages ? (
          <div className="bottom">
            <Button
              color="link"
              onClick={() => props.toggleModal("likesLanguageModal")}
            >
              {t("reports.view_more")}
            </Button>
            <Modal
              id="likesLanguageModalDownload"
              isOpen={props.modals.likesLanguageModal}
              toggle={() => props.toggleModal("likesLanguageModal")}
            >
              <ModalHeader
                toggle={() => props.toggleModal("likesLanguageModal")}
              >
                <span className="tooltip-button-wrap">
                  <span className="text-tooltip">
                    {t("reports.audience_details.location.language")}
                  </span>
                  <span
                    href="#"
                    id="likesLanguageModal"
                    className="tooltip-icon"
                  ></span>
                </span>
                {props.tooltip && (
                  <UncontrolledTooltip
                    placement="bottom"
                    className="tooltip-content"
                    target="likesLanguageModal"
                  >
                    {showTooltip("likesLanguageModal")}
                  </UncontrolledTooltip>
                )}
                <RenderButton
                  onClick={() =>
                    props.downloadPdf("likesLanguageModalDownload")
                  }
                  className="download-icon-button mg-left-15 link"
                >
                  {t("reports.download")}
                </RenderButton>
              </ModalHeader>
              <ModalBody>
                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th>{t("reports.audience_details.location.language")}</th>
                      <th className="text-right">
                        {t("reports.chart.followers")}
                      </th>
                      <th className="text-right">{t("reports.chart.likes")}</th>
                      <th className="text-right">
                        {t("reports.chart.percentage")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.accountOverviewDetails &&
                    props.accountOverviewDetails.audience_likers &&
                    props.accountOverviewDetails.audience_likers.data &&
                    props.accountOverviewDetails.audience_likers.data
                      .audience_languages &&
                    props.accountOverviewDetails.audience_likers.data
                      .audience_languages ? (
                      props.accountOverviewDetails.audience_likers.data.audience_languages.map(
                        (language, index) => (
                          <tr key={index}>
                            <th scope="row">
                              <div className="country-text-wrap">
                                <div className="country-name">
                                  {languages.find(
                                    (option) => option.value === language.code
                                  )?.label || "Other"}
                                </div>
                              </div>
                            </th>
                            <td className="text-right">
                              {formatNumbersToCommaSeperated(
                                language.weight *
                                  props.accountOverviewDetails.user_profile
                                    .followers
                              )}
                            </td>
                            <td className="text-right">
                              {formatNumbersToCommaSeperated(
                                language.weight *
                                  props.accountOverviewDetails.user_profile
                                    .avg_likes
                              )}
                            </td>
                            <td className="text-right">
                              {(language.weight * 100).toFixed(2) + "%"}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td align="center" colSpan="4">
                          {t("errors.no_record")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default LikesLanguage;
