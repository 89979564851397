import React from 'react'
import { Modal, ModalHeader, ModalBody, FormGroup, Table } from 'reactstrap';
import { Formik, Form } from 'formik';
import { RenderButton, SubmitButton } from '../../components';
import { formatNumbersToCommaSeperated } from '../../actions';
export default function ViewPostConfirmationModal(props) {

  return (
    <Modal isOpen={true} toggle={props.toggleConfirmationModal}>
      <ModalHeader toggle={props.toggleConfirmationModal}>
        <div className="text-center unlock-more-modal-heading-section">
          <h1 className="unlock-more-modal-heading">Confirmation</h1>

        </div>
      </ModalHeader>
      <ModalBody>
        <div className="container">
          <div className="user-form-wrap no-min-height">
            <Formik
              initialValues={props.initialValues}
              onSubmit={props.handleGenerateReport}
            >
              {() => (
                <Form>
                  <div className="confirmation-table-wrap pl-3 pr-3 pt-2 pb-0">
                    <FormGroup>
                      <Table borderless >
                        <tbody>

                          <tr className="text-grey tr-with-no-pad-to-td">
                            <td width="70%">Number of Likes to Analyze</td>
                            <td className="text-right" width="30%">{formatNumbersToCommaSeperated(props.likesLoaded && props.likesLoaded.estimated_likes)}</td>
                          </tr>
                          <tr className="text-grey tr-with-no-pad-to-td">
                            <td width="70%">Current Post Analysis Balance</td>
                            <td className="text-right" width="30%">{props.postsBalance ? formatNumbersToCommaSeperated(props.postsBalance) : 0}</td>
                          </tr>
                          <tr className="text-grey bt-1 tr-with-no-pad-to-td">
                            <td width="70%">Remaining Post Analysis Likes</td>
                            <td className="text-right" width="30%">{props.postsBalance ? props.postsBalance - props.likesLoaded.estimated_likes : 0}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </FormGroup>
                  </div>

                  <FormGroup>
                    <div className="d-flex justify-content-center">
                      <SubmitButton className="btn btn-primary mr-1">Continue</SubmitButton>
                      <RenderButton className="btn btn-outline-primary ml-1" onClick={props.toggleConfirmationModal}> Cancel</RenderButton>
                    </div>
                  </FormGroup>
                </Form>
              )}

            </Formik>
          </div>
        </div>

      </ModalBody>

    </Modal>
  )
}
