import { createRequestActionTypes, API_URL } from "../../../actions";
export const GET_PROFILE_DETAILS_URL = `${API_URL}/profile`;
export const UPDATE_PROFILE_DETAILS_URL = `${API_URL}/user`;
export const GET_PAST_EXPORTS_POSTS_URL = `${API_URL}/generated/posts?page=`;
export const GET_PAST_EXPORTS_REPORTS_URL = `${API_URL}/generated/reports?page=`;
export const GET_PAST_EXPORTS_LISTS_URL = `${API_URL}/generated/lists?page=`;
export const CANCEL_SUBSCRIPTION_PLAN = `${API_URL}/payment/subscription/cancel`;
export const UPDATE_PAYMENT_CARD = `${API_URL}/payment/update`;
export const UPDATE_USER_PASSWORD_URL = `${API_URL}/api/auth/update_password`;
export const SUBSCRIPTION_RE_ACTIVATE_URL = `${API_URL}/payment/subscription/reactivate`;
export const DELETE_REPORT_ID = `${API_URL}/generated/reports/`;
export const DELETE_POST_ID_URL = `${API_URL}/generated/posts/`;
export const DELETE_LIST_ID_URL = `${API_URL}/generated/lists/`;
export const DOWNLOAD_REPORT_PDF_URL = `${API_URL}/reports/`;
export const CREATE_SUBSCRIPTION = `${API_URL}/payment/subscription`;

export const userProfileActionTypes = {
    getProfileDetails: createRequestActionTypes('GET_PROFILE_DETAILS'),
    updateProfileDetails: createRequestActionTypes('UPDATE_PROFILE_DETAILS'),
    getPastExportsReports: createRequestActionTypes('GET_PAST_EXPORTS_REPORTS'),
    getPastExportsPosts: createRequestActionTypes('GET_PAST_EXPORTS_POSTS'),
    getPastExportsLists: createRequestActionTypes('GET_PAST_EXPORTS_LISTS'),
    cancelSubscription: createRequestActionTypes('CANCEL_SUBSCRIPTION_PLAN'),
    switchPlanConstant: createRequestActionTypes('SWITCH_PLAN'),
    updateCardDetails: createRequestActionTypes('POST_CARD_DETAILS'),
    updateUserPassword: createRequestActionTypes('UPDATE_USER_PASSWORD'),
    clearErrorMessage: createRequestActionTypes('CLEAR_ERROR_MESSAGE'),
    clearSuccessMessage: createRequestActionTypes('CLEAR_SUCCESS_MESSAGE'),
    planReActivate: createRequestActionTypes('SUBSCRIPTION_PLAN_REACTIVATE'),
    deleteReportId: createRequestActionTypes('DELETE_REPORT_ID'),
    deletePostId: createRequestActionTypes('DELETE_POST_ID'),
    deleteListId: createRequestActionTypes('DELETE_LIST_ID'),
    downloadReportPdf: createRequestActionTypes('DOWNLOAD_REPORT_PDF'),
    saveListSearchFilters:  createRequestActionTypes('SAVE_LIST_SEARCH_FILTERS'),
    clearSavedListFilters: createRequestActionTypes('CLEAR_SAVED_LIST_FILTERS'),
    createSubscription:createRequestActionTypes('POST_CARD_DETAILS'),
    showStripeMsg: createRequestActionTypes('SHOW_STRIPE_MSG'),
    clearStripeMsgAction: createRequestActionTypes('CLEAR_STRIPE_MESSAGE'),

}
