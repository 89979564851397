import React from "react";
import networkImage from "../../helpers/networkImage";
import { FollowerTag, SharedTag } from "components/tags";
import noimg from "../../img/MaskGroup.png";
import defaultImg from "../../img/user-circle-outline.svg";
import moment from "moment";
import CampaignActionsDropdown from "./components/CampaignActionsDropdown";

const CampaignsTableRow = ({
  campaign,
  deleteCampaignHandler,
  openCampaignHandler,
  toggleCampaignHandler,
  openNewPostModalHandler,
  ...props
}) => {
  return (
    <tr>
      <td className="tb-head" onClick={() => openCampaignHandler(campaign._id)}>
        <div className="name d-flex align-items-center">
          <h4 className="m-0">{campaign.name}</h4>
          {(campaign?.isShared || campaign?.sharedWithUser_ids.length > 0) && (
            <SharedTag />
          )}
        </div>
      </td>
      <td>
        <div className="social-icons">
          {campaign.__networks.map((network, index) => (
            <div className="social-icon-round" key={index}>
              {networkImage(network)}
            </div>
          ))}
        </div>
      </td>
      <td>
        <div className="post-count">{campaign.__postCounts?.total}</div>
      </td>
      <td>
        <div className="creators">
          <div className="account-images mr-2">
            {campaign.__creatorsDisplay?.creators.slice(0, 3).map((creator) => (
              <div className="account-image" key={creator.id}>
                <img
                  src={creator.pictureCDN || noimg}
                  alt="profile pic"
                  width="35px"
                  height="35px"
                  className="rounded-circle"
                  onError={(e) => (e.target.src = defaultImg)}
                />
              </div>
            ))}
          </div>
          <div className="creators-count">
            {campaign.__creatorsDisplay?.counts?.total}
          </div>
        </div>
      </td>
      <td>
        <FollowerTag followers={campaign.__totalFollowers || 0}></FollowerTag>
      </td>
      <td>{moment(campaign.updated).format("MMMM DD, YYYY")}</td>
      <td>
        <CampaignActionsDropdown
          campaign={campaign}
          deleteCampaignHandler={deleteCampaignHandler}
          openNewPostModalHandler={openNewPostModalHandler}
        />
      </td>
    </tr>
  );
};

export default CampaignsTableRow;
