import React from "react";
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
  Badge,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  formatNumbersToUnits,
  formatNumbersToIntegerUnits,
  capitalize,
  showTitle,
} from "../../../actions";
import { showTooltip } from "../../../actions";
const AccountOverview = (props) => {
  const { t } = useTranslation();

  return (
    <>
      {/* FOLLOWERS (ALL) */}
      <Col sm="3">
        <Card className="card-content-custom ch-1">
          <CardBody>
            <h2>
              {formatNumbersToUnits(
                props.accountOverviewDetails &&
                  props.accountOverviewDetails.user_profile &&
                  props.accountOverviewDetails.user_profile.followers
              )}
              <Badge
                className={`fs--2 ml-2 rank-wrap rank-wrap ${
                  props.accountOverviewDetails &&
                  props.accountOverviewDetails.user_profile &&
                  props.calculatePercentage("followers")
                    ? props.calculatePercentage("followers").indexOf("-") < 0
                      ? "rank-up badge-soft-success"
                      : "rank-down badge-soft-danger"
                    : ""
                }`}
              >
                {props.calculatePercentage("followers").replace("-", "")}
              </Badge>
            </h2>
            <CardTitle>
              {props.accountOverviewDetails.user_profile &&
              props.accountOverviewDetails.user_profile.type === "youtube"
                ? t("reports.influencer_details.subscribers")
                : t("reports.influencer_details.followers")}
            </CardTitle>
          </CardBody>
        </Card>
      </Col>
      {/* AVG VIEWS (ALL) */}
      {props.accountOverviewDetails.user_profile?.avg_views > 0 ? (
        <Col sm="3">
          <Card className="card-content-custom ch-1">
            <CardBody>
              <h2>
                {formatNumbersToUnits(
                  props.accountOverviewDetails &&
                    props.accountOverviewDetails.user_profile &&
                    props.accountOverviewDetails.user_profile.avg_views
                )}
                {props.accountOverviewDetails.user_profile &&
                  (props.accountOverviewDetails.user_profile.type ===
                    "youtube" ||
                    props.accountOverviewDetails.user_profile.type ===
                      "tiktok") && (
                    <Badge
                      className={`fs--2 ml-2 rank-wrap ${
                        props.accountOverviewDetails &&
                        props.accountOverviewDetails.user_profile &&
                        props.calculatePercentage("avg_views")
                          ? props
                              .calculatePercentage("avg_views")
                              .indexOf("-") < 0
                            ? "rank-up badge-soft-success"
                            : "rank-down badge-soft-danger"
                          : ""
                      }`}
                    >
                      {props.calculatePercentage("avg_views").replace("-", "")}
                    </Badge>
                  )}
              </h2>
              <CardTitle>
                <span className="tooltip-button-wrap">
                  <span className="text-tooltip">
                    {t("reports.influencer_details.views")}
                  </span>
                  <span id="reportAvgViews" className="tooltip-icon"></span>
                </span>
                {props.tooltip && (
                  <UncontrolledTooltip
                    className="tooltip-content"
                    target="reportAvgViews"
                  >
                    {showTooltip("reportAvgViews")}
                  </UncontrolledTooltip>
                )}
              </CardTitle>
            </CardBody>
          </Card>
        </Col>
      ) : null}

      {/* AVG LIKES (ALL) */}
      <Col sm="3">
        <Card className="card-content-custom ch-1">
          <CardBody>
            <h2>
              {formatNumbersToUnits(
                props.accountOverviewDetails &&
                  props.accountOverviewDetails.user_profile &&
                  props.accountOverviewDetails.user_profile.avg_likes
              )}
              <Badge
                className={`fs--2 ml-2 rank-wrap ${
                  props.accountOverviewDetails &&
                  props.accountOverviewDetails.user_profile &&
                  props.calculatePercentage("avg_likes")
                    ? props.calculatePercentage("avg_likes").indexOf("-") < 0
                      ? "rank-up badge-soft-success"
                      : "rank-down badge-soft-danger"
                    : ""
                }`}
              >
                {props.calculatePercentage("avg_likes").replace("-", "")}
              </Badge>
            </h2>
            <CardTitle>
              <span className="tooltip-button-wrap">
                <span className="text-tooltip">
                  {t("reports.influencer_details.likes")}
                </span>
                <span id="reportAvgLikes" className="tooltip-icon"></span>
              </span>
              {props.tooltip && (
                <UncontrolledTooltip
                  className="tooltip-content"
                  target="reportAvgLikes"
                >
                  {showTooltip("reportAvgLikes")}
                </UncontrolledTooltip>
              )}
            </CardTitle>
          </CardBody>
        </Card>
      </Col>
      {/* AVG COMMENTS (ALL) */}
      <Col sm="3">
        <Card className="card-content-custom ch-1">
          <CardBody>
            <h2>
              {formatNumbersToUnits(
                props.accountOverviewDetails &&
                  props.accountOverviewDetails.user_profile &&
                  props.accountOverviewDetails.user_profile.avg_comments
              )}
              {props.accountOverviewDetails.user_profile &&
                (props.accountOverviewDetails.user_profile.type === "youtube" ||
                  props.accountOverviewDetails.user_profile.type ===
                    "tiktok") && (
                  <Badge
                    className={`fs--2 ml-2 rank-wrap ${
                      props.accountOverviewDetails &&
                      props.accountOverviewDetails.user_profile &&
                      props.calculatePercentage("avg_comments")
                        ? props
                            .calculatePercentage("avg_comments")
                            .indexOf("-") < 0
                          ? "rank-up badge-soft-success"
                          : "rank-down badge-soft-danger"
                        : ""
                    }`}
                  >
                    {props.calculatePercentage("avg_comments").replace("-", "")}
                  </Badge>
                )}
            </h2>
            <CardTitle>
              <span className="tooltip-button-wrap">
                <span className="text-tooltip">
                  {t("reports.influencer_details.comments")}
                </span>
                <span id="reportAvgComments" className="tooltip-icon"></span>
              </span>
              {props.tooltip && (
                <UncontrolledTooltip
                  className="tooltip-content"
                  target="reportAvgComments"
                >
                  {showTooltip("reportAvgComments")}
                </UncontrolledTooltip>
              )}
            </CardTitle>
          </CardBody>
        </Card>
      </Col>
      {/* AVG REELS PLAYS (IG) */}
      {props.accountOverviewDetails.user_profile.type === "instagram" &&
        props.accountOverviewDetails.user_profile?.avg_reels_plays && (
          <Col sm="3">
            <Card className="card-content-custom ch-1">
              <CardBody>
                <h2>
                  {formatNumbersToUnits(
                    props.accountOverviewDetails.user_profile.avg_reels_plays
                  )}
                </h2>
                <CardTitle>
                  <span className="tooltip-button-wrap">
                    <span className="text-tooltip">
                      {t("reports.influencer_details.reels_plays")}
                    </span>
                    <span
                      id="reportAvgReelsPlays"
                      className="tooltip-icon"
                    ></span>
                  </span>
                  {props.tooltip && (
                    <UncontrolledTooltip
                      className="tooltip-content"
                      target="reportAvgReelsPlays"
                    >
                      {showTooltip("reportAvgReelsPlays")}
                    </UncontrolledTooltip>
                  )}
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
        )}
      {/* ESTIMATED PRICE (INSTA, TWITTER, YT) */}
      {props.accountOverviewDetails.user_profile &&
        (props.accountOverviewDetails.user_profile.type === "youtube" ||
          props.accountOverviewDetails.user_profile.type === "twitter" ||
          props.accountOverviewDetails.user_profile.type === "instagram") &&
        props.accountOverviewDetails.user_profile.price_estimation && (
          <Col sm="3">
            <Card className="card-content-custom ch-1">
              <CardBody>
                <h2>
                  {"$" +
                    formatNumbersToIntegerUnits(
                      props.accountOverviewDetails.user_profile.price_estimation
                        .min
                    ) +
                    " - "}
                  {"$" +
                    formatNumbersToIntegerUnits(
                      props.accountOverviewDetails.user_profile.price_estimation
                        .max
                    )}
                </h2>
                <CardTitle>
                  <span className="tooltip-button-wrap">
                    <span className="text-tooltip">
                      {t("reports.influencer_details.price")}
                    </span>
                    <span
                      id="influencerPriceTooltip"
                      className="tooltip-icon"
                    ></span>
                  </span>
                  {props.tooltip && (
                    <UncontrolledTooltip
                      className="tooltip-content"
                      target="influencerPriceTooltip"
                    >
                      {showTooltip("influencerPriceTooltip")}
                    </UncontrolledTooltip>
                  )}
                  {/* <UncontrolledTooltip className="tooltip-content" target="followerRate" >
              {showTooltip('followerRate')}
            </UncontrolledTooltip> */}
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
        )}
      {/* GEO (ALL) */}
      {props.accountOverviewDetails &&
      props.accountOverviewDetails.user_profile &&
      props.accountOverviewDetails.user_profile.geo ? (
        <Col sm="3">
          <Card className="card-content-custom ch-1">
            <CardBody>
              <div className="country-text-wrap">
                <div className="location-card-flag">
                  <img
                    src={props.generateImgUrl(
                      "country",
                      props.accountOverviewDetails.user_profile.geo.country.code
                    )}
                    alt="img"
                  />
                </div>
                <div
                  className="country-name pt-2"
                  title={showTitle(
                    "locationName",
                    (props.accountOverviewDetails.user_profile.geo.city &&
                      props.accountOverviewDetails.user_profile.geo.city
                        .name) ||
                      (props.accountOverviewDetails.user_profile.geo.country &&
                        props.accountOverviewDetails.user_profile.geo.country
                          .name)
                  )}
                >
                  <h2 id="locationName">
                    {capitalize(
                      (props.accountOverviewDetails.user_profile.geo.city &&
                        props.accountOverviewDetails.user_profile.geo.city
                          .name) ||
                        (props.accountOverviewDetails.user_profile.geo
                          .country &&
                          props.accountOverviewDetails.user_profile.geo.country
                            .name)
                    )}
                  </h2>
                </div>
              </div>

              <CardTitle>{t("reports.influencer_details.location")}</CardTitle>
            </CardBody>
          </Card>
        </Col>
      ) : null}
      {props.accountOverviewDetails &&
      props.accountOverviewDetails.user_profile &&
      props.accountOverviewDetails.user_profile.paid_post_performance ? (
        <Col sm="3">
          <Card className="card-content-custom ch-1">
            <CardBody>
              <h2>
                {(
                  props.accountOverviewDetails.user_profile
                    .paid_post_performance * 100
                ).toFixed(2) + "%"}
              </h2>
              <CardTitle>
                <span className="tooltip-button-wrap">
                  <span className="text-tooltip">
                    {t("reports.influencer_details.paid")}
                  </span>
                  <span id="paidPostTooltip" className="tooltip-icon"></span>
                </span>
                {props.tooltip && (
                  <UncontrolledTooltip
                    className="tooltip-content"
                    target="paidPostTooltip"
                  >
                    {showTooltip("paidPostTooltip")}
                  </UncontrolledTooltip>
                )}
                {/* <UncontrolledTooltip className="tooltip-content" target="followerRate" >
              {showTooltip('followerRate')}
            </UncontrolledTooltip> */}
              </CardTitle>
            </CardBody>
          </Card>
        </Col>
      ) : null}
    </>
  );
};
export default AccountOverview;
