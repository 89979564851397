import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Button,
  Badge,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  capitalize,
  formatNumbersToCommaSeperated,
  showTitle,
  showTooltip,
} from "../../../../actions";
import { RenderButton } from "../../../../components";
const FollowersEthnicity = (props) => {
  const { t } = useTranslation();

  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>
          {t("reports.audience_details.location.ethnicity")}
        </CardTitle>
        <div className="card-content ch-10">
          <ListGroup flush className="country-list with-progress">
            {props.accountOverviewDetails &&
            props.accountOverviewDetails.audience_followers &&
            props.accountOverviewDetails.audience_followers.data &&
            props.accountOverviewDetails.audience_followers.data
              .audience_ethnicities ? (
              props.accountOverviewDetails.audience_followers.data.audience_ethnicities.map(
                (ethnicity, index) => {
                  if (index < 3) {
                    return (
                      <ListGroupItem key={index}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="country-text-wrap">
                            <div
                              id={`followers_${ethnicity.name}`}
                              title={showTitle(
                                `followers_${ethnicity.name}`,
                                ethnicity.name
                              )}
                              className="country-name"
                            >
                              {capitalize(ethnicity.name)}
                            </div>
                          </div>
                          <Badge className="text-grey" color="">
                            {(ethnicity.weight * 100).toFixed(2) + "%"}
                          </Badge>
                        </div>
                        <Progress
                          value={(ethnicity.weight * 100).toFixed(2)}
                          className="progress-bar-custom"
                        />
                      </ListGroupItem>
                    );
                  }
                  return null;
                }
              )
            ) : (
              <span className="text-center text-grey ">
                <p className="pt-50">{t("errors.no_record")}</p>
              </span>
            )}
          </ListGroup>
        </div>
        {props.accountOverviewDetails &&
        props.accountOverviewDetails.audience_followers &&
        props.accountOverviewDetails.audience_followers.data &&
        props.accountOverviewDetails.audience_followers.data
          .audience_ethnicities ? (
          <div className="bottom">
            <Button
              color="link"
              onClick={() => props.toggleModal("followersEthnicityModal")}
            >
              {t("reports.view_more")}
            </Button>
            <Modal
              id="followersEthnicityModalDownload"
              isOpen={props.modals.followersEthnicityModal}
              toggle={() => props.toggleModal("followersEthnicityModal")}
            >
              <ModalHeader
                toggle={() => props.toggleModal("followersEthnicityModal")}
              >
                <span className="tooltip-button-wrap">
                  <span className="text-tooltip">
                    {t("reports.audience_details.location.ethnicity")}
                  </span>
                  <span
                    href="#"
                    id="followersEthnicityModal"
                    className="tooltip-icon"
                  ></span>
                </span>
                {props.tooltip && (
                  <UncontrolledTooltip
                    placement="bottom"
                    className="tooltip-content"
                    target="followersEthnicityModal"
                  >
                    {showTooltip("followersEthnicityModal")}
                  </UncontrolledTooltip>
                )}
                <RenderButton
                  onClick={() =>
                    props.downloadPdf("followersEthnicityModalDownload")
                  }
                  className="download-icon-button mg-left-15 link"
                >
                  {t("reports.download")}
                </RenderButton>
              </ModalHeader>
              <ModalBody>
                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th>
                        {t("reports.audience_details.location.ethnicity")}
                      </th>
                      <th className="text-right">
                        {t("reports.chart.followers")}
                      </th>
                      <th className="text-right">
                        {t("reports.chart.likes")}
                      </th>
                      <th className="text-right">
                        {t("reports.chart.percentage")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.accountOverviewDetails &&
                    props.accountOverviewDetails.audience_followers &&
                    props.accountOverviewDetails.audience_followers.data &&
                    props.accountOverviewDetails.audience_followers.data
                      .audience_ethnicities ? (
                      props.accountOverviewDetails.audience_followers.data.audience_ethnicities.map(
                        (ethnicity, index) => (
                          <tr key={index}>
                            <th scope="row">
                              <div className="country-text-wrap">
                                <div className="country-name">
                                  {capitalize(ethnicity.name)}
                                </div>
                              </div>
                            </th>
                            <td className="text-right">
                              {formatNumbersToCommaSeperated(
                                ethnicity.weight *
                                  props.accountOverviewDetails.user_profile
                                    .followers
                              )}
                            </td>
                            <td className="text-right">
                              {formatNumbersToCommaSeperated(
                                ethnicity.weight *
                                  props.accountOverviewDetails.user_profile
                                    .avg_likes
                              )}
                            </td>
                            <td className="text-right">
                              {(ethnicity.weight * 100).toFixed(2) + "%"}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td align="center" colSpan="4">
                          {t("errors.no_record")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default FollowersEthnicity;
