import { actionCreator, privateHeaders, checkHttpStatus, checkAuthTokenExpiry } from "../../../actions";
import { forgotPasswordActionTypes, FORGOT_PASSWORD_URL, RESET_PASSWORD_URL } from "../constants";
import history from "../../../history";

export const forgotPassword = (authData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(forgotPasswordActionTypes.forgotPassword.REQUEST));
    fetch(FORGOT_PASSWORD_URL, {
      method: 'POST',
      body: JSON.stringify(authData),
      headers: privateHeaders(getState),
    }).then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then(response => {
        if (response.success) {
          response.successMsg = response.message;
          dispatch(actionCreator(forgotPasswordActionTypes.forgotPassword.SUCCESS, response));
        } else {
          response.errorMsg = response.message;
          dispatch(actionCreator(forgotPasswordActionTypes.forgotPassword.SUCCESS, response));

        }

      }).catch(error => {
        dispatch(actionCreator(forgotPasswordActionTypes.forgotPassword.FAILURE));
      })
  }
}
export const resetPassword = (authData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(forgotPasswordActionTypes.resetPassword.REQUEST));
    fetch(RESET_PASSWORD_URL, {
      method: 'POST',
      body: JSON.stringify(authData),
      headers: privateHeaders(getState),
    }).then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then(response => {
        if (response.success) {
          response.successMsg = response.message;
          dispatch(actionCreator(forgotPasswordActionTypes.resetPassword.SUCCESS, response));
          history.push("/");
        } else {
          response.errorMsg = response.message;
          dispatch(actionCreator(forgotPasswordActionTypes.resetPassword.SUCCESS, response));

        }

      }).catch(error => {
        dispatch(actionCreator(forgotPasswordActionTypes.forgotPassword.FAILURE));
      })
  }
}
export const clearErrorMsg = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(forgotPasswordActionTypes.clearErrorMsg.SUCCESS));
  };
}
export const clearSuccessMsg = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(forgotPasswordActionTypes.clearSuccessMsg.SUCCESS));
  };
}
