import { forgotPasswordActionTypes } from "../constants";
import { COMMON_ERROR_MESSAGE } from "../../../actions";
const initialState = {
    loading: false,
    errorMsg: null,
    successMsg: null,
}
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case forgotPasswordActionTypes.forgotPassword.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case forgotPasswordActionTypes.forgotPassword.SUCCESS:
            return {
                ...state,
                loading: false,
                successMsg: payload.successMsg || null,
                errorMsg: payload.errorMsg || null,
            }
        case forgotPasswordActionTypes.forgotPassword.FAILURE:
            return {
                ...state,
                successMsg: null,
                loading: false,
                errorMsg: COMMON_ERROR_MESSAGE
            }

        case forgotPasswordActionTypes.resetPassword.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case forgotPasswordActionTypes.resetPassword.SUCCESS:
            return {
                ...state,
                loading: false,
                successMsg: payload.successMsg || null,
                errorMsg: payload.errorMsg || null,
            }
        case forgotPasswordActionTypes.resetPassword.FAILURE:
            return {
                ...state,
                successMsg: null,
                loading: false,
                errorMsg: COMMON_ERROR_MESSAGE
            }
        case forgotPasswordActionTypes.clearErrorMsg.SUCCESS:
            return {
                ...state,
                errorMsg: null,
            }
        case forgotPasswordActionTypes.clearSuccessMsg.SUCCESS:
            return {
                ...state,
                successMsg: null,
            }
        default:
            return state
    }

}