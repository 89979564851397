import React from "react";
import { Table, Row, Col } from "reactstrap";
import EmailMatchResultsModalRow from "./emailMatchResultsModalRow";
// import { useTranslation } from "react-i18next";

const EmailMatchResultsModalTable = ({ creators, handleAnalyze, isCreatorListModalOpen, toggleCreatorListModal, formatCreatorsForList, creatorLists, addCreatorListHandler, updateCreatorListHandler, ...props }) => {
  // const { t } = useTranslation();

  const columns = ["Creator", "Matched Email", "Followers"];
  const dataColumnHeaders = columns.map((column, index) => (
    <th key={index} className={index > 1 ? "mobile-view-past-report" : ""}>
      {/* {t(`creator_lists.list_table.${column}_header`)} */}
      {column}
    </th>
  ));

  const tableData = creators.map((creator) => (
    <EmailMatchResultsModalRow
      key={creator.user_id}
      creator={creator}
      handleAnalyze={handleAnalyze}
      isCreatorListModalOpen={isCreatorListModalOpen}
      toggleCreatorListModal={toggleCreatorListModal}
      formatCreatorsForList={formatCreatorsForList}
      creatorLists={creatorLists}
      addCreatorListHandler={addCreatorListHandler}
      updateCreatorListHandler={updateCreatorListHandler}
    ></EmailMatchResultsModalRow>
  ));

  return (
    <Row className="search-results-wrap">
      <Col md="12">
        <div className="table-responsive table-outer user-profile-table">
          <Table hover borderless>
            <thead>
              <tr>
                {dataColumnHeaders}
                <th className="mobile-view-past-report">
                  {/* {t(`creator_lists.list_table.${column}_header`)} */}
                </th>
                <th className="mobile-view-past-report">
                  {/* {t(`creator_lists.list_table.${column}_header`)} */}
                </th>
              </tr>
            </thead>
            <tbody>
              {creators && creators.length ? (
                tableData
              ) : (
                <tr>
                  <td colSpan={columns.length} align="center">
                    {/* {t("errors.no_record")} */}
                    No Records Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};

export default EmailMatchResultsModalTable;
