import React from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip
} from 'reactstrap';
import {
  capitalize,
  formatNumbersToCommaSeperated,
  showTitle
} from '../../../../actions';
import { RenderButton } from '../../../../components';
const LikesCreators = (props) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>Creators</CardTitle>
        <div className="card-content ch-9">
          <ListGroup className="card-list-items">
            {props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.creators ?
              props.postAnalysisDetails.posts_profile.creators.map((creator, index) => {
                if (index < 3) {
                  return <ListGroupItem key={index}><div className="avatar"><img src={creator.picture} alt="img" /></div>

                    <div id={`likes_${creator.fullname}`} title={showTitle(`likes_${creator.fullname}`, creator.fullname)} className="list-content"> <a rel="noopener noreferrer" target="_blank" href={creator.url}>{'@' + creator.username}</a>
                      <p>{capitalize(creator.fullname)}</p>
                    </div>
                  </ListGroupItem>
                }
                return null
              }) : null}
          </ListGroup>
        </div>
        <div className="bottom">
          <Button color="link" onClick={() => props.toggleModal("likesCreatorsModal")}>View More</Button>

          <Modal id="likesCreatorsModalDownload" isOpen={props.state.modals.likesCreatorsModal} toggle={() => props.toggleModal("likesCreatorsModal")} >
            <ModalHeader toggle={() => props.toggleModal("likesCreatorsModal")}>
              <span className="tooltip-button-wrap"><span className="text-tooltip">Creators</span>  <span href="#" id="likesCreatorsModal" className="tooltip-icon"></span></span>
              <UncontrolledTooltip placement="bottom" className="tooltip-content" target="likesCreatorsModal" >
                Hello world!
              </UncontrolledTooltip>
              <RenderButton onClick={() => props.downloadPdf("likesCreatorsModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
            </ModalHeader>
            <ModalBody>
              <Table className="modal-table">
                <thead>
                  <tr>
                    <th colSpan="2">Influencer</th>
                    <th className="text-right">Likes</th>
                    {/* <th className="text-right">Followers</th> */}
                    <th className="text-right">Posts Created</th>
                  </tr>
                </thead>
                <tbody>
                  {props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.creators ?
                    props.postAnalysisDetails.posts_profile.creators.map((creator, index) => {

                      return <tr key={index}>
                        <th scope="row" className="tb-media-left"><img src={creator.picture} alt="profile pic" className="rounded-circle" /></th>
                        <td className="tb-head"><h4> <a rel="noopener noreferrer" target="_blank" href={creator.url}>{'@' + creator.username}</a></h4>
                          <div className="country-text-wrap">
                            <div className="country-name">{creator.fullname}</div>
                          </div>
                        </td>
                        <td className="text-right">{formatNumbersToCommaSeperated(creator.engagements)}</td>
                        {/* <td className="text-right">{formatNumbersToCommaSeperated(creator.followers)}</td> */}
                        <td className="text-right">{formatNumbersToCommaSeperated(creator.posts_created)}</td>
                      </tr>

                    }) : <tr>
                      <td align="center" colSpan="4">No Record Found</td>
                    </tr>}
                </tbody>
              </Table>
            </ModalBody>

          </Modal>
        </div>
      </CardBody>
    </Card>
  )
}

export default LikesCreators
