import React from "react";
import { Table, Row, Col } from "reactstrap";
import CreatorListModalRow from "./creatorListModalRow";
import { useTranslation } from "react-i18next";

const CreatorListModalTable = (props) => {
  const { t } = useTranslation();
  const { creators, deleteCreator, handleAnalyze } = props;

  const columns = ["creator", "followers", "engagement", "date_added"];
  const columnHeaders = columns.map((column, index) => (
    <th key={index} className={index > 1 ? "mobile-view-past-report" : ""}>
      {t(`creator_lists.list_table.${column}_header`)}
    </th>
  ));

  const tableData = creators.map((creator) => (
    <CreatorListModalRow
      key={creator._id}
      creator={creator}
      handleAnalyze={handleAnalyze}
      deleteCreator={deleteCreator}
    ></CreatorListModalRow>
  ));

  return (
    <Row className="search-results-wrap">
      <Col md="12">
        <div className="table-responsive table-outer user-profile-table">
          <Table hover borderless>
            <thead>
              <tr>{columnHeaders}</tr>
            </thead>
            <tbody>
              {creators && creators.length ? (
                tableData
              ) : (
                <tr>
                  <td colSpan={columns.length} align="center">
                    {t("errors.no_record")}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};

export default CreatorListModalTable;
