import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { RenderSingleSelect } from "../../components";
import {
  audience_age_left,
  audience_age_right,
  influencer_age_left,
  influencer_age_right,
} from "../../helpers/Age";
import { engagement_rate } from "../../helpers/engagementRateOptions";
import { Field } from "formik";
import { showTooltip } from "../../actions";

const AgeFilter = (props) => {
  const { t } = useTranslation();
  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2"
      isOpen={props.state.filters.age}
      toggle={() => props.toggleFilter("age")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {t("discovery.filters.age.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-lg-1">
        <Row>
          <Col md="6" lg="6" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-audience">
                  {t("discovery.audience")}
                </h3>
              </span>
              <span id="ageAudience" className="tooltip-icon tool-top"></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="ageAudience"
              >
                {showTooltip("ageAudience")}
              </UncontrolledTooltip>
            )}
            <FormGroup className="select-field-col mw-none">
              <Field
                component={RenderSingleSelect}
                name="audiencesAgeLeft"
                placeholder={t("discovery.fields.from")}
                value={props.state.audiencesAgeLeft}
                onChange={(input) =>
                  props.handleSingleSelectChange(input, "audiencesAgeLeft")
                }
                options={audience_age_left.map((option) => {
                  return {
                    ...option,
                    isDisabled:
                      props.state.audiencesAgeRight &&
                      props.state.audiencesAgeRight.value
                        ? props.state.audiencesAgeRight.value <= option.value
                        : false,
                  };
                })}
              />
            </FormGroup>
            <FormGroup className="select-field-col mw-none">
              <Field
                component={RenderSingleSelect}
                name="audiencesAgeRight"
                placeholder={t("discovery.fields.to")}
                value={props.state.audiencesAgeRight}
                onChange={(input) =>
                  props.handleSingleSelectChange(input, "audiencesAgeRight")
                }
                options={audience_age_right.map((option) => {
                  return {
                    ...option,
                    isDisabled:
                      props.state.audiencesAgeLeft &&
                      props.state.audiencesAgeLeft.value
                        ? props.state.audiencesAgeLeft.value >= option.value
                        : false,
                  };
                })}
              />
            </FormGroup>
            <FormGroup className="select-field-col mini-select mw-none last">
              <Field
                component={RenderSingleSelect}
                name="audiencesAgeWeight"
                placeholder="weight"
                value={props.state.audiencesAgeWeight}
                onChange={(input) =>
                  props.handleSingleSelectChange(
                    input,
                    "audiencesAgeWeight",
                    true
                  )
                }
                options={engagement_rate}
              />
            </FormGroup>
          </Col>
          <Col md="6" lg="6" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span id="ageInfluencer" className="tooltip-icon tool-top"></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="ageInfluencer"
              >
                {showTooltip("ageInfluencer")}
              </UncontrolledTooltip>
            )}

            <FormGroup className="select-field-col  mw-none">
              <Field
                component={RenderSingleSelect}
                name="influencersAgeLeft"
                placeholder={t("discovery.fields.from")}
                value={props.state.influencersAgeLeft}
                onChange={(input) =>
                  props.handleSingleSelectChange(input, "influencersAgeLeft")
                }
                options={influencer_age_left.map((option) => {
                  option.isDisabled =
                    props.state.influencersAgeRight &&
                    props.state.influencersAgeRight.value
                      ? props.state.influencersAgeRight.value < option.value
                      : false;
                  return option;
                })}
              />
            </FormGroup>
            <FormGroup className="select-field-col  mw-none">
              <Field
                component={RenderSingleSelect}
                name="influencersAgeRight"
                placeholder={t("discovery.fields.to")}
                value={props.state.influencersAgeRight}
                onChange={(input) =>
                  props.handleSingleSelectChange(input, "influencersAgeRight")
                }
                options={influencer_age_right.map((option) => {
                  option.isDisabled =
                    props.state.influencersAgeLeft &&
                    props.state.influencersAgeLeft.value
                      ? props.state.influencersAgeLeft.value > option.value
                      : false;
                  return option;
                })}
              />
            </FormGroup>
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default AgeFilter;
