import {
  actionCreator,
  privateHeaders,
  checkHttpStatus,
  checkAuthTokenExpiry,
  stripePayment,
} from "../../../actions";
import {
  GET_TENSOR_LISTS_URL,
  CREATE_TENSOR_LISTS_URL,
  tensorListsActionTypes,
} from "../constants";
import { signOut } from "../../login/actions";

export const getTensorLists = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(tensorListsActionTypes.getTensorLists.REQUEST));
    fetch(`${GET_TENSOR_LISTS_URL}`, {
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(tensorListsActionTypes.getTensorLists.SUCCESS, response)
        );
      })
      .catch((error) => {
        // let errorMsg =  COMMON_ERROR_MESSAGE;
        dispatch(actionCreator(tensorListsActionTypes.getTensorLists.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const addTensorLists = (listData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(tensorListsActionTypes.createTensorLists.REQUEST));
    fetch(`${CREATE_TENSOR_LISTS_URL}`, {
      method: "POST",
      headers: privateHeaders(getState),
      body: JSON.stringify(listData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => stripePayment(response))
      .then((response) => {
        if (response.success) {
          dispatch(
            actionCreator(tensorListsActionTypes.createTensorLists.SUCCESS)
          );
          dispatch(getTensorLists());
        } else {
          dispatch(
            actionCreator(
              tensorListsActionTypes.createTensorLists.FAILURE,
              response.message
            )
          );
        }
      })
      .catch((error) => {
        // let errorMsg =  COMMON_ERROR_MESSAGE;
        // dispatch(actionCreator(tensorListsActionTypes.getTensorLists.FAILURE));
        if (
          error.statusCode &&
          error.statusCode >= 400 &&
          error.statusCode <= 500
        ) {
          dispatch(signOut(error));
        }
      });
  };
};

export const clearTensorListMsg = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(tensorListsActionTypes.createTensorLists.SUCCESS));
  };
};
