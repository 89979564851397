import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  RenderButton,
  RenderSingleSelect,
  RenderMutiSelect,
} from "../../components";
import { engagement_rate } from "../../helpers/engagementRateOptions";
import { Field } from "formik";
import { showTitle, showTooltip } from "../../actions";

const LocationFilter = (props) => {
  const { t } = useTranslation();

  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2 second sixth"
      isOpen={props.state.filters.location}
      toggle={() => props.toggleFilter("location")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {t("discovery.filters.location.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-lg">
        <Row>
          <Col sm="6" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-audience">
                  {t("discovery.audience")}
                </h3>
              </span>
              <span id="locationAudience" className="tooltip-icon"></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="locationAudience"
              >
                {showTooltip("locationAudience")}
              </UncontrolledTooltip>
            )}
            <Field
              component={RenderMutiSelect}
              name="audienceLocation"
              options={props.audiencesFilteredLoactions}
              onChange={(values) =>
                props.handleMultipleDropdownChange(values, "audienceLocation")
              }
              onInputChange={(value) =>
                props.getLoactionBasedOnFilterValue(
                  "audiencesFilteredLoactions",
                  value
                )
              }
              placeholder={
                props.state.platformValue?.value === "instagram"
                  ? t("discovery.filters.location.placeholder.city")
                  : t("discovery.filters.location.placeholder.country")
              }
              value={props.state.audienceLocationValues}
            />
            {props.state.audienceLocationValues &&
            props.state.audienceLocationValues.length ? (
              <Row className="selected-tags-bottom left-audience">
                <Col sm="12" className="col active-filters-wrap">
                  <div className="active-filters inside">
                    <ul className="list-inline">
                      {(props.state.audienceLocationValues || []).map(
                        (option, index) => (
                          <li key={index}>
                            <div className="li-audience">
                              <p
                                title={showTitle(
                                  `aud_loc_inside_${option.value}`,
                                  option.label
                                )}
                                id={`aud_loc_inside_${option.value}`}
                              >
                                {option.label}
                              </p>
                              <RenderButton
                                close
                                onClick={() =>
                                  props.removeMultipleDropdownFilters(
                                    option,
                                    "audienceLocationValues"
                                  )
                                }
                              />
                            </div>
                            <FormGroup key={index}>
                              <Field
                                className="li-weight"
                                component={RenderSingleSelect}
                                name={`locationWeight${index}`}
                                placeholder={t(
                                  "discovery.fields.select_weight"
                                )}
                                value={option.weight}
                                onChange={(option) =>
                                  props.handleSingleSelectWeight(
                                    option,
                                    "audienceLocationValues",
                                    index
                                  )
                                }
                                options={engagement_rate}
                              />
                            </FormGroup>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>

          <Col sm="6" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span
                id="locationInfluencer"
                className="tooltip-icon tool-top"
              ></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="locationInfluencer"
              >
                {showTooltip("locationInfluencer")}
              </UncontrolledTooltip>
            )}
            <Field
              component={RenderMutiSelect}
              name="influencerLocation"
              options={props.influencersFilteredLocations}
              onChange={(values) =>
                props.handleMultipleDropdownChange(values, "influencerLocation")
              }
              numberDisplayed={0}
              placeholder={
                props.state.platformValue?.value === "instagram"
                  ? t("discovery.filters.location.placeholder.city")
                  : t("discovery.filters.location.placeholder.country")
              }
              onInputChange={(value) =>
                props.getLoactionBasedOnFilterValue(
                  "influencersFilteredLocations",
                  value
                )
              }
              value={props.state.influencerLocationValues}
            />
            {props.state.influencerLocationValues &&
            props.state.influencerLocationValues.length ? (
              <Row className="selected-tags-bottom">
                <Col sm="12" className="col active-filters-wrap">
                  <div className="active-filters inside">
                    <ul className="list-inline">
                      {(props.state.influencerLocationValues || []).map(
                        (option, index) => (
                          <li key={index}>
                            <div className="li-influencer">
                              <p
                                title={showTitle(
                                  `inf_loc_inside_${option.value}`,
                                  option.label
                                )}
                                id={`inf_loc_inside_${option.value}`}
                              >
                                {option.label}
                              </p>
                              <RenderButton
                                close
                                onClick={() =>
                                  props.removeMultipleDropdownFilters(
                                    option,
                                    "influencerLocationValues"
                                  )
                                }
                              />
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default LocationFilter;
