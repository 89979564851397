import { actionCreator, privateHeaders, checkHttpStatus, checkAuthTokenExpiry } from "../../../actions";
import { UserBalanceDataActionTypes, GET_USER_BALANCE_DATA_URL } from "../constants";
import { checkSessionExistence, signOut } from "../../login/actions";

export const getUserBalanceData = (userId) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(UserBalanceDataActionTypes.getUserBalanceData.REQUEST));
    fetch(`${GET_USER_BALANCE_DATA_URL}${userId}/balance`, {
      method: 'GET',
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then(response => {
        dispatch(actionCreator(UserBalanceDataActionTypes.getUserBalanceData.SUCCESS, response));

        if (response.redirect && response.redirect === "PAYMENT_PAGE") {
          dispatch(checkSessionExistence());
        }
      }).catch(error => {
        dispatch(actionCreator(UserBalanceDataActionTypes.getUserBalanceData.FAILURE));
        if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
          dispatch(signOut(error, false));
        }
      })
  }
}
