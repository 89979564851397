import languages from "../../helpers/Languages";
import { engagement_rate } from "../../helpers/engagementRateOptions";
import { growthIntervals, growthRate } from "../../helpers/growthOptions";
import { formatNumbersToUnits } from "../../actions";

const calculateInitialStateFromSavedFiltersList = (
  initialState,
  savedFilters
) => {
  let searchObj = savedFilters.filters && savedFilters.filters.search;
  if (searchObj && savedFilters.data) {
    initialState.sortColumn =
      savedFilters.filters &&
      savedFilters.filters.sort &&
      savedFilters.filters.sort.field;
    let dataObj = savedFilters.data;

    initialState.platformValue =
      (dataObj.platformValue &&
        initialState.platformOptions.find(
          (item) => item.value === dataObj.platformValue.value
        )) ||
      null;
    if (searchObj && searchObj.audience_relevance) {
      initialState.audienceLookalikeValue = searchObj.audience_relevance.value
        ? {
            value: searchObj.audience_relevance.value,
            label: `@${searchObj.audience_relevance.value}`,
          }
        : null;
    }
    if (dataObj && dataObj.lookInfluencer) {
      initialState.influencerLookalikeValue = {
        value: dataObj.lookInfluencer,
        label: `@${dataObj.lookInfluencer}`,
      };
    }
    if (dataObj && dataObj.influencerLookalikeValue) {
      initialState.influencerLookalikeValue = {
        ...dataObj.influencerLookalikeValue,
      };
    }
    if (dataObj && dataObj.relevance) {
      initialState.relevance = dataObj.relevance || null;
    }
    initialState.showRelevance =
      dataObj.relevance && dataObj.relevance.length ? true : false;
    if (searchObj && searchObj.text) {
      initialState.bio = searchObj.text;
    }
    if (searchObj && searchObj.keywords) {
      initialState.keywords = searchObj.keywords;
    }
    if (searchObj && searchObj.username) {
      initialState.username = searchObj.username.value;
    }
    if (dataObj && dataObj.emailMatch_id) {
      initialState.emailMatch_id = dataObj.emailMatch_id;
    }
    if (dataObj && dataObj.creatorList_id) {
      initialState.creatorList_id = dataObj.creatorList_id;
    }
    if (dataObj && dataObj.audience_geo && dataObj.audience_geo.length) {
      initialState.audienceLocationValues = dataObj.audience_geo.map(
        (loc) => loc
      );
    }
    if (dataObj && dataObj.geo && dataObj.geo.length) {
      initialState.influencerLocationValues = dataObj.geo.map((loc) => loc);
    }

    if (dataObj && dataObj.university) {
      initialState.universityValue = dataObj.university;
    }
    if (dataObj && dataObj.with_contact && dataObj.with_contact.length) {
      initialState.contactsValues = dataObj.with_contact.map((loc) => loc);
    }
    if (searchObj && searchObj.audience_lang) {
      initialState.audienceLanguage = {
        label:
          (languages.find(
            (item) => item.value === searchObj.audience_lang.code
          ) &&
            languages.find(
              (item) => item.value === searchObj.audience_lang.code
            ).label) ||
          "",
        value: searchObj.audience_lang.code,
        radioButtonValueOfState: "audienceLanguage",
      };
      initialState.languageWeight = searchObj.audience_lang.weight
        ? {
            label:
              engagement_rate.find(
                (item) =>
                  item.value === parseFloat(searchObj.audience_lang.weight)
              ) &&
              engagement_rate.find(
                (item) =>
                  item.value === parseFloat(searchObj.audience_lang.weight)
              ).label,
            value: parseFloat(searchObj.audience_lang.weight),
          }
        : { label: "", value: "" };
    }
    if (searchObj && searchObj.lang) {
      initialState.influencerLanguage = {
        label:
          (languages.find((item) => item.value === searchObj.lang.code) &&
            languages.find((item) => item.value === searchObj.lang.code)
              .label) ||
          "",
        value: searchObj.lang.code,
        radioButtonValueOfState: "influencerLanguage",
      };
    }
    if (searchObj?.followers) {
      initialState.followersFrom = searchObj.followers.left_number && {
        label: formatNumbersToUnits(searchObj.followers.left_number),
        value: searchObj.followers.left_number,
      };

      initialState.followersTo = searchObj.followers.right_number && {
        label: formatNumbersToUnits(searchObj.followers.right_number),
        value: searchObj.followers.right_number,
      };
    }
    if (searchObj?.views) {
      initialState.viewsFrom = searchObj.views.left_number && {
        label: formatNumbersToUnits(searchObj.views.left_number),
        value: searchObj.views.left_number,
      };

      initialState.viewsTo = searchObj.views.right_number && {
        label: formatNumbersToUnits(searchObj.views.right_number),
        value: searchObj.views.right_number,
      };
    }
    if (searchObj?.reels_plays) {
      initialState.reelsFrom = searchObj.reels_plays.left_number && {
        label: formatNumbersToUnits(searchObj.reels_plays.left_number),
        value: searchObj.reels_plays.left_number,
      };

      initialState.reelsTo = searchObj.reels_plays.right_number && {
        label: formatNumbersToUnits(searchObj.reels_plays.right_number),
        value: searchObj.reels_plays.right_number,
      };
    }
    if (dataObj && dataObj.audience_brand && dataObj.audience_brand.length) {
      initialState.audienceBrandsValues = dataObj.audience_brand.map(
        (loc) => loc
      );
    }
    if (dataObj && dataObj.brand && dataObj.brand.length) {
      initialState.influencerBrandsValues = dataObj.brand.map((loc) => loc);
    }
    if (
      dataObj &&
      dataObj.audience_brand_category &&
      dataObj.audience_brand_category.length
    ) {
      initialState.audienceInterestsValues =
        dataObj.audience_brand_category.map((loc) => loc);
    }
    if (dataObj && dataObj.brand_category && dataObj.brand_category.length) {
      initialState.influencerInterestsValues = dataObj.brand_category.map(
        (loc) => loc
      );
    }
    if (searchObj && searchObj.audience_gender) {
      initialState.genderAudience = searchObj.audience_gender.code;
      initialState.genderWeight = searchObj.audience_gender.weight
        ? {
            label:
              engagement_rate.find(
                (item) =>
                  item.value === parseFloat(searchObj.audience_gender.weight)
              ) &&
              engagement_rate.find(
                (item) =>
                  item.value === parseFloat(searchObj.audience_gender.weight)
              ).label,
            value: parseFloat(searchObj.audience_gender.weight),
          }
        : { label: "", value: "" };
    }
    if (searchObj && searchObj.gender) {
      initialState.genderInfluencer = searchObj.gender.code;
    }
    if (searchObj && searchObj.followers_growth) {
      initialState.followersGrowth = {
        label:
          (growthIntervals.find(
            (item) => item.value === searchObj.followers_growth.interval
          ) &&
            growthIntervals.find(
              (item) => item.value === searchObj.followers_growth.interval
            ).label) ||
          "",
        value: searchObj.followers_growth.interval,
      };
      initialState.followersGrowthWeight = searchObj.followers_growth.value
        ? {
            label:
              growthRate.find(
                (item) =>
                  item.value === parseFloat(searchObj.followers_growth.value)
              ) &&
              growthRate.find(
                (item) =>
                  item.value === parseFloat(searchObj.followers_growth.value)
              ).label,
            value: parseFloat(searchObj.followers_growth.value),
          }
        : { label: "> 150%", value: 1.5 };
    }
    if (searchObj && searchObj.total_likes_growth) {
      initialState.likesGrowth = {
        label:
          (growthIntervals.find(
            (item) => item.value === searchObj.total_likes_growth.interval
          ) &&
            growthIntervals.find(
              (item) => item.value === searchObj.total_likes_growth.interval
            ).label) ||
          "",
        value: searchObj.total_likes_growth.interval,
      };
      initialState.likesGrowthWeight = searchObj.total_likes_growth.value
        ? {
            label:
              growthRate.find(
                (item) =>
                  item.value === parseFloat(searchObj.total_likes_growth.value)
              ) &&
              growthRate.find(
                (item) =>
                  item.value === parseFloat(searchObj.total_likes_growth.value)
              ).label,
            value: parseFloat(searchObj.total_likes_growth.value),
          }
        : { label: "> 150%", value: 1.5 };
    }
    if (searchObj && searchObj.total_views_growth) {
      initialState.viewsGrowth = {
        label:
          (growthIntervals.find(
            (item) => item.value === searchObj.total_views_growth.interval
          ) &&
            growthIntervals.find(
              (item) => item.value === searchObj.total_views_growth.interval
            ).label) ||
          "",
        value: searchObj.total_views_growth.interval,
      };
      initialState.viewsGrowthWeight = searchObj.total_views_growth.value
        ? {
            label:
              growthRate.find(
                (item) =>
                  item.value === parseFloat(searchObj.total_views_growth.value)
              ) &&
              growthRate.find(
                (item) =>
                  item.value === parseFloat(searchObj.total_views_growth.value)
              ).label,
            value: parseFloat(searchObj.total_views_growth.value),
          }
        : { label: "> 150%", value: 1.5 };
    }
    if (searchObj && searchObj.audience_race) {
      initialState.ethnicityAudience = searchObj.audience_race.code;
      initialState.ethnicityWeight = searchObj.audience_race.weight
        ? {
            label:
              engagement_rate.find(
                (item) =>
                  item.value === parseFloat(searchObj.audience_race.weight)
              ) &&
              engagement_rate.find(
                (item) =>
                  item.value === parseFloat(searchObj.audience_race.weight)
              ).label,
            value: parseFloat(searchObj.audience_race.weight),
          }
        : { label: "", value: "" };
    }
    if (dataObj) {
      if (dataObj.audiencesAgeLeft) {
        initialState.audiencesAgeLeft = dataObj.audiencesAgeLeft;
      }
      if (dataObj.audiencesAgeRight) {
        initialState.audiencesAgeRight = dataObj.audiencesAgeRight;
      }
      if (dataObj.audiencesAgeWeight) {
        initialState.audiencesAgeWeight = dataObj.audiencesAgeWeight;
      }
      if (dataObj.influencersAgeLeft) {
        initialState.influencersAgeLeft = dataObj.influencersAgeLeft;
      }
      if (dataObj.influencersAgeRight) {
        initialState.influencersAgeRight = dataObj.influencersAgeRight;
      }
      if (dataObj.engagementsInfluencersFrom) {
        initialState.engagementsInfluencersFrom =
          dataObj.engagementsInfluencersFrom;
      }
      if (dataObj.engagementsInfluencersTo) {
        initialState.engagementsInfluencersTo =
          dataObj.engagementsInfluencersTo;
      }
      if (dataObj.engagementRate) {
        initialState.engagementRate = dataObj.engagementRate;
      }
      if (dataObj.engagementRateOperator) {
        initialState.engagementRateOperator = dataObj.engagementRateOperator;
      }
      if (dataObj.accountTypeFilters) {
        initialState.accountTypeFilters = dataObj.accountTypeFilters;
      }
      if (dataObj.audienceType) {
        initialState.audienceType = dataObj.audienceType;
      }
      if (dataObj.otherFilters) {
        initialState.otherFilters = dataObj.otherFilters;
      }
      if (dataObj.allSelectedFilters) {
        initialState.allSelectedFilters = dataObj.allSelectedFilters;
      }
      if (dataObj.selectedThreeTableColumns) {
        initialState.selectedThreeTableColumns =
          dataObj.selectedThreeTableColumns;
      }
    }
  }

  return initialState;
};
export default calculateInitialStateFromSavedFiltersList;
