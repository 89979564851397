import React, { Component } from 'react'

import { Collapse, Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { getUserManagementData, getUserDetailsForEdit, grantAPIAccessAction, revokeAPIAccessAction, grantTrialAccessAction, updateCreditsBalanceAction, clearTrialAccessMessage, clearCardUpdateMessage, cardDetailsUpdateAction, getSubscriptionPlansDetails, editSubscriptionPlansDetails, clearPlanUpdateErrorMsg, getSearchResult, getDemoLink, clearDemoLinksErrorMsg } from './actions/index';
import './style.scss';
import UserManagementSection from './userManagementSection';
import { showSuccessMessage, showErrorMessage } from '../../actions';
import SubscriptionPlans from './subscriptionPlans';
import { injectStripe } from 'react-stripe-elements';
import DemoLinks from './demoLinks';
import { store } from '../../store';
class AdminPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLeftSidebarOpen: false,
      activeLink: '0',
      activeUserManagementPage: 1,
      card_error: false,
      card_number: '',
      error_monthYear: false,
      month_year: '',
      cvv: '',
      error_cvv: false,
      cardBilling: false,
      posts: false,
      exportsTextField: false,
      reportsTextField: false,
      totalBalanceTextField: false,
      editGrowthClicked: false,
      editStandardClicked: false,
      editProfessionalClicked: false,
      growthReports: '',
      standardExports: '',
      standardReports: '',
      professionalReports: '',
      professionalExports: '',
      professionalPostAnalysis: '',
      isSearchFieldEmpty: true,
      searchFieldValue: '',
    };
  }
  componentDidMount() {
    this.props.getUserManagementData(1);
    this.props.getSubscriptionPlansDetails();
  }

  toggleButton = () => {
    this.setState({
      isLeftSidebarOpen: !this.state.isLeftSidebarOpen
    })
  }
  toggleBilling = () => {
    this.setState((prevState) => ({
      cardBilling: !prevState.cardBilling
    }))
  }
  handleChangeStripe = ({ error, ...props }) => {

    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };
  handleActiveLink = (activeLink) => {
    this.setState({
      activeLink: activeLink
    })
  }
  handleTextChange = (input) => {
    input.persist();
    this.setState({
      [input.target.name]: (input.target.value && input.target.value.replace(/[^\d]/, '')) || ''
    })
  }
  handleEditButton = (planType) => {
    switch (planType) {
      case 'growth':
        this.setState({
          editGrowthClicked: !this.state.editGrowthClicked
        })
        break;
      case 'standard':
        this.setState({
          editStandardClicked: !this.state.editStandardClicked
        })
        break;
      case 'professional':
        this.setState({
          editProfessionalClicked: !this.state.editProfessionalClicked
        })
        break;
      default: return false;
    }

  }
  handleCancelButton = (planType) => {
    switch (planType) {
      case 'growth':
        this.setState({
          editGrowthClicked: false
        })
        break;
      case 'standard':
        this.setState({
          editStandardClicked: false
        })
        break;
      case 'professional':
        this.setState({
          editProfessionalClicked: false
        })
        break;
      default: return false;
    }
  }
  handleSaveButton = (planType, plan) => {

    switch (planType) {
      case 'growth':
        {
          let defaultPlan = (this.props.subscriptionPlansDetails && this.props.subscriptionPlansDetails.data && this.props.subscriptionPlansDetails.data.monthly && this.props.subscriptionPlansDetails.data.monthly.find(plan => plan.nickname && plan.nickname.toLowerCase() === 'growth'));
          let postData = {
            plan_id: plan.plan_id,
            services: { reports: (this.state.growthReports && parseInt(this.state.growthReports, 10)) || (defaultPlan && defaultPlan.services && defaultPlan.services.reports) || 0 }
          };
          this.props.editSubscriptionPlansDetails(postData);
          break;

        }
      case 'standard':
        {
          let defaultPlan = (this.props.subscriptionPlansDetails && this.props.subscriptionPlansDetails.data && this.props.subscriptionPlansDetails.data.monthly && this.props.subscriptionPlansDetails.data.monthly.find(plan => plan.nickname && plan.nickname.toLowerCase() === 'standard'));
          let postData = {
            plan_id: plan.plan_id,
            services: {
              reports: (this.state.standardReports && parseInt(this.state.standardReports, 10)) || (defaultPlan && defaultPlan.services && defaultPlan.services.reports) || 0,
              exports: (this.state.standardExports && parseInt(this.state.standardExports, 10)) || (defaultPlan && defaultPlan.services && defaultPlan.services.exports) || 0,
            }
          };
          this.props.editSubscriptionPlansDetails(postData);
          break;

        }

      case 'professional':
        {
          let plans = this.props.subscriptionPlansDetails;
          let defaultPlan = (plans && plans.data && plans.data.monthly && plans.data.monthly.find(plan => plan.nickname && plan.nickname.toLowerCase() === 'professional'));
          let postData = {
            plan_id: plan.plan_id,
            services: {
              reports: (this.state.professionalReports && parseInt(this.state.professionalReports, 10)) || (defaultPlan && defaultPlan.services && defaultPlan.services.reports) || 0,
              exports: (this.state.professionalExports && parseInt(this.state.professionalExports, 10)) || (defaultPlan && defaultPlan.services && defaultPlan.services.exports) || 0,
              posts: (this.state.professionalPostAnalysis && parseInt(this.state.professionalPostAnalysis, 10)) || (defaultPlan && defaultPlan.services && defaultPlan.services.posts) || 0,
            }
          };
          this.props.editSubscriptionPlansDetails(postData);
          break;

        }
      default: {
        return true;
      }
    }
  }

  renderCardImage = () => {
    const details = this.props.userDetailsById && this.props.userDetailsById.data && this.props.userDetailsById.data.subscription && Object.keys(this.props.userDetailsById.data.subscription).length && this.props.userDetailsById.data.subscription.card;
    const type = details && details.type ? details.type : '';
    const colorStyle = {
      color: 'dodgerblue',
      fontSize: '44px',
      marginLeft: '16px',
      marginRight: '15px'
    };
    if (type.toLowerCase() === 'visa') {
      return (<i className="fa fa-cc-visa fa-2x" style={colorStyle}></i>);
    } else if (type.toLowerCase().includes("master")) {
      return (<i className="fa fa-cc-mastercard fa-2x" style={colorStyle}></i>);
    } else if (type.toLowerCase().includes("amex")) {
      return (<i className="fa fa-cc-amex fa-2x" style={colorStyle}></i>);
    } else if (type.toLowerCase().includes('discover')) {
      return (<i className="fa fa-cc-discover fa-2x" style={colorStyle}></i>);
    } else {
      return (<i className="fa fa-credit-card-alt fa-2x" style={colorStyle}></i>);
    }
  }
  handleSubmitStripe = (values, formik, user) => {

    if (this.props.stripe) {
      this.props.stripe.createToken().then(response => {
        this.setState({ stripe_token: response.token.id });
        const postData = {
          token: response.token.id,
          // plan_id: this.props.choosePlanDetails.id
        }
        this.props.cardDetailsUpdateAction(user.id, postData, !this.state.isSearchFieldEmpty ? this.state.searchFieldValue : false, this.state.activeUserManagementPage);
        this.cancelEditBilling(formik)
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }

  }

  handleCardChange = (input) => {
    input.persist();
    let card = input.target.value ? input.target.value.trim().replace(/[^\d]/, '') : [];
    card = card.length ? card.match(/[0-9]{1,4}/g) : [];
    this.setState({
      card_number: card.join(' ')
    })
    if (input.target.value.length < 19) {
      this.setState({
        card_error: true
      })
    } else {
      this.setState({
        card_error: false
      })
    }
  }
  handleCVVChange = (input) => {
    input.persist();
    const re = /^[0-9\b]+$/;
    const name = input.target.name;
    if (name === 'cvv') {
      if (input.target.value === '' || re.test(input.target.value)) {
        this.setState({
          cvv: input.target.value
        });
      }
      if (input.target.value.length < 3) {
        this.setState({
          error_cvv: true
        })
      } else {
        this.setState({
          error_cvv: false
        })
      }
    }
  }
  handleBalanceUpdate = (values, user) => {

    let postData = { ...values };
    postData.exports = (postData.exports && parseInt(postData.exports, 10)) || ((this.props.userDetailsById && this.props.userDetailsById.data && this.props.userDetailsById.data.balance && this.props.userDetailsById.data.balance.exports) || 0);

    postData.posts = (postData.posts && parseInt(postData.posts, 10)) || ((this.props.userDetailsById && this.props.userDetailsById.data && this.props.userDetailsById.data.balance && this.props.userDetailsById.data.balance.posts) || 0);

    postData.reports = (postData.reports && parseInt(postData.reports, 10)) || ((this.props.userDetailsById && this.props.userDetailsById.data && this.props.userDetailsById.data.balance && this.props.userDetailsById.data.balance.reports) || 0);
    postData.total_balance = (postData.totalBalance && parseInt(postData.totalBalance, 10)) || ((this.props.userDetailsById && this.props.userDetailsById.data && this.props.userDetailsById.data.balance && this.props.userDetailsById.data.balance.total_balance) || 0);
    delete postData.totalBalance;
    delete postData.cardHolderName;


    this.props.updateCreditsBalanceAction(user.id, postData, !this.state.isSearchFieldEmpty ? this.state.searchFieldValue : false, this.state.activeUserManagementPage);
  }

  getUserDetails = (user) => {
    this.setState((prevState) => {
      return {
        activeUserId: prevState.activeUserId === user.id ? null : user.id
      }
    }, () => {
      if (this.state.activeUserId) {
        this.props.getUserDetailsForEdit(this.state.activeUserId);
      }
    })
  }
  keyPressFunc = (e) => {
    if (e.which === 8) {
      let val = this.state.month_year;
      if (val.length === 3) {
        val = val.slice(0, val.length - 1);
        this.setState({ month_year: val })
      }
    }
  }
  toggleBalance = (balance) => {
    this.setState((prevState) => ({
      [balance]: !prevState[balance]
    }))
  }
  cancelEditBilling = (formik) => {
    this.setState((prevState) => ({
      cardBilling: !prevState.cardBilling,
      month_year: '',
      card_number: '',
      cvv: '',
      card_error: false,
      error_monthYear: false,
      error_cvv: false
    }), () => formik.current.resetForm())
  }
  handleCardMonth = (input) => {
    input.persist();
    const re = /^[0-9\b/]+$/;
    if (input.target.value === '' || re.test(input.target.value)) {
      let val = input.target.value
      if (val.length === 2) {
        val += '/';
      }
      this.setState({
        month_year: val
      });
      if (val.length < 5) {
        this.setState({
          error_monthYear: true
        })
      } else {
        this.setState({ error_monthYear: false })
      }
    }
  }
  handlePageChange = (page, pageNo) => {
    switch (page) {
      case '0':
        this.setState({
          activeUserManagementPage: pageNo,
          activeUserId: null,
        },
          () => this.props.getUserManagementData(pageNo))
        break
      default:
        return null;
    }
  }
  grantAPIAccess = (user) => {
    this.props.grantAPIAccessAction(user.id, !this.state.isSearchFieldEmpty ? this.state.searchFieldValue : false, this.state.activeUserManagementPage);
  }
  revokeAPIAccess = (user) => {
    this.props.revokeAPIAccessAction(user.id, !this.state.isSearchFieldEmpty ? this.state.searchFieldValue : false, this.state.activeUserManagementPage);
  }
  grantTrialAccess = (user) => {
    this.props.grantTrialAccessAction(user.id, !this.state.isSearchFieldEmpty ? this.state.searchFieldValue : false, this.state.activeUserManagementPage);
  }
  closeMessage = () => {
    this.props.clearTrialAccessMessage();
  }
  closeCardUpdateMessage = () => {
    this.props.clearCardUpdateMessage();
  }
  closePlanUpdateErrorMsg = () => {
    this.props.clearPlanUpdateErrorMsg();
  }
  closeDemoLinksError = () => {
    this.props.clearDemoLinksErrorMsg();
  }
  searchUser = (ev) => {
    if (ev.target.value && ev.target.value.length > 2) {
      this.props.getSearchResult(ev.target.value.trim());
      this.setState({
        isSearchFieldEmpty: false,
        searchFieldValue: ev.target.value
      })
    } else if (ev.target.value.length === 0) {
      this.props.getUserManagementData(this.state.activeUserManagementPage || 1)
      this.setState({
        isSearchFieldEmpty: true,
        searchFieldValue: ''
      })
    }
  }
  onSubmitDemoLinks = (values) => {
    let postData = { ...values };
    postData.expiration = (postData.expiration && parseInt(postData.expiration, 10)) || ''
    this.props.getDemoLink(postData)

  }

  render() {
    return (
      <>
        {this.props.demoLinksError ? showErrorMessage(store, this.props.demoLinksError, this.closeDemoLinksError) : null}
        {this.props.planUpdateErrorMsg ? showErrorMessage(store,this.props.planUpdateErrorMsg, this.closePlanUpdateErrorMsg) : null}
        {this.props.trailAccessSuccessMessage ? showSuccessMessage(this.props.trailAccessSuccessMessage, this.closeMessage) : null}
        {this.props.trailAccessErrorMessage ? showErrorMessage(store, this.props.trailAccessErrorMessage, this.closeMessage) : null}
        {this.props.cardUpdateSuccessMessage ? showSuccessMessage(this.props.cardUpdateSuccessMessage, this.closeCardUpdateMessage) : null}
        {this.props.cardUpdateErrorMessage ? showErrorMessage(store,this.props.cardUpdateErrorMessage, this.closeCardUpdateMessage) : null}
        <div className={`admin-panel-body ${this.state.isLeftSidebarOpen ? 'nav-open' : ''}`}>
          <div className="admin-sidebar">
            <button className="btn sidebar-toggle" type="button" data-toggle="collapse" onClick={() => this.toggleButton()}>
              <i className="fa fa-bars"></i>
            </button>
            <div className="sidebar-wrap">
              <button className="btn sidebar-toggle-close" type="button" data-toggle="collapse" onClick={() => this.toggleButton()}>
                <i className="fa fa-close"></i>
              </button>
              <Navbar className="dashboard-nav">
                <Collapse isOpen={this.state.isLeftSidebarOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    <NavItem onClick={() => this.handleActiveLink('0')} className={this.state.activeLink === '0' ? "active" : ""}>
                      <NavLink >User Management</NavLink>
                    </NavItem>
                    <NavItem onClick={() => this.handleActiveLink('1')} className={this.state.activeLink === '1' ? "active" : ""}>
                      <NavLink  >Subscription Plans</NavLink>
                    </NavItem>
                    <NavItem onClick={() => this.handleActiveLink('2')} className={this.state.activeLink === '2' ? "active" : ""}>
                      <NavLink>Demo Links</NavLink>
                    </NavItem>
                    <NavItem onClick={() => this.handleActiveLink('3')} className={this.state.activeLink === '3' ? "active" : ""}>
                      <NavLink >Nav item 4</NavLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
          </div>
          {this.state.activeLink === '0' ?
            <UserManagementSection
              {...this.props}
              state={this.state}
              revokeAPIAccess={this.revokeAPIAccess}
              grantAPIAccess={this.grantAPIAccess}
              toggleBilling={this.toggleBilling}
              toggleBalance={this.toggleBalance}
              renderCardImage={this.renderCardImage}
              handleCardMonth={this.handleCardMonth}
              keyPressFunc={this.keyPressFunc}
              handleSubmitStripe={this.handleSubmitStripe}
              handleChangeStripe={this.handleChangeStripe}
              // handleCardUpdate={this.handleCardUpdate}
              handleCardChange={this.handleCardChange}
              getUserDetails={this.getUserDetails}
              handlePageChange={this.handlePageChange}
              handleCVVChange={this.handleCVVChange}
              initialValues={this.props.initialValues}
              cancelEditBilling={this.cancelEditBilling}
              grantTrialAccess={this.grantTrialAccess}
              handleBalanceUpdate={this.handleBalanceUpdate}
              searchUser={this.searchUser}
            /> : null}
          {this.state.activeLink === '1' ?
            <SubscriptionPlans

              state={this.state}
              initialValues={this.props.initialValues}
              handleEditButton={this.handleEditButton}
              handleCancelButton={this.handleCancelButton}
              handleSaveButton={this.handleSaveButton}
              handleTextChange={this.handleTextChange}
              subscriptionPlansDetails={this.props.subscriptionPlansDetails}
            /> : null}
          {this.state.activeLink === '2' ?
            <DemoLinks
              state={this.state}

              initialValuesForDemoLinks={this.props.initialValuesForDemoLinks}
              onSubmitDemoLinks={this.onSubmitDemoLinks}
              demoLinkdata={this.props.demoLinkdata}
            />
            : null}
        </div>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    userManagementData: state.adminPanel.userManagementData,
    userDetailsById: state.adminPanel.userDetailsById,
    subscriptionPlansDetails: state.adminPanel.subscriptionPlansDetails,
    trailAccessSuccessMessage: state.adminPanel.trailAccessSuccessMessage,
    trailAccessErrorMessage: state.adminPanel.trailAccessErrorMessage,
    cardUpdateSuccessMessage: state.adminPanel.cardUpdateSuccessMessage,
    cardUpdateErrorMessage: state.adminPanel.cardUpdateErrorMessage,
    planUpdateErrorMsg: state.adminPanel.planUpdateErrorMsg,
    demoLinkdata: state.adminPanel.demoLinkdata,
    demoLinksError: state.adminPanel.demoLinksError,
    initialValues: {
      reports: '',
      posts: '',
      exports: '',

    },
    initialValuesForDemoLinks: {
      name: '',
      email: '',
      company: '',
      expiration: '',


    }
  }
}
export default connect(mapStateToProps, { getUserManagementData, getUserDetailsForEdit, grantAPIAccessAction, revokeAPIAccessAction, grantTrialAccessAction, updateCreditsBalanceAction, clearTrialAccessMessage, clearCardUpdateMessage, cardDetailsUpdateAction, getSubscriptionPlansDetails, editSubscriptionPlansDetails, clearPlanUpdateErrorMsg, getSearchResult, getDemoLink, clearDemoLinksErrorMsg })(injectStripe(AdminPanel));
