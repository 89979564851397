import React, { useState, useEffect } from "react";
import { ArrowSmallUpIcon } from "@heroicons/react/24/outline";

import "./index.scss";

const ScrollToTop = ({ setActiveTab, ...props }) => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const goToTop = () => {
    setActiveTab("0");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="top-to-btm">
      {showTopBtn && (
        <ArrowSmallUpIcon
          className="icon-position icon-style"
          onClick={goToTop}
        />
      )}
    </div>
  );
};
export default ScrollToTop;
