import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Modal, ModalHeader, ModalBody } from "reactstrap";
import "../index.scss";
import {
  SubmitButton,
  RenderTextFieldWithoutAutoComplete,
} from "../../../components";
import { UserPlusIcon } from "@heroicons/react/20/solid";
import {
  RenderLabel,
  RenderSearchableSingleSelect,
  RenderRadioButton,
} from "../../../components";
import { Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import {
  createCampaign,
  createCampaignPost,
  getPostPreview,
  setNewPostModalIsOpen,
} from "../actions";
import CampaignPostDisplayCard from "../components/CampaignPostDisplayCard";
import Loader from "loader";
import PostUrlForm from "../components/PostUrlForm";

const CreateCampaignField = ({ ...props }) => {
  return (
    <div className="create-form">
      <Field
        className="create-campaign-field"
        name="newCampaignName"
        id="newCampaignName"
        component={RenderTextFieldWithoutAutoComplete}
        placeholder="Enter Campaign Name"
      />
    </div>
  );
};

const UpdateCampaignField = ({
  campaignToUpdate,
  setCampaignToUpdate,
  formattedCampaigns,
  ...props
}) => {
  return (
    <div className="update-form">
      <Field
        component={RenderSearchableSingleSelect}
        name="selectedCampaignToUpdate"
        id="selectedCampaignToUpdate"
        className="mt-0"
        placeholder="Select Existing Campaign"
        value={campaignToUpdate}
        onChange={(input) => setCampaignToUpdate(input)}
        options={formattedCampaigns}
      />
    </div>
  );
};

const NewPostModal = ({
  campaigns,
  collectPostUrlInModal,
  postUrlOverride,
  defaultCampaign,
  closedNewPostModalHandler,

  newPostModalIsOpen,
  loadingPostPreview,
  loadingNewPostModal,

  postPreview,
  postPreviewImage,
  creatorPreviewImage,

  setNewPostModalIsOpen,
  getPostPreview,
  createCampaign,
  createCampaignPost,

  ...props
}) => {
  const radioOptions = [
    { value: "CREATE", text: "Create new campaign" },
    { value: "UPDATE", text: "Add to existing campaign" },
  ];

  const formatCampaign = (campaign) => {
    return {
      value: campaign._id,
      label: campaign.name,
    };
  };

  const formatCampaignDropdownOptions = () => {
    return campaigns
      ?.filter((campaign) => !campaign?.isShared)
      .map((campaign) => formatCampaign(campaign));
  };

  const [modalPage, setModalPage] = useState(0);
  const [postUrl, setPostUrl] = useState("");
  const [radioCheckType, setRadioCheckType] = useState(
    defaultCampaign ? "UPDATE" : "CREATE"
  );
  const [campaignDropdownOptions, setCampaignDropdownOptions] = useState([]);
  const [campaignToUpdate, setCampaignToUpdate] = useState(
    defaultCampaign
      ? formatCampaign(defaultCampaign)
      : campaigns?.length
      ? formatCampaignDropdownOptions()?.[0]
      : null
  );

  const updateRadioCheck = (e, type) => {
    if (e.target.checked) {
      setRadioCheckType(type);
    }
  };

  const newPostValidateHandler = (values) => {
    const errors = {};
    if (radioCheckType === "CREATE") {
      if (!values.newCampaignName?.trim()) {
        errors.newCampaignName = "A campaign name is required";
      }
    }
    if (radioCheckType === "UPDATE") {
      if (!campaignToUpdate) {
        errors.selectedCampaignToUpdate = "You must select a campaign";
      }
    }
    return errors;
  };

  const newPostSubmitHandler = (values) => {
    if (radioCheckType === "CREATE") {
      let data = {
        name: values.newCampaignName,
      };
      data.postData = postPreview;
      createCampaign(data);
    }
    if (radioCheckType === "UPDATE") {
      // eslint-disable-next-line no-unused-vars
      const { postUrl, network, postId, ...rest } = postPreview;
      let data = {
        campaign_id: campaignToUpdate.value,
        postUrl,
        network,
        postId,
      };
      createCampaignPost(data);
    }
  };

  const submitUrlHandler = ({ url, ...rest }) => {
    setPostUrl(url);
    setModalPage(1);
  };

  const handleToggleModal = () => {
    setNewPostModalIsOpen(!newPostModalIsOpen);
  };

  const handleClosedModal = () => {
    closedNewPostModalHandler();
    setModalPage(0);
    setPostUrl("");
  };

  useEffect(() => {
    if (postUrlOverride) {
      getPostPreview({ postUrl: postUrlOverride });
      return;
    }
    if (postUrl) {
      getPostPreview({ postUrl });
    }
  }, [postUrl, postUrlOverride, getPostPreview]);

  useEffect(() => {
    if (defaultCampaign) {
      setRadioCheckType("UPDATE");
    }
    setCampaignToUpdate(
      defaultCampaign
        ? formatCampaign(defaultCampaign)
        : campaigns?.length
        ? formatCampaignDropdownOptions()?.[0]
        : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCampaign, campaigns]);

  useEffect(() => {
    const formattedCampaigns = formatCampaignDropdownOptions();
    // FIXME[epic=campaign-manager] derived state
    setCampaignDropdownOptions(formattedCampaigns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaigns]);

  return (
    <Modal
      id="campaign-modal"
      isOpen={newPostModalIsOpen}
      onClosed={handleClosedModal}
      toggle={handleToggleModal}
      className="modal-dialog-centered campaign-modal"
    >
      {(loadingNewPostModal || loadingPostPreview) && (
        <Loader className="social-cloud-loader campaign-manage-loader" />
      )}
      <ModalHeader toggle={handleToggleModal}>
        <span className="export-icon mr-3">
          <UserPlusIcon width={25} height={25} />
        </span>
        Add a Post
      </ModalHeader>
      <ModalBody className="confirm-modal">
        {modalPage === 0 && collectPostUrlInModal && (
          <Row className="justify-content-center">
            <Col xl="10" md="12" className="col search-wrap is-search-block">
              <PostUrlForm isCentered={true} submitHandler={submitUrlHandler} />
            </Col>
          </Row>
        )}
        {(modalPage === 1 || (!collectPostUrlInModal && postUrlOverride)) && (
          <>
            <Row className="justify-content-center">
              <Col md="8" className="creators my-4 justify-content-center">
                <CampaignPostDisplayCard
                  postPreviewImage={postPreviewImage}
                  creatorPreviewImage={creatorPreviewImage}
                  post={postPreview || {}}
                />
              </Col>
            </Row>
            <Formik
              initialValues={{
                campaignOperationSelected: "CREATE",
                newCampaignName: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                newPostSubmitHandler(values);
                setSubmitting(false);
              }}
              validateOnBlur={false}
              validateOnChange={true}
              validate={newPostValidateHandler}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <Row className="px-3">
                    {radioOptions.map((option, index) => (
                      <Col md="6" key={index}>
                        <FormGroup
                          check
                          className={`${
                            radioCheckType === option.value
                              ? "selected"
                              : "hidden"
                          } ${
                            option.value === "UPDATE" && !campaigns.length
                              ? "disabled"
                              : ""
                          } d-flex justify-content-center px-1`}
                        >
                          <RenderLabel
                            check
                            className="radio-container campaign-operation-radio"
                          >
                            {option.text}
                            <Field
                              component={RenderRadioButton}
                              value={option.value}
                              disabled={
                                option.value === "UPDATE" && !campaigns.length
                              }
                              checked={radioCheckType === option.value}
                              onChange={(e) =>
                                updateRadioCheck(e, option.value)
                              }
                              name="campaignOperationSelected"
                              id="campaignOperationSelected"
                            />
                            <span className="checkmark"></span>
                          </RenderLabel>
                        </FormGroup>
                      </Col>
                    ))}
                    <Col
                      md="7"
                      className="mx-auto mt-4 mb-5 justify-content-center"
                    >
                      {radioCheckType === "CREATE" && (
                        <>
                          <CreateCampaignField />
                          {errors.newCampaignName &&
                            touched.newCampaignName && (
                              <span className="required-bottom">
                                * {errors.newCampaignName}
                              </span>
                            )}
                        </>
                      )}
                      {radioCheckType === "UPDATE" && (
                        <>
                          <UpdateCampaignField
                            formattedCampaigns={campaignDropdownOptions}
                            campaignToUpdate={campaignToUpdate}
                            setCampaignToUpdate={setCampaignToUpdate}
                          />
                          {errors.selectedCampaignToUpdate && (
                            <span className="required-bottom">
                              * {errors.selectedCampaignToUpdate}
                            </span>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end">
                    <SubmitButton
                      disabled={isSubmitting}
                      className="btn btn-primary modal-button"
                    >
                      Submit
                    </SubmitButton>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    newPostModalIsOpen: state.campaignManager.newPostModalIsOpen,
    loadingNewPostModal: state.campaignManager.loadingNewPostModal,
    loadingPostPreview: state.campaignManager.loadingPostPreview,
    postPreview: state.campaignManager.postPreview,
    postPreviewImage: state.campaignManager.postPreviewImage,
    creatorPreviewImage: state.campaignManager.creatorPreviewImage,
  };
};

export default connect(mapStateToProps, {
  setNewPostModalIsOpen,
  getPostPreview,
  createCampaign,
  createCampaignPost,
})(NewPostModal);
