import React, {useEffect, useState} from "react";
import {
  Row,
  Col,
  TabContent,
  Nav,
  NavLink as ReactStrapNavLink,
  NavItem,
  TabPane,
  Button
} from "reactstrap";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import PostCard from "./postCard";

const PostsSection = (props) => {
  const { t } = useTranslation();

  const [activePostTab, setActivePostTab] = useState("0");

  const [visiblePostsCount, setVisiblePostsCount] = useState(3);
  const [canViewMore, setCanViewMore] = useState(true);

  const tabKey = activePostTab === "0" ? "top_posts" : "commercial_posts";
  let postsLength =
    props.accountOverviewDetails &&
    props.accountOverviewDetails.user_profile &&
    props.accountOverviewDetails.user_profile[tabKey] &&
    props.accountOverviewDetails.user_profile[tabKey].length;
  let posts = props.accountOverviewDetails.user_profile[tabKey] || [];

  const viewMorePosts = () => {
    if (canViewMore) {
      setVisiblePostsCount(visiblePostsCount + 3);
    }
  }

  const togglePostTab = (tab) => {
    setActivePostTab(tab);
    setVisiblePostsCount(3);
  };

  useEffect(() => {
    setCanViewMore(visiblePostsCount <= postsLength);
  }, [postsLength, visiblePostsCount])

  return (
    <div className="section section-po" id="Posts">
      <div className="section-head text-center">{/* <h2>Posts</h2> */}</div>
      <div className="follower-like-tabs">
        {props.accountOverviewDetails &&
          props.accountOverviewDetails.user_profile &&
          props.accountOverviewDetails.user_profile.commercial_posts &&
          props.accountOverviewDetails.user_profile.commercial_posts.length &&
          props.accountOverviewDetails.user_profile.top_posts &&
          props.accountOverviewDetails.user_profile.top_posts.length ? (
          <Nav pills className="d-flex justify-content-center">
            <NavItem>
              <ReactStrapNavLink
                className={
                  activePostTab === "0"
                    ? "active toggle first"
                    : "first"
                }
                onClick={() => togglePostTab("0")}
              >
                {t("reports.post_details.tabs.popular")}
              </ReactStrapNavLink>
            </NavItem>
            <NavItem>
              <ReactStrapNavLink
                className={
                  activePostTab === "1" ? "active toggle" : ""
                }
                onClick={() => togglePostTab("1")}
              >
                {t("reports.post_details.tabs.sponsored")}
              </ReactStrapNavLink>
            </NavItem>
          </Nav>
        ) : null}
        <TabContent activeTab={activePostTab}>
          <TabPane tabId="0">
            <div className="section-head text-center">
              <h2>{t("reports.post_details.popular")}</h2>
              {posts.length === 1 && (
                <span className="totalPosts">{posts.length} post</span>
              )}
            </div>
            {posts.length ? (
              <div className="posts-grid row">
                {posts.slice(0, visiblePostsCount).map((post, index) => {
                  return (
                    <div key={index} className="col-xs-12 col-md-6 col-lg-4">
                      <PostCard post={post} />
                    </div>
                  );
                })}
              </div>
            ) : null}
          </TabPane>
          <TabPane tabId="1">
            <div className="section-head text-center">
              <h2>{t("reports.post_details.sponsored")}</h2>
              {posts.length === 1 && (
                <span className="total-posts">{posts.length} post</span>
              )}
            </div>
            {postsLength ? (
              posts.length > 1 ? (
                <div className="posts-grid row">
                  {posts.slice(0, visiblePostsCount).map((post, index) => {
                    return (
                      <div key={index} className="col-xs-12 col-md-6 col-lg-4">
                        <PostCard post={post} />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Row className="row-xs">
                  <Col
                    sm="12"
                    md={{ size: 4, offset: 4 }}
                    className="posts-grid"
                  >
                    <PostCard post={posts[0]} />
                  </Col>
                </Row>
              )
            ) : null}
          </TabPane>
          {canViewMore ? (
            <div className="bottom text-center">
              <Button
                color="link"
              onClick={viewMorePosts}
              >
                {t("reports.view_more")}
              </Button>
            </div>
          ) : null}
        </TabContent>
      </div>
    </div>
  );
};
export default PostsSection;
