import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { RenderTextField } from "../../components";
import { Field } from "formik";
import { showTooltip } from "../../actions";

const BioFilter = (props) => {
  const { t } = useTranslation();
  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2 second"
      isOpen={props.state.filters.bio}
      toggle={() => props.toggleFilter("bio")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {t("discovery.filters.bio.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-md">
        <Row>
          <Col md="12" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span id="bio" className="tooltip-icon tool-top"></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip className="tooltip-content" target="bio">
                {showTooltip("bio")}
              </UncontrolledTooltip>
            )}
            <FormGroup className="form-field-col">
              <Field
                component={RenderTextField}
                name="bio"
                className="text-search-icon"
                placeholder={t("discovery.filters.bio.placeholder.influencer")}
                value={props.state.bio}
                onChange={props.handleBioChange}
                onKeyPress={props.handleEnterKeyForBio}
                onBlur={props.handleBioBlur}
              />
            </FormGroup>
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default BioFilter;
