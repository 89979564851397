import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import {
  ChatBubbleLeftEllipsisIcon,
  ChevronDoubleRightIcon,
  EyeIcon,
  LinkIcon,
  ArrowPathIcon,
  ArrowTrendingUpIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { SpeakerXMarkIcon, SpeakerWaveIcon } from "@heroicons/react/20/solid";
import { Trans } from "react-i18next";
import InstaStories from "react-insta-stories";
import { connect } from "react-redux";
import { formatNumbersToUnits } from "../../../actions";
import HasNoStory from "./hasNoStory";
import Loader from "../../../loader";
import moment from "moment";
import "./stories.scss";

const Stories = (props) => {
  const [muted, setMuted] = useState(true);

  const userData = props.user_profile;
  let storiesData = props.stories;

  const stories = [];
  const has_stories = storiesData && storiesData.stories.length > 0;

  for (const story of has_stories ? storiesData.stories : []) {
    const isBefore = moment().isBefore(moment.unix(story.expiring_at));

    if (isBefore) {
      stories.push({
        url: story.source_url,
        type: story.type,
        header: {
          heading: userData.username,
          subheading: `Posted ${moment(moment.unix(story.taken_at)).fromNow()}`,
          profileImage: userData.picture,
        },
      });
    }
  }

  useEffect(() => {
    [...document.querySelectorAll("audio, video")].forEach(
      (el) => (el.muted = muted)
    );
  }, [muted]);

  return (
    <div className="section" id="Stories">
      {stories && stories.length > 0 ? (
        <>
          <div className="section-head text-center">
            <h2>
              <Trans i18nKey="reports.story_details.title">Story Details</Trans>
            </h2>
          </div>
          <Row>
            <Col md="6">
              <Card className="card-content-custom">
                <CardBody>
                  <div className="country-text-wrap">
                    <div className="country-name pt-2 story-text">
                      <h2>
                        {formatNumbersToUnits(storiesData.estimated_reach)}
                      </h2>
                      <ArrowTrendingUpIcon className="story-icon" />
                    </div>
                  </div>
                  <CardTitle>
                    <Trans i18nKey="reports.story_details.reach">
                      Estimated Reach
                    </Trans>
                  </CardTitle>
                </CardBody>
              </Card>
              <Card className="card-content-custom">
                <CardBody>
                  <div className="country-text-wrap">
                    <div className="country-name pt-2 story-text">
                      <h2>{formatNumbersToUnits(storiesData.back)}</h2>
                      <ArrowPathIcon className="story-icon" />
                    </div>
                  </div>
                  <CardTitle>
                    <Trans i18nKey="reports.story_details.back">
                      Estimated Backs / Rewatch
                    </Trans>
                  </CardTitle>
                </CardBody>
              </Card>
              <Card className="card-content-custom">
                <CardBody>
                  <div className="country-text-wrap">
                    <div className="country-name pt-2 story-text">
                      <h2>{formatNumbersToUnits(storiesData.forwards)}</h2>
                      <ChevronDoubleRightIcon className="story-icon" />
                    </div>
                  </div>
                  <CardTitle>
                    <Trans i18nKey="reports.story_details.forwards">
                      Estimated Forwards
                    </Trans>
                  </CardTitle>
                </CardBody>
              </Card>
              {userData.followers > 10000 ? (
                <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2 story-text">
                        <h2>
                          {formatNumbersToUnits(
                            typeof storiesData.estimated_link_clicks !==
                              "undefined"
                              ? storiesData.estimated_link_clicks
                              : storiesData.estimated_swipe_ups
                          )}
                        </h2>
                        <LinkIcon className="story-icon" />
                      </div>
                    </div>
                    <CardTitle>
                      <Trans i18nKey="reports.story_details.link_clicks">
                        Estimated Link Clicks
                      </Trans>
                    </CardTitle>
                  </CardBody>
                </Card>
              ) : (
                <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2 story-text">
                        <h2>{Math.round(storiesData.stories_per_day)}</h2>
                        <VideoCameraIcon className="story-icon" />
                      </div>
                    </div>
                    <CardTitle>
                      <Trans i18nKey="reports.story_details.day">
                        Stories Per Day
                      </Trans>
                    </CardTitle>
                  </CardBody>
                </Card>
              )}
              <Card className="card-content-custom">
                <CardBody>
                  <div className="country-text-wrap">
                    <div className="country-name pt-2 story-text">
                      <h2>{Math.round(storiesData.replies)}</h2>
                      <ChatBubbleLeftEllipsisIcon className="story-icon" />
                    </div>
                  </div>
                  <CardTitle>
                    <Trans i18nKey="reports.story_details.replies">
                      Estimated Replies
                    </Trans>
                  </CardTitle>
                </CardBody>
              </Card>
              <Card className="card-content-custom">
                <CardBody>
                  <div className="country-text-wrap">
                    <div className="country-name pt-2 story-text">
                      <h2>
                        {formatNumbersToUnits(
                          storiesData.estimated_impressions
                        )}
                      </h2>
                      <EyeIcon className="story-icon" />
                    </div>
                  </div>
                  <CardTitle>
                    <Trans i18nKey="reports.story_details.impression">
                      Estimated Impressions
                    </Trans>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col
              md="6"
              className="d-flex justify-content-center align-items-center"
            >
              {!muted && has_stories ? (
                <span className="volume-icon active">
                  <SpeakerWaveIcon
                    width={20}
                    height={20}
                    onClick={() => setMuted(true)}
                  />
                </span>
              ) : (
                <span className="volume-icon muted">
                  <SpeakerXMarkIcon
                    width={20}
                    height={20}
                    onClick={() => setMuted(false)}
                  />
                </span>
              )}
              {has_stories && (
                <InstaStories
                  loop
                  stories={stories}
                  loader={<Loader className="social-cloud-loader" />}
                  storyContainerStyles={{
                    borderRadius: "15px",
                  }}
                  storyStyles={{
                    borderRadius: "15px",
                  }}
                />
              )}
            </Col>
          </Row>
        </>
      ) : (
        <HasNoStory {...{ ...storiesData, ...userData }} stories={stories} />
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    stories: state.reports.stories,
    user_profile: state.reports.accountOverviewDetails?.user_profile,
  };
};
export default connect(mapStateToProps, null)(Stories);
