import { actionCreator, privateHeaders, checkHttpStatus, checkAuthTokenExpiry, COMMON_ERROR_MESSAGE } from "../../../actions";
import { adminPanelActionTypes, GET_USER_MANAGEMENT_DATA_URL, GRANT_API_ACCESS_URL, REVOKE_API_ACCESS_URL, UPDATE_CREDITS_BALANCE_URL, UPDATE_CARD_DETAILS_URL, GET_METERED_PLANS_DETAILS_URL, GET_DEMO_LINK_URL } from "../constants";
import { signOut } from "../../login/actions";

export const getUserManagementData = (pageNo) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(adminPanelActionTypes.getUserManagementData.REQUEST));
    fetch(`${GET_USER_MANAGEMENT_DATA_URL}${pageNo ? `?page=${pageNo}` : ''}`, {
      method: 'GET',
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then(response => {
        dispatch(actionCreator(adminPanelActionTypes.getUserManagementData.SUCCESS, response));
      }).catch(error => {
        dispatch(actionCreator(adminPanelActionTypes.getUserManagementData.FAILURE));
        if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
          dispatch(signOut(error, true));
        }
      })
  }
}

export const getUserDetailsForEdit = (userId) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(adminPanelActionTypes.getUserDetailsForEdit.REQUEST));
    fetch(`${GET_USER_MANAGEMENT_DATA_URL}/${userId}`, {
      method: 'GET',
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then(response => {
        dispatch(actionCreator(adminPanelActionTypes.getUserDetailsForEdit.SUCCESS, response));
      }).catch(error => {
        dispatch(actionCreator(adminPanelActionTypes.getUserDetailsForEdit.FAILURE));
        if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
          dispatch(signOut(error, true));
        }
      })
  }
}
export const getSearchResult = (searchQuery) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(adminPanelActionTypes.getSearchResult.REQUEST));
    fetch(`${GET_USER_MANAGEMENT_DATA_URL}/${`search?q=${searchQuery}`}`, {
      method: 'GET',
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then(response => {
        response.users = response.data.map((obj) => obj)
        dispatch(actionCreator(adminPanelActionTypes.getSearchResult.SUCCESS, response));
      }).catch(error => {
        dispatch(actionCreator(adminPanelActionTypes.getSearchResult.FAILURE));
        if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
          dispatch(signOut(error, true));
        }
      })
  }
}
export const grantAPIAccessAction = (userId, isSearchField, pageNo) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(adminPanelActionTypes.grantAPIAccess.REQUEST));
    fetch(`${GRANT_API_ACCESS_URL}/${userId}/api_grant`, {
      method: 'PUT',
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then(response => {
        dispatch(actionCreator(adminPanelActionTypes.grantAPIAccess.SUCCESS, response));
        dispatch(getUserDetailsForEdit(userId));
        if (!isSearchField) {
          dispatch(getUserManagementData(pageNo));
        } else {
          dispatch(getSearchResult(isSearchField.trim()))
        }

      }).catch(error => {
        dispatch(actionCreator(adminPanelActionTypes.grantAPIAccess.FAILURE));
        if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
          dispatch(signOut(error, true));
        }
      })
  }
}
export const revokeAPIAccessAction = (userId, isSearchField, pageNo) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(adminPanelActionTypes.revokeAPIAccess.REQUEST));
    fetch(`${REVOKE_API_ACCESS_URL}/${userId}/api_revoke`, {
      method: 'PUT',
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then(response => {
        dispatch(actionCreator(adminPanelActionTypes.revokeAPIAccess.SUCCESS, response));
        dispatch(getUserDetailsForEdit(userId));
        if (!isSearchField) {
          dispatch(getUserManagementData(pageNo));
        } else {
          dispatch(getSearchResult(isSearchField.trim()))
        }
      }).catch(error => {
        dispatch(actionCreator(adminPanelActionTypes.revokeAPIAccess.FAILURE));
        if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
          dispatch(signOut(error, true));
        }
      })
  }
}


export const grantTrialAccessAction = (userId, isSearchField, pageNo) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(adminPanelActionTypes.grantTrialAccess.REQUEST));
    fetch(`${GRANT_API_ACCESS_URL}/${userId}/trial_access`, {
      method: 'PUT',
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then(response => {
        dispatch(actionCreator(adminPanelActionTypes.grantTrialAccess.SUCCESS, response));
        dispatch(getUserDetailsForEdit(userId));
        if (!isSearchField) {
          dispatch(getUserManagementData(pageNo));
        } else {
          dispatch(getSearchResult(isSearchField.trim()))
        }
      }).catch(error => {
        dispatch(actionCreator(adminPanelActionTypes.grantTrialAccess.FAILURE));
        if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
          dispatch(signOut(error, true));
        }
      })
  }
}

export const updateCreditsBalanceAction = (userId, postData, isSearchField, pageNo) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(adminPanelActionTypes.updateCreditsBalance.REQUEST));
    fetch(`${UPDATE_CREDITS_BALANCE_URL}/${userId}/balance`, {
      method: 'PUT',
      headers: privateHeaders(getState),
      body: JSON.stringify(postData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then(response => {
        dispatch(actionCreator(adminPanelActionTypes.updateCreditsBalance.SUCCESS, response));
        dispatch(getUserDetailsForEdit(userId));
        if (!isSearchField) {
          dispatch(getUserManagementData(pageNo));
        } else {
          dispatch(getSearchResult(isSearchField.trim()))
        }
      }).catch(error => {
        dispatch(actionCreator(adminPanelActionTypes.updateCreditsBalance.FAILURE));
        if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
          dispatch(signOut(error, true));
        }
      })
  }
}
export const cardDetailsUpdateAction = (userId, postData, isSearchField, pageNo) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(adminPanelActionTypes.cardDetailsUpdate.REQUEST));
    fetch(`${UPDATE_CARD_DETAILS_URL}/${userId}/payment_update`, {
      method: 'PUT',
      headers: privateHeaders(getState),
      body: JSON.stringify(postData),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then(response => {
        dispatch(actionCreator(adminPanelActionTypes.cardDetailsUpdate.SUCCESS, response));
        dispatch(getUserDetailsForEdit(userId));
        if (!isSearchField) {
          dispatch(getUserManagementData(pageNo));
        } else {
          dispatch(getSearchResult(isSearchField.trim()))
        }
      }).catch(error => {
        dispatch(actionCreator(adminPanelActionTypes.cardDetailsUpdate.FAILURE));
        if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
          dispatch(signOut(error, true));
        }
      })
  }
}
export const clearTrialAccessMessage = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(adminPanelActionTypes.clearTrialAccessMessage.SUCCESS));
  }
}
export const clearCardUpdateMessage = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(adminPanelActionTypes.clearCardUpdateMessage.SUCCESS));
  }
}
export const clearPlanUpdateErrorMsg = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(adminPanelActionTypes.clearPlanUpdateErrorMsg.SUCCESS));
  }
}
export const getSubscriptionPlansDetails = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(adminPanelActionTypes.getSubscriptionPlansDetails.REQUEST));
    fetch(GET_METERED_PLANS_DETAILS_URL, {
      method: 'GET',
      headers: privateHeaders(getState)
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then(response => {
        dispatch(actionCreator(adminPanelActionTypes.getSubscriptionPlansDetails.SUCCESS, response));

      }).catch(error => {
        dispatch(actionCreator(adminPanelActionTypes.getSubscriptionPlansDetails.FAILURE));
        if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
          dispatch(signOut(error, true));
        }
      });
  }
}
export const editSubscriptionPlansDetails = (postData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(adminPanelActionTypes.editSubscriptionPlansDetails.REQUEST));
    fetch(GET_METERED_PLANS_DETAILS_URL, {
      method: 'PUT',
      body: JSON.stringify(postData),
      headers: privateHeaders(getState)
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then(response => {
        if (response.status === false) {
          response.errorMsg = response.message;
        }
        dispatch(actionCreator(adminPanelActionTypes.editSubscriptionPlansDetails.SUCCESS, response));
        dispatch(getSubscriptionPlansDetails())
      }).catch(error => {
        dispatch(actionCreator(adminPanelActionTypes.editSubscriptionPlansDetails.FAILURE));
        if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
          dispatch(signOut(error, true));
        }
      });
  }
}
export const getDemoLink = (postData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(adminPanelActionTypes.getDemoLink.REQUEST));
    fetch(GET_DEMO_LINK_URL, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: privateHeaders(getState),
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then(response => {
        if (response.error) {
          response.demoLinksError = (Object.keys(response.error).length && response.error[Object.keys(response.error)[0]]) || COMMON_ERROR_MESSAGE
        }
        dispatch(actionCreator(adminPanelActionTypes.getDemoLink.SUCCESS, response));
      }).catch(error => {
        dispatch(actionCreator(adminPanelActionTypes.getDemoLink.FAILURE));
        if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
          dispatch(signOut(error, true));
        }
      })
  }
}
export const clearDemoLinksErrorMsg = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(adminPanelActionTypes.clearDemoLinksErrorMsg.SUCCESS));
  }
}