import React from "react";
import settings from "settings";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import "./splashScreen.scss";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import GettingStarted from "./GettingStarted";
import QuickSearches from "./QuickSearches";
import dashboardImage from "../../img/dashboard_image.svg";
import { DashSearchBar } from "./search_bar";

const SplashScreen = (props) => {
  const { t } = useTranslation();

  return (
    <Container className="main-site-content dashboard splash">
      <Row>
        <Col className="is-search-block search-wrap ">
          <div className="border-btm">
            {settings.pages.dashboard.header.welcome && (
              <h1 className="page-title">
                Welcome, {props.loginUser.name.split(" ")[0]}!
              </h1>
            )}
          </div>
        </Col>
      </Row>
      {!settings.pages.dashboard.header.enabled && (
        <>
          <Row>
            <DashSearchBar loginUser={props.loginUser} />
          </Row>
          <Row>
            <QuickSearches searchAgain={props.searchAgain} />
            {settings.pages.dashboard.header.getting_started && (
              <GettingStarted />
            )}
          </Row>
        </>
      )}
      {settings.pages.dashboard.header.enabled && (
        <div className="card card-custom">
          <Row>
            <Col className="is-search-block search-wrap col-7 ">
              <div className="text-wrapper">
                <h6 className="page-heading ">{t("dashboard.header.title")}</h6>
                <p className="sub-text text-grey">
                  {t("dashboard.header.description")}
                </p>
              </div>
            </Col>
            <Col className="col-5">
              <img
                className="dashboard-icon-img"
                src={dashboardImage}
                alt="dashboard_image"
              />
            </Col>
          </Row>
        </div>
      )}
      {/*<Row>
        <Col sm="3">
          <Card className="card-content-custom tracking-account">
            <CardBody>
              <CardTitle>
                <img
                  alt="profile"
                  width="50px"
                  height="50px"
                  className="rounded-circle tracking-profile"
                  src="https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDY9ylLT5c6L8M5YYtkm82Y2zjO1feqEH1as2tq%2B1wwrRw8166J6EwA1BUYsrVsRjroftrq2%2BGnVWf%2FmHxdUtroNIRbk7J5MKx3RJAshn9jFdA%3D%3D"
                ></img>
                <span className="text-tooltip tracking-influencer">
                  @kyliejenner
                </span>
              </CardTitle>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <hr />

      <Row className="mb-5">
        <Col sm="5">
          <FollowersTile {...props} />
        </Col>
      </Row>*/}

      <Row className="mt-5 mb-5">
        <Col md="12">
          <Card className="card-content-custom activity-tab">
            <CardBody>
              <CardTitle className="d-flex align-items-center">
                <ExclamationCircleIcon
                  width={25}
                  height={25}
                  className="mr-3"
                />
                <span className="text-tooltip tracking-influencer">
                  Check out the new Activity tab to view your past reports and
                  exports.
                </span>
              </CardTitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SplashScreen;
