import React from 'react'
import { Card, CardBody, CardTitle, Button, Modal, ModalHeader, ModalBody, Table, UncontrolledTooltip } from 'reactstrap';
import { RenderButton } from '../../../../components';
import { showTooltip } from "../../../../actions";
const LikesPopularHashtags = (props) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>
          <span className="tooltip-button-wrap">
            <span className="text-tooltip">Popular Hashtags</span>{" "}
            <span
              href="#"
              id="popularHashtagTooltip"
              className="tooltip-icon"
            ></span>
          </span>
          <UncontrolledTooltip
            placement="bottom"
            className="tooltip-content"
            target="popularHashtagTooltip"
          >
            {showTooltip("popularHashtagTooltip")}
          </UncontrolledTooltip>
        </CardTitle>
        <div className="popular-tags ch-5">
          <ul>
            {props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.top_hashtags && props.postAnalysisDetails.posts_profile.top_hashtags.length ? props.postAnalysisDetails.posts_profile.top_hashtags.map((tag, index) => {
              if (index < 5) {
                return <li key={index}>{`#${tag.tag}`}</li>
              }
              return null
            }) : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}
          </ul>
        </div>
        {props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.top_hashtags && props.postAnalysisDetails.posts_profile.top_hashtags.length ?
          <div className="bottom">
            <Button color="link" onClick={() => props.toggleModal("likesPopularHastagsModal")}>View More</Button>
            <Modal id="likesPopularHastagsModalDownload" isOpen={props.state.modals.likesPopularHastagsModal} toggle={() => props.toggleModal("likesPopularHastagsModal")} >
              <ModalHeader toggle={() => props.toggleModal("likesPopularHastagsModal")}>
                Popular Hashtags
                <RenderButton onClick={() => props.downloadPdf("likesPopularHastagsModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
              </ModalHeader>
              <ModalBody>
                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th colSpan="2">Hashtag</th>
                      <th className="text-right">Percent</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.top_hashtags && props.postAnalysisDetails.posts_profile.top_hashtags.length ? props.postAnalysisDetails.posts_profile.top_hashtags.map((tag, index) => <tr key={index}>
                      <th scope="row">{`#${tag.tag}`}</th>
                      <td className="text-left">&nbsp;</td>
                      <td className="text-right">{tag.weight.toFixed(2) + '%'}</td>
                    </tr>) :
                      <tr>
                        <td colSpan="3" align="center">No Record Found</td>
                      </tr>}
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </div>
          : null}
      </CardBody>
    </Card>
  )
}

export default LikesPopularHashtags;
