import React from 'react';
import {
  Card,
  CardImgOverlay,
  CardImg,
  CardTitle,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import TensorCarousel from '../../components/carousel';
import quickSearchPresets from 'helpers/quickSearchPresets';
import networkImage from "../../helpers/networkImage";

import './QuickSearches.scss';

const QuickSearches = (props) => {

  const { t } = useTranslation();

  const searchPresetItems = quickSearchPresets.map((preset, key) => {
    return (
      <Card key={key} className="text-right">
        <CardImg
          alt={`Stock image background for ${preset?.title}`}
          src={preset.image}
          width="100%"
        />
        <CardImgOverlay onClick={() =>
          props.searchAgain(preset?.filters)
        }>
          <div className="option-creator-network platform-icon">
            <div className="social-icon-round">
              {networkImage(preset?.platform)}
            </div>
          </div>
          <CardTitle tag="h5">
            {preset?.title}
          </CardTitle>
        </CardImgOverlay>
      </Card>
    );
  });

  return (
    <>
      {quickSearchPresets && !!quickSearchPresets.length && (
        <>
          <div className="text-wrapper" style={{ padding: "30px 15px 8px" }}>
            <h2 className="page-heading">
              {t("dashboard.quick_searches.title")}
            </h2>
            <p className="sub-text text-grey">
              {t("dashboard.quick_searches.description")}
            </p>
          </div>
          <TensorCarousel
            autoPlay={true}
            carouselItems={searchPresetItems}
            containerClass="quick-search"
            infinite={true}
          />
        </>
      )}
    </>
  );
};

export default QuickSearches;
