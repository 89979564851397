import React from "react";
import { Trans } from "react-i18next";

const audienceTypeFilterOptions = [
  {
    text: <Trans i18nKey="discovery.filters.audience_type.options.any" />,
    value: "any",
  },
  {
    text: <Trans i18nKey="discovery.filters.audience_type.options.likers" />,
    value: "likers",
  },
  {
    text: <Trans i18nKey="discovery.filters.audience_type.options.followers" />,
    value: "followers",
  },
];
export default audienceTypeFilterOptions;
