import { subscriptionActionTypes, planSelectionTypes, fetchPlanTypes, clearResponseMessage, updateSubscription, saveCardNumber } from "../constants";
import { COMMON_ERROR_MESSAGE } from "../../../actions";

const initialState = {
  loading: false,
  subscriptionDetails: null,
  subscriptionErrorMessage: null,
  choosePlanDetails: {},
  subscriptionPlans: [],
  subscriptionPlansErrorMessage: null,
  isPaymentSuccess: false,
  isPaymentFailure: false,
  userPlanUpdate: null,
  cardNumberDetails: '',
  subscriptionError: null,
  showHeader: true,
  trialAccess: null,
  continueWithTrialAccessErrorMsg: null,
  continueWithTrialAccessSuccessMsg: null,


}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case subscriptionActionTypes.activatePlan.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case subscriptionActionTypes.activatePlan.SUCCESS:
      return {
        ...state,
        subscriptionDetails: payload,
        loading: false,
        isPaymentSuccess: true,
        isPaymentFailure: false
      }
    case subscriptionActionTypes.hideHeaderAction.SUCCESS:
      return {
        ...state,
        showHeader: payload
      }
    case subscriptionActionTypes.activatePlan.FAILURE:
      return {
        ...state,
        loading: false,
        subscriptionErrorMessage: COMMON_ERROR_MESSAGE,
        isPaymentSuccess: false,
        isPaymentFailure: true
      }
    case planSelectionTypes.choosePlan.SUCCESS:

      return {
        ...state,
        loading: false,
        choosePlanDetails: payload
      }
    case fetchPlanTypes.getPlanTypes.REQUEST:
      return {
        ...state,
        loading: true
      }
    case fetchPlanTypes.getPlanTypes.SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionPlans: payload.data
      }
    case fetchPlanTypes.getPlanTypes.FAILURE:
      return {
        ...state,
        loading: false,
        subscriptionPlansErrorMessage: COMMON_ERROR_MESSAGE
      }

    case clearResponseMessage.removeResponsePaymentType.REQUEST:
      return {
        ...state,
        loading: false,
        isPaymentSuccess: false,
        isPaymentFailure: false,
        // choosePlanDetails:{},
        subscriptionPlans: [],
        subscriptionDetails: {}
      }
    case updateSubscription.updateSubscriptionType.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case updateSubscription.updateSubscriptionType.SUCCESS:
      return {
        ...state,
        loading: false,
        userPlanUpdate: payload
      }
    case updateSubscription.updateSubscriptionType.FAILURE:
      return {
        ...state,
        loading: false,
        userPlanUpdate: null
      }
    case saveCardNumber.saveCardNumberType.SUCCESS:
      return {
        ...state,
        loading: false,
        cardNumberDetails: payload
      }
    case subscriptionActionTypes.showSubscriptionError.SUCCESS:
      return {
        ...state,
        subscriptionError: payload,
      }
    case subscriptionActionTypes.clearSubscriptionError.SUCCESS:
      return {
        ...state,
        subscriptionError: null
      }
    case subscriptionActionTypes.continueWithTrialAccess.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case subscriptionActionTypes.continueWithTrialAccess.SUCCESS:
      return {
        ...state,
        loading: false,
        trialAccess: payload.errorMsg ? null : payload,
        continueWithTrialAccessErrorMsg: payload.errorMsg || null,
        continueWithTrialAccessSuccessMsg: payload.successMsg || null,
      }
    case subscriptionActionTypes.clearContinueWithTrialAccessErrorMsg.SUCCESS:
      return {
        ...state,
        continueWithTrialAccessErrorMsg: null,
      }
    case subscriptionActionTypes.clearContinueWithTrialAccessSuccessMsg.SUCCESS:
      return {
        ...state,
        continueWithTrialAccessSuccessMsg: null,
      }
    case subscriptionActionTypes.continueWithTrialAccess.FAILURE:
      return {
        ...state,
        loading: false,
        trialAccess: null,
        continueWithTrialAccessErrorMsg: COMMON_ERROR_MESSAGE
      }

    default: {
      return state
    }
  }
}
