import React from "react";
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { RenderSingleSelect } from "../../components";
import { Field } from "formik";
import { showTooltip } from "../../actions";

import {
  followers_engage_range_left,
  followers_engage_range_right,
} from "../../helpers/Followers";
const FollowersFilter = (props) => {
  const { t } = useTranslation();

  const from_options = () => {
    return followers_engage_range_left.map((option) => {
      if (
        props.state.followersTo &&
        props.state.followersTo.value &&
        props.state.followersTo.value <= option.value
      ) {
        return { ...option, isDisabled: true };
      } else {
        return { ...option, isDisabled: false };
      }
    });
  };

  const to_options = () => {
    return followers_engage_range_right.map((option) => {
      if (
        props.state.followersFrom &&
        props.state.followersFrom.value &&
        props.state.followersFrom.value >= option.value
      ) {
        return { ...option, isDisabled: true };
      } else {
        return { ...option, isDisabled: false };
      }
    });
  };
  return (
    <ButtonDropdown
      className="col-12 col-sm-6 col-md-4 col-lg-2 second"
      isOpen={props.state.filters.followers}
      toggle={() => props.toggleFilter("followers")}
    >
      <DropdownToggle caret className="btn btn-default btn-dropdown-white">
        {props.state.platformValue &&
        props.state.platformValue.value === "youtube"
          ? t("discovery.filters.followers.youtube")
          : t("discovery.filters.followers.title")}
      </DropdownToggle>
      <DropdownMenu className="ddmenu-col custom-dropdown-column width-md">
        <Row>
          <Col md="12" className="col">
            <span className="tooltip-button-wrap">
              <span className="text-tooltip">
                <h3 className="head icon-influencer">
                  {t("discovery.influencer")}
                </h3>
              </span>
              <span id="followerRate" className="tooltip-icon tool-top"></span>
            </span>
            {props.tooltip && (
              <UncontrolledTooltip
                className="tooltip-content"
                target="followerRate"
              >
                {showTooltip("followerRate")}
              </UncontrolledTooltip>
            )}
            <FormGroup className="select-field-col follower-col">
              <Field
                component={RenderSingleSelect}
                name="followersFrom"
                placeholder={t("discovery.fields.from")}
                value={props.state.followersFrom}
                onChange={(input) =>
                  props.handleSingleSelectChange(input, "followersFrom")
                }
                options={from_options()}
              />
            </FormGroup>

            <FormGroup className="select-field-col follower-col last">
              <Field
                component={RenderSingleSelect}
                name="followersTo"
                placeholder={t("discovery.fields.to")}
                value={props.state.followersTo}
                onChange={(input) =>
                  props.handleSingleSelectChange(input, "followersTo")
                }
                options={to_options()}
              />
            </FormGroup>
          </Col>
        </Row>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default FollowersFilter;
