import React from 'react'
import { Row, Col, TabContent, Card, CardBody, CardTitle, TabPane, UncontrolledTooltip } from 'reactstrap';
import { showTooltip } from "../../../actions";

import LikesGenderSplit from './byLikes/likesGenderSplit';
import LikesAgeAndGenderSplit from './byLikes/likesAgeAndGenderSplit';
import LikesLocationByCity from './byLikes/likesLocationByCity';
import LikesLocationByCountry from './byLikes/likesLocationByCountry';
import LikesEthnicity from './byLikes/likesEthnicity';
import LikesLanguage from './byLikes/likesLanguage';
import LikesAudienceBrandAffinity from './byLikes/likesAudienceBrandAffinity';
import LikesAudienceInterests from './byLikes/likesAudienceInterests';
import LikesCreators from './byLikes/likesCreators';
import LikesNotableUsers from './byLikes/likesNotableUsers';
import LikesAudienceReachability from './byLikes/likesAudienceReachability';
import LikesPopularHashtags from './byLikes/likesPopularHashtags';
import LikesPopularMentions from './byLikes/likesPopularMentions';
const AudiencesSection = (props) => {
  const likesLikeIconClass = props.postAnalysisDetails && props.postAnalysisDetails.audience_likers &&
    props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.credibility_class ? ((props.postAnalysisDetails.audience_likers.data.credibility_class.toLowerCase() === 'high' || props.postAnalysisDetails.audience_likers.data.credibility_class.toLowerCase() === 'best') ? 'icon-liked' : (props.postAnalysisDetails.audience_likers.data.credibility_class.toLowerCase() === 'bad' ? 'icon-unlike' : 'icon-neutral')) : "";
  return (
    <div className="section section-au" id="Audience">
      <div className="section-head text-center">
        <h2>Audience Details <span>By likes</span></h2>
      </div>

      <div className="follower-like-tabs">

        <TabContent activeTab={props.state.activeTab}>
          <TabPane tabId="0">
            <Row className="row-xs">
              <Col sm="6">
                <Card className="card-content-custom ch-179">
                  <CardBody>
                    <h2 className={likesLikeIconClass}>{props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_credibility
                      && (props.postAnalysisDetails.audience_likers.data.audience_credibility * 100).toFixed(2) + '%'}</h2>
                    <CardTitle><span className="tooltip-button-wrap"><span className="text-tooltip">Likers Credibility</span>  <span href="#" id="likessFollowersCredibility" className="tooltip-icon"></span></span>
                      <UncontrolledTooltip placement="bottom" className="tooltip-content" target="likessFollowersCredibility">
                      {showTooltip("likesFollowersCredibility")}
                      </UncontrolledTooltip>
                    </CardTitle>
                  </CardBody>
                </Card>

                <Card className="card-content-custom ch-179">
                  <CardBody>
                    <h2>{props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.notable_users_ratio && (props.postAnalysisDetails.audience_likers.data.notable_users_ratio * 100).toFixed(2) + '%'}</h2>
                    <CardTitle>
                      Notable Likes
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6">
                <LikesAudienceReachability {...props} />
              </Col>
            </Row>
            {(!props.postAnalysisDetails || !props.postAnalysisDetails.audience_likers || !props.postAnalysisDetails.audience_likers.data || !props.postAnalysisDetails.audience_likers.data.audience_genders || !props.postAnalysisDetails.audience_likers.data.audience_genders.length) && (!props.postAnalysisDetails || !props.postAnalysisDetails.audience_likers || !props.postAnalysisDetails.audience_likers.data || !props.postAnalysisDetails.audience_likers.data.audience_genders_per_age || !props.postAnalysisDetails.audience_likers.data.audience_genders_per_age.length)
              ? null :
              <Row className="row-xs">
                <Col sm="5" md="4">
                  <LikesGenderSplit {...props} />
                </Col>
                <Col sm="7" md="8">
                  <LikesAgeAndGenderSplit {...props} />
                </Col>
              </Row>
            }
            {!props.postAnalysisDetails || !props.postAnalysisDetails.audience_likers || !props.postAnalysisDetails.audience_likers.data || !props.postAnalysisDetails.audience_likers.data.audience_geo || !props.postAnalysisDetails.audience_likers.data.audience_geo.cities || !props.postAnalysisDetails.audience_likers.data.audience_geo.cities.length
              ? null :
              <Row>
                <Col>
                  <LikesLocationByCity {...props} />
                </Col>
              </Row>
            }
            {(!props.postAnalysisDetails || !props.postAnalysisDetails.audience_likers || !props.postAnalysisDetails.audience_likers.data || !props.postAnalysisDetails.audience_likers.data.audience_geo || !props.postAnalysisDetails.audience_likers.data.audience_geo.countries || !props.postAnalysisDetails.audience_likers.data.audience_geo.countries.length) && (!props.postAnalysisDetails || !props.postAnalysisDetails.audience_likers || !props.postAnalysisDetails.audience_likers.data || !props.postAnalysisDetails.audience_likers.data.audience_ethnicities || !props.postAnalysisDetails.audience_likers.data.audience_ethnicities.length)
              && (!props.postAnalysisDetails || !props.postAnalysisDetails.audience_likers || !props.postAnalysisDetails.audience_likers.data || !props.postAnalysisDetails.audience_likers.data.audience_languages || !props.postAnalysisDetails.audience_likers.data.audience_languages.length)
              ? null :
              <Row className="row-xs">
                <Col md="4">
                  <LikesLocationByCountry {...props} />
                </Col>

                <Col md="4">
                  <LikesEthnicity {...props} />
                </Col>

                <Col md="4">
                  <LikesLanguage {...props} />
                </Col>
              </Row>
            }
            {(!props.postAnalysisDetails || !props.postAnalysisDetails.audience_likers || !props.postAnalysisDetails.audience_likers.data || !props.postAnalysisDetails.audience_likers.data.audience_brand_affinity || !props.postAnalysisDetails.audience_likers.data.audience_brand_affinity.length) && (!props.postAnalysisDetails || !props.postAnalysisDetails.audience_likers || !props.postAnalysisDetails.audience_likers.data || !props.postAnalysisDetails.audience_likers.data.audience_interests || !props.postAnalysisDetails.audience_likers.data.audience_interests.length)
              ? null :
              <Row className="row-xs">
                <Col sm="6">
                  <LikesAudienceBrandAffinity {...props} />
                </Col>

                <Col sm="6">
                  <LikesAudienceInterests {...props} />
                </Col>
              </Row>
            }

            {(!props.postAnalysisDetails || !props.postAnalysisDetails.audience_likers || !props.postAnalysisDetails.audience_likers.data || !props.postAnalysisDetails.audience_likers.data.notable_users || !props.postAnalysisDetails.audience_likers.data.notable_users.length) && (!props.postAnalysisDetails || !props.postAnalysisDetails.audience_likers || !props.postAnalysisDetails.audience_likers.data || !props.postAnalysisDetails.audience_likers.data.creators || !props.postAnalysisDetails.audience_likers.data.creators.length)
              ? null :
              <Row className="row-xs">
                <Col sm="6">
                  <LikesNotableUsers  {...props} />
                </Col>
                <Col sm="6">
                  <LikesCreators  {...props} />
                </Col>

              </Row>
            }
            {(!props.postAnalysisDetails || !props.postAnalysisDetails.posts_profile || !props.postAnalysisDetails.posts_profile.top_hashtags || !props.postAnalysisDetails.posts_profile.top_hashtags.length) && (!props.postAnalysisDetails || !props.postAnalysisDetails.posts_profile || !props.postAnalysisDetails.posts_profile.top_mentions || !props.postAnalysisDetails.posts_profile.top_mentions.length)
              ? null :
              <Row className="row-xs">
                <Col sm="6">
                  <LikesPopularHashtags  {...props} />
                </Col>
                <Col sm="6">
                  <LikesPopularMentions {...props} />
                </Col>
              </Row>
            }
          </TabPane>
        </TabContent>
      </div>
    </div>
  )
}
export default AudiencesSection;
