import React from "react";
import settings from "settings";
import { useTranslation } from "react-i18next";
import { FollowerTag } from "components/tags";
import { RenderButton } from "../../../components";
import networkImage from "../../../helpers/networkImage";
import noimg from "../../../img/MaskGroup.png";
import defaultImg from "../../../img/user-circle-outline.svg";
import { Form, Formik } from "formik";
import CreatorListModal from "modules/identification/modals/creatorListModal";

const EmailMatchResultsModalRow = ({ creator, handleAnalyze, isCreatorListModalOpen, toggleCreatorListModal, formatCreatorsForList, creatorLists, addCreatorListHandler, updateCreatorListHandler,...props }) => {
  const { t } = useTranslation();

  return (
    <tr>
      <td className="tb-head d-flex">
        <div className="account-image">
          <img
            src={creator.picture ? creator.picture : noimg}
            alt="profile pic"
            width="50px"
            height="50px"
            className="rounded-circle"
            onError={(e) => (e.target.src = defaultImg)}
          />
          {networkImage(creator.type)}
        </div>

        <div className="d-flex align-items-center">
          <h4 className="m-0">{`@${creator.username || creator.custom_name || creator.fullname}`}</h4>
        </div>
      </td>
      <td className="mobile-view-past-report">
        {creator?.email}
      </td>
      <td className="text-left">
        <FollowerTag followers={creator.followers || 0}></FollowerTag>
      </td>
      <td align="center">
        <RenderButton
          onClick={() => props.handleAnalyze(creator)}
          className="btn btn-outline-primary"
        >
          {window.innerWidth < 676
            ? t("reports.splash.chart.view")
            : t("reports.splash.chart.view_report")}
        </RenderButton>
      </td>
      <td align="center">
        {settings.pages.creator_lists.enabled ? (
          <Formik>
            {() => (
              <Form>
                {/* CreatorListModal must be inside a Formik Form */}
                <RenderButton
                  className="btn btn-outline-primary row-item-button"
                  onClick={toggleCreatorListModal}
                >
                  Add to List
                </RenderButton>
                <CreatorListModal
                  isOpen={isCreatorListModalOpen}
                  toggleModal={toggleCreatorListModal}
                  selectedUsers={formatCreatorsForList([creator])}
                  creatorLists={creatorLists}
                  addCreatorListHandler={addCreatorListHandler}
                  updateCreatorListHandler={updateCreatorListHandler}
                />
              </Form>
            )}
          </Formik>
        ) : null}
      </td>
      <td className="mobile-view-past-report">
      </td>
    </tr>
  );
};

export default EmailMatchResultsModalRow;
