import React from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import {
  ChatBubbleLeftEllipsisIcon,
  ChevronDoubleRightIcon,
  EyeIcon,
  LinkIcon,
  ArrowPathIcon,
  SignalIcon,
  ArrowTrendingUpIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { formatNumbersToUnits } from "../../../actions";
const HasNoStory = (props) => {
  const { t } = useTranslation();

  return (
    <div className="section">
      <>
        {props ? (
          <>
            <div className="section-head text-center">
              <h2>{t("reports.story_details.title")}</h2>
            </div>
            <Row>
              <Col sm="12">
                <Card className="card-content-custom">
                  {props.is_private ? (
                    <CardBody>{t("reports.story_details.private")}</CardBody>
                  ) : props.stories?.length === 0 ? (
                    <CardBody>{t("reports.story_details.empty")}</CardBody>
                  ) : null}
                </Card>
              </Col>
              <Col sm="6">
                <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2 story-text">
                        <h2>{formatNumbersToUnits(props.estimated_reach)}</h2>
                        <ArrowTrendingUpIcon className="story-icon" />
                      </div>
                    </div>
                    <CardTitle>{t("reports.story_details.reach")}</CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2 story-text">
                        <h2>
                          {formatNumbersToUnits(props.estimated_impressions)}
                        </h2>
                        <EyeIcon className="story-icon" />
                      </div>
                    </div>
                    <CardTitle>
                      {t("reports.story_details.impression")}
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2 story-text">
                        <h2>{formatNumbersToUnits(props.back)}</h2>
                        <ArrowPathIcon className="story-icon" />
                      </div>
                    </div>
                    <CardTitle>{t("reports.story_details.back")}</CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2 story-text">
                        <h2>{formatNumbersToUnits(props.forwards)}</h2>
                        <ChevronDoubleRightIcon className="story-icon" />
                      </div>
                    </div>
                    <CardTitle>{t("reports.story_details.forwards")}</CardTitle>
                  </CardBody>
                </Card>
              </Col>
              {props.followers > 10000 ? (
                <Col md="6">
                  <Card className="card-content-custom">
                    <CardBody>
                      <div className="country-text-wrap">
                        <div className="country-name pt-2 story-text">
                          <h2>
                            {formatNumbersToUnits(props.estimated_link_clicks)}
                          </h2>
                          <LinkIcon className="story-icon" />
                        </div>
                      </div>
                      <CardTitle>
                        {t("reports.story_details.link_clicks")}
                      </CardTitle>
                    </CardBody>
                  </Card>
                </Col>
              ) : null}
              <Col md="6">
                <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2 story-text">
                        <h2>{props.replies ? Math.round(props.replies) : 0}</h2>
                        <ChatBubbleLeftEllipsisIcon className="story-icon" />
                      </div>
                    </div>
                    <CardTitle>{t("reports.story_details.replies")}</CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2 story-text">
                        <h2>
                          {props.stories_per_day
                            ? Math.round(props.stories_per_day)
                            : 0}
                        </h2>
                        <VideoCameraIcon className="story-icon" />
                      </div>
                    </div>
                    <CardTitle>{t("reports.story_details.day")}</CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2 story-text">
                        <h2>
                          {formatNumbersToUnits(
                            props.stories && props.stories.length === 0
                              ? 0
                              : null
                          )}
                          <SignalIcon className="story-icon" />
                        </h2>
                      </div>
                    </div>
                    <CardTitle>{t("reports.story_details.current")}</CardTitle>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        ) : null}
      </>
    </div>
  );
};

export default HasNoStory;
