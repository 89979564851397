import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './TensorCarousel.scss';

const TensorCarousel = (props) => {

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items:
        props.carouselItems &&
          props.carouselItems.length
          ? props.carouselItems.length >= 3
            ? 3
            : props.carouselItems.length
          : 0,
    },
    tablet: {
      breakpoint: { max: 992, min: 479 },
      items:
        props.carouselItems &&
          props.carouselItems.length
          ? props.carouselItems.length >= 2
            ? 2
            : props.carouselItems.length
          : 0,
    },
    mobile: {
      breakpoint: { max: 479, min: 0 },
      items:
        props.carouselItems &&
          props.carouselItems.length
          ? props.carouselItems.length >= 1
            ? 1
            : props.carouselItems.length
          : 0,
    },
  };

  return (
    <Carousel
      swipeable={true}
      draggable={false}
      showDots={true}
      responsive={responsive}
      arrows
      ssr={true}
      slidesToSlide={1}
      infinite={props.infinite || false}
      autoPlay={props.autoPlay || false}
      autoPlaySpeed={5000}
      keyBoardControl={true}
      transitionDuration={500}
      renderButtonGroupOutside={false}
      {...props}
    >
      {props.carouselItems}
    </Carousel>
  );
}

export default TensorCarousel;
