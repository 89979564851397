import React from "react";
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  UncontrolledTooltip,
  ModalBody,
  Table,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { LazyImage, RenderButton } from "../../../components";
import { showTitle, showTooltip } from "../../../actions";
const InfluencerBrandAffinity = (props) => {
  const { t } = useTranslation();

  return (
    <Col sm="6">
      <Card className="card-content-custom">
        <CardBody>
          <CardTitle>
            {t("reports.influencer_details.brand_affinity")}
          </CardTitle>
          <ListGroup className="ba-list ch-6">
            {props.accountOverviewDetails &&
            props.accountOverviewDetails.user_profile &&
            props.accountOverviewDetails.user_profile.brand_affinity ? (
              props.accountOverviewDetails.user_profile.brand_affinity.map(
                (brand, index) => {
                  if (index < 4) {
                    return (
                      <ListGroupItem
                        key={index}
                        className="d-flex align-items-center"
                      >
                        <div className="img-col-left">
                          <LazyImage
                            src={props.generateImgUrl("brand", brand.id)}
                            alt="img"
                          />
                        </div>
                        <div
                          id={`influencer_${brand.name}`}
                          title={showTitle(
                            `influencer_${brand.name}`,
                            brand.name
                          )}
                          className="text-wrap"
                        >
                          {brand.name}
                        </div>
                      </ListGroupItem>
                    );
                  }
                  return null;
                }
              )
            ) : (
              <span className="text-center text-grey ">
                <p className="pt-50">{t("errors.no_record")}</p>
              </span>
            )}
          </ListGroup>
          {props.accountOverviewDetails &&
          props.accountOverviewDetails.user_profile &&
          props.accountOverviewDetails.user_profile.brand_affinity ? (
            <div className="bottom">
              <RenderButton
                color="link"
                onClick={() =>
                  props.toggleModal("influencersBrandAffinityModal")
                }
              >
                {t("reports.view_more")}
              </RenderButton>
              <Modal
                id="influencersBrandAffinityModalDownload"
                isOpen={props.modals.influencersBrandAffinityModal}
                toggle={() =>
                  props.toggleModal("influencersBrandAffinityModal")
                }
              >
                <ModalHeader
                  toggle={() =>
                    props.toggleModal("influencersBrandAffinityModal")
                  }
                >
                  <span className="tooltip-button-wrap">
                    <span className="text-tooltip">
                      {t("reports.influencer_details.brand_affinity")}
                    </span>
                    <span
                      href="#"
                      id="influencersBrandAffinityModal"
                      className="tooltip-icon"
                    ></span>
                  </span>
                  {props.tooltip && (
                    <UncontrolledTooltip
                      placement="bottom"
                      className="tooltip-content"
                      target="influencersBrandAffinityModal"
                    >
                      {showTooltip("influencersBrandAffinityModal")}
                    </UncontrolledTooltip>
                  )}
                  <RenderButton
                    onClick={() =>
                      props.downloadPdf("influencersBrandAffinityModalDownload")
                    }
                    className="download-icon-button mg-left-15 link"
                  >
                    {t("reports.download")}
                  </RenderButton>
                </ModalHeader>
                <ModalBody>
                  <Table className="modal-table">
                    <thead>
                      <tr>
                        <th colSpan={2}>{t("reports.chart.brand")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.accountOverviewDetails &&
                      props.accountOverviewDetails.user_profile &&
                      props.accountOverviewDetails.user_profile
                        .brand_affinity ? (
                        props.accountOverviewDetails.user_profile.brand_affinity.map(
                          (affinity, index) => (
                            <tr key={index}>
                              <th colSpan={2} scope="row">
                                <div className="country-text-wrap">
                                  <div className="flag-img brand-img">
                                    <img
                                      src={props.generateImgUrl(
                                        "brand",
                                        affinity.id
                                      )}
                                      alt="img"
                                    />
                                  </div>
                                  <div className="brand-name">
                                    {affinity.name}
                                  </div>
                                </div>
                              </th>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td align="center" colSpan="2">
                            {t("errors.no_record")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </ModalBody>
              </Modal>
            </div>
          ) : null}
        </CardBody>
      </Card>
    </Col>
  );
};

export default InfluencerBrandAffinity;
