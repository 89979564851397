import React from "react";
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip,
  Badge,
} from "reactstrap";
import useDarkMode from "use-dark-mode";
import { useTranslation } from "react-i18next";
import {
  formatNumbersToCommaSeperated,
  formatNumbersToUnits,
} from "../../../actions";
import { RenderButton } from "../../../components";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import similarAccountPic from "../../../img/user-circle-outline.svg";
import { showTooltip } from "../../../actions";

const EngagementRateSection = (props) => {
  const { t } = useTranslation();
  const darkMode = useDarkMode(false);

  const tickPositions = [];
  const userProfilePicPath =
    props.accountOverviewDetails &&
    props.accountOverviewDetails.user_profile &&
    props.accountOverviewDetails.user_profile.picture
      ? props.accountOverviewDetails.user_profile.picture
      : null;
  if (
    props.accountOverviewDetails &&
    props.accountOverviewDetails.extra &&
    props.accountOverviewDetails.extra.engagement_rate_histogram
  ) {
    props.accountOverviewDetails.extra.engagement_rate_histogram.map(
      (eng, index) => {
        if (eng.median) {
          return tickPositions.push(index + 1);
        }
        return null;
      }
    );
  }
  const data =
    props.accountOverviewDetails &&
    props.accountOverviewDetails.extra &&
    props.accountOverviewDetails.extra.engagement_rate_histogram
      ? props.accountOverviewDetails.extra.engagement_rate_histogram.map(
          (eng, index) => {
            let isUserSlot = false;

            if (eng.median) {
              isUserSlot = false;
            } else if (
              eng.max &&
              !eng.min &&
              props.accountOverviewDetails.user_profile.engagement_rate <=
                eng.max
            ) {
              isUserSlot = true;
            } else if (
              !eng.max &&
              eng.min &&
              props.accountOverviewDetails.user_profile.engagement_rate >=
                eng.max
            ) {
              isUserSlot = true;
            } else if (
              eng.max &&
              eng.min &&
              props.accountOverviewDetails.user_profile.engagement_rate >=
                eng.min &&
              props.accountOverviewDetails.user_profile.engagement_rate <=
                eng.max
            ) {
              isUserSlot = true;
            }
            return {
              x: index + 1,
              id: `tick_${index + 1}`,
              xAxisLabelName: eng.median
                ? `<b>${t(
                    "reports.influencer_details.engagement_rate.graph"
                  )}</b>`
                : "",
              isUserSlot: isUserSlot,
              y: eng.total,
              name: !eng.min
                ? `<${(eng.max * 100).toFixed(2)}%`
                : !eng.max
                ? `>${(eng.min * 100).toFixed(2)}%`
                : `${(eng.min * 100).toFixed(2)}-${(eng.max * 100).toFixed(
                    2
                  )}%`,
            };
          }
        )
      : [];
  const chartOptions = {
    chart: {
      height: 200,
      animation: false,
      backgroundColor: null,
    },
    title: {
      text: "",
    },
    xAxis: {
      lineColor: "#1f87ee",
      lineWidth: 5,
      tickPositions: tickPositions,
      labels: {
        enabled: true,
        formatter: function () {
          return (
            data.find((eng) => eng.x === this.value) &&
            data.find((eng) => eng.x === this.value).xAxisLabelName
          );
        },
      },
    },
    yAxis: {
      lineWidth: 0,
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      stackLabels: {
        enabled: true,
        useHTML: true,
        formatter: function () {
          return data.find((eng) => eng.y === this.total) &&
            data.find((eng) => eng.y === this.total).xAxisLabelName !== ""
            ? `<span class="img-graph-bg"><img width="50px" src=${similarAccountPic} /></span>`
            : data.find((eng) => eng.y === this.total).isUserSlot
            ? `<span class="img-graph-bg"><img width="50px" src=${userProfilePicPath} /></span>`
            : "";
        },
      },

      title: {
        text: null,
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        animation: false,
      },
    },
    tooltip: {
      enabled: true,
      outside: true,
      backgroundColor: "#17222d",
      borderColor: "transparent",
      borderRadius: 10,
      shadow: false,
      style: {
        color: "white",
        fontSize: "11px",
      },
      formatter: function () {
        return `<b>${this.point.name}<br/>${formatNumbersToUnits(
          this.y
        )} Accounts`;
      },
    },
    legend: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
    series: [
      {
        type: "column",
        name: "Max",
        states: {
          hover: {
            color: "#ddd",
          },
        },
        color: darkMode.value ? "#898e94" : "#e6e6e6",
        borderColor: "transparent",
        data: data,
      },
    ],
  };

  let div1Width =
    props.accountOverviewDetails &&
    props.accountOverviewDetails.user_profile &&
    isFinite(props.accountOverviewDetails.user_profile.trending_analysis) &&
    props.accountOverviewDetails.user_profile.trending_analysis < 0
      ? 50 -
        Math.abs(
          props.accountOverviewDetails.user_profile.trending_analysis * 100
        ) /
          2 +
        "%"
      : 50 +
        Math.abs(
          props.accountOverviewDetails.user_profile.trending_analysis * 100
        ) /
          2 +
        "%";
  let div2Width =
    props.accountOverviewDetails &&
    props.accountOverviewDetails.user_profile &&
    isFinite(props.accountOverviewDetails.user_profile.trending_analysis) &&
    props.accountOverviewDetails.user_profile.trending_analysis < 0
      ? 100 -
        Math.abs(
          50 -
            Math.abs(
              props.accountOverviewDetails.user_profile.trending_analysis * 100
            ) /
              2
        ) +
        "%"
      : 100 -
        (50 +
          Math.abs(
            props.accountOverviewDetails.user_profile.trending_analysis * 100
          ) /
            2) +
        "%";

  return (
    <>
      {props.accountOverviewDetails &&
      props.accountOverviewDetails.user_profile &&
      isFinite(props.accountOverviewDetails.user_profile.trending_analysis) ? (
        <Col sm="6">
          <Card className="card-content-custom ch-378">
            <CardBody>
              <CardTitle>
                <span className="tooltip-button-wrap">
                  <span className="text-tooltip">
                    {t("reports.influencer_details.trending.title")}
                  </span>
                  <span
                    id="trendingAnalysisTooltip"
                    className="tooltip-icon"
                  ></span>
                </span>
                {props.tooltip && (
                  <UncontrolledTooltip
                    className="tooltip-content"
                    target="trendingAnalysisTooltip"
                  >
                    {showTooltip("trendingAnalysisTooltip")}
                  </UncontrolledTooltip>
                )}
              </CardTitle>
              <h2>
                {props.accountOverviewDetails.user_profile.trending_analysis}
                <span></span>
                <Badge
                  className={`fs--2 ml-2 rank-wrap ${
                    props.accountOverviewDetails &&
                    props.accountOverviewDetails.user_profile
                      .trending_analysis > 0
                      ? "rank-up badge-soft-success"
                      : props.accountOverviewDetails.user_profile
                          .trending_analysis < 0
                      ? "rank-down badge-soft-danger"
                      : "rank-neutral badge-soft-neutral"
                  }`}
                >
                  {props.accountOverviewDetails.user_profile.trending_analysis >
                  0
                    ? t("reports.influencer_details.trending.up")
                    : props.accountOverviewDetails.user_profile
                        .trending_analysis < 0
                    ? t("reports.influencer_details.trending.down")
                    : t("reports.influencer_details.trending.neutral")}
                </Badge>
              </h2>
              <div className="pt-90">
                <div className="trending-analysis-labels">
                  <span>-1</span>
                  <span>0</span>
                  <span>1</span>
                </div>
                <div className="progress trending-analysis-value">
                  <div
                    className="trending-overlap-div"
                    style={{ minWidth: div1Width }}
                  ></div>
                  <div
                    className="trending-overlap-transparent-div"
                    style={{ minWidth: "2px" }}
                  ></div>
                  <div
                    className="trending-overlap-div"
                    style={{ minWidth: div2Width }}
                  ></div>

                  <div
                    className="progress-bar trending-analysis-progress-bar"
                    role="progressbar"
                    style={{ width: "100%" }}
                  ></div>
                </div>
                <div className="progress trending-analysis-progress">
                  <div
                    id="progress-1"
                    className="progress-bar trending-analysis-progress-bar"
                    role="progressbar"
                    style={{ width: "100%" }}
                  ></div>
                </div>
                <div className="trending-analysis-labels">
                  <span>{t("reports.influencer_details.trending.bad")}</span>
                  <span>{t("reports.influencer_details.trending.normal")}</span>
                  <span>{t("reports.influencer_details.trending.good")}</span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ) : null}
      {props.accountOverviewDetails &&
      props.accountOverviewDetails.user_profile &&
      props.accountOverviewDetails.user_profile.engagement_rate ? (
        <Col sm="6">
          <Card className="card-content-custom ">
            <CardBody>
              <CardTitle>
                <span className="tooltip-button-wrap">
                  <span className="text-tooltip">
                    {t("reports.influencer_details.engagement_rate.title")}
                  </span>
                  <span id="engagementTooltip" className="tooltip-icon"></span>
                </span>
                {props.tooltip && (
                  <UncontrolledTooltip
                    className="tooltip-content"
                    target="engagementTooltip"
                  >
                    {showTooltip("engagementTooltip")}
                  </UncontrolledTooltip>
                )}
              </CardTitle>
              <h2>
                {props.accountOverviewDetails &&
                props.accountOverviewDetails.user_profile &&
                props.accountOverviewDetails.user_profile.engagement_rate
                  ? (
                      props.accountOverviewDetails.user_profile
                        .engagement_rate * 100
                    ).toFixed(2) + "%"
                  : ""}
              </h2>

              <div
                id="customChart"
                className="graph-wrap card-content position-relative ch-200"
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              </div>
              <div className="bottom">
                <Button
                  color="link"
                  onClick={() => props.toggleModal("engagementRateModal")}
                >
                  {t("reports.view_more")}
                </Button>

                <Modal
                  id="engagementRateModalDownload"
                  isOpen={props.modals.engagementRateModal}
                  toggle={() => props.toggleModal("engagementRateModal")}
                >
                  <ModalHeader
                    toggle={() => props.toggleModal("engagementRateModal")}
                  >
                    <span className="tooltip-button-wrap">
                      <span className="text-tooltip">
                        {t("reports.influencer_details.engagement_rate.title")}
                      </span>
                      <span
                        href="#"
                        id="engagementRateModal"
                        className="tooltip-icon"
                      ></span>
                    </span>
                    {props.tooltip && (
                      <UncontrolledTooltip
                        placement="bottom"
                        className="tooltip-content"
                        target="engagementRateModal"
                      >
                        {showTooltip("engagementRateModal")}
                      </UncontrolledTooltip>
                    )}
                    <RenderButton
                      onClick={() =>
                        props.downloadPdf("engagementRateModalDownload")
                      }
                      className="download-icon-button mg-left-15 link"
                    >
                      {t("reports.download")}
                    </RenderButton>
                  </ModalHeader>
                  <ModalBody>
                    <Table className="modal-table">
                      <thead>
                        <tr>
                          <th colSpan="2">
                            {t(
                              "reports.influencer_details.engagement_rate.title"
                            )}
                          </th>
                          <th className="text-right">
                            {t(
                              "reports.influencer_details.engagement_rate.accounts"
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.accountOverviewDetails &&
                        props.accountOverviewDetails.extra &&
                        props.accountOverviewDetails.extra
                          .engagement_rate_histogram &&
                        props.accountOverviewDetails.extra
                          .engagement_rate_histogram.length ? (
                          props.accountOverviewDetails.extra.engagement_rate_histogram.map(
                            (eng, index) => (
                              <tr key={index}>
                                <th scope="row">
                                  {!eng.min
                                    ? `<${(eng.max * 100).toFixed(2)}%`
                                    : !eng.max
                                    ? `>${(eng.min * 100).toFixed(2)}%`
                                    : `${(eng.min * 100).toFixed(2)}-${(
                                        eng.max * 100
                                      ).toFixed(2)}%`}
                                </th>
                                <td className="text-left">&nbsp;</td>
                                <td className="text-right">
                                  {formatNumbersToCommaSeperated(eng.total)}
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td align="center" colSpan="3">
                              {t("errors.no_record")}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </ModalBody>
                </Modal>
              </div>
            </CardBody>
          </Card>
        </Col>
      ) : null}
    </>
  );
};

export default EngagementRateSection;
