import { audience_age_right, audience_age_left } from "../../helpers/Age";
const calculateSearchObj = (state) => {
  let relevance =
    state.relevance && state.relevance.length
      ? state.relevance.map((item, index) => item.value)
      : [];
  let searchObj = {
    platform: state.platformValue && state.platformValue.value,
    search: { account_type: [] },
    sort: {
      field: state.sortColumn,
      direction: state.sortDesc ? "desc" : "asc",
    },
  };
  if (state.influencerLookalikeValue && state.influencerLookalikeValue.value) {
    relevance.push(`@${state.influencerLookalikeValue.value}`);
  }
  searchObj.search.relevance = {
    value: relevance.length ? relevance.join(" ") : "",
    weight: 0,
  };
  searchObj.emailMatch_id = state.emailMatch_id && state.emailMatch_id.trim();
  searchObj.creatorList_id =
    state.creatorList_id && state.creatorList_id.trim();
  searchObj.search.text = state.bio && state.bio.trim();
  searchObj.search.keywords = state.keywords && state.keywords.trim();
  searchObj.search.audience_relevance = {};
  searchObj.search.audience_relevance.value =
    state.audienceLookalikeValue && state.audienceLookalikeValue.value
      ? state.audienceLookalikeValue.value
      : "";

  if (state.username) {
    searchObj.search.username = {
      value: state.username,
      operator: "exact",
    };
  }

  if (state.audienceLocationValues.find((loc) => loc.value)) {
    searchObj.search.audience_geo = state.audienceLocationValues.map((loc) => ({
      id: loc.value,
      weight: parseFloat((loc.weight && loc.weight.value) || 0),
    }));
  }

  if (state.influencerLocationValues.find((loc) => loc.value)) {
    searchObj.search.geo = state.influencerLocationValues.map((loc) => ({
      id: loc.value,
    }));
  }
  if (state.universityValue && state.universityValue.value) {
    searchObj.university = {
      text: state.universityValue.label,
      value: state.universityValue.value.slice(2),
    };
  }

  if (state.contactsValues.find((loc) => loc.value)) {
    searchObj.search.with_contact = state.contactsValues.map((loc) => ({
      type: loc.value,
      action: "should",
    }));
  }

  if (state.audienceLanguage && state.audienceLanguage.value) {
    searchObj.search.audience_lang = {
      code: state.audienceLanguage.value,
      weight: parseFloat(
        (state.languageWeight && state.languageWeight.value) || 0
      ),
    };
  }

  if (state.influencerLanguage && state.influencerLanguage.value) {
    searchObj.search.lang = { code: state.influencerLanguage.value };
  }

  if (state.audienceBrandsValues.find((loc) => loc.value)) {
    searchObj.search.audience_brand = state.audienceBrandsValues.map((loc) => ({
      id: loc.value,
      weight: parseFloat((loc.weight && loc.weight.value) || 0),
    }));
  }

  if (state.influencerBrandsValues.find((loc) => loc.value)) {
    searchObj.search.brand = state.influencerBrandsValues.map(
      (loc) => loc.value
    );
  }

  if (state.influencerPartnershipValues.find((loc) => loc.value)) {
    searchObj.search.ads_brands = state.influencerPartnershipValues.map(
      (loc) => loc.value
    );
  }

  if (state.audienceInterestsValues.find((loc) => loc.value)) {
    searchObj.search.audience_brand_category =
      state.audienceInterestsValues.map((loc) => ({
        id: loc.value,
        weight: parseFloat((loc.weight && loc.weight.value) || 0),
      }));
  }

  if (state.influencerInterestsValues.find((loc) => loc.value)) {
    searchObj.search.brand_category = state.influencerInterestsValues.map(
      (loc) => loc.value
    );
  }

  if (state.genderAudience) {
    searchObj.search.audience_gender = {
      code: state.genderAudience,
      weight: parseFloat((state.genderWeight && state.genderWeight.value) || 0),
    };
  }

  if (state.genderInfluencer) {
    searchObj.search.gender = { code: state.genderInfluencer };
  }

  if (state.followersGrowth) {
    searchObj.search.followers_growth = {
      interval: state.followersGrowth.value,
      value: parseFloat(
        (state.followersGrowthWeight && state.followersGrowthWeight.value) || 0
      ),
    };
  }

  if (state.likesGrowth) {
    searchObj.search.total_likes_growth = {
      interval: state.likesGrowth.value,
      value: parseFloat(
        (state.likesGrowthWeight && state.likesGrowthWeight.value) || 0
      ),
    };
  }

  if (state.viewsGrowth) {
    searchObj.search.total_views_growth = {
      interval: state.viewsGrowth.value,
      value: parseFloat(
        (state.viewsGrowthWeight && state.viewsGrowthWeight.value) || 0
      ),
    };
  }

  if (state.ethnicityAudience) {
    searchObj.search.audience_race = {
      code: state.ethnicityAudience,
      weight: parseFloat(
        (state.ethnicityWeight && state.ethnicityWeight.value) || 0
      ),
    };
  }

  if (
    (state.audiencesAgeLeft && state.audiencesAgeLeft.value) ||
    (state.audiencesAgeRight && state.audiencesAgeRight.value)
  ) {
    let code = "";
    let weight =
      (state.audiencesAgeWeight && state.audiencesAgeWeight.value) || 0;
    let audience_age = [];
    let rightAgeGreaterThan65 =
      state.audiencesAgeRight &&
      state.audiencesAgeRight.value &&
      state.audiencesAgeRight.value === 65
        ? true
        : false;
    let rightList = audience_age_right;
    let leftList = audience_age_left;
    let rightIndex =
      state.audiencesAgeRight &&
      state.audiencesAgeRight.value &&
      rightList.findIndex(
        (obj) => obj.value === parseInt(state.audiencesAgeRight.value, 10)
      ) >= 0
        ? rightList.findIndex(
            (obj) => obj.value === parseInt(state.audiencesAgeRight.value, 10)
          )
        : -1;
    let leftIndex =
      state.audiencesAgeLeft &&
      state.audiencesAgeLeft.value &&
      leftList.findIndex(
        (obj) => obj.value === parseInt(state.audiencesAgeLeft.value, 10)
      ) >= 0
        ? leftList.findIndex(
            (obj) => obj.value === parseInt(state.audiencesAgeLeft.value, 10)
          )
        : -1;
    if (leftIndex >= 0 && rightIndex < 0) {
      for (let i = leftIndex; i <= audience_age_left.length - 1; i++) {
        if (audience_age_right[i].value === 65) {
          audience_age.push({
            code: "65-",
            weight: weight,
          });
        } else {
          audience_age.push({
            code: `${audience_age_left[i].value}-${audience_age_right[i].value}`,
            weight: weight,
          });
        }
      }
    } else if (rightIndex >= 0 && leftIndex < 0) {
      if (rightAgeGreaterThan65) {
        audience_age.push({
          code: "65-",
          weight: weight,
        });
      } else {
        for (let i = rightIndex; i >= 0; i--) {
          audience_age.push({
            code: `${audience_age_left[i].value}-${audience_age_right[i].value}`,
            weight: weight,
          });
        }
      }
    } else if (rightIndex >= 0 && leftIndex >= 0) {
      if (rightAgeGreaterThan65) {
        for (let i = leftIndex; i <= rightIndex - 1; i++) {
          code = `${audience_age_left[i].value}-${audience_age_right[i].value}`;
          audience_age.push({
            code: code,
            weight: weight,
          });
        }
        audience_age.push({
          code: "65-",
          weight: weight,
        });
      } else {
        for (let i = leftIndex; i <= rightIndex; i++) {
          code = `${audience_age_left[i].value}-${audience_age_right[i].value}`;
          audience_age.push({
            code: code,
            weight: weight,
          });
        }
      }
    }

    searchObj.search.audience_age = audience_age;
  }

  if (
    (state.influencersAgeLeft && state.influencersAgeLeft.value) ||
    (state.influencersAgeRight && state.influencersAgeRight.value)
  ) {
    searchObj.search.age = {
      left_number: state.influencersAgeLeft && state.influencersAgeLeft.value,
      right_number:
        state.influencersAgeRight && state.influencersAgeRight.value,
    };
  }

  if (
    (state.followersFrom && state.followersFrom.value) ||
    (state.followersTo && state.followersTo.value)
  ) {
    searchObj.search.followers = {
      left_number: parseFloat(
        (state.followersFrom && state.followersFrom.value) || null
      ),
      right_number: parseFloat(
        (state.followersTo && state.followersTo.value) || null
      ),
    };
  }

  if (
    (state.viewsFrom && state.viewsFrom.value) ||
    (state.viewsTo && state.viewsTo.value)
  ) {
    searchObj.search.views = {
      left_number: parseFloat(
        (state.viewsFrom && state.viewsFrom.value) || null
      ),
      right_number: parseFloat((state.viewsTo && state.viewsTo.value) || null),
    };
  }

  if (
    (state.reelsFrom && state.reelsFrom.value) ||
    (state.reelsTo && state.reelsTo.value)
  ) {
    searchObj.search.reels_plays = {
      left_number: parseFloat(
        (state.reelsFrom && state.reelsFrom.value) || null
      ),
      right_number: parseFloat((state.reelsTo && state.reelsTo.value) || null),
    };
  }

  if (
    (state.engagementsInfluencersFrom &&
      state.engagementsInfluencersFrom.value) ||
    (state.engagementsInfluencersTo && state.engagementsInfluencersTo.value)
  ) {
    searchObj.search.engagements = {
      left_number: parseFloat(
        (state.engagementsInfluencersFrom &&
          state.engagementsInfluencersFrom.value) ||
          null
      ),
      right_number: parseFloat(
        (state.engagementsInfluencersTo &&
          state.engagementsInfluencersTo.value) ||
          null
      ),
    };
  }

  if (state.engagementRate && state.engagementRate.value) {
    searchObj.search.engagement_rate = {
      value: parseFloat(state.engagementRate.value || null),
      operator:
        (state.engagementRateOperator && state.engagementRateOperator.value) ||
        null,
    };
  }

  if (state.otherFilters.is_verified) {
    searchObj.search.is_verified = state.otherFilters.is_verified;
  }
  if (state.otherFilters.onlyCredibleAccount) {
    searchObj.search.audience_credibility_class = [
      "low",
      "normal",
      "high",
      "best",
    ];
  }

  if (state.otherFilters.is_hidden) {
    searchObj.search.is_hidden = !state.otherFilters.is_hidden;
  }

  if (state.otherFilters.has_audience_data) {
    searchObj.search.has_audience_data = state.otherFilters.has_audience_data;
  }

  if (state.otherFilters.hasContactDetails) {
    searchObj.search.has_email = state.otherFilters.hasContactDetails;
  }

  if (state.otherFilters.hasSponseredPosts) {
    searchObj.search.has_ads = state.otherFilters.hasSponseredPosts;
  }
  if (state.audienceType) {
    searchObj.audience_source = state.audienceType;
  }
  if (state.lastPost) {
    searchObj.search.last_posted = parseInt(state.lastPost);
  }
  (state.accountTypeFiltersOptions || []).map((option) => {
    if (state.accountTypeFilters[option.type] === true) {
      return searchObj.search.account_type.push(option.value);
    }
    return null;
  });
  return searchObj;
};
export default calculateSearchObj;
