import {
	actionCreator,
	createQueryParams,
	privateHeaders,
	checkHttpStatus,
	checkAuthTokenExpiry,
	stripePayment,
} from "../../../actions";
import {
	emailMatchActionTypes,
	CREATE_ONE_EMAIL_MATCH_URL,
	READ_ONE_DOWNLOAD_EMAIL_MATCH_URL,
	READ_MANY_EMAIL_MATCHS_URL,
	// DELETE_ONE_EMAIL_MATCH_URL,
} from "../constants/constants_email_match";
import { signOut } from "../../login/actions";
import moment from "moment";

export const showLoadingAction = (action) => {
	return (dispatch, getState) => {
		if (action.toLowerCase() === "start") {
			dispatch(actionCreator(emailMatchActionTypes.showLoading.REQUEST));
		} else if (action.toLowerCase() === "stop") {
			dispatch(actionCreator(emailMatchActionTypes.showLoading.SUCCESS));
		}
	};
};

export const setShowFileUploading = (bool) => {
	return (dispatch, getState) => {
		if (bool) {
			dispatch(actionCreator(emailMatchActionTypes.showFileUploading.SUCCESS));
		} else {
			dispatch(actionCreator(emailMatchActionTypes.hideFileUploading.SUCCESS));
		}
	}
}

export const setShowUploadComplete = (bool) => {
	return (dispatch, getState) => {
		if (bool) {
			dispatch(actionCreator(emailMatchActionTypes.showUploadComplete.SUCCESS));
		} else {
			dispatch(actionCreator(emailMatchActionTypes.hideUploadComplete.SUCCESS));
		}
	}
}

export const createOneEmailMatch = (emailMatchFile) => {
	return (dispatch, getState) => {
		dispatch(actionCreator(emailMatchActionTypes.createOneEmailMatch.REQUEST));
		dispatch(setShowFileUploading(true));

		let formData = new FormData();
		formData.append('file', emailMatchFile);

		fetch(`${CREATE_ONE_EMAIL_MATCH_URL}`, {
			method: "POST",
			body: formData,
			headers: privateHeaders(getState, 'none'),
		})
			.then(checkHttpStatus)
			.then((response) => checkAuthTokenExpiry(response))
			.then((response) => stripePayment(response))
			.then((response) => {
				if (response && response.result) {
					dispatch(
						actionCreator(
							emailMatchActionTypes.createOneEmailMatch.SUCCESS
						)
					);
					dispatch(setShowFileUploading(false));
					dispatch(readManyEmailMatchs());
				} else {
					dispatch(
						actionCreator(emailMatchActionTypes.createOneEmailMatch.FAILURE, response && response.message ? response.message : "Error processing email matches")
					);
					dispatch(setShowFileUploading(false));
				}
			})
			.catch((error) => {
				dispatch(
					actionCreator(emailMatchActionTypes.createOneEmailMatch.FAILURE, 'Error processing email matches')
				);
				if (
					error.statusCode &&
					error.statusCode >= 400 &&
					error.statusCode <= 500
				) {
					dispatch(signOut(error));
				}
			});
	};
};

export const readManyEmailMatchs = (pageNumber) => {
	return (dispatch, getState) => {
		dispatch(actionCreator(emailMatchActionTypes.readManyEmailMatchs.REQUEST));

		const limit = 10;
		const query = {
			limit: limit,
			skip: pageNumber ? (pageNumber - 1) * limit : 0,
			sortKey: 'created',
			sortValue: -1
		};

		const queryParams = createQueryParams(query);

		fetch(`${READ_MANY_EMAIL_MATCHS_URL}${queryParams}`, {
			method: "GET",
			headers: privateHeaders(getState),
		})
			.then(checkHttpStatus)
			.then((response) => checkAuthTokenExpiry(response))
			.then((response) => {
				if (response && response.results) {
					dispatch(
						actionCreator(
							emailMatchActionTypes.readManyEmailMatchs.SUCCESS, response
						)
					);
				} else {
					dispatch(
						actionCreator(emailMatchActionTypes.readManyEmailMatchs.FAILURE, 'Error retrieving email match records')
					);
				}
			})
			.catch((error) => {
				dispatch(
					actionCreator(emailMatchActionTypes.readManyEmailMatchs.FAILURE)
				);
				if (
					error.statusCode &&
					error.statusCode >= 400 &&
					error.statusCode <= 500
				) {
					dispatch(signOut(error));
				}
			});
	};
};

export const readOneDownloadEmailMatch = (id) => {
	const downloadURL = READ_ONE_DOWNLOAD_EMAIL_MATCH_URL.replace(
		":emailMatch_id",
			id
	);
	return (dispatch, getState) => {
		dispatch(actionCreator(emailMatchActionTypes.readOneDownloadEmailMatch.REQUEST));
		fetch(downloadURL, {
			method: 'GET',
			headers: privateHeaders(getState, 'none'),
		})
			.then((response) => response.blob())
			.then((blob) => {
				if (blob && blob.type === 'text/csv') {
					dispatch(
						actionCreator(
							emailMatchActionTypes.readOneDownloadEmailMatch.SUCCESS, true
						)
					);
					let url = window.URL.createObjectURL(blob);
					let link = document.createElement('a');
					link.href = url;
					link.download = `email_match_results_${moment().format('YYYY-MM-DD')}.csv`;
					document.body.appendChild(link);
					link.click();
					link.parentNode.removeChild(link);
				} else {
					dispatch(
						actionCreator(emailMatchActionTypes.readOneDownloadEmailMatch.FAILURE, 'Error downloading email match results')
					);
				}
			})
			.catch((error) => {
				dispatch(
					actionCreator(emailMatchActionTypes.readOneDownloadEmailMatch.FAILURE, 'Error downloading email match results')
				);
				if (
					error.statusCode &&
					error.statusCode >= 400 &&
					error.statusCode <= 500
				) {
					dispatch(signOut(error));
				}
			});
	}
}

export const setEmailMatchError = (message) => {
	return (dispatch, getState) => {
		dispatch(actionCreator(emailMatchActionTypes.setEmailMatchError.SUCCESS, message));
	};
};

export const clearEmailMatchError = () => {
	return (dispatch, getState) => {
		dispatch(setEmailMatchError(''));
	};
}
