import React from "react";
import { Card, CardBody, CardLink, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import TensorCarousel from "../../components/carousel";
import tutorialArticles from "../../helpers/tutorialArticles";
import {
  ChartPieIcon,
  HomeIcon,
  MegaphoneIcon,
  MagnifyingGlassIcon,
  MagnifyingGlassCircleIcon,
  UsersIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import "./GettingStarted.scss";

const iconsMap = {
  global_search: MagnifyingGlassCircleIcon,
  dashboard: HomeIcon,
  discovery: MagnifyingGlassIcon,
  reports: ChartPieIcon,
  reports_audience: UsersIcon,
  reports_influencer: MegaphoneIcon,
  discovery_export: ArrowDownTrayIcon,
};

const GettingStarted = (props) => {
  const { t } = useTranslation();

  const articles = tutorialArticles.map((article, key) => {
    let CardIcon = iconsMap[article?.icon];
    return (
      <Card key={key}>
        <div className="card-img-top icon-container"></div>
        <CardBody>
          <Row className="pb-5">
            <Col md="2">
              <CardIcon
                className="card-icon"
                width={30}
                height={30}
                strokeWidth={2}
              />
            </Col>
            <Col md="10">
              <h5 className="card-title">{article?.title}</h5>
            </Col>
          </Row>
          <div className="link-container">
            <CardLink
              href={article?.url}
              target="_blank"
              className="btn btn-primary"
            >
              {t("dashboard.getting_started.learn_more")}
            </CardLink>
          </div>
        </CardBody>
      </Card>
    );
  });

  return (
    <>
      {tutorialArticles && !!tutorialArticles.length && (
        <>
          <div className="text-wrapper" style={{ padding: "30px 15px 8px" }}>
            <h2 className="page-heading">
              {t("dashboard.getting_started.title")}
            </h2>
            <p className="sub-text text-grey">
              {t("dashboard.getting_started.description")}
            </p>
          </div>
          <TensorCarousel autoPlay={false} carouselItems={articles} />
        </>
      )}
    </>
  );
};

export default GettingStarted;
