import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import "./index.scss";
// TODO[epic=campaign-manager]: empty state image
import emptyStateImage from "../../img/creator_list_image.svg";
import CampaignsTable from "./CampaignsTable";
import {
  deleteCampaign,
  getCampaigns,
  setNewPostModalIsOpen,
  setCampaignModalIsOpen,
} from "./actions";
import PostUrlForm from "./components/PostUrlForm";
import NewPostModal from "./modals/NewPostModal";
import CampaignModal from "./modals/CampaignModal";

const CampaignManager = function ({
  campaignsCount,
  campaigns,
  loading,

  getCampaigns,
  deleteCampaign,
  setNewPostModalIsOpen,
  setCampaignModalIsOpen,
  campaignModalIsOpen,

  ...props
}) {
  const { t } = useTranslation();

  const [newPostUrl, setNewPostUrl] = useState("");
  const [collectPostUrlInModal, setCollectPostUrlInModal] = useState(false);
  const [defaultCampaign, setDefaultCampaign] = useState(null);

  const openNewPostModalHandler = ({ campaign, collectUrl, url, ...rest }) => {
    if (collectUrl) {
      setNewPostUrl("");
      setCollectPostUrlInModal(true);
    } else if (url) {
      setNewPostUrl(url);
      setCollectPostUrlInModal(false);
    }
    setDefaultCampaign(campaign || null);
    setNewPostModalIsOpen(true, collectUrl);
  };

  const closedNewPostModalHandler = () => {
    setNewPostUrl("");
  };

  const campaignDeleteHandler = (id) => {
    deleteCampaign(id);
  };

  const openCampaignHandler = (campaign_id) => {
    setCampaignModalIsOpen(true, campaign_id);
  };

  const toggleCampaignHandler = (campaign_id) => {
    setCampaignModalIsOpen(!campaignModalIsOpen, campaign_id);
  };

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  return (
    <Container className="main-site-content splash campaign-manager">
      <Row className="page-header">
        <Col className="is-search-block search-wrap">
          {campaignsCount ? (
            <>
              <h1 className="page-title">{t("campaign_manager.title")}</h1>

              <p className="sub-text text-grey mb-0">
                {t("campaign_manager.description")}
              </p>
              <Row>
                <Col xs="12" sm="10" md="8">
                  <PostUrlForm submitHandler={openNewPostModalHandler} />
                </Col>
              </Row>

              <CampaignsTable
                campaigns={campaigns}
                campaignsCount={campaignsCount}
                openCampaignHandler={openCampaignHandler}
                toggleCampaignHandler={toggleCampaignHandler}
                deleteCampaignHandler={campaignDeleteHandler}
                openNewPostModalHandler={openNewPostModalHandler}
              />
            </>
          ) : (
            <div align="center" className="welcome-page">
              {!loading && (
                <>
                  <img
                    className="welcome-img"
                    src={emptyStateImage}
                    alt="dashboard_image"
                  />
                  <div className="text-wrapper">
                    <h6 className="page-heading">
                      {t("campaign_manager.welcome")}
                    </h6>
                    <p className="text-grey">
                      {t("campaign_manager.welcome_description")}
                    </p>
                  </div>
                  <Row className="justify-content-center">
                    <Col xs="12" sm="10" md="8">
                      <PostUrlForm
                        submitHandler={openNewPostModalHandler}
                        isCentered={true}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
          )}

          <NewPostModal
            campaigns={campaigns}
            defaultCampaign={defaultCampaign}
            postUrlOverride={newPostUrl}
            collectPostUrlInModal={collectPostUrlInModal}
            closedNewPostModalHandler={closedNewPostModalHandler}
          />
          <CampaignModal
            deleteCampaignHandler={campaignDeleteHandler}
            openNewPostModalHandler={openNewPostModalHandler}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.campaignManager.loading,
    campaigns: state.campaignManager.campaigns,
    campaignsCount: state.campaignManager.campaignsCount,
    campaignModalIsOpen: state.campaignManager.campaignModalIsOpen,
  };
};
export default connect(mapStateToProps, {
  getCampaigns,
  deleteCampaign,
  setNewPostModalIsOpen,
  setCampaignModalIsOpen,
})(CampaignManager);
