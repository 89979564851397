import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, CardBody, CardLink, Popover } from "reactstrap";
import moment from "moment";
import { formatNumbersToUnits } from "actions";
import networkImage from "helpers/networkImage";
import defaultCreatorImg from "../../../img/user-circle-outline.svg";
import { useTranslation } from "react-i18next";
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/24/outline";
import PreciseMetricDisplayHover from "./PreciseMetricDisplayHover";
import Loader from "loader";

const CampaignPostDisplayCard = ({
  post,
  postPreviewImage,
  isCampaignShared,
  creatorPreviewImage,
  deletePostHandler,

  ...props
}) => {
  const { t } = useTranslation();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [imageBackgroundLoading, setImageBackgroundLoading] = useState(true);
  const [imageCreatorLoading, setImageCreatorLoading] = useState(true);

  const truncatedDateElem = useRef(null);
  const truncatedTitleElem = useRef(null);
  const truncatedTextElem = useRef(null);

  const togglePopover = () => {
    setPopoverOpen((prevState) => !prevState);
  };

  const onDeleteHandler = () => {
    togglePopover();
    if (!post?.isPreview) {
      deletePostHandler(post?._id);
    }
  };

  const isTruncated = (elem) => {
    const bool =
      elem.current?.scrollHeight > elem.current?.clientHeight ||
      elem.current?.scrollWidth > elem.current?.offsetWidth;
    return bool;
  };

  useEffect(() => {
    if (!post) return;
    if (truncatedDateElem.current && !isTruncated(truncatedDateElem)) {
      truncatedDateElem.current.setAttribute("title", "");
    }
    if (truncatedTitleElem.current && !isTruncated(truncatedTitleElem)) {
      truncatedTitleElem.current.setAttribute("title", "");
    }
    if (truncatedTextElem.current && !isTruncated(truncatedTextElem)) {
      truncatedTextElem.current.setAttribute("title", "");
    }
  }, [post]);

  return (
    <div className="campaign-manager mt-0">
      <Card className={`post-preview mb-1 ${post?.network}`}>
        {(imageLoading || imageBackgroundLoading || imageCreatorLoading) && (
          <Loader className="social-cloud-loader campaign-image-loader" />
        )}
        <Row>
          <Col className="creator-preview">
            <div className="creator-heading">
              <div className="account-image">
                <img
                  src={
                    post?.isPreview
                      ? creatorPreviewImage
                      : post?.creator?.pictureCDN
                  }
                  alt="Creator's avatar"
                  width="40px"
                  height="40px"
                  className="rounded-circle"
                  onLoad={(e) => {
                    setImageCreatorLoading(false);
                  }}
                  onError={(e) => {
                    setImageCreatorLoading(false);
                    return (e.target.src = defaultCreatorImg);
                  }}
                />
              </div>
              <span className="creator-name">
                {post?.creator?.name ||
                  post?.creator?.username ||
                  "Name Unavailable"}
              </span>
            </div>
            <div className="social-icons">
              <div className="social-icon-round">
                {networkImage(post?.network)}
              </div>
              {!post?.isPreview && !isCampaignShared && (
                <>
                  <EllipsisVerticalIcon
                    className="icon-btn"
                    onClick={togglePopover}
                    id={`action-popover-${post?._id}`}
                    width={20}
                    height={20}
                    strokeWidth={2}
                  />
                  <Popover
                    isOpen={popoverOpen}
                    target={`action-popover-${post?._id}`}
                    toggle={togglePopover}
                    placement="auto"
                  >
                    <div className="action-buttons">
                      <button
                        className="btn btn-popover"
                        onClick={() => onDeleteHandler()}
                      >
                        <TrashIcon
                          width={20}
                          height={20}
                          strokeWidth={2}
                        ></TrashIcon>
                        <span>
                          {t("campaign_manager.campaigns_table.delete")}
                        </span>
                      </button>
                    </div>
                  </Popover>
                </>
              )}
            </div>
          </Col>
        </Row>
        <CardLink
          href={
            post?.postUrl?.includes("http://") ||
            post?.postUrl?.includes("https://")
              ? post?.postUrl
              : `https://${post?.postUrl}`
          }
          target="_blank"
        >
          <div className="post-image-container">
            <img
              src={post?.isPreview ? postPreviewImage : post?.thumbnailCDN}
              alt="Background of post media"
              className="image-background"
              onLoad={(e) => {
                setImageBackgroundLoading(false);
              }}
            />
            <img
              src={post?.isPreview ? postPreviewImage : post?.thumbnailCDN}
              alt="A preview of the post's media/thumbnail"
              className="image-preview"
              onLoad={(e) => {
                setImageLoading(false);
              }}
            />
          </div>
        </CardLink>
        <CardBody className="post-info-card">
          <div className="post-info">
            <div
              ref={truncatedDateElem}
              className="post-date text-truncated-inline"
              title={moment(post?.posted).format("MMMM DD, YYYY, h:mm:ss")}
            >
              {moment(post?.posted).format("MMMM DD, YYYY, h:mm:ss")}
            </div>
            <div
              ref={truncatedTitleElem}
              className="post-title text-truncated-inline"
              title={post?.title}
            >
              {post?.title}
            </div>
            <div
              ref={truncatedTextElem}
              className="mb-3 mt-2 post-text text-truncated-multiline"
              title={post?.text}
            >
              {post?.text}
            </div>
          </div>
          <Row className="row-xs post-stats">
            {typeof post?.stats?.views === "number" && (
              <Col className="post-bottom">
                <PreciseMetricDisplayHover
                  metricValue={post?.stats?.views}
                  triggerElem={
                    <div>
                      <span className="text-sm">Views</span>
                      <h5 className="text-count">
                        {(post?.stats?.likes > 0 ||
                          post?.stats?.comments > 0 ||
                          post?.stats?.plays > 0 ||
                          post?.stats?.shares > 0) &&
                        post?.stats?.views === 0
                          ? "--"
                          : formatNumbersToUnits(post?.stats?.views)}
                      </h5>
                    </div>
                  }
                />
              </Col>
            )}
            {typeof post?.stats?.plays === "number" && (
              <Col className="post-bottom">
                <PreciseMetricDisplayHover
                  metricValue={post?.stats?.plays}
                  triggerElem={
                    <div>
                      <span className="text-sm">Plays</span>
                      <h5 className="text-count">
                        {formatNumbersToUnits(post?.stats?.plays)}
                      </h5>
                    </div>
                  }
                />
              </Col>
            )}
            {typeof post?.stats?.likes === "number" && (
              <Col className="post-bottom">
                <PreciseMetricDisplayHover
                  metricValue={post?.stats?.likes}
                  triggerElem={
                    <div>
                      <span className="text-sm">Likes</span>
                      <h5 className="text-count">
                        {formatNumbersToUnits(post?.stats?.likes)}
                      </h5>
                    </div>
                  }
                />
              </Col>
            )}
            {typeof post?.stats?.comments === "number" && (
              <Col className="post-bottom">
                <PreciseMetricDisplayHover
                  metricValue={post?.stats?.comments}
                  triggerElem={
                    <div>
                      <span className="text-sm">Comments</span>
                      <h5 className="text-count">
                        {formatNumbersToUnits(post?.stats?.comments)}
                      </h5>
                    </div>
                  }
                />
              </Col>
            )}
            {typeof post?.stats?.shares === "number" && (
              <Col className="post-bottom">
                <PreciseMetricDisplayHover
                  metricValue={post?.stats?.shares}
                  triggerElem={
                    <div>
                      <span className="text-sm">Shares</span>
                      <h5 className="text-count">
                        {formatNumbersToUnits(post?.stats?.shares)}
                      </h5>
                    </div>
                  }
                />
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
export default CampaignPostDisplayCard;
