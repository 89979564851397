import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Button,
  Badge,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  capitalize,
  formatNumbersToCommaSeperated,
  showTitle,
  showTooltip,
} from "../../../../actions";
import { LazyImage, RenderButton } from "../../../../components";

const LikesLocationByCountry = (props) => {
  const { t } = useTranslation();

  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>{t("reports.audience_details.location.country")}</CardTitle>
        <div className="card-content ch-10 ">
          <ListGroup flush className="country-list with-progress">
            {props.accountOverviewDetails &&
            props.accountOverviewDetails.audience_likers &&
            props.accountOverviewDetails.audience_likers.data &&
            props.accountOverviewDetails.audience_likers.data.audience_geo &&
            props.accountOverviewDetails.audience_likers.data.audience_geo
              .countries ? (
              props.accountOverviewDetails.audience_likers.data.audience_geo.countries.map(
                (country, index) => {
                  if (index < 3) {
                    return (
                      <ListGroupItem key={index}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="country-text-wrap">
                            <div className="flag-img">
                              <LazyImage
                                src={props.generateImgUrl(
                                  "country",
                                  country.code
                                )}
                                alt="img"
                              />
                            </div>
                            <div
                              id={`likes_${country.name}`}
                              title={showTitle(
                                `likes_${country.name}`,
                                country.name
                              )}
                              className="country-name"
                            >
                              {capitalize(country.name)}
                            </div>
                          </div>
                          <Badge className="text-grey" color="">
                            {(country.weight * 100).toFixed(2) + "%"}
                          </Badge>
                        </div>

                        <Progress
                          value={(country.weight * 100).toFixed(2)}
                          className="progress-bar-custom"
                        />
                      </ListGroupItem>
                    );
                  }
                  return null;
                }
              )
            ) : (
              <span className="text-center text-grey ">
                <p className="pt-50">{t("errors.no_record")}</p>
              </span>
            )}
          </ListGroup>
        </div>
        {props.accountOverviewDetails &&
        props.accountOverviewDetails.audience_likers &&
        props.accountOverviewDetails.audience_likers.data &&
        props.accountOverviewDetails.audience_likers.data.audience_geo &&
        props.accountOverviewDetails.audience_likers.data.audience_geo
          .countries ? (
          <div className="bottom">
            <Button
              color="link"
              onClick={() => props.toggleModal("likesLocationByCountryModal")}
            >
              {t("reports.view_more")}
            </Button>
            <Modal
              id="likesLocationByCountryModalDownload"
              isOpen={props.modals.likesLocationByCountryModal}
              toggle={() => props.toggleModal("likesLocationByCountryModal")}
            >
              <ModalHeader
                toggle={() => props.toggleModal("likesLocationByCountryModal")}
              >
                <span className="tooltip-button-wrap">
                  <span className="text-tooltip">
                    {t("reports.audience_details.location.country")}
                  </span>
                  <span
                    href="#"
                    id="likesLocationByCountryModal"
                    className="tooltip-icon"
                  ></span>
                </span>
                {props.tooltip && (
                  <UncontrolledTooltip
                    placement="bottom"
                    className="tooltip-content"
                    target="likesLocationByCountryModal"
                  >
                    {showTooltip("likesLocationByCountryModal")}
                  </UncontrolledTooltip>
                )}
                <RenderButton
                  onClick={() =>
                    props.downloadPdf("likesLocationByCountryModalDownload")
                  }
                  className="download-icon-button mg-left-15 link"
                >
                  {t("reports.download")}
                </RenderButton>
              </ModalHeader>
              <ModalBody>
                <div className="multiple-tbl">
                  <h4>{t("reports.audience_details.location.country")}</h4>
                  <Table className="modal-table">
                    <thead>
                      <tr>
                        <th>{t("reports.chart.country")}</th>
                        <th className="text-right">
                          {t("reports.chart.followers")}
                        </th>
                        <th className="text-right">
                          {t("reports.chart.likes")}
                        </th>
                        <th className="text-right">
                          {t("reports.chart.percentage")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.accountOverviewDetails &&
                      props.accountOverviewDetails.audience_likers &&
                      props.accountOverviewDetails.audience_likers.data &&
                      props.accountOverviewDetails.audience_likers.data
                        .audience_geo &&
                      props.accountOverviewDetails.audience_likers.data
                        .audience_geo.countries ? (
                        props.accountOverviewDetails.audience_likers.data.audience_geo.countries.map(
                          (country, index) => (
                            <tr key={index}>
                              <th scope="row">
                                <div className="country-text-wrap">
                                  <div className="flag-img">
                                    {
                                      <img
                                        src={props.generateImgUrl(
                                          "country",
                                          country.code
                                        )}
                                        alt="img"
                                      />
                                    }
                                  </div>
                                  <div className="country-name">
                                    {capitalize(country.name)}
                                  </div>
                                </div>
                              </th>
                              <td className="text-right">
                                {formatNumbersToCommaSeperated(
                                  country.weight *
                                    props.accountOverviewDetails.user_profile
                                      .followers
                                )}
                              </td>
                              <td className="text-right">
                                {formatNumbersToCommaSeperated(
                                  country.weight *
                                    props.accountOverviewDetails.user_profile
                                      .avg_likes
                                )}
                              </td>
                              <td className="text-right">
                                {(country.weight * 100).toFixed(2) + "%"}
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td align="center" colSpan="4">
                            {t("errors.no_record")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="multiple-tbl">
                  <h4>{t("reports.audience_details.location.state")}</h4>
                  <Table className="modal-table">
                    <thead>
                      <tr>
                        <th>{t("reports.chart.state")}</th>
                        <th className="text-right">
                          {t("reports.chart.followers")}
                        </th>
                        <th className="text-right">
                          {t("reports.chart.likes")}
                        </th>
                        <th className="text-right">
                          {t("reports.chart.percentage")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.accountOverviewDetails &&
                      props.accountOverviewDetails.audience_likers &&
                      props.accountOverviewDetails.audience_likers.data &&
                      props.accountOverviewDetails.audience_likers.data
                        .audience_geo &&
                      props.accountOverviewDetails.audience_likers.data
                        .audience_geo.states ? (
                        props.accountOverviewDetails.audience_likers.data.audience_geo.states.map(
                          (state, index) => (
                            <tr key={index}>
                              <th scope="row">
                                <div className="country-text-wrap">
                                  <div className="flag-img">
                                    {
                                      <img
                                        src={props.generateImgUrl(
                                          "country",
                                          state.country && state.country.code
                                        )}
                                        alt="img"
                                      />
                                    }
                                  </div>
                                  <div className="country-name">
                                    {capitalize(state.name)}
                                  </div>
                                </div>
                              </th>
                              <td className="text-right">
                                {formatNumbersToCommaSeperated(
                                  state.weight *
                                    props.accountOverviewDetails.user_profile
                                      .followers
                                )}
                              </td>
                              <td className="text-right">
                                {formatNumbersToCommaSeperated(
                                  state.weight *
                                    props.accountOverviewDetails.user_profile
                                      .avg_likes
                                )}
                              </td>
                              <td className="text-right">
                                {(state.weight * 100).toFixed(2) + "%"}
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td align="center" colSpan="4">
                            {t("errors.no_record")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </ModalBody>
            </Modal>
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default LikesLocationByCountry;
