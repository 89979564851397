import React from "react";
import settings from "settings";
import { FormGroup } from "reactstrap";
import { NavLink } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { RenderPasswordField, SubmitButton } from "../../components";
import { validate } from "./validate";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { resetPassword, clearErrorMsg } from "./actions/index";
import { showErrorMessage } from "../../actions";
import { store } from "../../store";
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleEnterKey = debounce(this.handleEnterKey.bind(this), 500);
    this.onSubmit = debounce(this.onSubmit.bind(this), 500);
  }
  onSubmit = (values) => {
    let postObj = {
      password: values.password.trim(),
      confirm_password: values.password2.trim(),
      token: window.location.search.split("?token=").pop(),
    };

    if (postObj.token.trim() !== "") {
      this.props.resetPassword(postObj);
    }
  };
  clearErrorMsg = () => {
    this.props.clearErrorMsg();
  };

  componentDidMount() {
    document.getElementsByClassName("app")[0].classList.add("auth-wrap");
  }
  componentWillUnmount() {
    this.props.clearErrorMsg();
    document.getElementsByClassName("app")[0].classList.remove("auth-wrap");
  }
  handleEnterKey = (input, values) => {
    input.persist();
    if (input.charCode === 13 && input.target.value.trim() !== "") {
      let postObj = {
        password: values.password.trim(),
        confirm_password: values.password2.trim(),
        token: window.location.search.split("?token=").pop(),
      };

      if (postObj.token.trim() !== "") {
        this.props.resetPassword(postObj);
      }
    }
  };
  render() {
    return (
      <div className="pt-40">
        {this.props.errorMsg
          ? showErrorMessage(
              store,
              this.props.errorMsg,
              this.clearErrorMsg,
              5000,
              "resetPassword"
            )
          : null}
        <div className="main-site-content container">
          <div className="user-form-wrap">
            <div className="page-head">
              <img
                src={settings.mainbrand}
                className="auth_logo"
                alt="homepage"
              />
              <h1>Reset Password</h1>
              <p className="sub-text">Enter you new password</p>
            </div>
            <Formik
              initialValues={this.props.initialValues}
              onSubmit={this.onSubmit}
              validate={validate}
            >
              {({ errors, values, touched }) => (
                <Form>
                  <FormGroup>
                    <Field
                      name="password"
                      placeholder="New Password"
                      className={
                        errors.password && touched.password ? "error" : ""
                      }
                      component={RenderPasswordField}
                    />
                    {errors.password && touched.password && (
                      <span className="required">{errors.password}</span>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="password2"
                      placeholder="Confirm Password"
                      onKeyPress={(input) => this.handleEnterKey(input, values)}
                      className={
                        errors.password2 && touched.password2 ? "error" : ""
                      }
                      component={RenderPasswordField}
                    />
                    {errors.password2 && touched.password2 && (
                      <span className="required">{errors.password2}</span>
                    )}
                  </FormGroup>

                  <FormGroup className="bottom text-center">
                    <SubmitButton className="btn btn-primary">
                      Update
                    </SubmitButton>
                    <br />
                    {/* <Button className="btn btn-success">Ok</Button><br />
                      <Button className="btn btn-danger">Ok</Button> */}
                    <p className="text-grey">
                      Back to{" "}
                      <NavLink to="/" className="link-more">
                        Sign in
                      </NavLink>
                    </p>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    errorMsg: state.forgotPassword.errorMsg,
    successMsg: state.forgotPassword.successMsg,
    initialValues: {
      password: "",
      password2: "",
    },
  };
};
export default connect(mapStateToProps, {
  resetPassword,
  clearErrorMsg,
})(ResetPassword);
