import React from "react";
import settings from "settings";
import { useTranslation } from "react-i18next";
import { Row, Col, Card, CardBody, CardTitle, Badge } from "reactstrap";
import useDarkMode from "use-dark-mode";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { formatName, formatNumbersToCommaSeperated } from "../../../actions";
import AccountOverview from "./accountOverview";
import PopularMentions from "./popularMentions";
import PopularHashtags from "./popularHashtags";
import EngagementsSpreadLastPosts from "./engagementsSpreadLastPosts";
import LookAlikesSection from "./lookAlikes";
import EngagementRateSection from "./engagementRateSection";
import SocialCloud from "./socialCloud";
import InfluencerBrandAffinity from "./influencerBrandAffinity";
import InfluencerInterests from "./influencerInterests";
const chartOptions = (props, type, darkMode) => {
  let months = settings.pages.reports.months;
  let data =
    props.accountOverviewDetails &&
    props.accountOverviewDetails.user_profile &&
    props.accountOverviewDetails.user_profile.stat_history
      ? props.accountOverviewDetails.user_profile.stat_history.map((option) => {
          return option[type];
        })
      : [];
  // data = data.reverse();

  let categories =
    props.accountOverviewDetails &&
    props.accountOverviewDetails.user_profile &&
    props.accountOverviewDetails.user_profile.stat_history
      ? props.accountOverviewDetails.user_profile.stat_history.map((option) => {
          return `${months[parseInt(option.month.split("-").pop(), 10) - 1]}`;
        })
      : [];

  data.shift();
  categories.shift();

  return {
    chart: {
      height: 200,
      backgroundColor: null,
    },
    tooltip: {
      backgroundColor: "#17222d",
      borderColor: "transparent",
      borderRadius: 10,
      shadow: false,
      style: {
        color: "white",
        fontSize: "11px",
      },
      positioner: function (labelWidth, labelHeight, point) {
        return { x: point.plotX, y: 0 };
      },
      formatter: function () {
        return [formatNumbersToCommaSeperated(this.y)];
      },
    },

    title: "",
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          color: "#898e94",
        },
      },
      gridLineColor: darkMode ? "#66666640" : "#e6e6e6",
    },
    xAxis: {
      crosshair: {
        width: 1,
        color: "#dee2e6",
        label: {
          enabled: true,
          align: "right",
        },
      },
      categories: categories,
      title: {
        text: null,
      },
      labels: {
        style: {
          color: "#898e94",
        },
      },
      lineColor: "#1f87ee",
      tickColor: "transparent",
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      spline: {
        width: 1,
        color: "#1f87ee",
        linejoin: "round",
        marker: {
          enabled: false,
        },
      },
    },

    series: [
      {
        type: "spline",
        name: formatName(type),
        data: data,
      },
    ],
    credits: {
      enabled: false,
    },
  };
};
const InfulencersSection = (props) => {
  const { t } = useTranslation();
  const darkMode = useDarkMode(false);
  return (
    <div className="section-inf" id="Influencer">
      <Row className="row-xs">
        <AccountOverview {...props} />
      </Row>
      <Row className="row-xs">
        <EngagementRateSection {...props} />
      </Row>

      <Row className="row-xs">
        {props.cloudTags &&
        props.cloudTags.data &&
        props.cloudTags.data.length ? (
          <SocialCloud {...props} />
        ) : null}

        {props.accountOverviewDetails.user_profile?.similar_users &&
          props.accountOverviewDetails.user_profile.similar_users.length && (
            <LookAlikesSection {...props} />
          )}
      </Row>
      {props.accountOverviewDetails &&
      props.accountOverviewDetails.user_profile &&
      (props.calculatePercentage("following") ||
        props.calculatePercentage("followers") ||
        props.calculatePercentage("avg_views") ||
        props.calculatePercentage("avg_likes") ||
        props.calculatePercentage("avg_comments")) ? (
        <Row className="row-xs">
          {/* FOLLOWERS (ALL) */}
          <Col md="4">
            <Card className="card-content-custom">
              <CardBody>
                <CardTitle>
                  {props.accountOverviewDetails.user_profile &&
                  props.accountOverviewDetails.user_profile.type === "youtube"
                    ? t("reports.influencer_details.subscribers")
                    : t("reports.influencer_details.followers")}
                  <Badge
                    className={`fs--2 mx-2 rank-wrap ${
                      props.accountOverviewDetails &&
                      props.accountOverviewDetails.user_profile &&
                      props.calculatePercentage("followers")
                        ? props.calculatePercentage("followers").indexOf("-") <
                          0
                          ? "rank-up badge-soft-success"
                          : "rank-down badge-soft-danger"
                        : ""
                    }`}
                  >
                    {props.calculatePercentage("followers").replace("-", "")}
                  </Badge>
                  {props.calculatePercentage("followers")
                    ? t("reports.influencer_details.month")
                    : ""}{" "}
                </CardTitle>
                <div className="graph-wrap ptop15 ch-4">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions(props, "followers", darkMode.value)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

          {/* FOLLOWING (INSTA, TIKTOK) */}
          {props.accountOverviewDetails.user_profile &&
            (props.accountOverviewDetails.user_profile.type === "instagram" ||
              props.accountOverviewDetails.user_profile.type === "tiktok") && (
              <Col md="4">
                <Card className="card-content-custom">
                  <CardBody>
                    <CardTitle>
                      {t("reports.influencer_details.following")}
                      <Badge
                        className={`fs--2 mx-2 rank-wrap ${
                          props.accountOverviewDetails &&
                          props.accountOverviewDetails.user_profile &&
                          props.calculatePercentage("following")
                            ? props
                                .calculatePercentage("following")
                                .indexOf("-") < 0
                              ? "rank-up badge-soft-success"
                              : "rank-down badge-soft-danger"
                            : ""
                        }`}
                      >
                        {props
                          .calculatePercentage("following")
                          .replace("-", "")}
                      </Badge>
                      {props.calculatePercentage("following")
                        ? t("reports.influencer_details.month")
                        : ""}{" "}
                    </CardTitle>
                    <div className="graph-wrap ptop15 ch-4">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions(
                          props,
                          "following",
                          darkMode.value
                        )}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}

          {/* AVG VIEWS (YT, TIKTOK) */}
          {props.accountOverviewDetails.user_profile &&
            (props.accountOverviewDetails.user_profile.type === "youtube" ||
              props.accountOverviewDetails.user_profile.type === "tiktok") && (
              <Col md="4">
                <Card className="card-content-custom">
                  <CardBody>
                    <CardTitle>
                      {t("reports.influencer_details.views")}
                      <Badge
                        className={`fs--2 mx-2 rank-wrap ${
                          props.accountOverviewDetails &&
                          props.accountOverviewDetails.user_profile &&
                          props.calculatePercentage("avg_views")
                            ? props
                                .calculatePercentage("avg_views")
                                .indexOf("-") < 0
                              ? "rank-up badge-soft-success"
                              : "rank-down badge-soft-danger"
                            : ""
                        }`}
                      >
                        {props
                          .calculatePercentage("avg_views")
                          .replace("-", "")}
                      </Badge>
                      {props.calculatePercentage("avg_views")
                        ? t("reports.influencer_details.month")
                        : ""}{" "}
                    </CardTitle>
                    <div className="graph-wrap ptop15 ch-4">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions(
                          props,
                          "avg_views",
                          darkMode.value
                        )}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}

          {/* AVG LIKES (ALL) */}
          <Col md="4">
            <Card className="card-content-custom">
              <CardBody>
                <CardTitle>
                  {t("reports.influencer_details.likes")}
                  <Badge
                    className={`fs--2 mx-2 rank-wrap ${
                      props.accountOverviewDetails &&
                      props.accountOverviewDetails.user_profile &&
                      props.calculatePercentage("avg_likes")
                        ? props.calculatePercentage("avg_likes").indexOf("-") <
                          0
                          ? "rank-up badge-soft-success"
                          : "rank-down badge-soft-danger"
                        : ""
                    }`}
                  >
                    {props.calculatePercentage("avg_likes").replace("-", "")}
                  </Badge>
                  {props.calculatePercentage("avg_likes")
                    ? t("reports.influencer_details.month")
                    : ""}{" "}
                </CardTitle>
                <div className="graph-wrap ptop15 ch-4">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions(props, "avg_likes", darkMode.value)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

          {/* AVG COMMENTS (YT, TIKTOK) */}
          {props.accountOverviewDetails.user_profile &&
            (props.accountOverviewDetails.user_profile.type === "youtube" ||
              props.accountOverviewDetails.user_profile.type === "tiktok") && (
              <Col md="4">
                <Card className="card-content-custom">
                  <CardBody>
                    <CardTitle>
                      {t("reports.influencer_details.comments")}
                      <Badge
                        className={`fs--2 mx-2 rank-wrap ${
                          props.accountOverviewDetails &&
                          props.accountOverviewDetails.user_profile &&
                          props.calculatePercentage("avg_comments")
                            ? props
                                .calculatePercentage("avg_comments")
                                .indexOf("-") < 0
                              ? "rank-up badge-soft-success"
                              : "rank-down badge-soft-danger"
                            : ""
                        }`}
                      >
                        {props
                          .calculatePercentage("avg_comments")
                          .replace("-", "")}
                      </Badge>
                      {props.calculatePercentage("avg_comments")
                        ? t("reports.influencer_details.month")
                        : ""}{" "}
                    </CardTitle>
                    <div className="graph-wrap ptop15 ch-4">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions(
                          props,
                          "avg_comments",
                          darkMode.value
                        )}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
        </Row>
      ) : null}

      {props.accountOverviewDetails &&
        props.accountOverviewDetails.user_profile && (
          <>
            {props.calculatePercentage("avg_views") && <></>}
            {props.calculatePercentage("avg_views") && <></>}
            {props.calculatePercentage("avg_views") && <></>}
          </>
        )}

      {!props.accountOverviewDetails ||
      !props.accountOverviewDetails.user_profile ||
      !props.accountOverviewDetails.user_profile.recent_posts ||
      !props.accountOverviewDetails.user_profile.recent_posts.length ? null : (
        <Row>
          <EngagementsSpreadLastPosts {...props} />
        </Row>
      )}

      <Row className="row-xs">
        {(!props.accountOverviewDetails ||
          !props.accountOverviewDetails.user_profile ||
          !props.accountOverviewDetails.user_profile.top_hashtags ||
          !props.accountOverviewDetails.user_profile.top_hashtags.length) &&
        (!props.accountOverviewDetails ||
          !props.accountOverviewDetails.user_profile ||
          !props.accountOverviewDetails.user_profile.top_mentions ||
          !props.accountOverviewDetails.user_profile.top_mentions
            .length) ? null : (
          <>
            <PopularHashtags {...props} />
            <PopularMentions {...props} />
          </>
        )}
      </Row>

      <Row className="row-xs">
        {(!props.accountOverviewDetails ||
          !props.accountOverviewDetails.user_profile ||
          !props.accountOverviewDetails.user_profile.brand_affinity ||
          !props.accountOverviewDetails.user_profile.brand_affinity.length) &&
        (!props.accountOverviewDetails ||
          !props.accountOverviewDetails.user_profile ||
          !props.accountOverviewDetails.user_profile.interests ||
          !props.accountOverviewDetails.user_profile.interests
            .length) ? null : (
          <>
            <InfluencerBrandAffinity {...props} />
            <InfluencerInterests {...props} />
          </>
        )}
      </Row>
    </div>
  );
};
export default InfulencersSection;
