import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardLink,
} from "reactstrap";
import "react-multi-carousel/lib/styles.css";
import moment from "moment";
import { formatNumbersToCommaSeperated } from "../../actions";

const PostCard = (props) => {
  const { post } = props;
  const textLength =
    window.innerWidth <= 3000 && window.innerWidth > 992
      ? 118
      : window.innerWidth <= 992
      ? window.innerWidth >= 480
        ? 80
        : 126
      : 126;
  return (
    <Card>
      <CardLink href={post.link} target="_blank">
        <CardImg
          src={post.image ? post.image : post.thumbnail}
          alt="Post Image"
          loading="lazy"
        />
      </CardLink>
      <CardBody>
        <div className="post-date">
          {moment(post.created).format("MMMM DD, YYYY, HH:mm:ss")}
        </div>

        <CardText
          title={post.text && post.text.length > textLength ? post.text : ""}
        >
          {post.text && post.text.length > textLength
            ? post.text.slice(0, textLength) + ".."
            : post.text}
        </CardText>
        <Row className="row-xs">
          <Col className="post-bottom col-6">
            <span className="text-sm">Likes</span>
            <h5 className="text-count">
              {formatNumbersToCommaSeperated(post.stat.likes)}
            </h5>
          </Col>

          <Col className="post-bottom col-6">
            <span className="text-sm">Comments</span>
            <h5 className="text-count">
              {formatNumbersToCommaSeperated(post.stat.comments)}
            </h5>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
export default PostCard;
